import React from 'react';
import { Layout, Text } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';
import shared from '@sharedStyle/page.scss';
import style from '@sharedStyle/audienceSelector.scss';

const Campaign = props => {
    const _this = { props };
    const { components } = getFeatures('campaign_selector', _this, {
        isColumnView: true
    });

    return (
        <div className={shared.pageWrapper} style={props.fadeIn}>
            <Layout
                flexDirection="column"
                vAlign="space-around"
                padding="16px 48px"
                className={style.cardPageWrapper}>
                <Layout
                    flexDirection="column"
                    hAlign="flex-start"
                    padding="16px 0 56px 0">
                    <div className={style.selectorText}>
                        <Text type="h3">Select Campaign Type</Text>
                    </div>
                </Layout>

                <Layout
                    flexDirection="row"
                    hAlign="center"
                    className={style.cardsWrapper}>
                    {components}
                </Layout>
            </Layout>
        </div>
    );
};

export default withFeatures(Campaign);
