import React from 'react';
import requestSvg from '../../core/requestSvg';
import PropTypes from 'prop-types';
import { baseline } from '../../shared';
import { IconWrapper } from './style';
import SIZES from './sizes';
import { ICON } from '../testIds';
import { testIdBuilder } from '../../core/testIdBuilder';

const sizer = props =>
    props.size
        ? props.size in SIZES
            ? SIZES[props.size]
            : baseline(props.size)
        : '';

/**
 * Icon component
 * */
class Icon extends React.Component {
    constructor(props) {
        super(props);

        this._isMounted = false;

        this.state = {
            loading: true,
            result: null
        };

        this.requestIcon = this.requestIcon.bind(this);
    }

    componentDidMount() {
        this._isMounted = true;
        this.requestIcon(
            this.props.src,
            this.props.onSvgLoad,
            this.props.placeholder
        );
    }

    componentWillUnmount() {
        this._isMounted = false;
    }

    requestIcon(src, onSvgLoad, placeholderSrc) {
        requestSvg(src)
            .then(result => {
                if (this._isMounted) {
                    this.setState({
                        loading: false,
                        result
                    });

                    if (onSvgLoad) onSvgLoad();
                }
            })
            .catch(error => {
                if (placeholderSrc) {
                    this.requestIcon(placeholderSrc);
                }
            });
    }

    render() {
        const size = sizer(this.props);

        if (this.state.loading) {
            const css = {
                width: size,
                height: size
            };

            return <div style={css}></div>;
        }

        const createMarkup = () => ({
            __html: this.state.result
                ? this.state.result.outerHTML
                : '<div></div>'
        });
        const testId = testIdBuilder(this.props.testNameSpace, ICON);

        return (
            <IconWrapper
                className={this.props.className}
                size={size}
                id={this.props.id}
                dangerouslySetInnerHTML={createMarkup()}
                data-testid={testId}
            />
        );
    }
}

Icon.propTypes = {
    /** src of an icon */
    src: PropTypes.string,
    /** size of an icon:  "input", "checkbox" or integer to be multiplied by 8*/
    size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** callback function that will be called when icon is loaded */
    onSvgLoad: PropTypes.func,
    /** placeholder to display when icon is not found */
    placeholder: PropTypes.string,
    /** Namespace for e2e tests targeting */
    testNameSpace: PropTypes.string,
    /** Id for tracking */
    id: PropTypes.string
};

export default Icon;
