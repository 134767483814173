import { validateArg } from '@lib';
import {
    convertScheduleDateStrsToInstances,
    effectiveDurationService
} from '../schedule';

export const getScheduleReduxProperties = schedule => {
    validateArg(schedule, 'object');
    const {
        startDate,
        endDate,
        hiatusWeeks
    } = convertScheduleDateStrsToInstances(schedule);

    const duration = effectiveDurationService({
        startDate,
        endDate,
        hiatusWeeks
    });

    const { excludedDayparts, isBroadcast } = schedule;

    const formattedExcludedDayparts = excludedDayparts
        ? excludedDayparts.map(dayParts => {
              return {
                  ...dayParts,
                  value: dayParts.name
              };
          })
        : [];

    return {
        startDate,
        endDate,
        duration,
        hiatusWeeks,
        excludedDayparts: formattedExcludedDayparts,
        isBroadcast:
            isBroadcast === undefined || isBroadcast === null
                ? true
                : isBroadcast
    };
};
