import {
    LINEAR_TV,
    PRE_MID_ROLL,
    OTT,
    BANNERS,
    AD_MESSENGER,
    FACEBOOK,
    INSTAGRAM
} from '@config';

export const barColors = {
    [LINEAR_TV]: '#00AA5A',
    [BANNERS]: '#003380',
    [AD_MESSENGER]: '#9EB5D7',
    [FACEBOOK]: '#0090AA',
    [PRE_MID_ROLL]: '#C8ECDD',
    [OTT]: '#7DD3AB',
    [INSTAGRAM]: '#89D6E4'
};
