import login from './login';
import app from './app';
import main from './main';
import plan from './plan';
import location from './location';
import audience from './audience';
import advertisers from './advertisers';
import help from './help';
import profile from './profile';
import budget from './budget';
import creative from './creative';
import networks from './networks';
import mediaPlan from './mediaPlan';
import book from './book';
import schedule from './schedule';
import plans from './plans';
import reports from './reports';
import terms from './terms';
import privacy from './privacy';
import copyright from './copyright';
import admin from './admin';
import campaign from './campaign';
import verificationError from './verificationError';
import signUp from './signUp';
import verifyUser from './verifyUser';
import notfound from './notfound';
import ranker from './ranker';

export default [
    ...admin,
    ...login,
    ...app,
    ...main,
    ...plan,
    ...location,
    ...audience,
    ...advertisers,
    ...help,
    ...profile,
    ...budget,
    ...creative,
    ...networks,
    ...mediaPlan,
    ...book,
    ...schedule,
    ...plans,
    ...reports,
    ...terms,
    ...privacy,
    ...copyright,
    ...campaign,
    ...notfound,
    ...verificationError,
    ...signUp,
    ...verifyUser,
    ...ranker
];
