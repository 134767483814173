import { Networks } from '../components/pages';
import { LeftSidebarNetworks } from '../components/layouts';

export default [
    {
        name: 'app.plan.networks',
        url: '/network',
        views: {
            'leftSidebar@app': {
                component: LeftSidebarNetworks
            },
            'content@app': {
                component: Networks
            }
        }
    }
];
