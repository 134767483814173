import { createReducer } from '@reduxjs/toolkit';
import { SET_SHOW_PAY_NOW } from '../constants';

const initialState = false;

const setShowPayNow = (state, action) => {
    const { showPayNow } = action.payload;
    return typeof showPayNow === 'boolean' ? showPayNow : state;
};

export const showPayNow = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_SHOW_PAY_NOW, setShowPayNow)
        .addDefaultCase((state, _) => state);
});
