import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { strf, round, getPercentFromValue } from '@lib';
import { Layout, Text, Content, Bar } from 'maslow';
import styles from '../styles.scss';
import { budgetConfigSelector } from '@selectors/';

const _buildSection = (name, budget, percent, icon, bar, iconStyle) => {
    return (
        <Content fillParent key={name}>
            <Layout flexDirection="row" vAlign="center">
                <Layout hAlign="flex-end" className={styles.iconWrapper}>
                    <Layout
                        hAlign="center"
                        vAlign="center"
                        style={iconStyle}
                        className={styles.channelIconWrapper}>
                        <img src={icon} className={styles.icon} />
                    </Layout>
                </Layout>

                <Text className={styles.channelText} type="h5">
                    {name}
                </Text>

                <Layout flexDirection="column" vAlign="center" fillParent>
                    <Bar
                        className={styles.channelBar}
                        percentage={percent}
                        theme={bar}
                    />

                    <Layout flexDirection="row" alignSelf="flex-end">
                        <Text type="h5">{budget} |</Text>
                        <Text type="h5" className={styles.leftPad}>
                            {strf(percent).append('%').value()}
                        </Text>
                    </Layout>
                </Layout>
            </Layout>
        </Content>
    );
};

const Channel = ({ data }) => {
    const { total, channels } = useSelector(budgetConfigSelector);

    const { style, name, icon } = data;
    const { theme, primaryColor, shadowColor } = style;
    const budget = name in channels ? channels[name].budget : 0;
    const formattedBudget = strf(round(budget)).commas().prepend('$').value();
    const percent = getPercentFromValue(budget, total) || 0;

    const iconStyle = {
        backgroundColor: primaryColor,
        boxShadow: shadowColor
    };

    return _buildSection(
        name,
        formattedBudget,
        percent,
        icon,
        theme,
        iconStyle
    );
};

Channel.propTypes = {
    data: PropTypes.object
};

export default Channel;
