import { createReducer } from '@reduxjs/toolkit';
import { TOGGLE_IS_AUDIENCE_MODIFIED } from '../constants';

const initialState = false;

const toggleIsAudienceModified = (state, action) => {
    const { isModified } = action.payload;
    return typeof isModified === 'boolean' ? isModified : state;
};

export const isAudienceModified = createReducer(initialState, reducer => {
    reducer
        .addCase(TOGGLE_IS_AUDIENCE_MODIFIED, toggleIsAudienceModified)
        .addDefaultCase((state, _) => state);
});
