import { budgetConfig } from '@config/config.budget';
import { getFormattedBudget } from '@services/budget';
import { getPercentFromValue, strf } from '@lib';
import { MARGIN_10, CELL_WIDTH_01, COLOR_BLUE } from '../constants';
import { getFormattedCpm } from '@services/budget';
import { LINEAR_TV } from '@config/';

export const buildBudgetData = (
    doc,
    counter,
    total,
    channels,
    subCampaigns,
    tvCpm,
    digitalProductDetails
) => {
    budgetConfig.distribution.channels.forEach(channel => {
        const table = {
            startY: counter.add(MARGIN_10),
            headStyles: { fillColor: COLOR_BLUE, cellWidth: CELL_WIDTH_01 },
            head: [],
            body: [],
            didDrawPage: data => {
                counter.set(counter.count + data.table.height);
            }
        };

        const { name } = channel;
        const channelBudget = name in channels ? channels[name].budget : 0;

        if (channelBudget > 0) {
            const percent = strf(getPercentFromValue(channelBudget, total))
                .append('%')
                .value();

            const formattedBudget = getFormattedBudget(channelBudget);

            table.head.push([name, '', formattedBudget, percent]);
        }

        channel.subCampaigns.forEach(subCampaign => {
            const subChannelTotalBudget =
                name in channels ? channels[name].budget : 0;

            const subChannelBudget =
                subCampaign in subCampaigns
                    ? subCampaigns[subCampaign].budget
                    : 0;

            if (subChannelBudget > 0) {
                const subChannelBudgetFormattedAmount = getFormattedBudget(
                    subChannelBudget
                );

                const finalFormattedBudget =
                    subChannelBudgetFormattedAmount === '$'
                        ? '$0'
                        : subChannelBudgetFormattedAmount;

                const subChannelPercent = strf(
                    getPercentFromValue(subChannelBudget, subChannelTotalBudget)
                )
                    .append('%')
                    .value();

                const cpm =
                    subCampaign === LINEAR_TV
                        ? tvCpm
                        : digitalProductDetails[subCampaign].cpm;
                const cpmFormatted = `${getFormattedCpm(cpm)} CPM`;

                table.body.push([
                    subCampaign,
                    cpmFormatted,
                    finalFormattedBudget,
                    subChannelPercent
                ]);
            }
        });

        doc.autoTable(table);
    });
};
