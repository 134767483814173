import { combineReducers } from 'redux';

import { RESET, RESET_PLAN, SET_PLAN, SET_RANKER } from './constants';
import { audience } from './audience/reducers';
import { schedule } from './schedule/reducers';
import { budget } from './budget/reducers';
import { networks } from './networks/reducers';
import { user } from './user/reducers';
import { mediaPlan } from './mediaPlan/reducers';
import { plan } from './plan/reducers';
import { creative } from './creative/reducers';
import { customList } from './customList/reducers';
import { location } from './location/reducers';
import { ui } from './ui/reducers';

const appReducer = combineReducers({
    audience,
    budget,
    creative,
    customList,
    location,
    mediaPlan,
    networks,
    plan,
    schedule,
    ui,
    user
});

export default (state, action) => {
    if (action.type === RESET) state = undefined;
    if (action.type === RESET_PLAN) state = { user: state.user };
    if (action.type === SET_PLAN) state = { ...action.payload.data };
    if (action.type === SET_RANKER)
        state = { user: state.user, ui: { isRanker: true } };

    return appReducer(state, action);
};
