import React from 'react';
import PropTypes from 'prop-types';
import { StyledCard } from './style';
import { CARD } from '../testIds';
import { testIdBuilder } from '../../core/testIdBuilder';

/**
 * Card component
 */

const Card = React.forwardRef((props, ref) => {
    const { testNameSpace, id, ...result } = props;

    const testId = testIdBuilder(testNameSpace, CARD);
    return (
        <StyledCard {...result} ref={ref} data-testid={testId} id={id}>
            {props.children}
        </StyledCard>
    );
});

Card.propTypes = {
    /** Height of the card in px */
    height: PropTypes.string,
    /** Width of the card in px */
    width: PropTypes.string,
    /** Background color of the card */
    backgroundColor: PropTypes.string,
    /** Border width of the card */
    borderWidth: PropTypes.string,
    /** Border color of the card */
    borderColor: PropTypes.string,
    /** On hover border color of the card */
    hoverBorderColor: PropTypes.string,
    /** Box shadow for the card */
    boxShadow: PropTypes.string,
    /** Hover box shadow for the card */
    hoverBoxShadow: PropTypes.string,
    /** Border radius for the card */
    borderRadius: PropTypes.string,
    /** Namespace for e2e tests targeting */
    testNameSpace: PropTypes.string,
    /** Id for tracking */
    id: PropTypes.string,
    /** margin */
    margin: PropTypes.string,
    /** padding */
    padding: PropTypes.string
};

Card.defaultProps = {
    borderWidth: '2px',
    borderRadius: '7px',
    borderColor: 'transparent',
    boxShadow: '0 1px 3px 0 rgba(0,51,128,0.3)'
};

export default Card;
