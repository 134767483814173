import { createReducer } from '@reduxjs/toolkit';
import { SET_END_DATE } from '../constants';

const initialState = null;

const setEndDate = (state, action) => {
    const { endDate } = action.payload;
    return typeof endDate === 'object' ? endDate : state;
};

export const endDate = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_END_DATE, setEndDate)
        .addDefaultCase((state, _) => state);
});
