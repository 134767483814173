import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Button } from 'maslow';
import { isEmptyString } from '@lib';

import { LOGIN_LOGIN_BUTTON } from '@config/idConstants';
import shared from '@sharedStyle/loginAndSignUp.scss';

const LoginButton = ({ onLoginClick, loginService, text, buttonAnimation }) => {
    const { password } = loginService.getUserDetails();
    const isPasswordEmpty = password === undefined || isEmptyString(password);
    const disabled = !loginService.hasValidEmail() || isPasswordEmpty;

    return (
        <Layout flexDirection="column" hAlign="center">
            <Button
                id={LOGIN_LOGIN_BUTTON}
                className={shared.btnSize}
                onClick={onLoginClick}
                disabled={disabled}
                animate={buttonAnimation}>
                {text}
            </Button>
        </Layout>
    );
};

LoginButton.propTypes = {
    onLoginClick: PropTypes.func,
    loginService: PropTypes.object,
    text: PropTypes.string,
    buttonAnimation: PropTypes.bool
};

export default LoginButton;
