import React from 'react';
import { Content } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { components } = getFeatures('dashboard', this);

        return <Content>{components}</Content>;
    }
}

export default withFeatures(Dashboard);
