import React from 'react';
import PropTypes from 'prop-types';
import { Content, Card, Text, Layout, Icon } from 'maslow';

import styles from './index.scss';
import { idBuilder } from '../../../services';
import { AUDIENCE_BUILDER_NAV_BUTTON } from '@config/idConstants';
import { navIcons } from '@config/config.audienceNavTabs';

const AudienceNavButton = ({
    onSectionClick,
    sectionName,
    setActiveClassName,
    sectionValue
}) => {
    return (
        <Card
            id={idBuilder(AUDIENCE_BUILDER_NAV_BUTTON, sectionName)}
            className={styles.audienceSectionNavButton}
            onClick={() => onSectionClick(sectionName)}>
            <Layout
                flexDirection="row"
                hAlign="center"
                vAlign="center"
                className={setActiveClassName(sectionName)}>
                <Icon
                    src={navIcons[sectionValue]}
                    size="3"
                    className={styles.iconWrapper}
                />
                <Content>
                    <Text type="input" className={styles.textWrapper}>
                        {sectionName}
                    </Text>
                </Content>
            </Layout>
        </Card>
    );
};

AudienceNavButton.propTypes = {
    onSectionClick: PropTypes.func.isRequired,
    sectionName: PropTypes.string.isRequired,
    setActiveClassName: PropTypes.func.isRequired
};

export default AudienceNavButton;
