export const SET_FLYOUT_NAV_STATUS = 'SET_FLYOUT_NAV_STATUS';
export const SET_IS_ON_BOOK_PAGE = 'SET_IS_ON_BOOK_PAGE';
export const SET_IS_PLAN_OPENED = 'SET_IS_PLAN_OPENED';
export const UPDATE_LOADING_SCREEN = 'UPDATE_LOADING_SCREEN';
export const RESET_LOADING_SCREEN = 'RESET_LOADING_SCREEN';
export const SET_IS_EDIT_CREATIVES = 'SET_IS_EDIT_CREATIVES';
export const SET_SHOW_PAY_NOW = 'SET_SHOW_PAY_NOW';
export const SET_IS_RANKER = 'SET_IS_RANKER';

export const ADD_TOAST = 'ADD_TOAST';
export const REMOVE_TOAST = 'REMOVE_TOAST';
export const REMOVE_ALL_TOASTS = 'REMOVE_ALL_TOASTS';
export const SET_IS_MEDIA_PLAN_VISITED = 'SET_IS_MEDIA_PLAN_VISITED';
