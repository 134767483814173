import { createReducer } from '@reduxjs/toolkit';
import { SET_AUDIENCE_TYPE } from '../constants';

const initialState = '';

const setAudienceType = (state, action) => {
    const { audienceType } = action.payload;
    return typeof audienceType === 'string' ? audienceType : state;
};

export const audienceType = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_AUDIENCE_TYPE, setAudienceType)
        .addDefaultCase((state, _) => state);
});
