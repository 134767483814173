import featuresManager from '@featuresManager';
import { createSelector } from 'reselect';
import {
    planSelector,
    mediaPlanSelector,
    scheduleSelector,
    creativeUrlsSelector,
    creativeProductsSelector,
    productDetailsSelector,
    formattedTvDetailsSelector,
    networkSelector,
    selectedLocationsSelector,
    locationSelector,
    formattedAudiencesSelector,
    budgetSelector,
    uiSelector,
    audienceSelector,
    userSelector,
    budgetConfigSelector
} from '@selectors';
import {
    getSaveMediaPlanObject,
    getBookPlanObject,
    getMediaPlanObject
} from '@services';

export const bookPlanObjectSelector = createSelector(
    planSelector,
    mediaPlanSelector,
    scheduleSelector,
    creativeUrlsSelector,
    creativeProductsSelector,
    productDetailsSelector,
    formattedTvDetailsSelector,
    networkSelector,
    selectedLocationsSelector,
    locationSelector,
    formattedAudiencesSelector,
    budgetSelector,
    uiSelector,
    audienceSelector,
    userSelector,
    budgetConfigSelector,
    (
        { attributes: { name, advertiser, region } },
        mediaPlan,
        schedule,
        creativeUrls,
        creativeProducts,
        productDetails,
        formattedTvDetails,
        { excludedNetworks },
        selectedLocations,
        { mapImageDataUrls },
        audience,
        { total, subCampaigns: { linearTv } },
        { showPayNow },
        { audiences, combinations, vennPngDataUrl, households },
        { account: { firstName, lastName, email } },
        budget
    ) => {
        const [userRole] = featuresManager.getCustomFilterState('role');
        const { a4PlanId, campaignRouterId, planStatus } = mediaPlan;
        const { startDate, endDate, hiatusWeeks } = schedule;

        const saveMediaPlanObject = getSaveMediaPlanObject({
                name,
                advertiser,
                a4PlanId,
                schedule,
                creativeUrls,
                productDetails,
                creativeProducts,
                excludedNetworks,
                formattedTvDetails,
                mapImageDataUrls,
                selectedLocations,
                audience,
                households,
                campaignRouterId: campaignRouterId
            }),
            bookPlanObject = linearTv
                ? getBookPlanObject({
                      campaignRouterId,
                      name,
                      advertiser,
                      showPayNow,
                      total
                  })
                : null,
            mediaPlanBookObject = getMediaPlanObject({
                name,
                audiences,
                a4PlanId,
                advertiser,
                email,
                userRole,
                region,
                selectedLocations,
                budget,
                hiatusWeeks,
                startDate,
                endDate,
                showPayNow,
                combinations,
                mediaPlan,
                vennPngDataUrl,
                firstName,
                lastName
            });

        return bookPlanObject
            ? {
                  saveMediaPlanObject,
                  bookPlanObject,
                  mediaPlanBookObject,
                  planStatus
              }
            : {
                  saveMediaPlanObject,
                  mediaPlanBookObject,
                  planStatus
              };
    }
);
