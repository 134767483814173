import { WARNING, ERROR } from '@config';

export const maintenanceModeConfig = {
    type: WARNING,
    text: 'Platform maintenance is in effect.',
    details: {
        errorMessage:
            'Zeus and the rest of the gods are making some improvements throughout Athena. As these are in progress, you may experience unusual behavior at this time. Please come back in ~1 hour for your new Athena experience!'
    }
};
