import React from 'react';
import styles from '../styles.scss';
import { Text, Layout, Icon } from 'maslow';
import plusSvg from '@public/img/UI-Kit-Plus.svg';
import PropTypes from 'prop-types';

export const getClassNames = (styles, audiences) => {
    const positionClass = audiences.length >= 2 ? 'circle3' : 'circle2';
    return [styles.circle, styles.new, styles[positionClass]].join(' ');
};

export const isVisible = audiences => {
    return audiences.length < 3 && !(audiences[1] && !audiences[1].length);
};

const AddNew = props => {
    if (!isVisible(props.audiences)) return null;

    const classNames = getClassNames(styles, props.audiences);

    return (
        <Layout
            className={classNames}
            onClick={props.addAudience}
            flexDirection="column"
            vAlign="center"
            hAlign="center">
            <Icon src={plusSvg} size="2" />

            <Text type="button">Add</Text>
        </Layout>
    );
};

AddNew.propTypes = {
    audiences: PropTypes.array.isRequired,
    addAudience: PropTypes.func.isRequired
};

export default AddNew;
