import { createReducer } from '@reduxjs/toolkit';
import {
    ADD_EXCLUDED_DAYPART,
    REMOVE_EXCLUDED_DAYPART,
    REMOVE_ALL_EXCLUDED_DAYPARTS
} from '../constants';

const initialState = [];

const addExcludedDaypart = (state, action) => {
    const { excludedDaypart } = action.payload;
    return [...state, excludedDaypart];
};

const removeExcludedDaypart = (state, action) => {
    const { payload } = action;
    return state.filter(option => option.value !== payload);
};

const removeAllExcludedDayparts = () => {
    return [];
};
export const excludedDayparts = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_EXCLUDED_DAYPART, addExcludedDaypart)
        .addCase(REMOVE_EXCLUDED_DAYPART, removeExcludedDaypart)
        .addCase(REMOVE_ALL_EXCLUDED_DAYPARTS, removeAllExcludedDayparts)
        .addDefaultCase((state, _) => state);
});
