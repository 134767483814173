import React, { useEffect } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'maslow';
import { isEmptyString } from '@lib';

import styles from './styles.scss';

import {
    SIGNUP_PASSWORD_INPUT,
    SIGNUP_PASSWORD_CONFIRM
} from '@config/idConstants';

const Passwords = ({
    updateStateValue,
    password1,
    password2,
    password1Status,
    password2Status,
    password1Error,
    password2Error,
    STATUSES,
    INTERVAL_TIME,
    errors,
    ls
}) => {
    const checkIfPasswordsMatch = () => {
        if (password1 !== password2) {
            updateStateValue('password2Status', STATUSES.ERROR);
            updateStateValue('password2Error', errors.passwordMatchError);
            updateStateValue('passwordsMatched', false);
        } else {
            updateStateValue('password2Status', STATUSES.VALID);
            updateStateValue('passwordsMatched', true);
        }
    };

    const setPassword2Status = () => {
        if (isEmptyString(password2)) {
            updateStateValue('password2Status', STATUSES.IDLE);
            updateStateValue('passwordsMatched', false);
        } else {
            checkIfPasswordsMatch();
        }
    };

    useEffect(() => {
        setPassword2Status();
    }, [password1, password2]);

    const setPassword1Status = (value, hasValidPassword) => {
        if (isEmptyString(value)) {
            updateStateValue('password1Status', STATUSES.IDLE);
        } else if (hasValidPassword) {
            updateStateValue('password1Status', STATUSES.VALID);
        } else {
            updateStateValue('password1Status', STATUSES.ERROR);
            updateStateValue('password1Error', errors.passwordError);
        }
    };

    const password1DoneTyping = value => {
        updateStateValue('password1', value);
        const hasValidPassword = ls.hasValidPassword(value);
        setPassword1Status(value, hasValidPassword);
    };

    const password2DoneTyping = value => {
        updateStateValue('password2', value);
    };

    return (
        <React.Fragment>
            <div className={styles.topSpace}>
                <Input
                    id={SIGNUP_PASSWORD_INPUT}
                    name="password"
                    theme="smallLine"
                    placeholder="Password"
                    type="password"
                    status={password1Status}
                    errorText={password1Error}
                    doneTypingInterval={INTERVAL_TIME}
                    doneTyping={password1DoneTyping}
                />
            </div>

            <div className={styles.topSpace}>
                <Input
                    id={SIGNUP_PASSWORD_CONFIRM}
                    name="confirm"
                    theme="smallLine"
                    placeholder="Confirm Your Password"
                    type="password"
                    status={password2Status}
                    errorText={password2Error}
                    doneTypingInterval={INTERVAL_TIME}
                    doneTyping={password2DoneTyping}
                />
            </div>
        </React.Fragment>
    );
};

Passwords.propTypes = {
    updateStateValue: PropTypes.func.isRequired,
    STATUSES: PropTypes.object.isRequired,
    ls: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    password1: PropTypes.string.isRequired,
    password2: PropTypes.string.isRequired,
    password1Status: PropTypes.string.isRequired,
    password2Status: PropTypes.string.isRequired
};

export default Passwords;
