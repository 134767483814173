export const geos = {
    State: 'States',
    PoliticalState: 'States',
    DMA: 'DMA',
    Zipcode: 'ZIP',
    USCongressionalDistrict: 'US Congressional Districts',
    StateHouseDistrict: 'State House District',
    County: 'County',
    StateLegislativeDistrict: 'State Legislative District',
    StateSenateDistrict: 'State Senate District',
    City: 'Cities'
};

export const categories = {
    STATE: 'State',
    ZIP: 'Zipcode',
    DMA: 'DMA',
    COUNTY: 'County',
    CITY: 'City',
    US_CONGRESS: 'USCongressionalDistrict',
    STATE_HOUSE: 'StateHouseDistrict',
    STATE_LEGISLATIVE: 'StateLegislativeDistrict',
    STATE_SENATE: 'StateSenateDistrict',
    DISTRICT: 'District'
};

export const segmentTypes = {
    SW: 'STATES',
    CO: 'COUNTY',
    DMA: 'DMAS',
    CD: 'CONGRESSIONAL',
    SH: 'STATE HOUSE',
    SS: 'STATE SENATE',
    ZIP: 'ZIP',
    SL: 'LEGISLATIVE',
    CITY: 'CITIES'
};
