import { datesHandler } from '@lib';
import { ALL, SUBMITTED, DRAFT, COMPLETED } from '@config';

const DEFAULT_NAME = 'Plan Default Name';

const defaultName = name => {
    return name.indexOf(DEFAULT_NAME) !== -1;
};

export const filterPlans = (array, selected) => {
    const rules = {
        [ALL]: plan => !defaultName(plan.name),
        [DRAFT]: plan => !plan.bookedDate && !defaultName(plan.name),
        [SUBMITTED]: (plan, pastEndDate) => plan.bookedDate && !pastEndDate,
        [COMPLETED]: (plan, pastEndDate) =>
            plan.bookedDate && pastEndDate && !defaultName(plan.name)
    };

    return array.filter(plan => {
        const pastEndDate =
            datesHandler.differenceInDays(new Date(), new Date(plan.endDate)) >
            0;

        return rules[selected](plan, pastEndDate);
    });
};
