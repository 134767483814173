import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Layout, Text, Card, Content } from 'maslow';

import {
    mappedDigitalDetailsForUiSelector,
    totalDigitalBudgetSelector,
    totalDigitalImpressionSelector,
    totalDigitalCpmSelector
} from '@selectors';
import { setIsMediaPlanVisited } from '@state/ui/actions';
import { budgetConfig } from '@config/config.budget';
import { strf, isGreaterThanZero } from '@lib';
import { getFormattedCpm } from '@services/budget';
import Channel from './Channel';

import styles from './digitalDetails.scss';

const getStyle = (channel, configChannels) => {
    const styles = {};
    configChannels.forEach(configChannel => {
        if (channel.name === configChannel.name) {
            styles.style = configChannel.style;
            styles.icon = configChannel.icon;
        }
    });

    return styles;
};

const DigitalDetails = () => {
    const dispatch = useDispatch();
    const { distribution } = budgetConfig;
    const mappedDigitalDetailsForUi = useSelector(
        mappedDigitalDetailsForUiSelector
    );
    const totalDigitalBudget = useSelector(totalDigitalBudgetSelector);
    const totalDigitalBudgetFormatted = strf(totalDigitalBudget)
        .commas()
        .prepend('$')
        .value();
    const totalDigitalImpression = useSelector(totalDigitalImpressionSelector);
    const totalDigitalImpressionFormatted = strf(totalDigitalImpression)
        .commas()
        .value();
    const totalDigitalCpm = useSelector(totalDigitalCpmSelector);
    const totalDigitalCpmFormatted = getFormattedCpm(totalDigitalCpm);
    const hasTotalDigitalBudget = isGreaterThanZero(totalDigitalBudget);

    const buildChannelsImpression = (channel, i) => {
        const styles = getStyle(channel, distribution.channels);
        return (
            <Channel
                key={i}
                channel={channel}
                digitalImpression={totalDigitalImpression}
                style={styles.style}
                icon={styles.icon}
            />
        );
    };

    const impressionDistribution = mappedDigitalDetailsForUi.map(
        buildChannelsImpression
    );
    useEffect(() => {
        dispatch(setIsMediaPlanVisited(true));
    });

    return (
        hasTotalDigitalBudget && (
            <Layout flexDirection="column" className={styles.wrapper}>
                <Layout flexDirection="row" hAlign="center">
                    <Text type="h3" className={styles.title}>
                        Digital Details
                    </Text>
                </Layout>

                <Layout flexDirection="column">
                    <Card borderColor="deepBlue" borderWidth="3px">
                        <Content fillParent>
                            <Layout flexDirection="row" hAlign="space-around">
                                <Layout flexDirection="column" hAlign="center">
                                    <Text type="h4">
                                        {totalDigitalBudgetFormatted}
                                    </Text>
                                    <Text type="h6" color="deepBlue">
                                        BUDGET
                                    </Text>
                                </Layout>
                                <Layout flexDirection="column" hAlign="center">
                                    <Text type="h4">
                                        {totalDigitalImpressionFormatted}
                                    </Text>
                                    <Text type="h6" color="deepBlue">
                                        IMPRESSIONS
                                    </Text>
                                </Layout>

                                <Layout flexDirection="column" hAlign="center">
                                    <Text type="h4">
                                        {totalDigitalCpmFormatted}
                                    </Text>
                                    <Text type="h6" color="deepBlue">
                                        CPM
                                    </Text>
                                </Layout>
                            </Layout>
                        </Content>
                    </Card>
                </Layout>

                <Content className={styles.contentPadding}>
                    <Text type="h4">Digital Distribution</Text>
                    <Text type="h5"> By Impressions</Text>
                </Content>

                {impressionDistribution}
            </Layout>
        )
    );
};

export default DigitalDetails;
