import request from '../request';

export default function ({
    file,
    a4PlanId,
    name,
    startDate,
    endDate,
    size,
    width,
    height
}) {
    const formData = new FormData();

    const headers = {
        'Content-Type': `multipart/form-data; `
    };

    formData.append('file', file);
    formData.append('MediaPlanId', a4PlanId);
    formData.append('Name', name);
    formData.append('BeginDate', startDate);
    formData.append('EndDate', endDate);
    formData.append('Size', size);
    formData.append('Width', width);
    formData.append('Height', height);

    const req = {
        method: `POST`,
        url: `creatives/`,
        headers,
        data: formData
    };

    return request(req).catch(console.error);
}
