import {
    addDays,
    addMonths,
    addBusinessDays,
    differenceInDays,
    differenceInMonths,
    differenceInYears,
    differenceInWeeks,
    endOfToday,
    eachDayOfInterval,
    eachWeekOfInterval,
    differenceInBusinessDays,
    differenceInCalendarDays,
    subDays,
    subBusinessDays,
    isSameDay,
    isWeekend,
    isToday,
    add,
    sub,
    isDate,
    toDate,
    startOfTomorrow,
    endOfTomorrow,
    isTomorrow,
    startOfDay,
    startOfMonth,
    endOfMonth,
    getISODay,
    getYear,
    getMonth,
    getDay,
    getDate,
    set,
    parse,
    parseISO,
    format,
    isWithinInterval,
    getISOWeek
} from 'date-fns';

// These functions operate with dates in user's local timezone (NON UTC)
export const datesHandler = {
    newDate(toAdd = 0, type = 'days') {
        const date = add(new Date(), { [type]: toAdd });
        return set(date, {
            hours: 12,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        });
    },
    newDateStatic(date) {
        return toDate(date);
    },
    parseISO(date) {
        return parseISO(date);
    },
    addToDate(toDate, toAdd, type = 'days') {
        const date = add(new Date(toDate), { [type]: toAdd });
        return set(date, {
            hours: 12,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        });
    },
    subFromDate(toDate, toSub, type = 'days') {
        const date = sub(new Date(toDate), { [type]: toSub });
        return set(date, {
            hours: 12,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        });
    },
    format(date, formatter = 'yyyy-MM-dd') {
        return format(new Date(date), formatter);
    },
    formatBooking(date) {
        return format(new Date(date), 'LLL do, yyyy');
    },

    formatString(string, from, formatter = 'ha') {
        const parsedDate = parse(string, from, new Date());
        return format(parsedDate, formatter).toLowerCase();
    },
    businessAddToDate(toDate, toAdd) {
        const date = addBusinessDays(new Date(toDate), toAdd);
        return set(date, {
            hours: 12,
            minutes: 0,
            seconds: 0,
            milliseconds: 0
        });
    },
    getYear(date) {
        return getYear(new Date(date));
    },
    getMonth(date) {
        return getMonth(new Date(date));
    },
    getDay(date) {
        return getDay(new Date(date));
    },
    getDate(date) {
        return getDate(new Date(date));
    },
    isWithinInterval(date, from, to) {
        return isWithinInterval(new Date(date), {
            start: new Date(from),
            end: new Date(to)
        });
    },
    getWeekDayNumber(date) {
        return getISODay(new Date(date));
    },
    getWeekNumber(date) {
        return getISOWeek(new Date(date));
    },
    getEndOfToday() {
        return endOfToday();
    },
    eachDayOfInterval({ start, end }) {
        return eachDayOfInterval({ start, end });
    },
    eachWeekOfInterval({ start, end }) {
        return eachWeekOfInterval({ start, end });
    },
    addDays(date, amount) {
        return addDays(date, amount);
    },
    addBusinessDays(date, amount) {
        return addBusinessDays(date, amount);
    },
    addMonths(date, amount) {
        return addMonths(date, amount);
    },
    differenceInDays(from, to) {
        return differenceInDays(new Date(from), new Date(to));
    },
    differenceInCalendarDays(from, to) {
        return differenceInCalendarDays(new Date(from), new Date(to));
    },
    differenceInWeeks(from, to) {
        return differenceInWeeks(new Date(from), new Date(to));
    },
    differenceInMonths(from, to) {
        return differenceInMonths(new Date(from), new Date(to));
    },
    differenceInYears(from, to) {
        return differenceInYears(new Date(from), new Date(to));
    },
    differenceInBusinessDays(from, to) {
        return differenceInBusinessDays(new Date(from), new Date(to));
    },
    subDays(from, amount) {
        return subDays(from, amount);
    },
    subBusinessDays(from, amount) {
        return subBusinessDays(from, amount);
    },
    isSameDay(date1, date2) {
        return isSameDay(date1, date2);
    },
    isWeekend(date) {
        return isWeekend(date);
    },
    isToday(date) {
        return isToday(date);
    },
    add(from, obj = { days: 0 }) {
        return add(from, obj);
    },
    sub(from, obj = { days: 0 }) {
        return sub(from, obj);
    },
    isDate(date) {
        return isDate(date);
    },
    toDate(date) {
        return toDate(date);
    },
    startOfTomorrow() {
        return startOfTomorrow();
    },
    endOfTomorrow() {
        return endOfTomorrow();
    },
    isTomorrow(date) {
        return isTomorrow(new Date(date));
    },
    startOfDay(date) {
        return startOfDay(new Date(date));
    },
    startOfMonth(date) {
        return startOfMonth(new Date(date));
    },
    endOfMonth(date) {
        return endOfMonth(new Date(date));
    }
};
