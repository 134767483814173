// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".extraSpacing___HC3fN {\n  margin-bottom: 8px; }\n\n.inputWrapper___3f_Jy {\n  margin-bottom: 16px; }\n\n.pointerCursor___3zaQL {\n  cursor: pointer; }\n\n.sendLinkBtnWrapper___j4txB {\n  margin: 24px 0; }\n\n.iconWrapper___5s7W4 {\n  margin: 24px 0 32px 0; }\n\n.confirmationText___2B-V8 {\n  text-align: center;\n  margin-bottom: 40px; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/features/Login/1.3.0/login.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAGA;EACI,kBCJe,EAAA;;ADOnB;EACI,mBCPyB,EAAA;;ADU7B;EACI,eAAe,EAAA;;AAGnB;EACI,cAAsB,EAAA;;AAG1B;EACI,qBAAqC,EAAA;;AAGzC;EACI,kBAAkB;EAClB,mBAAmB,EAAA","file":"login.scss","sourcesContent":["@import '~@scss/settings/colors';\n@import '~@scss/settings/baseline';\n\n.extraSpacing {\n    margin-bottom: $baseline;\n}\n\n.inputWrapper {\n    margin-bottom: $baseline-x2;\n}\n\n.pointerCursor {\n    cursor: pointer;\n}\n\n.sendLinkBtnWrapper {\n    margin: $baseline-x3 0;\n}\n\n.iconWrapper {\n    margin: $baseline-x3 0 $baseline-x4 0;\n}\n\n.confirmationText {\n    text-align: center;\n    margin-bottom: 40px;\n}","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"extraSpacing": "extraSpacing___HC3fN",
	"inputWrapper": "inputWrapper___3f_Jy",
	"pointerCursor": "pointerCursor___3zaQL",
	"sendLinkBtnWrapper": "sendLinkBtnWrapper___j4txB",
	"iconWrapper": "iconWrapper___5s7W4",
	"confirmationText": "confirmationText___2B-V8"
};
module.exports = exports;
