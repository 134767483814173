import { createReducer } from '@reduxjs/toolkit';
import {
    SET_AD_MESSENGER,
    SET_DESTINATION,
    SET_FACEBOOK,
    SET_INSTAGRAM
} from '../constants';

const initialState = {
    isValid: false,
    url: ''
};

const setAdMessenger = (state, action) => {
    const { adMessenger } = action.payload;
    if (typeof adMessenger === 'string') {
        return { ...state, ...{ isValid: true, url: adMessenger } };
    } else {
        return typeof adMessenger === 'object' ? adMessenger : state;
    }
};

const setDestination = (state, action) => {
    const { destination } = action.payload;
    if (typeof destination === 'string') {
        return { ...state, ...{ isValid: true, url: destination } };
    } else {
        return typeof destination === 'object' ? destination : state;
    }
};

const setFacebook = (state, action) => {
    const { facebook } = action.payload;
    if (typeof facebook === 'string') {
        return { ...state, ...{ isValid: true, url: facebook } };
    } else {
        return typeof facebook === 'object' ? facebook : state;
    }
};

const setInstagram = (state, action) => {
    const { instagram } = action.payload;
    if (typeof instagram === 'string') {
        return { ...state, ...{ isValid: true, url: instagram } };
    } else {
        return typeof instagram === 'object' ? instagram : state;
    }
};

export const adMessenger = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_AD_MESSENGER, setAdMessenger)
        .addDefaultCase((state, _) => state);
});

export const destination = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_DESTINATION, setDestination)
        .addDefaultCase((state, _) => state);
});

export const facebook = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_FACEBOOK, setFacebook)
        .addDefaultCase((state, _) => state);
});

export const instagram = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_INSTAGRAM, setInstagram)
        .addDefaultCase((state, _) => state);
});
