import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { Content, Card, Text, Layout } from 'maslow';
import AudienceNavButton from './AudienceNavButton';
import { scrollToElement, slugBuilder } from '@services';

import styles from './index.scss';
import { capitalizeFirstLetters } from '@lib/';
import { VIEWERSHIP } from '@config/';
import { hasPermissionSelector } from '@selectors/';

const AudienceHeader = ({
    activeSection,
    currentAudienceIndex,
    audienceConfig,
    onSectionChange,
    boxShadow,
    boxShrink,
    marginShrink
}) => {
    const isShowViewershipTab = useSelector(
        hasPermissionSelector('mediaplan.useviewership')
    );
    const setActiveClassName = name =>
        name === activeSection ? styles.activeSectionNavButton : '';

    const onSectionClick = sectionName => {
        onSectionChange(sectionName);

        setTimeout(() => {
            scrollToElement(
                'audience-scroll-container',
                slugBuilder(sectionName)
            );
        });
    };

    //here temporarily
    const _viewershipConfig = [
        {
            name: 'Viewership',
            value: '5',
            options: []
        }
    ];
    const _audienceConfig = isShowViewershipTab
        ? [...audienceConfig, ..._viewershipConfig]
        : audienceConfig;

    const setHeaderWrapperStyling = activeSection => {
        return activeSection === capitalizeFirstLetters(VIEWERSHIP)
            ? styles.headerWrapperViewership
            : styles.headerWrapper;
    };
    return (
        <Content className={setHeaderWrapperStyling(activeSection)}>
            <div className={styles.audienceHeadingTextBox} style={boxShrink}>
                <Text className={styles.audienceHeading}>
                    Audience {currentAudienceIndex + 1}
                </Text>
            </div>
            <Card
                className={styles.audienceSectionNavCard}
                style={marginShrink}>
                <Layout
                    flexDirection="row"
                    hAlign="center"
                    className={styles.audienceSectionNav}
                    style={boxShadow}>
                    {_audienceConfig.map(section => (
                        <AudienceNavButton
                            sectionName={section.name}
                            setActiveClassName={setActiveClassName}
                            key={section.value}
                            onSectionClick={onSectionClick}
                            sectionValue={section.value}
                        />
                    ))}
                </Layout>
            </Card>
        </Content>
    );
};

AudienceHeader.propTypes = {
    activeSection: PropTypes.string.isRequired,
    currentAudienceIndex: PropTypes.number.isRequired,
    audienceConfig: PropTypes.array.isRequired,
    onSectionChange: PropTypes.func.isRequired
};

export default AudienceHeader;
