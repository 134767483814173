import { createReducer } from '@reduxjs/toolkit';
import { SET_IS_ON_BOOK_PAGE } from '../constants';

const initialState = false;

const setIsOnBookPage = (state, action) => {
    const { isOnBookPage } = action.payload;
    return typeof isOnBookPage === 'boolean' ? isOnBookPage : state;
};

export const isOnBookPage = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_IS_ON_BOOK_PAGE, setIsOnBookPage)
        .addDefaultCase((state, _) => state);
});
