import { createReducer } from '@reduxjs/toolkit';
import { ADD_TOAST, REMOVE_TOAST, REMOVE_ALL_TOASTS } from '../constants';
import { WARNING } from '@config';
// Example toast object

// {
//     type: WARNING,
//     text: 'This is a test.',
//     details: {
//             errorMessage:
//                 "Some toast message text",
//             *optional routeTo: 'app.plan.schedule',
//             *optional routeText: 'Schedule'
//         }
// };
const initialState = [];

const toastExists = (state, messageText, toastType) => {
    return (
        state.some(s => s.text === messageText) &&
        state.some(s => s.type === toastType)
    );
};

const addToast = (state, action) => {
    const {
        type = WARNING,
        text = 'No title provided',
        details = {},
        ...rest
    } = action.payload.message;

    if (toastExists(state, text, type)) {
        return state;
    } else {
        return [...state, { type, text, details, ...rest }];
    }
};

const removeToast = (state, action) => {
    const { id } = action.payload;
    return typeof id === 'string'
        ? state.filter(toast => toast.id !== id)
        : state;
};

const removeAllToasts = () => {
    return initialState;
};

export const toasts = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_TOAST, addToast)
        .addCase(REMOVE_TOAST, removeToast)
        .addCase(REMOVE_ALL_TOASTS, removeAllToasts)
        .addDefaultCase((state, _) => state);
});
