import React from 'react';
import { SelectedNetworks } from './SelectedNetworks';
import styles from './styles.scss';

export default function (version, feature) {
    const { selectedAudienceStyles } = feature.data;
    const NetworksSummarySelections = () => {
        return (
            <div className={styles.wrapper}>
                <SelectedNetworks />
            </div>
        );
    };

    return NetworksSummarySelections;
}
