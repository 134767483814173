import { createReducer } from '@reduxjs/toolkit';
import { SET_DURATION } from '../constants';

const initialState = 0;

const setDuration = (state, action) => {
    const { duration } = action.payload;
    return typeof duration === 'number' ? duration : state;
};
export const duration = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_DURATION, setDuration)
        .addDefaultCase((state, _) => state);
});
