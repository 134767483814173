import { isObject } from '@lib';

export function findAllByKey(obj, keyToFind) {
    if (!obj && !isObject(obj)) return;

    return Object.entries(obj).reduce(
        (acc, [key, value]) =>
            key === keyToFind
                ? acc.concat(value)
                : typeof value === 'object'
                ? acc.concat(findAllByKey(value, keyToFind))
                : acc,
        []
    );
}
