import { createSelector } from 'reselect';
import { PLAN_STATUSES } from '@config/config.planStatus';
import { pastLeadDateSelector } from '../schedule';
import { planSelector } from '../plan';
import {
    getBudgetByProduct,
    budgetConfigSelector,
    totalSelector,
    budgetSelector,
    bookedSelector
} from '@selectors';
import { LINEAR_TV } from '@config';
import { selectedLocationsSelector } from '@selectors/location';
import { getPendoMetaData, getFormattedTvDetails } from '@services';
import { uiSelector } from '@selectors/ui';
import {
    mapDigitalDetailsForUi,
    getTotalDigitalBudget,
    getTotalDigitalImpression,
    getRoundedCpm,
    getDigitalProductDetails,
    getProductDetails
} from '@services/mediaPlan';

export const mediaPlanSelector = createSelector(
    state => state.mediaPlan,
    mediaPlan => mediaPlan
);

export const a4PlanIdSelector = createSelector(
    mediaPlanSelector,
    ({ a4PlanId }) => a4PlanId
);

export const campaignRouterIdSelector = createSelector(
    mediaPlanSelector,
    ({ campaignRouterId }) => campaignRouterId
);

export const planSaveStatusSelector = createSelector(
    mediaPlanSelector,
    ({ planStatus }) =>
        planStatus === PLAN_STATUSES.SAVED_DRAFT ||
        planStatus === PLAN_STATUSES.BOOKED
            ? PLAN_STATUSES.VIEW_PLAN_LIST
            : PLAN_STATUSES.INITIAL
);

export const errorAlertSelector = createSelector(
    pastLeadDateSelector,
    planSelector,
    (isPastLeadDate, { attributes }) => isPastLeadDate && !attributes.booked
);

export const primaryTargetDemoNameSelector = createSelector(
    mediaPlanSelector,
    ({ tvDetails }) => tvDetails?.primaryTarget?.primaryDemoName
);

export const planGeneratedSelector = createSelector(
    a4PlanIdSelector,
    uiSelector,
    (a4PlanId, { isMediaPlanVisited }) => {
        return a4PlanId && isMediaPlanVisited;
    }
);
export const getPendoMetaDataSelector = status =>
    createSelector(
        state => state,
        selectedLocationsSelector,
        totalImpressionSelector,
        totalCpmSelector,
        (state, locations, totalImpression, totalCpm) =>
            getPendoMetaData(
                state,
                locations,
                status,
                totalImpression,
                totalCpm
            )
    );

export const formattedTvDetailsSelector = createSelector(
    mediaPlanSelector,
    ({ tvDetails }) => getFormattedTvDetails(tvDetails)
);

export const mappedDigitalDetailsForUiSelector = createSelector(
    budgetConfigSelector,
    bookedSelector,
    mediaPlanSelector,
    ({ subCampaigns, channels }, booked, { submittedDigitalDetails }) =>
        mapDigitalDetailsForUi(
            channels,
            subCampaigns,
            booked,
            submittedDigitalDetails
        )
);

export const totalDigitalBudgetSelector = createSelector(
    budgetConfigSelector,
    ({ subCampaigns }) => getTotalDigitalBudget(subCampaigns)
);

export const totalDigitalImpressionSelector = createSelector(
    mappedDigitalDetailsForUiSelector,
    mappedDigitalDetailsForUi =>
        getTotalDigitalImpression(mappedDigitalDetailsForUi)
);

export const totalDigitalCpmSelector = createSelector(
    totalDigitalBudgetSelector,
    totalDigitalImpressionSelector,
    (totalDigitalBudget, totalDigitalImpression) =>
        getRoundedCpm(totalDigitalBudget, totalDigitalImpression)
);

export const totalImpressionSelector = createSelector(
    totalDigitalImpressionSelector,
    totalDigitalImpression => {
        return totalDigitalImpression;
    }
);

export const totalCpmSelector = createSelector(
    totalSelector,
    totalImpressionSelector,
    (totalBudget, totalImpression) =>
        getRoundedCpm(totalBudget, totalImpression)
);

export const digitalProductDetailsSelector = createSelector(
    mappedDigitalDetailsForUiSelector,
    mappedDigitalProducts => getDigitalProductDetails(mappedDigitalProducts)
);

export const productDetailsSelector = createSelector(
    budgetSelector,
    digitalProductDetailsSelector,
    mediaPlanSelector,
    (
        { subCampaigns },
        digitalProductDetails,
        { tvDetails: { tvImpressions, primaryTarget } }
    ) =>
        getProductDetails(
            subCampaigns,
            digitalProductDetails,
            tvImpressions || 0,
            primaryTarget ? primaryTarget.cpm || 0 : 0
        )
);
