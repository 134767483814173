export const impressionService = (budget = 1, cpm = 1) => {
    try {
        const result = (budget / cpm) * 1000;
        const validNum = _checkIfValidNumber(result) || 0;
        const finiteNum = _checkIfFinite(validNum) || 0;
        return finiteNum;
    } catch (err) {
        throw Error(`Error encountered during processing: ${err}`);
    }
};

const _checkIfFinite = val => {
    if (Number.isFinite(val)) return val;
};

const _checkIfValidNumber = val => {
    if (Number.parseFloat(val) || Number.parseInt(val)) {
        return val;
    }
};
