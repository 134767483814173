import axios from 'axios';
import { hhCountLimit } from '@config/config.households';

let currentRequestCount = 0;
let currentRequestCountLocation = 0;
let requestRunning = false;

export default function (payload, source, location = false) {
    const { min } = hhCountLimit;
    const req = {
        method: 'POST',
        url: '/api/households/count/',
        data: {
            payload,
            min
        },
        requestCount: location
            ? ++currentRequestCountLocation
            : ++currentRequestCount
    };

    if (source) req.cancelToken = source.token;

    const axiosInstance = axios.create();
    axiosInstance.interceptors.request.use(
        config =>
            new Promise((resolve, reject) => {
                let interval = setInterval(() => {
                    if (
                        config.requestCount <
                        (location
                            ? currentRequestCountLocation
                            : currentRequestCount)
                    ) {
                        clearInterval(interval);
                        reject(new Error('Cancel'));
                        return;
                    }
                    if (!requestRunning) {
                        requestRunning = true;
                        clearInterval(interval);
                        resolve(config);
                    }
                }, 200);
            })
    );
    axiosInstance.interceptors.response.use(
        response => {
            requestRunning = false;
            if (
                response.config.requestCount <
                (location ? currentRequestCountLocation : currentRequestCount)
            ) {
                return Promise.reject(new Error('Expired'));
            } else {
                return Promise.resolve(response);
            }
        },
        error => {
            requestRunning = false;
            error.message = 'Cancel';
            return Promise.reject(error);
        }
    );

    axiosInstance.defaults.timeout = 240000;

    return new Promise((resolve, reject) => {
        axiosInstance(req)
            .then(resolve)
            .catch(error => {
                if (error.message !== 'Expired' && error.message !== 'Cancel') {
                    return reject(error);
                }
            });
    });
}
