import React from 'react';
import ReactDOM from 'react-dom';
import Icon from '../components/Icon';

// all svgs that are being loaded via this tool

export default async function loadSvg(item) {
    const target = document.querySelector(item.target);

    if (!target) return;

    function runAfterSvgLoads(item) {
        if (item.after && typeof item.after === 'function') item.after();
    }

    if (item.before && typeof item.before === 'function') item.before();

    ReactDOM.render(
        <Icon src={item.src} onSvgLoad={() => runAfterSvgLoads(item)} />,
        target
    );
}
