import * as d3 from 'd3';

export const createCurve = (start, end, radius = 2) => {
    const line = d3
        .line()
        .x(d => d[0])
        .y(d => d[1])
        .curve(d3.curveBundle.beta(1));

    const points = [
        start,
        [start[0], end[1] - radius],
        [start[0] + radius, end[1]],
        end
    ];

    return line(points);
};
