import { createAction } from '@reduxjs/toolkit';
import { SET_IS_PLAN_OPENED } from '../constants';

export const setIsPlanOpened = createAction(
    SET_IS_PLAN_OPENED,
    isPlanOpened => {
        return {
            payload: {
                isPlanOpened
            }
        };
    }
);
