export default {
    name: 'AudienceSummary',
    description: 'Audience Option Selections Summary',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan', 'ranker'],
    sections: ['leftSidebar'],
    permissions: [],
    data: {
        defaultHouseholdTitle: 'Currently targeting all households',
        defaultHouseholdDescription:
            'Or make a selection to build your audience.',
        maxAudienceNameLimit: 50,
        maxDescriptionLimit: 100,
        characterErrorLimit: 10
    },
    attributes: {
        test: ''
    },
    modifications: {}
};
