import { createReducer } from '@reduxjs/toolkit';
import { SET_IS_PLAN_OPENED } from '../constants';

const initialState = false;

const setIsPlanOpened = (state, action) => {
    const { isPlanOpened } = action.payload;
    return typeof isPlanOpened === 'boolean' ? isPlanOpened : state;
};

export const isPlanOpened = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_IS_PLAN_OPENED, setIsPlanOpened)
        .addDefaultCase((state, _) => state);
});
