import request from '../request';

export default function(planId) {
    const req = {
        method: 'GET',
        url: `reports/data/${planId}/`
    };

    return request(req);
}
