import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { getFeatures, withFeatures } from '@delivery/react';
import styles from './styles.scss';
import Summary from './Summary';
import AudienceSavePrompt from './AudienceSavePrompt';
import { isTargetingEveryone } from '@services/audiencesService';
import { audienceSelector } from '@selectors/';

export default function (version, feature) {
    const {
        defaultHouseholdTitle,
        defaultHouseholdDescription,
        maxAudienceNameLimit,
        maxDescriptionLimit,
        characterErrorLimit
    } = feature.data;

    const AudienceSummary = props => {
        const [isPopupActive, setIsPopupActive] = useState(false);
        const { components } = getFeatures('audienceSummary', { props });
        const { audiences, isAudienceModified } = useSelector(audienceSelector);

        const getDisableState = () => {
            return (
                audiences.length !== 1 ||
                isTargetingEveryone(audiences) ||
                !isAudienceModified
            );
        };

        const openSaveAudiencePopup = () => {
            const buttonState = !getDisableState();
            setIsPopupActive(buttonState);
        };

        const updateActiveState = value => {
            value ? setIsPopupActive(true) : setIsPopupActive(false);
        };

        return (
            <div className={styles.wrapper}>
                {components}

                <Summary
                    title={defaultHouseholdTitle}
                    description={defaultHouseholdDescription}
                    openSaveAudiencePopup={openSaveAudiencePopup}
                    getDisableState={getDisableState}
                />

                <div className={styles.wrapperPrompt}>
                    <AudienceSavePrompt
                        isPopupActive={isPopupActive}
                        updateActiveState={updateActiveState}
                        maxAudienceNameLimit={maxAudienceNameLimit}
                        maxDescriptionLimit={maxDescriptionLimit}
                        characterErrorLimit={characterErrorLimit}
                    />
                </div>
            </div>
        );
    };

    return withFeatures(AudienceSummary);
}
