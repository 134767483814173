import { UPLOAD_STEPS } from '@config/config.customList';

export const defaultCustomListShape = {
    id: undefined,
    description: '',
    file_name: '',
    name: '',
    num_rows: 0,
    num_matches: 0,
    match_percent: 0,
    received_matches: false,
    isFetchingCl: false,
    uploadStep: UPLOAD_STEPS.INITIAL,
    agreedToTerms: false
};
