import React, { useEffect, useMemo, useCallback, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import {
    setCustomList,
    resetCustomList,
    setClIsFetching,
    setUploadStep,
    setAgreedToTerms,
    setMatchPercent
} from '@state/customList/actions';
import { cloneShallow } from '@lib';
import requestHandlers from '@requestHandlers';
import {
    customListSelector,
    audienceSelector,
    regionSelector,
    accountSelector
} from '@selectors';
import { steps } from '@config/config.customList';

import { Content } from 'maslow';
import Upload from './CLUpload';
import AgreeToTerms from './CLAgreeToTerms';
import { actionRunner } from './CLActionRunner';
import style from './styles.scss';
import { UPLOAD_STEPS } from '@config/config.customList';
import { hhCountLimit } from '@config/config.households';
import featuresManager from '@featuresManager';
import { getSelectedAgeList } from '@services/audiencesService';
import { setBudgetDistribution } from '@services/budget';
import { sendAppInsightEvent } from '@services/';
import { CUSTOM_LIST_ERROR_DELETING_UPLOAD } from '@config/constants';

const STEPS = cloneShallow(steps);
const INTERVAL_TIME = 5000;

let cancelUpload = false;

export const CustomList = props => {
    const dispatch = useDispatch();
    const [role] = featuresManager.getCustomFilterState('role');
    const selectedAgesRef = useRef([]);
    const {
        received_matches,
        file_name,
        id,
        uploadStep,
        num_matches,
        num_rows,
        match_percent,
        agreedToTerms
    } = useSelector(customListSelector);
    const { id: userId } = useSelector(accountSelector);

    const region = useSelector(regionSelector);
    const { audiences } = useSelector(audienceSelector);

    let currentStep = STEPS[uploadStep];

    useEffect(() => {
        selectedAgesRef.current = getSelectedAgeList(audiences);
    }, [audiences]);

    useEffect(() => {
        if (id) {
            if (received_matches) {
                _finalizeMatchingProcess({ numOfMatches: num_matches });
            } else {
                _setStep(STEPS[UPLOAD_STEPS.MATCHING_IN_PROGRESS]);
            }
        } else {
            actionRunner.run(currentStep.action);
        }

        return () => {
            setBudgetDistribution(
                role,
                region,
                selectedAgesRef.current,
                dispatch
            );
        };
    }, []);

    useEffect(() => {
        let interval;

        if (id && !received_matches) {
            interval = setInterval(async () => {
                try {
                    const {
                        data
                    } = await requestHandlers.customList.getCustomList(id);

                    if (
                        data.received_matches &&
                        data.num_matches !== received_matches
                    ) {
                        dispatch(setCustomList(data));
                        _finalizeMatchingProcess({
                            num_matches: data.num_matches,
                            num_rows
                        });
                    }
                } catch (e) {
                    _updateAndSetStep(e);
                }
            }, INTERVAL_TIME);
        }
        if (received_matches && num_matches && num_rows) {
            dispatch(setMatchPercent());
        }

        return () => {
            interval && clearInterval(interval);
        };
    }, [id, received_matches]);

    const _finalizeMatchingProcess = ({ num_matches, num_rows }) => {
        actionRunner.run(currentStep.action);
        const args = {
            num_rows,
            num_matches
        };
        if (num_matches >= hhCountLimit.min)
            _setStep(STEPS[UPLOAD_STEPS.PROMPT_TO_SAVE], args);
        else if (num_matches < 5000)
            _setStep(STEPS[UPLOAD_STEPS.BELOW_THRESHOLD], args);
        else if (num_matches < 1000)
            _setStep(STEPS[UPLOAD_STEPS.BELOW_MATCHING_THRESHOLD], args);
        else if (num_matches === 0)
            _setStep(STEPS[UPLOAD_STEPS.ZERO_MATCHES], args);
        else _setStep(currentStep, args);
    };

    const _setStep = (step, args) => {
        if (id) dispatch(setClIsFetching(false));

        if (step && step.action) {
            dispatch(setUploadStep(step.name));
            return actionRunner.run(step.action, args);
        }
    };

    const uploadCustomList = async file => {
        if (id) return;

        const response = await requestHandlers.customList
            .upload(file[0], { userId })
            .catch(err => {
                _updateAndSetStep(err);
            });

        if (!cancelUpload) {
            dispatch(setCustomList(response.data));
            _setStep(STEPS[UPLOAD_STEPS.UPLOADING]);
            return _setStep(STEPS[UPLOAD_STEPS.MATCHING]);
        }
    };

    const onDrop = useCallback(
        file => {
            if (!file || !file.length) return;
            dispatch(setClIsFetching(true));
            _setStep(STEPS[UPLOAD_STEPS.PREUPLOADING]);
            cancelUpload = false;

            // delaying api call and step change to ensure the animation has completed
            return setTimeout(() => {
                uploadCustomList(file);
            }, 1000);
        },
        [file_name]
    );
    const _updateAndSetStep = error => {
        const errorStep = cloneShallow(STEPS[UPLOAD_STEPS.BELOW_THRESHOLD]);
        dispatch(setClIsFetching(false));

        errorStep.title = error?.data?.message || 'An error occured';
        errorStep.body = 'Select a file that has more than 5000 rows';

        _setStep(errorStep);
    };

    const onTermsAgree = event => {
        dispatch(setAgreedToTerms(event.target.checked));
    };

    const onReset = async () => {
        cancelUpload = true;
        actionRunner.a.stopAll();
        actionRunner.run(STEPS[UPLOAD_STEPS.INITIAL].action);
        dispatch(resetCustomList());
        deleteFile();
    };

    const deleteFile = async () => {
        if (id) {
            await requestHandlers.customList.deleteFile(id).catch(error => {
                sendAppInsightEvent(CUSTOM_LIST_ERROR_DELETING_UPLOAD, error);
                throw Error(error);
            });
        }
    };

    return (
        <Content style={props.fadeIn} className={style.wrapperContent}>
            <div className={style.whiteWrapper}>
                <div
                    className={
                        agreedToTerms ? style.hiddenTerms : style.hiddenUploader
                    }>
                    <div className={style.termsBox}>
                        <AgreeToTerms
                            onChange={onTermsAgree}
                            agreedToTerms={agreedToTerms}
                        />
                    </div>

                    <div className={style.clUploader}>
                        <Upload
                            onDrop={onDrop}
                            onReset={onReset}
                            currentStep={currentStep}
                            steps={STEPS}
                            match_percent={match_percent}
                        />
                    </div>
                </div>
            </div>
        </Content>
    );
};

export default CustomList;
