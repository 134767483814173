import * as d3 from 'd3';
import { GREY, FILL_COLORS } from '@services';

const { select } = d3;
const domUrl = window.URL || window.webkitURL || window;

const removeEmptyAudiences = audiences => {
    return audiences.filter(audience => audience.length > 0);
};

const prependStyle = (g, itemData, i) => {
    const path = g.children[0];
    const styleEl = document.createElement('style');
    const style = itemData.keepElement ? FILL_COLORS[itemData.ref] : GREY;

    const styles = [
        `#path_${i} path { fill: ${style}; }`,
        `.label { fill: ${GREY}; font-family: sans-serif; }`
    ].join(' ');

    styleEl.setAttribute('type', 'text/css');
    styleEl.innerHTML = styles;

    g.setAttribute('id', `path_${i}`);
    g.insertBefore(styleEl, path);
};

export const vennSvgToPng = (svg, audiences) => {
    return new Promise((resolve, reject) => {
        const formattedAudiencesLength =
            removeEmptyAudiences(audiences).length || 1;
        const maxIndex = formattedAudiencesLength - 1;
        const svgClone = select(svg).clone(true);
        const data = svgClone.data();

        svgClone.selectAll('g').each((d, i, n) => {
            const g = n[i];
            const item = data[0][i];
            const itemData = {
                ref: item.sets.join(' + '),
                keepElement: Math.max(...item.sets) <= maxIndex
            };

            !itemData.keepElement ? g.remove() : prependStyle(g, itemData, i);
        });

        const svgStr = new XMLSerializer().serializeToString(svgClone.node());
        const url = 'data:image/svg+xml;base64,' + window.btoa(svgStr);
        const canvas = document.createElement('canvas');
        const img = new Image();

        canvas.width = 150;
        canvas.height = formattedAudiencesLength > 2 ? 150 : 105;
        img.src = url;

        img.onload = () => {
            canvas.getContext('2d').drawImage(img, 0, 0);
            domUrl.revokeObjectURL(url);

            resolve(canvas.toDataURL('image/png'));
        };
    });
};

export const svgToPng = svg => {
    return new Promise((resolve, reject) => {
        const svgClone = select(svg).clone(true);
        const svgStr = new XMLSerializer().serializeToString(svgClone.node());
        const url = 'data:image/svg+xml;base64,' + window.btoa(svgStr);
        const canvas = document.createElement('canvas');
        const img = new Image();

        canvas.width = 15;
        canvas.height = 15;
        img.src = url;

        img.onload = () => {
            canvas.getContext('2d').drawImage(img, 0, 0);
            domUrl.revokeObjectURL(url);

            resolve(canvas.toDataURL('image/png'));
        };
    });
};
