import { createReducer } from '@reduxjs/toolkit';
import { SET_IS_BROADCAST } from '../constants';

const initialState = null;

const setIsBroadcast = (state, action) => {
    const { isBroadcast } = action.payload;
    return typeof isBroadcast === 'boolean' ? isBroadcast : state;
};

export const isBroadcast = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_IS_BROADCAST, setIsBroadcast)
        .addDefaultCase((state, _) => state);
});
