import { createAction } from '@reduxjs/toolkit';
import { SET_CURRENT_AUDIENCE_INDEX } from '../constants';

export const setCurrentAudienceIndex = createAction(
    SET_CURRENT_AUDIENCE_INDEX,
    audienceIndex => {
        return {
            payload: { audienceIndex }
        };
    }
);
