import { createAction } from '@reduxjs/toolkit';
import {
    UPDATE_ATTRIBUTES,
    UPDATE_REGION,
    UPDATE_PLAN_ID,
    SET_CAMPAIGN_TYPE,
    TOGGLE_HAS_ACCEPTED_TERMS,
    SET_PAYMENT_ERROR,
    REMOVE_PAYMENT_ERROR
} from '../constants';
import { REMOVE_ALL_EXCLUDED_DAYPARTS } from '../../schedule/constants';
import { LOCAL } from '@config';

export const updateAttributes = createAction(UPDATE_ATTRIBUTES, attributes => {
    return {
        payload: { attributes }
    };
});

export const removeAllExcludedDayparts = createAction(
    REMOVE_ALL_EXCLUDED_DAYPARTS
);

export const setRegion = (dispatch, region) =>
    dispatch({ type: UPDATE_REGION, payload: { region } });

export const updateRegion = region => {
    return (dispatch, getState) => {
        const {
            schedule: { excludedDayparts }
        } = getState();

        if (region === LOCAL && excludedDayparts.length) {
            dispatch(removeAllExcludedDayparts());
        }

        setRegion(dispatch, region);
    };
};

export const updatePlanId = createAction(UPDATE_PLAN_ID, planId => {
    return {
        payload: { planId }
    };
});

export const setCampaignType = createAction(SET_CAMPAIGN_TYPE, campaignType => {
    return {
        payload: { campaignType }
    };
});

export const toggleAcceptedTerms = createAction(TOGGLE_HAS_ACCEPTED_TERMS);

export const setPaymentError = createAction(SET_PAYMENT_ERROR, paymentError => {
    return {
        payload: { paymentError }
    };
});

export const removePaymentError = createAction(REMOVE_PAYMENT_ERROR);
