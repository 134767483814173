import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import { Content } from 'maslow';
import shared from '@sharedStyle/page.scss';

const Networks = props => {
    const { components } = getFeatures('networks', { props });

    return <Content className={shared.smallPageWrapper}>{components}</Content>;
};

export default withFeatures(Networks);
