import React, { useEffect } from 'react';
import { createSelector } from 'reselect';
import { Card, Layout, Content } from 'maslow';
import {
    Schedule,
    Summary,
    WhatsNext,
    Budget,
    TVDetails,
    DigitalDetails,
    PlanAndAdvertiserName,
    Audiences
} from './';
import { useDispatch } from 'react-redux';
import styles from './mediaPlan.scss';
import {
    errorAlertSelector,
    householdsSelector,
    a4PlanIdSelector,
    selectedLocationsSelector,
    uiSelector,
    hasPermissionSelector
} from '@selectors';
import { resetLoadingScreen } from '@state/ui/actions';
import { useSelector } from 'react-redux';
import { mediaPlanConfig } from '@config/config.mediaPlan';
import { generateToast } from '@services/';
import { fetchLocationCount } from '@state/audience/actions/households';

const hasCountSelector = createSelector(
    a4PlanIdSelector,
    householdsSelector,
    (a4PlanId, { count, total, locationCount, isFetching }) => {
        return a4PlanId && total && !isFetching && count && locationCount;
    }
);

export default function () {
    const MediaPlan = ({ planId }) => {
        const dispatch = useDispatch();
        const isErrorAlert = useSelector(errorAlertSelector);
        const {
            loadingScreen: { showLoadingScreen }
        } = useSelector(uiSelector);
        const { audienceStyles, mediaToastConfig } = mediaPlanConfig;
        const hasCount = useSelector(hasCountSelector);
        const selectedLocations = useSelector(selectedLocationsSelector);
        const { total, count, locationCount } = useSelector(householdsSelector);
        const canNotBook = useSelector(
            hasPermissionSelector('mediaplan.cannotbook')
        );

        useEffect(() => {
            if (!showLoadingScreen && isErrorAlert)
                generateToast.add(mediaToastConfig, dispatch);
        }, [isErrorAlert, showLoadingScreen]);

        useEffect(() => {
            if (
                count &&
                (!locationCount ||
                    (selectedLocations.length && locationCount === total))
            ) {
                dispatch(fetchLocationCount());
            }
        }, [count]);

        useEffect(() => {
            if (hasCount) dispatch(resetLoadingScreen());
        }, [hasCount]);
        return (
            <React.Fragment>
                <Layout
                    flexDirection="column"
                    className={styles.mediaPlanWrapper}>
                    <div>
                        <Card backgroundColor="white" className={styles.card}>
                            <Content
                                className={styles.contentWrapper}
                                fillParent>
                                <Layout flexDirection="column">
                                    <PlanAndAdvertiserName />
                                </Layout>
                            </Content>
                        </Card>
                    </div>
                    <div>
                        <Card backgroundColor="white" className={styles.card}>
                            <Content
                                className={styles.contentWrapper}
                                fillParent>
                                <Layout flexDirection="column">
                                    <Summary />
                                    <Schedule />
                                </Layout>
                            </Content>
                        </Card>
                    </div>
                    <div>
                        <Card backgroundColor="white" className={styles.card}>
                            <Content
                                className={styles.contentWrapper}
                                fillParent>
                                <Layout flexDirection="column">
                                    <Audiences
                                        audienceStyles={audienceStyles}
                                    />
                                    <Budget />
                                    <DigitalDetails />
                                </Layout>
                            </Content>
                        </Card>
                    </div>
                    {!canNotBook && (
                        <div>
                            <Card
                                backgroundColor="white"
                                className={styles.card}>
                                <Content
                                    className={styles.contentWrapper}
                                    fillParent>
                                    <Layout flexDirection="column">
                                        <WhatsNext />
                                    </Layout>
                                </Content>
                            </Card>
                        </div>
                    )}
                </Layout>
            </React.Fragment>
        );
    };

    return MediaPlan;
}
