import { isArray } from '@lib';
import { validateArg } from '@lib';

export const filterZips = (zips, geos) => {
    if (!isArray(zips)) throw new Error('first arg must be an array');
    if (!isArray(geos)) throw new Error('second arg must be an array');

    return zips.filter(zip => !geos.find(geo => geo.id === zip.id));
};

//Necessary to convert locations saved prior to new locations service being in use
export const convertOldLocations = locations => {
    if (!isArray(locations)) throw new Error('arg must be an array');

    return locations.map(location => {
        if (location.hasOwnProperty('audienceAttributeId')) {
            return location;
        }

        const {
            attributeName,
            category,
            id,
            displayString,
            code,
            type
        } = location;

        return {
            audienceAttributeId: attributeName,
            audienceCategory: category,
            id: id || `${type}.${code}`,
            name: displayString
        };
    });
};

//geoJson saved prior to new locations service in use is filtered out, and Map component will get it from the new service
export const filterOldGeoJson = geoJson => {
    if (!isArray(geoJson)) throw new Error('arg must be an array');
    return geoJson.filter(geo => geo.hasOwnProperty('audienceAttributeId'));
};

export const filterLocationSearchResponse = (data, locations) => {
    validateArg(data, 'array', 1);
    validateArg(locations, 'array', 2);

    if (locations.length === 0 || data.length === 0) {
        return data;
    }
    const selectedIds = locations.map(l => l.id);
    return data.map(datum => ({
        type: datum.type,
        entries: datum.entries.filter(
            entry => !selectedIds.some(id => id === entry.id)
        )
    }));
};
