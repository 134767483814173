import request from '../request';

export default function(role) {
    const req = {
        method: 'GET',
        url: `households/audience`,
        params: {
            role
        }
    };

    return request(req);
}
