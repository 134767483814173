import { createSelector } from 'reselect';

export const userSelector = createSelector(
    state => state.user,
    user => user
);

export const accountSelector = createSelector(
    userSelector,
    ({ account }) => account
);

export const hasPermissionSelector = permissionName => {
    return createSelector(userSelector, ({ account }) => {
        const { permissions } = account;
        return permissions?.includes(permissionName);
    });
};
