import { Plans } from '../components/pages';
import { store } from '@state';
import { resetPlan } from '@state/rootActions';
import featuresManager from '@featuresManager';

export default [
    {
        name: 'app.main.plans',
        url: '/plans',
        views: {
            'content@app': {
                component: Plans
            }
        },
        onEnter: () => {
            store.dispatch(resetPlan());
            featuresManager.setFeatureState('main');
        }
    }
];
