import {
    COLOR_BLUE,
    COLOR_GREY,
    COLOR_LIGHT_GREY,
    COLOR_BLACK,
    CIRCLE_RADIUS,
    CIRCLE_RADIUS_6,
    FONT_SIZE_10,
    FONT_SIZE_12,
    FONT_SIZE_16,
    COLOR_WHITE
} from './constants';

import { isArray } from '@lib';

export const circle = (doc, x, y, isSmall, circleRadius, color) => {
    const fillColor = color || (isSmall ? COLOR_LIGHT_GREY : COLOR_BLUE);
    doc.setFillColor(...fillColor);
    doc.circle(x + circleRadius, y + circleRadius, circleRadius, 'F');

    return doc;
};

export const circleWithText = (doc, x, y, text, isSmall, radius, textColor, fillColor) => {
    const _textColor = textColor  || (isSmall ? COLOR_BLACK : COLOR_WHITE);
    const _circleRadius = radius || (isSmall ? CIRCLE_RADIUS_6 : CIRCLE_RADIUS);
    const _fontSize = isSmall ? FONT_SIZE_10 : FONT_SIZE_12;
    const _text = String(text);
    circle(doc, x, y, isSmall, _circleRadius, fillColor);

    doc.setFontSize(_fontSize);
    doc.setFontType('normal');
    doc.setTextColor(..._textColor);

    const textWidth =
        (doc.getStringUnitWidth(_text) * _fontSize) / doc.internal.scaleFactor;

    const { h } = doc.getTextDimensions(_text);
    const _x = x + _circleRadius - textWidth / 2;
    const _y = y + _circleRadius + Math.floor(h) / 3;

    doc.text(_text, _x, _y);

    return doc;
};

export const roundedRect = (doc, x, y, w, h, radius, color) => {
    doc.setFillColor(...color);
    doc.roundedRect(x, y, w, h, radius, radius, 'F');

    return doc;
};

export const getTextWidth = (doc, text, fontSize) => {
    const _text = String(text);
    const size = fontSize || FONT_SIZE_10;

    return (
        (doc.getStringUnitWidth(_text) * size) /
        doc.internal.scaleFactor
    );
};

export const roundedRectWithText = (
    doc,
    x,
    y,
    w,
    rh,
    radius,
    text,
    textWidth,
    textColor,
    color
) => {
    const _text = String(text);

    roundedRect(doc, x, y, w, rh, radius, color);

    doc.setFontSize(FONT_SIZE_10);
    doc.setFontType('normal');
    doc.setTextColor(...textColor);

    const { h } = doc.getTextDimensions(_text);
    const _x = x + w / 2 - textWidth / 2;
    const _y = y + rh / 2 + Math.floor(h) / 3;

    doc.text(_text, _x, _y);

    return doc;
};

export const audienceCategoryRect = (
    doc,
    x,
    y,
    w,
    rh,
    radius,
    text,
    textColor,
    color
) => {
    const _text = isArray(text) ? text : String(text);

    roundedRect(doc, x, y, w, rh, radius, color);

    doc.setFontSize(FONT_SIZE_10);
    doc.setFontType('bold');
    doc.setTextColor(...textColor);
    doc.setLineWidth(2);

    const _x = x + 15;
    const _y = y + 15;

    doc.text(_x, _y, _text);

    return doc;
};

export const getTextHeight = (doc, text) => {
    const _text = String(text);
    const { h } = doc.getTextDimensions(_text);

    return h;
};
