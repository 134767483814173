import React, { useEffect } from 'react';
import { Page } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';

const VerificationError = props => {
    const _props = { props };
    const { components } = getFeatures('verificationError', _props);

    return <Page backgroundColor="backgroundGray">{components}</Page>;
};

export default withFeatures(VerificationError);
