import { validateArg } from '@lib/';

export const pendoService = {
    track: (eventName: string, value: object) => {
        validateArg(eventName, 'string', 1);
        validateArg(value, 'object', 2);

        if (window?.pendo?.track) {
            return window.pendo.track(eventName, value);
        }
    }
};
