import { configureStore, getDefaultMiddleware } from '@reduxjs/toolkit';
import { default as rootReducer } from './rootReducer';

function configureAppStore() {
    const store = configureStore({
        reducer: rootReducer,
        middleware: getDefaultMiddleware =>
            getDefaultMiddleware({
                serializableCheck: false,
                immutableCheck: false
            })
    });
    return store;
}

export let store = configureAppStore();
