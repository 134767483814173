import { loginService } from '@services';
import { store } from '@state';
import { updateAccount } from '@state/user/actions/account';

const urlWhiteList = ['/login', '/verify', '/error'];

export default router => {
    const ls = loginService();

    if (!urlWhiteList.includes(router.locationService.path())) {
        const data = ls.getAndDecodeJwt();

        // Log user out if cant retrieve jwt token from browser
        if (!data) ls.logout(router);

        data && store.dispatch(updateAccount(ls.processAccountData(data)));
    }

    if (urlWhiteList.includes(router.locationService.path())) {
        return;
    } else {
        router.urlRouter.otherwise('/app/main/plans');
    }
    return;
};
