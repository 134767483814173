export default {
    name: 'MediaPlanSummaryFooter',
    description: 'This is intended for displaying messages to the user',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['main', 'plan'],
    sections: ['footer'],
    flavours: ['cgd', 'cablevision', 'beta'],
    userRoles: ['guest', 'admin'],
    data: {
        messages: {
            generating: 'Generating your media plan.',
            generated: 'You are now ready to book.'
        }
    },
    attributes: {},
    modifications: {}
};
