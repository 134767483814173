import { createSelector } from 'reselect';
import { convertOldLocations, filterOldGeoJson } from '@services/locations';
import { getLocations } from '@services/locations';
import { HAWAII, ALASKA } from '@config/config.maps';

export const locationSelector = createSelector(
    state => state.location,
    location => location
);

export const selectedLocationsSelector = createSelector(
    state => state.location.selectedLocations,
    locations => convertOldLocations(locations)
);

export const geoJsonSelector = createSelector(locationSelector, ({ geoJson }) =>
    filterOldGeoJson(geoJson)
);

export const geoStatesSelector = createSelector(
    locationSelector,
    ({ states }) => states
);

export const statesSelector = createSelector(
    locationSelector,
    ({ states }) => states
);

export const locationsEmptySelector = createSelector(
    locationSelector,
    ({ selectedLocations, states }) =>
        !selectedLocations.length && !states.length
);

const mapRegionLocationsSelector = createSelector(
    [selectedLocationsSelector, (_, mapRegion) => mapRegion],
    (locations, mapRegion) => getLocations(mapRegion, locations)
);

export const createLocationsSelector = mapRegion => state =>
    mapRegionLocationsSelector(state, mapRegion);

export const showHawaiiAndAlaskaSelector = createSelector(
    createLocationsSelector(HAWAII.initials.toUpperCase()),
    createLocationsSelector(ALASKA.initials.toUpperCase()),
    selectedLocationsSelector,
    (hiLocations, akLocations, allLocations) => {
        return !allLocations.length || hiLocations.length || akLocations.length
            ? true
            : false;
    }
);

const geoJsonLocationSelector = createSelector(
    [geoJsonSelector, (_, mapRegion) => mapRegion],
    (geoJsons, mapRegion) => getLocations(mapRegion, geoJsons)
);

export const createGeoJsonLocationSelector = mapRegion => state =>
    geoJsonLocationSelector(state, mapRegion);
