import React, { useReducer, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';

import { Input, Text } from 'maslow';

import requestHandlers from '@requestHandlers';
import { isEmptyString } from '@lib';
import LoginButton from '../LoginButton';

import { updateAccount } from '@state/user/actions/account';
import { updateAgency } from '@state/user/actions/agency';

import shared from '@sharedStyle/loginAndSignUp.scss';

import {
    LOGIN_EMAIL_INPUT,
    LOGIN_PASSWORD_INPUT,
    LOGIN_CREATE_ACCOUNT,
    LOGIN_FORGOT_PASSWORD
} from '@config/idConstants';

const getInitialState = ({ IDLE }) => {
    return {
        emailStatus: IDLE,
        emailError: '',
        passwordStatus: IDLE,
        passwordError: ''
    };
};

const reducer = (state, action) => {
    const { type, payload } = action;
    const { name, value } = payload;
    switch (type) {
        case 'updateValue':
            return {
                ...state,
                [name]: value
            };
        default:
            throw new Error();
    }
};

const LoginSection = ({
    ls,
    errors,
    STATUSES,
    INTERVAL_TIME,
    DISPLAY_TEXT_STATES,
    displayText,
    updateAccount,
    updateAgency,
    transition,
    updateComponentState
}) => {
    const [state, stateDispatch] = useReducer(
        reducer,
        getInitialState(STATUSES)
    );
    const { emailStatus, emailError, passwordStatus, passwordError } = state;

    const [buttonAnimation, setButtonAnimation] = useState(false);

    const updateStateValue = (name, value) => {
        stateDispatch({
            type: 'updateValue',
            payload: { name, value }
        });
    };

    const invalidEmailSideEffects = value => {
        updateStateValue(
            'emailStatus',
            isEmptyString(value) ? STATUSES.IDLE : STATUSES.ERROR
        );
        updateStateValue('emailError', errors.emailError);
    };

    const emailDoneTyping = value => {
        const hasValidEmail = ls.hasValidEmail(value);
        hasValidEmail
            ? updateStateValue('emailStatus', STATUSES.VALID)
            : invalidEmailSideEffects(value);
        updateStateValue('passwordStatus', STATUSES.IDLE);
    };

    const passwordDoneTyping = value => {
        const hasValidPassword = ls.hasValidPassword(value);
        if (isEmptyString(value)) {
            updateStateValue('passwordStatus', STATUSES.IDLE);
        } else {
            updateStateValue('passwordStatus', STATUSES.VALID);
        }
    };

    const showError = (error = errors.authLoginError) => {
        updateStateValue('passwordStatus', STATUSES.ERROR);
        updateStateValue('passwordError', error);
        setButtonAnimation(false);
    };

    const login = (email, password) => {
        return requestHandlers.auth.login(email, password).catch(() => {
            showError();
        });
    };

    const submitForm = async () => {
        setButtonAnimation(true);
        const { email, password } = ls.getUserDetails();
        const loginResponse = await login(email, password);

        if (loginResponse.status === 200) {
            const data = ls.setAndDecodeJwt(loginResponse.data);

            const agency = { name: data.email };

            updateAccount(ls.processAccountData(data));
            updateAgency(ls.processAgencyData(agency));

            if (data.verified) {
                transition.router.stateService.go('app.main.plans');
            } else {
                showError(errors.verificationNeeded);
                requestHandlers.auth
                    .resendVerification(email)
                    .catch(() =>
                        console.error('sending verification email failed')
                    );
                requestHandlers.auth
                    .logout()
                    .catch(() => console.error('log out failed'));
            }
        } else {
            showError();
        }
    };

    const onLoginClick = () => {
        setTimeout(() => {
            submitForm();
        }, INTERVAL_TIME);
    };

    const onForgotPassword = () => {
        updateComponentState(
            'displayTextState',
            DISPLAY_TEXT_STATES.FORGOT_PASSWORD
        );
        updateComponentState('showSendLink', true);
    };

    const renderEmailInput = () => {
        return (
            <div>
                <Input
                    id={LOGIN_EMAIL_INPUT}
                    name="email"
                    theme="smallLine"
                    placeholder="Email Address"
                    status={emailStatus}
                    errorText={emailError}
                    doneTypingInterval={INTERVAL_TIME}
                    doneTyping={emailDoneTyping}
                />
            </div>
        );
    };

    const renderPasswordInput = () => {
        return (
            <div>
                <Input
                    id={LOGIN_PASSWORD_INPUT}
                    name="password"
                    theme="smallLine"
                    placeholder="Password"
                    type="password"
                    status={passwordStatus}
                    errorText={passwordError}
                    onEnterKey={onLoginClick}
                    doneTypingInterval={INTERVAL_TIME}
                    doneTyping={passwordDoneTyping}
                />
            </div>
        );
    };

    const renderLoginButton = ({ button }) => {
        return (
            <LoginButton
                onLoginClick={onLoginClick}
                loginService={ls}
                text={button}
                buttonAnimation={buttonAnimation}
            />
        );
    };

    return (
        <React.Fragment>
            {renderEmailInput()}
            {renderPasswordInput()}

            <div className={shared.textAboveButton}>
                <Text
                    id={LOGIN_FORGOT_PASSWORD}
                    type="input"
                    color="vividNavy"
                    onClick={onForgotPassword}
                    className={shared.textLink}>
                    Forgot password?
                </Text>
            </div>

            {renderLoginButton(displayText)}
        </React.Fragment>
    );
};

LoginSection.propTypes = {
    ls: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    STATUSES: PropTypes.object.isRequired,
    INTERVAL_TIME: PropTypes.number.isRequired,
    DISPLAY_TEXT_STATES: PropTypes.object.isRequired,
    displayText: PropTypes.object.isRequired,
    updateAccount: PropTypes.func.isRequired,
    updateAgency: PropTypes.func.isRequired,
    updateComponentState: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    updateAccount: account => dispatch(updateAccount(account)),
    updateAgency: agency => dispatch(updateAgency(agency))
});

export default connect(null, mapDispatchToProps)(LoginSection);
