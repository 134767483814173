import { validateArg } from '@lib';

export const getAllNetworks = async (excludedNetworks, getNetworks, booked) => {
    validateArg(excludedNetworks, 'array', 1);
    validateArg(getNetworks, 'function', 2);
    validateArg(booked, 'boolean', 3);

    if (booked || !excludedNetworks.length) {
        return null;
    } else {
        const { data: allNetworks } = await getNetworks();
        return allNetworks;
    }
};

export const getNetworksReduxProperties = async (
    excludedNetworks,
    getNetworks,
    booked
) => {
    validateArg(excludedNetworks, 'array', 1);
    validateArg(getNetworks, 'function', 2);
    validateArg(booked, 'boolean', 3);

    const allNetworks = await getAllNetworks(
        excludedNetworks,
        getNetworks,
        booked
    );
    const filteredExcludedNetworks = booked
        ? excludedNetworks
        : excludedNetworks.filter(network =>
              allNetworks.some(item => item.A4mediaId === network.A4mediaId)
          );

    return {
        excludedNetworks: filteredExcludedNetworks,
        allNetworks,
        orderBy: ''
    };
};
