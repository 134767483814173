import { createAction } from '@reduxjs/toolkit';
import { UPDATE_TV_DETAILS, REMOVE_ALL_TV_DETAILS } from '../constants';

export const updateTvDetails = createAction(UPDATE_TV_DETAILS, tvDetails => {
    return {
        payload: { tvDetails }
    };
});

export const removeAllTvDetails = createAction(REMOVE_ALL_TV_DETAILS);
