import { createAction } from '@reduxjs/toolkit';
import {
    SET_INSTAGRAM,
    SET_FACEBOOK,
    SET_DESTINATION,
    SET_AD_MESSENGER
} from '../constants';

export const setAdMessenger = createAction(SET_AD_MESSENGER, adMessenger => {
    return {
        payload: { adMessenger }
    };
});

export const setDestination = createAction(SET_DESTINATION, destination => {
    return {
        payload: { destination }
    };
});

export const setFacebook = facebook => ({
    type: SET_FACEBOOK,
    payload: { facebook }
});

export const setInstagram = instagram => ({
    type: SET_INSTAGRAM,
    payload: { instagram }
});
