import Login from '../components/main/Login';
import featuresManager from '@featuresManager';

export default [
    {
        name: 'login',
        url: '/login',
        views: {
            '@': {
                component: Login
            }
        },
        onEnter: () => {
            featuresManager.setFeatureState('login');
        }
    }
];
