import { createSelector } from 'reselect';
import { cloneDeep } from 'lodash';

import {
    subCampaignSelector,
    subsWithBudgetSelector,
    getSCWithBudgetByNameSelector,
    budgetSelector
} from '@selectors';
import {
    validateSubCreatives,
    validateSubsForHeader,
    areAllSubsValid,
    getUnvalidatedSubs,
    formatErrors,
    updateFileStatuses,
    getPublishStatus,
    getDisclaimer,
    getSelectedSubCampaigns
} from '@services/creative';
import { getContextFromName } from '@services/budget';
import { isValidUrl } from '@services/';
import { AD_MESSENGER, LINEAR_TV } from '@config';
import { scheduleSelector } from '../schedule';
import { hasBudgtForOnlyProvidedSubCampaigns } from '@services/budget';

export const creativeSelector = createSelector(
    state => state.creative,
    creative => creative
);

export const formattedCreativeSelector = createSelector(
    creativeSelector,
    subCampaignSelector,
    (creative, subCampaignBudgets) => {
        //To prevent mutation of data in state
        const newCreative = cloneDeep(creative);
        newCreative.files = getSelectedSubCampaigns(
            newCreative.files,
            subCampaignBudgets
        );
        newCreative.files = updateFileStatuses(newCreative.files);
        return formatErrors(newCreative);
    }
);

export const allValidFilesSelector = createSelector(
    formattedCreativeSelector,
    ({ files }) =>
        files.every(
            file => file.hasOwnProperty('errors') && !file.errors.length
        )
);

export const creativeUrlsSelector = createSelector(
    formattedCreativeSelector,
    ({ adMessenger, destination, facebook, instagram }) => ({
        adMessenger: adMessenger?.url ?? '',
        destination: destination?.url ?? '',
        facebook: facebook?.url ?? '',
        instagram: instagram?.url ?? ''
    })
);

export const getDisclaimerStatusSelector = id =>
    createSelector(formattedCreativeSelector, ({ disclaimer }) => {
        return disclaimer[id]?.status;
    });

export const creativeProductsSelector = createSelector(
    [formattedCreativeSelector, scheduleSelector],
    ({ files, disclaimer }, { startDate, endDate }) => {
        return files.map(({ id, subCampaigns, status }) => {
            const products = [];
            subCampaigns.forEach(sc => {
                products.push(getContextFromName(sc) || {});
            });
            return {
                CreativeId: id,
                Products: products,
                BeginDate: startDate,
                EndDate: endDate,
                CreativeStatus: getPublishStatus(status),
                PoliticalDisclaimer: getDisclaimer(disclaimer, id)
            };
        });
    }
);

export const subCreativeStatusSelector = createSelector(
    formattedCreativeSelector,
    subsWithBudgetSelector,
    (creative, subCampaigns) => validateSubCreatives(creative, subCampaigns)
);

export const headerValidationSelector = createSelector(
    formattedCreativeSelector,
    subsWithBudgetSelector,
    (creative, subs) => validateSubsForHeader(creative, subs)
);

export const unvalidatedSubsSelector = createSelector(
    headerValidationSelector,
    subs => getUnvalidatedSubs(subs)
);

export const headerIsValidSelector = createSelector(
    headerValidationSelector,
    subs => areAllSubsValid(subs)
);

export const skipDestinationUrlSelector = createSelector(
    budgetSelector,
    ({ subCampaigns }) => {
        return (
            hasBudgtForOnlyProvidedSubCampaigns(subCampaigns, [
                AD_MESSENGER,
                LINEAR_TV
            ]) ||
            hasBudgtForOnlyProvidedSubCampaigns(subCampaigns, [AD_MESSENGER]) ||
            hasBudgtForOnlyProvidedSubCampaigns(subCampaigns, [LINEAR_TV])
        );
    }
);

export const creativeValidationSelector = createSelector(
    formattedCreativeSelector,
    subCreativeStatusSelector,
    skipDestinationUrlSelector,
    allValidFilesSelector,
    (creative, subs, skipDestinationUrl, allFilesValid) =>
        areAllSubsValid(subs) &&
        (skipDestinationUrl ||
            (!skipDestinationUrl &&
                isValidUrl(creative.destination.url) &&
                allFilesValid))
);

export const isfileUploadingSelector = createSelector(
    creativeSelector,
    ({ files }) => {
        return files.some(file => file.isUploading);
    }
);
