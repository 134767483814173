import React from 'react';
import PropTypes from 'prop-types';
import {
    Wrapper,
    DashedWrapper,
    DashedInputStyle,
    InputStyle,
    LineInputStyle,
    SmallLineInputStyle
} from './style';
import { INPUT, INPUT_ERROR } from '../testIds';
import { testIdBuilder } from '../../core/testIdBuilder';

import InputIcon from './InputIcon';
import InputLabel from './InputLabel';
import InputError from './InputError';
import InputClear from './InputClear';

const INPUT_THEME_MAP = {
    base: InputStyle,
    dashed: DashedInputStyle,
    line: LineInputStyle,
    smallLine: SmallLineInputStyle
};

const WRAPPER_THEME_MAP = {
    base: Wrapper,
    dashed: DashedWrapper,
    line: DashedWrapper,
    smallLine: Wrapper
};

/**
 * Input component
 * */
class Input extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hasValue: false
        };

        this.inputRef = React.createRef();
        this.typingTimer = null;

        this.registerChange = this.registerChange.bind(this);
        this.clearInput = this.clearInput.bind(this);
        this.handleChange = this.handleChange.bind(this);
        this.handleFocus = this.handleFocus.bind(this);
        this.handleKeyUp = this.handleKeyUp.bind(this);
        this.onEnterKey = this.onEnterKey.bind(this);
        this.doneTyping = this.doneTyping.bind(this);
        this.focusInput = this.focusInput.bind(this);
        this.onPaste = this.onPaste.bind(this);
    }

    get InputWrapper() {
        return WRAPPER_THEME_MAP[this.props.theme];
    }

    componentDidMount() {
        if (this.props.value) {
            this.setState({ hasValue: true });
        }
    }

    componentWillUnmount() {
        clearTimeout(this.typingTimer);
    }

    registerChange(value) {
        if (this.props.onChange) this.props.onChange(value, this.props.name);
        this.setState({ hasValue: !!value.length });
    }

    handleChange(evt) {
        this.registerChange(evt.target.value);
    }

    handleFocus() {
        if (this.props.onFocus) this.props.onFocus(this.props.name);
    }

    focusInput() {
        if (this.inputRef.current) {
            this.inputRef.current.focus();
        }
    }

    clearInput() {
        if (this.inputRef) {
            this.inputRef.current.value = '';
            this.registerChange(this.inputRef.current.value);
        }
    }

    doneTyping(value) {
        if (this.props.doneTyping) {
            this.props.doneTyping(value, this.props.name);
        }
    }

    onEnterKey(evt) {
        if (this.props.onEnterKey && evt.key === 'Enter') {
            this.props.onEnterKey(evt);
        }
    }

    handleKeyUp(evt) {
        if (evt.key !== 'Enter') {
            clearTimeout(this.typingTimer);
            const value = evt.target.value;
            this.typingTimer = setTimeout(() => {
                this.doneTyping(value);
            }, this.props.doneTypingInterval);
        }
    }

    onPaste(evt) {
        const value = evt.clipboardData.getData('Text');
        clearTimeout(this.typingTimer);
        this.typingTimer = setTimeout(() => {
            this.doneTyping(value);
        }, this.props.doneTypingInterval);
    }

    render() {
        const {
            status,
            errorText,
            errorClassName,
            label,
            icon,
            clearText,
            onEnterKey,
            isLabelFloated,
            theme,
            testNameSpace,
            rightAlignError,
            id,
            ...result
        } = this.props;

        const InputWrapper = WRAPPER_THEME_MAP[theme];
        const InputField = INPUT_THEME_MAP[theme];
        const testId = testIdBuilder(testNameSpace, INPUT);
        const testIdError = testIdBuilder(testNameSpace, INPUT_ERROR);

        return (
            <InputWrapper hasLabel={label && label.length} id={id}>
                <InputField
                    {...result}
                    status={status}
                    onChange={this.handleChange}
                    onFocus={this.handleFocus}
                    onKeyUp={this.handleKeyUp}
                    onKeyDown={this.onEnterKey}
                    onPaste={this.onPaste}
                    hasIcon={icon && icon.length}
                    ref={this.inputRef}
                    data-testid={testId}
                />

                <InputIcon icon={icon} status={status} />

                <InputLabel
                    hasValue={this.state.hasValue}
                    icon={icon}
                    isLabelFloated={isLabelFloated}
                    label={label}
                    theme={theme}
                />

                <InputClear
                    hasValue={this.state.hasValue}
                    onClearClick={this.clearInput}
                    clearText={clearText}
                />

                <InputError
                    status={status}
                    errorText={errorText}
                    errorClassName={errorClassName}
                    theme={theme}
                    testIdError={testIdError}
                    rightAlignError={rightAlignError}
                />
            </InputWrapper>
        );
    }
}

Input.propTypes = {
    /** Input value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Name of the input field */
    name: PropTypes.string,
    /** onChange callback */
    onChange: PropTypes.func,
    /** onKeyPress callback */
    onKeyDown: PropTypes.func,
    /** onFocus callback */
    onFocus: PropTypes.func,
    /** Is input disabled  */
    disabled: PropTypes.bool,
    /** Html input type */
    type: PropTypes.string,
    /** Label text for the input */
    label: PropTypes.string,
    /** Icon */
    icon: PropTypes.string,
    /** Theme: base, dashed or line */
    theme: PropTypes.oneOf(['base', 'dashed', 'line', 'smallLine']),
    /** Text to be shown in clear button */
    clearText: PropTypes.string,
    /** Whether label should float */
    isLabelFloated: PropTypes.bool,
    /** Readonly */
    readOnly: PropTypes.bool,
    /** Input status */
    status: PropTypes.oneOf(['idle', 'valid', 'error']),
    /** Error text to be shown when status is error */
    errorText: PropTypes.string,
    /** Class name for error container */
    errorClassName: PropTypes.string,
    /** Callback when paste from right click mouse selection*/
    onPaste: PropTypes.func,
    /** Callback when typing is done */
    doneTyping: PropTypes.func,
    /** Time to wait until typing is considered done */
    doneTypingInterval: PropTypes.number,
    /** Namespace for e2e tests targeting */
    testNameSpace: PropTypes.string,
    /** text-align for the input */
    textAlign: PropTypes.string,
    /** Whether errorText should right align */
    rightAlignError: PropTypes.bool,
    /** Id for tracking */
    id: PropTypes.string.isRequired
};

Input.defaultProps = {
    type: 'text',
    isLabelFloated: true,
    status: 'idle',
    doneTypingInterval: 0,
    theme: 'base',
    readOnly: false
};

export default Input;
