export const DOC_HEADER_HEIGHT = 125;

export const MARGIN_5 = 5;
export const MARGIN_10 = 10;
export const MARGIN_15 = 15;
export const MARGIN_17 = 17;
export const MARGIN_20 = 20;
export const MARGIN_30 = 30;
export const MARGIN_40 = 40;
export const MARGIN_45 = 45;
export const MARGIN_50 = 50;
export const MARGIN_55 = 55;
export const MARGIN_58 = 58;
export const MARGIN_60 = 60;
export const MARGIN_70 = 70;
export const MARGIN_80 = 80;
export const MARGIN_140 = 140;
export const MARGIN_160 = 160;
export const MARGIN_175 = 175;
export const MARGIN_215 = 215;

export const FONT_SIZE_10 = 10;
export const FONT_SIZE_12 = 12;
export const FONT_SIZE_16 = 16;
export const FONT_SIZE_20 = 20;
export const FONT_SIZE_24 = 24;
export const FONT_SIZE_40 = 40;

export const COLOR_WHITE = [255, 255, 255];
export const COLOR_BLACK = [0, 0, 0];
export const COLOR_GREY = [165, 165, 165];
export const COLOR_BLUE = [67, 94, 255];
export const COLOR_LIGHT_BLUE = [230, 239, 255];
export const COLOR_TEXT_BLUE = [46, 94, 255];
export const COLOR_LIGHT_GREY = [225, 231, 232];
export const COLOR_LAVENDER_BLUE = [231, 239, 255];
export const COLOR_CYAN_BLUE = [230, 241, 248];
export const COLOR_TOBERNITE = [228, 240, 241];
export const COLOR_BLUE_GREY = [131, 145, 188];
export const COLOR_DENIM = [13, 130, 192];
export const COLOR_BLUE_GREEN = [7, 102, 125];
export const CELL_WIDTH_01 = 90;

export const CIRCLE_RADIUS = 8;
export const CIRCLE_RADIUS_6 = 6;

export const IMAGE_SIZE_5 = 5;
export const IMAGE_SIZE_15 = 15;
export const IMAGE_SIZE_20 = 20;
export const IMAGE_SIZE_33 = 33;
export const IMAGE_SIZE_38 = 38;
export const STRIPED_IMAGE_HEIGHT = 0.5;

export const HIATUS_WEEK_HEIGHT = 12;
export const CUSTOM_LIST_TYPE = 'custom';
