import React from 'react';

export default function (version, feature) {
    class Component extends React.Component {
        constructor(props) {
            super(props);
        }

        render () {
            return <div>Admin Dashboard</div>
        };
    }

    Component.propTypes = {};

    return Component;
};

