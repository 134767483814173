import { validateArg, isObject, round, isArray } from '@lib';
import { impressionService } from '../impressionService';
import { getSubName, getSubcampaignKey } from '../budget';

import { LINEAR_TV } from '@config';
import { PLAN_STATUSES } from '@config/config.planStatus';

export const formatSubmittedDigitalDetails = budget => {
    validateArg(budget, 'array');
    let submittedDigitalDetails = {};
    budget.map(({ Name, Cpm, Impressions }) => {
        const key = getSubcampaignKey(Name);
        const subName = getSubName(key);
        if (subName !== LINEAR_TV) {
            submittedDigitalDetails[subName] = {
                cpm: Cpm,
                impression: Impressions
            };
        }
    });
    return submittedDigitalDetails;
};

export const getMediaPlanReduxProperties = data => {
    validateArg(data, 'object');
    const {
        planId,
        tvDistribution,
        primaryTargetDemography,
        budget,
        campaignRouterId,
        booked,
        submittedDigitalDetails
    } = data;

    if (isNaN(parseInt(planId)))
        throw new Error(
            'Property planId must be a number or a string that converts to a number'
        );
    if (!isObject(primaryTargetDemography))
        throw new Error('Property primaryTargetDemography must be an object');
    if (!isObject(tvDistribution))
        throw new Error('Property tvDistribution must be an object');
    if (!isObject(budget)) throw new Error('Property budget must be an object');
    if (campaignRouterId && isNaN(parseInt(campaignRouterId)))
        throw new Error(
            'If property campaignRouterId has truthy value, then it must be a number or a string that converts to a number'
        );
    if (typeof booked !== 'boolean')
        throw new Error('Property booked must be a boolean');
    if (!isArray(submittedDigitalDetails))
        throw new Error('Property submittedDigitalDetails must be an array');

    const hasTvDetails = false;
    return {
        campaignRouterId,
        tvDetails: {
            primaryTarget: {
                primaryDemoName: primaryTargetDemography?.name,
                cpm: primaryTargetDemography?.cpm
            },
            distribution: hasTvDetails ? tvDistribution : {},
            tvImpressions:
                hasTvDetails && primaryTargetDemography
                    ? round(
                          impressionService(
                              budget.subCampaigns[LINEAR_TV].budget,
                              primaryTargetDemography.cpm
                          )
                      )
                    : 0
        },
        a4PlanId: planId,
        hasTvDetails,
        planStatus: booked ? PLAN_STATUSES.BOOKED : PLAN_STATUSES.SAVED_DRAFT,
        submittedDigitalDetails: booked
            ? formatSubmittedDigitalDetails(submittedDigitalDetails)
            : {}
    };
};
