import cookies from 'js-cookie';
import request from '../request';

export default function ({
    firstName,
    lastName,
    email,
    password,
    password2,
    hasAgreedToTerms
}) {
    return request({
        method: 'POST',
        url: 'auth/registration/',
        data: {
            firstName,
            lastName,
            email,
            password,
            password2,
            hasAgreedToTerms
        }
    }).then(response => {
        return response;
    }).catch(error => {
        console.error('ERROR:', error);
        return { message: error, status: error.response.status };
    });
};
