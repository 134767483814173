import { createReducer } from '@reduxjs/toolkit';
import { ADD_COMBINATIONS, REMOVE_COMBINATIONS } from '../constants';

const initialState = {};

const addCombinations = (state, action) => {
    const { combinations } = action.payload;
    return { ...combinations };
};

const removeCombinations = () => {
    return initialState;
};

export const combinations = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_COMBINATIONS, addCombinations)
        .addCase(REMOVE_COMBINATIONS, removeCombinations)
        .addDefaultCase((state, _) => state);
});
