import videoSvg from '@public/img/video.svg';
import digitalSvg from '@public/img/digital.svg';
import socialSvg from '@public/img/social.svg';
import displaySvg from '@public/img/display.svg';

import playSvg from '@public/img/play.svg';
import desktopSvg from '@public/img/desktop.svg';
import instagramSvg from '@public/img/instagram.svg';

import {
    POLITICAL,
    COMMERCIAL,
    NATIONAL,
    LOCAL,
    VIDEO,
    DISPLAY,
    SOCIAL_MEDIA,
    PRE_MID_ROLL,
    OTT,
    BANNERS,
    AD_MESSENGER,
    FACEBOOK,
    INSTAGRAM,
    ACTUAL_USER,
    TESTER
} from '@config';

const MAX_SUB_BUDGET = 9999999999;

export const campaigns = {
    preMidRoll: PRE_MID_ROLL,
    ott: OTT,
    banners: BANNERS,
    adMessenger: AD_MESSENGER,
    facebook: FACEBOOK,
    instagram: INSTAGRAM
};

export const channelNames = {
    video: VIDEO,
    display: DISPLAY,
    social: SOCIAL_MEDIA
};

export const budgetConfig = {
    noThresholdEmail: ['athena.a4user@gmail.com'],
    limits: {
        min: {
            [ACTUAL_USER]: {
                [COMMERCIAL]: {
                    [NATIONAL]: 5000,
                    [LOCAL]: 500
                },
                [POLITICAL]: {
                    [NATIONAL]: 500,
                    [LOCAL]: 500
                }
            },
            [TESTER]: {
                [COMMERCIAL]: {
                    [NATIONAL]: 1,
                    [LOCAL]: 1
                },
                [POLITICAL]: {
                    [NATIONAL]: 1,
                    [LOCAL]: 1
                }
            }
        },
        minErrorText: {
            [COMMERCIAL]: {
                [NATIONAL]: 'Minimum budget is $5,000',
                [LOCAL]: 'Minimum budget is $500'
            },
            [POLITICAL]: {
                [NATIONAL]: 'Minimum budget is $500',
                [LOCAL]: 'Minimum budget is $500'
            }
        },
        maxBudgetLength: 12,
        maxSubBudget: MAX_SUB_BUDGET
    },
    distribution: {
        channels: [
            {
                name: VIDEO,
                key: 'video',
                icon: playSvg,
                subCampaigns: [OTT, PRE_MID_ROLL],
                style: {
                    primaryColor: '#00aa5a',
                    sliderColorName: 'green',
                    percentTextBackgroundColor: '#e5f6ee',
                    shadowColor:
                        '0 10px 19px 0 rgba(0,170,90,0.25), 0 8px 10px 0 rgba(0,170,90,0.15)',
                    theme: 'green',
                    errorColor: '#e31d1a',
                    errorBackgroundColor: 'rgba(227, 29, 26, 0.05)',
                    errorBorderColor: 'rgba(227, 29, 26, 0.16)'
                },
                isDisabled: false,
                roundIndex: 1,
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: true
                }
            },
            {
                name: DISPLAY,
                key: 'display',
                icon: desktopSvg,
                subCampaigns: [BANNERS, AD_MESSENGER],
                style: {
                    primaryColor: '#003380',
                    sliderColorName: 'deepBlue',
                    percentTextBackgroundColor: '#e5ebf5',
                    shadowColor:
                        '0 10px 19px 0 rgba(0,50,128,0.25), 0 8px 10px 0 rgba(0,50,128,0.15)',
                    theme: 'dark',
                    errorColor: '#e31d1a',
                    errorBackgroundColor: 'rgba(227, 29, 26, 0.05)',
                    errorBorderColor: 'rgba(227, 29, 26, 0.16)'
                },
                isDisabled: false,
                roundIndex: 2,
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: true
                }
            },
            {
                name: SOCIAL_MEDIA,
                key: 'social',
                icon: instagramSvg,
                subCampaigns: [FACEBOOK, INSTAGRAM],
                style: {
                    primaryColor: '#0090AA',
                    sliderColorName: 'teal',
                    percentTextBackgroundColor: '#e5f3f6',
                    shadowColor:
                        '0 10px 19px 0 rgba(0,144,170,0.25), 0 8px 10px 0 rgba(0,144,170,0.25)',
                    theme: 'teal',
                    errorColor: '#e31d1a',
                    errorBackgroundColor: 'rgba(227, 29, 26, 0.05)',
                    errorBorderColor: 'rgba(227, 29, 26, 0.16)'
                },
                isDisabled: false,
                roundIndex: 3,
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: false
                }
            }
        ],
        subCampaigns: {
            [OTT]: {
                name: OTT,
                key: 'ott',
                context: 'ott',
                medium: 'video',
                primary_target_demo_id: 0,
                primary_target_demo_cpm: 0,
                network_exclusions: [],
                spot_length: 30,
                min: {
                    [ACTUAL_USER]: {
                        [NATIONAL]: 500,
                        [LOCAL]: 500
                    },
                    [TESTER]: {
                        [NATIONAL]: 1,
                        [LOCAL]: 1
                    }
                },
                minErrorText: {
                    [NATIONAL]: 'Minimum is $500',
                    [LOCAL]: 'Minimum is $500'
                },
                dsp: 'Appnexus',
                isUsedForMediaPlan: true,
                isDisabled: false,
                geo: {
                    [NATIONAL]: true,
                    [LOCAL]: true
                },
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: true
                },
                roundIndex: 1,
                urlRequired: [],
                creativeRequired: ['ott']
            },
            [PRE_MID_ROLL]: {
                name: PRE_MID_ROLL,
                key: 'preMidRoll',
                context: 'in stream',
                medium: 'video',
                primary_target_demo_id: 0,
                primary_target_demo_cpm: 0,
                network_exclusions: [],
                spot_length: 30,
                min: {
                    [ACTUAL_USER]: {
                        [NATIONAL]: 250,
                        [LOCAL]: 250
                    },
                    [TESTER]: {
                        [NATIONAL]: 1,
                        [LOCAL]: 1
                    }
                },
                minErrorText: {
                    [NATIONAL]: 'Minimum is $250',
                    [LOCAL]: 'Minimum is $250'
                },
                dsp: 'Appnexus',
                isUsedForMediaPlan: true,
                isDisabled: false,
                geo: {
                    [NATIONAL]: true,
                    [LOCAL]: true
                },
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: true
                },
                roundIndex: 2,
                urlRequired: [],
                creativeRequired: ['preMidRoll']
            },
            [BANNERS]: {
                name: BANNERS,
                key: 'banners',
                context: 'banner',
                medium: 'display',
                primary_target_demo_id: 0,
                primary_target_demo_cpm: 0,
                network_exclusions: [],
                spot_length: 30,
                min: {
                    [ACTUAL_USER]: {
                        [NATIONAL]: 200,
                        [LOCAL]: 200
                    },
                    [TESTER]: {
                        [NATIONAL]: 1,
                        [LOCAL]: 1
                    }
                },
                minErrorText: {
                    [NATIONAL]: 'Minimum is $200',
                    [LOCAL]: 'Minimum is $200'
                },
                dsp: 'Appnexus',
                isUsedForMediaPlan: true,
                isDisabled: false,
                geo: {
                    [NATIONAL]: true,
                    [LOCAL]: true
                },
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: true
                },
                roundIndex: 1,
                urlRequired: [],
                creativeRequired: ['banners']
            },
            [AD_MESSENGER]: {
                name: AD_MESSENGER,
                key: 'adMessenger',
                context: 'admessenger',
                medium: 'display',
                primary_target_demo_id: 0,
                primary_target_demo_cpm: 0,
                network_exclusions: [],
                spot_length: 30,
                min: {
                    [ACTUAL_USER]: {
                        [NATIONAL]: 500,
                        [LOCAL]: 500
                    },
                    [TESTER]: {
                        [NATIONAL]: 1,
                        [LOCAL]: 1
                    }
                },
                minErrorText: {
                    [NATIONAL]: 'Minimum is $500',
                    [LOCAL]: 'Minimum is $500'
                },
                dsp: 'Appnexus',
                isUsedForMediaPlan: false,
                isDisabled: false,
                geo: {
                    [NATIONAL]: true,
                    [LOCAL]: true
                },
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: false
                },
                roundIndex: 2,
                urlRequired: ['adMessenger'],
                creativeRequired: []
            },
            [FACEBOOK]: {
                name: FACEBOOK,
                key: 'facebook',
                context: 'facebook',
                medium: 'social',
                primary_target_demo_id: 0,
                primary_target_demo_cpm: 0,
                network_exclusions: [],
                spot_length: 30,
                min: {
                    [ACTUAL_USER]: {
                        [NATIONAL]: 200,
                        [LOCAL]: 200
                    },
                    [TESTER]: {
                        [NATIONAL]: 1,
                        [LOCAL]: 1
                    }
                },
                minErrorText: {
                    [NATIONAL]: 'Minimum is $200',
                    [LOCAL]: 'Minimum is $200'
                },
                dsp: 'Appnexus',
                isUsedForMediaPlan: false,
                isDisabled: false,
                geo: {
                    [NATIONAL]: true,
                    [LOCAL]: true
                },
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: false
                },
                roundIndex: 1,
                urlRequired: ['facebook'],
                creativeRequired: ['facebook']
            },
            [INSTAGRAM]: {
                name: INSTAGRAM,
                key: 'instagram',
                context: 'instagram',
                medium: 'social',
                primary_target_demo_id: 0,
                primary_target_demo_cpm: 0,
                network_exclusions: [],
                spot_length: 30,
                min: {
                    [ACTUAL_USER]: {
                        [NATIONAL]: 200,
                        [LOCAL]: 200
                    },
                    [TESTER]: {
                        [NATIONAL]: 1,
                        [LOCAL]: 1
                    }
                },
                minErrorText: {
                    [NATIONAL]: 'Minimum is $200',
                    [LOCAL]: 'Minimum is $200'
                },
                dsp: 'Appnexus',
                isUsedForMediaPlan: false,
                isDisabled: false,
                geo: {
                    [NATIONAL]: true,
                    [LOCAL]: true
                },
                role: {
                    [COMMERCIAL]: true,
                    [POLITICAL]: false
                },
                roundIndex: 2,
                urlRequired: ['facebook', 'instagram'],
                creativeRequired: ['instagram']
            }
        }
    }
};
