import request from '../request';
import axios from 'axios';

export default function (file, options = {}) {
    const formData = new FormData();
    formData.append('customList', file);

    Object.keys(options).forEach(option => {
        formData.append(option, options[option]);
    });

    return axios.post('/api/custom_list/', formData, {
        headers: {
            'Content-Type': 'multipart/form-data'
        }
    }).then(response => {
        return response;
    }).catch(error => {
        return Promise.reject(error.response);
    });
};
