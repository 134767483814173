import request from '../request';

export default function (zips) {
    const req = {
        method: 'POST',
        url: `locations/zips`,
        data: zips
    };

    return request(req);
}
