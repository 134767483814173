import { createAction } from '@reduxjs/toolkit';
import { TOGGLE_IS_INDICATOR_MOUNTED } from '../constants';

export const toggleIsIndicatorMounted = createAction(
    TOGGLE_IS_INDICATOR_MOUNTED,
    isMounted => {
        return {
            payload: { isMounted }
        };
    }
);
