import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import featuresManager from '@featuresManager/';
import { Card, Layout, Text, Icon } from 'maslow';
import styles from '../Distribution/styles.scss';
import { budgetCalc } from '@services/budget';
import { PLAN_STATUSES } from '@config/config.planStatus';

import {
    budgetSelector,
    regionSelector,
    getLockedSubCampaignsSelector,
    getLockedChannelsSelector,
    childErrorSelector,
    channelDisabledSelector
} from '@selectors/';

import { updateBudget } from '@state/budget/actions';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import { setIsValid } from '@state/creative/actions';

import removeSvg from '@public/img/remove.svg';
import editSvg from '@public/img/edit.svg';

import { idBuilder } from '@services';
import {
    BUDGET_EDIT_CHANNEL,
    BUDGET_REMOVE_CHANNEL
} from '@config/idConstants';

const ChannelControls = ({
    config,
    toggleEditing,
    isEditing,
    disableChannel,
    disabledChannelsKeys
}) => {
    const { key, name } = config;
    const dispatch = useDispatch();
    const [role] = featuresManager.getCustomFilterState('role');

    const budget = useSelector(budgetSelector);
    const region = useSelector(regionSelector);
    const lockedSubCampaigns = useSelector(getLockedSubCampaignsSelector);
    const lockedChannels = useSelector(getLockedChannelsSelector);
    const hasChildError = useSelector(childErrorSelector(name));
    const disabledClass = useSelector(channelDisabledSelector)
        ? styles.disabledWrapper
        : '';

    const updateState = () => {
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        dispatch(setIsValid(false));

        const newAmounts = budgetCalc.channel(
            key,
            0,
            budget,
            false,
            lockedSubCampaigns,
            lockedChannels,
            role,
            region,
            disabledChannelsKeys
        );
        dispatch(updateBudget.setIsAutoDistributed(false));
        dispatch(updateBudget.channel(newAmounts));
    };

    const editButtonStyle = isEditing
        ? [styles.editButton, styles.editButtonClicked].join(' ')
        : styles.editButton;

    const removeChannel = key => {
        disableChannel(key);
        updateState();
    };

    return (
        <Card
            borderRadius="2px"
            boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
            className={styles.cardButtons}
            backgroundColor="white">
            <Layout
                id={idBuilder(BUDGET_EDIT_CHANNEL, key)}
                flexDirection="column"
                vAlign="center"
                hAlign="center"
                className={`${editButtonStyle} ${styles.controlBtn} ${disabledClass}`}
                onClick={() => toggleEditing(key)}>
                <Icon src={editSvg} size={2} className={styles.aroundMargin} />
                {hasChildError && <span className={styles.errorDot}></span>}
                <Text type="body" className={styles.aroundMargin}>
                    Edit
                </Text>
            </Layout>
            <Layout
                id={idBuilder(BUDGET_REMOVE_CHANNEL, key)}
                flexDirection="column"
                vAlign="center"
                hAlign="center"
                className={`${styles.removeButton} ${styles.controlBtn} ${disabledClass}`}
                onClick={() => removeChannel(key)}>
                <Icon
                    src={removeSvg}
                    size={2}
                    className={styles.aroundMargin}
                />
                <Text type="body" className={styles.aroundMargin}>
                    Remove
                </Text>
            </Layout>
        </Card>
    );
};

ChannelControls.propTypes = {
    config: PropTypes.object.isRequired,
    toggleEditing: PropTypes.func.isRequired,
    isEditing: PropTypes.bool.isRequired,
    disableChannel: PropTypes.func.isRequired,
    disabledChannelsKeys: PropTypes.array
};

export default ChannelControls;
