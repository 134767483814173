import React from 'react';
import { Layout } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';
import shared from '@sharedStyle/page.scss';

const Ranker = props => {
    const { components } = getFeatures('ranker', { props });

    return <Layout className={shared.pageWrapper}>{components}</Layout>;
};

export default withFeatures(Ranker);
