import * as d3 from 'd3';

export default (impressionsDelivered, totalBudgeted) => {
    const wrapper = d3.select('#deliveryProgressChart'),
        pi = Math.PI,
        tempProgress = impressionsDelivered / totalBudgeted,
        formatText = d3.format('.0%'),
        border = 20,
        tempBoxSize = wrapper.node().clientWidth;

    const progressText =
        tempProgress < 0.005 ? '<1%' : formatText(tempProgress);
    const colors = {
        vividNavy: '#2e5eff',
        paleCyan: '#d9e1e2',
        gray: '#b2b2b2'
    };
    const boxSize = tempBoxSize > 175 ? tempBoxSize : 175;
    const radius = boxSize / 2;
    const textHeight = -(radius / 3);
    const tempProgressAngle = tempProgress * pi;
    const minProgressAngle = (border / (2 * pi * radius)) * (2 * pi);
    const progressAngle =
        tempProgressAngle > minProgressAngle
            ? tempProgressAngle
            : minProgressAngle;

    wrapper.select('svg').remove();

    const endAngle = pi / 2,
        startAngle = -(pi / 2);

    const circle = d3
        .arc()
        .startAngle(startAngle)
        .innerRadius(radius)
        .outerRadius(radius - border)
        .cornerRadius(10);

    const svg = wrapper
        .append('svg')
        .attr('width', boxSize)
        .attr('height', boxSize / 2);

    const g = svg
        .append('g')
        .attr(
            'transform',
            'translate(' + boxSize / 2 + ',' + boxSize / 2 + ')'
        );

    const track = g.append('g').attr('class', 'radial-progress');
    track
        .append('path')
        .attr('class', 'radial-progress__background')
        .attr('fill', colors.paleCyan)
        .attr('d', circle.endAngle(endAngle));

    const value = track
        .append('path')
        .attr('class', 'radial-progress__value')
        .attr('fill', colors.vividNavy)
        .attr('d', circle.endAngle(startAngle + progressAngle));

    const numberText = track
        .append('text')
        .attr('class', 'radial-progress__text')
        .attr('fill', colors.gray)
        .attr('text-anchor', 'middle')
        .attr('y', textHeight + 'px')
        .attr('font-size', '20px');

    numberText.text(progressText);
};
