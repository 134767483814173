import React, { useMemo } from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'maslow';
import usePortal from 'react-cool-portal';
import styles from './styles.scss';
import { uiSelector } from '@selectors';

const SpinnerLoader = ({ text = '', isShow }) => {
    return (
        <React.Fragment>
            <div className={styles.loadingWrapper}>
                <svg
                    className={styles.loadingSvg}
                    viewBox="0 0 100 100"
                    xmlns="http://www.w3.org/2000/svg">
                    <circle
                        className={[
                            styles.circleLoader1,
                            styles.circleLoader
                        ].join(' ')}
                        cx="50"
                        cy="50"
                        r="45"
                    />
                    <circle
                        className={[
                            styles.circleLoader2,
                            styles.circleLoader
                        ].join(' ')}
                        cx="50"
                        cy="50"
                        r="30"
                    />
                    <circle
                        className={[
                            styles.circleLoader3,
                            styles.circleLoader
                        ].join(' ')}
                        cx="50"
                        cy="50"
                        r="17"
                    />
                </svg>
                <Text type="h5" className={styles.loaderText}>
                    {text}
                </Text>
            </div>
        </React.Fragment>
    );
};

export const LoadingScreen = () => {
    const {
        loadingScreen: { loadingText, showLoadingScreen }
    } = useSelector(uiSelector);

    const { Portal, show, hide, isShow } = usePortal({
        containerId: 'portal-root',
        defaultShow: false
    });

    showLoadingScreen ? show() : hide();

    return (
        <React.Fragment>
            <Portal>
                <SpinnerLoader text={loadingText} isShow={isShow} />
            </Portal>
        </React.Fragment>
    );
};
