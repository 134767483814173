import jsPDF from '@services/jsPDF';
import { LINEAR_TV, SAVED_AUDIENCES } from '@config';
import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';
import { datesHandler } from '@lib';

import { counterFactory, strf, sortArrayOfObjectsByProp } from '@lib';

import { getFormattedBudget, getFormattedCpm } from '@services/budget';
import {
    getFullWeeks,
    getWeeksLabel,
    getDatesText,
    mapHiatusWeeksDataForUi,
    hasAudience
} from '@services';

import {
    buildBudgetData,
    buildDigitalDetailsData,
    buildDayPartsData,
    buildWeekPartsData,
    buildNetworksData
} from './builders';

import {
    bodyText,
    bodyTextBold,
    bodyTextGrey,
    bodyTextBlue,
    captionText,
    captionTextBlue,
    h1,
    h3,
    h4,
    h5
} from './fontStyles';

import {
    circle,
    circleWithText,
    roundedRectWithText,
    roundedRect,
    audienceCategoryRect,
    getTextWidth,
    getTextHeight
} from './uiStyles';

import {
    MARGIN_5,
    MARGIN_10,
    MARGIN_15,
    MARGIN_17,
    MARGIN_20,
    MARGIN_30,
    MARGIN_40,
    MARGIN_45,
    MARGIN_50,
    MARGIN_55,
    MARGIN_58,
    MARGIN_60,
    MARGIN_70,
    MARGIN_80,
    MARGIN_140,
    MARGIN_160,
    MARGIN_175,
    MARGIN_215,
    FONT_SIZE_10,
    FONT_SIZE_16,
    FONT_SIZE_20,
    FONT_SIZE_24,
    FONT_SIZE_40,
    COLOR_BLACK,
    COLOR_GREY,
    COLOR_BLUE,
    COLOR_TEXT_BLUE,
    COLOR_LIGHT_BLUE,
    COLOR_LIGHT_GREY,
    CELL_WIDTH_01,
    IMAGE_SIZE_5,
    IMAGE_SIZE_15,
    IMAGE_SIZE_20,
    IMAGE_SIZE_33,
    IMAGE_SIZE_38,
    STRIPED_IMAGE_HEIGHT,
    HIATUS_WEEK_HEIGHT,
    COLOR_WHITE,
    COLOR_LAVENDER_BLUE,
    COLOR_CYAN_BLUE,
    COLOR_TOBERNITE,
    COLOR_DENIM,
    COLOR_BLUE_GREEN,
    CUSTOM_LIST_TYPE
} from './constants';

// import { mediaPlanReduxFixture } from '@components/features/MediaPlanTopbar/1.0.0/mediaPlanReduxFixture';
// const { mediaPlan } = mediaPlanReduxFixture;

const header = (doc, count, text) => {
    doc.setFontType('bold');
    doc.setFontSize(FONT_SIZE_16);
    doc.centeredText(text, count);
    doc.setFontType('normal');
};

const drawDividerLine = (doc, counter, width) => {
    const pos = counter.add(MARGIN_20 + FONT_SIZE_16 - MARGIN_10 / 2);
    doc.setDrawColor(200, 209, 218);
    doc.line(MARGIN_30, pos, width - MARGIN_30, pos);
};

const pageHeader = (doc, counter, text, width) => {
    h3(doc).text(text, MARGIN_30, counter.add(MARGIN_40));
    doc.setTextColor(...COLOR_BLACK);
    doc.setFontSize(FONT_SIZE_16);
    doc.setFontType('normal');

    drawDividerLine(doc, counter, width);
};

const audienceColors = [
    { circle: COLOR_TEXT_BLUE, background: COLOR_LAVENDER_BLUE },
    { circle: COLOR_DENIM, background: COLOR_CYAN_BLUE },
    { circle: COLOR_BLUE_GREEN, background: COLOR_TOBERNITE }
];

const audienceHeader = (doc, counter, count, width) => {
    const headerText = count > 1 ? 'Audiences' : 'Audience';

    circleWithText(
        doc,
        MARGIN_30,
        counter.add(MARGIN_40),
        count,
        false,
        10,
        COLOR_TEXT_BLUE,
        COLOR_LIGHT_BLUE
    );
    h3(doc).text(`${headerText} Selected`, 55, counter.add(15));
    doc.setTextColor(...COLOR_BLACK);
    doc.setFontSize(FONT_SIZE_16);
    doc.setFontType('normal');

    drawDividerLine(doc, counter, width);
};

const audienceSubHeader = (doc, counter, index) => {
    circle(
        doc,
        MARGIN_30,
        counter.add(MARGIN_40),
        false,
        3,
        audienceColors[index].circle
    );
    bodyTextBold(doc).text(`Audience ${index + 1}`, 40, counter.add(6.5));
    doc.setTextColor(...COLOR_BLACK);
    doc.setFontSize(FONT_SIZE_16);
    doc.setFontType('normal');
};

const formatPlanName = name => strf(name).replace(' ', '-').value();

/**
 * this function accepts plan data and creates
 * side effects that generate and download a PDF
 * that loosely replicates the Media Plan Summary page
 *
 * @param {Object} planData
 *     @param {Object} attributes
 *     @param {Object} schedule
 *     @param {Object} audience
 *     @param {Object} budget
 *     @param {String} region
 *     @param {Object} mediaPlan
 *     @param {Image} logo
 * @returns {undefined}
 */
export const createMediaPlanPDF = ({
    attributes,
    schedule,
    audience,
    budget,
    region,
    selectedLocations,
    locationArray,
    mediaPlan,
    customList,
    logo,
    mapImages,
    icons,
    hhCount,
    percentage,
    showHawaiiAndAlaska,
    mappedDigitalDetailsForUi,
    totalDigitalBudget,
    totalDigitalImpression,
    totalDigitalCpm,
    totalImpressions,
    totalCpm,
    digitalProductDetails
}) => {
    const counter = counterFactory();
    const doc = new jsPDF({
        orientation: 'p',
        unit: 'px',
        format: 'a4',
        putOnlyUsedFonts: true
    });

    const { name, advertiser } = attributes;
    const HousholdsValue = strf(hhCount).commas().value();

    const { startDate, endDate, hiatusWeeks } = schedule;
    const { startDateText, endDateText } = getDatesText(
        startDate,
        endDate,
        true
    );
    const mappedHiatusWeeks = mapHiatusWeeksDataForUi(hiatusWeeks);
    const weeks = getFullWeeks(startDate, endDate);
    const weeksLabel = getWeeksLabel({ weeks });
    const { total, channels, subCampaigns } = budget;
    const totalBudget = getFormattedBudget(total);
    const totalDigitalBudgetFormatted = getFormattedBudget(totalDigitalBudget);
    const totalDigitalCpmFormatted = getFormattedCpm(totalDigitalCpm);
    const totalDigitalImpressionFormatted = strf(totalDigitalImpression)
        .commas()
        .value();
    const { tvDetails, hasTvDetails, a4PlanId } = mediaPlan;
    const { primaryTarget } = tvDetails;
    const tvSpend = subCampaigns[LINEAR_TV]
        ? subCampaigns[LINEAR_TV].budget
        : 0;

    const tvSpendFormatted = getFormattedBudget(tvSpend);
    const tvImpressionsValue = tvSpend === 0 ? 0 : tvDetails.tvImpressions;
    const tvImpressionsFormatted = strf(tvImpressionsValue).commas().value();
    const tvCpm = tvSpend === 0 || !primaryTarget ? 0 : primaryTarget.cpm;
    const tvCpmFormatted = getFormattedCpm(tvCpm);
    const primaryDemoName =
        tvSpend === 0 || !primaryTarget ? '-' : primaryTarget.primaryDemoName;
    const formattedTotalImpressions = strf(totalImpressions).commas().value();
    const formattedTotalCpm = getFormattedCpm(totalCpm);

    const hasSelections = selectedLocations.length > 0;
    const locationTitle = hasSelections
        ? 'Selected Locations:'
        : 'Targeting All Households';
    const customListFileName = customList.file_name
        .match(/[^\/]*$/)[0]
        .slice(0, -4);

    const pageWidth = doc.internal.pageSize.width;
    const pageHeight = doc.internal.pageSize.height;
    const RIGHT_IMG_CAPTION_MARGIN = pageWidth - 97;
    const RIGHT_IMG_MARGIN = pageWidth - 135;
    const pageHeightMinusBottomMargin = pageHeight - 45;

    const reorderAudiencesByCategory = audience => {
        const uniqueCategories = [
            ...new Set(audience.map(option => option.category))
        ];
        return uniqueCategories.map(categoryName => ({
            categoryName,
            options: audience.filter(option => option.category === categoryName)
        }));
    };

    const addLogo = doc => {
        doc.addImage(
            logo,
            'PNG',
            pageWidth - 95,
            pageHeight - MARGIN_40,
            80,
            28,
            undefined,
            'FAST'
        );
    };

    const addUsaMap = doc => {
        doc.addImage(
            mapImages.usa,
            'PNG',
            170,
            90,
            230,
            128,
            undefined,
            'FAST'
        );
    };

    const addAlaskaMap = doc => {
        doc.addImage(
            mapImages.alaska,
            'PNG',
            165,
            185,
            67,
            57,
            undefined,
            'FAST'
        );
    };

    const addHawaiiMap = doc => {
        doc.addImage(
            mapImages.hawaii,
            'PNG',
            230,
            counter.add(60),
            55,
            45,
            undefined,
            'FAST'
        );
    };

    const addPage = (yPos = pageHeight) => {
        if (yPos >= pageHeightMinusBottomMargin) {
            doc.addPage();
            counter.set(0);
        }
    };

    // Start building PDF
    addLogo(doc);

    doc.addImage(
        icons.rightArrowImg,
        'PNG',
        MARGIN_30,
        counter.add(MARGIN_70),
        IMAGE_SIZE_15,
        10,
        undefined,
        'FAST'
    );
    doc.setFontSize(FONT_SIZE_16);
    doc.setTextColor(...COLOR_GREY);
    doc.text('Media Plan Summary', MARGIN_50, counter.add(MARGIN_10));
    doc.text(`Plan ID: ${a4PlanId}`, MARGIN_50, counter.add(MARGIN_20));
    const splitPlanName = doc.splitTextToSize(name || 'Test Plan Name', 150);
    h1(doc).text(splitPlanName, MARGIN_30, counter.add(MARGIN_40));

    const splitAdvertiserName = doc.splitTextToSize(
        advertiser || 'Test Advertiser Name',
        350
    );

    const AdvertiserNameHeight =
        splitAdvertiserName.length * getTextHeight(doc, splitAdvertiserName);
    const AdvertiserYValue = pageHeight - (MARGIN_70 + AdvertiserNameHeight);

    doc.setFontSize(FONT_SIZE_40);
    doc.setTextColor(...COLOR_GREY);
    doc.text('for', MARGIN_30, AdvertiserYValue - MARGIN_40);

    h1(doc).text(splitAdvertiserName, MARGIN_30, AdvertiserYValue);

    //// Summary Section
    addPage();

    pageHeader(doc, counter, 'Summary', pageWidth);

    bodyText(doc).text('Households', MARGIN_30, counter.add(MARGIN_30));
    h3(doc).text(HousholdsValue, MARGIN_30, counter.add(MARGIN_30));

    const perentageText = 'or ' + percentage + '%';
    const percentageTextWidth = getTextWidth(doc, perentageText);
    const width = percentageTextWidth + 14;

    roundedRectWithText(
        doc,
        MARGIN_30,
        counter.add(MARGIN_10),
        width,
        12,
        6,
        perentageText,
        percentageTextWidth,
        COLOR_TEXT_BLUE,
        COLOR_LIGHT_BLUE
    );

    if (mapImages.usa) addUsaMap(doc);
    if (showHawaiiAndAlaska) {
        if (mapImages.alaska) addAlaskaMap(doc);
        if (mapImages.hawaii) addHawaiiMap(doc);
    }

    if (!hasSelections) {
        circleWithText(doc, MARGIN_30, counter.add(MARGIN_40), '');

        bodyTextBold(doc).text(locationTitle, MARGIN_55, counter.add(12));
    }

    if (hasSelections) {
        circleWithText(
            doc,
            MARGIN_30,
            counter.add(MARGIN_40),
            selectedLocations.length
        );

        bodyTextBold(doc).text(locationTitle, MARGIN_55, counter.add(12));

        for (let i = 0; i < locationArray.length; i++) {
            if (locationArray[i].entries.length) {
                captionText(doc).text(
                    locationArray[i].title + ':',
                    MARGIN_30,
                    counter.add(MARGIN_30)
                );

                let x = MARGIN_30;
                let y = 6;

                for (let j = 0; j < locationArray[i].entries.length; j++) {
                    const locationName = locationArray[i].entries[j].name;
                    const textWidth = getTextWidth(doc, locationName);
                    const w = textWidth + 14;
                    roundedRectWithText(
                        doc,
                        x,
                        counter.add(y),
                        w,
                        12,
                        2,
                        locationName,
                        textWidth,
                        COLOR_TEXT_BLUE,
                        COLOR_LIGHT_BLUE
                    );
                    x += w + 3;

                    if (x >= pageWidth - 125) {
                        y += 15;
                        x = MARGIN_30;
                        addPage(counter.count + y + 30);
                    } else {
                        y = 0;
                    }
                }
            }
        }
    }

    //// Schedule section
    const SCHEDULE_HEIGHT = 176;
    addPage(counter.count + SCHEDULE_HEIGHT);

    drawDividerLine(doc, counter, pageWidth);

    doc.addImage(
        icons.scheduleImg,
        'PNG',
        MARGIN_20,
        counter.add(MARGIN_15),
        IMAGE_SIZE_38,
        IMAGE_SIZE_38,
        undefined,
        'FAST'
    );
    bodyText(doc).text('Schedule', MARGIN_58, counter.add(MARGIN_17));
    h4(doc).text(startDateText, MARGIN_30, counter.add(MARGIN_30));
    doc.addImage(
        icons.stripedLineImg,
        'PNG',
        103,
        counter.add(-MARGIN_5),
        IMAGE_SIZE_33,
        STRIPED_IMAGE_HEIGHT,
        undefined,
        'FAST'
    );
    circleWithText(doc, MARGIN_140, counter.add(-MARGIN_5), weeks, true);
    captionText(doc).text(weeksLabel, 153, counter.add(8));
    doc.addImage(
        icons.stripedLineImg,
        'PNG',
        178,
        counter.add(-3),
        IMAGE_SIZE_33,
        STRIPED_IMAGE_HEIGHT,
        undefined,
        'FAST'
    );
    h4(doc).text(endDateText, MARGIN_215, counter.add(MARGIN_5));

    doc.addImage(
        icons.budgetImg,
        'PNG',
        RIGHT_IMG_MARGIN,
        counter.add(-47),
        IMAGE_SIZE_38,
        IMAGE_SIZE_38,
        undefined,
        'FAST'
    );
    h4(doc).text(totalBudget, RIGHT_IMG_CAPTION_MARGIN, counter.add(MARGIN_17));
    captionText(doc).text(
        'Budget',
        RIGHT_IMG_CAPTION_MARGIN,
        counter.add(MARGIN_15)
    );

    doc.addImage(
        icons.impressionsImg,
        'PNG',
        RIGHT_IMG_MARGIN,
        counter.add(MARGIN_10),
        IMAGE_SIZE_38,
        IMAGE_SIZE_38,
        undefined,
        'FAST'
    );
    h4(doc).text(
        formattedTotalImpressions,
        RIGHT_IMG_CAPTION_MARGIN,
        counter.add(MARGIN_17)
    );
    captionText(doc).text(
        'Impressions',
        RIGHT_IMG_CAPTION_MARGIN,
        counter.add(MARGIN_15)
    );

    doc.addImage(
        icons.cpmImg,
        'PNG',
        RIGHT_IMG_MARGIN,
        counter.add(MARGIN_10),
        IMAGE_SIZE_38,
        IMAGE_SIZE_38,
        undefined,
        'FAST'
    );
    h4(doc).text(
        formattedTotalCpm,
        RIGHT_IMG_CAPTION_MARGIN,
        counter.add(MARGIN_17)
    );

    captionText(doc).text(
        'CPM',
        RIGHT_IMG_CAPTION_MARGIN,
        counter.add(MARGIN_15)
    );

    ////// Hiatus weeks (if selected)
    if (hiatusWeeks.length) {
        captionText(doc).text(
            'Hiatus weeks:',
            MARGIN_30,
            counter.add(-MARGIN_15)
        );

        Object.keys(mappedHiatusWeeks).forEach((year, j) => {
            const hiatusWeeksByYear = mappedHiatusWeeks[year];
            const yPos = j == 0 ? MARGIN_20 : MARGIN_30;
            addPage(counter.count + yPos + HIATUS_WEEK_HEIGHT);
            captionText(doc).text(year, MARGIN_30, counter.add(yPos));
            doc.addImage(
                icons.rightArrowImg,
                'PNG',
                MARGIN_50,
                counter.add(-MARGIN_5),
                IMAGE_SIZE_15,
                IMAGE_SIZE_5,
                undefined,
                'FAST'
            );
            let x = 68;
            let y = -3;

            hiatusWeeksByYear.forEach((hiatusWeek, i) => {
                const histusWeekText = `${hiatusWeek.from} - ${hiatusWeek.to}`;
                const textWidth = getTextWidth(doc, histusWeekText);
                const w = textWidth + 6;
                roundedRectWithText(
                    doc,
                    x,
                    counter.add(y),
                    w,
                    HIATUS_WEEK_HEIGHT,
                    2,
                    histusWeekText,
                    textWidth,
                    COLOR_BLACK,
                    COLOR_LIGHT_GREY
                );
                x += w + 4;
                if (i === 0) {
                    y += 3;
                } else if ((i + 1) % 5 === 0) {
                    y += 20;
                    x = 68;
                    if (i < hiatusWeeksByYear.length - 1) {
                        addPage(counter.count + y + HIATUS_WEEK_HEIGHT);
                    }
                } else {
                    y = 0;
                }
            });
        });
    }

    //// Audience section - only if audience segments have been selected
    if (hasAudience(audience.audiences)) {
        addPage();
        const audiencesCount = audience.audiences.length;
        audienceHeader(doc, counter, audiencesCount, pageWidth);

        if (audiencesCount > 1) {
            doc.addImage(
                audience.vennPngDataUrl,
                'PNG',
                pageWidth / 2 - 50,
                counter.add(MARGIN_10)
            );
            counter.add(MARGIN_50);
        }

        audience.audiences.map((a, index) => {
            audienceSubHeader(doc, counter, index);

            if (a[0]?.category === SAVED_AUDIENCES) {
                addPage(counter.count + 50);
                roundedRect(
                    doc,
                    MARGIN_30,
                    counter.add(MARGIN_10),
                    pageWidth - MARGIN_60,
                    50,
                    3,
                    audienceColors[index].background
                );

                doc.addImage(
                    icons.uploadArrowImg,
                    'PNG',
                    MARGIN_45,
                    counter.add(MARGIN_15),
                    IMAGE_SIZE_20,
                    IMAGE_SIZE_20,
                    undefined,
                    'FAST'
                );

                bodyTextGrey(doc).text(
                    SAVED_AUDIENCES,
                    MARGIN_80,
                    counter.add(13)
                );

                doc.addImage(
                    icons.rightTriangleImg,
                    'PNG',
                    MARGIN_160,
                    counter.add(-MARGIN_5),
                    IMAGE_SIZE_5,
                    IMAGE_SIZE_5,
                    undefined,
                    'FAST'
                );

                bodyTextBold(doc).text(
                    a[0].name,
                    MARGIN_175,
                    counter.add(MARGIN_5)
                );
            } else {
                const audienceByCategory = reorderAudiencesByCategory(a);
                audienceByCategory.map((a, i) => {
                    const categoryTextWidth =
                        getTextWidth(doc, a.categoryName) + 10;
                    const sidesMargin = 45;
                    const categoryTextWithMarginWidth =
                        categoryTextWidth + sidesMargin + 18;
                    const isTextWrapped =
                        categoryTextWithMarginWidth > pageWidth / 2;
                    const categoryNameText = isTextWrapped
                        ? doc.splitTextToSize(a.categoryName, pageWidth / 2)
                        : a.categoryName;
                    const xPosForOption =
                        categoryTextWidth + sidesMargin < MARGIN_140
                            ? MARGIN_140
                            : isTextWrapped
                            ? 300
                            : categoryTextWithMarginWidth;

                    const rowHeight = 18;
                    const minOptionTextWidth = 25;
                    const defaultOptionTextWidth = 30;
                    const yPosForCategory = i === 0 ? MARGIN_10 : MARGIN_30;

                    let _x = xPosForOption;
                    let numOfRows = 1;
                    a.options.map((option, i) => {
                        const textWidth = getTextWidth(doc, option.name);
                        const textWidthWithPadding = textWidth + 14;

                        const w =
                            textWidthWithPadding >= minOptionTextWidth
                                ? textWidthWithPadding
                                : defaultOptionTextWidth;
                        if (i !== 0 && _x + w > pageWidth - sidesMargin) {
                            numOfRows++;
                            _x = xPosForOption;
                        }
                        _x += w + 3;
                    });

                    const categoryRectHeight = numOfRows * rowHeight + 10;
                    addPage(counter.count + categoryRectHeight);
                    audienceCategoryRect(
                        doc,
                        MARGIN_30,
                        counter.add(yPosForCategory),
                        pageWidth - MARGIN_60,
                        categoryRectHeight,
                        3,
                        categoryNameText,
                        COLOR_BLACK,
                        audienceColors[index].background
                    );

                    let x = xPosForOption;
                    let y = 6;

                    a.options.map((option, i) => {
                        const textWidth = getTextWidth(doc, option.name);
                        const textWidthWithPadding = textWidth + 14;

                        const w =
                            textWidthWithPadding >= minOptionTextWidth
                                ? textWidthWithPadding
                                : defaultOptionTextWidth;
                        if (i !== 0) {
                            if (x + w > pageWidth - sidesMargin) {
                                y = rowHeight;
                                x = xPosForOption;
                                addPage(counter.count + y + MARGIN_30);
                            } else {
                                y = 0;
                            }
                        }
                        roundedRectWithText(
                            doc,
                            x,
                            counter.add(y),
                            w,
                            15,
                            8,
                            option.name,
                            textWidth,
                            COLOR_BLACK,
                            COLOR_WHITE
                        );
                        x += w + 3;
                    });
                });
            }
        });
    }

    //// Budget section
    addPage();

    pageHeader(doc, counter, 'Budget', pageWidth);
    header(doc, counter.add(MARGIN_40), 'Total Budget');
    doc.centeredText(totalBudget, counter.add(MARGIN_20));

    counter.add(MARGIN_10);
    buildBudgetData(
        doc,
        counter,
        total,
        channels,
        subCampaigns,
        tvCpm,
        digitalProductDetails
    );

    //// TV Details section
    if (tvSpend > 0) {
        addPage();

        pageHeader(doc, counter, 'TV Details', pageWidth);

        doc.autoTable({
            startY: counter.add(MARGIN_20),
            headStyles: { fillColor: COLOR_BLUE, cellWidth: CELL_WIDTH_01 },
            head: [['Budget', 'Impressions', 'CPM', 'Primary Demo']],
            body: [
                [
                    tvSpendFormatted,
                    tvImpressionsFormatted,
                    tvCpmFormatted,
                    primaryDemoName
                ]
            ],
            didDrawPage: data => {
                counter.set(counter.count + data.table.height);
            }
        });

        const hasDistribution = prop => {
            return (
                tvDetails &&
                tvDetails.hasOwnProperty('distribution') &&
                tvDetails.distribution.hasOwnProperty(prop)
            );
        };

        ////// Day Parts
        const dayParts = hasDistribution('Dayparts')
            ? sortArrayOfObjectsByProp(
                  tvDetails.distribution.Dayparts,
                  'BeginHour'
              )
            : [];

        doc.centeredText(
            'Daypart Distribution - by impressions',
            counter.add(MARGIN_20)
        );

        buildDayPartsData(doc, counter, dayParts, tvImpressionsValue);

        ////// Week Parts
        const weekParts = hasDistribution('Weekparts')
            ? tvDetails.distribution.Weekparts
            : [];

        doc.centeredText(
            'Weekday Distribution - by impressions',
            counter.add(MARGIN_20)
        );

        buildWeekPartsData(doc, counter, weekParts, tvImpressionsValue);

        ////// Networks
        addPage();

        const networkDistribution = hasDistribution('Networks')
            ? sortArrayOfObjectsByProp(
                  tvDetails.distribution.Networks,
                  'DeliveryEstimate',
                  true
              )
            : [];

        doc.centeredText(
            'Network Distribution - by impressions',
            counter.add(MARGIN_40)
        );

        buildNetworksData(
            doc,
            counter,
            networkDistribution,
            tvImpressionsValue
        );
    }

    //// Digital Details section
    if (totalDigitalBudget > 0) {
        addPage();

        pageHeader(doc, counter, 'Digital Details', pageWidth);

        doc.autoTable({
            startY: counter.add(MARGIN_20),
            headStyles: { fillColor: COLOR_BLUE, cellWidth: CELL_WIDTH_01 },
            head: [['Budget', 'Impressions', 'CPM']],
            body: [
                [
                    totalDigitalBudgetFormatted,
                    totalDigitalImpressionFormatted,
                    totalDigitalCpmFormatted
                ]
            ],
            didDrawPage: data => {
                counter.set(counter.count + data.table.height);
            }
        });

        doc.centeredText(
            'Digital Distribution - by impressions',
            counter.add(MARGIN_20)
        );

        buildDigitalDetailsData(
            doc,
            counter,
            mappedDigitalDetailsForUi,
            totalDigitalImpression
        );
    }

    //// Signature section
    const startX = pageWidth / 6;
    const endX = pageWidth - pageWidth / 6;
    const dateX = endX - 20;
    const termsX = pageWidth / 4 - 5;
    const agreeX = pageWidth / 7 - 8;
    const generateDateText = y => doc.text('Date', dateX, y);

    doc.setFontSize(FONT_SIZE_10);
    doc.setTextColor(...COLOR_GREY);

    const signatureLineYPos01 = counter.add(MARGIN_40 + MARGIN_40 + MARGIN_40);

    doc.line(startX, signatureLineYPos01, endX, signatureLineYPos01);
    doc.text(
        'Accepted by Advertiser (Signature)',
        startX,
        counter.add(MARGIN_10)
    );
    generateDateText(counter.count);

    const signatureLineYPos02 = counter.add(MARGIN_20);

    doc.line(startX, signatureLineYPos02, endX, signatureLineYPos02);
    doc.text(
        'Accepted by Account Executive (AE Signature)',
        startX,
        counter.add(MARGIN_10)
    );
    generateDateText(counter.count);

    const signatureLineYPos03 = counter.add(MARGIN_20);

    doc.line(startX, signatureLineYPos03, endX, signatureLineYPos03);
    doc.text(
        'Accepted by a4 Manager (Mgr. Signature)',
        startX,
        counter.add(MARGIN_10)
    );
    generateDateText(counter.count);

    doc.text(
        'By signing this agreement, you are agreeing to the following Standard Order Terms and Conditions:',
        agreeX,
        counter.add(MARGIN_40)
    );
    doc.text(
        termsAndPolicyConfig.standardTerms,
        termsX,
        counter.add(MARGIN_10)
    );

    //// Footer section
    const pageCount = doc.internal.getNumberOfPages();
    for (let i = 0; i < pageCount; i++) {
        doc.setPage(i);
        doc.text(
            MARGIN_30,
            pageHeight - MARGIN_20,
            datesHandler.format(new Date(), 'MM/dd/yyyy')
        );
        addLogo(doc);
    }

    doc.save(`${formatPlanName(name)}-media-plan.pdf`);
};
