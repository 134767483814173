import React from 'react';
import { Layout, Text, Card, Checkbox, Content } from 'maslow';
import styles from '../styles.scss';
import {
    CUSTOM_LIST_BOOK_TERMS_CHECKBOX,
    CUSTOM_LIST_TERMS
} from '@config/idConstants';
import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';

const AgreeToTerms = props => {
    return (
        <Card backgroundColor="vividNavy">
            <Content>
                <Layout flexDirection="column">
                    <div className={styles.clTextWrapper}>
                        <Text type="h3" color="white">
                            Custom Audience
                        </Text>
                    </div>

                    <div className={styles.clTextWrapper}>
                        <Text type="h4" color="white">
                            Terms of list uploader
                        </Text>
                    </div>

                    <Layout flexDirection="column">
                        <Text color="white" className={styles.captionText}>
                            Custom list files must contain only postal addresses
                            from U.S. households and must
                        </Text>

                        <div className={styles.clTextWrapper}>
                            <Text className={styles.captionText} color="white">
                                comply with other{' '}
                            </Text>
                            <a
                                id={CUSTOM_LIST_TERMS}
                                target="_blank"
                                href={`${termsAndPolicyConfig.termsOfUse}#CustomList`}
                                className={styles.termText}>
                                <Text color="white">
                                    custom list restrictions
                                </Text>
                            </a>
                            <Text color="white">.</Text>
                        </div>

                        <Checkbox
                            id={CUSTOM_LIST_BOOK_TERMS_CHECKBOX}
                            label="I Agree"
                            onChange={props.onChange}
                            theme="inverted"
                            value={props.agreedToTerms}
                        />
                    </Layout>
                </Layout>
            </Content>
        </Card>
    );
};

export default AgreeToTerms;
