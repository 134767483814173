import React from 'react';

import BuilderVenn from './BuilderVenn';

export default function (version, feature) {
    const MultiAudiences = ({ transition }) => {
        return <BuilderVenn isState={transition.router.stateService.is} />;
    };

    return MultiAudiences;
}
