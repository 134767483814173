import { createReducer } from '@reduxjs/toolkit';
import { UPDATE_ACCOUNT, RESET_ACCOUNT } from '../constants';
import { EXTERNAL } from '@config';

const initialState = {
    firstName: '',
    lastName: '',
    email: '',
    id: '',
    verified: false,
    type: [EXTERNAL]
};

const updateAccount = (state, action) => {
    const { account } = action.payload;

    return {
        ...state,
        ...account
    };
};

const resetAccount = () => {
    return { ...initialState };
};

export const account = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_ACCOUNT, updateAccount)
        .addCase(RESET_ACCOUNT, resetAccount)
        .addDefaultCase((state, _) => state);
});
