export default (array, toRemoveArray) => {
    const toRemoveMap = toRemoveArray.reduce((previous, element) => {
        previous[element] = true;
        return previous;
    }, {});

    if (array && array.length) {
        return array.filter(function(element) {
            return !(element in toRemoveMap)
        });
    } else {
        return [];
    }
};
