import { Campaign } from '../components/pages';

export default [
    {
        name: 'app.plan.campaign',
        url: '/campaign',
        views: {
            'content@app': {
                component: Campaign
            }
        }
    }
];
