import { RESET, RESET_PLAN, SET_PLAN, SET_RANKER } from './constants';

export const reset = () => ({
    type: RESET
});

export const resetPlan = () => ({
    type: RESET_PLAN
});

export const setPlan = data => ({
    type: SET_PLAN,
    payload: { data }
});

export const setRanker = () => ({
    type: SET_RANKER
});
