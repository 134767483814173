import request from '../request';

export default function (data) {
    const req = {
        method: `POST`,
        url: `budget/digital_cpm/`,
        data
    };

    return request(req).catch(console.error);
}
