import { createAction } from '@reduxjs/toolkit';
import { ADD_COMBINATIONS, REMOVE_COMBINATIONS } from '../constants';
import { isEmpty } from '@lib';

export const addCombinations = createAction(ADD_COMBINATIONS, combinations => {
    return {
        payload: { combinations }
    };
});

export const removeCombinations = createAction(REMOVE_COMBINATIONS);

export const removeCombinationsIfAudienceEmpty = currentAudienceIndex => {
    return (dispatch, getState) => {
        const {
            audience: { audiences, combinations }
        } = getState();

        if (isEmpty(combinations)) return;

        if (
            audiences[currentAudienceIndex] &&
            isEmpty(audiences[currentAudienceIndex])
        ) {
            dispatch(removeCombinations());
        }
    };
};
