import { createReducer } from '@reduxjs/toolkit';
import {
    UPDATE_TOTAL,
    UPDATE_CHANNEL,
    UPDATE_SUB_CAMPAIGN,
    RESET_BUDGET
} from '../constants';

const initialState = {};

const updateTotal = (state, action) => {
    const {
        distribution: { subCampaigns }
    } = action.payload;

    return typeof subCampaigns === 'object'
        ? { ...state, ...subCampaigns }
        : state;
};

const updateChannel = (state, action) => {
    const {
        distribution: { subCampaigns }
    } = action.payload;

    return typeof subCampaigns === 'object'
        ? { ...state, ...subCampaigns }
        : state;
};

const updateSubCampaign = (state, action) => {
    const { subCampaigns } = action.payload;

    return typeof subCampaigns === 'object'
        ? { ...state, ...subCampaigns }
        : state;
};

const resetBudget = () => {
    return initialState;
};

export const subCampaigns = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_TOTAL, updateTotal)
        .addCase(UPDATE_CHANNEL, updateChannel)
        .addCase(UPDATE_SUB_CAMPAIGN, updateSubCampaign)
        .addCase(RESET_BUDGET, resetBudget)
        .addDefaultCase((state, _) => state);
});
