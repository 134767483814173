import React from 'react';
import PropTypes from 'prop-types';
import { Text, Layout } from 'maslow';
import styles from '../audiences.scss';

const SelectedAudienceOption = ({ option }) => {
    return (
        <Layout className={styles.productTag} key={name}>
            <Text className={styles.tagText} type="input">
                {option.name}
            </Text>
        </Layout>
    );
};

SelectedAudienceOption.propTypes = {
    option: PropTypes.object.isRequired
};

export default SelectedAudienceOption;
