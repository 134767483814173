import React from 'react';
import { datesHandler, concatAndDeDuplicate, excludeFromArray } from '@lib';
import { DateUtils } from 'react-day-picker';
import { POLITICAL, COMMERCIAL } from '@config';
import { idBuilder } from '@services';
import { SCHEDULE_DAYPICKER_DAY } from '@config/idConstants';

export const calendarHelper = {
    firstOfMonth: day => datesHandler
        .isSameDay(day, datesHandler.startOfMonth(day)),
    endOfMonth: day => datesHandler
        .isSameDay(day, datesHandler.startOfMonth(day)),

    getFromDate: (day, oldFrom, focusedType) => {
        return focusedType === 'from'
            ? day
            : oldFrom && DateUtils.isDayBefore(day, oldFrom)
            ? null
            : oldFrom;
    },

    getToDate: (day, oldTo, focusedType) => {
        return focusedType === 'to'
            ? day
            : oldTo && DateUtils.isDayAfter(day, oldTo)
            ? null
            : oldTo;
    },

    renderDay: day => {
        return (
            <div
                className="DayPicker-Day-wrapper"
                id={idBuilder(
                    SCHEDULE_DAYPICKER_DAY,
                    datesHandler.format(day)
                )}>
                {day.getDate()}
            </div>
        );
    },

    addOrExcludeWeek: (previous, week, toAdd) => {
        if (toAdd) return concatAndDeDuplicate(previous, week);
        else return excludeFromArray(previous, week);
    },

    getStartDate: {
        [COMMERCIAL]: min => {
            return datesHandler.businessAddToDate(datesHandler.newDate(), min);
        },
        [POLITICAL]: min => {
            return datesHandler.addToDate(datesHandler.newDate(), min);
        }
    },

    getDaysFromWeeks: weeks => {
        return weeks * 7;
    },

    hideWeeks: () => {
        [...document.querySelectorAll('.DayPicker-Month')].forEach(month => {
            const weeks = [...month.querySelectorAll('.DayPicker-Week')];
            const lastWeekInMonth = weeks.length - 1;

            weeks.forEach((week, i) => {
                [...week.childNodes].forEach(day => {
                    if (
                        [...week.classList].includes(
                            'DayPicker-Week--hide-week'
                        )
                    ) {
                        week.classList.remove('DayPicker-Week--hide-week');
                    }

                    if (
                        [...day.classList].includes('DayPicker-Day--outside') &&
                        ![...week.classList].includes(
                            'DayPicker-Week--hide-week'
                        ) &&
                        i === lastWeekInMonth
                    ) {
                        week.classList.add('DayPicker-Week--hide-week');
                    }
                });
            });
        });
    },

    getUpdatedDateForUI: (date, lastDayOfWeek) => {
        const lastDayOfMonth = datesHandler.endOfMonth(date);
        const lastDayNumber = datesHandler.getDay(lastDayOfMonth);
        if (lastDayNumber !== lastDayOfWeek) {
            const daysToSub = lastDayNumber ? lastDayNumber - 1 : 1;
            const firstDayOfLastWeek = datesHandler.subFromDate(
                lastDayOfMonth,
                daysToSub
            );
            const isWithinInterval = datesHandler.isWithinInterval(
                date,
                firstDayOfLastWeek,
                lastDayOfMonth
            );
            if (isWithinInterval) return datesHandler.addToDate(lastDayOfMonth, 1);
        }
        return date;
    },

    checkEnabledWeek: (week, fromMonth, toMonth) => {
        const isStartDateWithinRange =
            datesHandler.differenceInDays(week[0], fromMonth) > 0;

        const isEndDateWithinRange =
            datesHandler.differenceInDays(toMonth, week[week.length - 1]) > 0;

        return isStartDateWithinRange && isEndDateWithinRange;
    },

    shiftWeekBehind: week => {
        week.unshift(datesHandler.subDays(week[0], 1));
        week.pop();
    },

    shiftWeekAhead: week => {
        week.shift();
        week.push(datesHandler
            .addDays(week[week.length - 1], 1));
    }
};
