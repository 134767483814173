import { isSavedAudience } from '@services/audiencesService';
import {
    replaceAudience,
    resetAudiencesOrder,
    addOptionToAudience,
    removeOptionFromAudience,
    toggleIsAudienceModified,
    fetchHouseholdCount
} from '@state/audience/actions';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { SAVED_AUDIENCES, NETWORKS } from '@config/';
import { PLAN_STATUSES } from '@config/config.planStatus';
import { capitalizeFirstLetters } from '@lib';

const onAudienceChange = dispatch => {
    dispatch(fetchHouseholdCount());
    dispatch(toggleIsAudienceModified(true));
    dispatch(setCampaignRouterId(null));
    dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
};

export const updateAudiences = attribute => {
    return (dispatch, getState) => {
        const {
            audience: { audiences, currentAudienceIndex }
        } = getState();

        const {
            name,
            value,
            id,
            categoryValue,
            category,
            section,
            isSelected
        } = attribute;

        const currentAudience = audiences[currentAudienceIndex];

        const optionToSave =
            category === SAVED_AUDIENCES
                ? {
                      name,
                      id,
                      category
                  }
                : {
                      name,
                      value,
                      id,
                      categoryValue,
                      category,
                      section
                  };

        if (currentAudience.length && isSavedAudience(currentAudience)) {
            dispatch(replaceAudience(currentAudienceIndex, [optionToSave]));
            return onAudienceChange(dispatch);
        }

        if (!isSelected) {
            dispatch(addOptionToAudience(currentAudienceIndex, optionToSave));
        } else {
            dispatch(removeOptionFromAudience(currentAudienceIndex, id));
            dispatch(resetAudiencesOrder(currentAudienceIndex));
        }

        onAudienceChange(dispatch);
    };
};
