// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".logSvg___3QZkA {\n  height: 140px; }\n\n.title___1xN0t {\n  text-align: center;\n  font-weight: bold; }\n\n.subTitle___2N8fP {\n  text-align: center;\n  margin-bottom: 8px; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/common/TopLogoSection/styles.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAGA;EACI,aAAa,EAAA;;AAGjB;EACI,kBAAkB;EAClB,iBAAiB,EAAA;;AAGrB;EACI,kBAAkB;EAClB,kBCde,EAAA","file":"styles.scss","sourcesContent":["@import '~@scss/settings/colors';\n@import '~@scss/settings/baseline';\n\n.logSvg {\n    height: 140px;\n}\n\n.title{\n    text-align: center;\n    font-weight: bold;\n }\n\n.subTitle{\n    text-align: center;\n    margin-bottom: $baseline;\n}","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"logSvg": "logSvg___3QZkA",
	"title": "title___1xN0t",
	"subTitle": "subTitle___2N8fP"
};
module.exports = exports;
