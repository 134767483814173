import { sortArrayOfObjectsByProp, validateArg } from '@lib';
import { isAllChannelsLocked } from './helpers';

export const getRoundPriorities = sections => {
    return sortArrayOfObjectsByProp(
        Object.values(sections).map(({ key, roundIndex }) => {
            return { key, roundIndex };
        }),
        'roundIndex'
    );
};

export const getSubCampaignRoundPriorities = (channels, subCampaigns) => {
    const priorities = {};
    Object.values(channels).forEach(({ key }) => {
        const subs = Object.values(subCampaigns).filter(({ medium }) => {
            return key === medium;
        });
        priorities[key] = getRoundPriorities(subs);
    });
    return priorities;
};

export const getNewPriorities = (sections, priorities, initialIndex) => {
    const priors = isNaN(initialIndex) ? [] : priorities.slice(0, initialIndex);
    const afters = isNaN(initialIndex)
        ? priorities
        : priorities.slice(initialIndex + 1, priorities.length);

    const aftersMoreThanZero = afters.filter(
        ({ key }) => sections[key] && !isNaN(sections[key])
    );
    const priorsMoreThanZero = priors.filter(
        ({ key }) => sections[key] && !isNaN(sections[key])
    );
    const aftersAtZero = afters.filter(
        ({ key }) => !isNaN(sections[key]) && !sections[key]
    );
    const priorsAtZero = priors.filter(
        ({ key }) => !isNaN(sections[key]) && !sections[key]
    );

    return [
        ...aftersMoreThanZero,
        ...priorsMoreThanZero.reverse(),
        ...aftersAtZero,
        ...priorsAtZero.reverse()
    ];
};

export const getPrioritiesBasedOnLockedSections = (
    newPriorities,
    lockedSectionKeys
) => {
    let lockedPriorities = [];
    const unlockedPriorities = newPriorities.filter(channel => {
        if (!lockedSectionKeys.includes(channel['key'])) {
            return true;
        } else {
            lockedPriorities.push(channel);
        }
    });
    return [...unlockedPriorities, ...lockedPriorities];
};

export const getDistributions = (total, distributionConfig) => {
    const distribution = distributionConfig
        .filter(
            d =>
                total >= d.minimumBudget &&
                (total <= d.maximumBudget || d.maximumBudget === null)
        )
        .map(d => d.distributions)[0];
    return distribution || distributionConfig[0].distributions;
};

export const getDistributionBasedOnLockedSections = (
    distributions,
    lockedSectionKeys,
    priorities
) => {
    const updatedDistributions = {};
    Object.keys(distributions).forEach(sub => {
        if (lockedSectionKeys.includes(sub)) {
            const subAmount = updatedDistributions.hasOwnProperty(sub)
                ? updatedDistributions[sub]
                : distributions[sub];
            updatedDistributions[sub] = 0;

            const index = priorities.findIndex(({ key }) => key === sub);
            const newPriorities = getNewPriorities(
                distributions,
                priorities,
                index
            );
            const finalPriorities = getPrioritiesBasedOnLockedSections(
                newPriorities,
                lockedSectionKeys
            );
            if (
                finalPriorities.length &&
                !lockedSectionKeys.includes(finalPriorities[0].key) &&
                (distributions[finalPriorities[0].key] !== 0 ||
                    finalPriorities.length === lockedSectionKeys.length)
            ) {
                const nextSub = finalPriorities[0].key;
                const nextSubAmount = updatedDistributions.hasOwnProperty(
                    nextSub
                )
                    ? updatedDistributions[nextSub]
                    : distributions[nextSub];
                updatedDistributions[nextSub] = subAmount + nextSubAmount;
            }
        }
    });

    return { ...distributions, ...updatedDistributions };
};
export const distribute = (
    oldTotal,
    newTotal,
    sections,
    roundPriorities,
    defaultPercentages,
    isGreaterThanLimit,
    lockedAndExcludedChannels,
    maxBudgets
) => {
    let newSections = {};
    const isAllChannelLocked = isAllChannelsLocked(
        lockedAndExcludedChannels,
        sections
    );
    const isCurrentDistribution =
        oldTotal && (isGreaterThanLimit || isAllChannelLocked);
    Object.keys(sections).forEach(section => {
        const percentage = isCurrentDistribution
            ? sections[section] / oldTotal
            : defaultPercentages[section] || 0;
        const roundedPercent = Math.round(percentage * 100);
        newSections[section] = Math.floor((roundedPercent / 100) * newTotal);
    });

    let difference =
        newTotal - Object.values(newSections).reduce((a, b) => a + b, 0);

    roundPriorities.forEach(({ key }) => {
        if (
            difference > 0 &&
            sections.hasOwnProperty(key) &&
            ((isCurrentDistribution &&
                !lockedAndExcludedChannels.includes(key)) ||
                (!isCurrentDistribution && defaultPercentages[key] !== 0))
        ) {
            newSections[key]++;
            difference--;
        }
    });

    if (!isCurrentDistribution && newTotal <= maxBudgets?.total) {
        newSections = overMaxRedistribute(newSections, maxBudgets);
    }

    return newSections;
};

export const overMaxRedistribute = (sections, maxBudgets) => {
    validateArg(sections, 'object', 1);
    validateArg(maxBudgets, 'object', 2);
    const newSections = sections;

    const sortedSections = sortArrayOfObjectsByProp(
        Object.keys(sections).map(section => ({
            key: section,
            budget: sections[section],
            maxBudget: maxBudgets[section]
        })),
        'budget',
        true
    );

    sortedSections.forEach(({ key, budget, maxBudget }) => {
        //Only trigger redistribution when budget on a given product exceeds maxBudget
        if (newSections[key] > maxBudget) {
            let dif = budget - maxBudget;
            newSections[key] = maxBudgets[key];

            sortedSections.forEach(section => {
                if (
                    section.key !== key &&
                    //Do not redistribute difference to any product that is already equal or over maxBudget
                    section.maxBudget >= newSections[section.key]
                ) {
                    const allowance =
                        section.maxBudget - newSections[section.key];
                    if (dif <= allowance) {
                        //If highest budgeted product has enough allowance for full diff, it will add that to its current budget
                        newSections[section.key] += dif;
                        dif = 0;
                    } else {
                        //If highest budget itself has a maxBudget, then remainder must be pushed on to the next highest option
                        newSections[section.key] = section.maxBudget;
                        dif -= allowance;
                    }
                }
            });
        }
    });
    return newSections;
};

export const distributeSubCampaigns = (
    oldChannels,
    newChannels,
    subCampaigns,
    roundPriorities,
    isGreaterThanLimit,
    lockedSubCampaigns,
    distributions,
    maxBudgets
) => {
    let newSubCampaigns = {};
    Object.keys(roundPriorities).forEach(channel => {
        const sections = {};

        roundPriorities[channel].forEach(({ key }) => {
            if (!isNaN(subCampaigns[key])) sections[key] = subCampaigns[key];
        });

        const defaultPercentages = {};

        distributions
            .find(d => d.channel === channel)
            ?.subcampaigns.forEach(s => {
                defaultPercentages[s.channel] = s.percentage / 100;
            });

        const newSubPriorities = getNewPriorities(
            subCampaigns,
            roundPriorities[channel]
        ).filter(({ key }) => Object.keys(sections).some(x => x === key));

        newSubCampaigns = {
            ...distribute(
                oldChannels[channel],
                newChannels[channel],
                sections,
                newSubPriorities,
                getDistributionBasedOnLockedSections(
                    defaultPercentages,
                    lockedSubCampaigns,
                    newSubPriorities
                ),
                isGreaterThanLimit,
                lockedSubCampaigns,
                maxBudgets
            ),
            ...newSubCampaigns
        };
    });

    return newSubCampaigns;
};

export const changeAmounts = (
    name,
    amount,
    sections,
    priorities,
    lockedSectionKeys
) => {
    const newSections = {
        [name]: amount
    };

    let difference = amount - sections[name];
    const nameIndex = priorities.findIndex(({ key }) => key === name);
    const newPriorities = getNewPriorities(sections, priorities, nameIndex);
    const finalPriorities =
        lockedSectionKeys && lockedSectionKeys.length
            ? getPrioritiesBasedOnLockedSections(
                  newPriorities,
                  lockedSectionKeys
              )
            : newPriorities;

    finalPriorities.forEach(({ key }) => {
        let change = sections[key] - difference;
        const isLocked = lockedSectionKeys && lockedSectionKeys.includes(key);
        newSections[key] = change >= 0 && !isLocked ? change : 0;
        difference = change >= 0 ? 0 : -change;
    });

    return newSections;
};
