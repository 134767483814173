import { createAction } from '@reduxjs/toolkit';
import { TOGGLE_IS_AUDIENCE_MODIFIED } from '../constants';

export const toggleIsAudienceModified = createAction(
    TOGGLE_IS_AUDIENCE_MODIFIED,
    isModified => {
        return {
            payload: { isModified }
        };
    }
);
