import { VerifyUser } from '../components/pages';
import featuresManager from '@featuresManager';

export default [
    {
        name: 'verifyUser',
        url: '/verify?{email}&{token}',
        views: {
            '@': {
                component: VerifyUser
            }
        },
        onEnter: () => {
            featuresManager.setFeatureState('verifyUser');
        }
    }
];
