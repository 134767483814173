import { createReducer } from '@reduxjs/toolkit';
import { UPDATE_TOTAL, RESET_BUDGET } from '../constants';

const initialState = 0;

const updateTotal = (state, action) => {
    const {
        distribution: { total }
    } = action.payload;

    return typeof total === 'number' ? total : state;
};

const resetBudget = () => {
    return initialState;
};

export const total = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_TOTAL, updateTotal)
        .addCase(RESET_BUDGET, resetBudget)
        .addDefaultCase((state, _) => state);
});
