import React from 'react';

export default function (Component, duration) {
    return class extends React.Component {
        constructor (props) {
            super(props);

            this.state = { show: false };
        }

        componentDidMount () {
            const timeout = setTimeout(() => {
                this.setState({ show: true });
                clearTimeout(timeout);
            }, duration);
        }

        render () {
            const css = {
                show: {
                    opacity: 1,
                    transition: `opacity ${duration}ms ease-in-out`
                },
                hide: {
                    opacity: 0,
                    transition: `opacity ${duration}ms ease-in-out`
                }
            };

            const styles = this.state.show ? css.show : css.hide;

            return (
                <Component fadeIn={styles} {...this.props} />
            );
        }
    }
}
