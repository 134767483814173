import { combineReducers } from 'redux';

import { files } from './files';
import { destination } from './urls';
import { adMessenger } from './urls';
import { facebook } from './urls';
import { instagram } from './urls';
import { skipUpload } from './skipUpload';
import { isValid } from './isValid';
import { disclaimer } from './disclaimer';

export const creative = combineReducers({
    files,
    destination,
    adMessenger,
    facebook,
    instagram,
    skipUpload,
    isValid,
    disclaimer
});
