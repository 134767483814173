import {
    VALIDATION_ERROR_PAY_STRIPE_CC_CARD_INVALID,
    VALIDATION_ERROR_PAY_STRIPE_CC_CARD_DECLINED,
    VALIDATION_ERROR_PAY_STRIPE_DATE_INCOMPLETE,
    VALIDATION_ERROR_PAY_STRIPE_CVC_INCOMPLETE
} from '@config';

export const BOOK_ERRORS_MAP = {
    incomplete_number: VALIDATION_ERROR_PAY_STRIPE_CC_CARD_INVALID,
    PaymentRequired: VALIDATION_ERROR_PAY_STRIPE_CC_CARD_DECLINED,
    cardExpiry: VALIDATION_ERROR_PAY_STRIPE_DATE_INCOMPLETE,
    cardCvc: VALIDATION_ERROR_PAY_STRIPE_CVC_INCOMPLETE
};
