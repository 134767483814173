import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { cancelable } from 'cancelable-promise';
import { cloneDeep } from 'lodash';

import { Layout, Text, Card, Content, Spinner } from 'maslow';
import featuresManager from '@featuresManager';

import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { updateTvDetails } from '@state/mediaPlan/actions/tvDetails';
import { setHasTvDetails } from '@state/mediaPlan/actions/hasTvDetails';
import { setIsMediaPlanVisited } from '@state/ui/actions';

import { LINEAR_TV } from '@config';
import { PLAN_STATUSES } from '@config/config.planStatus';
import { bookingPayloadContainer } from '@components/hoc/withBookingPayload';
import requestHandlers from '@requestHandlers';
import { strf, round, sortArrayOfObjectsByProp, pipe } from '@lib';
import { buildHHCountPayload } from '@services';
import { budgetConfigSelector } from '@selectors';
import { getFormattedCpm } from '@services/budget';

import DayPartDistribution from '../DayPartDistribution';
import WeekDayDistribution from '../WeekDayDistribution';
import NetworkDistribution from '../NetworkDistribution';

import styles from './tvDetails.scss';

const STATUSES = {
    success: 'Created',
    error: 'Error',
    spin: 'Generating'
};

const TvDetails = props => {
    const [role] = featuresManager.getCustomFilterState('role');
    const dispatch = useDispatch();
    const excludedNetworks = useSelector(
        state => state.networks.excludedNetworks
    );
    const tvDetails = useSelector(state => state.mediaPlan.tvDetails);
    const campaignRouterId = useSelector(
        state => state.mediaPlan.campaignRouterId
    );
    const region = useSelector(state => state.plan.attributes.region);
    const locations = useSelector(state => state.location.selectedLocations);
    const planStatus = useSelector(state => state.mediaPlan.planStatus);
    const isPlanOpened = useSelector(state => state.ui.isPlanOpened);
    const { subCampaigns } = useSelector(budgetConfigSelector);

    const [engageDataReceived, setEngageDataReceived] = useState(false);
    const [status, setStatus] = useState('spin');

    const tvSpend = subCampaigns[LINEAR_TV]
        ? subCampaigns[LINEAR_TV].budget
        : 0;

    const isTvBudgetGreaterThanZero = tvSpend > 0;

    let primaryTargetDemo = {};
    let engage_estimate_id = undefined;

    const { startDate, endDate, hiatusWeeks, daypartExclusions } = props;

    const buildNetworksExclusionsPayload = excludedNetworksArg => {
        return excludedNetworksArg.map(network => network.A4mediaId);
    };

    const excludedNetworksList =
        buildNetworksExclusionsPayload(excludedNetworks);

    const getSubCampaigns = (subCampaigns, primaryTargetObject) => {
        const newSubCampaigns = cloneDeep(subCampaigns);
        if (primaryTargetObject) {
            Object.keys(newSubCampaigns).forEach(item => {
                newSubCampaigns[item].primary_target_demo_id =
                    primaryTargetObject.primaryDemoNumber;
                newSubCampaigns[item].primary_target_demo_cpm =
                    primaryTargetObject.cpm;

                if (newSubCampaigns[item].name === LINEAR_TV) {
                    newSubCampaigns[item].primary_cpm_net =
                        primaryTargetObject.cpm;
                }
            });
        }

        if (excludedNetworksList) {
            Object.keys(newSubCampaigns).forEach(item => {
                newSubCampaigns[item].network_exclusions = excludedNetworksList;
            });
        }

        return newSubCampaigns;
    };

    const getPrimaryTargetDemo = (callback, cancel) => {
        const audiencePayload = buildHHCountPayload(
            props.audiences,
            props.combinations,
            locations
        );

        requestHandlers.audiences
            .primaryTargetDemo(audiencePayload)
            .then(primaryTargetDemoResponse => {
                dispatch(
                    updateTvDetails({
                        name: 'primaryTarget',
                        value: primaryTargetDemoResponse.data.response
                    })
                );

                primaryTargetDemo = {
                    ...primaryTargetDemo,
                    ...primaryTargetDemoResponse.data.response
                };

                callback();
            })
            .catch(cancel);
    };

    const getEstimate = (callback, cancel) => {
        const builtSubCampaigns = getSubCampaigns(
            subCampaigns,
            primaryTargetDemo
        );

        if (isTvBudgetGreaterThanZero) {
            const subCampaignLinearTv = builtSubCampaigns[LINEAR_TV];

            requestHandlers.plans
                .estimate({
                    start_date: startDate,
                    end_date: endDate,
                    dollar_budget: subCampaignLinearTv.budget,
                    primary_target_demo_id:
                        subCampaignLinearTv.primary_target_demo_id,
                    primary_target_demo_cpm:
                        subCampaignLinearTv.primary_target_demo_cpm,
                    spot_length: 30,
                    hiatus_periods: hiatusWeeks,
                    day_parts: daypartExclusions,
                    network_exclusions: excludedNetworksList
                })
                .then(estimateResponse => {
                    engage_estimate_id = estimateResponse.data.id;

                    dispatch(
                        updateTvDetails({
                            name: 'distribution',
                            value: estimateResponse.data.calculated_distribution
                        })
                    );

                    dispatch(
                        updateTvDetails({
                            name: 'tvImpressions',
                            value: estimateResponse.data.impressions_goal
                        })
                    );

                    dispatch(setHasTvDetails(true));
                })
                .catch(cancel)
                .finally(callback);
        } else {
            dispatch(
                updateTvDetails({
                    name: 'distribution',
                    value: {}
                })
            );

            dispatch(
                updateTvDetails({
                    name: 'tvImpressions',
                    value: 0
                })
            );

            callback();
        }
    };

    const getAuthUser = (callback, cancel) => {
        requestHandlers.auth
            .user()
            .then(response => {
                callback(response.data);
            })
            .catch(cancel);
    };

    const getClientUser = (userData, callback, cancel) => {
        requestHandlers.auth
            .clientUser(userData.email)
            .then(response => {
                callback(userData, response.data);
            })
            .catch(cancel);
    };

    const savePlan = (userData, clientUserId, callback, cancel) => {
        const builtSubCampaigns = getSubCampaigns(
            subCampaigns,
            primaryTargetDemo
        );

        const filteredKey = [LINEAR_TV];
        const filteredSubCampaigns = filteredKey.reduce(
            (obj, key) => ({ ...obj, [key]: builtSubCampaigns[key] }),
            {}
        );

        if (planStatus === PLAN_STATUSES.UNSAVED) {
            dispatch(setPlanStatus(PLAN_STATUSES.AUTO_SAVE));
        }

        requestHandlers.plans
            .saveToCr({
                clientUserId,
                hiatusWeeks,
                daypartExclusions,
                startDate,
                endDate,
                engage_estimate_id,
                user: userData,
                subCampaigns: filteredSubCampaigns
            })
            .then(response => {
                dispatch(setCampaignRouterId(response.data.campaign.id));
                callback();
            })
            .catch(cancel);
    };

    useEffect(() => {
        const pipeFns = isTvBudgetGreaterThanZero
            ? [
                  getPrimaryTargetDemo,
                  getEstimate,
                  getAuthUser,
                  getClientUser,
                  savePlan
              ]
            : [getPrimaryTargetDemo, getEstimate, getAuthUser, getClientUser];

        let pipeFnsPromise;

        const hasSubCampaigns = Object.values(subCampaigns).filter(sub => {
            return sub.budget > 0;
        }).length;

        if (!campaignRouterId && !isPlanOpened && hasSubCampaigns) {
            pipeFnsPromise = cancelable(pipe(pipeFns));
            pipeFnsPromise
                .then(res => {
                    setEngageDataReceived(true);
                    setStatus('success');
                    dispatch(setIsMediaPlanVisited(true));
                })
                .catch(console.error);
            dispatch(setHasTvDetails(false));
        } else {
            setEngageDataReceived(true);
        }
        return () => {
            if (pipeFnsPromise) pipeFnsPromise.cancel();
        };
    }, []);

    const statusText = status ? STATUSES[status] : '';
    const tvSpendFormatted = strf(round(tvSpend)).commas().prepend('$').value();
    const primaryTarget = tvDetails.primaryTarget;
    const tvCpm = tvSpend === 0 || !primaryTarget ? 0 : primaryTarget.cpm;
    const tvCpmFormatted = getFormattedCpm(tvCpm);
    const primaryDemoName =
        tvSpend === 0 || !primaryTarget ? '-' : primaryTarget.primaryDemoName;

    const tvImpressionsValue =
        tvSpend === 0 || !engageDataReceived
            ? '-'
            : tvDetails.tvImpressions || 0;

    const tvImpressionsFormatted = strf(tvImpressionsValue).commas().value();

    const distributionHasProps = prop =>
        tvDetails.hasOwnProperty('distribution') &&
        tvDetails.distribution.hasOwnProperty(prop);

    const dayParts = distributionHasProps('Dayparts')
        ? sortArrayOfObjectsByProp(tvDetails.distribution.Dayparts, 'BeginHour')
        : [];

    const weekParts = distributionHasProps('Weekparts')
        ? tvDetails.distribution.Weekparts
        : [];

    const networks = distributionHasProps('Networks')
        ? sortArrayOfObjectsByProp(
              tvDetails.distribution.Networks,
              'DeliveryEstimate',
              true
          )
        : [];

    return (
        isTvBudgetGreaterThanZero && (
            <Layout flexDirection="column" className={styles.wrapper}>
                <Layout flexDirection="row" hAlign="center">
                    <Text type="h3" className={styles.title}>
                        TV Details
                    </Text>
                </Layout>
                <Layout flexDirection="column" className={styles.tvBox}>
                    <Card borderColor="vividNavy" borderWidth="3px">
                        <Content fillParent>
                            <Layout flexDirection="row" hAlign="space-around">
                                <Layout flexDirection="column" hAlign="center">
                                    <Text type="h4">{tvSpendFormatted}</Text>
                                    <Text type="h5" color="vividNavy">
                                        BUDGET
                                    </Text>
                                </Layout>
                                <Layout flexDirection="column" hAlign="center">
                                    <Text type="h4">
                                        {tvImpressionsFormatted}
                                    </Text>
                                    <Text type="h5" color="vividNavy">
                                        IMPRESSIONS
                                    </Text>
                                </Layout>
                                <Layout flexDirection="column" hAlign="center">
                                    <Text type="h4">{tvCpmFormatted}</Text>
                                    <Text type="h5" color="vividNavy">
                                        CPM
                                    </Text>
                                </Layout>
                                <Layout flexDirection="column" hAlign="center">
                                    <Text type="h4">{primaryDemoName}</Text>
                                    <Text type="h5" color="vividNavy">
                                        PRIMARY DEMO
                                    </Text>
                                </Layout>
                            </Layout>
                        </Content>
                    </Card>
                </Layout>

                {status && !engageDataReceived && (
                    <Layout flexDirection="column" hAlign="center">
                        <Spinner status={status} />
                        <Text type="caption"> {statusText} </Text>
                    </Layout>
                )}

                {engageDataReceived && (
                    <React.Fragment>
                        <DayPartDistribution
                            tvImpressions={tvImpressionsValue}
                            dayParts={dayParts}
                        />
                        <WeekDayDistribution
                            tvImpressions={tvImpressionsValue}
                            weekParts={weekParts}
                        />
                        <NetworkDistribution
                            tvImpressions={tvImpressionsValue}
                            networks={networks}
                        />
                    </React.Fragment>
                )}
            </Layout>
        )
    );
};

TvDetails.propTypes = {
    audiences: PropTypes.array,
    locations: PropTypes.array,
    primaryTarget: PropTypes.object,
    daypartExclusions: PropTypes.array.isRequired,
    hiatusWeeks: PropTypes.array.isRequired,
    combinations: PropTypes.object
};

export default bookingPayloadContainer(TvDetails);
