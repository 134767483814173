import request from '../request';

export default function(audiences, token) {
    const req = {
        method: 'POST',
        url: 'audiences/audience/',
        data: audiences
    };

    if (token) req.cancelToken = token;

    return request(req);
}
