import React, { useState } from 'react';

import { Card, Text, Layout } from 'maslow';
import { strf, addDecimals } from '@lib';
import { getSubCampaignNameFromContext } from '@services';
import { VCR, CTR } from '@config';

import styles from './styles.scss';
import { barColors } from '@config/barColors';
import eyePng from '@public/img/eye.png';
import statsSvg from '@public/img/stats.svg';
import calendarSvg from '@public/img/calendar.svg';

const MAX_COUNT = 5;

const CreativePerformance = ({ data, config }) => {
    const { vcr } = config;
    const total = data.reduce((sum, creative) => {
        return sum + creative.perCreativePerformanceData.length;
    }, 0);

    const [viewAll, setViewAll] = useState(total <= MAX_COUNT);
    const amount = viewAll ? total : MAX_COUNT;
    let count = 0;

    const displayAllCreatives = () => setViewAll(!viewAll);

    const buildChannelRows = () => {
        return data.map((datum, i) => {
            if (count < amount) {
                const { channel, perCreativePerformanceData } = datum;
                const channelName = getSubCampaignNameFromContext(channel);
                const bubbleStyle = {
                    backgroundColor: barColors[channelName],
                    borderColor: barColors[channelName]
                };

                const channelMetric = vcr.find(
                    channel => channel === channelName
                )
                    ? VCR
                    : CTR;

                return (
                    <div key={channel}>
                        <div className={styles.channelRow}>
                            <div
                                style={bubbleStyle}
                                className={styles.bubble}></div>
                            <div
                                className={
                                    channelMetric === VCR
                                        ? styles.spanColumn
                                        : ''
                                }>
                                <Text
                                    type="body"
                                    className={styles.channelTitle}>
                                    {channelName}
                                </Text>
                            </div>
                            <div className={styles.tableCell}>
                                <img className={styles.eyeImg} src={eyePng} />
                                <Text color="gray">Imps.</Text>
                            </div>
                            {channelMetric === CTR ? (
                                <>
                                    <div className={styles.tableCell}>
                                        <img
                                            src={statsSvg}
                                            className={styles.statsImg}
                                        />
                                        <Text color="gray">Clicks</Text>
                                    </div>
                                    <div className={styles.tableCell}>
                                        <img
                                            src={statsSvg}
                                            className={styles.statsImg}
                                        />
                                        <Text color="gray">CTR</Text>
                                    </div>
                                </>
                            ) : (
                                <>
                                    <div className={styles.tableCell}>
                                        <img
                                            src={statsSvg}
                                            className={styles.statsImg}
                                        />
                                        <Text color="gray">VCR</Text>
                                    </div>
                                </>
                            )}
                        </div>
                        {buildTableRows(
                            perCreativePerformanceData,
                            channelMetric
                        )}
                    </div>
                );
            }
        });
    };

    const buildTableRows = (creativeData, channelMetric) => {
        return creativeData.map((datum, i) => {
            if (count < amount) {
                const {
                    img,
                    fileName,
                    impressions,
                    clicks,
                    videoCompletions,
                    videoStarts
                } = datum;

                const metrics =
                    channelMetric === CTR
                        ? addDecimals((clicks / impressions) * 100)
                        : addDecimals((videoCompletions / videoStarts) * 100);
                count++;

                return (
                    <div className={styles.tableRow} key={'creative.' + i}>
                        <div>
                            {/* <img className={styles.creativeImg} src={img} /> */}
                        </div>
                        <div
                            className={`${styles.tableCell} ${
                                channelMetric === VCR ? styles.spanColumn : ''
                            }`}>
                            <Text>{fileName}</Text>
                        </div>
                        <div className={styles.valueTableCell}>
                            <Text>{strf(impressions).commas().value()}</Text>
                        </div>
                        {channelMetric === CTR ? (
                            <>
                                <div className={styles.valueTableCell}>
                                    <Text>{strf(clicks).commas().value()}</Text>
                                </div>
                                <div className={styles.tableCell}>
                                    <Text>
                                        {strf(metrics).append('%').value()}
                                    </Text>
                                </div>
                            </>
                        ) : (
                            <>
                                <div className={styles.tableCell}>
                                    <Text>
                                        {strf(metrics).append('%').value()}
                                    </Text>
                                </div>
                            </>
                        )}
                    </div>
                );
            }
        });
    };

    const buildViewAll = () => {
        return !viewAll ? (
            <div className={styles.viewAll}>
                <Text
                    type="input"
                    color="vividNavy"
                    onClick={displayAllCreatives}>
                    + VIEW ALL CREATIVES
                </Text>
            </div>
        ) : null;
    };

    const viewAllRow = buildViewAll();

    const channelRows = buildChannelRows();

    return (
        <Layout flexDirection="column" className={styles.creativePerformance}>
            <div className={styles.creativeTitle}>
                <Text type="h5">Creative Performance</Text>
            </div>
            {channelRows}
            {viewAllRow}
        </Layout>
    );
};

export default CreativePerformance;
