import { isObject } from '@lib';

export const sendAppInsightEvent = () => {
    if (!window.hasOwnProperty('appInsights')) return;

    return (event = '', eventData = {}) => {
        if (typeof event !== 'string' || !isObject(eventData))
            window.appInsights.trackEvent(event, eventData);
    };
};
