import { createReducer } from '@reduxjs/toolkit';
import { SET_ORDER } from '../constants';

const initialState = '';

const setOrder = (state, action) => {
    const { orderBy } = action.payload;
    return typeof orderBy === 'string' ? orderBy : state;
};

export const orderBy = createReducer(initialState, reducer => {
    reducer.addCase(SET_ORDER, setOrder).addDefaultCase((state, _) => state);
});
