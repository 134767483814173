import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { cancelable } from 'cancelable-promise';

import requestHandlers from '@requestHandlers';
import { Layout, Text } from 'maslow';
import styles from './styles.scss';
import { loginService } from '@services';
import { updateAccount, updateAgency } from '@state/user/actions/';

export default function (version, feature) {
    const VerifyUser = props => {
        const dispatch = useDispatch();
        const ls = loginService(feature);
        const { email, token } = props.transition.router.stateService.params;
        const decodedEmail = decodeURIComponent(email);

        const updateAccountAndAgency = data => {
            const agency = { name: data.email };
            dispatch(updateAccount(ls.processAccountData(data)));
            dispatch(updateAgency(ls.processAgencyData(agency)));
        };

        useEffect(() => {
            const verifyUser = cancelable(
                requestHandlers.auth.verify({ email: decodedEmail, token })
            );
            verifyUser
                .then(verifyResponse => {
                    const userData = ls.setAndDecodeJwt(verifyResponse.data);
                    updateAccountAndAgency(userData);
                    props.transition.router.stateService.go('app.main.plans');
                })
                .catch(() => {
                    props.transition.router.stateService.go(
                        'verificationError'
                    );
                });

            return () => verifyUser.cancel();
        }, []);

        return (
            <Layout
                flexDirection="column"
                vAlign="center"
                hAlign="center"
                className={styles.textContainer}>
                <Text type="h5">Verifying</Text>
            </Layout>
        );
    };

    return VerifyUser;
}
