import { createReducer } from '@reduxjs/toolkit';
import {
    SET_HOUSEHOLD_COUNT,
    SET_LOCATION_COUNT,
    SET_TOTAL_HOUSEHOLD_COUNT,
    FETCHING_HOUSEHOLD_COUNT,
    FETCHING_TOTAL_HOUSEHOLD_COUNT,
    ABORT_FETCHING_HOUSEHOLD_COUNT,
    FETCHING_LOCATION_COUNT,
    ABORT_FETCHING_LOCATION_COUNT
} from '../constants';

const initialState = {
    locationIsFetching: false,
    isFetching: false,
    isFetchingTotal: false,
    total: 0,
    count: 0,
    locationCount: 0
};

const fetchingHouseholdCount = (state, action) => {
    const payload = {
        isFetching: true,
        isFetchingTotal: false
    };
    return { ...state, ...payload };
};

const fetchingLocationCount = (state, action) => {
    const payload = {
        locationIsFetching: true
    };
    return { ...state, ...payload };
};

const fetchingTotalHouseholdCount = (state, action) => {
    const payload = {
        isFetching: false,
        isFetchingTotal: true
    };
    return { ...state, ...payload };
};

const setHouseholdCount = (state, action) => {
    const { count } = action.payload;
    const payload = {
        count,
        isFetching: false,
        isFetchingTotal: false
    };

    return { ...state, ...payload };
};

const setLocationCount = (state, action) => {
    const { count } = action.payload;
    const payload = {
        locationCount: count,
        locationIsFetching: false
    };
    return { ...state, ...payload };
};

const setTotalHouseholdCount = (state, action) => {
    const { total } = action.payload;
    const payload = {
        total,
        isFetching: false
    };
    return { ...state, ...payload };
};

const abortFetchingHouseholdCount = (state, action) => {
    const payload = {
        isFetching: false,
        isFetchingTotal: false
    };

    return { ...state, ...payload };
};

const abortFetchingLocationCount = (state, action) => {
    const payload = {
        locationIsFetching: false
    };

    return { ...state, ...payload };
};

export const households = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_HOUSEHOLD_COUNT, setHouseholdCount)
        .addCase(SET_LOCATION_COUNT, setLocationCount)
        .addCase(SET_TOTAL_HOUSEHOLD_COUNT, setTotalHouseholdCount)
        .addCase(FETCHING_HOUSEHOLD_COUNT, fetchingHouseholdCount)
        .addCase(FETCHING_TOTAL_HOUSEHOLD_COUNT, fetchingTotalHouseholdCount)
        .addCase(FETCHING_LOCATION_COUNT, fetchingLocationCount)
        .addCase(ABORT_FETCHING_HOUSEHOLD_COUNT, abortFetchingHouseholdCount)
        .addCase(ABORT_FETCHING_LOCATION_COUNT, abortFetchingLocationCount)
        .addDefaultCase((state, _) => state);
});
