import React, { useRef, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { WarningPopup } from '@components/common';
import { Layout } from 'maslow';
import { Header } from './Header';
import { SortPlanCards } from './SortPlanCards';
import { Search } from './Search';
import Plan from './Plan';
import { idBuilder } from '@services';
import requestHandlers, { fetcher } from '@requestHandlers';
import {
    userSelector,
    hasPermissionSelector,
    accountSelector
} from '@selectors';
import styles from './styles.scss';
import { updateAccount } from '@state/user/actions/';
import { updateLoadingScreen, resetLoadingScreen } from '@state/ui/actions';
import {
    MODIFIED_DATE,
    MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN,
    ASCENDING_ORDER,
    DESCENDING_ORDER
} from '@config';
import { POPUP_SETTINGS } from '@config/config.plans';
import {
    PLANS_REMOVE_PLAN,
    PLANS_CANCEL_REMOVE,
    PLANS_RETURN_TO_PLAN_LIST,
    PLANS_INFINITE_SCROLL_BUTTON
} from '@config/idConstants';
import useInView from 'react-cool-inview';
import { useSWRInfinite } from 'swr';

export default function (version, feature) {
    const Plans = ({ customFiltersStates, transition }) => {
        const isShowCampaignPage = useSelector(
            hasPermissionSelector(MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN)
        );
        const { account } = useSelector(userSelector);
        const showRankerButton = useSelector(
            hasPermissionSelector('mediaplan.useranker')
        );
        const { email } = useSelector(accountSelector);
        const dispatch = useDispatch();

        const [orderBy, setOrderBy] = useState(() => MODIFIED_DATE);
        const [descending, setDescending] = useState(() => DESCENDING_ORDER);
        const [isSearching, setIsSearching] = useState(() => false);
        const [isSorting, setIsSorting] = useState(() => false);
        const [searchValue, setSearchValue] = useState('');
        const [popupStep, setPopupStep] = useState(() => POPUP_SETTINGS.HIDDEN);
        const idForPopupRef = useRef(0);

        const getKey = (pageIndex, previousPageData) => {
            if (previousPageData && !previousPageData.length) return null;
            return isSearching
                ? `/api/plans/search/${email}?search=${encodeURIComponent(
                      searchValue
                  )}&page=${pageIndex}&orderBy=${orderBy}&sort=${descending}`
                : `/api/plans/${email}?page=${pageIndex}&orderBy=${orderBy}&sort=${descending}`;
        };

        const {
            data,
            error,
            size: page,
            setSize: setPage,
            mutate
        } = useSWRInfinite(getKey, fetcher, {
            refreshInterval: 10000,
            dedupingInterval: 2000
        });

        let plans = data ? [].concat(...data) : [];
        let isLoadingInitialData = !data && !error;
        let isEmpty = data?.[0]?.length === 0;

        const { ref, observe, unobserve } = useInView({
            threshold: 0,
            delay: 100,
            rootMargin: '0px',
            onEnter: () => {
                // if its sorting, loading or an error occured trigger fetch for next page
                !isSorting && !isLoadingInitialData && !error
                    ? setPage(page + 1)
                    : null;
            }
        });

        useEffect(() => {
            dispatch(updateAccount({ ...account, ...customFiltersStates }));
            return () => {
                unobserve();
                dispatch(resetLoadingScreen());
            };
        }, []);

        useEffect(() => {
            dispatch(
                updateLoadingScreen({
                    showLoadingScreen: true,
                    loadingText: isSorting
                        ? 'Sorting your plan list'
                        : 'Retrieving your plan list'
                })
            );
            if (!isLoadingInitialData) dispatch(resetLoadingScreen());
        }, [isLoadingInitialData]);

        useEffect(() => {
            if (isSearching || isEmpty) unobserve();
            else {
                observe();
            }
        }, [isSearching]);

        const removePlan = id => {
            if (!id) return;
            requestHandlers.plans
                .removeMediaPlan(id)
                .then(() => setPopupStep(POPUP_SETTINGS.CONFIRMATION))
                .catch(console.error);
        };

        const hidePopup = () => {
            setPopupStep(POPUP_SETTINGS.HIDDEN);
        };

        const refreshPlans = () => {
            // SWR library requires two calls to mutate in order for UI to re-render.
            mutate();
            mutate();
            setPopupStep(POPUP_SETTINGS.HIDDEN);
        };

        const showPopup = id => {
            idForPopupRef.current = id;
            setPopupStep(POPUP_SETTINGS.QUERY);
        };

        const toggleDescending = () => {
            descending === DESCENDING_ORDER
                ? setDescending(ASCENDING_ORDER)
                : setDescending(DESCENDING_ORDER);
        };

        const popupActions = {
            query: {
                text: 'Remove your drafted plan?',
                showPopup: true,
                showSuccessIcon: false,
                button1: {
                    text: 'Remove',
                    theme: 'primary',
                    id: PLANS_REMOVE_PLAN, //change
                    onClick: () => removePlan(idForPopupRef.current),
                    animate: true
                },
                button2: {
                    text: 'Keep Draft',
                    theme: 'outline',
                    id: PLANS_CANCEL_REMOVE, // change
                    onClick: () => hidePopup()
                }
            },
            confirmation: {
                text: 'Plan removed!',
                showPopup: true,
                showSuccessIcon: true,
                button1: {
                    text: 'Return to plan list',
                    theme: 'primary',
                    id: PLANS_RETURN_TO_PLAN_LIST, //change
                    onClick: () => refreshPlans()
                }
            },
            hidden: {
                text: '',
                showPopup: false
            }
        };

        const buildPlanPages = array => {
            if (!array.length || !array) return null;
            return array.map((plan, index) => {
                return (
                    <Plan
                        key={plan.id}
                        plan={plan}
                        transition={transition}
                        showPopup={showPopup}
                        cardIndex={index + 1}
                    />
                );
            });
        };

        return (
            <div className={styles.parentWrapper}>
                <React.Fragment>
                    <Header
                        showRankerButton={showRankerButton}
                        isShowCampaignPage={isShowCampaignPage}
                        transition={transition}
                        account={account}
                        dispatch={dispatch}
                    />
                    <Layout
                        flexDirection="row"
                        className={styles.headerContainer}
                        vAlign="center">
                        <Search
                            setIsSearching={setIsSearching}
                            setSearchValue={setSearchValue}
                        />
                        <SortPlanCards
                            descending={descending}
                            toggleDescending={toggleDescending}
                            orderBy={orderBy}
                            setOrderBy={setOrderBy}
                            isSorting={isSorting}
                            setIsSorting={setIsSorting}
                        />
                    </Layout>

                    <Layout flexDirection="row" className={styles.cardsWrapper}>
                        {buildPlanPages(plans)}
                    </Layout>
                    <div
                        id={idBuilder(PLANS_INFINITE_SCROLL_BUTTON)}
                        className={styles.infinteScrollerBtn}
                        ref={ref}></div>
                </React.Fragment>
                <WarningPopup settings={popupActions[popupStep]}></WarningPopup>
            </div>
        );
    };

    return Plans;
}
