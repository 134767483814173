export const determineRightSidebarHeight = (config, tHeight, cHeight) => {
    const titleHeight = tHeight || 0;
    const categoryHeight = cHeight || 0;
    const amountOfTitles = config.length;
    let amountOfCategories = 0;
    let height = 0;

    config.forEach(section => {
        amountOfCategories += section.options.length;
    });

    height += amountOfTitles * titleHeight;
    height += amountOfCategories * categoryHeight;

    return height;
};
