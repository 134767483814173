import React from 'react';
import { Page } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';

const VerifyUser = props => {
    const _props = { props };
    const { components } = getFeatures('verifyUser', _props);

    return (
        <Page center backgroundColor="quartz">
            {components}
        </Page>
    );
};

export default withFeatures(VerifyUser);
