import React from 'react';

import { Layout, Text } from 'maslow';
import { datesHandler } from '@lib';

import style from './styles.scss';

import { GLOBAL_TERMS, PRIVACY_POLICY } from '@config/idConstants';
import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';

export const TermsAndConditions = props => {
    const currentDate = datesHandler.newDate();
    const currentYear = datesHandler.getYear(currentDate);

    return (
        <React.Fragment>
            <Layout
                flexDirection="column"
                className={style.emptyWrapper}></Layout>

            <Layout flexDirection="column" className={style.bottomSection}>
                <Layout flexDirection="row" hAlign="center">
                    <a
                        id={GLOBAL_TERMS}
                        target="_blank"
                        href={termsAndPolicyConfig.termsOfUse}
                        className={style.termsWrapper}>
                        <Text
                            type="input"
                            color="echoBlue"
                            className={style.textLink}>
                            Terms of Use
                        </Text>
                    </a>
                    <a
                        id={PRIVACY_POLICY}
                        target="_blank"
                        href={termsAndPolicyConfig.privacyPolicy}
                        className={style.policyWrapper}>
                        <Text
                            type="input"
                            color="echoBlue"
                            className={style.textLink}>
                            Privacy Policy
                        </Text>
                    </a>
                </Layout>
                <Layout flexDirection="column" hAlign="center">
                    <Text type="input" color="echoBlue">
                        {`© 2019 - ${currentYear} a4 Media.`}
                    </Text>
                    <Text type="input" color="echoBlue">
                        All rights reserved.
                    </Text>
                </Layout>
            </Layout>
        </React.Fragment>
    );
};
