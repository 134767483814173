export const SET_START_DATE = 'SET_START_DATE';
export const SET_END_DATE = 'SET_END_DATE';
export const SET_DURATION = 'SET_DURATION'

export const ADD_HIATUS_WEEK = 'ADD_HIATUS_WEEK';
export const REMOVE_HIATUS_WEEK = 'REMOVE_HIATUS_WEEK';
export const REMOVE_ALL_HIATUS_WEEKS = 'REMOVE_ALL_HIATUS_WEEKS';
export const SHIFT_HIATUS_WEEKS = 'SHIFT_HIATUS_WEEKS';

export const ADD_EXCLUDED_DAYPART = 'ADD_EXCLUDED_DAYPART';
export const REMOVE_EXCLUDED_DAYPART = 'REMOVE_EXCLUDED_DAYPART';
export const REMOVE_ALL_EXCLUDED_DAYPARTS = 'REMOVE_ALL_EXCLUDED_DAYPARTS';

export const SET_IS_BROADCAST = 'SET_IS_BROADCAST';
