import React, { useState, useRef } from 'react';
import PropTypes from 'prop-types';

import { Layout, Icon, Text, Card } from 'maslow';
import dropDownIcon from '@public/img/ico-dropdown.svg';
import sortIcon from '@public/img/ico-sort.svg';

import style from '../styles.scss';

import {
    PLANS_SORT,
    PLANS_SORT_ASCENDING_ORDER,
    PLANS_SORT_DESCENDING_ORDER,
    PLANS_SORT_CRITERION
} from '@config/idConstants';
import { DESCENDING_ORDER } from '@config';
import { idBuilder } from '@services';
import { plansConfig } from '@config/config.plans';
import { useClickOutside } from '@hooks/';

export const SortPlanCards = ({
    orderBy,
    setOrderBy,
    descending,
    toggleDescending,
    isSorting,
    setIsSorting
}) => {
    const { sortCriteria } = plansConfig;
    const [showSortCriteria, setShowSortCriteria] = useState(false);
    const sortRef = useRef(null);

    useClickOutside(sortRef, () => setShowSortCriteria(false));

    const toggleSortCriteria = () => {
        setShowSortCriteria(!showSortCriteria);
    };

    const toggleIsSortingFlag = () => {
        setIsSorting(!isSorting);
        setTimeout(() => {
            setIsSorting(false);
        }, 1000);
    };

    const toggleSortOrder = () => {
        setShowSortCriteria(false);
        toggleIsSortingFlag();
        toggleDescending();
    };

    const setSortCriterion = option => {
        toggleSortCriteria();
        toggleIsSortingFlag();
        if (orderBy === option) return;
        setOrderBy(option);
    };

    const buildSortCriteria = () => {
        return Object.keys(sortCriteria).map((option, i) => {
            return (
                <div
                    key={i}
                    className={style.sortCriterionWrapper}
                    onClick={() => setSortCriterion(option)}
                    id={idBuilder(PLANS_SORT_CRITERION, sortCriteria[option])}>
                    <Text type="input">{sortCriteria[option]}</Text>
                </div>
            );
        });
    };

    const sortOrderIconStyle =
        descending === DESCENDING_ORDER ? '' : style.ascendingOrderIcon;
    const sortCriterionIconStyle = showSortCriteria
        ? style.hideCriteriaIcon
        : '';
    const idText =
        descending === DESCENDING_ORDER
            ? PLANS_SORT_DESCENDING_ORDER
            : PLANS_SORT_ASCENDING_ORDER;

    return (
        <div ref={sortRef}>
            <Layout flexDirection="row" className={style.sortFieldWrapper}>
                <Layout
                    flexDirection="row"
                    onClick={toggleSortCriteria}
                    id={PLANS_SORT}
                    flex
                    className={style.leftWrapper}
                    vAlign="center">
                    <Text type="input" color="slateGray">
                        Sort by
                    </Text>
                    <Text type="input" className={style.selectedCriterion}>
                        {sortCriteria[orderBy]}
                    </Text>
                    <Icon
                        src={dropDownIcon}
                        className={[
                            style.sortCriterionIcon,
                            sortCriterionIconStyle
                        ].join(' ')}
                    />
                    <div className={style.separator}></div>
                </Layout>

                <Layout
                    flexDirection="column"
                    onClick={toggleSortOrder}
                    className={style.sortOrderWrapper}
                    vAlign="center"
                    id={idText}>
                    <Icon
                        src={sortIcon}
                        size="2"
                        className={sortOrderIconStyle}
                    />
                </Layout>
            </Layout>

            {showSortCriteria && (
                <Card className={style.sortCriteriaWrapper}>
                    {buildSortCriteria()}
                </Card>
            )}
        </div>
    );
};

SortPlanCards.propTypes = {
    descending: PropTypes.string.isRequired,
    toggleDescending: PropTypes.func.isRequired,
    orderBy: PropTypes.string.isRequired,
    setOrderBy: PropTypes.func.isRequired,
    setIsSorting: PropTypes.func.isRequired,
    isSorting: PropTypes.bool.isRequired
};
