import React from 'react';
import { Layout } from 'maslow';
import styles from './fileExample.scss';

const FileExample = () => {
    return (
        <Layout flexDirection="row">
            <Layout flexDirection="column">
                <span className={styles.header}>Address</span>
                <span className={styles.row}>1 Court Sq. Fl 1</span>
            </Layout>
            <Layout flexDirection="column">
                <span className={styles.header}>City</span>
                <span className={styles.row}>New York</span>
            </Layout>
            <Layout flexDirection="column">
                <span className={styles.header}>State</span>
                <span className={styles.row}>NY</span>
            </Layout>
            <Layout flexDirection="column">
                <span className={styles.header}>Zip</span>
                <span className={styles.row}>11101</span>
            </Layout>
        </Layout>
    );
};

export default FileExample;
