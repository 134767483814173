import { combineReducers } from 'redux';

import { total } from './total';
import { subCampaigns } from './subCampaigns';
import { channels } from './channels';
import { isAutoDistributed } from './isAutoDistributed';
import { distributionConfig } from './distributionConfig';
import { digitalCpmConfig } from './digitalCpmConfig';
import { impressionModifierConfig } from './impressionModifierConfig';

export const budget = combineReducers({
    total,
    subCampaigns,
    channels,
    isAutoDistributed,
    distributionConfig,
    digitalCpmConfig,
    impressionModifierConfig
});
