import { INTERNAL, NATIONAL } from '@config';

export default {
    name: 'Networks', // Component name
    description:
        'Media AKA Networks feature - name changed as per Product team',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan'],
    sections: ['networks'],
    permissions: [],
    customFilters: {
        region: [NATIONAL],
        type: [INTERNAL]
    },
    data: { exclusionsLimit: 5 },
    attributes: {},
    modifications: {}
};
