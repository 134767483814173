import { default as featureBase } from './feature.base';
import { default as constants } from './constants';
import { hasIntersection } from '@lib';

let permissions = [];

class FeaturesManager {
    constructor() {
        this.config = {};
        this.featureState = '';
        this.features = [];
        this.customFilters = {};
        this.customFiltersStates = {};
    }

    reset() {
        this.config = {};
        this.featureState = '';
        this.features = [];
        this.customFilters = {};
        this.customFiltersStates = {};

        permissions = [];
    }

    setPermissions(permissionItems) {
        if (!Array.isArray(permissionItems)) {
            return console.error(
                'Incorrect arg type for permissions. Must be an Array.'
            );
        }

        permissions = permissionItems;
    }

    getPermissions() {
        return permissions;
    }

    initialize(options) {
        if (!options.hasOwnProperty('features'))
            throw Error('No features were provided.');
        if (!Array.isArray(options.features))
            throw Error('Features must be of type Array.');
        if (!options.hasOwnProperty('config'))
            throw Error('No config options were provided.');
        if (!options.config.hasOwnProperty('status'))
            throw Error('No config status option was provided.');
        if (typeof options.config.status !== 'string')
            throw Error('Config status option must be of type String.');

        this.features = this._registerFeatures(options.features);
        this.config = Object.assign({}, this.config, options.config);
        this.customFilters = {
            ...this.customFilters,
            ...options.customFilters
        };
        this.customFiltersStates = {
            ...this.customFiltersStates,
            ...options.customFiltersStates
        };
    }

    setFeatureState(featureState) {
        if (!featureState) throw Error('no featureState provided.');
        if (typeof featureState !== 'string')
            throw Error('featureState arg must be of type String.');

        this.featureState = featureState;
        window.emitter.emit('featureState.change', this.featureState);
    }

    setCustomFilterState(customFilter, state) {
        this.customFiltersStates[customFilter] = state;

        window.emitter.emit('featureState.change', Date.now());
    }

    getCustomFilterState(customFilter) {
        return this.customFiltersStates[customFilter];
    }

    addCustomFilterState(customFilter, state) {
        if (this.customFiltersStates.hasOwnProperty(customFilter)) {
            this.customFiltersStates[customFilter].push(state);
        } else {
            console.warn(
                `custom filter state '${customFilter}' has not been set. we'll do this for you :)`
            );
            this.customFiltersStates[customFilter] = [];
            this.customFiltersStates[customFilter].push(state);
        }

        window.emitter.emit('featureState.change', Date.now());
    }

    removeCustomFilterState(customFilter, state) {
        if (this.customFiltersStates.hasOwnProperty(customFilter)) {
            this.customFiltersStates[customFilter] = this.customFiltersStates[
                customFilter
            ].filter(option => option !== state);
            window.emitter.emit('featureState.change', Date.now());
        } else {
            console.warn(
                `custom filter state '${customFilter}' has not been set.`
            );
        }
    }

    /**
     * filters down a chain of features and executes modifications
     *
     * @param {String} section
     * @returns {Array}
     */
    getFeatures(section) {
        if (!section) throw Error('no section provided.');
        if (typeof section !== 'string')
            throw Error('section arg should be of type String.');
        if (!Object.keys(this.config).length)
            throw Error('Features Manager not configured or initialized.');
        if (this.featureState === '') throw Error('Feature state not set.');
        if (!Object.keys(this.features).length)
            throw Error('There are no registered features.');

        // filter features by states
        return this._filterFeaturesByCustomFilters(
            this._filterFeaturesByPermissions(
                this._filterFeaturesByValue(
                    constants.STATES,
                    this.featureState,

                    // filter features by section
                    this._filterFeaturesByValue(constants.SECTIONS, section)
                )
            )
        );
    }

    getFeature(name, section, features) {
        const _features = features || this.getFeatures(section);

        return _features.filter(feature => {
            return feature.name === name;
        });
    }

    sortOrder(features) {
        const _features = features || this.features;
        const notUndefined = value => value !== undefined;

        return _features.sort((a, b) => {
            const orderA = notUndefined(a.order) ? a.order : 0;
            const orderB = notUndefined(b.order) ? b.order : 0;

            if (orderA > orderB) return 1;
            if (orderA < orderB) return -1;

            return 0;
        });
    }

    getFeatureComponentVersion(feature) {
        const status = this.config.status,
            version = feature.versions[status],
            name = feature.name;

        try {
            return require(`@components/features/${name}/${version}/component.content`).default(
                version,
                feature
            );
        } catch (err) {
            throw Error(
                `Error in component '${version}' of feature '${name}' for status '${status}' : ${err}`
            );
        }
    }

    filterFeaturesByLibrary(library, features) {
        if (!library || typeof library !== 'string')
            throw Error('library arg not provided or of incorrect type.');

        const _features = features || this.features;

        return _features.filter(feature => feature.library === library);
    }

    _registerFeatures(features) {
        return features.map(feature => {
            return Object.assign({}, featureBase, feature);
        });
    }

    _filterFeaturesByPermissions(features) {
        const _features = features || this.features;

        if (!permissions.length) return _features;

        return _features.filter(feature => {
            return feature.permissions.length
                ? hasIntersection(permissions, feature.permissions)
                : true;
        });
    }

    _filterFeaturesByValue(prop, match, features) {
        const _features = features || this.features;

        return _features.filter(feature => {
            return feature.hasOwnProperty(prop) && feature[prop].length
                ? feature[prop].includes(match)
                : false;
        });
    }

    _filterFeaturesByCustomFilters(features) {
        const _features = features || this.features;

        return _features.filter(feature => {
            const customFilters = Object.keys(feature.customFilters);
            const results = customFilters.length
                ? customFilters.map(customFilter =>
                      hasIntersection(
                          feature.customFilters[customFilter],
                          this.customFiltersStates[customFilter]
                      )
                  )
                : [];

            return results.length ? results.every(bool => bool) : true;
        });
    }

    _executeFeatureModifications(prop, features) {
        const _features = features || this.features;

        _features.forEach(feature => {
            if (feature.hasOwnProperty(constants.MODIFICATIONS)) {
                if (feature.modifications.hasOwnProperty(this.config[prop])) {
                    feature.modifications[this.config[prop]].call(feature);
                }
            }
        });

        return _features;
    }
}

export default new FeaturesManager();
