import { validateArg } from '@lib';

export const testZipString = (unTrimmedString, handleZips) => {
    validateArg(unTrimmedString, 'string', 1);
    validateArg(handleZips, 'boolean', 2);
    if (!handleZips) return false;
    const string = unTrimmedString.trimLeft(' ');
    const regex = RegExp(/[0-9]{5}/);
    return string.length >= 5 && regex.test(string.substring(0, 5));
};
