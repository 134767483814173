// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".exportIcon___1q0Dq {\n  margin-left: 16px; }\n\n.buttonWrapper___2VOnu {\n  width: 220px;\n  margin: 0 16px; }\n  .buttonWrapper___2VOnu button {\n    height: 32px; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/features/PdfExport/1.0.0/PdfExportPopup/styles.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAEA;EACI,iBCFyB,EAAA;;ADK7B;EACI,YAAY;EACZ,cCPyB,EAAA;EDK7B;IAKQ,YAAY,EAAA","file":"styles.scss","sourcesContent":["@import '~@scss/settings/baseline';\n\n.exportIcon {\n    margin-left: $baseline-x2;\n}\n\n.buttonWrapper {\n    width: 220px;\n    margin: 0 $baseline-x2;\n\n    button {\n        height: 32px;\n    }\n}\n","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"exportIcon": "exportIcon___1q0Dq",
	"buttonWrapper": "buttonWrapper___2VOnu"
};
module.exports = exports;
