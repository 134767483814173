import axios from 'axios';

const baseUrl = '/api/';
const requiredProps = ['method', 'url'];

export default function request(req) {
    const axiosInstance = axios.create();

    axiosInstance.defaults.timeout = 240000;

    requiredProps.forEach(prop => {
        if (!req.hasOwnProperty(prop))
            throw Error(
                `Request payload is missing required property: ${prop}`
            );
    });

    req.url = `${baseUrl}${req.url}`;

    return new Promise((resolve, reject) => {
        axiosInstance(req).then(resolve).catch(reject);
    });
}

export const fetcher = url => axios.get(url).then(res => res.data);
