import { COMMERCIAL, POLITICAL } from '@config';

export default {
    name: 'Location', // Component name
    description: 'Location page',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan', 'ranker'],
    sections: ['location'],
    permissions: [],
    data: {
        searchLabel: {
            [COMMERCIAL]: 'Search for County, City, State, ZIP, or DMA',
            [POLITICAL]:
                'Search for Political District, County, City, State, ZIP, or DMA'
        }
    },
    attributes: {},
    modifications: {}
};
