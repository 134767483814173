import React from 'react';
import { Layout, Text } from 'maslow';
import featuresManager from '@featuresManager';
import styles from './styles.scss';
import Summary from './Summary';
import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';
import { POLITICAL } from '@config';
import { LOCATIONS_POLITICAL_CAMPAIGNS_LINK } from '@config/idConstants';

export default function (version, feature) {
    const LocationSummarySelections = () => {
        const [role] = featuresManager.getCustomFilterState('role');
        return (
            <React.Fragment>
                <div className={styles.wrapper}>
                    <Summary />
                </div>
                {role === POLITICAL && (
                    <React.Fragment>
                        <Layout flex="1"></Layout>

                        <Text
                            type="input"
                            color="vividNavy"
                            className={styles.politicalTouWrapper}>
                            Political Districts may be used only for{' '}
                            <a
                                id={LOCATIONS_POLITICAL_CAMPAIGNS_LINK}
                                target="_blank"
                                href={`${termsAndPolicyConfig.termsOfUse}#PoliticalCampaigns`}
                                className={styles.linkText}>
                                <Text type="input" color="vividNavy">
                                    legally permissible political campaigns
                                </Text>
                            </a>
                        </Text>
                    </React.Fragment>
                )}
            </React.Fragment>
        );
    };

    return LocationSummarySelections;
}
