import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Layout, Text } from 'maslow';

import { hasAudience, hasMultipleAudiences } from '@services/audiencesService';
import VennSection from './VennSection';
import SelectedAudiences from './SelectedAudiences';

import styles from './audiences.scss';

const Audiences = ({ audienceStyles }) => {
    const audiences = useSelector(state => state.audience.audiences);
    const isMultipleAudiences = hasMultipleAudiences(audiences);
    const title = isMultipleAudiences
        ? 'Audiences Selected'
        : 'Audience Selected';
    const titleWrapperStyle = isMultipleAudiences ? '' : styles.marginBottom;

    return (
        <React.Fragment>
            {hasAudience(audiences) && (
                <Layout flexDirection="column" className={styles.wrapper}>
                    <Layout
                        flexDirection="row"
                        vAlign="center"
                        className={titleWrapperStyle}>
                        <Layout
                            className={styles.round}
                            hAlign="center"
                            vAlign="center">
                            <Text
                                color="brightBlue"
                                className={styles.boldText}>
                                {audiences.length}
                            </Text>
                        </Layout>
                        <Text type="h4" className={styles.boldText}>
                            {title}
                        </Text>
                    </Layout>

                    <VennSection />

                    <SelectedAudiences audienceStyles={audienceStyles} />
                </Layout>
            )}
        </React.Fragment>
    );
};

Audiences.propTypes = {
    audienceStyles: PropTypes.array.isRequired
};

export default Audiences;
