// Audience Selection
export const AUDIENCE_SELECTION_BUILD_AUDIENCE =
    'audience-selection-build-audience';
export const AUDIENCE_SELECTION_MY_AUDIENCE = 'audience-selection-my-audience';
export const AUDIENCE_SELECTION_CUSTOM_AUDIENCE =
    'audience-selection-custom-audience';
export const AUDIENCE_BUILDER_TOOLTIP_INFO = 'audience-builder-tooltip-info';
export const SAVED_AUDIENCES_TOOLTIP_INFO = 'saved-audiences-tooltip-info';
export const CUSTOM_LIST_TOOLTIP_INFO = 'custom-list-tooltip-info';

//My Audiences
export const MY_AUDIENCES_EMPTY_AUDIENCES_BUILDER =
    'my-audiences-empty-audiences-builder';
export const MY_AUDIENCES_EMPTY_AUDIENCES_CUSTOM =
    'my-audiences-empty-audiences-custom';
export const MY_AUDIENCES_SEARCH_INPUT = 'my-audiences-search-input';
export const MY_AUDIENCES_SEARCH_CLEAR = 'my-audiences-search-clear';
export const MY_AUDIENCES_SELECT_AUDIENCE = 'my-audiences-select-audience';

//Audience Builder
export const AUDIENCE_BUILDER_NAV_BUTTON = 'audience-builder-nav-button';
export const AUDIENCE_BUILDER_OPTION = 'audience-builder-option';
export const AUDIENCE_BUILDER_CATEGORY = 'audience-builder-category';
export const AUDIENCE_BUILDER_COMBINATIONS_SAVE =
    'audience-builder-combinations-save';
export const AUDIENCE_BUILDER_POLITICAL_CAMPAIGNS_LINK =
    'audience-builder-political-campaigns-link';
export const AUDIENCE_BUILDER_SMALL_VENN = 'audience-builder-small-venn';
export const AUDIENCE_BUILDER_VENN = 'audience-builder-venn';
export const AUDIENCE_BUILDER_COMBINATIONS_CLOSE =
    'audience-builder-combinations-close';

//Viewership
export const NETWORK_SCROLL_LEFT = 'network-scroll-left';
export const NETWORK_SCROLL_RIGHT = 'network-scroll-right';
export const NETWORK_SCROLL_OPTION = 'network-scroll-option';

// Custom list
export const CUSTOM_LIST_BOOK_TERMS_CHECKBOX = 'custom-list-terms-checkbox';
export const CUSTOM_LIST_AUDIENCE_NAME_INPUT =
    'custom-list-audience-name-input';
export const CUSTOM_LIST_AUDIENCE_DESCRIPTION_INPUT =
    'custom-list-audience-description-input';
export const CUSTOM_LIST_AUDIENCE_SAVE = 'custom-list-audience-save';
export const CUSTOM_LIST_STOP_BUTTON = 'custom-list-stop-button';
export const CUSTOM_LIST_DROPZONE = 'custom-list-dropzone';
export const CUSTOM_LIST_BACK = 'custom-list-back';
export const CUSTOM_LIST_DROPZONE_BROWSE_LINK =
    'custom-list-dropzone-browse-link';
export const CUSTOM_LIST_REMOVE_BUTTON = 'custom-list-remove-button';
export const CUSTOM_LIST_AUDIENCE_SAVE_USE = 'custom-list-audience-save-use';
export const CUSTOM_LIST_TERMS = 'custom-list-terms';

// Audience Summary //TODO in categories
export const AUDIENCE_SUMMARY_CANCEL_SAVE_AUDIENCE =
    'audience-summary-cancel-save-audience';
export const AUDIENCE_SUMMARY_INPUT_SAVE_AUDIENCE =
    'audience-summary-input-save-audience';
export const AUDIENCE_SUMMARY_SAVE_AUDIENCE = 'audience-summary-save-audience';
export const AUDIENCE_SUMMARY_SAVE_AND_USE_AUDIENCE =
    'audience-summary-save-and-use-audience';
export const AUDIENCE_SUMMARY_SCROLL = 'audience-summary-scroll';
export const AUDIENCE_SUMMARY_CLEAR_CATEGORY =
    'audience-summary-clear-category';
export const AUDIENCE_SUMMARY_SELECTED_AUDIENCE_HEADER =
    'audience-summary-selected-audience-header';
export const AUDIENCE_SUMMARY_CLEAR_OPTION = 'audience-summary-clear-option';
export const AUDIENCE_SUMMARY_SELECT_COMBINATIONS =
    'audience-summary-select-combinations';
export const AUDIENCE_SUMMARY_OPEN_SAVE_POPUP =
    'audience-summary-open-save-popup';
export const AUDIENCE_SUMMARY_TYPE_OPTION = 'audience-summary-type-option';
export const AUDIENCE_SUMMARY_DESCRIPTION_SAVE_AUDIENCE =
    'audience-summary-description-save-audience';

// Book Page
export const BOOK_PLAN_NAME_INPUT = 'book-plan-name-input';
export const BOOK_ADVERTISER_NAME_INPUT = 'book-advertiser-name-input';
export const BOOK_TOGGLE_PAYMENT_METHOD_PAY_NOW =
    'book-toggle-payment-method-pay-now';
export const BOOK_TOGGLE_PAYMENT_METHOD_PAY_LATER =
    'book-toggle-payment-method-pay-later';
export const BOOK_SUBMIT_PLAN_BUTTON = 'book-submit-plan';
export const BOOK_CREATE_NEW_PLAN_BUTTON = 'book-create-new-plan-button';
export const BOOK_GO_TO_PLAN_LIST_BUTTON = 'book-go-to-plan-list-button';
export const BOOK_TERMS_LINK = 'book-terms-link';
export const BOOK_TERMS_CHECKBOX = 'book-terms-checkbox';
export const BOOK_CARDHOLDER_NAME = 'book-cardholder-name';
export const BOOK_PAYMENT_METHOD = 'book-payment-method';
export const BOOK_CARD_NUM_ELEMENT = 'book-card-num-element';
export const BOOK_CARD_EXPIRY_ELEMENT = 'book-card-expiry-element';
export const BOOK_CARD_CVC_ELEMENT = 'book-card-cvc-element';

//Budget
export const BUDGET_TOTAL_RESET_BUTTON = 'budget-total-reset-button';
export const BUDGET_TOTAL_INPUT = 'budget-total-input';
export const BUDGET_PERCENT_INPUT = 'budget-percent-input';
export const BUDGET_INPUT = 'budget-input';
export const BUDGET_ENABLE_CHANNEL = 'budget-enable-channel';
export const BUDGET_SLIDER = 'budget-slider';
export const BUDGET_EDIT_CHANNEL = 'budget-edit-channel';
export const BUDGET_REMOVE_CHANNEL = 'budget-remove-channel';
export const BUDGET_SUB_PERCENT_BUBBLE = 'budget-sub-percent_bubble';
export const BUDGET_TOOLTIP_INFO = 'budget-tooltip-info';

//Campaign Type
export const CAMPAIGN_POLITICAL = 'campaign-political';
export const CAMPAIGN_COMMERCIAL = 'campaign-commercial';
export const CAMPAIGN_COMMERCIAL_TOOLTIP_INFO =
    'campaign-commercial-tooltip-info';
export const CAMPAIGN_POLITICAL_TOOLTIP_INFO =
    'campaign-political-tooltip-info';

//Creative
export const CREATIVE_PDF_LINK = 'creative-pdf-link';
export const CREATIVE_SKIP_UPLOAD = 'creative-skip-upload';
export const CREATIVE_EMPTY_DROPZONE = 'creative-empty-dropzone';
export const CREATIVE_MINI_DROPZONE = 'creative-mini-dropzone';
export const CREATIVE_INPUT = 'creative-input';
export const CREATIVE_DESTINATION_URL = 'creative-destination-url';
export const CREATIVE_REMOVE_FILES = 'creative-remove-files';
export const CREATIVE_SHOW_AVAILABLE_SUBCAMPAIGNS =
    'creative-show-available-subcampaigns';
export const CREATIVE_REMOVE_SUBCAMPAIGN = 'creative-remove-subcampaign';
export const CREATIVE_ADD_SUBCAMPAIGN = 'creative-add-subcampaign';
export const CREATIVE_REMOVE_FILE = 'creative-remove-file';
export const CREATIVE_RETURN_TO_PLAN_LIST = 'creative-return-to-plan-list';
export const CREATIVE_CANCEL_EDITS = 'creative-cancel-edits';
export const CREATIVE_RESUME_EDITS = 'creative-resume-edits';
export const CREATIVE_PUBLISH_EDITS = 'creative-publish-edits';
export const CREATIVE_ACTIVE_TAB = 'creative-active-tab';
export const CREATIVE_PAUSED_TAB = 'creative-paused-tab';
export const CREATIVE_ACTION_MENU = 'creative-action-menu';
export const CREATIVE_ACTION_MENU_ITEM = 'creative-action-menu-item';

//Plans
export const PLANS_NEW_PLAN = 'plans-new-plan';
export const PLANS_NETWORK_RANKER = 'plans-network-ranker';
export const PLANS_SEARCH = 'plans-search';
export const PLANS_CONTINUE_EDITING = 'plans-continue-editing';
export const PLANS_VIEW_PLAN = 'plans-view-plan';
export const PLANS_VIEW_REPORT = 'plans-view-report';
export const PLANS_NAME_TOOLTIP = 'plans-name-tooltip';
export const PLANS_ADVERTISER_NAME_TOOLTIP = 'plans-advertiser-name-tooltip';
export const PLANS_ACTION_MENU = 'plans-action-menu';
export const PLANS_ACTION_MENU_ITEM = 'plans-action-menu-item';
export const PLANS_CANCEL_REMOVE = 'plans-cancel-remove';
export const PLANS_REMOVE_PLAN = 'plans-remove-plan';
export const PLANS_RETURN_TO_PLAN_LIST = 'plans-return-to-plan-list';
export const PLANS_SORT = 'plans-sort';
export const PLANS_SORT_CRITERION = 'plans-sort-criterion';
export const PLANS_SORT_ASCENDING_ORDER = 'plans-sort-ascending-order';
export const PLANS_SORT_DESCENDING_ORDER = 'plans-sort-descending-order';
export const PLANS_INFINITE_SCROLL_BUTTON = 'plans-infinite-scroll-button';

//Locations
export const LOCATIONS_SELECTED_LOCATION = 'locations-selected-location';
export const LOCATIONS_REMOVE_LOCATIONS = 'locations-remove-locations';
export const LOCATIONS_POLITICAL_CAMPAIGNS_LINK =
    'locations-political-campaigns-link';

//Login
export const LOGIN_EMAIL_INPUT = 'login-email-input';
export const LOGIN_LOGIN_BUTTON = 'login-login-button';
export const LOGIN_PASSWORD_INPUT = 'login-password-input';
export const LOGIN_CREATE_ACCOUNT = 'login-create-account';
export const LOGIN_FORGOT_PASSWORD = 'login-forgot-password';
export const LOGIN_NEW_PASSWORD = 'login-new-password';
export const LOGIN_NEW_PASSWORD_CONFIRM = 'login-new-password-confirm';
export const LOGIN_RESET_PASSWORD = 'login-reset-password';
export const LOGIN_PASSWORD_CHANGED = 'login-password-changed';
export const LOGIN_SEND_LINK = 'login-send-link';
export const LOGIN_SEND_LINK_BACK = 'login-send-link-back';
export const LOGIN_SEND_LINK_EMAIL_INPUT = 'login-send-link-email-input';

// MainNav
export const MAIN_NAV = 'main-nav';
export const MAIN_NAV_STAY = 'main-nav-stay';
export const MAIN_NAV_LOGOUT = 'main-nav-logout';
export const MAIN_NAV_CLOSE = 'main-nav-close';

// MPS
export const MEDIA_PLAN_NAME = 'media-plan-name';
export const MEDIA_PLAN_ADVERTISER_NAME = 'media-plan-advertiser-name';
export const MEDIA_PLAN_TOGGLE_AUDIENCE_CATEGORIES =
    'media-plan-toggle-audience-categories';
export const MEDIA_PLAN_TOGGLE_VIEW_NETWORKS =
    'media-plan-toggle-view-networks';
export const MEDIA_PLAN_BOOK = 'media-plan-book';
export const MEDIA_PLAN_OPEN_SAVE_POPUP = 'media-plan-open-save-popup';
export const MEDIA_PLAN_VIEW_SAVED_PLANS = 'media-plan-view-saved-plans';
export const MEDIA_PLAN_CLOSE_SAVE_POPUP = 'media-plan-close-save-popup';
export const MEDIA_PLAN_POPUP_NAME = 'media-plan-popup-name';
export const MEDIA_PLAN_POPUP_ADVERTISER_NAME =
    'media-plan-popup-advertiser-name';
export const MEDIA_PLAN_POPUP_SAVE = 'media-plan-popup-save';
export const MEDIA_PLAN_PDF_EXPORT_OPEN_POPUP =
    'media-plan-pdf-export-toggle-popup';
export const MEDIA_PLAN_PDF_EXPORT_CLOSE_POPUP =
    'media-plan-pdf-export-close-popup';
export const MEDIA_PLAN_PDF_EXPORT_PLAN_NAME =
    'media-plan-pdf-export-plan-name';
export const MEDIA_PLAN_PDF_EXPORT_ADVERTISER_NAME =
    'media-plan-pdf-export-advertiser-name';
export const MEDIA_PLAN_PDF_EXPORT_PDF = 'media-plan-pdf-export-pdf';
export const MEDIA_PLAN_POLITICAL_CAMPAIGNS_LINK =
    'media-plan-political-campaign-link';
export const MEDIA_PLAN_PDF_EXPORT_PDF_AND_SAVE =
    'media-plan-pdf-export-pdf-and-save';

// Networks
export const NETWORKS_HEADER_ARROW = 'networks-header-arrow';
export const NETWORKS_NETWORK = 'networks-network';
export const NETWORKS_SUMMARY_SELECTED_NETWORK =
    'networks-summary-selected-network';
export const NETWORKS_SUMMARY_CLEAR_SELECTED =
    'networks-summary-clear-selected';

//Schedule
export const SCHEDULE_DAYPART = 'daypart';
export const SCHEDULE_HIATUS_WEEK = 'schedule-hiatus-week';
export const SCHEDULE_WEEKS_INPUT = 'schedule-weeks-input';
export const SCHEDULE_CLEAR = 'schedule-clear';
export const WEEK_HEADER_INPUT = 'week-header-input';
export const WEEK_HEADER_CLEAR = 'week-header-clear';
export const SCHEDULE_CALENDAR_NEXT = 'schedule-calendar-next';
export const SCHEDULE_CALENDAR_PREV = 'schedule-calendar-prev';
export const SCHEDULE_DAYPICKER_DAY = 'schedule-daypicker-day';
export const SCHEDULE_TOGGLE_FORMAT = 'schedule-toggle-format';

//Sign Up
export const SIGNUP_LOGIN = 'signup-login';
export const SIGNUP_BOOK_TERMS_CHECKBOX = 'signup-terms-checkbox';
export const SIGNUP_FIRST_NAME_INPUT = 'signup-first-name-input';
export const SIGNUP_LAST_NAME_INPUT = 'signup-last-name-input';
export const SIGNUP_EMAIL_INPUT = 'signup-email-input';
export const SIGNUP_PASSWORD_INPUT = 'signup-password-input';
export const SIGNUP_PASSWORD_CONFIRM = 'signup-password-confirm';
export const SIGNUP_REGISTER_BUTTON = 'signup-register-button';
export const SIGNUP_CHECKBOX_TERMS = 'signup-checkbox-terms';

export const VERIFICATION_ERROR_SIGNUP = 'verification-error-signup';
export const NOT_FOUND_BACK = 'not-found-back';
export const POPULATE_DATA = 'populate-data';
export const MY_AUDIENCES_LINK = 'my-audiences-link';
export const GLOBAL_TERMS = 'global-terms';
export const MAIN_SIDEBAR_LOGO = 'main-sidebar-logo';
export const PRIVACY_POLICY = 'privacy-policy';

//Toast Alert
export const TOAST_DISMISS = 'toast-dismiss';
export const TOAST_TOGGLE_DETAILS = 'toast-toggle-details';
export const TOAST_ROUTE_TO = 'toast-route-to';

//Reports
export const REPORTS_DOWNLOAD_CSV = 'reports-download-csv';

//Search
export const SEARCH_INPUT = 'search-input';
