import { createReducer } from '@reduxjs/toolkit';
import {
    ADD_DISCLAIMERS,
    REMOVE_DISCLAIMER,
    REMOVE_ALL_DISCLAIMERS
} from '../constants';
import { isObject, excludeFromObject } from '@lib';

const initialState = {};

const addDisclaimers = (state, action) => {
    const { value } = action.payload;
    return isObject(value) ? { ...state, ...value } : { ...state };
};

const removeDisclaimer = (state, action) => {
    const { id } = action.payload;
    return typeof id === 'number' ? excludeFromObject(state, id) : { ...state };
};

const removeAllDisclaimers = () => {
    return initialState;
};

export const disclaimer = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_DISCLAIMERS, addDisclaimers)
        .addCase(REMOVE_DISCLAIMER, removeDisclaimer)
        .addCase(REMOVE_ALL_DISCLAIMERS, removeAllDisclaimers)
        .addDefaultCase((state, _) => state);
});
