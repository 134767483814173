export const UPLOAD_STEPS = {
    INITIAL: 'initial',
    PREUPLOADING: 'preUploading',
    UPLOADING: 'uploading',
    MATCHING: 'matching',
    MATCHING_IN_PROGRESS: 'matchingInProgress',
    DONE_MATCHING: 'doneMatching',
    PROMPT_TO_SAVE: 'prompt_to_save',
    NEXT_STEPS: 'nextSteps',
    BELOW_THRESHOLD: 'belowThreshold',
    BELOW_MATCHING_THRESHOLD: 'belowMatchingThreshold',
    ERROR: 'error',
    ZERO_MATCHES: 'zeroMatches'
};

export const steps = {
    initial: {
        name: UPLOAD_STEPS.INITIAL,
        title: 'Drop or',
        caption: '.CSV File',
        subTitle: 'Your File Should Contain',
        body:
            'Between 5,000 (minimum) and 250,000 addresses (10MB file size maximum) with the information below:',
        blockToDisplay: 'example',
        action: 'initialAnimation'
    },
    preUploading: {
        name: UPLOAD_STEPS.PREUPLOADING,
        title: 'Upload in progress',
        caption: 'CANCEL',
        subTitle: 'Your File Should Contain',
        body:
            'Between 5,000 (minimum) and 250,000 addresses (10MB file size maximum) with the information below:',
        blockToDisplay: 'example',
        action: 'initFileUploadAnimation'
    },
    uploading: {
        name: UPLOAD_STEPS.UPLOADING,
        title: 'Upload in progress',
        caption: 'CANCEL',
        subTitle: 'Your File Should Contain',
        body:
            'Between 5,000 (minimum) and 250,000 addresses (10MB file size maximum) with the information below:',
        blockToDisplay: 'example',
        action: 'runFileUploadAnimation'
    },
    matching: {
        name: UPLOAD_STEPS.MATCHING,
        title: 'Matching in progress',
        caption: 'CANCEL',
        subTitle: 'Audience Size',
        body: 'This process may take a few minutes.',
        matchesText: 'pending',
        blockToDisplay: 'info',
        action: 'matchingAnimation'
    },
    matchingInProgress: {
        name: UPLOAD_STEPS.MATCHING_IN_PROGRESS,
        title: 'Matching in progress',
        caption: 'CANCEL',
        subTitle: 'Audience Size',
        body: 'This process may take a few minutes.',
        matchesText: 'pending',
        blockToDisplay: 'info',
        action: 'matchingInProgressAnimation'
    },
    doneMatching: {
        name: UPLOAD_STEPS.DONE_MATCHING,
        title: 'Matching in progress',
        caption: '',
        subTitle: 'Audience Size',
        body: 'This process may take a few minutes.',
        matchesText: 'pending',
        blockToDisplay: 'info',
        action: 'doneMatchingAnimation'
    },
    prompt_to_save: {
        name: UPLOAD_STEPS.PROMPT_TO_SAVE,
        title: 'Your file has matched',
        caption: '',
        subTitle: 'Audience Size',
        body: 'Your file has matched!',
        matchesText: 0,
        blockToDisplay: 'info',
        action: 'doneMatchingAnimation'
    },
    nextSteps: {
        name: UPLOAD_STEPS.NEXT_STEPS,
        title: 'Your file has matched',
        caption: '',
        subTitle: 'Audience Size',
        body: 'Your file has matched!',
        subText: 'Your custom list has been',
        matchesText: 0,
        blockToDisplay: 'info',
        action: 'doneMatchingAnimation'
    },
    belowThreshold: {
        name: UPLOAD_STEPS.BELOW_THRESHOLD,
        title: 'Number of households is less than the privacy minimum',
        caption: 'CANCEL',
        subTitle: 'Audience Size',
        body:
            'Your file contains less than 5,000 unique targetable households. For privacy reasons, we can not run campaigns against an audience this small. Please upload a new list or review the format of the list used to try again.',
        matchesText: '< 5,000',
        action: 'belowThresholdAnimation'
    },
    belowMatchingThreshold: {
        name: UPLOAD_STEPS.BELOW_MATCHING_THRESHOLD,
        title: 'Number of households is less than the privacy minimum',
        caption: 'CANCEL',
        subTitle: 'Audience Size',
        body:
            'Your file matches to less than 1000 unique targetable households. For privacy reasons, we can not run campaigns against an audience this small. Please upload a new list and try again.',
        matchesText: '< 1,000',
        action: 'belowThresholdAnimation'
    },
    zeroMatches: {
        name: UPLOAD_STEPS.ZERO_MATCHES,
        title: 'Number of households is less than the privacy minimum',
        caption: 'CANCEL',
        subTitle: 'Audience Size',
        body:
            'Your file matches to less than 1000 unique targetable households. For privacy reasons, we can not run campaigns against an audience this small. Please upload a new list or review the format of the list used to try again',
        matchesText: '< 1,000',
        action: 'belowThresholdAnimation'
    },
    error: {
        name: UPLOAD_STEPS.ERROR,
        title: 'An error has occurred',
        caption: 'CANCEL',
        subTitle: '.csv error',
        body:
            'Something went wrong. Please try again later or contact us for further help.',
        matchesText: 'error',
        action: 'errorFileUploadAnimation'
    }
};
export const MAX_AUDIENCE_NAME_LIMIT = 50;
export const MAX_AUDIENCE_DESC_LIMIT = 250;

export const DISPLAY_TEXT_STATES = {
    ERROR_RETRIEVING_AUDIENCES:
        'There was an error retrieving existing audiences.',
    ERROR_SAVING_AUDIENCE: 'There was an error saving the custom audience.',
    EXISTING_AUDIENCE_NAME: 'Audience name already exists.'
};

export const VALID_FILE_TYPES = ['text/csv', 'application/vnd.ms-excel'];

export const SHOW_TRASHCAN_STAGES = [
    UPLOAD_STEPS.PROMPT_TO_SAVE,
    UPLOAD_STEPS.NEXT_STEPS,
    UPLOAD_STEPS.BELOW_THRESHOLD,
    UPLOAD_STEPS.BELOW_MATCHING_THRESHOLD,
    UPLOAD_STEPS.ERROR,
    UPLOAD_STEPS.ZERO_MATCHES
];
