import { Privacy } from '../components/pages';

export default [
    {
        name: 'app.main.privacy',
        url: '/privacy',
        views: {
            'content@app': {
                component: Privacy
            }
        }
    }
];
