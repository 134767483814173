import { createReducer } from '@reduxjs/toolkit';
import {
    SET_CUSTOM_LIST,
    SET_CL_IS_FETCHING,
    SET_UPLOAD_STEP,
    RESET_CUSTOM_LIST,
    SET_AGREED_TO_TERMS,
    SET_MATCH_PERCENT
} from '../constants';
import { defaultCustomListShape } from '@services/customList';

const setCustomList = (state, action) => {
    const { customList } = action.payload;

    return { ...state, ...customList };
};

const setClIsFetching = (state, action) => {
    const { isFetchingCl } = action.payload;
    return { ...state, ...{ isFetchingCl } };
};

const resetCustomList = () => {
    return { ...defaultCustomListShape };
};

const setUploadStep = (state = 'initial', action) => {
    const { uploadStep } = action.payload;

    return { ...state, uploadStep };
};

const setAgreedToTerms = (state = 'initial', action) => {
    const agreedToTerms = action.payload;
    return { ...state, ...agreedToTerms };
};

const setMatchPercent = (state = 'initial', action) => {
    const { match_percent } = action.payload;

    return { ...state, ...match_percent };
};

export const customList = createReducer(
    { ...defaultCustomListShape },
    reducer => {
        reducer
            .addCase(SET_CUSTOM_LIST, setCustomList)
            .addCase(SET_CL_IS_FETCHING, setClIsFetching)
            .addCase(SET_UPLOAD_STEP, setUploadStep)
            .addCase(RESET_CUSTOM_LIST, resetCustomList)
            .addCase(SET_AGREED_TO_TERMS, setAgreedToTerms)
            .addCase(SET_MATCH_PERCENT, setMatchPercent)
            .addDefaultCase((state, _) => state);
    }
);
