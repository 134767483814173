import React, { useState, useEffect, memo } from 'react';

import featuresManager from '@featuresManager';

const css = {
    mounted: {
        opacity: 1,
        transition: 'all 1s ease-in-out'
    }
};

export default function (Component) {
    return memo(props => {
        const [featureState, setFeatureState] = useState(
            featuresManager.featureState
        );

        useEffect(() => {
            const token = window.emitter.addListener(
                'featureState.change',
                value => setFeatureState(value)
            );

            return () => {
                token.remove();
            };
        }, []);

        return (
            <Component
                style={css.mounted}
                customFilters={featuresManager.customFilters}
                customFiltersStates={featuresManager.customFiltersStates}
                {...props}
            />
        );
    });
}
