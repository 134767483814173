import { chain } from 'lodash';
import mime from 'mime-types';
import {
    getSubName,
    isValidUrl,
    getSubCampaign,
    getContextFromKey,
    getContextFromName
} from '@services';
import { AD_MESSENGER, FACEBOOK, INSTAGRAM } from '@config';
import { isArray, isObject, matchConditionals } from '@lib';
import { SUB_CAMPAIGN_SETTINGS, FILE_STATUS } from '@config/config.creative';
const { NEW, ACTIVE } = FILE_STATUS;

export const areSubFilesValid = (files, subs) => {
    if (!Array.isArray(files)) throw Error('first arg is not an array');
    if (!Array.isArray(subs)) throw Error('second arg is not an array');

    return subs.length
        ? subs.find(key => {
              return files.length
                  ? files.find(({ subCampaigns, fileLocation }) => {
                        return subCampaigns
                            ? subCampaigns.includes(getSubName(key)) &&
                                  fileLocation
                            : false;
                    })
                  : false;
          })
        : true;
};

export const areSubUrlsValid = (creative, subs) => {
    if (typeof creative !== 'object') throw Error('first arg is not an object');
    if (!Array.isArray(subs)) throw Error('second arg is not an array');

    return subs.find(key => {
        if (!creative[key]) return false;
        return isValidUrl(creative[key].url);
    });
};

export const isSubValid = (creative, key) => {
    if (typeof creative !== 'object') throw Error('first arg is not an object');
    if (!creative.hasOwnProperty('files'))
        throw Error('first arg must have "files" property');
    if (typeof key !== 'string') throw Error('second arg is not string');

    const { files } = creative;

    const sub = getSubCampaign(key);
    const { creativeRequired, urlRequired } = sub;

    const filesValid = creativeRequired.length
        ? areSubFilesValid(files, creativeRequired)
        : true;
    const urlsValid = urlRequired.length
        ? areSubUrlsValid(creative, urlRequired)
        : true;

    return filesValid && urlsValid;
};

export const validateSubCreatives = (creative, subCampaigns) => {
    if (typeof creative !== 'object') throw Error('first arg is not an object');
    if (!Array.isArray(subCampaigns)) throw Error('second arg is not an array');

    const subs = {};
    subCampaigns.forEach(key => {
        if (typeof key !== 'string')
            throw Error('second arg must be an array of strings');

        subs[key] = Boolean(isSubValid(creative, key));
    });
    return subs;
};

export const isSubValidForHeader = (creative, key) => {
    if (typeof creative !== 'object') throw Error('first arg is not an object');
    if (!creative.hasOwnProperty('files'))
        throw Error('first arg must have "files" property');
    if (typeof key !== 'string') throw Error('second arg is not string');

    const { files } = creative;
    const { creativeRequired } = getSubCampaign(key);

    const filesValid = creativeRequired.length
        ? areSubFilesValid(files, creativeRequired)
        : true;

    return filesValid;
};

export const validateSubsForHeader = (creative, subCampaigns) => {
    if (typeof creative !== 'object') throw Error('first arg is not an object');
    if (!Array.isArray(subCampaigns)) throw Error('second arg is not an array');

    const subs = {};
    subCampaigns.forEach(key => {
        if (typeof key !== 'string')
            throw Error('second arg must be an array of strings');

        const isValid =
            getSubName(key) === AD_MESSENGER
                ? isValidUrl(creative.adMessenger.url)
                : isSubValidForHeader(creative, key);

        subs[key] = Boolean(isValid);
    });
    return subs;
};

export const areAllSubsValid = subs => {
    if (typeof subs !== 'object') throw Error('first arg is not an object');
    return Object.values(subs).every(status => status);
};

export const getUnvalidatedSubs = subs => {
    if (typeof subs !== 'object') throw Error('first arg is not an object');
    return Object.keys(subs).filter(key => !subs[key]);
};

export const formatErrors = creative => {
    //We should not display errors if creative matches requirements for an existing product (eg. preMidRoll has no required dimensions)
    if (typeof creative !== 'object' && Boolean(creative))
        throw new Error('arg must be an object with truthy value');

    const { files } = creative;
    if (!isArray(files))
        throw new Error('arg must have a files property that is an array');

    creative.files = files.map(file => {
        const { applicableSubCampaigns } = file;
        //If it matches requirements of a subCampaign in budget, errors should not be displayed
        if (isArray(applicableSubCampaigns) && applicableSubCampaigns.length) {
            if (!file.errors.includes('errorDuringUpload')) file.errors = [];
        }
        //If it does not match the requirements of a subCampaign in the budget, this error should be displayed
        if (
            isArray(applicableSubCampaigns) &&
            !applicableSubCampaigns.length &&
            !file.errors.includes('errorDuringUpload')
        ) {
            file.errors.push('noValidProducts');
        }
        return file;
    });

    return creative;
};

export const subCampaignFunctions = {
    dimensions: ({ width, height }, conditions) => {
        const conditionArray = conditions.map(condition => [
            condition.width,
            condition.height
        ]);

        return matchConditionals.isEqualTo([width, height], conditionArray);
    },
    formats: ({ mimeType }, conditions) => {
        const conditionArray = conditions.map(condition => [condition]);

        return matchConditionals.isEqualTo([mimeType], conditionArray);
    },
    maxSize: ({ size }, condition) => {
        return matchConditionals.isLessThanOrEqual(size, condition);
    },
    aspectRatio: ({ aspectRatio }, conditions) => {
        const conditionArray = conditions.map(condition => [condition]);
        return matchConditionals.isEqualTo([aspectRatio], conditionArray);
    }
};

export const areSubConditionsMet = (key, file) => {
    if (!key || typeof key !== 'string')
        throw new Error('first arg must be a string with truthy value');

    if (!file || typeof file !== 'object')
        throw new Error('second arg must be an object with a truthy value');
    const subCampaign = SUB_CAMPAIGN_SETTINGS[key];
    const groupingKeys = Object.keys(subCampaign);

    const groupingMatches = groupingKeys.filter(groupingKey => {
        const grouping = subCampaign[groupingKey];
        const ruleKeys = Object.keys(grouping);

        const rulesPassed = ruleKeys.filter(rule => {
            return subCampaignFunctions[rule](file, grouping[rule]);
        });
        return rulesPassed.length === ruleKeys.length;
    });

    return groupingMatches.length;
};

export const getSelectedSubCampaigns = (files, budget) => {
    if (!isArray(files)) throw new Error('first arg must be an array');
    if (!budget || typeof budget !== 'object')
        throw new Error('second array must be an object with a truthy value');

    const returnArray = files.map(file => {
        const subCampaignKeys = Object.keys(SUB_CAMPAIGN_SETTINGS);
        const { excludedProducts } = file;

        file.applicableSubCampaigns = subCampaignKeys
            .filter(key => {
                return budget[key] > 0 ? areSubConditionsMet(key, file) : null;
            })
            .map(key => getSubName(key));

        file.subCampaigns = file.applicableSubCampaigns.filter(
            sub => !excludedProducts.includes(sub)
        );

        return file;
    });

    return returnArray;
};

export const replaceUpdatedFile = (state, file, index) => {
    if (!isArray(state)) throw new Error('first arg must be an array');
    if (!file || typeof file !== 'object')
        throw new Error('second arg must be an object with truthy value');
    if (typeof index !== 'number')
        throw new Error('if third arg exists, it must be a number');

    const category = state
        .filter(storedFile => storedFile.type === file.type)
        .map((storedFile, i) => {
            if (file.id) {
                return storedFile.id === file.id
                    ? { ...storedFile, ...file }
                    : { ...storedFile };
            } else {
                return i === index
                    ? { ...storedFile, ...file }
                    : { ...storedFile };
            }
        });

    const others = state.filter(storedFile => storedFile.type !== file.type);

    return [...others, ...category];
};

export const updateCreativeStatus = (state, id, status) => {
    if (!isArray(state)) throw new Error('first argument must be an array');
    if (typeof id !== 'number')
        throw new Error('second argument must be a number');
    if (typeof status !== 'string')
        throw new Error('third argument must be a string');

    const newState = state.map(storedFile => {
        return storedFile.id === id
            ? { ...storedFile, ...{ status } }
            : { ...storedFile };
    });

    return newState;
};

export const getPublishStatus = status => {
    if (typeof status !== 'string')
        throw new Error('argument must be a string');
    return status === NEW ? ACTIVE : status;
};

export const updateFileStatuses = files => {
    if (!isArray(files)) throw new Error('argument must be an array');
    return files.map(file =>
        file.status ? file : { ...file, status: ACTIVE }
    );
};

export const sortCreatives = files => {
    if (!isArray(files)) throw new Error('argument must be an array');

    const uploadingFiles = files
        .filter(file => {
            return file.isUploading;
        })
        .reverse();

    const uploadedFiles = files.filter(file => {
        return !file.isUploading;
    });

    const sortedUploadedFiles = uploadedFiles.sort(function (a, b) {
        return new Date(b.createdDate) - new Date(a.createdDate);
    });

    return [...uploadingFiles, ...sortedUploadedFiles];
};

export const filterCreatives = (files, status, type) => {
    if (!isArray(files)) throw new Error('first argument must be an array');
    if (typeof status !== 'string')
        throw new Error('second argument must be a string');
    if (type && typeof type !== 'string')
        throw new Error('optional third argument must be a string');

    const filteredFiles = files.filter(file => {
        return (
            getPublishStatus(file.status) === status &&
            (type ? type === file.type : true)
        );
    });

    return sortCreatives(filteredFiles);
};

export const getCreativesObject = (id, creativeUrls, creativeProducts) => {
    if (typeof id !== 'number')
        throw new Error('first argument must be a number');

    if (!creativeUrls || typeof creativeUrls !== 'object')
        throw new Error('second argument must be an object with truthy value');
    if (!isArray(creativeProducts))
        throw new Error('third argument must be an array');

    return {
        urls: {
            ClickThroughUrl: creativeUrls.destination || '',
            AdMessengerUrl: creativeUrls.adMessenger || '',
            InstagramUrl: creativeUrls.instagram || '',
            FacebookUrl: creativeUrls.facebook || ''
        },
        creativeProducts,
        id
    };
};

export const findProductBudget = (key, products, budget) => {
    if (typeof key !== 'string')
        throw new Error('First argument must be a string');
    if (!isArray(products)) throw new Error('Second argument must be an array');
    if (!isArray(budget)) throw new Error('Third argument must be an array');
    return (
        budget.find(({ Name }) => {
            return Name === getContextFromKey(key);
        })?.Amount || 0
    );
};

export const getExcludedProducts = (creative, budget) => {
    if (!isObject(creative))
        throw new Error('First argument must be an object');
    const { products } = creative;
    return Object.keys(SUB_CAMPAIGN_SETTINGS)
        .filter(key => {
            const productBudget = findProductBudget(key, products, budget);

            return productBudget > 0
                ? areSubConditionsMet(key, creative)
                : null;
        })
        .map(key => getSubName(key))
        .filter(subName => {
            return !products.find(
                product => product === getContextFromName(subName)
            );
        });
};

export const extractCreatives = (creativeProducts, a4PlanId, budget) => {
    if (!isArray(creativeProducts))
        throw new Error('First argument must be an array');

    if (isNaN(a4PlanId)) throw new Error('Second argument must be a number');

    if (!isArray(budget)) throw new Error('Third argument must be an array');

    return creativeProducts.map(creative => {
        const {
            name,
            fileLocation,
            size,
            height,
            width,
            creativeId,
            creativeStatus,
            createdDate,
            beginDate,
            endDate,
            products,
            politicalDisclaimer
        } = creative;

        const mimeType = mime.lookup(name);
        const type = mimeType.split('/')[0];
        const aspectRatio = width / height;

        return {
            name,
            type,
            mimeType,
            src: fileLocation,
            status: creativeStatus,
            size,
            width,
            height,
            aspectRatio,
            errors: [],
            isUploading: false,
            fileLocation,
            startDate: beginDate,
            endDate: endDate,
            a4PlanId,
            excludedProducts: getExcludedProducts(
                {
                    name,
                    fileLocation,
                    createdDate,
                    size,
                    height,
                    width,
                    mimeType,
                    type,
                    aspectRatio,
                    products
                },
                budget
            ),
            file: {},
            duration: 0,
            length: 0,
            advertiserId: 1,
            createdDate,
            id: creativeId,
            disclaimer: politicalDisclaimer
        };
    });
};

export const getDisclaimer = (disclaimer, id) => {
    return disclaimer?.[id]?.status ?? 'N/A';
};

export const extractUrls = ({
    clickThroughUrl,
    adMessengerUrl,
    instagramUrl,
    facebookUrl
}) => {
    return {
        destination: {
            isValid: clickThroughUrl ? true : false,
            url: clickThroughUrl || ''
        },
        adMessenger: {
            isValid: adMessengerUrl ? true : false,
            url: adMessengerUrl
        },
        facebook: {
            isValid: facebookUrl ? true : false,
            url: facebookUrl
        },
        instagram: {
            isValid: instagramUrl ? true : false,
            url: instagramUrl
        }
    };
};

export const extractDisclaimers = files => {
    if (!isArray(files)) throw new Error('Argument must be an array');
    return chain(files)
        .map(({ fileLocation, id, name, disclaimer }) => ({
            fileLocation,
            id,
            name,
            status: disclaimer
        }))
        .keyBy('id')
        .value();
};

export const dedupeFileName = (fileName, files) => {
    if (typeof fileName !== 'string')
        throw new Error('First argument must be string');
    if (!isArray(files)) throw new Error('Second argument must be array');

    let counter = 0;
    const dedupe = name => {
        const dupe = files.find(file => {
            return name === file.name;
        });

        if (dupe) {
            counter++;
            const [simpleName, ext] = fileName.split(/\.(?=[^\.]+$)/);
            return dedupe(`${simpleName}(${counter}).${ext}`);
        }
        return name;
    };
    return dedupe(fileName);
};
