import React from 'react';
import PropTypes from 'prop-types';

import { Text, Layout } from 'maslow';
import styles from '../styles.scss';

const EmptySelections = ({ currentAudienceIndex, title, description }) => {
    return (
        <Layout flexDirection="column">
            {currentAudienceIndex === 0 && (
                <Text type="h5" className={styles.emptyAudTitle}>
                    {title}
                </Text>
            )}

            <Text type="body" className={styles.emptyAudText}>
                {description}
            </Text>
        </Layout>
    );
};

EmptySelections.propTypes = {
    currentAudienceIndex: PropTypes.number.isRequired,
    title: PropTypes.string.isRequired,
    description: PropTypes.string.isRequired
};

export default EmptySelections;
