import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { cancelable } from 'cancelable-promise';
import { useSelector, useDispatch } from 'react-redux';
import { Layout, Text, Spinner, Icon } from 'maslow';

import requestHandlers from '@requestHandlers';
import { addDisclaimers } from '@state/creative/actions';
import { getDisclaimerStatusSelector } from '@selectors';
import { validateDisclaimer } from '@services/creative';
import { reMount } from '@components/hoc';
import styles from '../styles.scss';
import successIcon from '@public/img/success.svg';
import errorIcon from '@public/img/error.svg';
import { STATUS } from '@config/config.creative';

export const DisclaimerText = ({ file }) => {
    const { id, fileLocation, name } = file;
    const dispatch = useDispatch();
    const status = useSelector(getDisclaimerStatusSelector(id));

    useEffect(() => {
        let disclaimerPromise;
        if (!status) {
            disclaimerPromise = cancelable(
                validateDisclaimer(
                    fileLocation,
                    requestHandlers.creative.detectText
                )
            )
                .then(res => {
                    const status = res ? STATUS.VALID : STATUS.INVALID;
                    const disclaimer = {
                        [id]: {
                            name,
                            fileLocation,
                            id,
                            status
                        }
                    };
                    dispatch(addDisclaimers(disclaimer));
                })
                .catch(console.error);
        }
        return () => {
            if (disclaimerPromise) disclaimerPromise.cancel();
        };
    }, [status]);

    const renderStatusIcon = src => {
        const IconComponent = reMount(Icon);

        return (
            <div className={styles.statusIcon}>
                <IconComponent src={src} size="2" />
            </div>
        );
    };

    const render = () => {
        switch (status || STATUS.IN_PROGRESS) {
            case STATUS.IDLE: {
                return (
                    <div
                        className={[
                            styles.statusWrapper,
                            styles.idleWrapper
                        ].join(' ')}>
                        <Layout flexDirection="row" vAlign="center" fillParent>
                            <Text type="body">Warming up...</Text>
                            <div className={styles.statusIcon}>
                                <Spinner size="2" margin="0" />
                            </div>
                        </Layout>
                    </div>
                );
            }
            case STATUS.IN_PROGRESS: {
                return (
                    <div
                        className={[
                            styles.statusWrapper,
                            styles.inProgressWrapper
                        ].join(' ')}>
                        <Layout flexDirection="row" vAlign="center" fillParent>
                            <Text type="body">
                                Checking for Political Disclaimers
                            </Text>
                            <div className={styles.statusIcon}>
                                <Spinner size="2" margin="0" />
                            </div>
                        </Layout>
                    </div>
                );
            }
            case STATUS.VALID: {
                return (
                    <div
                        className={[
                            styles.statusWrapper,
                            styles.successWrapper
                        ].join(' ')}>
                        <Layout flexDirection="row" vAlign="center" fillParent>
                            <Text type="body" color="green">
                                Checking Successfully Finished
                            </Text>
                            {renderStatusIcon(successIcon)}
                        </Layout>
                    </div>
                );
            }
            case STATUS.INVALID: {
                return (
                    <div
                        className={[
                            styles.statusWrapper,
                            styles.errorWrapper
                        ].join(' ')}>
                        <Layout flexDirection="row" vAlign="center" fillParent>
                            <Text type="body" color="red">
                                Political Disclaimer Is Missing
                            </Text>
                            {renderStatusIcon(errorIcon)}
                        </Layout>
                    </div>
                );
            }
            default:
                return null;
        }
    };

    return <div>{render()}</div>;
};

DisclaimerText.propTypes = {
    fileLocation: PropTypes.string
};
