import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Text } from 'maslow';
import SelectedAudienceOption from '../SelectedAudienceOption';
import styles from '../audiences.scss';

const SelectedAudienceCategory = ({
    category,
    categoryName,
    audienceStyles
}) => {
    const renderCategory = () => {
        return (
            <Layout flexDirection="column" className={styles.categoryText}>
                <Layout
                    flexDirection="row"
                    hAlign="space-between"
                    vAlign="center"
                    className={styles.selectedAudienceCategoryTitleWrapper}>
                    <Text type="input" className={styles.boldText}>
                        {categoryName}
                    </Text>
                </Layout>
            </Layout>
        );
    };

    const renderOption = () => {
        return category.map((option, i) => {
            return <SelectedAudienceOption key={i} option={option} />;
        });
    };

    return (
        <Layout
            flexDirection="row"
            className={styles.card}
            style={audienceStyles.card}>
            {renderCategory()}
            <Layout flexDirection="row" className={styles.rightSection}>
                {renderOption()}
            </Layout>
        </Layout>
    );
};

SelectedAudienceCategory.propTypes = {
    categoryName: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    audienceStyles: PropTypes.object.isRequired
};

export default SelectedAudienceCategory;
