export default {
    name: 'VerificationError', // Component name
    description: 'VerificationFailed page',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['verificationError'],
    sections: ['verificationError'],
    flavours: ['cgd', 'cablevision', 'beta'],
    userRoles: ['guest', 'admin'],
    data: {},
    attributes: {},
    modifications: {}
};