import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import featuresManager from '@featuresManager/';
import { Slider } from 'maslow';
import styles from '../Distribution/styles.scss';
import { budgetCalc } from '@services/budget';
import { existsInArray } from '@lib';
import { PLAN_STATUSES } from '@config/config.planStatus';

import {
    createPercentSelector,
    budgetSelector,
    regionSelector,
    createSectionSelector,
    getAmountBasedOnTotalSelector,
    getLockedSubCampaignsSelector,
    getLockedChannelsSelector
} from '@selectors/';

import { updateBudget } from '@state/budget/actions';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import { setIsValid } from '@state/creative/actions';

import { idBuilder } from '@services';
import { BUDGET_SLIDER } from '@config/idConstants';

const BudgetSlider = ({ size, color, config, theme, disabledChannelsKeys }) => {
    const { key } = config;
    const dispatch = useDispatch();
    const [role] = featuresManager.getCustomFilterState('role');

    const [value, setValue] = useState(0);
    const percent = useSelector(createPercentSelector[theme](key));
    const budget = useSelector(budgetSelector);
    const region = useSelector(regionSelector);
    const sectionBudget = useSelector(createSectionSelector[theme](key));
    const sliderPercent = useSelector(getAmountBasedOnTotalSelector(percent));
    const lockedSubCampaigns = useSelector(getLockedSubCampaignsSelector);
    const lockedChannels = useSelector(getLockedChannelsSelector);

    const updateSlider = value => {
        setValue(value);
    };

    const updateState = value => {
        const newAmounts = budgetCalc[theme](
            key,
            value,
            budget,
            true,
            lockedSubCampaigns,
            lockedChannels,
            role,
            region,
            disabledChannelsKeys
        );

        const newBudget =
            theme === 'channel' ? newAmounts.channels[key] : newAmounts[key];

        if (newBudget !== sectionBudget) {
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        }

        dispatch(setIsValid(false));
        dispatch(updateBudget.setIsAutoDistributed(false));
        dispatch(updateBudget[theme](newAmounts));
    };

    useEffect(() => {
        if (!isNaN(percent)) updateSlider(percent);
    }, [percent]);

    useEffect(() => {
        if (!isNaN(percent)) updateSlider(sliderPercent);
        if (existsInArray(key, lockedChannels) && budget.channels[key] > 0) {
            updateSlider(0);
        }
    }, []);

    return (
        <Slider
            id={idBuilder(BUDGET_SLIDER, key)}
            size={size}
            className={styles.smallSliderWrapper}
            color={color}
            value={value}
            onAfterChange={updateState}
            onChange={updateSlider}
        />
    );
};

BudgetSlider.propTypes = {
    size: PropTypes.string,
    color: PropTypes.string,
    config: PropTypes.object.isRequired,
    theme: PropTypes.string,
    disabledChannelsKeys: PropTypes.array
};

export default BudgetSlider;
