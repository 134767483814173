import axios from 'axios';

export default function (email) {
    return axios({
        method: 'GET',
        url: `user/check?email=${email.toLowerCase()}`
    }).then(() => {
        return true;
    }).catch(() => {
        return false;
    });
};
