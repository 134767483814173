import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Text, Icon, Button, Divider, Layout } from 'maslow';
import styles from './styles.scss';
import closeIcon from '@public/img/close.svg';
import CombinationsVenn from './CombinationsVenn';
import { fetchHouseholdCount } from '@state/audience/actions/households';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { connect } from 'react-redux';
import { hhCountLimit } from '@config/config.households';

import { PLAN_STATUSES } from '@config/config.planStatus';
import {
    AUDIENCE_BUILDER_COMBINATIONS_SAVE,
    AUDIENCE_BUILDER_COMBINATIONS_CLOSE
} from '@config/idConstants';

const Component = props => {
    const childRef = useRef();

    const onClose = () => {
        props.fetchHouseholdCount();
        window.history.back();
    };

    const onSave = () => {
        childRef.current.saveCombinations();
        window.history.back();
        props.setCampaignRouterId(null);
        props.setPlanStatus(PLAN_STATUSES.UNSAVED);
    };

    const { households } = props;
    const { count } = households;
    const { min } = hhCountLimit;
    const isDisabled = count <= min;

    return (
        <div className={styles.pageWrapper}>
            <div className={styles.cPage}>
                <span
                    className={styles.close}
                    onClick={onClose}
                    id={AUDIENCE_BUILDER_COMBINATIONS_CLOSE}>
                    <Text
                        type="button"
                        color="vividNavy"
                        className={styles.closeTitle}>
                        Close
                    </Text>
                    <Icon src={closeIcon} size="1" />
                </span>

                <CombinationsVenn ref={childRef} />
                <Divider color="paleCyan" borderWidth="2px" margin="16px 0" />
                <Layout
                    className={styles.saveWrapper}
                    flexDirection="row"
                    hAlign="flex-end">
                    <Button
                        id={AUDIENCE_BUILDER_COMBINATIONS_SAVE}
                        disabled={isDisabled}
                        onClick={onSave}>
                        Save
                    </Button>
                </Layout>
            </div>
        </div>
    );
};

Component.propTypes = {
    fetchHouseholdCount: PropTypes.func.isRequired,
    setCampaignRouterId: PropTypes.func.isRequired,
    setPlanStatus: PropTypes.func.isRequired,
    audiences: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    households: state.audience.households,
    audiences: state.audience.audiences
});

export default connect(mapStateToProps, {
    fetchHouseholdCount,
    setCampaignRouterId,
    setPlanStatus
})(Component);
