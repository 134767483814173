import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Layout, Text, Icon, Input } from 'maslow';
import calendarSvg from '@public/img/calendar.svg';
import arrowSvg from '@public/img/arrow-left-dates.svg';

import { datesHandler } from '@lib';
import { calendarHelper, planDurationErrorType } from '@services';
import { PLAN_STATUSES } from '@config/config.planStatus';

import {
    setStartDate,
    setEndDate,
    setDuration,
    removeAllHiatusWeeks
} from '@state/schedule/actions/';
import { WEEK_HEADER_INPUT, WEEK_HEADER_CLEAR } from '@config/idConstants';

import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions/';

import styles from './styles.scss';
import { scheduleSelector, effectiveDurationSelector } from '@selectors/';

export const ScheduleHeader = ({
    weeks = 0,
    setWeeks,
    setFocusedType,
    errors,
    rules,
    role
}) => {
    const dispatch = useDispatch();

    const { startDate, endDate } = useSelector(scheduleSelector);
    const effectiveDuration = useSelector(effectiveDurationSelector);
    const [errorType, setErrorType] = useState(null);

    const numberOfWeeks = weeks || '';
    const error = errorType ? errors[errorType][role] : '';

    useEffect(() => {
        if (startDate && endDate) {
            updateErrorType(effectiveDuration);
        }
    }, [effectiveDuration, endDate]);

    const updateErrorType = duration => {
        const errorType = duration
            ? planDurationErrorType(
                  duration,
                  rules[role].minPlanDuration,
                  rules.maxPlanDuration,
                  role
              )
            : null;
        setErrorType(errorType);
    };

    const getDaysFromWeek = value => {
        const formattedWeeks = parseInt(value);
        const totalDays = calendarHelper.getDaysFromWeeks(formattedWeeks);
        return totalDays < 0 ? 0 : totalDays;
    };

    const setWeeksValue = value => {
        if (value === '') return clearEndDateAndWeeks();

        if (value > rules.maxWeeks) value = rules.maxWeeks;

        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        onWeeksChange(value);
    };

    const onWeeksChange = value => {
        const totalDaysInWeek = getDaysFromWeek(value);
        const fromDayNumber = datesHandler.getWeekDayNumber(startDate);
        const totalDaysToAdd = totalDaysInWeek - Math.abs(fromDayNumber);

        const formattedValue = parseInt(value);
        const to = formattedValue
            ? datesHandler.addToDate(startDate, totalDaysToAdd)
            : null;
        dispatch(setEndDate(to));
        setWeeks(formattedValue);
        dispatch(removeAllHiatusWeeks());
    };

    const clearEndDateAndWeeks = () => {
        updateErrorType(0);
        dispatch(setEndDate(null));
        setWeeks(null);
        dispatch(removeAllHiatusWeeks());
    };

    const getStartStyle = () => {
        const updatedStyles = endDate
            ? styles.selectedDateStyle
            : !startDate
            ? styles.activeDateStyle
            : '';
        return [styles.defaultDateStyle, updatedStyles].join(' ');
    };

    const getEndStyle = () => {
        const updatedStyles = endDate
            ? styles.selectedDateStyle
            : startDate
            ? styles.activeDateStyle
            : '';
        return [styles.defaultDateStyle, updatedStyles].join(' ');
    };

    const onClearClick = () => {
        dispatch(setStartDate(null));
        dispatch(setEndDate(null));
        dispatch(setDuration(0));
        dispatch(removeAllHiatusWeeks());
        setFocusedType('from');
        setWeeks(0);
        updateErrorType(0);
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    const fromDate = startDate
        ? datesHandler.format(startDate, 'MM/dd/yyyy')
        : 'Start Date';
    const toDate = endDate
        ? datesHandler.format(endDate, 'MM/dd/yyyy')
        : 'End Date';

    const startClassName = getStartStyle();
    const endClassName = getEndStyle();
    const isDisableClear = !endDate && !startDate;
    const clearStyle = isDisableClear
        ? styles.clearTextDisabled
        : styles.clearText;
    const topWrapperStyle = error
        ? [styles.topDateWrapper, styles.errorTopWrapper].join(' ')
        : styles.topDateWrapper;

    return (
        <Layout flexDirection="column">
            <Layout
                flexDirection="row"
                className={topWrapperStyle}
                vAlign="center"
                hAlign="space-between">
                <Icon src={calendarSvg} size="2" />
                <div className={startClassName}>
                    <Text type="input">{fromDate}</Text>
                </div>

                <Icon src={arrowSvg} size="2" />
                <div className={endClassName}>
                    <Text type="input">{toDate}</Text>
                </div>
                <div className={styles.verticalLine}></div>

                <Layout flexDirection="row" vAlign="center">
                    <Text color="gray">Weeks</Text>
                    <Input
                        name="weeks"
                        theme="smallLine"
                        placeholder="0"
                        id={WEEK_HEADER_INPUT}
                        onChange={setWeeksValue}
                        value={numberOfWeeks}
                        className={styles.weekInput}
                        onFocus={() => setFocusedType('to')}
                        pattern="[0-9]{1}"
                        disabled={!startDate}></Input>
                </Layout>

                <Text
                    id={WEEK_HEADER_CLEAR}
                    type="input"
                    onClick={isDisableClear ? null : onClearClick}
                    className={clearStyle}>
                    Clear
                </Text>
            </Layout>

            <Text color="red" type="caption" className={styles.errorText}>
                {error}
            </Text>
        </Layout>
    );
};

ScheduleHeader.propTypes = {
    weeks: PropTypes.number,
    setFocusedType: PropTypes.func.isRequired,
    setWeeks: PropTypes.func.isRequired,
    errors: PropTypes.object.isRequired,
    rules: PropTypes.object.isRequired
};
