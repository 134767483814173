import { createReducer } from '@reduxjs/toolkit';
import {
    ADD_AUDIENCE,
    REPLACE_AUDIENCE,
    REPLACE_AUDIENCES,
    REMOVE_AUDIENCE,
    REMOVE_ALL_AUDIENCES,
    ADD_OPTION_TO_AUDIENCE,
    REMOVE_OPTION_FROM_AUDIENCE,
    REMOVE_ALL_OPTIONS_FROM_AUDIENCE,
    REMOVE_CATEGORY_FROM_AUDIENCE
} from '../constants';

const audiencesLimit = 3;

const initialState = [[]];

const addAudience = (state, _) => {
    const audiencesLength = state.length;
    return audiencesLength < audiencesLimit ? [...state, []] : state;
};

const removeAudience = (state, action) => {
    const { audienceIndex } = action.payload;
    return state.filter((_, i) => i !== audienceIndex);
};

const removeAllAudiences = () => {
    return initialState;
};

const addOptionToAudience = (state, action) => {
    const { audienceIndex, option } = action.payload;
    return state.map((audience, i) => {
        return i === audienceIndex ? [...audience, option] : audience;
    });
};

const removeAllOptionsFromAudience = (state, action) => {
    const { audienceIndex } = action.payload;

    return state.map((audience, i) => {
        return i === audienceIndex ? [] : audience;
    });
};

const removeOptionFromAudience = (state, action) => {
    const { audienceIndex, optionId } = action.payload;

    return state.map((audience, i) => {
        return i === audienceIndex
            ? audience.filter(option => option.id !== optionId)
            : audience;
    });
};
const removeCategoryFromAudience = (state, action) => {
    const { audienceIndex, categoryName } = action.payload;

    return state.map((audience, i) => {
        return i === audienceIndex
            ? audience.filter(option => option.category !== categoryName)
            : audience;
    });
};

const replaceAudience = (state, action) => {
    const { audienceIndex, newAudience } = action.payload;
    return state.map((audience, i) => {
        return i === audienceIndex ? newAudience : audience;
    });
};

const replaceAudiences = (_, action) => {
    return [[action.payload]];
};

export const audiences = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_AUDIENCE, addAudience)
        .addCase(REPLACE_AUDIENCE, replaceAudience)
        .addCase(REPLACE_AUDIENCES, replaceAudiences)
        .addCase(REMOVE_AUDIENCE, removeAudience)
        .addCase(REMOVE_ALL_AUDIENCES, removeAllAudiences)
        .addCase(ADD_OPTION_TO_AUDIENCE, addOptionToAudience)
        .addCase(REMOVE_ALL_OPTIONS_FROM_AUDIENCE, removeAllOptionsFromAudience)
        .addCase(REMOVE_OPTION_FROM_AUDIENCE, removeOptionFromAudience)
        .addCase(REMOVE_CATEGORY_FROM_AUDIENCE, removeCategoryFromAudience)
        .addDefaultCase((state, _) => state);
});
