import Cookies from 'js-cookie';
import request from '../request';

const removeCookies = () => {
    Cookies.remove('athena_auth');
    Cookies.remove('jwt');
};

export default function () {
    return request({
        method: 'GET',
        url: 'auth/logout/'
    })
        .then(response => {
            removeCookies();
            return response;
        })
        .catch(error => {
            removeCookies();
            console.error('ERROR:', error);
            return error;
        });
}
