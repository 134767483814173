export default {
    name: 'Admin/Dashboard', // Component name
    description: 'Admin Dashboard component',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: [
        'admin'
    ],
    sections: [
        'dashboard'
    ],
    flavours: [
        'cgd',
        'cablevision',
        'beta'
    ],
    userRoles: [
        'admin'
    ],
    data: {},
    attributes: {},
    modifications: {}
};
