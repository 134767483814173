import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Input } from 'maslow';
import searchIcon from '@public/img/Search.svg';
import style from '../styles.scss';
import { PLANS_SEARCH } from '@config/idConstants';

const USER_INPUT_DELAY_BEFORE_SEARCH_MS = 250;

export const Search = ({ setSearchValue, setIsSearching }) => {
    const [inputValue, setInputValue] = useState('');

    const searchOnChange = () => {
        if (inputValue.length >= 2) {
            setSearchValue(inputValue);
            setIsSearching(true);
        } else {
            setIsSearching(false);
        }
    };

    return (
        <Layout className={style.searchWrapper}>
            <Input
                name="search"
                theme="base"
                placeholder="Search by Plan Name, Advertiser, or ID"
                icon={searchIcon}
                doneTypingInterval={USER_INPUT_DELAY_BEFORE_SEARCH_MS}
                doneTyping={searchOnChange}
                id={PLANS_SEARCH}
                inputValue={inputValue}
                onChange={setInputValue}
                className={style.searchInput}
                maxLength={90}
            />
        </Layout>
    );
};

Search.propTypes = {
    setSearchValue: PropTypes.func.isRequired,
    setIsSearching: PropTypes.func.isRequired
};
