export const actionMenuHelper = {
    show(id) {
        const currentDropDownEl = document.querySelector('.card_' + id);
        const currentMenuEl = document.querySelector('.menu_' + id);
        currentDropDownEl.style.display = 'block';
        currentMenuEl.classList.add('activeMenuIconWrapper');
    },
    hide(id) {
        const currentDropDownEl = document.querySelector('.card_' + id);
        const currentMenuEl = document.querySelector('.menu_' + id);
        currentDropDownEl.style.display = 'none';
        currentMenuEl.classList.remove('activeMenuIconWrapper');
    },
    hideOpenedDropDown() {
        [...document.querySelectorAll('.dropDownCard')].forEach(el => {
            el.style.display = 'none';
        });
        [...document.querySelectorAll('.menuIconWrapper')].forEach(el => {
            el.classList.remove('activeMenuIconWrapper');
        });
    }
};
