import React from 'react';
import { UISref } from '@uirouter/react';
import styles from './styles.scss';
import errorSvg from '@public/img/empty-state-small.svg';

export const ErrorPage = ({ error, resetErrorBoundary }) => {
    console.error('An application error occured', error);
    return (
        <div className={styles.errorContainer}>
            <div className={styles.errorWrapper}>
                <div className={styles.errorContent}>
                    <p
                        className={
                            styles.title
                        }>{`Oops, something's gone wrong!`}</p>
                    <p
                        className={
                            styles.text
                        }>{`Not to worry, why don't you try one of these helpful links: `}</p>
                    <div className={styles.buttonWrapper}>
                        <button
                            className={styles.actionBtn}
                            onClick={() => {
                                resetErrorBoundary();
                                window.history.back();
                            }}>
                            {'Go Back'}
                        </button>
                        <UISref to={'app.main.plans'}>
                            <button
                                className={styles.actionBtn}
                                onClick={resetErrorBoundary}>
                                {'Plans Page'}
                            </button>
                        </UISref>
                    </div>
                </div>
                <div className={styles.imageWrapper}>
                    <img src={errorSvg} className={styles.errorImage} />
                </div>
            </div>
        </div>
    );
};
