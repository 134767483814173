import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { sliceArray } from '@lib';
import { Text, Layout, Icon } from 'maslow';
import SelectedAudienceCategory from '../SelectedAudienceCategory';
import styles from '../audiences.scss';

import { MEDIA_PLAN_TOGGLE_AUDIENCE_CATEGORIES } from '@config/idConstants';

const CATEGORY_LIST_SIZE = 5;

const SelectedAudience = ({ audience, audienceStyles }) => {
    const [displayAllCategories, setDisplayAllCategories] = useState(false);
    const [expandButton, setExpandButton] = useState(false);

    const categoryNames = Object.keys(audience);
    const categoryList = displayAllCategories
        ? categoryNames
        : sliceArray(categoryNames, 0, CATEGORY_LIST_SIZE);

    const toggleButtonClickHandler = e => {
        displayAllCategories
            ? setDisplayAllCategories(false)
            : setDisplayAllCategories(true);
    };

    const renderCategory = () => {
        return categoryList.map((categoryName, i) => {
            const enabledGradientOverlay =
                i == CATEGORY_LIST_SIZE - 1 &&
                categoryNames.length > CATEGORY_LIST_SIZE &&
                !displayAllCategories;
            const categoryStyle = enabledGradientOverlay
                ? styles.gradientOverlay
                : '';
            return (
                <div key={i} className={categoryStyle}>
                    <SelectedAudienceCategory
                        categoryName={categoryName}
                        category={audience[categoryName]}
                        audienceStyles={audienceStyles}
                    />
                </div>
            );
        });
    };
    return (
        <React.Fragment>
            <Layout flexDirection="column">{renderCategory()}</Layout>
            {!displayAllCategories &&
                categoryNames.length > CATEGORY_LIST_SIZE && (
                    <Layout
                        id={MEDIA_PLAN_TOGGLE_AUDIENCE_CATEGORIES}
                        flexDirection="row"
                        hAlign="center"
                        className={styles.expandWrapper}
                        onClick={() => toggleButtonClickHandler()}>
                        <Icon
                            src={audienceStyles.icon}
                            style={audienceStyles.circle}
                            size="2"></Icon>
                        <Text
                            color={audienceStyles.color}
                            className={styles.expandText}>
                            Expand
                        </Text>
                    </Layout>
                )}
        </React.Fragment>
    );
};

SelectedAudience.propTypes = {
    audience: PropTypes.object.isRequired,
    audienceStyles: PropTypes.object.isRequired
};

export default SelectedAudience;
