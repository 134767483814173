import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import { VIEWERSHIP } from '@config';
import { capitalizeFirstLetters } from '@lib';
import { ScrollContainer } from 'scrollmonitor-react';
import { Content } from 'maslow';

import AudienceCategory from './AudienceCategory';
import AudienceSection from './AudienceSection';

import styles from './index.scss';
import shared from '@sharedStyle/page.scss';

const OPTION_COLUMN_MIN_WIDTH = 300;

const AudienceScrollContainer = ({
    audienceConfig,
    scrollContainer,
    onSectionViewPortChange,
    scrollUnderHeader,
    scrollOutOfHeader
}) => {
    const [{ optionsColumns, sizeRatio }, setState] = useState({
        sizeRatio: 33.33,
        optionsColumns: 3
    });

    const resize = optionsColumns => {
        const currentOptionsColumns = Math.round(
            window.innerWidth / OPTION_COLUMN_MIN_WIDTH
        );
        if (currentOptionsColumns !== optionsColumns) {
            setState({
                optionsColumns: currentOptionsColumns,
                sizeRatio: 100 / currentOptionsColumns
            });
        }
    };

    const sectionViewportChange = (
        name,
        { isInViewport, isFullyInViewport, container: { viewportTop } },
        viewPortState
    ) => {
        onSectionViewPortChange({
            name,
            isInViewport,
            isFullyInViewport,
            viewPortState
        });

        viewportTop > 80 ? scrollUnderHeader() : scrollOutOfHeader();
    };

    const buildCategories = item => {
        return item.options.map((category, key) => (
            <AudienceCategory
                key={key}
                categoryData={category}
                sectionName={item.name}
                sizeRatio={sizeRatio}
                optionsColumns={optionsColumns}
                scrollContainer={scrollContainer}></AudienceCategory>
        ));
    };

    const buildSections = (item, sectionKey) => {
        if (item.name === capitalizeFirstLetters(VIEWERSHIP)) return false;

        return (
            <AudienceSection
                key={sectionKey}
                sectionData={item}
                scrollContainer={scrollContainer}
                enterViewport={data =>
                    sectionViewportChange(item.name, data, 'enterViewport')
                }
                fullyEnterViewport={data =>
                    sectionViewportChange(item.name, data, 'fullyEnterViewport')
                }
                partiallyExitViewport={data =>
                    sectionViewportChange(
                        item.name,
                        data,
                        'partiallyExitViewport'
                    )
                }
                exitViewport={data =>
                    sectionViewportChange(item.name, data, 'exitViewport')
                }>
                {buildCategories(item)}
            </AudienceSection>
        );
    };

    useEffect(() => {
        const updateColumns = () => resize(optionsColumns);
        window.addEventListener('resize', updateColumns);
        return () => {
            window.removeEventListener('resize', updateColumns);
            if (scrollContainer) scrollContainer.destroy();
        };
    }, [optionsColumns, scrollContainer]);

    useEffect(() => resize(optionsColumns), []);

    return (
        <Content
            className={[shared.smallPageWrapper, styles.scrollContainer]}
            id="audience-scroll-container">
            {audienceConfig.map(buildSections)}
        </Content>
    );
};

AudienceScrollContainer.propTypes = {
    onCategoryViewPortChange: PropTypes.func,
    onSectionViewPortChange: PropTypes.func,
    audienceConfig: PropTypes.array.isRequired,
    scrollUnderHeader: PropTypes.func.isRequired,
    scrollOutOfHeader: PropTypes.func.isRequired
};

export default ScrollContainer(AudienceScrollContainer);
