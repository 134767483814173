import { validateArg, isEmpty } from '@lib';
import { getReduxBudget } from './budgetFormatters';
import { formatAudienceDataForRedux } from './audienceFormatters';
import { getMediaPlanReduxProperties } from './mediaPlanFormatters';
import { getScheduleReduxProperties } from './scheduleFormatters';
import { getNetworksReduxProperties } from './networksFormatters';
import { extractCreatives, extractUrls, extractDisclaimers } from '../creative';
import { getBudgetConfig } from '../budget';
import { defaultCustomListShape } from '../customList';

import { budgetConfig } from '@config/config.budget';
import {
    ACTUAL_USER,
    TESTER,
    POLITICAL,
    UNSET,
    NATIONAL,
    LOCAL,
    COMMERCIAL
} from '@config';

export const getCampaignType = planType => {
    validateArg(planType, 'string');
    const role = planType ? planType.toLowerCase() : COMMERCIAL;
    return role === UNSET ? POLITICAL : role;
};

export const formatData = async (data, funcs) => {
    validateArg(data, 'object', 1);
    validateArg(funcs, 'object', 2);

    const {
        mediaPlanId: planId,
        bookedDate,
        modifiedDate,
        isEditCreatives,
        creativeProducts,
        urls,
        budget,
        tvDistribution,
        primaryTargetDemography,
        userId,
        selectedLocations,
        mediaPlanMapImageDataUrls,
        user,
        households,
        planType,
        audience,
        name,
        advertiserName: advertiser,
        type,
        campaignRouterId,
        schedule,
        excludedNetworks
    } = data;

    const {
        getDigitalCpmConfig,
        getImpressionModifierConfig,
        getCounts,
        getNetworks
    } = funcs;

    const booked = !!bookedDate;
    const campaignType = getCampaignType(planType || '');
    const region = isEmpty(selectedLocations) ? NATIONAL : LOCAL;

    //SCHEDULE
    const newSchedule =
        schedule.startDate && schedule.endDate
            ? getScheduleReduxProperties(schedule)
            : schedule;

    //BUDGET
    const normalizedDigitalCpmConfig = booked
        ? {}
        : await getDigitalCpmConfig(type, campaignType, region);
    const normalizedImpressionModifierConfig = await getImpressionModifierConfig(
        campaignType,
        region,
        newSchedule.duration
    );
    const newBudget = getReduxBudget(
        budget,
        normalizedDigitalCpmConfig,
        normalizedImpressionModifierConfig
    );

    //AUDIENCE
    const newAudience = await formatAudienceDataForRedux(
        audience || {},
        selectedLocations,
        households,
        getCounts,
        booked
    );

    //PLAN
    const plan = {
        attributes: {
            advertiser,
            booked,
            campaignType,
            hasAcceptedTerms: booked,
            modifiedDate: modifiedDate.replace(/-/g, '/'),
            name,
            paymentError: [],
            planId,
            region
        }
    };

    //CREATIVE
    const files = extractCreatives(creativeProducts, planId, budget);
    const creative = {
        files,
        disclaimer: extractDisclaimers(files),
        ...extractUrls(urls),
        skipUpload: isEmpty(files)
    };

    //NETWORKS
    const networks = await getNetworksReduxProperties(
        excludedNetworks,
        getNetworks,
        booked
    );

    //MEDIAPLAN
    const formattedBudgetConfig = getBudgetConfig({
        role: campaignType,
        budget: newBudget,
        region,
        count: households.count,
        duration: newSchedule.duration,
        userType: budgetConfig.noThresholdEmail.includes(userId)
            ? TESTER
            : ACTUAL_USER,
        linearTvCpm: primaryTargetDemography.cpm
    });
    const mediaPlan = getMediaPlanReduxProperties({
        planId,
        tvDistribution,
        primaryTargetDemography,
        budget: formattedBudgetConfig,
        campaignRouterId,
        booked,
        submittedDigitalDetails: budget
    });

    //LOCATION
    const location = {
        selectedLocations,
        mapImageDataUrls: mediaPlanMapImageDataUrls
    };

    //UI
    const ui = {
        loadingScreen: {
            showLoadingScreen: true,
            loadingText: 'Retrieving your plan details'
        },
        isEditCreatives,
        isMediaPlanVisited: true
    };

    //CUSTOMLIST
    const customList = defaultCustomListShape;

    return {
        audience: newAudience,
        budget: newBudget,
        creative,
        customList,
        location,
        mediaPlan,
        networks,
        plan,
        schedule: newSchedule,
        ui,
        user
    };
};
