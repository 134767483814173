import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import { Text, Layout } from 'maslow';
import style from '@sharedStyle/audienceSelector.scss';
import shared from '@sharedStyle/page.scss';

class Audience extends React.Component {
    constructor(props) {
        super(props);
    }

    getCards() {
        const { components } = getFeatures('audience_selector', this, {
            isColumnView: true
        });

        return (
            <Layout
                flexDirection="row"
                hAlign="center"
                className={style.cardsWrapper}>
                {components}
            </Layout>
        );
    }

    render() {
        const cards = this.getCards();

        return (
            <div className={shared.pageWrapper} style={this.props.fadeIn}>
                <Layout
                    flexDirection="column"
                    vAlign="space-around"
                    padding="16px"
                    className={style.cardPageWrapper}>
                    <Layout
                        flexDirection="column"
                        hAlign="flex-start"
                        padding="16px 0 56px 0">
                        <div className={style.selectorText}>
                            <Text type="h3">Define Your Audience</Text>
                        </div>
                    </Layout>
                    {cards}
                </Layout>
            </div>
        );
    }
}

export default withFeatures(Audience);
