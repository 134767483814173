import request from '../request';

export default function(data) {
    const email = data.toLowerCase();
    const req = {
        method: 'POST',
        url: 'auth/password_reset_token/',
        data: { email }
    };

    return request(req);
}