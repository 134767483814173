import React from 'react';

import { Layout } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';
import shared from '@sharedStyle/page.scss';

class MediaPlan extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const styles = { ...this.props.fadeIn, width: '100%' };
        const { components } = getFeatures('mediaPlan', this);

        return (
            <Layout
                flexDirection="column"
                className={shared.pageWrapper}
                style={styles}>
                <div>{components}</div>
            </Layout>
        );
    }
}

export default withFeatures(MediaPlan);
