import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { setCurrentAudienceIndex } from '@state/audience/actions/audiences';
import { Text, Layout, Icon } from 'maslow';
import { SelectedAudienceCategory } from '../SelectedAudienceCategory';
import { reMount } from '@components/hoc';
import styles from '../styles.scss';

import { idBuilder } from '@services';
import { AUDIENCE_SUMMARY_SELECTED_AUDIENCE_HEADER } from '@config/idConstants';

const Component = props => {
    const {
        interactive,
        currentAudienceIndex,
        audience,
        selectedAudienceIndex,
        selectedAudienceStyles,
        minusIcon,
        closeIcon
    } = props;

    const renderCategory = () => {
        const categoryNames = Object.keys(audience);

        return categoryNames.map((categoryName, i) => {
            return (
                <SelectedAudienceCategory
                    key={i}
                    interactive={interactive}
                    currentAudienceIndex={currentAudienceIndex}
                    categoryName={categoryName}
                    category={audience[categoryName]}
                    selectedAudienceIndex={selectedAudienceIndex}
                    minusIcon={minusIcon}
                    closeIcon={closeIcon}
                />
            );
        });
    };

    const hasSelection = () => Object.keys(audience).length;
    const isCurrentAudience = () =>
        currentAudienceIndex === selectedAudienceIndex;
    const showCategory = () => !interactive || isCurrentAudience();
    const showEmptyAudienceText = () => isCurrentAudience() && !hasSelection();

    const onClickHandler = () => {
        if (interactive) props.setCurrentAudienceIndex(selectedAudienceIndex);
    };

    const getArrowIcon = src => {
        const IconComponent = reMount(Icon);

        return (
            <div className={styles.selectedAudienceArrowIconWrapper}>
                <IconComponent src={src} className={styles.icon} />
            </div>
        );
    };

    const renderArrowIcon = () => {
        return interactive
            ? showCategory()
                ? getArrowIcon(props.arrowIcons.up)
                : getArrowIcon(props.arrowIcons.down)
            : null;
    };

    return (
        <div
            className={styles.selectedAudienceWrapper}
            style={selectedAudienceStyles.card}>
            <Layout
                id={idBuilder(
                    AUDIENCE_SUMMARY_SELECTED_AUDIENCE_HEADER,
                    selectedAudienceIndex + 1
                )}
                flexDirection="row"
                hAlign="space-between"
                vAlign="center"
                className={styles.selectedAudienceHeaderWrapper}
                onClick={onClickHandler}>
                <Text
                    type="h5"
                    className={styles.selectedAudienceTitle}
                    style={selectedAudienceStyles.title}>
                    Audience {selectedAudienceIndex + 1}
                </Text>

                {renderArrowIcon()}
            </Layout>

            {showCategory() ? (
                <Layout flexDirection="column">{renderCategory()}</Layout>
            ) : null}

            {showEmptyAudienceText() ? (
                <Layout
                    flexDirection="column"
                    className={styles.emptyTextMargin}>
                    <Text type="body" className={styles.bodyFontSize}>
                        Choose your audience by selecting targeting options.
                    </Text>
                </Layout>
            ) : null}
        </div>
    );
};

Component.propTypes = {
    interactive: PropTypes.bool.isRequired,
    audience: PropTypes.object.isRequired,
    currentAudienceIndex: PropTypes.number.isRequired,
    selectedAudienceIndex: PropTypes.number.isRequired,
    minusIcon: PropTypes.node.isRequired,
    closeIcon: PropTypes.node.isRequired,
    arrowIcons: PropTypes.object.isRequired,
    setCurrentAudienceIndex: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    setCurrentAudienceIndex: audienceIndex =>
        dispatch(setCurrentAudienceIndex(audienceIndex))
});

export const SelectedAudience = connect(null, mapDispatchToProps)(Component);
