import { createReducer } from '@reduxjs/toolkit';
import { SET_PLAN_STATUS } from '../constants';

const initialState = 'unsaved';

const setPlanStatus = (state, action) => {
    const { planStatus } = action.payload;
    return typeof planStatus === 'string' ? planStatus : state;
};

export const planStatus = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_PLAN_STATUS, setPlanStatus)
        .addDefaultCase((state, _) => state);
});
