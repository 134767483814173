export default {
    name: 'Budget', // Component name
    description: 'Budget Feature Component',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan'],
    sections: ['budget'],
    permissions: [],
    data: {},
    attributes: {},
    modifications: {}
};
