import {
    strf,
    getUnformattedBudget,
    isMinSatisfied,
    validateArg,
    addDecimals
} from '@lib';
import { budgetConfig } from '@config/config.budget';
import { LINEAR_TV } from '@config';

export const getChannelKey = (subKey, subCampaigns) => {
    return Object.values(subCampaigns).find(({ key }) => key === subKey).medium;
};

export const getChannelKeyFromName = name => {
    return budgetConfig.distribution.channels.find(
        channel => channel.name === name
    ).key;
};

export const getChannelName = key => {
    return budgetConfig.distribution.channels.find(
        channel => key === channel.key
    ).name;
};

export const getSubKey = name => {
    return budgetConfig.distribution.subCampaigns[name].key;
};

export const getSubName = key => {
    if (typeof key !== 'string') throw Error('arg is not a string');
    const sub = Object.values(budgetConfig.distribution.subCampaigns).find(
        sub => sub.key === key
    );
    if (!sub) throw Error('subCampaign does not exist');
    return sub.name;
};

export const getChannelBudget = (key, budget) => {
    const channelKey = getChannelKey(
        key,
        budgetConfig.distribution.subCampaigns
    );
    return budget.channels[channelKey];
};

export const getSubCampaign = key => {
    return Object.values(budgetConfig.distribution.subCampaigns).find(
        sub => sub.key === key
    );
};

export const getContextFromName = name => {
    if (typeof name !== 'string') throw new Error('arg is not a string');
    const sub = Object.values(budgetConfig.distribution.subCampaigns).find(
        sub => sub.name === name
    );

    if (!sub) throw new Error('subCampaign does not exist');

    return sub.context;
};

export const getContextFromKey = key => {
    if (typeof key !== 'string') throw new Error('arg is not a string');
    const sub = Object.values(budgetConfig.distribution.subCampaigns).find(
        sub => sub.key === key
    );

    if (!sub) throw new Error('subCampaign does not exist');

    return sub.context;
};

export const getChannelDefaults = distributions => {
    const defaultPercentages = {};
    distributions.forEach(d => {
        defaultPercentages[d.channel] = d.percentage / 100;
    });
    return defaultPercentages;
};

export const getMergedChannelKeys = (lockedChannels, disabledChannelsKeys) => {
    return [...new Set([...lockedChannels, ...disabledChannelsKeys])];
};

export const isAllChannelsLocked = (lockedChannelKeys, channels) => {
    return lockedChannelKeys
        ? lockedChannelKeys.length === Object.keys(channels).length
        : false;
};

export const getMaxErrorText = value =>
    `Maximum is ${strf(value).commas().prepend('$').value()}.`;

export const getErrorText = ({
    subCampaigns,
    key,
    min,
    region,
    minErrorText,
    maxBudget,
    userType
}) => {
    return subCampaigns[key] < min[userType][region]
        ? minErrorText[region]
        : subCampaigns[key] > maxBudget
        ? getMaxErrorText(maxBudget)
        : '';
};

export const setRegionPrimaryCpmNet = (primaryCpmNet, region, role) => {
    return typeof primaryCpmNet !== 'number'
        ? primaryCpmNet[role][region]
        : primaryCpmNet;
};

export const getMaxImpressions = (
    hhCountValue,
    duration,
    impressionModifier
) => {
    return hhCountValue * duration * impressionModifier;
};

export const getImpressionsWithCPM = (primary_cpm_net, impressions) => {
    return (impressions * primary_cpm_net) / 1000;
};

export const getStatus = (
    value,
    min,
    isSubCampaignInput,
    channelBudget,
    maxBudget
) => {
    const clean = getUnformattedBudget(value);
    const minIsSatisfied = isSubCampaignInput
        ? isMinSatisfied(clean, min, 0) || channelBudget === 0
        : isMinSatisfied(clean, min, 0);

    const isUnderMax = maxBudget ? clean <= maxBudget : true;

    const isValid = minIsSatisfied && isUnderMax;
    return isValid ? 'idle' : 'error';
};

export const getCpm = (name, linearTvCpm, configCpm) => {
    validateArg(name, 'string', 1);
    validateArg(linearTvCpm, 'number', 2);
    validateArg(configCpm, 'number', 3);

    if (name === LINEAR_TV) {
        return linearTvCpm ? linearTvCpm : configCpm;
    }
    return configCpm;
};

export const isProductExist = (productArr, name) => {
    validateArg(productArr, 'array', 1);
    validateArg(name, 'string', 2);

    return productArr.some(product => product.name === name);
};

export const getFormattedCpm = cpm => {
    validateArg(cpm, 'number');
    return strf(addDecimals(cpm, 4)).prepend('$').value();
};
