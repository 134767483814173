import { strf, round } from '@lib';
import { datesHandler } from '@lib';
import { MARGIN_10, CELL_WIDTH_01, COLOR_BLUE } from '../constants';

export const buildDayPartsData = (
    doc,
    counter,
    dayParts,
    tvImpressionsValue
) => {
    const table = {
        startY: counter.add(MARGIN_10),
        headStyles: { fillColor: COLOR_BLUE, cellWidth: CELL_WIDTH_01 },
        head: [['Day Part', 'Impressions', 'Percentage']],
        body: [],
        didDrawPage: data => {
            counter.set(counter.count + data.table.height);
        }
    };

    dayParts.forEach(dayPart => {
        const begin = datesHandler.formatString(dayPart.BeginHour, ['H'], 'ha');
        const end = datesHandler.formatString(dayPart.EndHour, ['H'], 'ha');
        const daypartImpressions = round(
            (tvImpressionsValue * dayPart.DeliveryEstimate) / 100
        );
        const percentage = round(dayPart.DeliveryEstimate);

        table.body.push([
            `${begin} – ${end}`,
            strf(daypartImpressions)
                .commas()
                .value(),
            `${percentage}%`
        ]);
    });

    doc.autoTable(table);
};
