import { createReducer } from '@reduxjs/toolkit';
import {
    ADD_HIATUS_WEEK,
    REMOVE_HIATUS_WEEK,
    REMOVE_ALL_HIATUS_WEEKS,
    SHIFT_HIATUS_WEEKS
} from '../constants';

const initialState = [];

const addHiatusWeek = (state, action) => {
    const { hiatusWeek } = action.payload;
    return [...state, hiatusWeek];
};

const shiftHiatusWeeks = (state, action) => {
    const { hiatusWeeks } = action.payload;
    return hiatusWeeks;
};

const removeHiatusWeek = (state, action) => {
    const { weekNumber } = action.payload;
    return state.filter(val => val.weekNumber !== weekNumber);
};
const removeAllHiatusWeeks = () => {
    return initialState;
};

export const hiatusWeeks = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_HIATUS_WEEK, addHiatusWeek)
        .addCase(SHIFT_HIATUS_WEEKS, shiftHiatusWeeks)
        .addCase(REMOVE_HIATUS_WEEK, removeHiatusWeek)
        .addCase(REMOVE_ALL_HIATUS_WEEKS, removeAllHiatusWeeks)
        .addDefaultCase((state, _) => state);
});
