import React, { useState } from 'react';
import { UISref } from '@uirouter/react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import requestHandlers from '@requestHandlers';
import featuresManager from '@featuresManager';

import { Layout, Text, Icon, Card, Button } from 'maslow';

import { getRouteForUserType } from '@services';

import bookSuccessSvg from '@public/img/book-success.svg';

import { setPlanStatus, setA4PlanId } from '@state/mediaPlan/actions';
import { updateAttributes } from '@state/plan/actions/attributes';
import { userSelector, hasPermissionSelector } from '@selectors';

import styles from './styles.scss';

import {
    EXTERNAL,
    POLITICAL,
    MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN
} from '@config';
import { PLAN_STATUSES } from '@config/config.planStatus';
import {
    BOOK_CREATE_NEW_PLAN_BUTTON,
    BOOK_GO_TO_PLAN_LIST_BUTTON
} from '@config/idConstants';

const NextSteps = ({ a4PlanId, customFiltersStates, routes, transition }) => {
    const dispatch = useDispatch();
    const [buttonAnimation, setButtonAnimation] = useState(false);

    const {
        account: { email }
    } = useSelector(userSelector);
    const isShowCampaignPage = useSelector(
        hasPermissionSelector(MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN)
    );

    const updateIsPlanBook = () => {
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    const newPlan = () => {
        if (buttonAnimation) return false;
        setButtonAnimation(true);
        const type = customFiltersStates.type;

        if (type === EXTERNAL)
            requestHandlers.plans
                .newMediaPlan({ mediaPlanType: POLITICAL, email })
                .then(res => {
                    dispatch(setA4PlanId(res.data));
                    dispatch(updateAttributes({ planId: res.data }));
                    transition.router.stateService.go(
                        getRouteForUserType(isShowCampaignPage, routes),
                        { planId: 'new' }
                    );
                })
                .catch(err => {
                    setButtonAnimation(false);
                    console.error('error creating new plan', err);
                });
        else {
            transition.router.stateService.go(
                getRouteForUserType(isShowCampaignPage, routes),
                { planId: 'new' }
            );
        }

        updateIsPlanBook();
    };

    return (
        <Layout flexDirection="column" className={styles.wrapper}>
            <Card
                backgroundColor="white"
                borderRadius="9px"
                borderWidth="0px"
                boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                className={styles.submittedWrapper}>
                <Layout flexDirection="row" hAlign="center" vAlign="center">
                    <Icon className={styles.topIcon} src={bookSuccessSvg} />
                </Layout>

                <Layout
                    flexDirection="row"
                    flex
                    hAlign="space-around"
                    vAlign="center"
                    className={styles.bottomWrapper}>
                    <Layout flexDirection="column">
                        <Text type="h4" className={styles.boldText}>
                            Your media plan <br /> has been submitted!
                        </Text>
                    </Layout>

                    <Layout flexDirection="column">
                        <Text type="body" className={styles.thankYouText}>
                            Thank you! We have emailed your plan details.
                            <br />
                            Your Plan ID is <strong>{a4PlanId}</strong>
                        </Text>

                        <Layout flexDirection="row" vAlign="center">
                            <Layout>
                                <Button
                                    id={BOOK_CREATE_NEW_PLAN_BUTTON}
                                    onClick={newPlan}
                                    animate={buttonAnimation}>
                                    + Create New Plan
                                </Button>
                            </Layout>

                            <UISref to={`app.main.plans`}>
                                <Text
                                    id={BOOK_GO_TO_PLAN_LIST_BUTTON}
                                    type="body"
                                    color="vividNavy"
                                    className={styles.goToText}
                                    onClick={updateIsPlanBook}>
                                    Go to Plan List
                                </Text>
                            </UISref>
                        </Layout>
                    </Layout>
                </Layout>
            </Card>
        </Layout>
    );
};

NextSteps.propTypes = {
    customFiltersStates: PropTypes.object.isRequired,
    routes: PropTypes.object
};

export default NextSteps;
