import React from 'react';
import PropTypes from 'prop-types';
import { Layout, Card, Icon, Text } from 'maslow';
import { useSelector, useDispatch } from 'react-redux';
import { setOrderBy } from '@state/networks/actions/orderBy';
import upIcon from '@public/img/Arrow-Up.svg';
import downIcon from '@public/img/Arrow-Down.svg';
import styles from './styles.scss';
import { reMount } from '@components/hoc';
import { NetworksExclusions } from './NetworksExclusions';
import { networkSelector } from '@selectors';

import { NETWORKS_HEADER_ARROW } from '@config/idConstants';

let arrowUpIconStyle = styles.arrowIcon;
let arrowDownIconStyle = styles.arrowIcon;

export default function (version, feature) {
    const Networks = () => {
        const dispatch = useDispatch();
        const { orderBy, allNetworks: networksList } = useSelector(
            networkSelector
        );

        const renderIcon = src => {
            const IconComponent = reMount(Icon);
            const iconSrc = src;

            return <IconComponent src={iconSrc} size="1.2" />;
        };

        const setArrowStyle = orderBy => {
            arrowUpIconStyle =
                orderBy === 'ASC'
                    ? [styles.arrowIcon, styles.selected]
                    : styles.arrowIcon;

            arrowDownIconStyle =
                orderBy === 'DESC'
                    ? [styles.arrowIcon, styles.selected]
                    : styles.arrowIcon;
        };

        const arrowClickHandler = () => {
            if (orderBy === 'DESC') {
                dispatch(setOrderBy('ASC'));
                setArrowStyle('ASC');
            } else {
                dispatch(setOrderBy('DESC'));
                setArrowStyle('DESC');
            }
        };

        return (
            <Layout flexDirection="column">
                <Text type="h6" color="deepBlue" className={styles.header}>
                    MEDIA
                </Text>

                <Card
                    borderRadius="2px"
                    boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                    backgroundColor="white"
                    className={styles.networkHeader}>
                    <Layout
                        id={NETWORKS_HEADER_ARROW}
                        onClick={arrowClickHandler}
                        className={styles.networkPinHeader}
                        flexDirection="row"
                        vAlign="center"
                        fillParent>
                        <Layout className={styles.networkEmptyBox} />
                        <Text className={styles.title} type="h5">
                            Network
                        </Text>

                        <Layout
                            flexDirection="column"
                            className={styles.arrowIconWrapper}>
                            <Layout
                                hAlign="flex-end"
                                fillParent
                                className={arrowUpIconStyle}>
                                {renderIcon(upIcon)}
                            </Layout>
                            <Layout
                                hAlign="flex-start"
                                fillParent
                                className={arrowDownIconStyle}>
                                {renderIcon(downIcon)}
                            </Layout>
                        </Layout>
                    </Layout>
                </Card>

                <Card
                    backgroundColor="white"
                    borderRadius="2px"
                    borderWidth="0px"
                    className={styles.networkCard}>
                    <Layout flexDirection="column" fillParent>
                        <NetworksExclusions
                            networksList={networksList}
                            orderBy={orderBy}
                            exclusionsLimit={feature.data.exclusionsLimit}
                        />
                    </Layout>
                </Card>
            </Layout>
        );
    };

    return Networks;
}
