import { createAction } from '@reduxjs/toolkit';
import {
    SET_CUSTOM_LIST,
    SET_CL_IS_FETCHING,
    SET_UPLOAD_STEP,
    RESET_CUSTOM_LIST,
    SET_AGREED_TO_TERMS,
    SET_MATCH_PERCENT
} from '../constants';
import { getPercentFromValue } from '@lib';

export const setMatchPercent = () => {
    return (dispatch, getState) => {
        const {
            customList: { received_matches, num_rows, num_matches }
        } = getState();

        if (received_matches) {
            dispatch(
                setPercent({
                    match_percent: getPercentFromValue(num_matches, num_rows)
                })
            );
        }
    };
};

const setPercent = createAction(SET_MATCH_PERCENT, match_percent => {
    return {
        payload: { match_percent }
    };
});

export const setCustomList = createAction(SET_CUSTOM_LIST, customList => {
    return {
        payload: { customList }
    };
});

export const resetCustomList = createAction(RESET_CUSTOM_LIST);

export const setClIsFetching = createAction(
    SET_CL_IS_FETCHING,
    isFetchingCl => {
        return {
            payload: { isFetchingCl }
        };
    }
);

export const setUploadStep = createAction(SET_UPLOAD_STEP, uploadStep => {
    return {
        payload: { uploadStep }
    };
});

export const setAgreedToTerms = createAction(
    SET_AGREED_TO_TERMS,
    agreedToTerms => {
        return {
            payload: { agreedToTerms }
        };
    }
);
