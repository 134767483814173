import React from 'react';
import { useSelector } from 'react-redux';
import { Text } from 'maslow';
import { strf, datesHandler } from '@lib';
import { amountsSelector } from '@selectors/audience';
import {
    totalImpressionSelector,
    totalCpmSelector,
    locationSelector,
    scheduleSelector
} from '@selectors/';
import { getFormattedCpm } from '@services/budget';

import styles from './styles.scss';

const SummaryTable = () => {
    const { mapImageDataUrls } = useSelector(locationSelector);
    const { startDate, endDate } = useSelector(scheduleSelector);
    const { percentage, hhCount } = useSelector(amountsSelector);
    const totalImpressions = useSelector(totalImpressionSelector);
    const totalCpm = useSelector(totalCpmSelector);
    const formattedTotalImpressions = strf(totalImpressions).commas().value();
    const formattedTotalCpm = getFormattedCpm(totalCpm);

    return (
        <div className={styles.table}>
            <div className={`${styles.span} ${styles.mapRow}`}>
                <img
                    src={mapImageDataUrls.usaBooking}
                    className={styles.map}></img>
                <div className={styles.mapText}>
                    <Text className={styles.bold}>
                        <span className={styles.percent}>
                            {strf(percentage || '< 1')
                                .append('%')
                                .value()}
                        </span>
                        {strf(hhCount).commas().value()}
                    </Text>
                    <div>
                        <Text>Households</Text>
                    </div>
                </div>
            </div>
            <div className={styles.columnCase}>
                <div className={styles.column}>
                    <div>
                        <Text className={styles.bold}>
                            {formattedTotalImpressions}
                        </Text>
                    </div>
                    <div>
                        <Text>Impressions</Text>
                    </div>
                </div>
                <div className={styles.column}>
                    <div>
                        <Text className={styles.bold}>{formattedTotalCpm}</Text>
                    </div>
                    <div>
                        <Text>CPM</Text>
                    </div>
                </div>
            </div>
            <div className={styles.span}>
                <div>
                    <Text>This campaign will run from</Text>
                </div>
                <div>
                    <Text
                        className={styles.bold}>{`${datesHandler.formatBooking(
                        startDate
                    )} to ${datesHandler.formatBooking(endDate)}`}</Text>
                </div>
            </div>
        </div>
    );
};

export default SummaryTable;
