import React from 'react';
import PropTypes from 'prop-types';

import { Text, Layout, Tooltip } from 'maslow';
import infoSvg from '@public/img/information-icon.svg';

import { BUDGET_TOOLTIP_INFO } from '@config/idConstants';
import { idBuilder } from '@services/idBuilder';

import styles from './styles.scss';

const LockedInfoToolTipSection = ({ sliderKey }) => {
    return (
        <Layout
            flexDirection="row"
            vAlign="center"
            className={styles.infoTooltipSection}>
            <Text color="slateGray" type="body">
                Why is this product disabled?
            </Text>
            <div className={styles.infoTooltipWrapper}>
                <Tooltip
                    icon={infoSvg}
                    size={2}
                    width={233}
                    id={idBuilder(BUDGET_TOOLTIP_INFO, sliderKey)}>
                    <div className={styles.toolTipFont}>
                        Based on your campaign selection, the available
                        inventory is too low.
                    </div>
                    <div
                        className={`${styles.toolTipTextPadding} ${styles.toolTipFont}`}>
                        If you wish to include this product in your media mix,
                        you can take one or more of the following actions:
                    </div>
                    <Layout
                        flexDirection="row"
                        vAlign="center"
                        className={styles.toolTipList}>
                        <Layout
                            className={styles.listCircle}
                            hAlign="center"
                            vAlign="center">
                            <Text color="white" className={styles.toolTipFont}>
                                1
                            </Text>
                        </Layout>
                        <Text
                            color="white"
                            className={`${styles.toolTipListPadding} ${styles.toolTipFont}`}>
                            Increase your campaign duration
                        </Text>
                    </Layout>
                    <Layout
                        flexDirection="row"
                        vAlign="center"
                        className={styles.toolTipList}>
                        <Layout
                            className={styles.listCircle}
                            hAlign="center"
                            vAlign="center">
                            <Text color="white" className={styles.toolTipFont}>
                                2
                            </Text>
                        </Layout>
                        <Text
                            color="white"
                            className={`${styles.toolTipListPadding} ${styles.toolTipFont}`}>
                            Expand your target audience
                        </Text>
                    </Layout>
                    <Layout
                        flexDirection="row"
                        vAlign="center"
                        className={styles.toolTipList}>
                        <Layout
                            className={styles.listCircle}
                            hAlign="center"
                            vAlign="center">
                            <Text color="white" className={styles.toolTipFont}>
                                3
                            </Text>
                        </Layout>
                        <Text
                            color="white"
                            className={`${styles.toolTipListPadding} ${styles.toolTipFont}`}>
                            Expand your target location
                        </Text>
                    </Layout>
                </Tooltip>
            </div>
        </Layout>
    );
};

LockedInfoToolTipSection.propTypes = {
    sliderKey: PropTypes.string.isRequired
};

export default LockedInfoToolTipSection;
