import React from 'react';
import PropTypes from 'prop-types';
import { UISref } from '@uirouter/react';

import { Layout, Text, Icon } from 'maslow';
import viewReportSvg from '@public/img/view-report.svg';

import style from '../styles.scss';

import { idBuilder } from '@services';
import { PLANS_VIEW_REPORT } from '@config/idConstants';

const ViewReport = ({ route, id }) => {
    return (
        <UISref to={route} params={{ planId: id }}>
            <Layout
                id={idBuilder(PLANS_VIEW_REPORT, id)}
                flexDirection="row"
                hAlign="center"
                vAlign="center"
                className={style.viewReportWrapper}>
                <Icon src={viewReportSvg} className={style.bottomSectionIcon} />
                <Text color="vividNavy" className={style.bottomSectionLink}>
                    View Report
                </Text>
            </Layout>
        </UISref>
    );
};

ViewReport.propTypes = {
    route: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
};

export default ViewReport;
