import React from 'react';
import { Layout, Text, Divider, Bar } from 'maslow';
import styles from './daypartdist.scss';
import { datesHandler, round, strf } from '@lib';
import PropTypes from 'prop-types';

const DayPartDistribution = props => {
    const buildDayParts = (item, key) => {
        const begin = datesHandler.formatString(item.BeginHour, ['H'], 'ha');
        const end = datesHandler.formatString(item.EndHour, ['H'], 'ha');
        const itemImpressions = round(
            (props.tvImpressions * item.DeliveryEstimate) / 100
        );
        const percentage = round(item.DeliveryEstimate);

        return (
            <Layout key={key} flexDirection="row" vAlign="center">
                <Layout size="15">
                    <Text type="input">
                        {begin} – {end}
                    </Text>
                </Layout>
                <Layout size="10" hAlign="flex-end">
                    <Text type="input">
                        {strf(itemImpressions).commas().value()}
                    </Text>
                </Layout>
                <Layout size="10" hAlign="flex-end">
                    <Text type="input">{percentage}%</Text>
                </Layout>
                <Layout flex className={styles.barWrapper}>
                    <Bar percentage={percentage} />
                </Layout>
            </Layout>
        );
    };

    const dayParts = props.dayParts.map(buildDayParts);

    return (
        <Layout flexDirection="column" className={styles.wrapper}>
            <Layout
                flexDirection="row"
                vAlign="baseline"
                className={styles.tableTitle}>
                <Text className={styles.title} type="h4">
                    Daypart Distribution
                </Text>
                <Text type="h5">By impressions</Text>
            </Layout>

            <Layout flexDirection="column">
                <Divider
                    borderWidth="2px"
                    color="paleCyan"
                    margin="16px 0"></Divider>
                <Layout flexDirection="row" vAlign="center">
                    <Layout size="25" hAlign="flex-end">
                        <Text type="input">
                            <b>
                                <b>
                                    Total:{' '}
                                    {strf(props.tvImpressions).commas().value()}
                                </b>
                            </b>
                        </Text>
                    </Layout>
                    <Layout size="10" hAlign="flex-end">
                        <Text type="input">
                            <b>100%</b>
                        </Text>
                    </Layout>
                    <Layout flex className={styles.barWrapper}>
                        {' '}
                    </Layout>
                </Layout>
                <Divider
                    borderWidth="2px"
                    color="paleCyan"
                    margin="16px 0"></Divider>
                {dayParts}
            </Layout>
        </Layout>
    );
};

DayPartDistribution.propTypes = {
    tvImpressions: PropTypes.number.isRequired,
    dayParts: PropTypes.array.isRequired
};

export default DayPartDistribution;
