import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Layout, Text, Card, Input, Icon } from 'maslow';
import payLaterSvg from '@public/img/pay-later.svg';
import payNowSvg from '@public/img/pay-now.svg';
import arrowSvg from '@public/img/pay-arrow.svg';

import PayNow from '../PayNow';

import styles from './styles.scss';
import shared from '../styles.scss';

import { setShowPayNow } from '@state/ui/actions';

import { budgetSelector } from '@selectors/';
import {
    BOOK_TOGGLE_PAYMENT_METHOD_PAY_NOW,
    BOOK_TOGGLE_PAYMENT_METHOD_PAY_LATER,
    BOOK_PAYMENT_METHOD
} from '@config/idConstants';

const PaymentMethod = props => {
    const { total } = useSelector(budgetSelector);
    const dispatch = useDispatch();

    const {
        showPayNow,
        setPayError,
        maxPayNowLimit,
        cardHolderName,
        setCardHolderName,
        hasPayLaterOption
    } = props;

    const disablePayNow = total > maxPayNowLimit;

    const renderPayLaterSection = () => {
        return (
            <Layout flexDirection="column">
                <Card
                    backgroundColor="lavenderBlue"
                    borderRadius="2px"
                    boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)">
                    <Layout flexDirection="row" className={shared.paddingSpace}>
                        <Layout flexDirection="column">
                            <Icon src={payLaterSvg} size="10"></Icon>
                        </Layout>

                        <Layout flexDirection="column">
                            <Layout
                                flexDirection="row"
                                className={styles.titleWrapper}>
                                <Text color="chetwodeBlue" type="h5">
                                    You have a Pay Later option available.
                                </Text>
                            </Layout>

                            <Layout
                                flexDirection="row"
                                className={shared.paddingSpace}>
                                <Text color="chetwodeBlue">
                                    By selecting Pay Later, you’re verifying
                                    that you’ve received client approval for
                                    this order. Billing approval is required
                                    before the campaign begins.
                                </Text>
                            </Layout>
                        </Layout>
                    </Layout>
                </Card>

                {!disablePayNow && (
                    <Layout flexDirection="row">
                        <Layout
                            id={BOOK_TOGGLE_PAYMENT_METHOD_PAY_NOW}
                            flexDirection="row"
                            vAlign="center"
                            onClick={togglePayment}
                            className={styles.payMethodToggle}>
                            <Icon
                                src={payNowSvg}
                                className={styles.cardIcon}></Icon>

                            <Text color="chetwodeBlue" type="h5">
                                Want To Pay with your Credit Card?
                            </Text>

                            <Icon
                                src={arrowSvg}
                                size="2"
                                className={styles.arrowIcon}
                            />
                        </Layout>
                    </Layout>
                )}
            </Layout>
        );
    };

    const renderPayNowSection = () => {
        return (
            <Layout flexDirection="column">
                {hasPayLaterOption && (
                    <Card
                        backgroundColor="lavenderBlue"
                        className={styles.payMethodToggle}
                        borderRadius="2px"
                        boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)">
                        <Layout
                            flex
                            id={BOOK_TOGGLE_PAYMENT_METHOD_PAY_LATER}
                            flexDirection="row"
                            hAlign="space-around"
                            vAlign="center"
                            onClick={togglePayment}
                            className={shared.paddingSpace}>
                            <Icon src={payLaterSvg} size="7"></Icon>

                            <Text
                                color="chetwodeBlue"
                                type="h5"
                                className={styles.payMethodDescription}>
                                You have a Pay Later option available.
                            </Text>

                            <Icon src={arrowSvg} size="2" />
                        </Layout>
                    </Card>
                )}

                <PayNow
                    setPayError={setPayError}
                    cardHolderName={cardHolderName}
                    setCardHolderName={setCardHolderName}
                />
            </Layout>
        );
    };

    const togglePayment = () => {
        if (showPayNow) setPayError(false);
        dispatch(setShowPayNow(!showPayNow));
    };

    return (
        <Card
            backgroundColor="white"
            borderRadius="2px"
            boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
            className={shared.paddingSpace}>
            <Layout flexDirection="column" flex>
                <Input
                    id={BOOK_PAYMENT_METHOD}
                    label="Payment Method"
                    name="payment"
                    theme="line"
                    className={styles.paymentMethodInput}
                    disabled={true}
                />

                {showPayNow && renderPayNowSection()}
                {!showPayNow && renderPayLaterSection()}
            </Layout>
        </Card>
    );
};

PaymentMethod.propTypes = {
    showPayNow: PropTypes.bool.isRequired,
    setPayError: PropTypes.func.isRequired,
    maxPayNowLimit: PropTypes.number.isRequired,
    cardHolderName: PropTypes.string.isRequired,
    setCardHolderName: PropTypes.func.isRequired,
    hasPayLaterOption: PropTypes.bool.isRequired
};

export default PaymentMethod;
