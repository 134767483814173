import React, { useState, useEffect, createRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import featuresManager from '@featuresManager/';
import { Input } from 'maslow';
import { budgetConfig } from '@config/config.budget';
import { PLAN_STATUSES } from '@config/config.planStatus';

import {
    budgetSelector,
    totalSelector,
    regionSelector,
    createStatusSelector,
    getAmountBasedOnTotalSelector,
    getUserTypeSelector,
    getLockedSubCampaignsSelector,
    getLockedChannelsSelector,
    allMaxBudgetsSelector
} from '@selectors';
import { budgetCalc } from '@services/budget';

import { strf, getUnformattedBudget } from '@lib';
import { updateBudget } from '@state/budget/actions';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import { setIsValid } from '@state/creative/actions';

import { BUDGET_TOTAL_INPUT } from '@config/idConstants';

const INTERVAL_TIME = 500;

const TotalInput = ({ disableUnusedChannels, disabledChannelsKeys }) => {
    const dispatch = useDispatch();
    const totalInput = createRef();

    const [role] = featuresManager.getCustomFilterState('role');
    const [value, setValue] = useState('$');

    const budget = useSelector(budgetSelector);
    const total = useSelector(totalSelector);
    const region = useSelector(regionSelector);
    const status = useSelector(createStatusSelector.total());
    const inputTotal = useSelector(getAmountBasedOnTotalSelector(total));
    const userType = useSelector(getUserTypeSelector);
    const lockedSubCampaigns = useSelector(getLockedSubCampaignsSelector);
    const lockedChannels = useSelector(getLockedChannelsSelector);
    const maxBudgets = useSelector(allMaxBudgetsSelector);

    const updateInput = value => {
        const amount = getUnformattedBudget(value);
        const formattedAmount = !amount
            ? '$'
            : strf(amount).commas().prepend('$').value();

        setValue(formattedAmount);
    };

    const updateState = value => {
        const amount = getUnformattedBudget(value);

        if (amount !== total) {
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        }

        dispatch(setIsValid(false));
        const newAmounts = budgetCalc.total(
            amount,
            budget,
            role,
            region,
            userType,
            lockedSubCampaigns,
            lockedChannels,
            disabledChannelsKeys,
            maxBudgets
        );
        disableUnusedChannels(newAmounts.channels);
        dispatch(updateBudget.total(newAmounts));
    };

    const focusOnTotalInput = () => {
        if (totalInput.current) {
            totalInput.current.focusInput();
        }
    };

    useEffect(() => {
        if (!isNaN(total)) updateInput(inputTotal);
        focusOnTotalInput();
    }, []);

    return (
        <Input
            id={BUDGET_TOTAL_INPUT}
            name="amount"
            value={value}
            theme="line"
            status={status}
            doneTypingInterval={INTERVAL_TIME}
            doneTyping={updateState}
            onChange={updateInput}
            errorText={budgetConfig.limits.minErrorText[role][region]}
            ref={totalInput}
            maxLength={budgetConfig.limits.maxBudgetLength}
            textAlign="right"></Input>
    );
};

TotalInput.propTypes = {
    disableUnusedChannels: PropTypes.func.isRequired
};

export default TotalInput;
