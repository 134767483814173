import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Layout, Text, Card, Content, Icon, Checkbox } from 'maslow';
import { toggleAcceptedTerms } from '@state/plan/actions';
import budgetSvg from '@public/img/budget-gray.svg';
import errorIcon from '@public/img/error-state.svg';

import { SubmitButton } from '../SubmitButton';
import SummaryTable from '../SummaryTable';
import { strf, isArray } from '@lib';

import styles from './styles.scss';

import { BOOK_TERMS_LINK, BOOK_TERMS_CHECKBOX } from '@config/idConstants';
import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';
import { budgetSelector, planSelector } from '@selectors/';

const Summary = React.memo(
    ({ setSubmitted, showPayNow, setPayError, cardHolderName, errorState }) => {
        const dispatch = useDispatch();
        const { total } = useSelector(budgetSelector);
        const {
            attributes: { hasAcceptedTerms, paymentError }
        } = useSelector(planSelector);
        const totalFormatted = strf(total).commas().prepend('$').value();

        const handleCheckboxOnChange = () => {
            dispatch(toggleAcceptedTerms());
        };

        const renderPaymentErrors = array => {
            if (!isArray(array)) return null;

            return array.map((errorObj, index) => {
                return <li key={index}>{errorObj.message}</li>;
            });
        };

        const labelHtml = (
            <label className={styles.checkbox}>
                I agree to the Athena{' '}
                <a
                    id={BOOK_TERMS_LINK}
                    target="_blank"
                    href={termsAndPolicyConfig.termsOfUse}>
                    {' '}
                    Terms of Use
                </a>
            </label>
        );

        return (
            <Layout flexDirection="column" className={styles.wrapper}>
                <Card
                    width="100%"
                    backgroundColor="white"
                    borderRadius="2px"
                    boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)">
                    <Layout
                        flexDirection="column"
                        className={styles.summaryWrapper}
                        flex>
                        <Layout
                            flexDirection="row"
                            vAlign="center"
                            className={styles.marginBottom}>
                            <Text
                                type="body"
                                className={styles.summaryOrderText}>
                                Order Total
                            </Text>
                            <Text type="input" color="gray">
                                As specified in{' '}
                                <Icon src={budgetSvg} size="2" />{' '}
                                <Text
                                    type="input"
                                    color="gray"
                                    className={styles.budgetText}>
                                    Budget
                                </Text>
                            </Text>
                        </Layout>

                        <Text type="h4" className={styles.boldText}>
                            {totalFormatted}
                        </Text>
                        <div className={styles.divider}></div>

                        <Layout
                            flexDirection="column"
                            className={styles.middleWrapper}>
                            <SummaryTable />
                        </Layout>

                        {errorState && (
                            <Layout flexDirection="column">
                                <Card
                                    borderColor="red"
                                    borderRadius="2px"
                                    boxShadow="none"
                                    className={styles.errorWrapper}>
                                    <Content>
                                        <Layout flexDirection="row">
                                            <Icon
                                                src={errorIcon}
                                                size="3"
                                                className={styles.marginRight}
                                            />
                                            <Text
                                                type="caption"
                                                color="red"
                                                className={styles.errorText}>
                                                There was an issue while
                                                processing your payment. Please
                                                find the information below to
                                                review:
                                                {
                                                    <ol
                                                        className={
                                                            styles.errorList
                                                        }>
                                                        {renderPaymentErrors(
                                                            paymentError
                                                        )}
                                                    </ol>
                                                }
                                            </Text>
                                        </Layout>
                                    </Content>
                                </Card>
                            </Layout>
                        )}

                        <Layout flexDirection="column" hAlign="center">
                            <Checkbox
                                id={BOOK_TERMS_CHECKBOX}
                                name="agree"
                                theme="blue"
                                label={labelHtml}
                                value={hasAcceptedTerms}
                                onChange={handleCheckboxOnChange}
                            />

                            <SubmitButton
                                setSubmitted={setSubmitted}
                                showPayNow={showPayNow}
                                setPayError={setPayError}
                                cardHolderName={cardHolderName}
                            />
                            {showPayNow && (
                                <Text type="caption" color="gray">
                                    Plan will be automatically booked after
                                    successful checkout
                                </Text>
                            )}
                        </Layout>
                    </Layout>
                </Card>
            </Layout>
        );
    }
);

Summary.propTypes = {
    errorState: PropTypes.bool.isRequired,
    setSubmitted: PropTypes.func.isRequired,
    showPayNow: PropTypes.bool.isRequired,
    setPayError: PropTypes.func.isRequired,
    cardHolderName: PropTypes.string.isRequired
};

export default Summary;
