import { createAction } from '@reduxjs/toolkit';
import { UPDATE_AGENCY, RESET_AGENCY } from '../constants';

export const updateAgency = createAction(UPDATE_AGENCY, agency => {
    return {
        payload: { agency }
    };
});

export const resetAgency = createAction(RESET_AGENCY);
