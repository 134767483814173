import { strf, datesHandler, isArray, isEmpty } from '@lib';
import { getZones, prepareDataForLogicalExpressionBuilder } from '@services';
import { trimAudiences } from '@services';
import { isObject } from '@lib';

export const formatHiatusWeeks = hiatusWeeks => {
    if (!isArray(hiatusWeeks)) throw new Error('arg must be an array');
    return hiatusWeeks.map(({ week }) => {
        return {
            StartDate: datesHandler.format(week[0]),
            EndDate: datesHandler.format(week[week.length - 1])
        };
    });
};

export const getFormattedTvDetails = tvDetails => {
    if (!isObject(tvDetails)) throw new Error('Argument must be an object');
    if (isEmpty(tvDetails)) return {};

    const { primaryTarget, distribution } = tvDetails;
    if (!isObject(primaryTarget))
        throw new Error(
            'If tvDetails has property primaryTarget, it must be an object'
        );
    if (distribution && !isObject(distribution))
        throw new Error(
            'If tvDetails has property distribution, it must be an object'
        );

    return {
        primaryTargetDemography: primaryTarget
            ? {
                  Name: primaryTarget.primaryDemoName,
                  Number: primaryTarget.Number,
                  Cpm: primaryTarget.cpm
              }
            : null,
        tvDistribution:
            !distribution || isEmpty(distribution) ? null : distribution
    };
};

export const getMediaPlanObject = input => {
    if (!input || typeof input !== 'object')
        throw new Error('arg must be an object with truthy value');
    const {
        name,
        audiences,
        a4PlanId,
        advertiser,
        email,
        userRole,
        region,
        selectedLocations,
        budget,
        hiatusWeeks,
        startDate,
        endDate,
        showPayNow,
        mediaPlan,
        vennPngDataUrl,
        firstName,
        lastName,
        combinations
    } = input;
    return {
        //AudienceInstanceJson
        mediaPlanId: a4PlanId,
        name: '',
        planName: name,
        audience: trimAudiences(audiences),
        //MediaPlan
        advertiserName: advertiser,
        submittedBy: email,
        mediaPlanType: strf(userRole).uppercase(true).value(),
        geographicType: region,
        geoData: getZones(selectedLocations || []),
        hiatusWeeks: formatHiatusWeeks(hiatusWeeks),
        startDate: datesHandler.format(startDate),
        endDate: datesHandler.format(endDate),
        paymentReceived: showPayNow,
        preparedData: prepareDataForLogicalExpressionBuilder(
            audiences,
            combinations
        ),
        //PaymentDetails
        amount: budget.total * 100,
        //AdOpsEmail
        primaryTargetDemography: {
            name: mediaPlan?.tvDetails?.primaryTarget?.primaryDemoName
        },
        vennDiagram: vennPngDataUrl,
        userName: [firstName, lastName].join(' ')
    };
};

export const getSaveMediaPlanObject = input => {
    if (!input || typeof input !== 'object')
        throw new Error('arg must be an object with truthy value');
    const {
        name,
        advertiser,
        a4PlanId,
        schedule,
        creativeUrls,
        productDetails,
        creativeProducts,
        excludedNetworks,
        formattedTvDetails: { primaryTargetDemography, tvDistribution },
        mapImageDataUrls,
        selectedLocations,
        audience,
        households,
        campaignRouterId
    } = input;
    return {
        name,
        advertiserName: advertiser,
        id: a4PlanId,
        schedule: {
            ...schedule,
            hiatusWeeks: formatHiatusWeeks(schedule.hiatusWeeks)
        },
        urls: {
            ClickThroughUrl: creativeUrls.destination,
            AdMessengerUrl: creativeUrls.adMessenger,
            InstagramUrl: creativeUrls.instagram,
            FacebookUrl: creativeUrls.facebook
        },
        productDetails,
        creativeProducts,
        excludedNetworks,
        primaryTargetDemography,
        tvDistribution,
        mediaPlanMapImageDataUrls: mapImageDataUrls,
        selectedLocations,
        audience,
        households,
        campaignRouterId
    };
};

export const getBookPlanObject = input => {
    if (!input || typeof input !== 'object')
        throw new Error('arg must be an object with truthy value');
    const { campaignRouterId, name, advertiser, showPayNow, total } = input;
    return {
        campaign_id: campaignRouterId,
        campaign_name: name,
        advertiser_name: advertiser,
        amount_received: showPayNow ? total : 0
    };
};

export const getPaymentPayload = input => {
    if (!input || typeof input !== 'object')
        throw new Error('arg must be an object with truthy value');
    const { name, advertiser, email, stripeToken, budget, a4PlanId } = input;
    return {
        description: name + '-' + advertiser + '-' + a4PlanId,
        email,
        stripeToken,
        amount: budget.total * 100,
        mediaPlanId: a4PlanId
    };
};
