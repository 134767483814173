import { NotFound } from '../components/pages';
import { MainSidebar } from '../components/layouts';
import featuresManager from '@featuresManager';

export default [
    {
        name: 'app.main.notfound',
        url: '/notfound',
        views: {
            'mainSidebar@app': {
                component: MainSidebar
            },
            'content@app': {
                component: NotFound
            }
        },
        onEnter: () => {
            featuresManager.setFeatureState('main');
        }
    }
];
