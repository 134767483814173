import React, { useReducer } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import config from '@config/config.campaign';
import featuresManager from '@featuresManager';
import requestHandlers from '@requestHandlers';

import { CardSelector } from '@components/common';
import { userSelector } from '@selectors';
import { setCampaignType } from '@state/plan/actions/attributes';
import { setA4PlanId } from '@state/mediaPlan/actions';
import { updateAttributes } from '@state/plan/actions';
import { capitalizeFirstLetters } from '@lib';

const reducer = (state, action) => {
    const { role, value } = action;
    return {
        ...state,
        [role]: value
    };
};

export default function (version, feature) {
    const Component = props => {
        const dispatch = useDispatch();
        const [state, stateDispatch] = useReducer(reducer, {
            political: false,
            commercial: false
        });

        const {
            account: { email }
        } = useSelector(userSelector);

        const updateCampaignType = role => {
            if (state[role]) return false;

            stateDispatch({
                role,
                value: true
            });

            requestHandlers.plans
                .newMediaPlan({ mediaPlanType: role, email })
                .then(res => {
                    dispatch(setA4PlanId(res.data));
                    dispatch(updateAttributes({ planId: res.data }));
                    featuresManager.setCustomFilterState('role', [role]);
                    dispatch(setCampaignType(role));
                    props.transition.router.stateService.go(
                        feature.data.routeTo,
                        { planId: 'new' }
                    );
                })
                .catch(err => {
                    stateDispatch({
                        role,
                        value: false
                    });
                    console.error('error creating new plan', err);
                });
        };

        return (
            <React.Fragment>
                {config.map(({ role, id, tooltipId, copy, icon }, i) => {
                    return (
                        <CardSelector
                            onClick={() => updateCampaignType(role)}
                            key={id + i}
                            cardId={id}
                            svg={icon}
                            text={capitalizeFirstLetters(role)}
                            buttonText={'Select'}
                            tooltipId={tooltipId}
                            tooltipText={copy}
                            animateButton={state[role]}></CardSelector>
                    );
                })}
            </React.Fragment>
        );
    };

    return Component;
}
