import { createAction } from '@reduxjs/toolkit';
import { UPDATE_LOADING_SCREEN, RESET_LOADING_SCREEN } from '../constants';

export const updateLoadingScreen = createAction(
    UPDATE_LOADING_SCREEN,
    loadingScreen => {
        return {
            payload: {
                loadingScreen
            }
        };
    }
);

export const resetLoadingScreen = createAction(RESET_LOADING_SCREEN);
