import { Budget } from '../components/pages';
import { LeftSidebarBudget } from '../components/layouts';

export default [
    {
        name: 'app.plan.budget',
        url: '/budget',
        views: {
            'leftSidebar@app': {
                component: LeftSidebarBudget
            },
            'content@app': {
                component: Budget
            }
        }
    }
];
