import { createAction } from '@reduxjs/toolkit';
import { TOGGLE_IS_SUMMARY_SCROLLBAR } from '../constants';

export const toggleIsSummaryScrollbar = createAction(
    TOGGLE_IS_SUMMARY_SCROLLBAR,
    isVisible => {
        return {
            payload: { isVisible }
        };
    }
);
