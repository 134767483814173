export const ADD_FILE = 'ADD_FILE';
export const ADD_FILES = 'ADD_FILES';
export const UPDATE_FILE = 'UPDATE_FILE';
export const UPDATE_FILES = 'UPDATE_FILES';
export const REMOVE_FILE = 'REMOVE_FILE';
export const REMOVE_ALL_FILES = 'REMOVE_ALL_FILES';
export const SET_AD_MESSENGER = 'SET_AD_MESSENGER';
export const SET_FACEBOOK = 'SET_FACEBOOK';
export const SET_INSTAGRAM = 'SET_INSTAGRAM';
export const SET_DESTINATION = 'SET_DESTINATION';
export const SET_SKIP_UPLOAD = 'SET_SKIP_UPLOAD';
export const SET_IS_VALID = 'SET_IS_VALID';
export const SET_IS_UPLOADING = 'SET_IS_UPLOADING';
export const ADD_DISCLAIMERS = 'ADD_DISCLAIMERS';
export const UPDATE_DISCLAIMERS = 'UPDATE_DISCLAIMERS';
export const REMOVE_DISCLAIMER = 'REMOVE_DISCLAIMER';
export const REMOVE_ALL_DISCLAIMERS = 'REMOVE_ALL_DISCLAIMERS';
export const SET_LAST_SAVED = 'SET_LAST_SAVED';
export const SHOW_POPUP = 'SHOW_POPUP';
export const SET_FILE_STATUS = 'SET_FILE_STATUS';
