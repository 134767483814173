import {
    PRE_MID_ROLL,
    OTT,
    BANNERS,
    AD_MESSENGER,
    FACEBOOK,
    INSTAGRAM
} from '@config';

export default {
    name: 'Reports',
    description: 'Campaign Reports Page',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['main'],
    sections: ['reports'],
    flavours: ['cgd', 'cablevision', 'beta'],
    userRoles: ['guest', 'admin'],
    data: {
        ctr: [BANNERS, PRE_MID_ROLL, AD_MESSENGER, FACEBOOK, INSTAGRAM],
        vcr: [OTT]
    },
    attributes: {},
    modifications: {}
};
