import { createReducer } from '@reduxjs/toolkit';
import { SET_GEO_JSON } from '../constants';
import { isArray } from '@lib';

const initialState = [];

const setGeoJson = (state, action) => {
    const { geoJson } = action.payload;
    return isArray(geoJson) ? geoJson : state;
};

export const geoJson = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_GEO_JSON, setGeoJson)
        .addDefaultCase((state, _) => state);
});
