import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import {
    DefaultButton,
    BookButton,
    IconButton,
    NotificationButton,
    ThemeProvider,
    ButtonAnimationContainer,
    ButtonAnimationArcs
} from './style';
import { BUTTON } from '../testIds';
import { testIdBuilder } from '../../core';

/**
 * Button component
 * */

const getButtonByTheme = theme => {
    switch (theme) {
        case 'book':
            return BookButton;
        case 'icon':
            return IconButton;
        case 'notification':
            return NotificationButton;
        default:
            return DefaultButton;
    }
};

const Button = forwardRef((props, ref) => {
    const {
        theme,
        onClick,
        disabled,
        children,
        notClickable,
        testNameSpace,
        className,
        style,
        id,
        animate,
        doubleClick,
        animationColor = '#fff'
    } = props;

    let buttonState;
    const handleClick = event => {
        if ((buttonState === undefined && !animate) || doubleClick) {
            buttonState = event;
            onClick && onClick(event);
        }
        return false;
    };

    const StyledButton = getButtonByTheme(theme);
    const themeObj = {
        name: theme
    };
    const testId = testIdBuilder(testNameSpace, BUTTON);

    const returnAnimation = () => {
        if (animate === true)
            return (
                <ButtonAnimationContainer>
                    <ButtonAnimationArcs />
                </ButtonAnimationContainer>
            );
    };

    return (
        <ThemeProvider theme={themeObj}>
            <StyledButton
                onClick={handleClick}
                disabled={disabled}
                notClickable={notClickable}
                data-testid={testId}
                style={style}
                className={className}
                id={id}
                ref={ref}>
                {returnAnimation()}
                {children}
            </StyledButton>
        </ThemeProvider>
    );
});

Button.propTypes = {
    /** On click callback */
    onClick: PropTypes.func,
    /** Theme of the button */
    theme: PropTypes.oneOf([
        'primary',
        'notification',
        'black',
        'dark',
        'green',
        'outline',
        'book',
        'icon',
        'outlineGray',
        'outlineNeutral'
    ]),
    /** Disables the button */
    disabled: PropTypes.bool,
    /** Makes button not clickable while still having a regular non disabled style */
    notClickable: PropTypes.bool,
    /** Namespace for e2e tests targeting */
    testNameSpace: PropTypes.string,
    style: PropTypes.string,
    className: PropTypes.string,
    id: PropTypes.string,
    doubleClick: PropTypes.bool
};

Button.defaultProps = {
    theme: 'primary',
    disabled: false,
    notClickable: false
};

export default Button;
