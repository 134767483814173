import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Page } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';

import { generateToast } from '@services';
import { ToastNotification } from '@components/common';
import { maintenanceModeConfig } from '@config/config.maintenanceMode';

const Login = props => {
    const _props = { props };
    const { components } = getFeatures('login', _props);
    const dispatch = useDispatch();

    useEffect(() => {
        if (window.maintenanceMode === 'true') {
            return generateToast.add({ ...maintenanceModeConfig }, dispatch);
        }
    }, [window.maintenanceMode]);

    return (
        <Page center backgroundColor="quartz" style={{ overflow: 'hidden' }}>
            {components}
            <ToastNotification />
        </Page>
    );
};

export default withFeatures(Login);
