import React, { useReducer, useState } from 'react';
import PropTypes from 'prop-types';

import { Input } from 'maslow';

import requestHandlers from '@requestHandlers';
import { isEmptyString } from '@lib';
import RegisterButton from '../RegisterButton';
import Passwords from '../Passwords';
import CheckboxSection from '../CheckboxSection';
import AlreadyMemberSection from '../AlreadyMemberSection';

import {
    SIGNUP_FIRST_NAME_INPUT,
    SIGNUP_LAST_NAME_INPUT,
    SIGNUP_EMAIL_INPUT
} from '@config/idConstants';

const INTERVAL_TIME = 500;
const STATUSES = {
    IDLE: 'idle',
    VALID: 'valid',
    ERROR: 'error'
};

const getInitialState = ({ IDLE }) => {
    return {
        firstNameStatus: IDLE,
        lastNameStatus: IDLE,
        emailStatus: IDLE,
        emailError: '',
        hasAgreedToTerms: false,
        password1Status: IDLE,
        password1Error: '',
        password2Status: IDLE,
        password2Error: '',
        password1: '',
        password2: '',
        passwordsMatched: false,
        firstName: '',
        lastName: ''
    };
};

const reducer = (state, action) => {
    const { type, payload } = action;
    const { name, value } = payload;
    switch (type) {
        case 'updateValue':
            return {
                ...state,
                [name]: value
            };
        default:
            throw new Error();
    }
};

const CreateAccount = ({
    ls,
    errors,
    DISPLAY_TEXT_STATES,
    displayText,
    setDisplayTextState,
    setShowSignUpPage,
    transition
}) => {
    const [state, stateDispatch] = useReducer(
        reducer,
        getInitialState(STATUSES)
    );
    const {
        firstNameStatus,
        lastNameStatus,
        emailStatus,
        emailError,
        hasAgreedToTerms,
        password1Status,
        password1Error,
        password2Status,
        password2Error,
        password1,
        password2,
        passwordsMatched,
        firstName,
        lastName
    } = state;

    const [buttonAnimation, setButtonAnimation] = useState(false);

    const updateStateValue = (name, value) => {
        stateDispatch({
            type: 'updateValue',
            payload: { name, value }
        });
    };

    const onNameChange = (value, name) => {
        updateStateValue(name, value);
        if (isEmptyString(value)) {
            updateStateValue(`${name}Status`, STATUSES.IDLE);
        } else {
            updateStateValue(`${name}Status`, STATUSES.VALID);
        }
    };

    const invalidEmailSideEffects = value => {
        updateStateValue(
            'emailStatus',
            isEmptyString(value) ? STATUSES.IDLE : STATUSES.ERROR
        );
        updateStateValue('emailError', errors.emailError);
    };

    const emailDoneTyping = value => {
        const hasValidEmail = ls.hasValidEmail(value);
        hasValidEmail
            ? updateStateValue('emailStatus', STATUSES.VALID)
            : invalidEmailSideEffects(value);
    };

    const showVerificationSection = () => {
        setDisplayTextState(DISPLAY_TEXT_STATES.VERIFY_ACCOUNT);
        setShowSignUpPage(false);
    };

    const register = (email, password) => {
        return requestHandlers.auth
            .register({
                firstName,
                lastName,
                email,
                password,
                password2,
                hasAgreedToTerms
            })
            .catch(error => {
                console.error('error signing up', error);
                setButtonAnimation(false);
            });
    };

    const submitForm = async () => {
        setButtonAnimation(true);
        const { email, password } = ls.getUserDetails();
        const registerResponse = await register(email, password);
        const { status, data } = registerResponse;

        if (status === 200) {
            requestHandlers.auth.athenaUser(email, data.id).catch(err => {
                console.error('error sending externalId', err);
            });
            showVerificationSection();
        } else if (status === 400) {
            updateStateValue('emailStatus', STATUSES.ERROR);
            updateStateValue('emailError', errors.emailExistsError);
            setButtonAnimation(false);
        }
    };

    return (
        <React.Fragment>
            <div>
                <Input
                    id={SIGNUP_FIRST_NAME_INPUT}
                    name="firstName"
                    theme="smallLine"
                    placeholder="First Name"
                    status={firstNameStatus}
                    onChange={onNameChange}
                />
            </div>

            <div>
                <Input
                    id={SIGNUP_LAST_NAME_INPUT}
                    name="lastName"
                    theme="smallLine"
                    placeholder="Last Name"
                    status={lastNameStatus}
                    onChange={onNameChange}
                />
            </div>

            <div>
                <Input
                    id={SIGNUP_EMAIL_INPUT}
                    name="email"
                    theme="smallLine"
                    placeholder="Email Address"
                    status={emailStatus}
                    errorText={emailError}
                    doneTypingInterval={INTERVAL_TIME}
                    doneTyping={emailDoneTyping}
                />
            </div>

            <Passwords
                updateStateValue={updateStateValue}
                password1={password1}
                password2={password2}
                password1Status={password1Status}
                password2Status={password2Status}
                password1Error={password1Error}
                password2Error={password2Error}
                STATUSES={STATUSES}
                INTERVAL_TIME={INTERVAL_TIME}
                errors={errors}
                ls={ls}
            />

            <CheckboxSection updateStateValue={updateStateValue} />

            <RegisterButton
                submitForm={submitForm}
                hasAgreedToTerms={hasAgreedToTerms}
                text={displayText.button}
                firstName={firstName}
                lastName={lastName}
                passwordsMatched={passwordsMatched}
                ls={ls}
                buttonAnimation={buttonAnimation}
            />

            <AlreadyMemberSection transition={transition} />
        </React.Fragment>
    );
};

CreateAccount.propTypes = {
    ls: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    DISPLAY_TEXT_STATES: PropTypes.object.isRequired,
    displayText: PropTypes.object.isRequired,
    setDisplayTextState: PropTypes.func.isRequired,
    setShowSignUpPage: PropTypes.func.isRequired
};

export default CreateAccount;
