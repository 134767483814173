import { Book } from '../components/pages';

export default [
    {
        name: 'app.plan.book',
        url: '/book',
        views: {
            'content@app': {
                component: Book
            }
        }
    }
];
