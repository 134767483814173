import { createAction } from '@reduxjs/toolkit';
import { SET_FLYOUT_NAV_STATUS } from '../constants';

export const setFlyoutNavStatus = createAction(
    SET_FLYOUT_NAV_STATUS,
    flyoutNavStatus => {
        return {
            payload: { flyoutNavStatus }
        };
    }
);
