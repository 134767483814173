import { createSelector } from 'reselect';
import { selectedLocationsSelector } from '../location';
import { customListSelector } from '../customList';

import {
    chunkLocationData,
    formatAudienceCombinations,
    formatAudienceArrays,
    isAudienceSelected,
    buildHHCountPayload
} from '@services';
import { hasLocations, getHouseholdAmounts } from '@services/audiencesService';

export const hhCountSelector = createSelector(
    state => state.audience.households,
    ({ count, total }) => (count > 0 ? count : total)
);

export const householdsSelector = createSelector(
    state => state.audience.households,
    households => households
);

export const audienceSelector = createSelector(
    state => state.audience,
    audience => audience
);

export const audiencesSelector = createSelector(
    state => state.audience.audiences,
    audiences => audiences
);

export const combinationsSelector = createSelector(
    state => state.audience.combinations,
    combinations => combinations
);

export const locationDataSelector = createSelector(
    selectedLocationsSelector,
    selectedLocations => {
        return {
            selectedLocations,
            locationArray: chunkLocationData(selectedLocations),
            locationTitle: hasLocations(selectedLocations)
                ? 'Selected Locations:'
                : 'Targeting All Households',
            hasLocationsResult: hasLocations(selectedLocations)
        };
    }
);

export const amountsSelector = createSelector(
    audienceSelector,
    selectedLocationsSelector,
    customListSelector,
    (audience, locations, customList) =>
        getHouseholdAmounts(audience, locations, customList)
);

export const formattedAudiencesSelector = createSelector(
    audienceSelector,
    ({ audiences, combinations, vennPngDataUrl }) => {
        return {
            audiences: formatAudienceArrays(audiences),
            audienceCombinations: formatAudienceCombinations(combinations),
            vennPngDataUrl
        };
    }
);

export const savedAudiencesSelector = createSelector(
    audienceSelector,
    audience => audience.savedAudiences
);

export const audienceConfigSelector = createSelector(
    audienceSelector,
    audience => audience.audienceConfig
);

export const isAudienceSelectedSelector = savedAudience => {
    return createSelector(
        audienceSelector,
        ({ audiences, currentAudienceIndex }) =>
            isAudienceSelected(audiences, currentAudienceIndex, savedAudience)
    );
};

export const hhCountPayloadSelector = createSelector(
    audiencesSelector,
    combinationsSelector,
    selectedLocationsSelector,
    (audiences, combinations, selectedLocations) =>
        buildHHCountPayload(audiences, combinations, selectedLocations)
);
