import { datesHandler } from '@lib';

export default {
    advertiser: '',
    channels: [],
    created_at: '',
    created_by: {
        profile: {
            organization: 'my org skwjwjee'
        }
    },
    creatives: [],
    destination_url: '',
    end_date: datesHandler.format(datesHandler.newDate(15)),
    extras: {},
    id: null,
    name: '',
    organization: '',
    start_date: datesHandler.format(datesHandler.newDate(6)),
    stats: {},
    status: 'draft',
    target: {},
    total_budget: 0,
    type: '',
    updated_at: ''
};
