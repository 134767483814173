import React from 'react';

import { Layout, Icon, Text, Button } from 'maslow';
import emptyStateSvg from '@public/img/empty-state.svg';

import styles from './styles.scss';

import { VERIFICATION_ERROR_SIGNUP } from '@config/idConstants';

export default function (version, feature) {
    const VerificationError = props => {
        const onSignUp = () => {
            props.transition.router.stateService.go('signUp');
        };

        return (
            <Layout flexDirection="column" hAlign="center" flex>
                <Layout
                    flexDirection="column"
                    hAlign="center"
                    className={styles.wrapper}>
                    <Layout
                        flexDirection="row"
                        hAlign="center"
                        vAlign="center"
                        className={styles.iconWrapper}>
                        <Icon size="30" src={emptyStateSvg} />
                    </Layout>

                    <Layout flexDirection="column" hAlign="center">
                        <Text color="vividNavy" type="h3">
                            Oh nooo!
                        </Text>
                        <Text
                            color="echoBlue"
                            type="body"
                            className={styles.middleText}>
                            Seems like your verification link has expired.
                            <br />
                            Please, sign up again to start enjoying Athena.
                        </Text>
                        <Layout flexDirection="column">
                            <Button
                                id={VERIFICATION_ERROR_SIGNUP}
                                onClick={onSignUp}>
                                Sign Up
                            </Button>
                        </Layout>
                    </Layout>
                </Layout>
            </Layout>
        );
    };

    return VerificationError;
}
