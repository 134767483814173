// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".notFoundWrapper___3DF02 {\n  height: 90vh; }\n\n.title___3VbyB, .text___2VfbN, .actionBtn___3UioI {\n  margin-top: 40px; }\n\n.title___3VbyB {\n  font-size: 5rem; }\n\n.text___2VfbN {\n  font-size: 2.8rem;\n  display: block;\n  width: 30%;\n  font-weight: 400;\n  text-align: center; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/features/NotFound/1.0.0/styles.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAGA;EACE,YAAY,EAAA;;AAGd;EACE,gBCJ2B,EAAA;;ADO7B;EACE,eAAe,EAAA;;AAGjB;EACE,iBAAiB;EACjB,cAAc;EACd,UAAU;EACV,gBAAgB;EAChB,kBAAkB,EAAA","file":"styles.scss","sourcesContent":["@import '../../../../scss/settings/colors';\n@import '../../../../scss/settings/baseline';\n\n.notFoundWrapper {\n  height: 90vh;\n}\n\n.title, .text, .actionBtn {\n  margin-top: $baseline-x5\n}\n\n.title {\n  font-size: 5rem;\n}\n\n.text {\n  font-size: 2.8rem;\n  display: block;\n  width: 30%;\n  font-weight: 400;\n  text-align: center;\n}","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"notFoundWrapper": "notFoundWrapper___3DF02",
	"title": "title___3VbyB",
	"text": "text___2VfbN",
	"actionBtn": "actionBtn___3UioI"
};
module.exports = exports;
