import React from 'react';
import { Text, Icon } from 'maslow';
import style from './progressCircle.scss';
import deleteSvg from '@public/img/delete.svg';

import {
    CUSTOM_LIST_STOP_BUTTON,
    CUSTOM_LIST_REMOVE_BUTTON
} from '@config/idConstants';
import { SHOW_TRASHCAN_STAGES } from '@config/config.customList';

export class ProgressCircle extends React.Component {
    constructor(props) {
        super(props);

        this.state = { elementKey: 0 };

        this.stop = this.stop.bind(this);
    }

    isVisible(element) {
        return (
            window.getComputedStyle(element).getPropertyValue('visibility') ===
            'visible'
        );
    }

    stop(event) {
        if (
            !this.isVisible(
                document.querySelector('#progress-close-svg .cross')
            )
        ) {
            event.stopPropagation();
        }

        this.setState(
            prevState => ({
                elementKey: prevState.elementKey + 1
            }),
            () => {
                this.props.onReset();
            }
        );
    }

    hasDeletionOption(currentStep) {
        if (!currentStep || !currentStep?.name) return false;
        return SHOW_TRASHCAN_STAGES.includes(currentStep.name);
    }

    render() {
        const currentStep = this.props.currentStep;

        const enabledDeletion = this.hasDeletionOption(currentStep);
        return (
            <React.Fragment>
                <div className={style.progressWrapper}>
                    <div
                        id="progress-circle"
                        className={style.progressCircle}
                        key={this.state.elementKey}>
                        <div id="persons-side" className={style.pSide}>
                            <div
                                id="person1-side-wrapper"
                                className={style.pSideWrapper1}>
                                <span
                                    id="person1-side-inner"
                                    className={style.pSideInner}>
                                    <span
                                        id="person1-side"
                                        className={style.personSide}
                                    />
                                </span>
                            </div>

                            <div
                                id="person2-side-wrapper"
                                className={style.pSideWrapper2}>
                                <span
                                    id="person2-side-inner"
                                    className={style.pSideInner}>
                                    <span
                                        id="person2-side"
                                        className={style.personSide}
                                    />
                                </span>
                            </div>

                            <div
                                id="person3-side-wrapper"
                                className={style.pSideWrapper3}>
                                <span
                                    id="person3-side-inner"
                                    className={style.pSideInner}>
                                    <span
                                        id="person3-side"
                                        className={style.personSide}
                                    />
                                </span>
                            </div>

                            <div
                                id="person4-side-wrapper"
                                className={style.pSideWrapper4}>
                                <span
                                    id="person4-side-inner"
                                    className={style.pSideInner}>
                                    <span
                                        id="person4-side"
                                        className={style.personSide}
                                    />
                                </span>
                            </div>
                        </div>

                        <div className={style.progressCircleWrapper}>
                            <div
                                id="match-progress-result"
                                className={style.matchResult}>
                                <span className={style.resultCounts}>
                                    <span id="result-counts-number">
                                        {this.props.match_percent}
                                    </span>
                                    <span>%</span>
                                </span>

                                <span className={style.resultCaption}>
                                    Match
                                    <br />
                                    rate
                                </span>
                            </div>

                            <span
                                id="match-progress-circle"
                                className={style.progressCircleIcon}></span>

                            <span
                                id="match-progress-check"
                                className={style.progressLinesIcon}></span>

                            <span
                                id="match-progress-ex"
                                className={style.progressExIcon}></span>

                            <span
                                id="white-lines"
                                className={style.progressLinesIcon}></span>

                            <span
                                id="transparent-lines"
                                className={style.progressLinesIcon}></span>

                            <div id="persons-big" className={style.personsBig}>
                                <span
                                    id="person4-big"
                                    className={style.person4Big}
                                />
                                <span
                                    id="person3-big"
                                    className={style.person3Big}
                                />
                                <span
                                    id="person1-big"
                                    className={style.person1Big}
                                />
                                <span
                                    id="person2-big"
                                    className={style.person2Big}
                                />
                            </div>

                            <div
                                className={style.progressCloseIcon}
                                onClick={this.stop}>
                                <span
                                    id="file-upload"
                                    className={style.fileUploadIcon}></span>

                                <span
                                    id="match-progress-close"
                                    className={style.closeIcon}></span>

                                <div
                                    id="persons-small"
                                    className={style.personsSmall}>
                                    <span
                                        id="person4-small"
                                        className={style.personSmall}
                                    />
                                    <span
                                        id="person3-small"
                                        className={style.personSmall}
                                    />
                                    <span
                                        id="person1-small"
                                        className={style.personSmall}
                                    />
                                    <span
                                        id="person2-small"
                                        className={style.personSmall}
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                    <span
                        id={CUSTOM_LIST_STOP_BUTTON}
                        className={style.uploaderIconCaption}
                        onClick={this.stop}>
                        <Text type="caption">{currentStep.caption}</Text>
                    </span>
                </div>
                {enabledDeletion && (
                    <div className={style.deleteWrapper}>
                        <span
                            id={CUSTOM_LIST_REMOVE_BUTTON}
                            className={style.deleteIconWrapper}
                            onClick={this.stop}>
                            <Icon src={deleteSvg} size="4" />
                        </span>
                    </div>
                )}
            </React.Fragment>
        );
    }
}
