import { Dashboard } from '../../components/pages';

export default [
    {
        name: 'app.admin.dashboard',
        url: '/dashboard',
        views: {
            'content@app': {
                component: Dashboard
            }
        }
    }
];
