import request from '../request';

export default function (payload) {
    const req = {
        method: `POST`,
        url: `creatives/detectText/`,
        data: { url: payload }
    };
    return request(req).catch(error => {
        throw Error(error);
    });
}
