import submittedSvg from '@public/img/ico-submitted.svg';
import completedSvg from '@public/img/ico-completed.svg';
import pendingSvg from '@public/img/ico-pending.svg';
import {
    EDIT_CREATIVES,
    SUBMITTED,
    LIVE,
    COMPLETED,
    DRAFT,
    PENDING,
    REMOVE_PLAN,
    MODIFIED_DATE,
    ID,
    STATE,
    NAME,
    ADVERTISER_NAME,
    START_DATE,
    END_DATE
} from '@config';

export const plansConfig = {
    routes: {
        mediaPlanSummary: 'app.plan.mediaPlan',
        campaignPage: 'app.plan.campaign',
        locationPage: 'app.plan.location',
        report: 'app.main.reports',
        creative: 'app.plan.creative',
        ranker: 'app.ranker.location'
    },
    planStatus: {
        [DRAFT]: {
            menu: [REMOVE_PLAN],
            styles: {
                color: '#8794a6',
                background: 'rgba(135, 148, 166, 0.1)',
                borderRightColor: '#8794a6'
            },
            startDateIcon: '',
            endDateIcon: ''
        },
        [SUBMITTED]: {
            menu: [EDIT_CREATIVES],
            styles: {
                color: '#00abcc',
                background: 'rgba(0, 171, 204, 0.1)',
                borderRightColor: '#00abcc'
            },
            startDateIcon: submittedSvg,
            endDateIcon: ''
        },
        [LIVE]: {
            menu: [EDIT_CREATIVES],
            styles: {
                color: '#13c836',
                background: 'rgba(19, 200, 54, 0.1)',
                borderRightColor: '#13c836'
            },
            startDateIcon: '',
            endDateIcon: ''
        },
        [COMPLETED]: {
            menu: [],
            styles: {
                color: '#577cf7',
                background: 'rgba(87, 124, 247, 0.1)',
                borderRightColor: '#577cf7'
            },
            startDateIcon: '',
            endDateIcon: completedSvg
        },
        [PENDING]: {
            menu: [EDIT_CREATIVES],
            styles: {
                color: '#00317A',
                background: 'rgba(0, 49, 122, 0.1)',
                borderRightColor: '#00317A'
            },
            startDateIcon: pendingSvg,
            endDateIcon: ''
        }
    },
    sortCriteria: {
        [MODIFIED_DATE]: 'Last Modified',
        [ID]: 'Plan ID',
        [STATE]: 'Plan Status',
        [NAME]: 'Plan Name',
        [ADVERTISER_NAME]: 'Advertiser',
        [START_DATE]: 'Start Date',
        [END_DATE]: 'End Date'
    }
};

export const POPUP_SETTINGS = {
    HIDDEN: 'hidden',
    QUERY: 'query',
    CONFIRMATION: 'confirmation'
};
