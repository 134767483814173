import { Reports } from '../components/pages';

export default [
    {
        name: 'app.main.reports',
        url: '/reports/:planId',
        views: {
            'content@app': {
                component: Reports
            }
        }
    }
];
