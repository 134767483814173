import { combineReducers } from 'redux';

import { startDate } from './startDate';
import { endDate } from './endDate';
import { duration } from './duration';
import { hiatusWeeks } from './hiatusWeeks';
import { excludedDayparts } from './excludedDayparts';
import { isBroadcast } from './isBroadcast';

export const schedule = combineReducers({
    startDate,
    endDate,
    duration,
    hiatusWeeks,
    excludedDayparts,
    isBroadcast
});
