import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
    budgetSelector,
    regionSelector,
    getLockedSubCampaignsSelector,
    getLockedChannelsSelector,
    hasLockedSubsBudgetSelector,
    hasLockedChannelsBudgetSelector
} from '@selectors';
import {
    filterSubs,
    budgetCalc,
    isAllChannelsLocked,
    getNewSubAmountsBasedOnLockedStatus,
    getMergedChannelKeys,
    getNewChannelAmountsBasedOnLockedStatus
} from '@services/budget';
import { existsInArray } from '@lib';
import featuresManager from '@featuresManager/';

import { Text, Layout, Icon, Button, Card } from 'maslow';
import Channel from '../Channel';

import { updateBudget } from '@state/budget/actions';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import { setIsValid } from '@state/creative/actions';
import { PLAN_STATUSES } from '@config/config.planStatus';

import styles from './styles.scss';

import plusSvg from '@public/img/plus-selector.svg';

import { idBuilder } from '@services';
import { BUDGET_ENABLE_CHANNEL } from '@config/idConstants';

const Distribution = ({
    config,
    disabledChannelsKeys,
    setDisabledChannelsKeys
}) => {
    const { channels, subCampaigns } = config;
    const dispatch = useDispatch();
    const [role] = featuresManager.getCustomFilterState('role');

    const [editedChannel, setEditedChannel] = useState('');
    const budget = useSelector(budgetSelector);
    const region = useSelector(regionSelector);
    const lockedSubCampaigns = useSelector(getLockedSubCampaignsSelector);
    const lockedChannels = useSelector(getLockedChannelsSelector);
    const hasLockedSubsBudget = useSelector(hasLockedSubsBudgetSelector);
    const hasLockedChannelsBudget = useSelector(
        hasLockedChannelsBudgetSelector
    );

    const toggleEditing = key => {
        if (editedChannel === key) return setEditedChannel('');
        return setEditedChannel(key);
    };

    const updateState = key => {
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        dispatch(setIsValid(false));

        const newAmounts = budgetCalc.channel(
            key,
            100,
            budget,
            true,
            lockedSubCampaigns,
            lockedChannels,
            role,
            region,
            disabledChannelsKeys
        );
        dispatch(updateBudget.setIsAutoDistributed(false));
        dispatch(updateBudget.channel(newAmounts));
    };

    const disableChannel = key =>
        setDisabledChannelsKeys([...disabledChannelsKeys, key]);
    const enableChannel = (key, isLockedStatus) => {
        const lockedAndExcludedChannels = getMergedChannelKeys(
            lockedChannels,
            disabledChannelsKeys
        );
        if (
            isAllChannelsLocked(lockedAndExcludedChannels, budget.channels) &&
            !isLockedStatus
        ) {
            updateState(key);
        }
        setDisabledChannelsKeys(disabledChannelsKeys.filter(k => k !== key));
    };

    const buildChannels = config => {
        return config ? (
            <Channel
                toggleEditing={toggleEditing}
                disableChannel={disableChannel}
                editedChannel={editedChannel}
                key={config.key}
                config={config}
                subCampaigns={filterSubs(config.subCampaigns, subCampaigns)}
                disabledChannelsKeys={disabledChannelsKeys}
            />
        ) : null;
    };

    const buildDisabledChannels = config => {
        const isLocked = existsInArray(config.key, lockedChannels);
        const iconStyle = {
            backgroundColor: config.style.primaryColor,
            boxShadow: config.style.shadowColor
        };

        return config ? (
            <Layout key={config.key} vAlign="center">
                <Card className={styles.disabledChannel}>
                    <Layout
                        hAlign="center"
                        vAlign="center"
                        style={iconStyle}
                        className={styles.miniChannelIconWrapper}>
                        <Icon src={config.icon} size="2" />
                    </Layout>
                    <Layout
                        vAlign="center"
                        className={styles.disabledChannelText}>
                        <Text type="button">{config.name}</Text>
                    </Layout>
                    <Layout vAlign="center">
                        <Button
                            id={idBuilder(BUDGET_ENABLE_CHANNEL, config.key)}
                            className={styles.addIcon}
                            onClick={() => enableChannel(config.key, isLocked)}>
                            <Icon src={plusSvg} size={3} />
                        </Button>
                    </Layout>
                </Card>
            </Layout>
        ) : null;
    };

    const channelComps = channels
        .filter(channel => !disabledChannelsKeys.includes(channel.key))
        .map(buildChannels);

    const disableChannelComps = channels
        .filter(channel => disabledChannelsKeys.includes(channel.key))
        .map(buildDisabledChannels);

    useEffect(() => {
        if (hasLockedChannelsBudget) {
            dispatch(setIsValid(false));
            const newChannelAmounts = getNewChannelAmountsBasedOnLockedStatus(
                budget,
                lockedChannels,
                role,
                region,
                lockedSubCampaigns
            );
            dispatch(updateBudget.channel(newChannelAmounts));
        } else if (hasLockedSubsBudget) {
            dispatch(setIsValid(false));
            const newAmounts = getNewSubAmountsBasedOnLockedStatus(
                budget,
                lockedSubCampaigns
            );
            dispatch(updateBudget.subCampaign(newAmounts));
        }
    }, []);

    return (
        <Layout flexDirection="column">
            <div>{channelComps}</div>
            {disableChannelComps.length ? (
                <Layout
                    flexDirection="column"
                    className={styles.disabledChannels}>
                    <Layout
                        flexDirection="column"
                        className={styles.disabledChannelsCaption}>
                        <Text type="button">Want to add more products?</Text>
                    </Layout>
                    <Layout flexDirection="row">{disableChannelComps}</Layout>
                </Layout>
            ) : null}
        </Layout>
    );
};

Distribution.propTypes = {
    config: PropTypes.object.isRequired
};

export default Distribution;
