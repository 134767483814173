import { createReducer } from '@reduxjs/toolkit';
import { SET_FLYOUT_NAV_STATUS } from '../constants';

const initialState = false;

const setFlyoutNavStatus = (state, action) => {
    const { flyoutNavStatus } = action.payload;
    return typeof flyoutNavStatus === 'boolean' ? flyoutNavStatus : state;
};

export const flyoutNavStatus = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_FLYOUT_NAV_STATUS, setFlyoutNavStatus)
        .addDefaultCase((state, _) => state);
});
