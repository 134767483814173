import React, { useEffect, useRef, useState } from 'react';
import { Text } from 'maslow';
import { strf } from '@lib';
import buildRadialProgressBar from './buildRadialProgressBar';
import eyePng from '@public/img/eye.png';
import styles from './styles.scss';

export default ({ data }) => {
    const { impressionsDelivered, totalBudgeted } = data;
    const container = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        buildRadialProgressBar(impressionsDelivered, totalBudgeted);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [container.current, width]);

    return (
        <div className={styles.deliveryProgress}>
            <div className={styles.contentBox}>
                <Text type="input" className={styles.boxTitle}>
                    <img className={styles.eyeImg} src={eyePng} />
                    Delivery Progress
                </Text>
                <Text className={styles.impressions}>
                    {strf(impressionsDelivered).commas().value()}
                </Text>
                <Text type="body" className={styles.impressionsCaption}>
                    Impressions Delivered
                </Text>
                <Text type="body" className={styles.totalBudgeted}>
                    of <span>{strf(totalBudgeted).commas().value()}</span>{' '}
                    budgeted
                </Text>
            </div>
            <div className={styles.chartWrap}>
                <div
                    className={styles.deliveryProgressChart}
                    ref={container}
                    id="deliveryProgressChart"></div>
            </div>
        </div>
    );
};
