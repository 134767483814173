import React from 'react';
import { getFeatures } from '@delivery/react';

import { Content } from 'maslow';
import shared from '@sharedStyle/page.scss';

const Reports = props => {
    const redirectTo = props.transition.router.stateService.go;
    const { planId } = props.transition.params();
    const { components } = getFeatures('reports', props, {
        planId,
        redirectTo
    });
    return <Content className={shared.pageWrapper}>{components}</Content>;
};

export default Reports;
