import React, { useReducer, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Input, Button, Layout } from 'maslow';
import { isEmptyString } from '@lib';
import requestHandlers from '@requestHandlers';
import style from '../login.scss';

import {
    LOGIN_NEW_PASSWORD,
    LOGIN_NEW_PASSWORD_CONFIRM,
    LOGIN_RESET_PASSWORD
} from '@config/idConstants';

const getInitialStates = ({ IDLE }) => {
    return {
        password1Status: IDLE,
        password2Status: IDLE,
        password1Error: '',
        password2Error: '',
        buttonDisabled: true,
        password1Entered: '',
        password2Entered: ''
    };
};
const reducer = (state, action) => {
    const { type, payload } = action;
    const { name, value } = payload;
    switch (type) {
        case 'updateValue':
            return {
                ...state,
                [name]: value
            };
        default:
            throw new Error('Error on Password Reset');
    }
};

const NewPassword = props => {
    const {
        loginService,
        token,
        email,
        errors,
        intervalTime,
        STATUSES,
        updateComponentState,
        DISPLAY_TEXT_STATES
    } = props;

    const [state, stateDispatch] = useReducer(
        reducer,
        getInitialStates(STATUSES)
    );
    const {
        password1Status,
        password2Status,
        password1Error,
        password2Error,
        buttonDisabled,
        password1Entered,
        password2Entered
    } = state;

    const updateStateValue = (name, value) => {
        stateDispatch({
            type: 'updateValue',
            payload: { name, value }
        });
    };

    useEffect(() => {
        if (password2Entered !== '') {
            checkIfPasswordsMatch();
        }
    }, [password1Entered, password2Entered]);

    const checkIfPasswordsMatch = () => {
        const hasValidPassword = loginService.hasValidPassword(
            password1Entered
        );
        if (password1Entered !== password2Entered) {
            updateStateValue('password2Status', STATUSES.ERROR);
            updateStateValue('password2Error', errors.passwordMatchError);
            updateStateValue('buttonDisabled', true);
        } else {
            updateStateValue('password2Status', STATUSES.VALID);
            if (hasValidPassword) updateStateValue('buttonDisabled', false);
        }
    };

    const password1DoneTyping = value => {
        updateStateValue('password1Entered', value);
        const hasValidPassword = loginService.hasValidPassword(value);
        if (isEmptyString(value)) {
            updateStateValue('password1Status', STATUSES.IDLE);
        } else if (hasValidPassword) {
            updateStateValue('password1Status', STATUSES.VALID);
        } else {
            updateStateValue('password1Status', STATUSES.ERROR);
            updateStateValue('password1Error', errors.passwordError);
            updateStateValue('buttonDisabled', true);
        }
    };

    const password2DoneTyping = value => {
        updateStateValue('password2Entered', value);
        if (isEmptyString(value)) {
            updateStateValue('password2Status', STATUSES.IDLE);
            updateStateValue('buttonDisabled', true);
        }
    };

    const resetPassword = () => {
        requestHandlers.auth
            .passwordReset(email, token, password1Entered, password2Entered)
            .then(({ status }) => {
                if (status === 200) {
                    showConfirmation();
                }
            })
            .catch(err => {
                console.error('error updating password', err);
            });
    };

    const showConfirmation = () => {
        updateComponentState(
            'displayTextState',
            DISPLAY_TEXT_STATES.PASSWORD_CHANGED
        );
        updateComponentState('showPasswordChangedPage', true);
        updateComponentState('newPassword', false);
    };

    return (
        <React.Fragment>
            <div>
                <Input
                    id={LOGIN_NEW_PASSWORD}
                    name="password"
                    theme="smallLine"
                    placeholder="New Password"
                    type="password"
                    status={password1Status}
                    errorText={password1Error}
                    doneTypingInterval={intervalTime}
                    doneTyping={password1DoneTyping}
                />
            </div>
            <div className={style.inputWrapper}>
                <Input
                    id={LOGIN_NEW_PASSWORD_CONFIRM}
                    name="confirm"
                    theme="smallLine"
                    placeholder="New Password Confirmation"
                    type="password"
                    status={password2Status}
                    errorText={password2Error}
                    doneTypingInterval={intervalTime}
                    doneTyping={password2DoneTyping}
                />
            </div>
            <Layout flexDirection="column">
                <Button
                    id={LOGIN_RESET_PASSWORD}
                    disabled={buttonDisabled}
                    onClick={resetPassword}>
                    {props.text}
                </Button>
            </Layout>
        </React.Fragment>
    );
};

NewPassword.propTypes = {
    loginService: PropTypes.object.isRequired,
    token: PropTypes.string,
    email: PropTypes.string,
    errors: PropTypes.object.isRequired,
    intervalTime: PropTypes.number.isRequired,
    STATUSES: PropTypes.object.isRequired,
    text: PropTypes.string.isRequired,
    updateComponentState: PropTypes.func.isRequired,
    DISPLAY_TEXT_STATES: PropTypes.object.isRequired
};

export default NewPassword;
