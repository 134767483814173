import { POLITICAL, COMMERCIAL } from '@config';

export const scheduleConfig = {
    rules: {
        // dates are inclusive of start/end date
        [POLITICAL]: {
            minPlanDuration: 2,
            // future days are used to to ensure disable dates uses inclusive date range
            futureDaysToDisable: 1
        },
        [COMMERCIAL]: {
            // duration is inclusive
            minPlanDuration: 5,
            // future days are used to to ensure disable dates uses inclusive date range
            futureDaysToDisable: 3
        },
        numberOfMonthsToShow: 2,
        // after the following hour in the timezone switch to next available day
        boundTimeZone: 'America/New_York',
        boundHour: 17,

        // the max plan duration is in days
        maxPlanDuration: 365,
        firstDayOfWeek: 1,
        maxMonths: 17,
        maxWeeks: 99
    },
    errors: {
        min: {
            [COMMERCIAL]: "Campaign duration can't be less than 5 days",
            [POLITICAL]: "Campaign duration can't be less than 3 days"
        },
        max: {
            [COMMERCIAL]: "Campaign duration can't be longer than 12 months",
            [POLITICAL]: "Campaign duration can't be longer than 12 months"
        }
    }
};
