import React, { useEffect, useState } from 'react';

import { UISref } from '@uirouter/react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import requestHandlers from '@requestHandlers/';
import { Layout, Text, Icon } from 'maslow';
import { WarningPopup } from '@components/common';
import { isArray } from '@lib/';
import { reMount } from '@components/hoc';
import { setSkipUpload, setDestination } from '@state/creative/actions/';
import {
    budgetConfigSelector,
    creativeSelector,
    getSCWithBudgetByNameSelector,
    uiSelector,
    hasPermissionSelector,
    creativeUrlsSelector,
    creativeProductsSelector,
    a4PlanIdSelector
} from '@selectors/';
import { getCreativesObject } from '@services/creative';

import { Header } from './Header';
import { Footer } from './Footer';
import { CreativeInput } from './CreativeInput';
import { FileManager } from './FileManager';
import DestinationUrl from './DestinationUrl';
import Dropzone from './Dropzone';

import arrowRight from '@public/img/caret-right.svg';
import creativeUploadSvg from '@public/img/creativeUpload.svg';
import creativeUploadMiniSvg from '@public/img/creative-upload-mini.svg';
import { LINEAR_TV, AD_MESSENGER } from '@config';
import featuresManager from '@featuresManager';
import styles from './styles.scss';
import { adSpecsPdf, POPUP_STEPS } from '@config/config.creative';

import {
    CREATIVE_SKIP_UPLOAD,
    CREATIVE_EMPTY_DROPZONE,
    CREATIVE_MINI_DROPZONE,
    CREATIVE_PUBLISH_EDITS,
    CREATIVE_RETURN_TO_PLAN_LIST,
    CREATIVE_CANCEL_EDITS,
    CREATIVE_RESUME_EDITS
} from '@config/idConstants';

import { INTERNAL, EXTERNAL } from '@config';

export const publishPayloadSelector = createSelector(
    a4PlanIdSelector,
    creativeUrlsSelector,
    creativeProductsSelector,
    (id, urls, products) => {
        return getCreativesObject(id || 0, urls, products);
    }
);

export default function (version, feature) {
    const Creative = props => {
        const dispatch = useDispatch();
        const [popupStep, setPopupStep] = useState(POPUP_STEPS.HIDDEN);
        const { total, subCampaigns } = useSelector(budgetConfigSelector);
        const { skipUpload: isUploadSkipped, files } = useSelector(
            creativeSelector
        );
        const adMessengerOnlyBudget = useSelector(
            getSCWithBudgetByNameSelector(AD_MESSENGER)
        );
        const { isEditCreatives = false } = useSelector(uiSelector);
        const hasSkipUploadOption = useSelector(
            hasPermissionSelector('mediaplan.creativeoptional')
        );
        const hasCreativeAdSpecsExternalPdf = useSelector(
            hasPermissionSelector('mediaplan.creativeadspecsexternalpdf')
        );
        const hasCreativeAdSpecsInternalPdf = useSelector(
            hasPermissionSelector('mediaplan.creativeadspecsinternalpdf')
        );
        const publishPayload = useSelector(publishPayloadSelector);

        const [role] = featuresManager.getCustomFilterState('role');
        const [region] = featuresManager.getCustomFilterState('region');
        const [type] = featuresManager.getCustomFilterState('type');
        const effectiveUserPermissions = [role, region, type];

        const { routesTo, creativeText, creativeUrls } = feature.data;
        const editStatus = isEditCreatives ? 'EDIT' : 'NEW';

        const determineNextRoute = () => {
            return routesTo.mediaPlan.to;
        };

        const buildCreativeUrl = urlArr => {
            return (
                isArray(urlArr) &&
                urlArr.map(({ name, label, placeholder, errorText }) => {
                    if (
                        subCampaigns.hasOwnProperty(name) &&
                        subCampaigns[name].budget > 0
                    ) {
                        return (
                            <CreativeInput
                                key={name}
                                name={name}
                                label={label}
                                placeholder={placeholder}
                                errorText={errorText}
                            />
                        );
                    }
                })
            );
        };

        const buildSkipUploadLink = () => {
            const renderArrowIcon = () => {
                const IconComponent = reMount(Icon);
                return <IconComponent src={arrowRight} size="3" />;
            };

            return hasSkipUploadOption && !isEditCreatives ? (
                <Layout className={styles.skipLinkWrapper} hAlign="center">
                    <div id={CREATIVE_SKIP_UPLOAD} onClick={updateSkipUpload}>
                        <UISref to={determineNextRoute(LINEAR_TV)}>
                            <Layout
                                flexDirection="row"
                                vAlign="center"
                                className={styles.skipLinkContainer}>
                                <Text
                                    type="body"
                                    className={styles.skipLinkText}>
                                    Continue without uploading
                                </Text>

                                {renderArrowIcon()}
                            </Layout>
                        </UISref>
                    </div>
                </Layout>
            ) : null;
        };

        const updateSkipUpload = () => {
            dispatch(setSkipUpload(true));
        };

        let showDestinationUrl = Object.keys(subCampaigns).some(subCampaign => {
            return (
                subCampaign !== LINEAR_TV &&
                subCampaign !== AD_MESSENGER &&
                subCampaigns[subCampaign].budget > 0 &&
                subCampaigns[AD_MESSENGER].budget < total
            );
        });

        useEffect(() => {
            if (!showDestinationUrl) dispatch(setDestination(''));
        }, []);

        const renderEmptyDropzone = () => (
            <>
                <Layout
                    flexDirection="column"
                    hAlign="center"
                    className={styles.dropzoneWrapper}>
                    <Dropzone>
                        <Layout
                            flexDirection="column"
                            className={styles.emptyDropzoneWrapper}>
                            <Layout
                                id={CREATIVE_EMPTY_DROPZONE}
                                flexDirection="column"
                                hAlign="center"
                                vAlign="center"
                                className={styles.emptyContainer}>
                                <Icon
                                    src={creativeUploadSvg}
                                    size="15"
                                    className={styles.creativeUploadIcon}
                                />
                                <Text type="h5" className={styles.title}>
                                    {creativeText[editStatus].dropzoneTitle}
                                </Text>
                                <Text type="h5" className={styles.subTitle}>
                                    or <a>browse</a> to choose
                                </Text>
                            </Layout>
                        </Layout>
                    </Dropzone>
                </Layout>
                {buildSkipUploadLink()}
            </>
        );

        const buildMiniDropzone = () => (
            <Layout
                flexDirection="column"
                hAlign="center"
                className={styles.dropzoneWrapper}>
                <Dropzone>
                    <Layout
                        flexDirection="column"
                        className={styles.dropzoneCaptionWrapper}>
                        <Layout
                            id={CREATIVE_MINI_DROPZONE}
                            flexDirection="column"
                            hAlign="center"
                            vAlign="center"
                            className={styles.emptyContainer}>
                            <Icon src={creativeUploadMiniSvg} size="6" />
                            <Text type="body" className={styles.title}>
                                {creativeText[editStatus].dropzoneTitle}
                            </Text>
                            <Text type="body" className={styles.subTitle}>
                                or <a>browse</a> to choose
                            </Text>
                        </Layout>
                    </Layout>
                </Dropzone>
                <Layout flexDirection="column" className={styles.urlWrapper}>
                    <Layout flexDirection="column" vAlign="center">
                        <Layout
                            flexDirection="column"
                            vAlign="center"
                            hAlign="flex-start"
                            flex="1 1 50%"
                            flexWrap="wrap"
                            fillParent>
                            {showDestinationUrl && <DestinationUrl />}

                            {buildCreativeUrl(creativeUrls)}
                        </Layout>
                    </Layout>
                </Layout>
            </Layout>
        );

        const buildUrlOnlyCard = () => (
            <>
                <Layout
                    flexDirection="column"
                    hAlign="center"
                    className={styles.dropzoneWrapper}>
                    <Layout
                        flexDirection="column"
                        className={styles.urlWrapper}>
                        <Layout flexDirection="column" vAlign="center">
                            <Layout
                                flexDirection="column"
                                vAlign="center"
                                hAlign="flex-start"
                                flex="1 1 100%"
                                flexWrap="wrap"
                                fillParent>
                                {buildCreativeUrl(creativeUrls)}
                            </Layout>
                        </Layout>
                    </Layout>
                </Layout>
                {isEditCreatives && <Footer showPopup={showPopup} />}
                {buildSkipUploadLink()}
            </>
        );

        //Popup Details
        const showPopup = popupStep => {
            setPopupStep(popupStep);
        };

        const publishCreatives = () => {
            requestHandlers.creative
                .publish(publishPayload)
                .then(() => setPopupStep(POPUP_STEPS.PUBLISH_CONFIRMATION));
        };
        const returnToPlanList = () => {
            props.transition.router.stateService.go('app.main.plans');
        };
        const hidePopup = () => setPopupStep(POPUP_STEPS.HIDDEN);

        const popupSettings = {
            publishQuery: {
                text: 'Publish campaign edits?',
                showPopup: true,
                showSuccessIcon: false,
                button1: {
                    text: 'Publish',
                    theme: 'primary',
                    id: CREATIVE_PUBLISH_EDITS,
                    onClick: publishCreatives
                },
                button2: {
                    text: 'Keep Editing',
                    theme: 'outline',
                    id: CREATIVE_CANCEL_EDITS,
                    onClick: hidePopup
                }
            },
            publishConfirmation: {
                text: 'Changes published!',
                subText:
                    'Please wait up to 72 hours for changes to be reflected in reporting.',
                showPopup: true,
                showSuccessIcon: true,
                button1: {
                    text: 'Back to Plan List',
                    id: CREATIVE_RETURN_TO_PLAN_LIST,
                    onClick: returnToPlanList
                }
            },
            cancelQuery: {
                showPopup: true,
                showSuccessIcon: false,
                text:
                    'Do you wish to continue without publishing these changes?',
                button1: {
                    text: 'Go to Plan List',
                    theme: 'primary',
                    id: CREATIVE_RETURN_TO_PLAN_LIST,
                    onClick: returnToPlanList
                },
                button2: {
                    text: 'Keep Editing',
                    theme: 'outline',
                    id: CREATIVE_RESUME_EDITS,
                    onClick: hidePopup
                }
            },
            hidden: {
                text: '',
                showPopup: false
            }
        };

        const renderFileManager = () => (
            <Layout className={styles.fileManagerOuterWrapper}>
                <Layout
                    flexDirection="row"
                    className={styles.fileManagerWrapper}>
                    <Layout
                        flexDirection="column"
                        className={styles.miniDropzoneWrapper}>
                        {buildMiniDropzone()}
                    </Layout>
                    <Layout
                        flexDirection="column"
                        className={styles.fileManager}>
                        <FileManager isEditCreatives={isEditCreatives} />
                    </Layout>
                </Layout>
                {isEditCreatives && <Footer showPopup={showPopup} />}
            </Layout>
        );

        const renderContent = () => {
            if (files.length) return renderFileManager();
            if (adMessengerOnlyBudget) return buildUrlOnlyCard();
            return renderEmptyDropzone();
        };

        const getPdfLink = () => {
            if (hasCreativeAdSpecsExternalPdf) return adSpecsPdf[EXTERNAL];
            if (hasCreativeAdSpecsInternalPdf) return adSpecsPdf[INTERNAL];
            return null;
        };

        const innerPageWrapperStyle = files.length
            ? styles.innerPageWrapperContent
            : '';
        return (
            <Layout flexDirection="column" className={styles.pageWrapper}>
                <Layout
                    flexDirection="column"
                    className={[
                        styles.innerPageWrapper,
                        innerPageWrapperStyle
                    ].join(' ')}>
                    <Layout
                        flexDirection="row"
                        className={styles.headerWrapper}>
                        <Header
                            editStatus={editStatus}
                            creativeText={creativeText}
                            pdfLink={getPdfLink()}
                        />
                    </Layout>
                    {renderContent()}
                    <WarningPopup
                        settings={popupSettings[popupStep]}
                        setPopupStep={setPopupStep}
                    />
                </Layout>
            </Layout>
        );
    };

    return Creative;
}
