export default {
    name: 'Schedule', // Component name
    description: 'Scheduling feature',
    library: 'react',
    versions: {
        prod: '1.1.0',
        sim: '1.1.0',
        ltd: '1.1.0',
        beta: '1.1.0',
        alpha: '1.1.0'
    },
    states: ['plan'],
    sections: ['schedule'],
    permissions: [],
    data: {
        defaultNumberOfMonths: 2
    },
    attributes: {},
    modifications: {}
};
