export const LOCATION_TYPES = {
    STATES: 'States',
    ZIPCODE: 'Zipcode'
};

const hasSmallBrowserHeight = window.innerHeight <= 800;
const miniMapHeight = hasSmallBrowserHeight ? 120 : 140;
const miniMapWidth = hasSmallBrowserHeight ? 190 : 212;

const defaultStateStyle = {
        fillColor: 'white',
        weight: 2,
        opacity: 1,
        color: '#D5E1FF',
        fillOpacity: 1
    },
    selectedStateStyle = {
        fillColor: '#2E5EFF',
        weight: 2,
        opacity: 1,
        fillOpacity: 0.9,
        color: 'white'
    },
    selectedLocationStyle = {
        fillColor: '#2E5EFF',
        opacity: 0.3,
        color: '#2E5EFF',
        fillOpacity: 1
    },
    selectedZipStyle = {
        radius: 4,
        fillColor: '#2E5EFF',
        color: '#2E5EFF',
        weight: 0.3,
        opacity: 1,
        fillOpacity: 1
    },
    defaultStateBooking = {
        fillColor: 'white',
        weight: 2,
        opacity: 1,
        color: 'grey',
        fillOpacity: 1
    },
    selectedStateBooking = {
        fillColor: '#2E5EFF',
        weight: 2,
        opacity: 1,
        color: '#2E5EFF',
        fillOpacity: 1
    },
    defaultLabelStyle = 'fill: rgba(0, 0, 0, 0.2); font: bold 12px sans-serif',
    selectedLabelStyle = 'fill: white; font: bold 12px sans-serif',
    wrapperStyle = {
        height: '91%',
        background: 'rgba(54, 25, 25, 0.00004)',
        zIndex: 2,
        width: '100%'
    },
    wrapperAlaskaMapStyle = {
        position: 'absolute',
        bottom: '10%',
        right: window.innerWidth <= 1024 ? '40%' : '45%',
        width: miniMapWidth,
        height: miniMapHeight,
        borderRadius: 7,
        background: 'rgba(54, 25, 25, 0.00004)',
        border: '3px solid #FFFFFF',
        boxShadow: '0 2px 6px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.05)',
        zIndex: 1
    },
    wrapperHawaiiMapStyle = {
        position: 'absolute',
        left: window.innerWidth <= 1024 ? '62%' : '57%',
        bottom: '10%',
        width: miniMapWidth,
        height: miniMapHeight,
        background: 'rgba(54, 25, 25, 0.00004)',
        border: '3px solid #FFFFFF',
        borderRadius: 7,
        boxShadow: '0 2px 6px 0 rgba(0,0,0,0.1), 0 1px 3px 0 rgba(0,0,0,0.05)',
        zIndex: 1
    },
    hiddenUsaWrapper = {
        position: 'absolute',
        left: -99999,
        width: 1024,
        height: 600,
        visibility: 'hidden',
        overflow: 'hidden'
    },
    hiddenAlaskaWrapper = {
        position: 'absolute',
        left: -9999,
        width: 320,
        height: 300,
        visibility: 'hidden'
    },
    hiddenHawaiiWrapper = {
        position: 'absolute',
        left: -9999,
        width: 300,
        height: 250,
        visibility: 'hidden'
    };

export const ALASKA = {
    code: '0400000US02',
    initials: 'ak'
};

export const HAWAII = {
    code: '0400000US15',
    initials: 'hi'
};

export const markerSettings = {
    buildSvg: (style, name) =>
        `data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="40" height="30" ><text x="0" y="30"  style="${style}">${name}</text></svg>`,
    iconSize: [40, 30]
};

export const maps = [
    {
        mapRegion: 'ALL',
        latLng: [33.0902, -94.7129],
        zoom: null,
        minZoom: 3.75,
        maxZoom: 8,
        zoomControl: true,
        keyboard: true,
        dragging: true,
        scrollWheelZoom: true,
        doubleClickZoom: false,
        zoomAnimation: true,
        animate: true,
        touchZoom: true,
        maxBounds: [
            [15.1492, -135.3326],
            [48.5014, -55.0709]
        ],
        id: 'usa',
        styles: {
            initialStateStyle: defaultStateStyle,
            defaultStateStyle,
            selectedStateStyle,
            selectedLocationStyle,
            selectedZipStyle,
            defaultLabelStyle,
            selectedLabelStyle,
            wrapper: wrapperStyle
        },
        labels: true,
        labelAnchor: [0, 35],
        image: false
    },
    {
        mapRegion: 'ALL',
        latLng: [37.0902, -95.7129],
        zoom: 4.6,
        minZoom: 4.6,
        maxZoom: 6.4,
        zoomControl: true,
        keyboard: false,
        dragging: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        animate: false,
        touchZoom: false,
        maxBounds: null,
        id: 'usaMps',
        styles: {
            initialStateStyle: selectedStateStyle,
            defaultStateStyle,
            selectedStateStyle,
            selectedLocationStyle,
            selectedZipStyle,
            defaultLabelStyle,
            selectedLabelStyle,
            wrapper: hiddenUsaWrapper
        },
        labels: true,
        labelAnchor: [35, 35],
        image: true
    },
    {
        mapRegion: 'ALL',
        latLng: [37.0902, -95.7129],
        zoom: 4.6,
        minZoom: 4.6,
        zoomControl: false,
        keyboard: false,
        dragging: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        animate: true,
        touchZoom: false,
        maxBounds: null,
        id: 'usaBooking',
        styles: {
            initialStateStyle: selectedStateBooking,
            defaultStateStyle: defaultStateBooking,
            selectedStateStyle: selectedStateBooking,
            selectedLocationStyle,
            selectedZipStyle,
            defaultLabelStyle,
            selectedLabelStyle,
            wrapper: hiddenUsaWrapper
        },
        labels: true,
        labelAnchor: [35, 35],
        image: true
    },
    {
        mapRegion: 'AK',
        latLng: [63.5, -155],
        zoom: hasSmallBrowserHeight ? 1.5 : 2,
        minZoom: hasSmallBrowserHeight ? 1.5 : 2,
        zoomControl: false,
        keyboard: false,
        dragging: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        animate: false,
        touchZoom: false,
        maxBounds: null,
        id: 'alaska',
        styles: {
            initialStateStyle: defaultStateStyle,
            defaultStateStyle,
            selectedStateStyle,
            selectedLocationStyle,
            selectedZipStyle,
            defaultLabelStyle,
            selectedLabelStyle,
            wrapper: wrapperAlaskaMapStyle
        },
        labels: true,
        labelAnchor: [0, 35],
        image: false
    },
    {
        mapRegion: 'AK',
        latLng: [64.731667, -152.47],
        zoom: 3,
        minZoom: 3,
        maxZoom: 6.4,
        zoomControl: false,
        keyboard: false,
        dragging: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        animate: false,
        touchZoom: false,
        maxBounds: null,
        id: 'alaskaMps',
        styles: {
            initialStateStyle: selectedStateStyle,
            defaultStateStyle,
            selectedStateStyle,
            selectedLocationStyle,
            selectedZipStyle,
            defaultLabelStyle,
            selectedLabelStyle,
            wrapper: hiddenAlaskaWrapper
        },
        labels: true,
        labelAnchor: [35, 35],
        image: true
    },
    {
        mapRegion: 'HI',
        latLng: [20.5, -157.58],
        zoom: hasSmallBrowserHeight ? 5 : 5.5,
        minZoom: hasSmallBrowserHeight ? 5 : 5.5,
        zoomControl: false,
        keyboard: false,
        dragging: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        animate: false,
        touchZoom: false,
        maxBounds: null,
        id: 'hawaii',
        styles: {
            initialStateStyle: defaultStateStyle,
            defaultStateStyle,
            selectedStateStyle,
            selectedLocationStyle,
            selectedZipStyle,
            defaultLabelStyle,
            selectedLabelStyle,
            wrapper: wrapperHawaiiMapStyle
        },
        labels: true,
        labelAnchor: [0, 35],
        image: false
    },
    {
        mapRegion: 'HI',
        latLng: [20.95027778, -157.25166667],
        zoom: 6,
        minZoom: 6,
        maxZoom: 6.4,
        zoomControl: false,
        keyboard: false,
        dragging: false,
        scrollWheelZoom: false,
        doubleClickZoom: false,
        zoomAnimation: false,
        animate: false,
        touchZoom: false,
        maxBounds: null,
        id: 'hawaiiMps',
        styles: {
            initialStateStyle: selectedStateStyle,
            defaultStateStyle,
            selectedStateStyle,
            selectedLocationStyle,
            selectedZipStyle,
            defaultLabelStyle,
            selectedLabelStyle,
            wrapper: hiddenHawaiiWrapper
        },
        labels: true,
        labelAnchor: [50, 50],
        image: true
    }
];
