// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".popupContentsWrapper___3bbNv {\n  padding: 20px 16px;\n  height: auto;\n  display: flex;\n  justify-content: space-between; }\n\n.popupText___23p29 {\n  font-size: 2rem;\n  line-height: unset;\n  text-align: center;\n  padding-bottom: 50px; }\n\n.popupSubText___15ISh {\n  margin-top: 20px;\n  text-align: center; }\n\n.popupButtonWrapper___Xw4Sv {\n  width: 100%;\n  padding-top: 50px; }\n  .popupButtonWrapper___Xw4Sv button {\n    width: 100%; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/common/WarningPopup/styles.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAEA;EACI,kBCFyB;EDGzB,YAAY;EACZ,aAAa;EACb,8BAA8B,EAAA;;AAGlC;EACI,eAAe;EACf,kBAAkB;EAClB,kBAAkB;EAClB,oBAAoB,EAAA;;AAGxB;EACI,gBAAgB;EAChB,kBAAkB,EAAA;;AAGtB;EACI,WAAW;EACX,iBAAiB,EAAA;EAFrB;IAKQ,WAAW,EAAA","file":"styles.scss","sourcesContent":["@import '~@scss/settings/baseline';\n\n.popupContentsWrapper {\n    padding: 20px $baseline-x2;\n    height: auto;\n    display: flex;\n    justify-content: space-between;\n}\n\n.popupText {\n    font-size: 2rem;\n    line-height: unset;\n    text-align: center;\n    padding-bottom: 50px;\n}\n\n.popupSubText {\n    margin-top: 20px;\n    text-align: center;\n}\n\n.popupButtonWrapper {\n    width: 100%;\n    padding-top: 50px;\n\n    & button {\n        width: 100%;\n    }\n}\n","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"popupContentsWrapper": "popupContentsWrapper___3bbNv",
	"popupText": "popupText___23p29",
	"popupSubText": "popupSubText___15ISh",
	"popupButtonWrapper": "popupButtonWrapper___Xw4Sv"
};
module.exports = exports;
