import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { removeAllExcludedNetworks } from '@state/networks/actions/excludedNetworks';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { Text, Icon, Layout } from 'maslow';
import minusIcon from '@public/img/minus-selector.svg';
import closeIcon from '@public/img/close.svg';
import styles from '../styles.scss';
import { SelectedNetwork } from '../SelectedNetwork';

import { PLAN_STATUSES } from '@config/config.planStatus';
import { NETWORKS_SUMMARY_CLEAR_SELECTED } from '@config/idConstants';

const Component = props => {
    const { excludedNetworks, removeAllExcludedNetworks } = props;

    const clearExcludedNetwroks = () => {
        props.removeAllExcludedNetworks();
        props.setCampaignRouterId(null);
        props.setPlanStatus(PLAN_STATUSES.UNSAVED);
    };

    const renderNetworks = () => {
        return excludedNetworks.map((excludedNetwork, i) => {
            return (
                <SelectedNetwork
                    key={i}
                    excludedNetwork={excludedNetwork}
                    minusIcon={minusIcon}
                />
            );
        });
    };

    const renderNetworkTitle = () => {
        if (excludedNetworks.length && excludedNetworks.length > 0) {
            return (
                <Layout
                    flexDirection="row"
                    hAlign="space-between"
                    vAlign="center"
                    className={styles.networksSummarySelections}>
                    <Text> Networks </Text>

                    <Layout
                        id={NETWORKS_SUMMARY_CLEAR_SELECTED}
                        flexDirection="row"
                        vAlign="center"
                        className={styles.selectedNetworksClearWrapper}
                        onClick={clearExcludedNetwroks}>
                        <Text
                            type="body"
                            className={styles.selectedNetworksClearText}>
                            Clear
                        </Text>

                        <Icon
                            src={closeIcon}
                            className={styles.selectedNetworksCloseIcon}
                            size="2"
                        />
                    </Layout>
                </Layout>
            );
        } else {
            return (
                <div className={styles.networksSummaryBody}>
                    <Text type="body">
                        {' '}
                        Choose networks you would like to exclude from your
                        media plan.{' '}
                    </Text>
                </div>
            );
        }
    };

    return (
        <div className={styles.networksSummaryWrapper}>
            <Layout className={styles.networksSummaryHeader}>
                <Text type="h5"> Exclusions </Text>
            </Layout>

            {renderNetworkTitle()}

            {renderNetworks()}
        </div>
    );
};

Component.propTypes = {
    excludedNetworks: PropTypes.array.isRequired,
    removeAllExcludedNetworks: PropTypes.func.isRequired,
    setCampaignRouterId: PropTypes.func.isRequired,
    setPlanStatus: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
    excludedNetworks: state.networks.excludedNetworks
});

const mapDispatchToProps = dispatch => ({
    removeAllExcludedNetworks: () => dispatch(removeAllExcludedNetworks()),
    setCampaignRouterId: campaignRouterId =>
        dispatch(setCampaignRouterId(campaignRouterId)),
    setPlanStatus: planStatus => dispatch(setPlanStatus(planStatus))
});

export const SelectedNetworks = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);
