import React from 'react';
import { useSelector } from 'react-redux';
import { strf } from '@lib';
import {
    getFullWeeks,
    getWeeksLabel,
    getDatesText,
    mapHiatusWeeksDataForUi
} from '@services';
import budgetSvg from '@public/img/budget.svg';
import scheduleSvg from '@public/img/schedule.svg';
import cpmSvg from '@public/img/cpm.svg';
import impressionsSvg from '@public/img/impressions.svg';
import rightArrowsSvg from '@public/img/right-arrows.svg';
import { Text, Layout, Icon, Spinner } from 'maslow';
import style from './schedule.scss';
import { LINEAR_TV } from '@config';
import {
    budgetConfigSelector,
    totalImpressionSelector,
    totalCpmSelector,
    scheduleSelector,
    totalSelector,
    mediaPlanSelector
} from '@selectors/';
import { getFormattedCpm } from '@services/budget';

const buildHiatusWeeks = hiatusWeeks => {
    return hiatusWeeks.map((week, i) => {
        return (
            <Text type="input" key={i} className={style.weekText}>
                {week.from} - {week.to}
            </Text>
        );
    });
};

const buildHiatusSelections = hiatusWeeks => {
    const mappedHiatusWeeks = mapHiatusWeeksDataForUi(hiatusWeeks);
    const hiatusYears = Object.keys(mappedHiatusWeeks);

    return hiatusYears.map((year, i) => {
        return (
            <Layout key={i} flexDirection="row">
                <Layout flexDirection="column" flex>
                    <Layout flexDirection="row">
                        <Layout
                            flexDirection="column"
                            vAlign="center"
                            className={style.yearColumn}>
                            <Layout flexDirection="row">
                                <Text type="input" className={style.yearText}>
                                    {year}
                                </Text>
                                <Icon src={rightArrowsSvg} />
                            </Layout>
                        </Layout>
                        <Layout flexDirection="column" flex>
                            <Layout
                                flexDirection="row"
                                className={style.weeksWrapper}>
                                {buildHiatusWeeks(mappedHiatusWeeks[year])}
                            </Layout>
                        </Layout>
                    </Layout>
                </Layout>
            </Layout>
        );
    });
};

const buildHiatusSection = hiatusWeeks => {
    if (hiatusWeeks.length < 1) return null;
    return (
        <Layout flexDirection="column" className={style.hiatusWeek}>
            <Text type="body" className={style.hiatusWeekTitle}>
                Hiatus weeks:
            </Text>
            {buildHiatusSelections(hiatusWeeks)}
        </Layout>
    );
};

const MediaPlanSchedule = () => {
    const { hasTvDetails } = useSelector(mediaPlanSelector);
    const budgetTotal = useSelector(totalSelector);
    const { startDate, endDate, hiatusWeeks } = useSelector(scheduleSelector);

    const tvSubCampaign = useSelector(budgetConfigSelector).subCampaigns[
        LINEAR_TV
    ];
    const totalImpressions = useSelector(totalImpressionSelector);
    const totalCpm = useSelector(totalCpmSelector);

    const weeks = getFullWeeks(startDate, endDate);
    const weeksLabel = getWeeksLabel({ weeks });
    const { startDateText, endDateText } = getDatesText(
        startDate,
        endDate,
        true
    );
    const formattedTotalBudget = strf(budgetTotal)
        .commas()
        .prepend('$')
        .value();
    const formattedTotalImpressions = strf(totalImpressions).commas().value();
    const formattedTotalCpm = getFormattedCpm(totalCpm);
    const tvSpend = tvSubCampaign ? tvSubCampaign.budget : 0;
    const totalValueReceived = !hasTvDetails && tvSpend > 0 ? false : true;
    const weekStyle = weeks >= 10 ? style.weeks : style.week;

    return (
        <Layout flexDirection="row" className={style.wrapper}>
            <Layout
                flexDirection="column"
                className={style.title}
                id="pdf-content-schedule">
                <Layout flexDirection="row">
                    <img src={scheduleSvg} />
                    <Text type="body" className={style.iconLabel}>
                        Schedule
                    </Text>
                </Layout>

                <Layout
                    flexDirection="row"
                    className={style.dateWrapper}
                    vAlign="center">
                    <Text type="h3" className={style.flightDatesText}>
                        {startDateText}
                    </Text>
                    <div className={style.weekLine} />
                    <div className={style.weekCircle} />
                    <div>
                        <Text type="caption" className={weekStyle}>
                            {weeks}
                        </Text>
                    </div>
                    <Text type="caption">{weeksLabel}</Text>
                    <div className={style.weekLine} />
                    <Text
                        type="h3"
                        className={style.flightDatesText}
                        color="black">
                        {endDateText}
                    </Text>
                </Layout>
                {buildHiatusSection(hiatusWeeks)}
            </Layout>

            <Layout
                flexDirection="column"
                vAlign="center"
                className={style.rightSection}>
                <Layout flexDirection="row">
                    <img src={budgetSvg} />
                    <Text type="h5" className={style.iconLabel}>
                        {formattedTotalBudget}
                    </Text>
                </Layout>
                <Text type="caption" className={style.labelText}>
                    Budget
                </Text>
                <Layout flexDirection="row">
                    <img src={impressionsSvg} />
                    {!totalValueReceived && <Spinner />}
                    {totalValueReceived && (
                        <Text type="h5" className={style.iconLabel}>
                            {formattedTotalImpressions}
                        </Text>
                    )}
                </Layout>
                <Text type="caption" className={style.labelText}>
                    Impressions
                </Text>
                <Layout flexDirection="row">
                    <img src={cpmSvg} />
                    {!totalValueReceived && <Spinner />}
                    {totalValueReceived && (
                        <Text type="h5" className={style.iconLabel}>
                            {formattedTotalCpm}
                        </Text>
                    )}
                </Layout>
                <Text type="caption" className={style.labelText}>
                    CPM
                </Text>
            </Layout>
        </Layout>
    );
};

export default MediaPlanSchedule;
