import { store } from '@state';
import { setPlan } from '@state/rootActions';
import featuresManager from '@featuresManager';
import requestHandlers from '@requestHandlers';
import {
    setIsPlanOpened,
    updateLoadingScreen,
    resetLoadingScreen,
    setFlyoutNavStatus
} from '@state/ui/actions';
import { formatData } from '@services/reduxFormatters';
import { getSelectedAgeList } from '@services/audiencesService';
import {
    normalizeDistributionConfig,
    getDigitalCpmConfig,
    getImpressionModifierConfig
} from '@services/budget';
import { updateBudget } from '@state/budget/actions/';

export default [
    {
        name: 'app.plan',
        url: '/plan/:planId',
        abstract: true,
        resolve: [
            {
                token: 'planId',
                deps: ['$transition$'],
                resolveFn: trans => {
                    featuresManager.setFeatureState('plan');

                    const { planId } = trans.params();
                    const targetPage =
                        trans.targetState().identifier().name ||
                        trans.targetState().identifier();
                    store.dispatch(setFlyoutNavStatus(false));

                    if (planId !== 'new') {
                        store.dispatch(
                            updateLoadingScreen({
                                showLoadingScreen: true,
                                loadingText: 'Retrieving your plan details'
                            })
                        );
                        store.dispatch(setIsPlanOpened(true));

                        requestHandlers.plans
                            .getMediaPlan(planId)
                            .then(async response => {
                                const { data: planData } = response;

                                const {
                                    ui: { isEditCreatives },
                                    user
                                } = store.getState();

                                const [
                                    type
                                ] = featuresManager.getCustomFilterState(
                                    'type'
                                );

                                const updatedData = await formatData(
                                    {
                                        ...planData,
                                        isEditCreatives,
                                        user,
                                        planType: planData.type,
                                        type
                                    },
                                    {
                                        getDigitalCpmConfig,
                                        getImpressionModifierConfig,
                                        getCounts:
                                            requestHandlers.households.count,
                                        getNetworks:
                                            requestHandlers.networks.getNetworks
                                    }
                                );

                                const {
                                    campaignType,
                                    region
                                } = updatedData.plan.attributes;

                                featuresManager.setCustomFilterState('role', [
                                    campaignType
                                ]);

                                store.dispatch(setPlan(updatedData));

                                if (!updatedData.budget.distributionConfig) {
                                    requestHandlers.budget
                                        .getBudgetDistribution({
                                            campaignType,
                                            region,
                                            ages: getSelectedAgeList(
                                                updatedData.audience.audiences
                                            )
                                        })
                                        .then(({ data }) => {
                                            const normalizedDistribution = normalizeDistributionConfig(
                                                data
                                            );
                                            store.dispatch(
                                                updateBudget.saveDistributionConfig(
                                                    normalizedDistribution
                                                )
                                            );
                                        })
                                        .catch(console.error);
                                }

                                if (targetPage !== 'app.plan.mediaPlan')
                                    store.dispatch(resetLoadingScreen());

                                return response.data;
                            })
                            .catch(err => {
                                if (
                                    err.hasOwnProperty('response') &&
                                    err.response.status >= 400 &&
                                    err.response.status <= 499
                                ) {
                                    trans.router.stateService.go(
                                        'app.main.notfound'
                                    );
                                } else {
                                    console.error(err);
                                }
                            });
                    } else {
                        const targetState = trans.targetState();
                        if (
                            !store.getState().mediaPlan.a4PlanId &&
                            targetState.identifier() !== 'app.plan.campaign'
                        ) {
                            trans.router.stateService.reload('app.main.plans');
                            trans.router.stateService.go('app.main.plans');
                        }
                    }

                    return planId;
                }
            }
        ]
    }
];
