import { URLSchema } from '@config/validationSchemas';

export const isValidUrl = url => {
    const pattern = URLSchema;

    if (typeof url === 'string' && url !== undefined && url !== null)
        return url.match(pattern) ? true : false;
    else {
        return false;
    }
};
