export default {
    preload: [
        {
            target: '#match-progress-circle',
            src: '/public/img/custom_list/match-progress-circle.svg'
        },
        {
            target: '#person1-side',
            src: '/public/img/custom_list/person1.svg'
        },
        {
            target: '#person2-side',
            src: '/public/img/custom_list/person2.svg'
        },
        {
            target: '#person3-side',
            src: '/public/img/custom_list/person3.svg'
        },
        {
            target: '#person4-side',
            src: '/public/img/custom_list/person4.svg'
        },
        {
            target: '#match-progress-ex',
            src: '/public/img/custom_list/match-progress-exclamation.svg'
        },
        {
            target: '#person1-small',
            src: '/public/img/custom_list/person1.svg'
        },
        {
            target: '#person2-small',
            src: '/public/img/custom_list/person2.svg'
        },
        {
            target: '#person3-small',
            src: '/public/img/custom_list/person3.svg'
        },
        {
            target: '#person4-small',
            src: '/public/img/custom_list/person4.svg'
        },
        {
            target: '#match-progress-close',
            src: '/public/img/custom_list/match-progress-close.svg'
        },
        {
            target: '#white-lines',
            src: '/public/img/custom_list/match-progress-strokes-white.svg'
        },
        {
            target: '#transparent-lines',
            src: '/public/img/custom_list/match-progress-strokes-blue.svg'
        }
    ],
    layers: [
        {
            method: 'fadeOutPeopleAndLines',
            async: true
        },
        {
            method: 'fadeOutSmallPeopleAndLines'
        },
        {
            method: 'hideCloseButton'
        },
        {
            method: 'moveWhiteLines',
            async: true
        },
        {
            method: 'drawWhiteLines',
            args: { duration: 100 }
        },

        {
            method: 'drawExclamationMark',
            async: true
        },
        {
            method: 'darkenLargeCircle'
        },
        {
            method: 'darkenSmallCircle'
        },
        // {
        //     method: 'rotatePeople',
        //     async: true
        // },

        {
            method: 'showCrossIcon'
        }
    ]
};
