import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import * as d3 from 'd3';
import * as venn from 'venn.js';

import { vennSvgToPng } from '@lib';
import { greyOut, removeGrayinOut } from '@services/vennService';
import { addAudience, removeAudience } from '@state/audience/actions/audiences';
import { removeCombinations } from '@state/audience/actions/combinations';
import { addVennPngDataUrl } from '@state/audience/actions/vennPngDataUrl';
import { setCurrentAudienceIndex } from '@state/audience/actions/currentAudienceIndex';
import { AUDIENCE_BUILDER_SMALL_VENN } from '@config/idConstants';
import './styles.scss';
import { audiencesSelector, audienceSelector, pathSelector } from '@selectors';
import {
    COLORED,
    COLORED_EMPTY,
    INACTIVE,
    ORDER
} from '@config/config.multiAudiences';
import audienceRoutes from '@config/config.audienceRoutes';
import { getAudienceToRemove, buildVennSets } from '@services';

let _chart = null;
let _container = null;

export const BuilderVenn = ({ isState }) => {
    const dispatch = useDispatch();
    const audiences = useSelector(audiencesSelector);
    const { currentAudienceIndex, combinations } = useSelector(
        audienceSelector
    );
    const path = useSelector(pathSelector);

    useEffect(() => {
        const sets = buildVennSets(audiences);

        _chart = venn
            .VennDiagram()
            .width(150)
            .height(150)
            .styled(false)
            .orientation(-Math.PI / 2);

        _chart.orientationOrder((a, b) => ORDER[a.setid] - ORDER[b.setid]);

        const setColor = d => {
            const maxIndex = Math.max(...d.sets);

            return maxIndex < audiences.length ? 'coloredSet' : '';
        };

        _container = d3.select('#small-venn');
        _container.datum(sets).call(_chart);
        _container
            .selectAll('path')
            .attr('class', d => ('type' in d ? `${d.type}Set` : setColor(d)));
        _container.selectAll('g').on('click', clickOnSet);

        updateVenn();
        updateVennCircles();

        return () => {
            if (
                !isState(audienceRoutes.builder[path]) &&
                !isState(audienceRoutes.audiences[path]) &&
                !isState(audienceRoutes.custom[path])
            ) {
                _chart = null;
                _container = null;
                removeEmpty();

                vennSvgToPng(
                    document.querySelector('#small-venn svg'),
                    audiences
                ).then(result => {
                    dispatch(addVennPngDataUrl(result));
                });
            }
        };
    }, [audiences, currentAudienceIndex]);

    const updateVennCircles = () => {
        const combinationKeys = Object.keys(combinations);
        const hasCombinations = combinationKeys.length > 0;
        const audiencesLength = audiences.length - 1;

        _container.selectAll('g').each((d, i, n) => {
            const node = n[i];
            const maxIndex = Math.max(...d.sets);

            d3.select(node).attr('id', function (d) {
                return AUDIENCE_BUILDER_SMALL_VENN + '-' + d.sets.join('-');
            });

            if (hasCombinations) {
                const ref = d.sets.join(' + ');

                if (
                    !combinationKeys.includes(ref) &&
                    maxIndex <= audiencesLength
                ) {
                    greyOut(node);
                }
            } else {
                removeGrayinOut(node);
            }
        });
    };

    const updateVenn = () => {
        const sets = buildVennSets(audiences);

        _container.datum(sets).call(_chart);

        _container.selectAll('path').attr('class', (d, i, n) => {
            const node = n[i];
            let retainClass = false;
            d3.select(node.parentNode).each((d, i, n) => {
                const combinationKeys = Object.keys(combinations);
                const ref = d.sets.join(' + ');
                const maxIndex = Math.max(...d.sets);
                if (
                    combinationKeys.includes(ref) &&
                    maxIndex < audiences.length
                ) {
                    retainClass = true;
                }
            });
            if (retainClass) {
                return d3.select(node).attr('class');
            } else {
                return sets[i] && 'type' in sets[i] ? `${sets[i].type}Set` : '';
            }
        });
    };

    const removeEmpty = () => {
        const toRemove = getAudienceToRemove(audiences, 0);

        if (toRemove.indexOf(currentAudienceIndex) !== -1) {
            dispatch(setCurrentAudienceIndex(0));
            removeEmptyAudiences(toRemove);
        }
    };

    const removeEmptyAudiences = toRemove => {
        toRemove.forEach(index => dispatch(removeAudience(index)));
    };

    const addAudienceSet = () => {
        const currentLength = audiences.length;

        dispatch(removeCombinations());
        dispatch(addAudience());

        setTimeout(() => {
            dispatch(setCurrentAudienceIndex(currentLength));
        }, 100);
    };

    const onColoredClick = d => {
        const index = d.sets[0];
        dispatch(setCurrentAudienceIndex(index));
        setTimeout(() => {
            removeEmptyAudiences(getAudienceToRemove(audiences, index));
        }, 100);
    };

    const clickOnSet = d => {
        if (d.type === INACTIVE || d.sets.length > 1) return;
        if (d.type === COLORED || d.type === COLORED_EMPTY)
            return onColoredClick(d);

        addAudienceSet();
    };

    return <div id="small-venn" />;
};

BuilderVenn.propTypes = {
    isState: PropTypes.func.isRequired
};

export default BuilderVenn;
