import { ALASKA, HAWAII, markerSettings } from '@config/config.maps';
import { isArray } from '@lib';
import { validateArg } from '@lib';
import { memoize, cloneDeep } from 'lodash';

export const getStates = (mapRegion, data) => {
    if (!isArray(data)) throw new Error('second arg must be an array');
    switch (mapRegion) {
        case HAWAII.initials.toUpperCase():
            return data.filter(({ properties }) => {
                return properties.code === HAWAII.code;
            });

        case ALASKA.initials.toUpperCase():
            return data.filter(({ properties }) => {
                return properties.code === ALASKA.code;
            });
        default:
            return data.filter(({ properties }) => {
                return (
                    properties.code !== ALASKA.code &&
                    properties.code !== HAWAII.code
                );
            });
    }
};

export const getLocations = (mapRegion, data) => {
    if (!isArray(data)) throw new Error('second arg must be an array');
    const _data = cloneDeep(data);

    const newData = _data.map(item => {
        if (isArray(item.states))
            item.states = item.states.map(state => state.toUpperCase());
        return item;
    });
    switch (mapRegion) {
        case HAWAII.initials.toUpperCase():
            return newData.filter(({ states, name, code }) => {
                if (states)
                    return states.includes(HAWAII.initials.toUpperCase());
                if (name) return name.includes('(HI)');
                if (code) return code === HAWAII.code;
            });

        case ALASKA.initials.toUpperCase():
            return newData.filter(({ states, name, code }) => {
                if (states)
                    return states.includes(ALASKA.initials.toUpperCase());
                if (name) return name.includes('(AK)');
                if (code) return code === ALASKA.code;
            });
        default:
            return newData.filter(({ states, name, code }) => {
                if (states)
                    return (
                        !states.includes(ALASKA.initials.toUpperCase()) &&
                        !states.includes(HAWAII.initials.toUpperCase())
                    );
                if (name)
                    return !name.includes('(AK)') && !name.includes('(HI)');
                if (code) return code !== ALASKA.code && code !== HAWAII.code;
            });
    }
};

export const isSelected = (id, locations) => {
    if (!locations.length) return;
    const fn = memoize(() => {
        return locations.some(loc => {
            return loc.id === id;
        });
    });
    return fn();
};

export const layerExists = (geo, locationLayers) => {
    let alreadyExists = false;

    locationLayers.eachLayer(({ options }) => {
        if (options.id === geo.id) alreadyExists = true;
    });

    return alreadyExists;
};

export const layerZipDotExists = (geo, locationLayers) => {
    locationLayers.eachLayer(({ options }) => {
        if (options.id === geo.id && options.zipDots) return true;
    });
    return false;
};

export const buildMarkerSettings = (
    styles,
    labelAnchor,
    state,
    layerType,
    textLatLng,
    selected
) => {
    const { buildSvg, iconSize } = markerSettings;
    const labelStyle = selected
        ? styles.selectedLabelStyle
        : styles.defaultLabelStyle;

    return {
        iconUrl: buildSvg(labelStyle, state.name),
        iconSize,
        iconAnchor: labelAnchor,
        id: state.id,
        code: state.code,
        type: layerType,
        name: state.name,
        textLatLng,
        selected
    };
};

export const geoExists = (location, geoJson) => {
    if (!location || typeof location !== 'object')
        throw new Error('first arg must be an object with truthy value');
    if (!isArray(geoJson)) throw new Error('second arg must be an array');

    return geoJson.find(({ properties, id }) => {
        //To protect for old plans
        if (properties)
            return (
                properties &&
                properties.type === location.type &&
                properties.id === location.id
            );

        return id === location.id;
    });
};

export const getZoom = width => {
    validateArg(width, 'number');
    if (width <= 1100) return 3.75;
    if (width <= 1200) return 4.0;
    if (width <= 1350) return 4.25;
    return 4.5;
};
