import { createAction } from '@reduxjs/toolkit';
import {
    ADD_EXCLUDED_DAYPART,
    REMOVE_EXCLUDED_DAYPART,
    REMOVE_ALL_EXCLUDED_DAYPARTS
} from '../constants';

export const addExcludedDaypart = createAction(
    ADD_EXCLUDED_DAYPART,
    excludedDaypart => {
        return {
            payload: { excludedDaypart }
        };
    }
);

export const removeExcludedDaypart = createAction(REMOVE_EXCLUDED_DAYPART);

export const removeAllExcludedDayparts = createAction(
    REMOVE_ALL_EXCLUDED_DAYPARTS
);
