import { createAction } from '@reduxjs/toolkit';
import { TOGGLE_IS_AUDIENCE_SAVED } from '../constants';

export const toggleIsAudienceSaved = createAction(
    TOGGLE_IS_AUDIENCE_SAVED,
    isSaved => {
        return {
            payload: { isSaved }
        };
    }
);
