import React, { useState, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFlyoutNavStatus } from '@state/ui/actions';
import { Icon, Text, Button, Layout, Popup } from 'maslow';
import { userSelector } from '@selectors/';
import featuresManager from '@featuresManager';
import style from './mainNav.scss';
import logoSvg from '@public/img/Athena-R.svg';
import arrowSvg from '@public/img/UI-Kit-Arrow.svg';
import { idBuilder } from '@services/idBuilder';
import { getFilteredNavItems } from '@services/';
import config from '@config/config.flyout';
import { loginService } from '@services';

import {
    MAIN_NAV,
    MAIN_NAV_STAY,
    MAIN_NAV_LOGOUT,
    MAIN_NAV_CLOSE
} from '@config/idConstants';
import { useClickOutside } from '@hooks';

export default function (version, feature) {
    const MainNav = ({ transition: { router } }) => {
        const dispatch = useDispatch();
        const flyoutNavStatus = useSelector(state => state.ui.flyoutNavStatus);
        const { account } = useSelector(userSelector);
        const [isPopupActive, setPopupActive] = useState(false);
        const [type] = featuresManager.getCustomFilterState('type');
        const mainNavRef = useRef(null);
        const {
            account: { permissions }
        } = useSelector(userSelector);
        const ls = loginService();

        const getLink = ({ permissionList, to }) => {
            const permissionName = permissionList.find(permission =>
                permissions.includes(permission)
            );
            return to[permissionName];
        };

        const _setFlyoutNavStatus = () => {
            dispatch(setFlyoutNavStatus(!flyoutNavStatus));
        };

        const buildPrimaryNavItems = ({
            onClick,
            linkTo,
            to,
            text,
            icon,
            transOptions
        }) => {
            const onClickFunction = () => {
                if (onClick) onClick();
                if (linkTo) window.open(linkTo);
                if (to) router.stateService.go(to, null, transOptions);
            };
            return (
                <div
                    key={text}
                    className={style.item}
                    onClick={_setFlyoutNavStatus}>
                    <div className={style.item}>
                        <a
                            id={idBuilder(MAIN_NAV, text)}
                            onClick={onClickFunction}>
                            <Layout flexDirection="row" vAlign="flex-end">
                                <span className={style.iconMargin}>
                                    <Icon src={icon} size="3" />
                                </span>

                                <Text type="h4">{text}</Text>
                            </Layout>
                        </a>
                    </div>
                </div>
            );
        };

        const buildSecondaryNavItems = navItem => {
            const linkTo = navItem.permissionList
                ? getLink(navItem)
                : navItem.to[type];
            return linkTo ? (
                <div
                    id={idBuilder(MAIN_NAV, navItem.text)}
                    key={navItem.text}
                    onClick={_setFlyoutNavStatus}>
                    <a target="_blank" href={linkTo}>
                        <Text type="input">{navItem.text}</Text>
                    </a>
                </div>
            ) : null;
        };

        useClickOutside(
            mainNavRef,
            () => flyoutNavStatus && dispatch(setFlyoutNavStatus(false))
        );

        const buildAdminNavItems = () => {
            if (featuresManager.config.userRole === 'admin') {
                return config.adminNavItems.map(buildPrimaryNavItems);
            } else {
                return [];
            }
        };

        const openPopup = () => {
            _setFlyoutNavStatus();
            setPopupActive(true);
        };

        const closePopup = () => {
            setPopupActive(false);
            ls.logout(router);
        };

        const stayOnPage = () => {
            setPopupActive(false);
        };

        const buildLogoutPopup = () => {
            return (
                <Popup
                    show={isPopupActive}
                    width="374px"
                    height="140px"
                    backgroundColor="rgba(0, 0, 0, 0.75)">
                    <div className={style.popupContents}>
                        <Text type="h5">Are you sure you want to log out?</Text>
                    </div>

                    <Layout
                        flexDirection="row"
                        padding="0 80px"
                        vAlign="center"
                        hAlign="center">
                        <Layout flexDirection="column" fillParent>
                            <Button
                                id={MAIN_NAV_STAY}
                                theme="outline"
                                onClick={stayOnPage}>
                                Stay
                            </Button>
                        </Layout>

                        <Layout flexDirection="column" fillParent>
                            <Button
                                id={MAIN_NAV_LOGOUT}
                                theme="green"
                                onClick={closePopup}>
                                Log Out
                            </Button>
                        </Layout>
                    </Layout>
                </Popup>
            );
        };

        const buildInitialsLogo = name => {
            const initials = name
                .map(n => n[0])
                .join('')
                .toUpperCase();
            return (
                <div className={style.initialsLogo}>
                    <Text type="h5" className={style.initialsText}>
                        {initials}
                    </Text>
                </div>
            );
        };

        const buildUserInfo = () => {
            const { firstName = 'Athena', lastName = 'User', email } = account;
            return (
                <div className={style.userInfo}>
                    <Layout
                        flexDirection="row"
                        fillParent
                        vAlign="center"
                        hAlign="flex-start">
                        {buildInitialsLogo([firstName, lastName])}
                        <div>
                            <Layout
                                flexDirection="column"
                                fillParent
                                vAlign="center"
                                hAlign="space-around">
                                <Text type="h5" className={style.userName}>
                                    {`${firstName} ${lastName}`}
                                </Text>
                                <Text type="input" className={style.userEmail}>
                                    {email}
                                </Text>
                            </Layout>
                        </div>
                    </Layout>
                </div>
            );
        };

        const topNavItems = getFilteredNavItems(
            config.topNavItems,
            permissions
        ).map(buildPrimaryNavItems);

        const bottomNavItems = getFilteredNavItems(
            config.bottomNavItems,
            permissions,
            { openPopup }
        ).map(buildPrimaryNavItems);

        const secondaryNavItems = config.secondaryNavItems.map(
            buildSecondaryNavItems
        );
        const adminNavItems = buildAdminNavItems();
        const logoutPrompt = buildLogoutPopup();

        return (
            <div ref={mainNavRef} className={style.mainNav}>
                <div className={style.logoContainer}>
                    <Layout
                        flexDirection="row"
                        fillParent
                        vAlign="center"
                        hAlign="space-evenly">
                        <Layout
                            id={MAIN_NAV_CLOSE}
                            flexDirection="column"
                            vAlign="center"
                            className="clickable"
                            onClick={_setFlyoutNavStatus}>
                            <div className={style.logo}>
                                <Icon src={logoSvg} size="6" />
                            </div>

                            <Layout
                                className={style.logoClose}
                                flexDirection="row"
                                vAlign="center">
                                <div className={style.arrow}>
                                    <Icon src={arrowSvg} size="1" />
                                </div>

                                <div className={style.close}>
                                    <Text type="logo" color="white">
                                        close
                                    </Text>
                                </div>
                            </Layout>
                        </Layout>
                    </Layout>
                </div>

                <div className={style.primaryItems}>
                    {topNavItems}

                    <div className={style.separator}></div>

                    {bottomNavItems}

                    {adminNavItems}
                </div>

                <div>{logoutPrompt}</div>

                <div className={style.middleSpace}></div>
                <div className={style.secondaryItems}>{secondaryNavItems}</div>
                {buildUserInfo()}
            </div>
        );
    };
    return MainNav;
}
