import { createReducer } from '@reduxjs/toolkit';
import { SET_START_DATE } from '../constants';

const initialState = null;

const setStartDate = (state, action) => {
    const { startDate } = action.payload;
    return typeof startDate === 'object' ? startDate : state;
};

export const startDate = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_START_DATE, setStartDate)
        .addDefaultCase((state, _) => state);
});
