import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Text, Icon } from 'maslow';
import visaSvg from '@public/img/visa.svg';
import maestroSvg from '@public/img/maestro.svg';
import masterSvg from '@public/img/master.svg';
import amexSvg from '@public/img/amex.svg';

import PayForm from './PayForm';

import styles from './styles.scss';

const PayNow = props => {
    const { setPayError, cardHolderName, setCardHolderName } = props;

    return (
        <Layout flexDirection="column">
            <Layout
                flexDirection="row"
                vAlign="center"
                hAlign="space-between"
                className={styles.marginBottom}>
                <Text type="body" className={styles.title}>
                    Credit Card Information
                </Text>

                <Layout flexDirection="row">
                    <Icon src={visaSvg} size="4" />
                    <Icon
                        src={maestroSvg}
                        size="4"
                        className={styles.marginLeft}
                    />
                    <Icon
                        src={masterSvg}
                        size="4"
                        className={styles.marginLeft}
                    />
                    <Icon
                        src={amexSvg}
                        size="4"
                        className={styles.marginLeft}
                    />
                </Layout>
            </Layout>

            <Layout flexDirection="column">
                <PayForm
                    cardHolderName={cardHolderName}
                    setPayError={setPayError}
                    cardHolderName={cardHolderName}
                    setCardHolderName={setCardHolderName}
                />
            </Layout>
        </Layout>
    );
};

PayNow.propTypes = {
    setPayError: PropTypes.func.isRequired,

    cardHolderName: PropTypes.string.isRequired,
    setCardHolderName: PropTypes.func.isRequired
};

export default PayNow;
