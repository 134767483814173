import { createReducer } from '@reduxjs/toolkit';
import { UPDATE_TV_DETAILS, REMOVE_ALL_TV_DETAILS } from '../constants';

const initialState = {};

const updateTvDetails = (state, action) => {
    const { tvDetails } = action.payload;

    return {
        ...state,
        [tvDetails.name]: tvDetails.value
    };
};

const removeAllTvDetails = () => {
    return initialState;
};

export const tvDetails = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_TV_DETAILS, updateTvDetails)
        .addCase(REMOVE_ALL_TV_DETAILS, removeAllTvDetails)
        .addDefaultCase((state, _) => state);
});
