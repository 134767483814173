import { createReducer } from '@reduxjs/toolkit';
import { SET_CAMPAIGN_ROUTER_ID } from '../constants';

const initialState = null;

const setCampaignRouterId = (state, action) => {
    const { value } = action.payload;
    return value === null || typeof value === 'number' ? value : state;
};

export const campaignRouterId = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_CAMPAIGN_ROUTER_ID, setCampaignRouterId)
        .addDefaultCase((state, _) => state);
});
