import { createAction } from '@reduxjs/toolkit';
import {
    ADD_DISCLAIMERS,
    REMOVE_DISCLAIMER,
    REMOVE_ALL_DISCLAIMERS
} from '../constants';

export const addDisclaimers = createAction(ADD_DISCLAIMERS, value => {
    return {
        payload: { value }
    };
});

export const removeDisclaimer = createAction(REMOVE_DISCLAIMER, id => {
    return {
        payload: { id }
    };
});

export const removeAllDisclaimers = createAction(REMOVE_ALL_DISCLAIMERS);
