import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import {
    setSkipUpload,
    removeAllDisclaimers,
    removeAllFiles
} from '@state/creative/actions/';
import { setIsEditCreatives } from '@state/ui/actions';
import { Text, Layout, Icon, Button } from 'maslow';
import { reMount } from '@components/hoc';
import { CreativeFile } from './CreativeFile';
import { isEmpty } from '@lib';
import { pluralize } from '@services';
import { filterCreatives } from '@services/creative';
import { bookedSelector, formattedCreativeSelector } from '@selectors';
import styles from './styles.scss';

import errorIcon from '@public/img/creativeError.svg';
import closeSvg from '@public/img/close.svg';

import { CREATIVE_REMOVE_FILES } from '@config/idConstants';
import { FILE_STATUS } from '@config/config.creative';
const { ACTIVE, PAUSED } = FILE_STATUS;

const renderFiles = (type, files, status) => {
    const filteredFiles = filterCreatives(files, status, type).map(
        (file, i) => {
            return (
                <React.Fragment key={file.name + i}>
                    <CreativeFile file={file} index={i} />
                </React.Fragment>
            );
        }
    );

    return isEmpty(filteredFiles) ? null : (
        <Layout flexDirection="column" className={styles.fileTypeContainer}>
            <Text className={styles.subHeader}>{type}</Text>
            {filteredFiles}
        </Layout>
    );
};

const renderIcon = iconSrc => {
    const IconComponent = reMount(Icon);

    return <IconComponent src={iconSrc} size={2} />;
};

const renderErrorMessages = files => {
    const errorNum = files.reduce((total, file) => {
        return file.errors.length > 0 ? total + file.errors.length : total;
    }, 0);

    return errorNum ? (
        <Layout
            flexDirection="row"
            hAlign="center"
            vAlign="center"
            className={styles.errorWrapper}>
            {renderIcon(errorIcon)}
            <Text type="input" className={styles.errorText}>
                {`${errorNum} ${pluralize(errorNum, 'error')} found`}
            </Text>
        </Layout>
    ) : null;
};

const renderTabs = (files, status, setStatus) => {
    const activeClass = status === ACTIVE ? styles.enabledTab : '';
    const pausedClass = status === PAUSED ? styles.enabledTab : '';
    const activeNum = filterCreatives(files, ACTIVE).length;
    const pausedNum = filterCreatives(files, PAUSED).length;
    const tabClick = newStatus => setStatus(newStatus);

    return (
        <Layout flexDirection="row">
            <Button
                theme="outline"
                className={`${styles.tab} ${activeClass}`}
                onClick={() => tabClick(ACTIVE)}>
                Active <span>{activeNum}</span>
            </Button>
            <Button
                theme="outline"
                className={`${styles.tab} ${pausedClass}`}
                onClick={() => tabClick(PAUSED)}>
                Paused <span>{pausedNum}</span>
            </Button>
        </Layout>
    );
};

const Component = React.memo(({ isEditCreatives }) => {
    const { files = [] } = useSelector(formattedCreativeSelector);
    const booked = useSelector(bookedSelector);
    const [status, setStatus] = useState(ACTIVE);
    const dispatch = useDispatch();

    useEffect(() => {
        dispatch(setSkipUpload(false));
        if (booked && !isEditCreatives) dispatch(setIsEditCreatives(true));
    }, []);

    const removeFiles = () => {
        dispatch(removeAllFiles());
        dispatch(removeAllDisclaimers());
    };
    return (
        <Layout flexDirection="column" className={styles.fileContainer}>
            <Layout
                flexDirection="row"
                vAlign="center"
                hAlign="space-between"
                className={styles.headerWrapper}>
                {isEditCreatives ? (
                    renderTabs(files, status, setStatus)
                ) : (
                    <React.Fragment>
                        <Text type="h5" className={styles.header}>
                            Uploaded Files
                        </Text>
                        <Text type="h5" className={styles.fileNumber}>
                            ({files.length})
                        </Text>
                    </React.Fragment>
                )}
                {renderErrorMessages(files)}
                {isEditCreatives ? null : (
                    <Button
                        id={CREATIVE_REMOVE_FILES}
                        className={styles.closeIcon}
                        onClick={() => removeFiles()}>
                        <Icon src={closeSvg} />
                    </Button>
                )}
            </Layout>
            <Layout
                flexDirection="column"
                className={styles.filesSectionWrapper}>
                {renderFiles('video', files, status)}
                {renderFiles('image', files, status)}
            </Layout>
        </Layout>
    );
});

Component.propTypes = {
    isEditCreatives: PropTypes.bool.isRequired
};

export const FileManager = Component;
