import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Text, Layout, Toggle } from 'maslow';

import featuresManager from '@featuresManager';
import { getFeature } from '@delivery/react';
import { NATIONAL } from '@config';
import { PLAN_STATUSES } from '@config/config.planStatus';
import { scheduleConfig } from '@config/config.schedule';
import { datesHandler } from '@lib';
import {
    scheduleSelector,
    regionSelector,
    effectiveDurationSelector
} from '@selectors';
import { getFullWeeks } from '@services';
import { calendarHelper } from '@services';

import { ScheduleHeader } from './ScheduleHeader';

import {
    setStartDate,
    setEndDate,
    updateDuration,
    setDuration,
    removeAllExcludedDayparts,
    removeAllHiatusWeeks,
    shiftHiatusWeeks,
    setIsBroadcast
} from '@state/schedule/actions/';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions/';
import { SCHEDULE_TOGGLE_FORMAT } from '@config/idConstants';
import { getImpressionModifierConfig } from '@services/budget';

import styles from './styles.scss';
import { Calendar } from './Calendar';
import { updateBudget } from '@state/budget/actions/';

export default function (version, feature) {
    const Schedule = ({ fadeIn }) => {
        const { rules, errors } = scheduleConfig;

        const { numberOfMonthsToShow } = rules;
        const [role] = featuresManager.getCustomFilterState('role');
        const effectiveDuration = useSelector(effectiveDurationSelector);

        const { startDate, endDate, hiatusWeeks, isBroadcast } = useSelector(
            scheduleSelector
        );
        const region = useSelector(regionSelector);

        const dispatch = useDispatch();

        const maxDate = datesHandler.addMonths(Date.now(), rules.maxMonths);
        const isBothDateSelected = startDate && endDate;

        const [weeks, setWeeks] = useState(0);
        const [focusedType, setFocusedType] = useState('from');

        const setWeeksFromDays = (from, to) => {
            if (!from || !to) return setWeeks(0);
            const fullWeeks = getFullWeeks(from, to);
            setWeeks(fullWeeks);
        };

        const updatePlan = (from, to) => {
            const isSecondClickEarlierThanStartDate = !from && to;
            const isThirdClickEarlierThanStartDate =
                from && to && focusedType === 'from';
            const isClickAfterWeekEntry =
                isBothDateSelected && focusedType === 'to';

            if (isClickAfterWeekEntry || isSecondClickEarlierThanStartDate) {
                dispatch(setStartDate(to));
                dispatch(setEndDate(null));
                setWeeks(0);
            } else if (isThirdClickEarlierThanStartDate) {
                dispatch(setStartDate(from));
                dispatch(setEndDate(null));
                setWeeks(0);
            } else {
                dispatch(setStartDate(from));
                dispatch(setEndDate(to));
                setWeeksFromDays(from, to);
            }
        };

        const updateDateFocus = (from, to) => {
            const isSecondClick = from && to && focusedType === 'to';
            const isClickAfterWeekEntry =
                isBothDateSelected && focusedType === 'to';
            if (isClickAfterWeekEntry) return;

            if (isSecondClick) {
                setFocusedType('from');
            } else {
                setFocusedType('to');
            }
        };

        const onDatePickerChange = ({ from, to }) => {
            updatePlan(from, to);
            dispatch(removeAllHiatusWeeks());
            dispatch(setDuration(0));
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
            updateDateFocus(from, to);
        };

        useEffect(() => {
            //check whether it's an old plan or a new one
            if (isBroadcast === null) {
                isBothDateSelected
                    ? dispatch(setIsBroadcast(true))
                    : dispatch(setIsBroadcast(false));
            }
        }, []);

        useEffect(() => {
            if (startDate && !endDate) {
                setFocusedType('to');
            }
            if (isBothDateSelected) {
                setWeeksFromDays(startDate, endDate);
                dispatch(updateDuration());
            }
        }, []);

        useEffect(() => {
            if (!isBothDateSelected) {
                dispatch(removeAllExcludedDayparts());
            }
            dispatch(updateDuration());
        }, [startDate, endDate]);

        useEffect(() => {
            if (isBothDateSelected) {
                getImpressionModifierConfig(role, region, effectiveDuration)
                    .then(data => {
                        dispatch(
                            updateBudget.saveImpressionModifierConfig(data)
                        );
                    })
                    .catch(console.error);
            }
        }, [effectiveDuration]);

        const renderDaypartExclusions = () => {
            const DaypartExclusions = getFeature(
                'DaypartExclusions',
                'daypartExclusions'
            );
            return <DaypartExclusions />;
        };

        const toggleFormat = () => {
            toggleHiatusWeeks(isBroadcast);
            dispatch(setIsBroadcast(!isBroadcast));
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        };

        const toggleHiatusWeeks = isBroadcast => {
            hiatusWeeks.forEach(({ week }) => {
                isBroadcast
                    ? calendarHelper.shiftWeekBehind(week)
                    : calendarHelper.shiftWeekAhead(week);
            });

            const enabledWeeks = hiatusWeeks.filter(({ week }) => {
                const lastDayOfWeek = datesHandler.getDay(
                    week[week.length - 1]
                );
                const toMonth = calendarHelper.getUpdatedDateForUI(
                    endDate,
                    lastDayOfWeek
                );
                const fromMonth = calendarHelper.getUpdatedDateForUI(
                    startDate,
                    lastDayOfWeek
                );
                return calendarHelper.checkEnabledWeek(
                    week,
                    fromMonth,
                    toMonth
                );
            });

            dispatch(shiftHiatusWeeks(enabledWeeks));
        };

        const isShowDayPartExclusions =
            isBothDateSelected && region === NATIONAL;

        return (
            <Layout
                flexDirection="column"
                styles={fadeIn}
                className={styles.wrapper}
                hAlign="center">
                <Layout flexDirection="column" className={styles.innerWrapper}>
                    <Layout
                        flexDirection="row"
                        hAlign="space-between"
                        vAlign="center"
                        className={styles.topWrapper}>
                        <Text type="h3" className={styles.title}>
                            Select Advertising Dates
                        </Text>

                        <ScheduleHeader
                            from={startDate}
                            to={endDate}
                            weeks={weeks}
                            setWeeks={setWeeks}
                            setFocusedType={setFocusedType}
                            errors={errors}
                            rules={rules}
                            role={role}
                        />
                    </Layout>

                    <Layout
                        flexDirection="row"
                        hAlign="flex-end"
                        className={styles.toggleFormat}>
                        <Text className={styles.toggleFormatCaption}>
                            Broadcast
                        </Text>
                        <Toggle
                            id={SCHEDULE_TOGGLE_FORMAT}
                            checked={!!isBroadcast}
                            onChange={toggleFormat}
                        />
                    </Layout>

                    <Layout
                        flexDirection="column"
                        className={styles.datePickerWrapper}>
                        <Calendar
                            from={startDate}
                            to={endDate}
                            onDateChange={onDatePickerChange}
                            numberOfMonths={numberOfMonthsToShow}
                            focusedType={focusedType}
                            maxDate={maxDate}
                            role={role}
                            isBroadcast={isBroadcast}
                        />
                    </Layout>
                    {isShowDayPartExclusions && renderDaypartExclusions()}
                </Layout>
            </Layout>
        );
    };

    Schedule.propTypes = {
        startDate: PropTypes.instanceOf(Date),
        endDate: PropTypes.instanceOf(Date),
        duration: PropTypes.number,
        region: PropTypes.string
    };

    return Schedule;
}
