import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { Layout, Text, Icon, Card } from 'maslow';
import eyePng from '@public/img/eye.png';
import successIcon from '@public/img/success.svg';
import styles from '../styles.scss';
import { headerIsValidSelector, unvalidatedSubsSelector } from '@selectors/';
import { getSubName } from '@services/';

import { CREATIVE_PDF_LINK } from '@config/idConstants';

const Component = memo(({ editStatus, creativeText, pdfLink }) => {
    const headerIsValid = useSelector(headerIsValidSelector);
    const unvalidatedSubs = useSelector(unvalidatedSubsSelector);

    const renderStatusBar = appliedSubs => {
        return appliedSubs.map(key => (
            <Layout className={styles.productTag} key={key}>
                <Layout flexDirection="row">
                    <Text className={styles.tagText} type="input">
                        {getSubName(key)}
                    </Text>
                </Layout>
            </Layout>
        ));
    };

    const renderContent = isValid => {
        if (isValid) {
            return (
                <Card
                    className={[
                        styles.statusBar,
                        styles.statusBarCompleted
                    ].join(' ')}>
                    <Layout vAlign="center">
                        <Icon
                            className={styles.statusIcon}
                            src={successIcon}
                            size={3}
                        />
                        <Text type="h5" className={styles.statusBarText}>
                            All creatives have been successfully assigned!
                        </Text>
                    </Layout>
                </Card>
            );
        }
        return (
            <Card className={styles.statusBar}>
                <Text type="body" className={styles.statusBarText}>
                    Creatives are needed for:
                </Text>
                {renderStatusBar(unvalidatedSubs)}
            </Card>
        );
    };

    return (
        <Layout
            flexDirection="column"
            hAlign="space-between"
            vAlign="center"
            className={styles.headerContainer}>
            <Layout flexDirection="row" hAlign="space-between" vAlign="center">
                <Layout vAlign="center" className={styles.headerWrapper}>
                    <Text type="h3" className={styles.headerLabel}>
                        {creativeText[editStatus].pageTitle}
                    </Text>
                </Layout>
                <Layout
                    flexDirection="column"
                    hAlign="flex-end"
                    className={styles.pdfTextWrapper}>
                    {pdfLink && (
                        <a
                            target="_blank"
                            id={CREATIVE_PDF_LINK}
                            href={pdfLink}>
                            <img className={styles.eyeImg} src={eyePng} />
                            <Text type="input">View Ad Specs</Text>
                        </a>
                    )}
                </Layout>
            </Layout>
            <Layout vAlign="center" className={styles.headerWrapper}>
                {renderContent(headerIsValid)}
            </Layout>
        </Layout>
    );
});

Component.propTypes = {
    pdfLink: PropTypes.string.isRequired,
    creativeText: PropTypes.object.isRequired,
    editStatus: PropTypes.string.isRequired
};

export const Header = Component;
