import request from '../request';

export default function() {
    return request({
        method: 'GET',
        url: 'auth/user/'
    })
        .then(response => {
            return response;
        })
        .catch(error => {
            return error;
        });
}
