// @ts-check

// @ts-ignore
import { validateArg } from '@lib';

/**
 *
 * @param {boolean} isShowCampaignPage
 * @param {object} routes
 * @param {string} routes.campaignPage - path to campaign page
 * @param {string} routes.locationPage - path to location page
 */

export const getRouteForUserType = (isShowCampaignPage, routes) => {
    validateArg(isShowCampaignPage, 'boolean', 1);
    validateArg(routes, 'object', 2);
    return isShowCampaignPage ? routes.campaignPage : routes.locationPage;
};
