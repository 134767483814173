import React from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Layout, Input, Text } from 'maslow';

import { updateAttributes } from '@state/plan/actions/attributes';
import featuresManager from '@featuresManager';

import styles from './styles.scss';

import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';
import { POLITICAL } from '@config';
import { planSelector, mediaPlanSelector } from '@selectors/';
import {
    MEDIA_PLAN_NAME,
    MEDIA_PLAN_ADVERTISER_NAME,
    MEDIA_PLAN_POLITICAL_CAMPAIGNS_LINK
} from '@config/idConstants';
import { PLAN_STATUSES } from '@config/config.planStatus';

const PlanAndAdvertiserName = () => {
    const [role] = featuresManager.getCustomFilterState('role');
    const dispatch = useDispatch();
    const { attributes } = useSelector(planSelector);
    const { planStatus } = useSelector(mediaPlanSelector);

    const onChange = (value = '', type = '') => {
        if (type === 'name') dispatch(updateAttributes({ name: value }));
        if (type === 'advertiser')
            dispatch(updateAttributes({ advertiser: value }));
    };

    const disabledInputStyle =
        planStatus === PLAN_STATUSES.SAVING ? styles.disabledInputBox : '';
    return (
        <Layout flexDirection="row" className={styles.wrapper}>
            <Layout
                flexDirection="column"
                className={styles.leftWrapper}></Layout>

            <Layout
                flexDirection="column"
                vAlign="center"
                className={styles.rightWrapper}
                flex>
                <Layout
                    flexDirection="row"
                    vAlign="center"
                    className={styles.inputWrapper}>
                    <Layout
                        className={styles.round}
                        hAlign="center"
                        vAlign="center">
                        <Text color="brightBlue" className={styles.numText}>
                            1
                        </Text>
                    </Layout>

                    <Input
                        id={MEDIA_PLAN_NAME}
                        name="planName"
                        theme="line"
                        placeholder="Plan Name"
                        value={attributes.name}
                        onChange={value => onChange(value, 'name')}
                        className={`${styles.inputBox} ${disabledInputStyle}`}
                    />
                </Layout>

                <Text type="h4" color="heatherGray" className={styles.forText}>
                    for
                </Text>

                <Layout
                    flexDirection="row"
                    vAlign="center"
                    className={styles.inputWrapper}>
                    <Layout
                        className={styles.round}
                        hAlign="center"
                        vAlign="center">
                        <Text color="brightBlue" className={styles.numText}>
                            2
                        </Text>
                    </Layout>

                    <Layout flexDirection="column" flex>
                        <Input
                            id={MEDIA_PLAN_ADVERTISER_NAME}
                            name="advertiserName"
                            theme="line"
                            placeholder="Advertiser Name"
                            value={attributes.advertiser}
                            onChange={value => onChange(value, 'advertiser')}
                            className={`${styles.inputBox} ${disabledInputStyle}`}
                        />

                        {role === POLITICAL && (
                            <Text
                                type="input"
                                color="vividNavy"
                                className={styles.politicalTouWrapper}>
                                Campaigns cannot be booked via Athena on behalf
                                of{' '}
                                <a
                                    id={MEDIA_PLAN_POLITICAL_CAMPAIGNS_LINK}
                                    target="_blank"
                                    href={`${termsAndPolicyConfig.termsOfUse}#PoliticalCampaigns`}
                                    className={styles.linkText}>
                                    <Text type="input" color="vividNavy">
                                        foreign principals
                                    </Text>
                                </a>
                                .
                            </Text>
                        )}
                    </Layout>
                </Layout>
            </Layout>
        </Layout>
    );
};

export default PlanAndAdvertiserName;
