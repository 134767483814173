export const convertArrayToObject = (array, key) => {
    if (!array || !key) return;
    if (!Array.isArray(array) || typeof key !== 'string') return;
    return array.reduce((obj, item) => {
        return {
            ...obj,
            [item[key]]: item
        };
    }, {});
};
