import React from 'react';
import { Layout } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';
import shared from '@sharedStyle/page.scss';

const Location = props => {
    const { components } = getFeatures('location', { props });

    return <Layout className={shared.smallPageWrapper}>{components}</Layout>;
};

export default withFeatures(Location);
