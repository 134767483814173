import { strf, round } from '@lib';
import { MARGIN_10, CELL_WIDTH_01, COLOR_BLUE } from '../constants';

export const buildWeekPartsData = (
    doc,
    counter,
    weekParts,
    tvImpressionsValue
) => {
    const table = {
        startY: counter.add(MARGIN_10),
        headStyles: { fillColor: COLOR_BLUE, cellWidth: CELL_WIDTH_01 },
        head: [['Week Part', 'Impressions', 'Percentage']],
        body: [],
        didDrawPage: data => {
            counter.set(counter.count + data.table.height);
        }
    };

    weekParts.forEach(weekPart => {
        const weekPartImpressions = round(
            (tvImpressionsValue * weekPart.DeliveryEstimate) / 100
        );
        const percent = round(weekPart.DeliveryEstimate, true).toFixed(0);

        table.body.push([
            weekPart.Weekpart,
            strf(weekPartImpressions)
                .commas()
                .value(),
            `${percent}%`
        ]);
    });

    doc.autoTable(table);
};
