import {
    AUDIENCE_SELECTION_BUILD_AUDIENCE,
    AUDIENCE_SELECTION_MY_AUDIENCE,
    AUDIENCE_SELECTION_CUSTOM_AUDIENCE,
    AUDIENCE_BUILDER_TOOLTIP_INFO,
    SAVED_AUDIENCES_TOOLTIP_INFO,
    CUSTOM_LIST_TOOLTIP_INFO
} from './idConstants';
import audienceBuilderSvg from '@public/img/selector-ab.svg';
import myAudienceSvg from '@public/img/selector-mya.svg';
import customListSvg from '@public/img/selector-cl.svg';

export const audienceSelectorsConfig = [
    {
        name: 'Saved Audiences',
        button: 'View Saved',
        tooltip:
            'Use your saved audiences, including saved advertiser and household data.',
        route: {
            plan: 'app.plan.audience.audiences',
            ranker: 'app.ranker.audience.audiences'
        },
        type: 'audiences',
        id: AUDIENCE_SELECTION_MY_AUDIENCE,
        tooltipId: SAVED_AUDIENCES_TOOLTIP_INFO,
        svg: myAudienceSvg
    },
    {
        name: 'Audience Builder',
        button: 'Build Audience',
        tooltip:
            'Choose from a variety of demographic and attributes to create your audience.',
        route: {
            plan: 'app.plan.audience.builder.section',
            ranker: 'app.ranker.audience.builder.section'
        },
        type: 'builder',
        id: AUDIENCE_SELECTION_BUILD_AUDIENCE,
        tooltipId: AUDIENCE_BUILDER_TOOLTIP_INFO,
        svg: audienceBuilderSvg
    },
    {
        name: 'Custom Audience',
        button: 'Upload List',
        tooltip:
            "This feature is currently unavailable at this time. To provide a list for this campaign, please proceed through this URL: https://spaces.hightail.com/uplink/a4MediaSecureCustomUpload. \n The file format should be a .CSV file with the layout consisting of Address, City, State, Zip. Please have any other columns removed from the file, as that will impact our matching processing and please avoid including commas in the street address. \n As a message, please provide the name of the Athena campaign and any other special instructions you'd like to have applied. If any assistance is needed, contact our support team to aid you through this process.",
        route: {
            plan: '',
            ranker: 'app.ranker.audience.custom'
        },
        type: 'custom',
        id: AUDIENCE_SELECTION_CUSTOM_AUDIENCE,
        tooltipId: CUSTOM_LIST_TOOLTIP_INFO,
        svg: customListSvg
    }
];
