import { datesHandler } from '@lib';
const start = datesHandler.format(datesHandler.newDate());
const end = datesHandler.format(datesHandler.newDate(30));

export default [
    {
        primary_cpm_net: 11,
        dollar_budget: 5000,
        start_date: start,
        end_date: end,
        context: 'banner',
        medium: 'display',
        target_expression: {},
        primary_target_demo_id: 0,
        primary_target_demo_cpm: 0,
        network_exclusions: [],
        spot_length: 0
    },
    {
        primary_cpm_net: 22,
        dollar_budget: 6000,
        start_date: start,
        end_date: end,
        context: 'banner',
        medium: 'video',
        target_expression: {},
        primary_target_demo_id: 0,
        primary_target_demo_cpm: 0,
        network_exclusions: [],
        spot_length: 0
    },
    {
        primary_cpm_net: 15,
        dollar_budget: 38000,
        start_date: start,
        end_date: end,
        context: 'linear',
        medium: 'video',
        target_expression: {},
        primary_target_demo_id: 0,
        primary_target_demo_cpm: 0,
        network_exclusions: [],
        spot_length: 0
    }
];
