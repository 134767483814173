export default {
    name: 'SignUp', // Component name
    description: 'Signup page',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['signUp'],
    sections: ['signUp'],
    flavours: ['cgd', 'cablevision', 'beta'],
    userRoles: ['guest', 'admin'],
    data: {
        titles: {
            newUser: {
                title: 'Create an Account',
                subTitle: '',
                button: 'Sign Up'
            },
            verifyAccount: {
                title: 'Verify your email',
                subTitle:
                    'Please check your inbox for an account verification link. ',
                button: ''
            }
        }
    },
    attributes: {},
    modifications: {}
};