import {
    FONT_SIZE_10,
    FONT_SIZE_12,
    FONT_SIZE_16,
    FONT_SIZE_20,
    FONT_SIZE_40,
    COLOR_BLACK,
    COLOR_GREY,
    COLOR_BLUE,
    COLOR_BLUE_GREY
} from './constants';

export const captionText = doc => {
    doc.setFontType('normal');
    doc.setFontSize(FONT_SIZE_10);
    doc.setTextColor(...COLOR_BLACK);

    return doc;
};

export const captionTextBlue = doc => {
    doc.setFontSize(FONT_SIZE_10);
    doc.setTextColor(...COLOR_BLUE);

    return doc;
};

export const captionTextBold = doc => {
    captionText(doc);
    doc.setFontType('bold');

    return doc;
};

export const bodyText = doc => {
    doc.setFontType('normal');
    doc.setFontSize(FONT_SIZE_12);
    doc.setTextColor(...COLOR_BLACK);

    return doc;
};

export const bodyTextBold = doc => {
    bodyText(doc);
    doc.setFontType('bold');

    return doc;
};

export const bodyTextBlue = doc => {
    doc.setFontSize(FONT_SIZE_12);
    doc.setTextColor(...COLOR_BLUE);

    return doc;
};

export const bodyTextGrey = doc => {
    doc.setFontType('bold');
    doc.setFontSize(FONT_SIZE_12);
    doc.setTextColor(...COLOR_BLUE_GREY);

    return doc;
};

export const h1 = doc => {
    doc.setFontSize(FONT_SIZE_40);
    doc.setTextColor(...COLOR_BLACK);
    doc.setFontType('bold');

    return doc;
};

export const h3 = doc => {
    doc.setFontSize(FONT_SIZE_20);
    doc.setTextColor(...COLOR_BLACK);
    doc.setFontType('bold');

    return doc;
};

export const h4 = doc => {
    doc.setFontSize(FONT_SIZE_16);
    doc.setTextColor(...COLOR_BLACK);
    doc.setFontType('bold');

    return doc;
};

export const h5 = doc => {
    doc.setFontSize(FONT_SIZE_20);
    doc.setTextColor(...COLOR_GREY);
    doc.setFontType('normal');

    return doc;
};
