import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { UIView } from '@uirouter/react';
import { Page, Layout } from 'maslow';
import { FlyoutNav } from '../layouts';
import { userSelector, uiSelector } from '@selectors';
import useIntercom from 'use-intercom-hook';
import featuresManager from '@featuresManager';
import { LoadingScreen, ToastNotification } from '@components/common';
import { formatUserDetails, generateToast } from '@services';
import { ErrorBoundary } from 'react-error-boundary';
import { PRODUCTION } from '@config';
import { maintenanceModeConfig } from '@config/config.maintenanceMode';
import { ErrorPage } from './ErrorPage';
import { resetPlan } from '@state/rootActions';

const App = props => {
    const dispatch = useDispatch();
    const { account: userAccount } = useSelector(userSelector);
    const {
        loadingScreen: { showLoadingScreen }
    } = useSelector(uiSelector);
    const { id, email, firstName, lastName } = userAccount;
    const [type] = featuresManager.getCustomFilterState('type');

    const user = { ...formatUserDetails(userAccount) };

    const intercomId = window.env === 'production' ? 'pcqvz1gx' : 'ew3bkfk0';

    useIntercom(intercomId, user);

    // if (window.env === PRODUCTION && window.FS) {
    //     window.FS.identify(id, user);
    // }

    if (window.pendo) {
        window.pendo.initialize({
            visitor: {
                id: email,
                userId: id,
                email: email,
                firstName: firstName,
                lastName: lastName
            },

            account: {
                id: email,
                userId: id,
                email: email,
                role: type
            }
        });
    }

    useEffect(() => {
        if (!showLoadingScreen) {
            if (window.maintenanceMode === 'true') {
                return generateToast.add(maintenanceModeConfig, dispatch);
            }
        }
    }, [showLoadingScreen]);

    return (
        <Page>
            <FlyoutNav {...props} />
            <ErrorBoundary
                FallbackComponent={ErrorPage}
                onReset={() => {
                    dispatch(resetPlan());
                }}>
                <Layout flex>
                    <UIView
                        name="mainSidebar"
                        render={(Component, props) => {
                            return <Component {...props} />;
                        }}
                    />
                    <Layout flex flexDirection="column">
                        <Layout
                            flexDirection="row"
                            style={{ minHeight: 0 }}
                            flex>
                            <UIView name="leftSidebar" />

                            <Layout flexDirection="column" fillParent>
                                <UIView name="topbar" />
                                <UIView name="content" />
                            </Layout>

                            <LoadingScreen />
                            <ToastNotification />
                            <UIView name="rightSidebar" />
                        </Layout>

                        <UIView name="footer" />
                    </Layout>
                </Layout>
            </ErrorBoundary>
        </Page>
    );
};

export default App;
