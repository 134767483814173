import { Advertisers } from '../components/pages';

export default [
    {
        name: 'app.main.advertisers',
        url: '/advertisers',
        views: {
            'content@app': {
                component: Advertisers
            }
        }
    }
];
