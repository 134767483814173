export default {
    name: 'AudienceSummarySelections',
    description:
        'Audience Summary Selections Cards. If interactive audience options can be removed',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan', 'ranker'],
    sections: ['interactiveAudienceSummary', 'leftSidebarCombinations'],
    permissions: [],
    data: {
        selectedAudienceStyles: [
            {
                title: { color: '#2E5EFF' },
                card: {
                    borderLeft: '5px solid #2E5EFF',
                    backgroundColor: '#CFE0FF'
                }
            },
            {
                title: { color: '#1A83BE' },
                card: {
                    borderLeft: '5px solid #1A83BE',
                    backgroundColor: 'rgba(26,131,190,0.2)'
                }
            },
            {
                title: { color: '#002F75' },
                card: {
                    borderLeft: '5px solid #002F75',
                    backgroundColor: 'rgba(0,47,117,0.2)'
                }
            }
        ]
    },
    attributes: {},
    modifications: {}
};
