import { createAction } from '@reduxjs/toolkit';
import { SET_IS_RANKER } from '../constants';

export const setIsRanker = createAction(SET_IS_RANKER, isRanker => {
    return {
        payload: {
            isRanker
        }
    };
});
