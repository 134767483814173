export default {
    id: null,
    weeks: 0,
    reachPercent: 0,
    households: 0,
    tvSpend: 38000,
    digitalSpend: 11000,
    videoSpend: 6000,
    displaySpend: 5000,
    digitalCpm: 24,
    tvCpm: 15,
    impressions: 0
};
