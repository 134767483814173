import { createAction } from '@reduxjs/toolkit';
import { SET_SAVED_AUDIENCES } from '../constants';

export const setSavedAudiences = createAction(
    SET_SAVED_AUDIENCES,
    savedAudiences => {
        return {
            payload: { savedAudiences }
        };
    }
);
