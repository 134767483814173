export default {
    name: 'NotFound', // Component name
    description:
        'This component will be used for any access denied or page requests that cant resolve.',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['main'],
    sections: ['notfound'],
    permissions: [],
    customFilters: {},
    data: {
        headings: {
            title: 'Oh nooo!',
            text: `Something went wrong while loading this page.`,
            buttonText: `Go Back`
        },
        routeTo: 'app.main.plans'
    },
    attributes: {},
    modifications: {}
};
