import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Layout, Text, Icon } from 'maslow';
import plusIcon from '@public/img/plus-selector.svg';
import minusIcon from '@public/img/minus-selector.svg';
import disabledIcon from '@public/img/minus-selector-grey.svg';

import {
    addHiatusWeek,
    removeHiatusWeek,
    updateDuration
} from '@state/schedule/actions/';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions/';

import { datesHandler } from '@lib';
import { calendarHelper } from '@services';

import { PLAN_STATUSES } from '@config/config.planStatus';
import { scheduleSelector } from '@selectors/';
import { SCHEDULE_HIATUS_WEEK } from '@config/idConstants';
import { idBuilder } from '@services/idBuilder';

const reMount = Component => {
    return function (props) {
        return <Component {...props} />;
    };
};

const hasHiatusWeek = (hiatusWeeks, weekNumber) => {
    const selectedWeek = hiatusWeeks.map(weekNumber => weekNumber.weekNumber);

    return selectedWeek.includes(weekNumber);
};

export const WeekSelection = ({ week, weekNumber }) => {
    const { hiatusWeeks, startDate, endDate } = useSelector(scheduleSelector);

    const dispatch = useDispatch();
    const isSelected = hasHiatusWeek(hiatusWeeks, weekNumber);
    const lastDayOfWeek = datesHandler.getDay(week[week.length - 1]);
    const toMonth = calendarHelper.getUpdatedDateForUI(endDate, lastDayOfWeek);
    const fromMonth = calendarHelper.getUpdatedDateForUI(
        startDate,
        lastDayOfWeek
    );
    const showHiatusIcon = startDate && endDate;

    const handleClick = () => {
        if (
            !showHiatusIcon ||
            !calendarHelper.checkEnabledWeek(week, fromMonth, toMonth)
        )
            return;

        const hiatusWeek = {
            week: week,
            weekNumber: weekNumber
        };

        if (!isSelected) {
            dispatch(addHiatusWeek(hiatusWeek));
            dispatch(updateDuration());
        } else if (isSelected) {
            dispatch(removeHiatusWeek(hiatusWeek.weekNumber));
            dispatch(updateDuration());
        }

        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    const renderIcon = () => {
        const Component = reMount(Icon);
        let iconSrc = disabledIcon;
        if (calendarHelper.checkEnabledWeek(week, fromMonth, toMonth)) {
            iconSrc = isSelected ? plusIcon : minusIcon;
        }

        return <Component className="hiatusIcon" src={iconSrc} size="2.5" />;
    };

    const weekNumWrapperStyle = showHiatusIcon
        ? 'weekNumWithIcon'
        : 'weekNumWithoutIcon';
    return (
        <Layout
            id={idBuilder(SCHEDULE_HIATUS_WEEK, weekNumber)}
            flexDirection="row"
            vAlign="center"
            className={weekNumWrapperStyle}
            onClick={() => handleClick()}>
            <Text className="hiatusWeekNum">{weekNumber}</Text>

            {showHiatusIcon && renderIcon()}
        </Layout>
    );
};

WeekSelection.propTypes = {
    week: PropTypes.array,
    weekNumber: PropTypes.number
};
