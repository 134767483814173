import request from '../request';

export default function (ids) {
    const req = {
        method: 'POST',
        url: `locations/multipleGeoJson?ids=${ids}`,
        data: locations
    };

    return request(req);
}
