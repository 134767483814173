import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import styles from './styles.scss';
import { Layout, Card, Text, Icon, animator, cycles } from 'maslow';
import { fetchHouseholdCount } from '@state/audience/actions/households';
import { householdsSelector } from '@selectors';
import { formatHouseholdStatus } from '@services';
import { strf } from '@lib';
import { reMount } from '@components/hoc';
import { hhCountLimit } from '@config/config.households';
import errorIcon from '@public/img/error-state.svg';
import { createSelector } from 'reselect';
const SRC = '/public/img/house.svg';
const ANIMATION_TIMEOUT_TIME = 1000;

const renderIcon = src => {
    const IconComponent = reMount(Icon);
    const iconSrc = src;
    return <IconComponent src={iconSrc} size="2.5" />;
};

const getHouses = count => {
    const houses = [];
    for (let i = 0; i < count; i++) {
        houses.push({ src: SRC });
    }
    return houses;
};

const buildHouses = (item, index) => {
    const size = (Math.random() * (1.3 - 0.8) + 0.8).toFixed(1);
    return (
        <Icon key={index} src={item.src} className={styles.house} size={size} />
    );
};

const createHouseholdStatusSelector = page =>
    createSelector(householdsSelector, formatHouseholdStatus(page));

export default function (version, feature) {
    const HouseholdCount = ({ page }) => {
        const dispatch = useDispatch();
        const [continueFetching, setContinueFetching] = useState(false);

        const {
            count,
            total,
            pageTotal,
            isFetching,
            hhPercent,
            targetingEveryone
        } = useSelector(createHouseholdStatusSelector(page));

        const countBoxRef = useRef(null);
        const warningBoxRef = useRef(null);

        const houses = getHouses(feature.data.housesCount).map(buildHouses);

        const a = animator();
        a.registerCycles(cycles.household);

        useEffect(() => {
            if (!pageTotal) animateHousehold();
            if (targetingEveryone && !total) dispatch(fetchHouseholdCount());
            return () => {
                a.stopAll();
            };
        }, []);

        useEffect(() => {
            let animationTimeout;
            if (isFetching) {
                animationTimeout = setAnimationTimeout();
            } else {
                checkCountLimit(count);
            }

            if (!isFetching && pageTotal) {
                completeHHAnimation(count);
                targetingEveryone
                    ? displayCounts(animationTimeout)
                    : checkCountLimit(count);
            }
            return () => clearAnimationTimeout(animationTimeout);
        }, [isFetching, count]);

        useEffect(() => {
            let interval;
            if (continueFetching && !interval) {
                interval = setInterval(continueAnimation, 1500);
            }
            return () => {
                interval && clearInterval(interval);
            };
        }, [continueFetching]);

        const clearAnimationTimeout = animationTimeout => {
            if (animationTimeout) {
                clearTimeout(animationTimeout);
            }
        };

        const setAnimationTimeout = () => {
            return setTimeout(animateHousehold, ANIMATION_TIMEOUT_TIME);
        };

        const displayCounts = animationTimeout => {
            if (animationTimeout) clearAnimationTimeout(animationTimeout);
            a.stopAll();
            if (countBoxRef.current)
                countBoxRef.current.style.visibility = 'visible';
            if (warningBoxRef.current)
                warningBoxRef.current.style.visibility = 'hidden';
        };

        const displayWarning = () => {
            if (countBoxRef.current)
                countBoxRef.current.style.visibility = 'hidden';
            if (warningBoxRef.current)
                warningBoxRef.current.style.visibility = 'visible';
        };

        const checkCountLimit = count => {
            const disableCount = count < 0;
            disableCount ? displayWarning() : displayCounts();
        };

        const completeCountHHAnimation = count => {
            checkCountLimit(count);
            a.runCycle('hh_estimatingDone');
        };

        const completeHHAnimation = count => {
            a.stopAll();
            setContinueFetching(false);
            completeCountHHAnimation(count);
        };

        const continueAnimation = () => {
            a.runCycle('hh_continueEstimating');
        };

        const animateHousehold = () => {
            displayCounts();
            a.stopAll();
            a.runCycle('hh_initial');
            a.runCycle('hh_estimating');
            setContinueFetching(true);
        };

        return (
            <div className={styles.countBoxWrapper}>
                <div
                    id="count-box"
                    className={styles.countsBox}
                    ref={countBoxRef}>
                    <Layout
                        fillParent
                        flexDirection="column"
                        hAlign="center"
                        className={styles.householdWrapper}>
                        <Text type="h5" color="white" id="household-title">
                            HOUSEHOLDS
                        </Text>

                        <Text
                            type="h4"
                            id="household-count"
                            color={count ? 'white' : 'transparent'}>
                            {strf(count).commas().value()}
                        </Text>

                        <span id="map" className={styles.map} />

                        <Text type="h5" id="household-percent" color="white">
                            {hhPercent}% of {strf(pageTotal).commas().value()}
                        </Text>

                        <span id="houses">{houses}</span>
                    </Layout>
                </div>

                <div
                    id="warning-box"
                    ref={warningBoxRef}
                    className={styles.warningBox}>
                    <Card
                        borderRadius="2px"
                        borderColor="red"
                        boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                        backgroundColor="white">
                        <Layout
                            fillParent
                            flexDirection="column"
                            hAlign="center"
                            className={styles.householdWrapper}>
                            <Text type="input" className={styles.warningTitle}>
                                AUDIENCE IS LESS THAN
                            </Text>

                            <Layout
                                flexDirection="row"
                                className={styles.warningCountWrapper}
                                vAlign="center">
                                {renderIcon(errorIcon)}

                                <Text type="h5" className={styles.warningCount}>
                                    {strf(hhCountLimit.min).commas().value()}
                                </Text>
                            </Layout>
                            <Layout>
                                <Text
                                    type="caption"
                                    className={styles.warningSubTitle}>
                                    Edit audience selections to proceed
                                </Text>
                            </Layout>
                        </Layout>
                    </Card>
                </div>
            </div>
        );
    };

    HouseholdCount.propTypes = {
        page: PropTypes.string.isRequired
    };

    return HouseholdCount;
}
