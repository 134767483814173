import { pipe, addArgs, findAllByKey, validateArg } from '@lib';
import {
    VALID_FILE_TYPES,
    VALID_FILE_SIZES,
    VALID_DIMENSIONS,
    VALID_ASPECT_RATIO,
    VALID_POLITICAL_DISCLAIMER
} from '@config/config.creative';
import { wait } from '@services/';

export const defaultFileShape = {
    name: '',
    type: '',
    mimeType: '',
    src: '',
    size: 0,
    width: 0,
    height: 0,
    aspectRatio: 0,
    errors: [],
    isUploading: false,
    fileLocation: '',
    startDate: '',
    endDate: '',
    a4PlanId: 0,
    excludedProducts: []
};

export const defaultDisclaimerShape = {
    name: '',
    id: 0,
    fileLocation: '',
    status: ''
};

export const getVideoDimensionsOf = url => {
    return new Promise((resolve, reject) => {
        let video = document.createElement('video');

        video.addEventListener(
            'loadedmetadata',
            function () {
                let height = this.videoHeight;
                let width = this.videoWidth;
                let duration = this.duration;
                if (this) {
                    resolve({
                        height,
                        width,
                        duration
                    });
                } else {
                    reject(new Error('video file not valid'));
                }
            },
            false
        );

        video.src = url;
    });
};

/**
 * @param {Object} textArray
 * @returns {Array}
 */

const formatText = obj => {
    return findAllByKey(obj, 'text').map(text => {
        return text.toLowerCase();
    });
};

export const validateDisclaimer = async (url, detectText) => {
    validateArg(url, 'string', 1);
    validateArg(detectText, 'function', 2);

    let regions = [];
    try {
        const { data } = await detectText(url);
        regions = data.regions;
    } catch (e) {
        console.error(e);
    }

    await wait(1000, 3000);

    if (!regions.length) return false;
    const formattedText = formatText(regions[0]);

    return validatePolicalDisclaimer(formattedText);
};

export const getFileType = type => type.substring(0, type.indexOf('/'));

/**
 * @param {Array} files
 * @param {Function} callback (provided by pipe function)
 */
export const validateFiles = (files, callback) => {
    const validatedFiles = files.map(file => {
        const errors = validationFunctions[file.type]
            .map(fn => {
                return fn(file);
            })
            .filter(item => item !== undefined);

        return { ...file, errors };
    });
    return callback ? callback(validatedFiles) : validatedFiles;
};

/**
 * @param {Object} = {
 * 		{String} mineType
 * 		{Number} size
 * }
 */
export const validateFileSize = ({ type, size }) => {
    return size <= VALID_FILE_SIZES[type].minFileSize ||
        size > VALID_FILE_SIZES[type].maxFileSize
        ? 'fileSizeNotAcceptable'
        : undefined;
};

/**
 * @param {Object} = {
 * 		{String} mimeType
 * }
 */
export const validateFileFormat = ({ mimeType }) => {
    return !VALID_FILE_TYPES.includes(mimeType)
        ? 'fileFormatNotAcceptable'
        : undefined;
};

/**
 * @param {Object} = {
 * 		{Number} width,
 * 		{Number} height
 * }
 */
export const validateDimensions = ({ width, height }) => {
    const dimensions = `${width}x${height}`;
    return !VALID_DIMENSIONS.includes(dimensions)
        ? 'fileDimensionsNotAcceptable'
        : undefined;
};

/**
 * @param {Object} = {
 * 		{Number} width,
 * 		{Number} height
 * }
 */
export const validateAspectRatio = ({ aspectRatio }) => {
    return !VALID_ASPECT_RATIO.includes(aspectRatio)
        ? 'fileAspectRatioNotAcceptable'
        : undefined;
};

export const validatePolicalDisclaimer = wordsArr => {
    return !VALID_POLITICAL_DISCLAIMER.some(word => {
        return wordsArr.includes(word.toLowerCase());
    })
        ? false
        : true;
};

export const validationFunctions = {
    image: [validateFileSize, validateDimensions, validateFileFormat],
    video: [
        validateFileSize,
        validateAspectRatio,
        validateDimensions,
        validateFileFormat
    ]
};

/**
 * @param {Array} files
 * @param {Function} callback (provided by pipe function)
 */
const parseFiles = (files, callback) => {
    const parsedFiles = files.map(file => {
        const { name, type, size } = file;

        return {
            ...defaultFileShape,
            ...{
                name,
                size,
                file,
                mimeType: type,
                type: getFileType(type),
                src: URL.createObjectURL(file),
                isUploading: true
            }
        };
    });
    return callback ? callback(parsedFiles) : parsedFiles;
};

// side effects are required for Image and FileReader onload operations
const sideEffects = {
    /**
     * @param {Object} = {
     * 		{Array} files,
     * 		{Number} index,
     * 		{Function} recurse
     * }
     */
    image({ files, index, recurse, reader }) {
        const cachedIndex = index;

        reader.onload = () => {
            files[cachedIndex].src = reader.result;

            const img = new Image();

            img.onload = () => {
                URL.revokeObjectURL(files[cachedIndex].src);
                files[cachedIndex].width = img.width;
                files[cachedIndex].height = img.height;

                index++;

                return recurse(files, index);
            };
            img.src = files[cachedIndex].src;
        };
        reader.readAsDataURL(files[cachedIndex].file);
    },

    /**
     * @param {Object} = {
     * 		{Array} files,
     * 		{Number} index,
     * 		{Function} recurse
     * }
     */
    video({ files, index, recurse, reader }) {
        const cachedIndex = index;
        const video = document.createElement('video');

        reader.onload = () => {
            video.onresize = ev => {
                const { videoWidth, videoHeight, duration } = video;

                files[cachedIndex].width = videoWidth;
                files[cachedIndex].height = videoHeight;
                files[cachedIndex].duration = duration;
                files[cachedIndex].aspectRatio =
                    videoWidth && videoHeight ? videoWidth / videoHeight : 0;
                index++;
                return recurse(files, index);
            };

            video.src = files[cachedIndex].src;
        };

        reader.readAsArrayBuffer(files[cachedIndex].file);
    }
};

/**
 * @param {Array} files
 * @param {Function} callback (provided by pipe function)
 */
const runCreativeSideEffects = (files, callback) => {
    const recurse = (_files, index = 0) => {
        const reader = new FileReader();

        if (index > _files.length - 1) {
            callback(_files);
        } else {
            sideEffects[_files[index].type]({
                files: _files,
                index,
                recurse,
                reader
            });
        }
    };
    recurse([...files]);
};

/**
 * @param {Array} files
 */
export const runCreativeOperations = (files, config, fns) => {
    return pipe(
        fns || [
            addArgs(parseFiles, files),
            runCreativeSideEffects,
            validateFiles
        ]
    );
};
