import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import featuresManager from '@featuresManager/';
import { Input } from 'maslow';
import { budgetConfig } from '@config/config.budget';
import { PLAN_STATUSES } from '@config/config.planStatus';
import {
    budgetSelector,
    totalSelector,
    createSectionSelector,
    createStatusSelector,
    createErrorTextSelector,
    regionSelector,
    getLockedSubCampaignsSelector,
    getLockedChannelsSelector
} from '@selectors';
import { budgetCalc, getChannelBudget } from '@services/budget';

import { strf, getUnformattedBudget } from '@lib';
import { updateBudget } from '@state/budget/actions';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import { setIsValid } from '@state/creative/actions';
import styles from '../Distribution/styles.scss';

import { idBuilder } from '@services';
import { BUDGET_INPUT } from '@config/idConstants';

const INTERVAL_TIME = 500;

const BudgetInput = ({ config, theme, disabledChannelsKeys }) => {
    const { key } = config;
    const dispatch = useDispatch();

    const [role] = featuresManager.getCustomFilterState('role');
    const [value, setValue] = useState('$0');

    const budget = useSelector(budgetSelector);
    const total = useSelector(totalSelector);
    const sectionBudget = useSelector(createSectionSelector[theme](key));
    const region = useSelector(regionSelector);
    const status =
        theme === 'subCampaign'
            ? useSelector(createStatusSelector.subCampaign(key))
            : 'idle';
    const errorText =
        theme === 'subCampaign'
            ? useSelector(createErrorTextSelector(key, config))
            : '';
    const lockedSubCampaigns = useSelector(getLockedSubCampaignsSelector);
    const lockedChannels = useSelector(getLockedChannelsSelector);

    const updateInput = value => {
        const amount = getUnformattedBudget(value);
        const sectionTotal =
            theme === 'channel' ? total : getChannelBudget(key, budget);
        const correctedAmount = amount > sectionTotal ? sectionTotal : amount;
        const formattedAmount = strf(correctedAmount)
            .commas()
            .prepend('$')
            .value();

        setValue(formattedAmount);
    };

    const updateState = value => {
        const amount = getUnformattedBudget(value);

        if (amount !== sectionBudget) {
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        }

        dispatch(setIsValid(false));

        const newAmounts = budgetCalc[theme](
            key,
            amount,
            budget,
            false,
            lockedSubCampaigns,
            lockedChannels,
            role,
            region,
            disabledChannelsKeys
        );
        dispatch(updateBudget.setIsAutoDistributed(false));
        dispatch(updateBudget[theme](newAmounts));
    };

    useEffect(() => {
        if (!isNaN(sectionBudget)) updateInput(sectionBudget);
    }, [sectionBudget]);

    return (
        <Input
            id={idBuilder(BUDGET_INPUT, key)}
            name="amount"
            value={value}
            doneTypingInterval={INTERVAL_TIME}
            doneTyping={updateState}
            onChange={updateInput}
            status={status}
            errorText={errorText}
            errorClassName={styles.errorContainer}
            maxLength={budgetConfig.limits.maxBudgetLength}
            textAlign="center"></Input>
    );
};

BudgetInput.propTypes = {
    config: PropTypes.object.isRequired,
    theme: PropTypes.string,
    disabledChannelsKeys: PropTypes.array
};

export default BudgetInput;
