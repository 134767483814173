import { combineReducers } from 'redux';

import { excludedNetworks } from './excludedNetworks';
import { allNetworks } from './allNetworks';
import { orderBy } from './orderBy';

export const networks = combineReducers({
    excludedNetworks,
    allNetworks,
    orderBy
});
