import { createSelector } from 'reselect';

export const uiSelector = createSelector(
    state => state.ui,
    ui => ui
);

export const pathSelector = createSelector(uiSelector, ({ isRanker }) =>
    isRanker ? 'ranker' : 'plan'
);
