import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { UISrefActive, UISref } from '@uirouter/react';
import { Layout, Content, Card, Icon, Text } from 'maslow';
import styles from './emptyAudience.scss';
import myAudienceSvg from '@public/img/empty-audience.svg';
import { slugBuilder } from '@services';
import { setAudienceType } from '@state/audience/actions/audienceType';
import { audienceConfigSelector, pathSelector } from '@selectors/';

import {
    MY_AUDIENCES_EMPTY_AUDIENCES_BUILDER,
    MY_AUDIENCES_EMPTY_AUDIENCES_CUSTOM
} from '@config/idConstants';
import audienceRoutes from '@config/config.audienceRoutes';

const totalEmpytCards = 6;

const buildEmptyCard = index => {
    return (
        <Card
            key={index}
            backgroundColor="white"
            borderRadius="2px"
            boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
            borderColor="transparent">
            <Content fillParent>
                <Layout flexDirection="column" vAlign="center" hAlign="left">
                    <div className={styles.cardLines1} />
                    <div className={styles.cardLines2} />
                    <div className={styles.cardLines2} />
                    <div className={styles.cardLines3} />
                </Layout>
            </Content>
        </Card>
    );
};

const buildEmptyCards = count => {
    let emptyCards = [];
    for (let i = 0; i < count; i++) {
        emptyCards.push(buildEmptyCard(i));
    }

    return emptyCards;
};

const EmptyAudience = () => {
    const audienceConfig = useSelector(audienceConfigSelector);
    const path = useSelector(pathSelector);
    const dispatch = useDispatch();
    const updateAudienceType = audienceType => {
        dispatch(setAudienceType(audienceType));
    };
    const emptyCards = buildEmptyCards(totalEmpytCards);
    const sectionSlugName =
        audienceConfig.length > 0 ? slugBuilder(audienceConfig[0].name) : '';

    return (
        <Content>
            <Card
                backgroundColor="white"
                borderRadius="2px"
                boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                borderColor="transparent">
                <Content fillParent className={styles.emptyAudienceCardWrapper}>
                    <Layout
                        flexDirection="column"
                        vAlign="center"
                        hAlign="center">
                        <Icon
                            src={myAudienceSvg}
                            size="14"
                            className={styles.emptyAudienceIcon}
                        />
                        <Text type="h5" className={styles.descriptionTitle}>
                            {"Looks like you don't have any saved audiences."}
                        </Text>

                        <Content className={styles.descriptionWrapper}>
                            <Text> Try </Text>
                            <UISref
                                to={audienceRoutes.builder[path]}
                                params={{ sectionName: sectionSlugName }}>
                                <a>
                                    <Text
                                        id={
                                            MY_AUDIENCES_EMPTY_AUDIENCES_BUILDER
                                        }
                                        onClick={() =>
                                            updateAudienceType('builder')
                                        }>
                                        building an audience
                                    </Text>
                                </a>
                            </UISref>
                            <Text> or </Text>
                            <UISref to={audienceRoutes.custom[path]}>
                                <a>
                                    <Text
                                        id={MY_AUDIENCES_EMPTY_AUDIENCES_CUSTOM}
                                        onClick={() =>
                                            updateAudienceType('custom')
                                        }>
                                        uploading a custom list
                                    </Text>
                                </a>
                            </UISref>
                            <Text>
                                , and when you save that audience, it will show
                                up here.
                            </Text>
                        </Content>
                    </Layout>
                </Content>
            </Card>

            <div className={styles.gradientOverlay}>{emptyCards}</div>
        </Content>
    );
};

export default EmptyAudience;
