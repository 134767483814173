import { createAction } from '@reduxjs/toolkit';
import { CancelToken } from 'axios';
import { buildHHCountPayload } from '@services';
import { isEmpty } from '@lib';

import {
    trimAudiences,
    formatLocations,
    hasAudience
} from '@services/audiencesService';

import {
    SET_HOUSEHOLD_COUNT,
    SET_LOCATION_COUNT,
    SET_TOTAL_HOUSEHOLD_COUNT,
    FETCHING_HOUSEHOLD_COUNT,
    FETCHING_LOCATION_COUNT,
    FETCHING_TOTAL_HOUSEHOLD_COUNT,
    ABORT_FETCHING_HOUSEHOLD_COUNT,
    ABORT_FETCHING_LOCATION_COUNT
} from '../constants';

import requestHandlers from '@requestHandlers';
import featuresManager from '@featuresManager';

let source;
let locationSource;

const shouldUseModifierFns = {
    audiences(audiences = []) {
        return audiences
            .map(audience => audience.map(selections => selections.section))
            .flat()
            .includes('Political');
    },
    locations(locations = []) {
        return locations
            .map(location => location.section)
            .includes('PoliticalDistricts');
    }
};

const modifiers = {
    internal: window.householdCommercialModifier,
    external: window.householdPoliticalModifier
};

const hasSelections = (...args) => args.map(isEmpty).some(a => !a);
const useModifier = (count, type) =>
    count !== -1 ? count * Number(modifiers[type]) : count;

export const setHouseholdCount = createAction(SET_HOUSEHOLD_COUNT, count => {
    return {
        payload: { count }
    };
});

export const setLocationCount = createAction(SET_LOCATION_COUNT, count => {
    return {
        payload: { count }
    };
});

export const setTotalHouseholdCount = createAction(
    SET_TOTAL_HOUSEHOLD_COUNT,
    total => {
        return {
            payload: { total }
        };
    }
);

export const fetchingHouseholdCount = createAction(FETCHING_HOUSEHOLD_COUNT);

export const fetchingLocationCount = createAction(FETCHING_LOCATION_COUNT);

export const fetchingHouseholdTotalCount = createAction(
    FETCHING_TOTAL_HOUSEHOLD_COUNT
);

export const abortFetchingHouseholdCount = createAction(
    ABORT_FETCHING_HOUSEHOLD_COUNT
);

export const abortFetchingLocationCount = createAction(
    ABORT_FETCHING_LOCATION_COUNT
);

export const fetchLocationCount = () => {
    return (dispatch, getState) => {
        const {
            audience: { households, audiences },
            location: { selectedLocations }
        } = getState();

        if (isEmpty(selectedLocations)) {
            dispatch(setLocationCount(households.total));
            return;
        }

        if (!hasAudience(audiences)) {
            dispatch(setLocationCount(households.count));
            return;
        }

        dispatch(fetchingLocationCount());

        const formattedLocations = formatLocations(selectedLocations);

        const shouldUseModifier = [
            shouldUseModifierFns.locations(formattedLocations)
        ].includes(true);

        const [type] = featuresManager.getCustomFilterState('type');

        if (locationSource && households.locationIsFetching) {
            dispatch(abortFetchingLocationCount());
            locationSource.cancel();
        }

        locationSource = CancelToken.source();

        const payload = buildHHCountPayload([], {}, formattedLocations);
        requestHandlers.households
            .count(payload, locationSource, true)
            .then(({ data: { count } }) => {
                shouldUseModifier
                    ? dispatch(setLocationCount(useModifier(count, type)))
                    : dispatch(setLocationCount(count));
            })
            .catch(console.error);
    };
};

const count = async (dispatch, selections, source, providedData) => {
    const { hhTotal, combinations, audiences, locations } = selections;

    const shouldUseModifier = [
        shouldUseModifierFns.audiences(audiences),
        shouldUseModifierFns.locations(locations)
    ].includes(true);

    const [type] = featuresManager.getCustomFilterState('type');

    if (hhTotal === 0) await total(dispatch, source);

    dispatch(fetchingHouseholdCount());

    const payload =
        providedData || buildHHCountPayload(audiences, combinations, locations);

    if (isEmpty(payload.selected)) {
        dispatch(setHouseholdCount(-1));
        return;
    }

    requestHandlers.households
        .count(payload, source)
        .then(({ data: { count } }) => {
            shouldUseModifier
                ? dispatch(setHouseholdCount(useModifier(count, type)))
                : dispatch(setHouseholdCount(count));
        })
        .catch(console.error);
};

const total = async (dispatch, source, hasNoSelections) => {
    dispatch(fetchingHouseholdCount());

    await requestHandlers.households
        .count({}, source)
        .then(({ data: { count } }) => {
            dispatch(setTotalHouseholdCount(count));
            if (hasNoSelections) dispatch(setHouseholdCount(count));
        })
        .catch(console.error);
};

export const fetchHouseholdCount = providedData => (dispatch, getState) => {
    const {
        audience: { households, combinations, audiences },
        location: { selectedLocations }
    } = getState();

    const trimmedAudiences = trimAudiences(audiences);

    if (source && households.isFetching) {
        dispatch(abortFetchingHouseholdCount());
        source.cancel();
    }

    source = CancelToken.source();

    const selections = {
        hhTotal: households.total,
        combinations,
        audiences: trimmedAudiences,
        locations: formatLocations(selectedLocations)
    };

    const hasNoSelections = !hasSelections(trimmedAudiences, selectedLocations);

    if (hasNoSelections && !households.total) {
        total(dispatch, source, hasNoSelections);
        return;
    }

    hasNoSelections && households.total
        ? dispatch(setHouseholdCount(households.total))
        : count(dispatch, selections, source, providedData);
};
