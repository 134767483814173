import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Text, Icon, Layout } from 'maslow';
import {
    addExcludedNetwork,
    removeExcludedNetwork
} from '@state/networks/actions/excludedNetworks';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { reMount } from '@components/hoc';
import plusIcon from '@public/img/plus-selector.svg';
import minusIcon from '@public/img/minus-selector.svg';
import iconPlaceholder from '@public/img/network-placeholder.svg';
import styles from '../../styles.scss';
import networkIcons from '@config/config.networkIcons';

import { PLAN_STATUSES } from '@config/config.planStatus';
import { idBuilder } from '@services/idBuilder';

import { NETWORKS_NETWORK } from '@config/idConstants';

import {
    excludedNetworksSelector,
    hasExcludedNetworkSelector
} from '@selectors';

export const Network = ({ limit, network }) => {
    const dispatch = useDispatch();

    const excludedNetworks = useSelector(excludedNetworksSelector);
    const isSelected = useSelector(
        hasExcludedNetworkSelector(network.A4mediaId)
    );

    const handleClick = excludedNetwork => {
        if (!isSelected && excludedNetworks.length < limit) {
            dispatch(addExcludedNetwork(excludedNetwork));
            dispatch(setPlanStatus(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        } else if (isSelected) {
            dispatch(removeExcludedNetwork(excludedNetwork.A4mediaId));
            dispatch(setPlanStatus(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        }
    };

    const renderIcon = () => {
        const IconComponent = reMount(Icon);
        const iconSrc = isSelected ? minusIcon : plusIcon;

        return <IconComponent className={styles.icon} src={iconSrc} size="3" />;
    };

    const networkIcon = networkIcons[network.Code];
    const name = network.Name;

    const selectionStyle = isSelected
        ? [styles.selection, styles.selected]
        : styles.selection;

    const iconStyle = isSelected
        ? [styles.iconWrapper, styles.selected]
        : styles.iconWrapper;

    const networkNameTextStyle = isSelected
        ? [styles.networkNameText, styles.selected]
        : styles.networkNameText;

    return (
        <Layout
            id={idBuilder(NETWORKS_NETWORK, name)}
            flexDirection="row"
            className={selectionStyle}
            vAlign="center"
            fillParent
            onClick={() => handleClick(network)}>
            <div className={styles.icon}>{renderIcon()}</div>

            <Layout flexDirection="row" vAlign="center">
                <Icon
                    src={networkIcon}
                    size="6"
                    className={iconStyle}
                    placeholder={iconPlaceholder}
                />

                <Text type="body" className={networkNameTextStyle}>
                    {name}
                </Text>
            </Layout>
        </Layout>
    );
};

Network.propTypes = {
    limit: PropTypes.number.isRequired,
    network: PropTypes.shape({
        Name: PropTypes.string,
        A4mediaId: PropTypes.number,
        Code: PropTypes.string
    }).isRequired
};
