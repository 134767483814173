import { createAction } from '@reduxjs/toolkit';
import {
    ADD_AUDIENCE,
    REPLACE_AUDIENCE,
    REPLACE_AUDIENCES,
    REMOVE_AUDIENCE,
    REMOVE_ALL_AUDIENCES,
    ADD_OPTION_TO_AUDIENCE,
    REMOVE_OPTION_FROM_AUDIENCE,
    REMOVE_ALL_OPTIONS_FROM_AUDIENCE,
    REMOVE_CATEGORY_FROM_AUDIENCE,
    SET_CURRENT_AUDIENCE_INDEX
} from '../constants';

export const addAudience = createAction(ADD_AUDIENCE);

export const removeAudience = createAction(REMOVE_AUDIENCE, audienceIndex => {
    return {
        payload: { audienceIndex }
    };
});

export const removeAllAudiences = createAction(REMOVE_ALL_AUDIENCES);

export const addOptionToAudience = createAction(
    ADD_OPTION_TO_AUDIENCE,
    (audienceIndex, option) => {
        return {
            payload: { audienceIndex, option }
        };
    }
);

export const removeOptionFromAudience = createAction(
    REMOVE_OPTION_FROM_AUDIENCE,
    (audienceIndex, optionId) => {
        return {
            payload: { audienceIndex, optionId }
        };
    }
);

export const removeAllOptionsFromAudience = createAction(
    REMOVE_ALL_OPTIONS_FROM_AUDIENCE,
    audienceIndex => {
        return {
            payload: { audienceIndex }
        };
    }
);

export const removeCategoryFromAudience = createAction(
    REMOVE_CATEGORY_FROM_AUDIENCE,
    (audienceIndex, categoryName) => {
        return {
            payload: { audienceIndex, categoryName }
        };
    }
);

export const setCurrentAudienceIndex = createAction(
    SET_CURRENT_AUDIENCE_INDEX,
    audienceIndex => {
        return {
            payload: { audienceIndex }
        };
    }
);

export const replaceAudience = createAction(
    REPLACE_AUDIENCE,
    (audienceIndex, newAudience) => {
        return {
            payload: { audienceIndex, newAudience }
        };
    }
);

export const replaceAudiences = createAction(REPLACE_AUDIENCES, newAudience => {
    return {
        payload: newAudience
    };
});

export const resetAudiencesOrder = audienceIndex => {
    return (dispatch, getState) => {
        const {
            audience: { audiences }
        } = getState();
        if (audiences.length > 1 && !audiences[audienceIndex].length)
            dispatch(removeAudience(audienceIndex));
    };
};
