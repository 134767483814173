import requestHandlers from '@requestHandlers';

export const audienceNameExists = async ({
    savedAudiences,
    audienceName,
    externalId,
    campaignType
}) => {
    let savedAudienceList;
    if (!savedAudiences.length) {
        const response = await requestHandlers.audiences
            .getUserAssociatedSavedAudiences(externalId, campaignType)
            .catch(err => {
                console.error('error loading audiences', err);
            });
        savedAudienceList = response.data;
    } else {
        savedAudienceList = savedAudiences;
    }

    return savedAudienceList.find(
        audience => audience.name.toLowerCase() === audienceName.toLowerCase()
    );
};
