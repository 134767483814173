import { createReducer } from '@reduxjs/toolkit';
import { SET_MAPS, ADD_MAP_IMAGE_DATA_URL } from '../constants';

const initialState = {
    usaMps: '',
    usaBooking: '',
    alaskaMps: '',
    hawaiiMps: ''
};

export const mapImageDataUrls = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_MAPS, (state, action) => {
            const { value } = action.payload;
            return { ...state, value };
        })
        .addCase(ADD_MAP_IMAGE_DATA_URL, (state, action) => {
            const { id, mapImageDataUrl } = action.payload;

            return { ...state, [id]: mapImageDataUrl };
        })
        .addDefaultCase((state, _) => state);
});
