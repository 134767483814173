const UPLOAD_ANIMATION_DURATION = 4000;

export default async (a) => {
    const fileUploadAnimation = a.getRunning();

    fileUploadAnimation.pause();

    const duration = UPLOAD_ANIMATION_DURATION;
    const run = function (anim) {
        const el = document.querySelector('.file-upload-svg .stop-bt-animation');
        const fileUploadAnimationProgress = !isNaN(fileUploadAnimation.progress) ? fileUploadAnimation.progress : 0;
        if (el) el.setAttribute('offset', Math.round(anim.progress + fileUploadAnimationProgress) / 100 + 0.1);
    };

    await a.runAnimation('proceedFileUpload', {
        duration,
        run
    }).finished;
};
