import { datesHandler, timezoneHandler, sortArrayOfObjectsByProp } from '@lib';
import { POLITICAL, COMMERCIAL } from '@config';
import { scheduleConfig } from '@config/config.schedule';

const { rules } = scheduleConfig;

export const getFullWeeks = (startDate, endDate) => {
    const totalDays = datesHandler.differenceInDays(endDate, startDate);

    const startDayNumber = datesHandler.getWeekDayNumber(startDate);
    const endDayNumber = datesHandler.getWeekDayNumber(endDate);
    let daysToAddToFirstWeek = 0;
    let daysToAddToLastWeek = 0;

    if (!isNaN(startDayNumber) && startDayNumber !== 1)
        daysToAddToFirstWeek = Math.abs(1 - startDayNumber);
    if (!isNaN(endDayNumber) && endDayNumber !== 7)
        daysToAddToLastWeek = 7 - endDayNumber;

    const totalDaysToAdd = daysToAddToFirstWeek + daysToAddToLastWeek;
    const totalBroadCastDays = totalDaysToAdd + totalDays + 1;

    return parseInt(totalBroadCastDays / 7) || 0;
};

export const getWeeksLabel = ({ weeks, startDate, endDate }) => {
    const exp = wks => (wks > 1 ? 'weeks' : 'week');

    if (weeks) return exp(weeks);

    const _weeks = getFullWeeks(startDate, endDate);
    return exp(_weeks);
};

export const getDatesText = (startDate, endDate, showYear = false) => {
    const startDateYear = datesHandler.getYear(startDate);
    const endDateYear = datesHandler.getYear(endDate);

    const _startDateText =
        startDateYear === endDateYear && !showYear
            ? datesHandler.format(startDate, 'LLL d')
            : datesHandler.format(startDate, 'LLL d, yyyy');

    const _endDateText = datesHandler.format(endDate, 'LLL d, yyyy');

    return {
        startDateText: _startDateText,
        endDateText: _endDateText
    };
};

export const mapHiatusWeeksDataForUi = hiatusWeeks => {
    const mappedHiatusWeek = {};
    const sortedHiatusWeeks = sortArrayOfObjectsByProp(
        hiatusWeeks,
        'weekNumber',
        false
    );

    sortedHiatusWeeks.forEach(weeks => {
        const from = weeks.week[0];
        const to = weeks.week[weeks.week.length - 1];
        const year = datesHandler.getYear(to);
        const weekStartDateMonth = datesHandler.getMonth(from);
        const weekEndDateMonth = datesHandler.getMonth(to);
        const weekStartDate = datesHandler.format(from, 'LLL d');
        const weekEndDate =
            weekStartDateMonth === weekEndDateMonth
                ? datesHandler.format(to, 'd')
                : datesHandler.format(to, 'LLL d');

        const hiatusWeek = {
            from: weekStartDate,
            to: weekEndDate
        };

        if (!mappedHiatusWeek[year]) mappedHiatusWeek[year] = [];

        mappedHiatusWeek[year].push(hiatusWeek);
    });

    return mappedHiatusWeek;
};

export const convertScheduleDateStrsToInstances = schedule => {
    const _schedule = { ...schedule };

    const _pattern = /T\d\d:00:00\+00:00/;
    const _startDate = _schedule.startDate.replace(_pattern, '');
    const _endDate = _schedule.endDate.replace(_pattern, '');

    _schedule.startDate = datesHandler.parseISO(_startDate);
    _schedule.endDate = datesHandler.parseISO(_endDate);

    if (_schedule.hiatusWeeks && _schedule.hiatusWeeks.length) {
        _schedule.hiatusWeeks = _schedule.hiatusWeeks.map(
            ({ startDate, endDate }) => {
                const week = datesHandler.eachDayOfInterval({
                    start: datesHandler.parseISO(startDate),
                    end: datesHandler.parseISO(endDate)
                });
                return {
                    week,
                    weekNumber: datesHandler.getWeekNumber(week[3])
                };
            }
        );
    }

    return _schedule;
};

export const getNumDisabledDaysForRole = role => {
    if (typeof role !== 'string') return;
    switch (role) {
        case COMMERCIAL:
            return rules[COMMERCIAL].futureDaysToDisable;
        case POLITICAL:
            return rules[POLITICAL].futureDaysToDisable;
        default:
            return 0;
    }
};

export const isMinError = (duration, difference, role) => {
    if (!duration) return false;
    switch (role) {
        case COMMERCIAL:
            return duration < difference;
        case POLITICAL:
            return duration < difference;
        default:
            return false;
    }
};

export const isMaxError = (duration, difference) => {
    if (!duration) return false;
    return duration > difference;
};

// start and end are Date objects
export const planDurationErrorType = (
    duration,
    minPlanDuration,
    maxPlanDuration,
    role
) => {
    const minError = isMinError(duration, minPlanDuration, role);
    const maxError = isMaxError(duration, maxPlanDuration);
    return minError ? 'min' : maxError ? 'max' : null;
};

export const getStartDateBasedOnTime = (hourNum = 0) => {
    if (!hourNum && typeof hourNum !== 'number') return;

    return hourNum >= rules.boundHour
        ? datesHandler.addDays(Date.now(), 1)
        : datesHandler.toDate(Date.now());
};

export const returnFutureDateForRole = (
    from = datesHandler.toDate(Date.now()),
    amount,
    role
) => {
    if (!from || !amount || !role) return;
    if (![POLITICAL, COMMERCIAL].includes(role)) return;

    if (role === COMMERCIAL) {
        return datesHandler.isTomorrow(from)
            ? datesHandler.businessAddToDate(from, amount + 1)
            : datesHandler.addDays(from, amount);
    }
    return datesHandler.addDays(from, amount);
};

export const disabledDatesService = ({ from, to, role, hiatusWeeks }) => {
    // *** Commented out for this release, will need to be fixed in the future;

    // const currentTime = timezoneHandler.getTimeInTimezone(rules.boundTimeZone);

    // const businessDayRule = getDayBasedOnTime(currentTime);

    // controls all disabled dates previous to today, inclduing rules disable days X number of days
    // ahead of today

    // handles disabling all days before today and X days from today based on rules in config file
    const disabledDaysInPast = {
        before: datesHandler.endOfTomorrow()
    };

    // used to disable days starting from today to X days in the future
    const disableDaysAheadOfToday = {
        before: returnFutureDateForRole(
            datesHandler.endOfTomorrow(),
            getNumDisabledDaysForRole(role),
            role
        )
    };

    let disabledDaysFromSelectedDate = [];
    // controls action once a start date has been selected
    if (from && !to) {
        disabledDaysFromSelectedDate = datesHandler.eachDayOfInterval({
            start: from,
            end: returnFutureDateForRole(
                from,
                getNumDisabledDaysForRole(role),
                role
            )
        });
    }

    const disabledDates = [
        disabledDaysInPast,
        disableDaysAheadOfToday,
        ...disabledDaysFromSelectedDate
    ];

    return hiatusWeeks
        .map(weekNumber => weekNumber.week)
        .flat()
        .concat(disabledDates);
};

export const effectiveDurationService = ({
    startDate = '',
    endDate = '',
    hiatusWeeks = []
}) => {
    let campaignDateRange = [];
    let flatHiatusDates = [];
    let filteredDates = [];

    if (datesHandler.isDate(startDate) && datesHandler.isDate(endDate)) {
        campaignDateRange = datesHandler.eachDayOfInterval({
            start: startDate,
            end: endDate
        });
    }

    if (hiatusWeeks.length) {
        flatHiatusDates = hiatusWeeks
            .slice()
            .sort((a, b) => (a.weekNumber > b.weekNumber ? 1 : -1))
            .flatMap(({ week }) => {
                return week;
            });
        // index is used to ensure range of dates starting with earliest hiatus week date is removed.
        // ex: hiatus week date range could occur occur in the middle of 4 week range
        let index = 0;
        filteredDates = campaignDateRange.filter(day => {
            if (datesHandler.isSameDay(day, flatHiatusDates[index])) {
                index++;
                return false;
            }
            return true;
        });
    }

    return hiatusWeeks.length ? filteredDates.length : campaignDateRange.length;
};
