import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Text, Content, Bar } from 'maslow';

import { strf, getPercentFromValue, isGreaterThanZero } from '@lib';
import SubCampaign from './SubCampaign';

import styles from './digitalDetails.scss';

const buildSubCampaignsImpression = (
    subCampaigns,
    channelImpression,
    style
) => {
    return subCampaigns.map((subCampaign, i) => {
        return (
            <SubCampaign
                key={i}
                subCampaign={subCampaign}
                channelImpression={channelImpression}
                style={style}
            />
        );
    });
};

const Channel = props => {
    const { channel, digitalImpression, style, icon } = props;
    const impressionFormatted = strf(channel.impression).commas().value();
    const percent =
        getPercentFromValue(channel.impression, digitalImpression) || 0;
    const percentFormatted = strf(percent).append('%').value();
    const isImpressionGreaterThanZero = isGreaterThanZero(channel.impression);
    const subcampaignsImpression = buildSubCampaignsImpression(
        channel.subCampaigns,
        channel.impression,
        style
    );

    const iconStyle = {
        backgroundColor: style.primaryColor,
        boxShadow: style.shadowColor
    };

    return (
        isImpressionGreaterThanZero && (
            <Content className={styles.contentPadding}>
                <Layout flexDirection="row" vAlign="center">
                    <Layout vAlign="center" className={styles.leftWrapper}>
                        <Layout
                            hAlign="flex-end"
                            className={styles.iconWrapper}>
                            <Layout
                                hAlign="center"
                                vAlign="center"
                                style={iconStyle}
                                className={styles.channelIconWrapper}>
                                <img src={icon} className={styles.icon} />
                            </Layout>
                        </Layout>

                        <Layout className={styles.iconCaption}>
                            <Text type="h5">{channel.name}</Text>
                        </Layout>
                    </Layout>

                    <Layout flexDirection="column" vAlign="center" fillParent>
                        <Bar percentage={percent} theme={style.theme} />

                        <Layout flexDirection="row" alignSelf="flex-end">
                            <Text type="h5">{impressionFormatted} |</Text>
                            <Text type="h5" className={styles.leftPad}>
                                {percentFormatted}
                            </Text>
                        </Layout>
                    </Layout>
                </Layout>

                {subcampaignsImpression}
            </Content>
        )
    );
};

Channel.propTypes = {
    channel: PropTypes.object.isRequired,
    digitalImpression: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired,
    icon: PropTypes.string.isRequired
};

export default Channel;
