import { SignUp } from '../components/pages';
import featuresManager from '@featuresManager';

export default [
    {
        name: 'signUp',
        url: '/sign-up',
        views: {
            '@': {
                component: SignUp
            }
        },
        onEnter: () => {
            featuresManager.setFeatureState('signUp');
        }
    }
];