import { createAction } from '@reduxjs/toolkit';
import { SET_IS_BROADCAST } from '../constants';

export const setIsBroadcast = createAction(SET_IS_BROADCAST, isBroadcast => {
    return {
        payload: {
            isBroadcast
        }
    };
});
