import ReactDOM from 'react-dom';
import React from 'react';
import { Provider } from 'react-redux';
import { UIRouter, UIView, pushStateLocationPlugin } from '@uirouter/react';

import { store } from '@state';
import { initializeFeatureSystem } from '@components/features/init';
import './state';
import './scss/main.scss';
import { EventEmitter } from 'fbemitter';
import states from './routes';
import routeConfig from './routeConfig';

window.emitter = new EventEmitter();

const init = async () => {
    await initializeFeatureSystem();

    ReactDOM.render(
        <Provider store={store}>
            <UIRouter
                plugins={[pushStateLocationPlugin]}
                states={states}
                config={routeConfig}>
                <UIView />
            </UIRouter>
        </Provider>,
        document.getElementById('a4-app')
    );
};

init();
