import { createAction, createAsyncThunk } from '@reduxjs/toolkit';
import requestHandlers from '@requestHandlers';
import { convertOldLocations, filterOldGeoJson } from '@services/locations';

import { SET_MAPS, ADD_MAP_IMAGE_DATA_URL } from '../constants';

export const setMaps = createAction(SET_MAPS);

// export const getMaps = () => {
//     return (dispatch, getState) => {
//         const {
//             location: { selectedLocations, geoJson }
//         } = getState();
//         const maps = {};
//         requestHandlers.locations
//             .captureMaps(
//                 convertOldLocations(selectedLocations),
//                 filterOldGeoJson(geoJson)
//             )
//             .then(({ data }) => {
//                 data.forEach(({ id, uri }) => (maps[id] = uri));
//                 dispatch(setMaps(maps));
//             });
//     };
// };

// const getMaps = createAsyncThunk(
//     'users/fetchByIdStatus',
//     async (userId, thunkAPI) => {
//         const response = await userAPI.fetchById(userId);
//         return response.data;
//     }
// );

export const addMapImageDataUrl = createAction(
    ADD_MAP_IMAGE_DATA_URL,
    (id, mapImageDataUrl) => {
        return {
            payload: {
                id,
                mapImageDataUrl
            }
        };
    }
);
