import { createReducer } from '@reduxjs/toolkit';
import { TOGGLE_IS_SUMMARY_SCROLLBAR } from '../constants';

const initialState = false;

const toggleIsSummaryScrollbar = (state, action) => {
    const { isVisible } = action.payload;
    return typeof isVisible === 'boolean' ? isVisible : state;
};

export const isSummaryScrollbar = createReducer(initialState, reducer => {
    reducer
        .addCase(TOGGLE_IS_SUMMARY_SCROLLBAR, toggleIsSummaryScrollbar)
        .addDefaultCase((state, _) => state);
});
