import React, { useEffect, useState, useRef, memo } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { createSelector } from 'reselect';
import L from 'leaflet';
import leafletImage from 'leaflet-image';

import { addMapImageDataUrl } from '@state/location/actions/mapImageDataUrls';
import { LOCATION_TYPES } from '@config/config.maps';
import {
    isSelected,
    layerExists,
    layerZipDotExists,
    buildMarkerSettings,
    getStates,
    getLocations,
    getZoom
} from '@services/locations';
import {
    statesSelector,
    geoJsonSelector,
    locationsEmptySelector,
    createLocationsSelector,
    createGeoJsonLocationSelector
} from '@selectors/location';
import { useWindowSize } from '@hooks';
import { HAWAII, ALASKA } from '@config/config.maps';

const { ZIPCODE } = LOCATION_TYPES;

const Map = memo(({ config, mapData, labelData, isState }) => {
    const {
        mapRegion,
        id,
        latLng,
        zoom,
        styles,
        labels,
        labelAnchor,
        image,
        zoomControl,
        minZoom,
        maxZoom,
        keyboard,
        dragging,
        scrollWheelZoom,
        zoomAnimation,
        touchZoom,
        maxBounds,
        animate
    } = config;

    const dispatch = useDispatch();

    const statesGeoJson = getStates(mapRegion, mapData);
    const mapRef = useRef(null);
    const stateNamesRef = useRef(null);
    const locationsRef = useRef(null);
    const stateLayerRef = useRef(null);

    const [currentZoom, setCurrentZoom] = useState(null);

    const locations = useSelector(createLocationsSelector(mapRegion));
    const hiLocations = useSelector(
        createLocationsSelector(HAWAII.initials.toUpperCase())
    );
    const akLocations = useSelector(
        createLocationsSelector(ALASKA.initials.toUpperCase())
    );
    const locationsEmpty = useSelector(locationsEmptySelector);
    const states = useSelector(statesSelector);
    const geoJson = useSelector(createGeoJsonLocationSelector(mapRegion));

    //Allows map zoom to change on window resize
    const windowSize = useWindowSize();

    const generateMapImage = () => {
        if (mapRef.current) {
            leafletImage(mapRef.current, (err, canvas) => {
                const imgUrl = canvas.toDataURL();
                dispatch(addMapImageDataUrl(id, imgUrl));
            });
        }
    };

    const addLocation = geo => {
        const geoJsons = geo.geoJsons.map(json => json.geoJson);

        addZipDots(geoJsons);
        L.geoJSON(geoJsons, {
            style: styles.selectedLocationStyle,
            id: geo.id
        }).addTo(locationsRef.current);
    };

    const addZipDots = geo => {
        if (geo.audienceCategory === ZIPCODE) {
            const geoJsons = geo.geoJsons.map(json => json.geoJson);
            if (geoJsons.length) {
                const { lat, lng } = L.geoJSON(geoJsons)
                    .getBounds()
                    .getCenter();

                L.circleMarker([lat, lng], { zipDots: true })
                    .setStyle(styles.selectedZipStyle)
                    .addTo(locationsRef.current);
            }
        }
    };

    const addLabel = (options, type, textLatLng, selected) => {
        L.marker(textLatLng, {
            icon: L.icon(
                buildMarkerSettings(
                    styles,
                    labelAnchor,
                    options,
                    type,
                    textLatLng,
                    selected
                )
            )
        }).addTo(stateNamesRef.current);
    };

    const generateMap = () => {
        (mapRef.current = L.map(id, {
            zoomControl: zoomControl,
            minZoom: minZoom,
            zoomSnap: 0.1,
            keyboard: keyboard,
            dragging: dragging,
            doubleClickZoom: false,
            scrollWheelZoom: scrollWheelZoom,
            zoomAnimation: zoomAnimation,
            touchZoom: touchZoom,
            attributionControl: false,
            preferCanvas: true,
            maxBounds
        }).setView(latLng, zoom || getZoom(window.innerWidth))),
            (stateLayerRef.current = L.geoJSON(statesGeoJson, {
                onEachFeature: feature =>
                    (feature.selected = isSelected(
                        feature.properties.id,
                        locations
                    ))
            }));

        stateLayerRef.current.eachLayer(layer => {
            const selected = isSelected(layer.feature.properties.id, locations);

            const style = locationsEmpty
                ? styles.initialStateStyle
                : selected
                ? styles.selectedStateStyle
                : styles.defaultStateStyle;
            layer.setStyle(style);
        });

        mapRef.current.addLayer(stateLayerRef.current);

        locationsRef.current = new L.featureGroup();
        geoJson.forEach(async geo => {
            addLocation(geo);
        });
        mapRef.current.addLayer(locationsRef.current);

        if (labels) {
            stateNamesRef.current = new L.featureGroup();
            stateLayerRef.current.eachLayer(layer => {
                const {
                    feature: { properties, selected }
                } = layer;
                let lat = layer.getBounds().getCenter().lat;
                let lng = layer.getBounds().getCenter().lng;

                labelData.forEach(state => {
                    if (state.code === properties.code) {
                        const textLatLng = [
                            Number(lat) + Number(state.latOffSet),
                            Number(lng) + Number(state.lngOffSet)
                        ];

                        const updatedSelected =
                            locationsEmpty && image ? true : selected;

                        addLabel(
                            state,
                            properties.type,
                            textLatLng,
                            updatedSelected
                        );
                    }
                });
            });
            mapRef.current.addLayer(stateNamesRef.current);
        }

        updateAutoZoom();
    };

    const updateLocations = () => {
        if (mapRef.current) {
            stateLayerRef.current.eachLayer(layer => {
                const selected = isSelected(
                    layer.feature.properties.id,
                    locations
                );

                const style = locationsEmpty
                    ? styles.initialStateStyle
                    : selected
                    ? styles.selectedStateStyle
                    : styles.defaultStateStyle;

                layer.setStyle(style);
                layer.feature.selected = !layer.feature.selected;
            });

            locationsRef.current.eachLayer(layer => {
                if (!isSelected(layer.options.id, geoJson)) {
                    locationsRef.current.removeLayer(layer);
                }
            });

            geoJson.forEach(async geo => {
                const layer = layerExists(geo, locationsRef.current);
                if (!layer) addLocation(geo);
            });

            if (stateNamesRef.current) {
                stateNamesRef.current.eachLayer(layer => {
                    const { options } = layer.options.icon;
                    const { textLatLng, type } = options;
                    let selected = isSelected(options.id, locations);

                    if (locationsEmpty && image) selected = true;

                    if (selected !== options.selected) {
                        stateNamesRef.current.removeLayer(layer);

                        addLabel(options, type, textLatLng, selected);
                    }
                });
            }
            updateAutoZoom();
        }
    };

    const getLocationBounds = () => {
        return {
            southWest: locationsRef.current.getBounds().getSouthWest(),
            northEast: locationsRef.current.getBounds().getNorthEast()
        };
    };

    const updateAutoZoom = () => {
        let boundsArray = [];
        const { southWest, northEast } = getLocationBounds();

        const pushBounds = bounds =>
            bounds.isValid() && boundsArray.push(bounds);

        if (hiLocations.length || akLocations.length) {
            mapRef.current.setView(latLng, zoom || getZoom(window.innerWidth), {
                maxZoom
            });
        } else if (mapRef.current.zoomControl) {
            stateLayerRef.current.eachLayer(layer => {
                const selected = isSelected(
                    layer.feature.properties.id,
                    locations
                );
                if (selected) {
                    pushBounds(layer.getBounds());
                }
            });

            geoJson.forEach(async geo => {
                if (geo.audienceCategory === ZIPCODE) {
                    const geoJsons = geo.geoJsons.map(json => json.geoJson);
                    pushBounds(L.geoJSON(geoJsons).getBounds());
                }
            });

            if (southWest && northEast) {
                pushBounds(locationsRef.current.getBounds());
            }

            boundsArray.length
                ? mapRef.current.flyToBounds(boundsArray, { maxZoom, animate })
                : mapRef.current.setView(
                      latLng,
                      zoom || getZoom(window.innerWidth),
                      { animate }
                  );
            updateZips();
        }
    };

    const updateZips = () => {
        setCurrentZoom(mapRef.current.getZoom());
        locationsRef.current.eachLayer(layer => {
            if (layer.options.zipDots) {
                locationsRef.current.removeLayer(layer);
            }
        });

        if (currentZoom < 6) {
            geoJson.forEach(async geo => {
                const layer = layerZipDotExists(geo, locationsRef.current);
                if (!layer) addZipDots(geo);
            });
        }
    };

    const updateZipsZoom = () => {
        mapRef.current.on('zoomstart zoomend', function (e) {
            updateZips();
        });
    };

    const toggleAkAndHiMapOnZoom = () => {
        mapRef.current.on('zoomend moveend', function (e) {
            const currentZoom = mapRef.current.getZoom();
            const alaskaEl = document.getElementById('alaska');
            const hawaiiEl = document.getElementById('hawaii');
            const zoomThreshold =
                currentZoom > 6.8 ||
                mapRef.current.getBounds()._southWest.lat / currentZoom > 3.3;
            if (alaskaEl && hawaiiEl) {
                if (zoomThreshold) {
                    alaskaEl.style.display = 'none';
                    hawaiiEl.style.display = 'none';
                } else {
                    alaskaEl.style.display = 'block';
                    hawaiiEl.style.display = 'block';
                }
            }
        });
    };

    useEffect(() => {
        setTimeout(() => {
            generateMap();
            if (id === 'usa') toggleAkAndHiMapOnZoom();
        }, 250);
    }, []);

    useEffect(() => {
        setTimeout(() => {
            updateZipsZoom();
        }, 500);
    }, [geoJson]);

    useEffect(() => {
        updateLocations();

        return () => {
            // make sure the location state has changed before generating data urls
            if (
                image &&
                !isState('app.plan.location') &&
                !isState('app.ranker.location')
            ) {
                generateMapImage();

                geoJson.forEach(async geo => {
                    const layer = layerExists(geo, locationsRef.current);
                    if (!layer) addLocation(geo);
                });

                if (stateNamesRef.current) {
                    stateNamesRef.current.eachLayer(layer => {
                        const { options } = layer.options.icon;
                        const { textLatLng, type } = options;
                        const selected = isSelected(options, locations);

                        if (selected !== options.selected) {
                            stateNamesRef.current.removeLayer(layer);
                            addLabel(options, type, textLatLng, selected);
                        }
                    });
                }
            }
        };
    }, [geoJson, states]);

    return <div id={id} style={styles.wrapper} key={id} />;
});

export default Map;
