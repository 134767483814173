import React from 'react';

import { Layout, Icon, Text } from 'maslow';
import logoSvg from '@public/img/athena.svg';

import style from './styles.scss';

export const TopLogoSection = ({ displayText, isLogin }) => {
    const { title, subTitle } = displayText;

    const logoStyle = isLogin ? { marginBottom: '40px' } : {};
    const subTitleStyle = isLogin ? { marginBottom: '56px' } : {};

    return (
        <React.Fragment>
            <Layout className={style.logSvg} hAlign="center">
                <Icon src={logoSvg} size="24" />
            </Layout>

            <div className={style.title}>
                <Text type="h4">{title}</Text>
            </div>

            <div style={subTitleStyle} className={style.subTitle}>
                <Text color="echoBlue">{subTitle}</Text>
            </div>
        </React.Fragment>
    );
};
