import { buildSets, buildSelected } from '.';
import { isEmpty } from '@lib';

export const combineAudiences = arrays => {
    return arrays.map(array => {
        return {
            locations: array.filter(segment => {
                return segment.section === 'Location';
            }),
            audience: array.filter(segment => {
                return segment.section !== 'Location';
            })
        };
    });
};

export const buildHHCountPayload = (
    audiences,
    combinations,
    locations = []
) => {
    const locationsArray =
        locations.map(location => ({
            ...location,
            section: 'Location'
        })) || [];

    const audienceArrays = !audiences.length
        ? [locationsArray]
        : locationsArray.length
        ? audiences.map(audience => [...audience, ...locationsArray])
        : audiences;

    const combinedAudiences = combineAudiences(audienceArrays);

    const originalSets = audiences.length
        ? buildSets(combinedAudiences)
        : buildSets([locationsArray]);

    const selected = isEmpty(combinations)
        ? buildSelected(originalSets)
        : combinations;

    return { selected, originalSets, audiences: combinedAudiences };
};
