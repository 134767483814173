import jsPDF from 'jspdf';
import './autotable';

(function(API) {
    API.centeredText = function(txt, y) {
        const fontSize = this.internal.getFontSize();
        const pageWidth = this.internal.pageSize.width;

        const txtWidth =
            (this.getStringUnitWidth(txt) * fontSize) /
            this.internal.scaleFactor;

        const x = (pageWidth - txtWidth) / 2;

        this.text(txt, x, y);

        return this;
    };
})(jsPDF.API);

export default jsPDF;
