import React from 'react';
import featuresManager from '@featuresManager';

export default (section, c, customProps = {}) => {
    const componentFunctions = [];
    const configs = featuresManager.filterFeaturesByLibrary(
        'react',
        featuresManager.getFeatures(section)
    );

    const sortedConfigs = featuresManager.sortOrder([...configs]);

    sortedConfigs.forEach(feature => {
        componentFunctions.push(
            require(`../../../app/components/features/${feature.name}/component`)
                .component
        );
    });

    const components = componentFunctions.map((Component, i) => {
        return (
            <Component
                key={sortedConfigs[i].name}
                data={sortedConfigs[i].data}
                feature={sortedConfigs[i]}
                features={sortedConfigs}
                {...c.props}
                {...customProps}
            />
        );
    });

    return {
        components,
        configs: sortedConfigs
    };
};
