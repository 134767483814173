import React from 'react';
import { useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { Layout, Text } from 'maslow';
import SelectedLocationCategory from './SelectedLocationCategory';
import { strf } from '@lib';
import { hasLocations } from '@services/audiencesService';
import {
    locationSelector,
    selectedLocationsSelector,
    showHawaiiAndAlaskaSelector
} from '@selectors/location';
import { locationDataSelector, amountsSelector } from '@selectors/audience';
import style from './summary.scss';

const stylesSelector = createSelector(
    selectedLocationsSelector,
    selectedLocations => {
        return {
            locationCountStyle:
                selectedLocations.length > 9 ? style.counts : style.count,
            mapWrapper: !hasLocations(selectedLocations) ? style.mapWrapper : ''
        };
    }
);

const Summary = () => {
    const locations = useSelector(locationSelector);
    const {
        selectedLocations,
        locationArray,
        locationTitle,
        hasLocationsResult
    } = useSelector(locationDataSelector);
    const { locationCountStyle, mapWrapper } = useSelector(stylesSelector);
    const { percentage, hhCount } = useSelector(amountsSelector);
    const showHawaiiAndAlaska = useSelector(showHawaiiAndAlaskaSelector);

    return (
        <Layout flexDirection="column" className={style.wrapper}>
            <Text type="h4" className={style.header}>
                Summary
            </Text>

            <Layout flexDirection="row" className={style.mainWrapper}>
                <Layout flexDirection="column">
                    <Text type="body">Households</Text>
                    <Text type="h4" className={style.householdCount}>
                        {hhCount ? strf(hhCount).commas().value() : ''}
                    </Text>
                    <div className={style.percentageBubble}>
                        <Text
                            type="body"
                            color="vividNavy"
                            className={style.bubbleText}>
                            {percentage ? 'or ' + percentage + '%' : ''}
                        </Text>
                    </div>
                </Layout>
                <Layout
                    flexDirection="column"
                    vAlign="center"
                    hAlign="center"
                    className={mapWrapper}>
                    <img
                        className={style.map}
                        src={locations.mapImageDataUrls.usaMps}
                    />
                    {showHawaiiAndAlaska && (
                        <Layout flexDirection="row" vAlign="center">
                            <img
                                className={style.subMap}
                                src={locations.mapImageDataUrls.alaskaMps}
                            />
                            <img
                                className={style.subMap}
                                src={locations.mapImageDataUrls.hawaiiMps}
                            />
                        </Layout>
                    )}
                </Layout>
                <Layout
                    flexDirection="column"
                    className={style.locationRightSection}>
                    <Layout flexDirection="row" vAlign="center">
                        <div className={style.circle}>
                            {hasLocationsResult && (
                                <Text type="h5" className={locationCountStyle}>
                                    {selectedLocations.length}
                                </Text>
                            )}
                        </div>
                        <Text className={style.locationTitle} type="h5">
                            {locationTitle}
                        </Text>
                    </Layout>
                    {hasLocationsResult && (
                        <SelectedLocationCategory
                            locationArray={locationArray}
                        />
                    )}
                </Layout>
            </Layout>
        </Layout>
    );
};

export default Summary;
