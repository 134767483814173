import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { removeExcludedNetwork } from '@state/networks/actions/excludedNetworks';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { Text, Layout, Icon } from 'maslow';
import { reMount } from '@components/hoc';
import styles from '../styles.scss';

import { PLAN_STATUSES } from '@config/config.planStatus';

import { NETWORKS_SUMMARY_SELECTED_NETWORK } from '@config/idConstants';
import { idBuilder } from '@services/idBuilder';

export const SelectedNetwork = ({ minusIcon, excludedNetwork }) => {
    const dispatch = useDispatch();

    const onClickHandler = () => {
        dispatch(removeExcludedNetwork(excludedNetwork.A4mediaId));
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    const renderIcon = name => {
        const IconComponent = reMount(Icon);

        return (
            <div
                id={idBuilder(NETWORKS_SUMMARY_SELECTED_NETWORK, name)}
                onClick={onClickHandler}
                className={styles.selectedNetworkIcon}>
                <IconComponent src={minusIcon} className={styles.icon} />
            </div>
        );
    };

    return (
        <Layout
            flexDirection="row"
            vAlign="center"
            className={styles.selectedNetworksWrapper}>
            <div className={styles.selectedNetworkIcon}>
                {renderIcon(excludedNetwork.Name)}
            </div>

            <Text type="body" className={styles.selectedNetworkText}>
                {excludedNetwork.Name}
            </Text>
        </Layout>
    );
};

SelectedNetwork.propTypes = {
    excludedNetwork: PropTypes.object.isRequired,
    minusIcon: PropTypes.node.isRequired
};
