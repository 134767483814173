export default {
    name: 'Login', // Component name
    description: 'Login form',
    library: 'react',
    versions: {
        prod: '1.3.0',
        sim: '1.3.0',
        ltd: '1.3.0',
        beta: '1.3.0',
        alpha: '1.3.0'
    },
    states: ['login'],
    sections: ['login'],
    permissions: [],
    data: {
        titles: {
            initial: {
                title: 'Welcome,',
                subTitle: 'please log in to your account',
                button: 'Log In'
            },
            forgotPassword: {
                title: 'Forgot password?',
                subTitle:
                    "Please enter your email and we'll send you a link to reset your password",
                button: 'Send the link'
            },
            newPassword: {
                title: 'Reset password',
                subTitle: '',
                button: 'Reset'
            },
            passwordLinkSent: {
                title: 'Almost there',
                subTitle:
                    'The link has been sent, please check your inbox for it.',
                button: ''
            },
            passwordChanged: {
                title: 'Well done!',
                subTitle: 'Your password successfully changed.',
                button: 'Sign In'
            }
        }
    },
    attributes: {},
    modifications: {}
};
