import request from '../request';

export default function (customListId) {
    return request({
        method: 'GET',
        url: `custom_list/${customListId}/`,
        headers: {}
    }).catch(error => {
        throw Error(error);
    });
};
