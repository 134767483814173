import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { datesHandler } from '@lib';
import {
    createDefaultChannels,
    createDefaultSubCampaigns,
    getStatus
} from '@services';

import {
    setAudienceType,
    setTotalHouseholdCount,
    setHouseholdCount
} from '@state/audience/actions';
import { setStartDate, setEndDate, setDuration } from '@state/schedule/actions';
import {
    setTotalBudget,
    updateChannel,
    addChannels,
    updateSubCampaign,
    addSubCampaigns
} from '@state/budget/actions';

import { budgetConfig } from '@config/config.budget';

import { Button, Layout, Text } from 'maslow';
import { POPULATE_DATA } from '@config/idConstants';

const { distribution } = budgetConfig;

const dispatchSubCampaignUpdates = (dispatch, distribution, region) => {
    distribution.channels.forEach(channel => {
        const { name, subCampaigns } = channel;
        const subCampaignBudget = 20000.33;
        const channelBudget = subCampaignBudget * channel.subCampaigns.length;

        subCampaigns.forEach(subCampaign => {
            const config = distribution.subCampaigns[subCampaign];

            const status = getStatus(
                subCampaignBudget,
                config.min[region],
                true,
                channelBudget
            );
        });
    });
};

export default function (version, feature) {
    const {
        audienceType,
        totalHouseholdCount,
        householdCount,
        totalBudget,
        startDateDaysToAdd,
        endDateDaysToAdd,
        campaignDuration
    } = feature.data;

    const PopulateUIButton = props => {
        const region = useSelector(state => state.plan.attributes.region);

        const dispatch = useDispatch();

        const handleClick = () => {
            const startDate = datesHandler.newDate(startDateDaysToAdd);
            const endDate = datesHandler.addToDate(startDate, endDateDaysToAdd);

            dispatch(setDuration(campaignDuration));
            dispatch(setAudienceType(audienceType));
            dispatch(setTotalHouseholdCount(totalHouseholdCount));
            dispatch(setHouseholdCount(householdCount));
            dispatch(setStartDate(startDate));
            dispatch(setEndDate(endDate));
            dispatchSubCampaignUpdates(dispatch, distribution, region);
        };

        return (
            <Layout flexDirection="column" fillParent>
                <Button
                    id={POPULATE_DATA}
                    onClick={handleClick}
                    style={{ padding: 0 }}>
                    <Text type="caption">Populate Data</Text>
                </Button>
            </Layout>
        );
    };

    return PopulateUIButton;
}
