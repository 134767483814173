import { validateArg, isEmpty } from '@lib';
import { SAVED_AUDIENCES } from '@config/';

export const formatAudienceArrays = audiences => {
    validateArg(audiences, 'array');

    if (audiences.length === 1 && isEmpty(audiences[0])) return null;

    return audiences.map(audience => {
        return audience[0]?.category === SAVED_AUDIENCES
            ? {
                  name: audience[0].name,
                  myAudienceId: audience[0].id,
                  values: null,
                  id: null
              }
            : {
                  name: null,
                  id: null,
                  values: audience.map(
                      ({
                          name,
                          value,
                          id,
                          categoryValue,
                          category,
                          section
                      }) => ({
                          name,
                          value,
                          externalId: id,
                          categoryValue,
                          category,
                          section
                      })
                  )
              };
    });
};

export const formatAudienceCombinations = combinations => {
    validateArg(combinations, 'object');
    if (isEmpty(combinations)) return null;
    return Object.values(combinations).map(({ sets }) => sets);
};
