import demoSvg from '@public/img/demo.svg';
import autoSvg from '@public/img/auto.svg';
import lifestyleSvg from '@public/img/lifestyle.svg';
import politicsSvg from '@public/img/politics.svg';
import viewershipSvg from '@public/img/viewership.svg';

export const navIcons = {
    1: demoSvg,
    2: lifestyleSvg,
    3: politicsSvg,
    4: autoSvg,
    5: viewershipSvg
};
