export const matchConditionals = {
    isEqualTo: (expressionArray, conditionalArray) => {
        const filteredConditionals = conditionalArray.filter(conditional => {
            const filteredExpressions = expressionArray.filter(
                (expression, i) => {
                    return expression === conditional[i];
                }
            );
            return filteredExpressions.length === expressionArray.length;
        });
        return filteredConditionals.length ? true : false;
    },
    isLessThanOrEqual: (expression, conditional) => expression <= conditional
};
