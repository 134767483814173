import { createAction } from '@reduxjs/toolkit';
import { SET_IS_ON_BOOK_PAGE } from '../constants';

export const setIsOnBookPage = createAction(
    SET_IS_ON_BOOK_PAGE,
    isOnBookPage => {
        return {
            payload: {
                isOnBookPage
            }
        };
    }
);
