import { strf, round, isLessThanOne } from '@lib';
import { MARGIN_10, CELL_WIDTH_01, COLOR_BLUE } from '../constants';
import NETWORK_MAP from '@components/features/MediaPlan/1.0.0/NetworkDistribution/networks.json';

export const buildNetworksData = (
    doc,
    counter,
    networks,
    tvImpressionsValue
) => {
    const table = {
        startY: counter.add(MARGIN_10),
        headStyles: { fillColor: COLOR_BLUE, cellWidth: CELL_WIDTH_01 },
        head: [['Network', 'Impressions', 'Percentage']],
        body: [],
        didDrawPage: data => {
            counter.set(counter.count + data.table.height);
        }
    };

    networks.forEach(network => {
        const networkImpressions = round(
            (tvImpressionsValue * network.DeliveryEstimate) / 100
        );
        const percentage = round(network.DeliveryEstimate);
        const name = NETWORK_MAP[network.Network]
            ? NETWORK_MAP[network.Network].name
            : network.Network;

        table.body.push([
            name,
            strf(networkImpressions)
                .commas()
                .value(),
            `${isLessThanOne(round(percentage, true))}%`
        ]);
    });

    doc.autoTable(table);
};
