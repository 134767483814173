import request from '../request';

export default function (locations, geoJson) {
    const req = {
        method: 'POST',
        url: `locations/captureMaps`,
        data: {
            locations,
            geoJson
        }
    };

    return request(req);
}
