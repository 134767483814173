import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import { Content } from 'maslow';

const NotFound = props => {
    const { components } = getFeatures('notfound', { props });

    return <Content>{components}</Content>;
};

export default withFeatures(NotFound);
