export const getGreatestFromArray = (arr, value, reverse = false) => {
    if (!arr || value === undefined) throw Error('Missing array or value args.');
    if (!Array.isArray(arr)) throw Error('array arg must be of type Array.');
    if (!arr.length) throw Error('You have provided an empty array as a first arg.');
    if (typeof value !== 'number') throw Error('value arg should be a number.');

    let array = [...arr];
    if (reverse) array = array.reverse();

    return array.find(step => {
        if (reverse) return step < value;
        return step > value;
    });
};
