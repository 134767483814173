import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Layout, Text, Popup, Content, Icon, Input, Button } from 'maslow';
import closeSvg from '@public/img/close.svg';
import exportSvg from '@public/img/export.svg';

import { updateAttributes } from '@state/plan/actions/attributes';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import requestHandlers from '@requestHandlers';
import { createMediaPlanPDF, chunkLocationData } from '@services';
import {
    budgetConfigSelector,
    customListSelector,
    showHawaiiAndAlaskaSelector,
    bookPlanObjectSelector,
    getPendoMetaDataSelector,
    mappedDigitalDetailsForUiSelector,
    totalDigitalBudgetSelector,
    totalDigitalImpressionSelector,
    totalDigitalCpmSelector,
    totalImpressionSelector,
    totalCpmSelector,
    digitalProductDetailsSelector
} from '@selectors/';
import { PLAN_STATUSES } from '@config/config.planStatus';
import { pendoService } from '@services/tracking';

import shared from '@sharedStyle/mediaPlanTopbar.scss';
import styles from './styles.scss';

import {
    MEDIA_PLAN_PDF_EXPORT_CLOSE_POPUP,
    MEDIA_PLAN_PDF_EXPORT_PLAN_NAME,
    MEDIA_PLAN_PDF_EXPORT_ADVERTISER_NAME,
    MEDIA_PLAN_PDF_EXPORT_PDF,
    MEDIA_PLAN_PDF_EXPORT_PDF_AND_SAVE
} from '@config/idConstants';
import { amountsSelector } from '@selectors/audience';

const athenaLogoPath = '../../../../public/img/athena-logo-wm-min.png';
const budgetImgPath = '../../../../public/img/budget.png';
const scheduleImgPath = '../../../../public/img/schedule.png';
const cpmImgPath = '../../../../public/img/cpm.png';
const impressionsImgPath = '../../../../public/img/impressions.png';
const rightArrowImgPath = '../../../../public/img/right-arrows.png';
const stripedLineImgPath = '../../../../public/img/striped-line.png';
const rightTriangleImgPath = '../../../../public/img/right-triangle.png';
const uploadArrowImgPath = '../../../../public/img/upload-arrow.png';

const logo = new Image();
const budgetImg = new Image();
const scheduleImg = new Image();
const cpmImg = new Image();
const impressionsImg = new Image();
const rightArrowImg = new Image();
const stripedLineImg = new Image();
const rightTriangleImg = new Image();
const uploadArrowImg = new Image();

logo.src = athenaLogoPath;
budgetImg.src = budgetImgPath;
scheduleImg.src = scheduleImgPath;
cpmImg.src = cpmImgPath;
impressionsImg.src = impressionsImgPath;
rightArrowImg.src = rightArrowImgPath;
stripedLineImg.src = stripedLineImgPath;
rightTriangleImg.src = rightTriangleImgPath;
uploadArrowImg.src = uploadArrowImgPath;

const icons = {
    budgetImg,
    scheduleImg,
    cpmImg,
    impressionsImg,
    rightArrowImg,
    stripedLineImg,
    rightTriangleImg,
    uploadArrowImg
};

const PdfExportPopup = props => {
    const attributes = useSelector(state => state.plan.attributes);
    const schedule = useSelector(state => state.schedule);
    const audience = useSelector(state => state.audience);
    const budget = useSelector(budgetConfigSelector);
    const region = useSelector(state => state.plan.attributes.region);
    const selectedLocations = useSelector(
        state => state.location.selectedLocations
    );
    const locationArray = chunkLocationData(selectedLocations);
    const mediaPlan = useSelector(state => state.mediaPlan);
    const mapImages = useSelector(state => {
        return {
            usa: state.location.mapImageDataUrls.usaMps,
            hawaii: state.location.mapImageDataUrls.hawaiiMps,
            alaska: state.location.mapImageDataUrls.alaskaMps
        };
    });
    const showHawaiiAndAlaska = useSelector(showHawaiiAndAlaskaSelector);
    const customList = useSelector(customListSelector);
    const { hhCount, percentage } = useSelector(amountsSelector);
    const { isExportPdf, toggleExportPopup } = props;
    const dispatch = useDispatch();

    const mappedDigitalDetailsForUi = useSelector(
        mappedDigitalDetailsForUiSelector
    );
    const totalDigitalBudget = useSelector(totalDigitalBudgetSelector);
    const totalDigitalImpression = useSelector(totalDigitalImpressionSelector);
    const totalDigitalCpm = useSelector(totalDigitalCpmSelector);
    const totalImpressions = useSelector(totalImpressionSelector);
    const totalCpm = useSelector(totalCpmSelector);
    const digitalProductDetails = useSelector(digitalProductDetailsSelector);

    const pendoMetaData = useSelector(getPendoMetaDataSelector('save'));
    const { saveMediaPlanObject } = useSelector(bookPlanObjectSelector);
    const { booked } = attributes;
    const { planStatus } = mediaPlan;
    const [
        exportAndSaveButtonAnimate,
        setExportAndSaveButtonAnimate
    ] = useState(false);
    const [exportOnlyButtonAnimate, setExportOnlyButtonAnimate] = useState(
        false
    );

    const onPlanNameChange = value => {
        dispatch(updateAttributes({ name: value }));
    };

    const onAdvertiserNameChange = value => {
        dispatch(updateAttributes({ advertiser: value }));
    };

    const createPDF = () => {
        createMediaPlanPDF({
            attributes,
            schedule,
            audience,
            budget,
            region,
            selectedLocations,
            locationArray,
            mediaPlan,
            customList,
            logo,
            mapImages,
            icons,
            hhCount,
            percentage,
            showHawaiiAndAlaska,
            mappedDigitalDetailsForUi,
            totalDigitalBudget,
            totalDigitalImpression,
            totalDigitalCpm,
            totalImpressions,
            totalCpm,
            digitalProductDetails
        });
    };

    const exportPdfOnly = () => {
        setExportOnlyButtonAnimate(true);
        setTimeout(() => {
            createPDF();
            setExportOnlyButtonAnimate(false);
            toggleExportPopup();
        });
    };

    const exportPDFAndSave = () => {
        setExportAndSaveButtonAnimate(true);
        setTimeout(() => {
            pendoService.track('save', pendoMetaData);
            createPDF();
            requestHandlers.plans
                .saveMediaPlan(saveMediaPlanObject)
                .then(() => {
                    if (planStatus !== PLAN_STATUSES.SAVED_DRAFT)
                        dispatch(setPlanStatus(PLAN_STATUSES.SAVED_DRAFT));
                    setExportAndSaveButtonAnimate(false);
                    toggleExportPopup();
                })
                .catch(e => {
                    setExportAndSaveButtonAnimate(false);
                    console.error('error saving plan', e);
                });
        });
    };

    return (
        <Popup
            show={isExportPdf}
            borderRadius="2px"
            boxShadow="1px 1px 3px 0 rgba(178,178,178,0.4)"
            borderColor="vividNavy"
            borderWidth="2px"
            left="75px"
            className={shared.popupWrapper}>
            <Content fillParent>
                <Layout
                    flexDirection="row"
                    hAlign="space-between"
                    className={shared.header}>
                    <Layout vAlign="center">
                        <Text type="h5" color="deepBlue">
                            Export Your Plan
                        </Text>
                        <Icon
                            src={exportSvg}
                            size="2"
                            className={styles.exportIcon}
                        />
                    </Layout>

                    <div
                        id={MEDIA_PLAN_PDF_EXPORT_CLOSE_POPUP}
                        onClick={toggleExportPopup}>
                        <Icon src={closeSvg} size="2" />
                    </div>
                </Layout>

                <Text type="body">Name your plan before exporting</Text>

                <Layout flexDirection="column" className={shared.marginTop}>
                    <Text type="body" color="vividNavy">
                        Plan Name
                    </Text>
                    <Input
                        id={MEDIA_PLAN_PDF_EXPORT_PLAN_NAME}
                        name="plan"
                        value={attributes.name}
                        onChange={onPlanNameChange}
                    />
                    <Text
                        type="body"
                        color="vividNavy"
                        className={shared.marginTop}>
                        Advertiser Name
                    </Text>
                    <Input
                        id={MEDIA_PLAN_PDF_EXPORT_ADVERTISER_NAME}
                        name="advertiser"
                        value={attributes.advertiser}
                        onChange={onAdvertiserNameChange}
                    />
                </Layout>

                <Layout
                    flexDirection="row"
                    hAlign="center"
                    className={shared.buttonLayout}>
                    <Layout
                        flexDirection="column"
                        className={styles.buttonWrapper}>
                        <Button
                            id={MEDIA_PLAN_PDF_EXPORT_PDF_AND_SAVE}
                            theme="primary"
                            onClick={exportPDFAndSave}
                            disabled={
                                !attributes.name ||
                                !attributes.advertiser ||
                                booked
                            }
                            animate={exportAndSaveButtonAnimate}>
                            Export and save
                        </Button>
                    </Layout>

                    <Layout
                        flexDirection="column"
                        className={styles.buttonWrapper}>
                        <Button
                            id={MEDIA_PLAN_PDF_EXPORT_PDF}
                            theme="outline"
                            onClick={exportPdfOnly}
                            disabled={
                                !attributes.name || !attributes.advertiser
                            }
                            animate={exportOnlyButtonAnimate}>
                            Export only
                        </Button>
                    </Layout>
                </Layout>
            </Content>
        </Popup>
    );
};

PdfExportPopup.propTypes = {
    isExportPdf: PropTypes.bool.isRequired,
    toggleExportPopup: PropTypes.func.isRequired
};

export default PdfExportPopup;
