export const formatUserDetails = ({
    firstName,
    lastName,
    email
}) => {
    const _firstName = firstName || 'Guest';
    const _lastName = lastName || 'User';
    const _email = email || 'guest@a4media.com';

    if (
        typeof _firstName !== 'string' ||
        typeof _lastName !== 'string' ||
        typeof _email !== 'string'
    )
        throw Error('Invalid arguments passed into function.');

    return {
        email: _email,
        name: `${_firstName} ${_lastName}`
    };
};
