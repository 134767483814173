import request from '../request';

export default function (planId) {
    const req = {
        method: 'GET',
        url: `reports/files/${planId}/file/`
    };

    return request(req);
}
