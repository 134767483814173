import request from '../request';

export default function (data) {
    return request({
        method: 'POST',
        url: 'auth/client_user/',
        data: {
            email: data.toLowerCase()
        }
    }).then(response => {

        return response;
    }).catch(error => {
        throw new Error(error);
    });
};
