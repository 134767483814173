import {
    MODIFIED_DATE,
    START_DATE,
    END_DATE,
    ADVERTISER_NAME,
    NAME,
    STATE,
    ID
} from '@config';
import { sortArrayOfObjectsByDateProp, sortArrayOfObjectsByProp } from '@lib';
import { validateArg } from '@lib';

export const sortPlans = (plans, criterion, descending) => {
    validateArg(plans, 'array', 1);
    validateArg(criterion, 'string', 2);
    validateArg(descending, 'boolean', 3);
    const isStringCriterion =
        criterion === STATE ||
        criterion === NAME ||
        criterion === ADVERTISER_NAME;

    switch (true) {
        case criterion === MODIFIED_DATE ||
            criterion === START_DATE ||
            criterion === END_DATE:
            return sortArrayOfObjectsByDateProp(plans, criterion, descending);
        case isStringCriterion || criterion === ID:
            return sortArrayOfObjectsByProp(
                plans,
                criterion,
                descending,
                isStringCriterion
            );
        default:
            return plans;
    }
};
