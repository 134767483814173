import { Copyright } from '../components/pages';

export default [
    {
        name: 'app.main.copyright',
        url: '/copyright',
        views: {
            'content@app': {
                component: Copyright
            }
        }
    }
];
