import React from 'react';
import PropTypes from 'prop-types';
import { TextareaWrapper, TextareaStyle } from './style';
import { TEXTAREA } from '../testIds';
import { testIdBuilder } from '../../core/testIdBuilder';

/**
 * Textarea component
 * */
const Textarea = props => {
    const registerChange = value => {
        if (props.onChange) props.onChange(value, props.name);
    };

    const handleChange = evt => {
        registerChange(evt.target.value);
    };

    const handleFocus = () => {
        if (props.onFocus) props.onFocus(props.name);
    };

    const { testNameSpace, id, ...result } = props;

    const testId = testIdBuilder(testNameSpace, TEXTAREA);

    return (
        <TextareaWrapper id={id}>
            <TextareaStyle
                {...result}
                onChange={handleChange}
                onFocus={handleFocus}
                data-testid={testId}
            />
        </TextareaWrapper>
    );
};

Textarea.propTypes = {
    /** Textarea value */
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    /** Name of the Textarea field */
    name: PropTypes.string,
    /** Resize the Textarea field */
    resize: PropTypes.string,
    /** onChange callback */
    onChange: PropTypes.func,
    /** onFocus callback */
    onFocus: PropTypes.func,
    /** Namespace for e2e tests targeting */
    testNameSpace: PropTypes.string,
    /** Id for tracking */
    id: PropTypes.string.isRequired
};

Textarea.defaultProps = {
    resize: 'none'
};

export default Textarea;
