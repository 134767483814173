import { geos } from '@config/config.locations';

export const chunkLocationData = locations => {
    if (!Array.isArray(locations)) throw Error('arg must be an Array');

    let arr = [];

    Object.keys(geos).forEach(category => {
        if (!arr.find(chunk => chunk.title === geos[category])) {
            arr.push({
                title: geos[category],
                entries: locations.filter(location => {
                    return location.audienceCategory === category;
                }),
                title: geos[category]
            });
        } else {
            arr = arr.map(chunk => {
                if (chunk.title === geos[category]) {
                    locations.forEach(location => {
                        if (location.audienceCategory === category)
                            chunk.entries.push(location);
                    });
                }
                return chunk;
            });
        }
    });

    return arr;
};
