import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Icon, Text, Layout } from 'maslow';
import {
    removeOptionFromAudience,
    resetAudiencesOrder
} from '@state/audience/actions/audiences';
import { fetchHouseholdCount } from '@state/audience/actions/households';
import { toggleIsAudienceModified } from '@state/audience/actions/toggleIsAudienceModified';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import styles from '../styles.scss';

import { PLAN_STATUSES } from '@config/config.planStatus';

const Component = props => {
    const { id, interactive, selectedAudienceIndex, option, minusIcon } = props;

    const handleOnClick = () => {
        props.removeOptionFromAudience(selectedAudienceIndex, option.id);

        props.fetchHouseholdCount();
        props.toggleIsAudienceModified(true);
        props.setCampaignRouterId(null);
        props.setPlanStatus(PLAN_STATUSES.UNSAVED);
        props.resetAudiencesOrder(selectedAudienceIndex);
    };

    return (
        <Layout
            flexDirection="row"
            vAlign="center"
            className={styles.selectedAudienceOption}>
            {interactive && (
                <div
                    id={id}
                    className={styles.selectedAudienceIconWrapper}
                    onClick={handleOnClick}>
                    <Icon src={minusIcon} className={styles.icon} size="3" />
                </div>
            )}

            <Text type="body" className={styles.selectedAudienceOptionText}>
                {option.name}
            </Text>
        </Layout>
    );
};

Component.propTypes = {
    id: PropTypes.string.isRequired,
    interactive: PropTypes.bool,
    selectedAudienceIndex: PropTypes.number.isRequired,
    minusIcon: PropTypes.node,
    option: PropTypes.shape({
        category: PropTypes.string,
        categoryValue: PropTypes.string,
        id: PropTypes.string,
        name: PropTypes.string,
        section: PropTypes.string,
        value: PropTypes.string
    }).isRequired,
    removeOptionFromAudience: PropTypes.func.isRequired,
    fetchHouseholdCount: PropTypes.func.isRequired,
    toggleIsAudienceModified: PropTypes.func.isRequired,
    setCampaignRouterId: PropTypes.func.isRequired,
    setPlanStatus: PropTypes.func.isRequired,
    resetAudiencesOrder: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    removeOptionFromAudience: (audienceIndex, id) =>
        dispatch(removeOptionFromAudience(audienceIndex, id)),
    fetchHouseholdCount: () => dispatch(fetchHouseholdCount()),
    toggleIsAudienceModified: isModified =>
        dispatch(toggleIsAudienceModified(isModified)),
    setCampaignRouterId: campaignRouterId =>
        dispatch(setCampaignRouterId(campaignRouterId)),
    setPlanStatus: planStatus => dispatch(setPlanStatus(planStatus)),
    resetAudiencesOrder: audienceIndex =>
        dispatch(resetAudiencesOrder(audienceIndex))
});

export const SelectedAudienceOption = connect(
    null,
    mapDispatchToProps
)(Component);
