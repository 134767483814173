import React from 'react';
import { getFeature, withFeatures } from '@delivery/react';
import styles from './styles.scss';
import { LOCATION_PAGE } from '@config';

const Component = props => {
    const HouseholdCount = getFeature('HouseholdCount', 'leftSidebarLocation');

    const LocationSummarySelections = getFeature(
        'LocationSummarySelections',
        'leftSidebarLocation'
    );

    return (
        <div className={styles.leftSidebar}>
            <HouseholdCount {...props} page={LOCATION_PAGE} />
            <LocationSummarySelections />
        </div>
    );
};

export const LeftSidebarLocation = withFeatures(Component);
