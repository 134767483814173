import request from '../request';

export default function ({ email, search, page, sort, orderBy }) {
    return request({
        method: 'GET',
        url: `plans/search/${email}?search=${search}`,
        params: {
            page,
            sort,
            orderBy
        }
    });
}
