import React from 'react';
import PropTypes from 'prop-types';

import { Checkbox } from 'maslow';

import shared from '@sharedStyle/loginAndSignUp.scss';

import {
    SIGNUP_BOOK_TERMS_CHECKBOX,
    SIGNUP_CHECKBOX_TERMS
} from '@config/idConstants';
import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';

const CheckboxSection = ({ updateStateValue }) => {
    const labelHtml = (
        <label>
            I agree to the Athena{' '}
            <a
                target="_blank"
                href={termsAndPolicyConfig.termsOfUse}
                id={SIGNUP_CHECKBOX_TERMS}>
                {' '}
                Terms of Use
            </a>
        </label>
    );

    const handleCheckboxOnChange = event => {
        updateStateValue('hasAgreedToTerms', event.target.checked);
    };

    return (
        <div className={shared.textAboveButton}>
            <Checkbox
                id={SIGNUP_BOOK_TERMS_CHECKBOX}
                name="agree"
                theme="blue"
                label={labelHtml}
                onChange={handleCheckboxOnChange}
                labelColor="echoBlue"
            />
        </div>
    );
};

CheckboxSection.propTypes = {
    updateStateValue: PropTypes.func.isRequired
};

export default CheckboxSection;
