class Dispatcher {
    constructor () {
        this.actions = {}
    }

    setAction (name, fn, self) {
        if (!this.actions.hasOwnProperty(name)) {
            this.actions[name] = {};
            this.actions[name].fn = fn;
            this.actions[name].self = self || null;
        } //else {
        //     throw Error(`Action with name ${name} already exists. Please use a unique name.`);
        // }
    }

    deleteAction (name) {
        if (this.actions.hasOwnProperty(name)) {
            delete this.actions[name];
        }
    }

    useAction (name, args) {
        if (this.actions.hasOwnProperty(name)) {
            if (this.actions[name].self) {
                this.actions[name].fn.apply(this.actions[name].self, args);
            } else {
                this.actions[name].fn(args);
            }
        } else {
            throw Error(`There are no actions with name ${name}. Please ensure the action exists.`);
        }
    }

    getActions () {
        return this.actions;
    }
}

export default new Dispatcher();
