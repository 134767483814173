import { createAction } from '@reduxjs/toolkit';
import {
    UPDATE_TOTAL,
    UPDATE_CHANNEL,
    UPDATE_SUB_CAMPAIGN,
    UPDATE_IS_AUTO_DISTRIBUTED,
    RESET_BUDGET,
    SAVE_DISTRIBUTION_CONFIG,
    SAVE_DIGITAL_CPM_CONFIG,
    SAVE_IMPRESSION_MODIFIER_CONFIG
} from '../constants';

export const updateBudget = {
    total: createAction(UPDATE_TOTAL, distribution => {
        return {
            payload: { distribution }
        };
    }),
    channel: createAction(UPDATE_CHANNEL, distribution => {
        return {
            payload: { distribution }
        };
    }),
    subCampaign: createAction(UPDATE_SUB_CAMPAIGN, subCampaigns => {
        return {
            payload: { subCampaigns }
        };
    }),
    setIsAutoDistributed: createAction(
        UPDATE_IS_AUTO_DISTRIBUTED,
        isAutoDistributed => {
            return {
                payload: { isAutoDistributed }
            };
        }
    ),
    resetBudget: createAction(RESET_BUDGET),
    saveDistributionConfig: createAction(
        SAVE_DISTRIBUTION_CONFIG,
        distributionConfig => {
            return {
                payload: { distributionConfig }
            };
        }
    ),
    saveDigitalCpmConfig: createAction(
        SAVE_DIGITAL_CPM_CONFIG,
        digitalCpmConfig => {
            return {
                payload: { digitalCpmConfig }
            };
        }
    ),
    saveImpressionModifierConfig: createAction(
        SAVE_IMPRESSION_MODIFIER_CONFIG,
        impressionModifierConfig => {
            return {
                payload: { impressionModifierConfig }
            };
        }
    )
};
