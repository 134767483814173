import request from '../request';

export default function(type, code) {
    const req = {
        method: 'GET',
        url: `locations/allStates`
    };

    return request(req);
}
