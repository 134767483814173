import React, { useState } from 'react';
import { UISref } from '@uirouter/react';
import { useSelector, useDispatch } from 'react-redux';

import { Layout, Text, Icon } from 'maslow';
import { uiSelector } from '@selectors/';
import { ERROR_STATES } from '@config/config.ui';
import { capitalizeFirstLetters } from '@lib';
import styles from './styles.scss';
import { idBuilder, generateToast } from '@services';
import {
    TOAST_DISMISS,
    TOAST_TOGGLE_DETAILS,
    TOAST_ROUTE_TO
} from '@config/idConstants';
import { isArray, isObject } from '@lib/';
import { useTransition, animated } from 'react-spring';

export const ToastNotification = () => {
    const dispatch = useDispatch();

    const { toasts } = useSelector(uiSelector);
    const transitions = useTransition(
        toasts,
        toast => toast.id,
        {
            unique: true,
            trail: 1500 / toasts.length,
            from: { transform: 'translate3d(250px,0,0)', opacity: 0 },
            enter: {
                transform: 'translate3d(0px,0,0)',
                opacity: 1
            },
            leave: {
                transform: 'translate3d(350px,0,0)',
                opacity: 0
            }
        },
        {
            config: {
                delay: 4000,
                duration: 400
            }
        }
    );

    const renderToasts = toastMessageArray => {
        if (!isArray(toastMessageArray)) return null;

        return transitions.map(({ item, props }, index) => {
            const { id } = item;

            return (
                <animated.div style={props} key={id}>
                    <ToastMessage
                        item={item}
                        dispatch={dispatch}
                        index={index}
                    />
                </animated.div>
            );
        });
    };

    return <div className={styles.toastContainer}>{renderToasts(toasts)}</div>;
};

const ToastMessage = ({
    item: { details, id, text, type },
    index,
    dispatch
}) => {
    const [showDetails, setShowDetails] = useState(false);

    const toggleDetailsText = showDetails ? 'Hide Details' : 'Show Details';
    const isShowToggleDetailsText = isObject(details);

    const detailsWrapperStyle = showDetails
        ? styles.detailsWrapperFadeIn
        : [styles.detailsWrapperFadeOut, styles.detailsWrapperInactive].join(
              ' '
          );

    const renderDetails = (details, id) => {
        if (!isObject(details)) return null;

        const { errorMessage, routeTo, routeText } = details;
        const routeToPage =
            routeTo && routeTo.substring(routeTo.lastIndexOf('.') + 1);
        return (
            <Text color="white" key={id} className={styles.detailsText}>
                {errorMessage}{' '}
                {routeTo && (
                    <UISref to={routeTo}>
                        <Text
                            color="white"
                            className={styles.routeText}
                            id={idBuilder(TOAST_ROUTE_TO, routeToPage)}>
                            {routeText}
                        </Text>
                    </UISref>
                )}
            </Text>
        );
    };

    const removeToast = id => {
        generateToast.remove(id, dispatch);
    };

    return (
        <>
            <Layout
                flexDirection="column"
                hAlign="center"
                style={{
                    top: index === 0 ? `${2}vh` : `${2 + index * 6}vh`
                }}
                key={id}>
                <Layout
                    flexDirection="row"
                    className={styles.toastWrapper}
                    hAlign="space-between"
                    vAlign="center">
                    <Layout flexDirection="row" vAlign="center">
                        <Icon
                            src={ERROR_STATES[type.toUpperCase()].icon}
                            size="4.5"
                        />
                        <Text color="white" className={styles.errorText}>
                            {text}{' '}
                            {isShowToggleDetailsText && (
                                <Text
                                    id={TOAST_TOGGLE_DETAILS}
                                    color="white"
                                    className={styles.toggleDetailsText}
                                    onClick={() =>
                                        setShowDetails(!showDetails)
                                    }>
                                    {toggleDetailsText}
                                </Text>
                            )}
                        </Text>
                    </Layout>

                    <Text
                        id={TOAST_DISMISS}
                        color="atelier"
                        className={styles.dismissText}
                        data-toastid={id}
                        onClick={() => removeToast(id)}>
                        Dismiss
                    </Text>
                </Layout>

                <Layout
                    flexDirection="column"
                    className={[
                        styles.detailsWrapper,
                        detailsWrapperStyle
                    ].join(' ')}>
                    <Text color="white" className={styles.detailsTitle}>
                        {capitalizeFirstLetters(
                            ERROR_STATES[type.toUpperCase()].title
                        )}
                    </Text>
                    {renderDetails(details)}
                </Layout>
            </Layout>
        </>
    );
};
