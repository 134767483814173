import React, { memo, useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { datesHandler } from '@lib/';
import { Layout, Text, Button, Card } from 'maslow';
import {
    creativeUrlsSelector,
    creativeValidationSelector,
    planSelector,
    creativeProductsSelector,
    isfileUploadingSelector
} from '@selectors/';
import {
    useDeltaObject,
    useDelta,
    some,
    useConditionalEffect
} from 'react-delta';
import styles from '../styles.scss';
import { creativeSelector } from '@selectors/';
import { POPUP_STEPS } from '@config/config.creative';

const Component = memo(({ showPopup }) => {
    const urls = useSelector(creativeUrlsSelector);
    const { files } = useSelector(creativeSelector);
    const isValid = useSelector(creativeValidationSelector);
    const creativeProducts = useSelector(creativeProductsSelector);
    const {
        attributes: { modifiedDate }
    } = useSelector(planSelector);

    const urlsDelta = useDeltaObject(urls);
    const creativeProductsSelectorDelta = useDeltaObject(creativeProducts);
    const filesDelta = useDelta(files.length);

    const [isModified, setIsModified] = useState(false);
    const isfileUploading = useSelector(isfileUploadingSelector);

    useConditionalEffect(() => {
        isValid && setIsModified(true);
    }, some(Object.values(urlsDelta).filter(val => val !== null && val.hasOwnProperty('prev'))));

    useConditionalEffect(() => {
        isValid && setIsModified(true);
    }, !!filesDelta && filesDelta.hasOwnProperty('prev'));

    useConditionalEffect(() => {
        isValid && setIsModified(true);
    }, some(Object.values(creativeProductsSelectorDelta).filter(val => val !== null && val.hasOwnProperty('prev'))));

    useEffect(() => {
        if (!isValid) setIsModified(false);
    }, [isValid]);

    const lastSaved =
        datesHandler.format(modifiedDate, "LLL dd, yyyy 'at' h:mma ") || 'N/A';

    const renderLastSaved = () => (
        <React.Fragment>
            <Layout flexDirection="row" vAlign="center">
                <Text type="body" className={styles.lastSavedText}>
                    Last Saved: {lastSaved} (UTC)
                </Text>
            </Layout>
        </React.Fragment>
    );

    const renderButtons = () => (
        <React.Fragment>
            <Layout
                flexDirection="row"
                vAlign="center"
                className={styles.footerButtons}>
                <Button
                    onClick={() => showPopup(POPUP_STEPS.CANCEL_QUERY)}
                    doubleClick={true}
                    theme="outline">
                    Cancel Editing
                </Button>
                <Button
                    disabled={!isModified || isfileUploading}
                    doubleClick={true}
                    onClick={() => showPopup(POPUP_STEPS.PUBLISH_QUERY)}>
                    Publish Changes
                </Button>
            </Layout>
        </React.Fragment>
    );

    const renderContent = () => {
        return (
            <Layout vAlign="center" className={styles.footerWrapper}>
                {renderLastSaved()}
                {renderButtons()}
            </Layout>
        );
    };

    return <React.Fragment> {renderContent()} </React.Fragment>;
});

export const Footer = Component;
