import { createReducer } from '@reduxjs/toolkit';
import { SET_AUDIENCE_CONFIG } from '../constants';
import { isArray } from '@lib';

const initialState = [];

const setAudienceConfig = (state, action) => {
    const { config } = action.payload;
    return isArray(config) ? config : state;
};

export const audienceConfig = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_AUDIENCE_CONFIG, setAudienceConfig)
        .addDefaultCase((state, _) => state);
});
