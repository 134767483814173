import request from '../request';

export default function(id) {
    const req = {
        method: 'GET',
        url: `audiences/savedAudience?id=${id}`
    };

    return request(req);
}
