import React, { useState, useEffect, memo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { creativeSelector, creativeUrlsSelector } from '@selectors/';
import { isValidUrl } from '@services';
import { setDestination } from '@state/creative/actions';
import { Input, Layout, Card, Icon, Text } from 'maslow';
import styles from './styles.scss';
import linkSvg from '@public/img/ico-link.svg';
import triangleDropdown from '@public/img/triangle-dropdown.svg';

import { CREATIVE_DESTINATION_URL } from '@config/idConstants';

const INTERVAL_TIME = 500;

const STATUSES = {
    IDLE: 'idle',
    VALID: 'valid',
    ERROR: 'error',
    EMPTY: 'empty'
};

const URL_ERROR_STATE = {
    empty: `Please enter a valid click-through url.`,
    error: 'Not a valid url.',
    idle: 'idle',
    valid: 'valid'
};
const DestinationUrl = memo(props => {
    const dispatch = useDispatch();

    const { destination = '' } = useSelector(creativeUrlsSelector);
    const { files = [] } = useSelector(creativeSelector);

    const [status, setStatus] = useState(STATUSES.IDLE);
    const [inputValue, setInputValue] = useState(destination);
    const [toggle, setToggle] = useState(false);

    useEffect(() => {
        setInputValue(destination);
    }, []);

    const doneTyping = value => {
        validateInput(value);
    };

    const onInputChange = value => {
        setInputValue(value);
    };

    const validateInput = value => {
        if (files.length > 0) {
            isValidUrl(value)
                ? setStatus(STATUSES.VALID)
                : setStatus(STATUSES.ERROR);
        }
        buildPayload(value);
    };

    const buildPayload = value => {
        const payload = {
            isValid: status === STATUSES.VALID || STATUSES.IDLE ? true : false,
            url: value
        };
        addUrlToState(payload);
    };

    const addUrlToState = payload => {
        payload ? dispatch(setDestination(payload)) : null;
    };

    const toggleArrowStyle = toggle
        ? styles.dropdownArrowClosed
        : styles.dropdownArrowOpen;

    return (
        <div className={styles.inputContainer}>
            <Layout flexDirection="row" vAlign="center">
                <Layout flexDirection="row" vAlign="center">
                    <Card margin="0" padding="0" className={styles.iconWrapper}>
                        <Icon
                            src={linkSvg}
                            className={styles.linkIcon}
                            size="2"
                        />
                    </Card>
                    <Text type="body" className={styles.inputHeaderTitle}>
                        Edit Targeting URLs
                    </Text>
                </Layout>
                {/* *** TO BE USED IN FUTURE STORY */}
                {/* <div
                    className={`${styles.dropdownArrowWrapper} ${toggleArrowStyle}`}
                    onClick={() => setToggle(!toggle)}>
                    <Icon
                        src={triangleDropdown}
                        className={styles.dropdownArrow}
                        size="2.5"
                    />
                </div> */}
            </Layout>
            <Layout
                flexDirection="row"
                vAlign="center"
                className={styles.inputContainerDrawer}>
                <Input
                    id={CREATIVE_DESTINATION_URL}
                    className={styles.input}
                    name="urlText"
                    placeholder="Click-through URL"
                    value={inputValue || ''}
                    onChange={onInputChange}
                    doneTyping={doneTyping}
                    doneTypingInterval={INTERVAL_TIME}
                    status={status}
                    errorText={URL_ERROR_STATE[status]}
                />
            </Layout>
        </div>
    );
});

export default DestinationUrl;
