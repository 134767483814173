import { createAction } from '@reduxjs/toolkit';
import {
    ADD_EXCLUDED_NETWORK,
    REMOVE_EXCLUDED_NETWORK,
    REMOVE_ALL_EXCLUDED_NETWORKS
} from '../constants';

export const addExcludedNetwork = createAction(
    ADD_EXCLUDED_NETWORK,
    excludedNetwork => {
        return {
            payload: { excludedNetwork }
        };
    }
);

export const removeExcludedNetwork = createAction(
    REMOVE_EXCLUDED_NETWORK,
    value => {
        return {
            payload: { value }
        };
    }
);

export const removeAllExcludedNetworks = createAction(
    REMOVE_ALL_EXCLUDED_NETWORKS
);
