import { strf, validateArg, removeSpace } from '@lib';
import { budgetConfig } from '@config/config.budget';
import {
    getChannelKey,
    getChannelName,
    getSubKey,
    getErrorText,
    getStatus,
    getCpm
} from './helpers';
import { getMaxBudget } from './maxBudgetHelpers';

export const getBudgetConfig = ({
    role,
    budget: {
        total,
        channels,
        subCampaigns,
        digitalCpmConfig,
        impressionModifierConfig
    },
    region,
    hhCountValue,
    duration,
    userType,
    linearTvCpm
}) => {
    const newChannels = {};
    const newSubCampaigns = {};

    budgetConfig.distribution.channels.forEach(
        ({ name, key, subCampaigns, roundIndex }) => {
            newChannels[name] = {
                name,
                subCampaigns,
                budget: channels[key] || 0,
                roundIndex
            };
        }
    );

    Object.values(budgetConfig.distribution.subCampaigns).forEach(
        ({
            name,
            context,
            medium,
            primary_target_demo_id,
            primary_target_demo_cpm,
            network_exclusions,
            spot_length,
            key,
            dsp,
            isUsedForMediaPlan,
            roundIndex,
            minErrorText,
            min
        }) => {
            const channelKey = getChannelKey(
                key,
                budgetConfig.distribution.subCampaigns
            );

            const maxBudget = getMaxBudget({
                hhCountValue,
                duration: isNaN(duration) ? 0 : duration,
                impressionModifier: impressionModifierConfig[key],
                primary_cpm_net: digitalCpmConfig[key] || 0,
                region,
                role,
                userType
            });

            const errorText = getErrorText({
                subCampaigns,
                key,
                min,
                region,
                minErrorText,
                maxBudget,
                userType
            });

            const status = getStatus(
                subCampaigns[key],
                min[userType][region],
                true,
                channels[channelKey],
                maxBudget
            );

            newSubCampaigns[name] = {
                name,
                primary_cpm_net: getCpm(
                    name,
                    linearTvCpm || 0,
                    digitalCpmConfig[key] || 0
                ),
                context,
                medium,
                primary_target_demo_id,
                primary_target_demo_cpm,
                network_exclusions,
                spot_length,
                budget: subCampaigns[key] || 0,
                min: min[userType][region],
                status,
                dsp,
                isUsedForMediaPlan,
                roundIndex,
                errorText,
                maxBudget
            };
        }
    );

    return {
        total,
        channels: newChannels,
        subCampaigns: newSubCampaigns
    };
};

export const getFormattedBudget = total => {
    if (total && typeof total === 'number') {
        return strf(total).commas().prepend('$').value();
    }

    return '$';
};

export const getSubsWithBudget = subCampaigns => {
    if (typeof subCampaigns === 'object') {
        const validSubs = [];
        Object.keys(subCampaigns).forEach(sub => {
            if (isNaN(subCampaigns[sub]))
                throw Error('subCampaign values must be numbers');
            if (subCampaigns[sub] > 0) validSubs.push(sub);
        });

        return validSubs;
    }
    throw Error('arg must be a valid object');
};

export const getLockedSubCampaigns = subCampaigns => {
    let lockedSubCampaigns = [];
    if (subCampaigns && typeof subCampaigns === 'object') {
        Object.keys(subCampaigns).forEach(sub => {
            if (subCampaigns[sub].min > subCampaigns[sub].maxBudget) {
                lockedSubCampaigns.push(getSubKey(sub));
            }
        });
    }
    return lockedSubCampaigns;
};

export const isDisableSubCampaign = (
    subCampaigns,
    lockedSubCampaigns,
    channelBudget
) => {
    const filteredSubCampaigns = subCampaigns.filter(
        element => !lockedSubCampaigns.includes(element.key)
    );
    return filteredSubCampaigns.length < 2 || channelBudget <= 0;
};

export const isAnyChildInErrorState = (name, channels, subCampaigns) => {
    return channels[name].subCampaigns.some(subCampaignName => {
        const subCampaign = subCampaigns[subCampaignName];
        return (
            subCampaign &&
            subCampaign.budget !== 0 &&
            (subCampaign.budget < subCampaign.min ||
                subCampaign.budget > subCampaign.maxBudget)
        );
    });
};

export const isAllChildrenInLockedState = subs => {
    return !subs.some(sub => {
        return sub.min < sub.maxBudget;
    });
};

export const getLockedChannels = (channels, subCampaigns, sections, subs) => {
    let lockedChannels = [];
    Object.keys(sections).forEach(key => {
        const channelName = getChannelName(key);
        const filteredSubs = Object.values(subCampaigns).filter(sub => {
            const key = getSubKey(sub.name);
            return (
                subs.hasOwnProperty(key) &&
                channels[channelName].subCampaigns.includes(sub.name)
            );
        });
        if (isAllChildrenInLockedState(filteredSubs)) {
            lockedChannels.push(key);
        }
    });
    return lockedChannels;
};

export const hasBudgtForOnlyProvidedSubCampaigns = (
    subCampaigns,
    scKeyList
) => {
    validateArg(subCampaigns, 'object', 1);
    validateArg(scKeyList, 'array', 2);

    const _scKeyList = scKeyList.map(element =>
        removeSpace(element.toLowerCase())
    );
    return Object.entries(subCampaigns)
        .filter(([scName, value]) => {
            return value > 0;
        })
        .every(product => _scKeyList.includes(product[0].toLowerCase()));
};
