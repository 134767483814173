import React from 'react';
import PropTypes from 'prop-types';
import { UISref } from '@uirouter/react';

import { Layout, Text, Icon } from 'maslow';
import editSvg from '@public/img/ico-edit.svg';

import style from '../styles.scss';

import { idBuilder } from '@services';
import { PLANS_CONTINUE_EDITING } from '@config/idConstants';

const ContinueEditing = ({ route, id }) => {
    return (
        <UISref to={route} params={{ planId: id }}>
            <Layout
                id={idBuilder(PLANS_CONTINUE_EDITING, id)}
                flexDirection="row"
                hAlign="center"
                vAlign="center"
                className={style.editPlanWrapper}>
                <Icon src={editSvg} className={style.bottomSectionIcon} />
                <Text color="vividNavy" className={style.bottomSectionLink}>
                    Continue Editing
                </Text>
            </Layout>
        </UISref>
    );
};

ContinueEditing.propTypes = {
    route: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
};

export default ContinueEditing;
