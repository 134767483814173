import React, { useEffect, memo, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { cancelable } from 'cancelable-promise';

import Map from '../Map';
import stateLabel from '../stateLabel.json';
import allStatesData from './allStates.json';
import { maps } from '@config/config.maps';
import requestHandlers from '@requestHandlers';
import { setGeoJson, getMaps } from '@state/location/actions';
import { isEmpty } from '@lib';
import {
    selectedLocationsSelector,
    geoJsonSelector
} from '@selectors/location';
import { geoExists } from '@services/locations/';
import { getSelectedAgeList } from '@services/audiencesService';
import { setBudgetDistribution } from '@services/budget';
import featuresManager from '@featuresManager';
import {
    audienceSelector,
    regionSelector,
    hasDistributionConfigSelector
} from '@selectors';

const Maps = React.memo(({ isState }) => {
    const dispatch = useDispatch();
    const locations = useSelector(selectedLocationsSelector);
    const geoJson = useSelector(geoJsonSelector);
    const [role] = featuresManager.getCustomFilterState('role');
    const region = useSelector(regionSelector);
    const { audiences } = useSelector(audienceSelector);
    const hasDistributionConfig = useSelector(hasDistributionConfigSelector);
    const regionRef = useRef(region);
    const selectedAgesList = getSelectedAgeList(audiences);

    const getGeoJson = async (locations, geoJson) => {
        return Promise.all(
            locations.map(async location => {
                const oldGeo = geoExists(location, geoJson);

                if (!oldGeo) {
                    const response = await requestHandlers.locations
                        .getSingleGeoJson(location.id)
                        .catch(console.error);

                    const { geoJsons, geographyId } = response.data[0];
                    const filteredGeoJsons = geoJsons.filter(
                        location =>
                            location.geoJson && !isEmpty(location.geoJson)
                    );

                    return {
                        geographyId,
                        geoJsons: filteredGeoJsons,
                        ...location
                    };
                }

                return oldGeo;
            })
        );
    };

    useEffect(() => {
        const newGeoJsonPromise = cancelable(getGeoJson(locations, geoJson));

        newGeoJsonPromise
            .then(res => dispatch(setGeoJson(res)))
            .catch(console.error);

        return () => {
            newGeoJsonPromise.cancel();
        };
    }, [locations]);

    useEffect(() => {
        regionRef.current = region;
    }, [region]);

    useEffect(() => {
        return () => {
            if (hasDistributionConfig)
                setBudgetDistribution(
                    role,
                    regionRef.current,
                    selectedAgesList,
                    dispatch
                );
        };
    }, [hasDistributionConfig]);

    //Keep for later use
    // useEffect(() => {
    //     return () => dispatch(getMaps());
    // }, []);

    return (
        <React.Fragment>
            {maps.map(map => {
                return (
                    <Map
                        config={map}
                        mapId={map.id}
                        key={map.id}
                        labelData={stateLabel}
                        mapData={allStatesData}
                        isState={isState}
                        zoomControl={map.zoomControl}
                        minZoom={map.minZoom}
                        keyboard={map.keyboard}
                        dragging={map.dragging}
                        scrollWheelZoom={map.scrollWheelZoom}
                        zoomAnimation={map.zoomAnimation}
                        touchZoom={map.touchZoom}
                    />
                );
            })}
        </React.Fragment>
    );
});

export default Maps;
