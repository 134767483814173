import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import { Content } from 'maslow';
import shared from '@sharedStyle/page.scss';

class Creative extends React.Component {
    constructor(props) {
        super(props);
    }
    render() {
        const { components } = getFeatures('creative', this);

        return (
            <Content className={shared.creativePageWrapper}>
                {components}
            </Content>
        );
    }
}

export default withFeatures(Creative);
