import React, { useState, useEffect, useRef } from 'react';
import { Content, Layout, Text } from 'maslow';
import PropTypes from 'prop-types';
import requestHandlers from '@requestHandlers';
import { useSelector, useDispatch } from 'react-redux';
import { setSavedAudiences } from '@state/audience/actions/savedAudiences';
import {
    campaignTypeSelector,
    audienceSelector,
    regionSelector,
    hasDistributionConfigSelector
} from '@selectors/';
import featuresManager from '@featuresManager';
import SavedAudiences from './SavedAudiences';
import SearchAudience from './SearchAudience';
import EmptyAudience from './EmptyAudience';
import styles from './styles.scss';
import { getSelectedAgeList } from '@services/audiencesService';
import { setBudgetDistribution } from '@services/budget';

const Audiences = () => {
    const [savedAudiencesList, setSavedAudiencesList] = useState([]);
    const dispatch = useDispatch();
    const [role] = featuresManager.getCustomFilterState('role');
    const region = useSelector(regionSelector);
    const selectedAgesRef = useRef([]);
    const { audiences } = useSelector(audienceSelector);
    const hasDistributionConfig = useSelector(hasDistributionConfigSelector);
    const { account } = useSelector(state => state.user);
    const campaignType = useSelector(campaignTypeSelector);
    const externalId = account.id;
    const [emptyAudience, setEmptyAudience] = useState(false);

    const getSavedAudiences = async () => {
        const savedAudiencesResponse = await requestHandlers.audiences
            .getUserAssociatedSavedAudiences(externalId, campaignType)
            .catch(err => {
                console.error('error loading my audiences', err);
            });
        dispatch(setSavedAudiences(savedAudiencesResponse.data));
        setSavedAudiencesList(savedAudiencesResponse.data);
        setEmptyAudience(!savedAudiencesResponse.data.length);
    };

    useEffect(() => {
        selectedAgesRef.current = getSelectedAgeList(audiences);
    }, [audiences]);

    useEffect(() => {
        getSavedAudiences();
        return () => {
            setBudgetDistribution(
                role,
                region,
                selectedAgesRef.current,
                dispatch
            );
        };
    }, []);

    const buildSavedAudiences = (savedAudience, i) => {
        return <SavedAudiences key={i} savedAudience={savedAudience} />;
    };

    const savedAudiences = savedAudiencesList.map(buildSavedAudiences);

    return (
        <Content className={styles.wrapper}>
            <Layout className={styles.searchAudienceWrapper}>
                <Layout
                    flexDirection="column"
                    fillParent
                    className={styles.searchAudienceInnerWrapper}>
                    <Text
                        type="h6"
                        color="deepBlue"
                        className={styles.searchAudienceTitle}>
                        SAVED AUDIENCES
                    </Text>
                    <SearchAudience
                        setSavedAudiencesList={setSavedAudiencesList}
                    />
                </Layout>
            </Layout>

            {!emptyAudience && (
                <React.Fragment>{savedAudiences}</React.Fragment>
            )}

            {emptyAudience && <EmptyAudience />}
        </Content>
    );
};

export default Audiences;
