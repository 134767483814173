import { createReducer } from '@reduxjs/toolkit';
import {
    ADD_LOCATION,
    REMOVE_LOCATION,
    REMOVE_LOCATIONS,
    ADD_LOCATIONS
} from '../constants';

const initialState = [];

const addLocation = (state, action) => {
    const { location } = action.payload;
    return [...state, location];
};
const addLocations = (state, action) => {
    const { locations } = action.payload;
    return [...state, ...locations];
};
const removeLocation = (state, action) => {
    const { locationIndex } = action.payload;
    return state.filter((_, i) => i !== locationIndex);
};

const removeLocations = () => {
    return initialState;
};

export const selectedLocations = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_LOCATION, addLocation)
        .addCase(ADD_LOCATIONS, addLocations)
        .addCase(REMOVE_LOCATION, removeLocation)
        .addCase(REMOVE_LOCATIONS, removeLocations)
        .addDefaultCase((state, _) => state);
});
