export default {
    name: 'HouseholdCount',
    description: 'Household Count',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan', 'ranker'],
    sections: [
        'leftSidebar',
        'leftSidebarCombinations',
        'leftSidebarNetworks',
        'leftSidebarLocation',
        'leftSidebarBudget',
        'leftSidebarSchedule'
    ],
    permissions: [],
    data: {
        housesCount: 23
    },
    attributes: {},
    modifications: {}
};
