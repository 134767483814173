import React from 'react';
import { useSelector } from 'react-redux';
import { getFeatures, withFeatures } from '@delivery/react';

const css = {
    base: {
        transition: 'all 0.15s ease-in-out',
        display: 'block',
        position: 'absolute',
        height: '100%',
        zIndex: 10
    },
    open: {
        left: 0
    },
    closed: {
        left: '-400px'
    }
};

const FlyoutNav = props => {
    const flyoutNavStatus = useSelector(state => state.ui.flyoutNavStatus);
    const { components } = getFeatures('flyoutNav', { props });

    const styles = flyoutNavStatus
        ? { ...css.base, ...css.open }
        : { ...css.base, ...css.closed };

    return (
        <div style={styles} id="this-is-the-one">
            {components}
        </div>
    );
};

export default withFeatures(FlyoutNav);
