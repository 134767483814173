import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Layout, Button } from 'maslow';
import { isEmptyString } from '@lib';

import { SIGNUP_REGISTER_BUTTON } from '@config/idConstants';
import shared from '@sharedStyle/loginAndSignUp.scss';

const RegisterButton = ({
    submitForm,
    hasAgreedToTerms,
    text,
    firstName,
    lastName,
    passwordsMatched,
    ls,
    buttonAnimation
}) => {
    const disabled =
        isEmptyString(firstName) ||
        isEmptyString(lastName) ||
        !ls.hasValidEmail() ||
        !ls.hasValidPassword() ||
        !passwordsMatched ||
        !hasAgreedToTerms;

    return (
        <Layout flexDirection="column" hAlign="center">
            <Button
                id={SIGNUP_REGISTER_BUTTON}
                className={shared.btnSize}
                onClick={() => {
                    submitForm();
                }}
                disabled={disabled}
                animate={buttonAnimation}>
                {text}
            </Button>
        </Layout>
    );
};

RegisterButton.propTypes = {
    submitForm: PropTypes.func,
    hasAgreedToTerms: PropTypes.bool,
    text: PropTypes.string,
    firstName: PropTypes.string.isRequired,
    lastName: PropTypes.string.isRequired,
    passwordsMatched: PropTypes.bool.isRequired,
    ls: PropTypes.object.isRequired,
    buttonAnimation: PropTypes.bool
};

export default RegisterButton;
