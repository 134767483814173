import React from 'react';
import { connect } from 'react-redux';

import { getMapStateToProps } from './lib';
import invalidStyles from './styles';
import featuresManager from '@featuresManager';
const config = {};

export const validateElementInteraction = (validationRules, name) => {
    const mapStateToProps = getMapStateToProps(validationRules);

    if (!config.hasOwnProperty[name]) {
        config[name] = {
            prev: false,
            curr: false
        };
    }

    const ValidateElementInteraction = props => {
        const { isInvalid } = props;
        const style = isInvalid ? invalidStyles : {};

        config[name].curr = !isInvalid ? true : false;

        const children = React.Children.map(props.children, child => {
            return React.cloneElement(child, {
                isActive: config[name].curr,
                newlyActive: config[name].curr && !config[name].prev
            });
        });

        config[name].prev = config[name].curr;

        return (
            <div style={style} className="itemWrapper">
                {children}
            </div>
        );
    };

    return mapStateToProps
        ? connect(mapStateToProps)(ValidateElementInteraction)
        : ValidateElementInteraction;
};
