import React from 'react';
import { UISrefActive, UISref } from '@uirouter/react';

import { Content, Text, Layout, createReactComponent } from 'maslow';
import styles from './styles.scss';

import { MY_AUDIENCES_LINK } from '@config/idConstants';

export default function (version, feature) {
    return function () {
        return (
            <Content className={styles.wrapper}>
                <UISrefActive class="active">
                    <UISref to="app.main.audiences">
                        <Text id={MY_AUDIENCES_LINK} type="h5">
                            Saved Audiences
                        </Text>
                    </UISref>
                </UISrefActive>
            </Content>
        );
    };
}
