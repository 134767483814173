import { addToast, removeToast, removeAllToasts } from '@state/ui/actions';
import { nanoid } from '@reduxjs/toolkit';

export const generateToast = {
    add: (message, dispatch) => {
        if (typeof message !== 'object')
            throw Error('first argument needs to be valid message object');
        if (typeof dispatch !== 'function')
            throw Error('second argument needs to be dispatch function');
        const { details, type, text } = message;

        dispatch(
            addToast({
                type,
                text,
                details,
                id: nanoid()
            })
        );
    },
    remove: (id, dispatch) => {
        if (typeof dispatch !== 'function')
            throw Error('second argument needs to be dispatch function');
        if (typeof id !== 'string')
            throw Error('first argument needs to be valid id');
        dispatch(removeToast(id));
    },
    removeAll: dispatch => {
        dispatch(removeAllToasts());
    }
};
