import { datesHandler } from './datesHandler';

const createWeekArr = (start = 0, end = 7) => {
    const weeks = [];
    for (let i = start; i < end; i++) {
        weeks.push(datesHandler.newDate(i));
    }

    return weeks;
};

export {
    createWeekArr
};
