import React from 'react';
import { header, listItem } from './segment.scss';
import { segmentTypes } from '@config/config.locations';
import { NETWORKS } from '@config/';
import { capitalizeFirstLetters } from '@lib';

const Segment = props => {
    const {
        data: { entries, type },
        onClick,
        segmentId
    } = props;

    const buildEntries = (entries, type) => {
        return entries.map((entry, i) => {
            const { name, id } = entry;

            return (
                <li
                    tabIndex="-1"
                    key={`${type}.${id}`}
                    className={listItem}
                    onClick={() => onClick(entry)}
                    id={`${segmentId}.${i}`}>
                    {name}
                </li>
            );
        });
    };

    if (entries.length) {
        const entryComponents = buildEntries(entries, type);

        const category =
            type === capitalizeFirstLetters(NETWORKS)
                ? NETWORKS.toUpperCase()
                : segmentTypes[type];

        return (
            <React.Fragment>
                <li tabIndex="-1" className={header}>
                    {category}
                </li>
                {entryComponents}
            </React.Fragment>
        );
    } else {
        return null;
    }
};

export default Segment;
