import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Layout, Content, Card, Icon, Text } from 'maslow';
import {
    addOptionToAudience,
    removeOptionFromAudience,
    replaceAudience,
    resetAudiencesOrder
} from '@state/audience/actions/audiences';
import { fetchHouseholdCount } from '@state/audience/actions/households';
import { toggleIsAudienceModified } from '@state/audience/actions/toggleIsAudienceModified';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { reMount } from '@components/hoc';
import plusIcon from '@public/img/plus-selector.svg';
import minusIcon from '@public/img/minus-selector.svg';
import styles from './savedAudiences.scss';
import { store } from '@state';
import { audienceSelector, isAudienceSelectedSelector } from '@selectors/';

import { PLAN_STATUSES } from '@config/config.planStatus';
import { MY_AUDIENCES_SELECT_AUDIENCE } from '@config/idConstants';
import { idBuilder } from '@services/idBuilder';
import { SAVED_AUDIENCES } from '@config';

const SavedAudiences = ({ savedAudience }) => {
    const isSelected = useSelector(isAudienceSelectedSelector(savedAudience));
    const { currentAudienceIndex, audiences } = useSelector(audienceSelector);
    const dispatch = useDispatch();

    const onAudienceChange = () => {
        dispatch(fetchHouseholdCount());
        dispatch(toggleIsAudienceModified(true));
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    const getOptionToSave = () => {
        return {
            name: savedAudience.name,
            id: savedAudience.id.toString(),
            category: SAVED_AUDIENCES
        };
    };

    const handleClick = () => {
        const optionToSave = getOptionToSave();
        const currentAudience = audiences[currentAudienceIndex];

        if (currentAudience.length && !isSelected) {
            dispatch(replaceAudience(currentAudienceIndex, [optionToSave]));
            return onAudienceChange();
        }

        if (!isSelected) {
            dispatch(addOptionToAudience(currentAudienceIndex, optionToSave));
        } else {
            dispatch(
                removeOptionFromAudience(currentAudienceIndex, optionToSave.id)
            );
            dispatch(resetAudiencesOrder(currentAudienceIndex));
        }

        onAudienceChange();
    };

    const renderIcon = () => {
        const Component = reMount(Icon);
        const iconSrc = isSelected ? minusIcon : plusIcon;

        return (
            <Component
                src={iconSrc}
                size="3"
                className={styles.savedAudienceIcon}
            />
        );
    };

    return (
        <Card
            id={idBuilder(MY_AUDIENCES_SELECT_AUDIENCE, savedAudience.name)}
            backgroundColor="white"
            borderRadius="2px"
            boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
            borderColor={isSelected ? 'vividNavy' : 'transparent'}
            className={styles.savedAudienceCard}
            onClick={handleClick}>
            <Content fillParent>
                <Layout flexDirection="row" vAlign="center">
                    <Layout flexDirection="column" alignSelf="flex-start">
                        {renderIcon()}
                    </Layout>

                    <Layout flexDirection="column">
                        <Text
                            type="h5"
                            color={isSelected ? 'vividNavy' : 'black'}
                            className={styles.audienceNameText}>
                            {savedAudience.name}
                        </Text>
                    </Layout>
                </Layout>
            </Content>
        </Card>
    );
};

SavedAudiences.propTypes = {
    savedAudience: PropTypes.object.isRequired
};

export default SavedAudiences;
