import { createReducer } from '@reduxjs/toolkit';
import {
    UPDATE_ATTRIBUTES,
    UPDATE_REGION,
    UPDATE_PLAN_ID,
    SET_CAMPAIGN_TYPE,
    TOGGLE_HAS_ACCEPTED_TERMS,
    SET_PAYMENT_ERROR,
    REMOVE_PAYMENT_ERROR
} from '../constants';
import { NATIONAL } from '@config';
import { isArray } from '@lib';

export const initialState = {
    advertiser: '',
    booked: false,
    campaignType: 'unset',
    hasAcceptedTerms: false,
    paymentError: [],
    modifiedDate: null,
    name: '',
    planId: null,
    region: NATIONAL
};

const updateAttributes = (state, action) => {
    const { attributes } = action.payload;
    return {
        ...state,
        ...attributes
    };
};

const updatePlanId = (state, action) => {
    const { planId } = action.payload;
    return { ...state, planId };
};

const updateRegion = (state, action) => {
    const { region } = action.payload;
    return { ...state, region };
};

const setCampaignType = (state, action) => {
    const { campaignType } = action.payload;
    return { ...state, campaignType };
};

const toggleHasAcceptedTerms = (state, _) => {
    return {
        ...state,
        hasAcceptedTerms: !state.hasAcceptedTerms
    };
};

const setPaymentError = (state, action) => {
    const { paymentError } = action.payload;
    return isArray(paymentError) ? { ...state, paymentError } : state;
};

const removePaymentError = (state, _) => {
    return { ...state, paymentError: [] };
};

export const attributes = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_ATTRIBUTES, updateAttributes)
        .addCase(UPDATE_PLAN_ID, updatePlanId)
        .addCase(UPDATE_REGION, updateRegion)
        .addCase(SET_CAMPAIGN_TYPE, setCampaignType)
        .addCase(TOGGLE_HAS_ACCEPTED_TERMS, toggleHasAcceptedTerms)
        .addCase(SET_PAYMENT_ERROR, setPaymentError)
        .addCase(REMOVE_PAYMENT_ERROR, removePaymentError)
        .addDefaultCase((state, _) => state);
});
