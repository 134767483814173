import React from 'react';
import { UISref } from '@uirouter/react';
import { Text, Layout, Button, Icon } from 'maslow';
import requestHandlers from '@requestHandlers';
import { getRouteForUserType } from '@services';
import { setA4PlanId } from '@state/mediaPlan/actions';
import { updateAttributes } from '@state/plan/actions';
import { useToggle } from '@hooks';
import { plansConfig } from '@config/config.plans';
import { POLITICAL } from '@config';
import { PLANS_NEW_PLAN, PLANS_NETWORK_RANKER } from '@config/idConstants';

import rankerIcon from '@public/img/network-ranker-blue.svg';
import styles from '../styles.scss';

const _Header = ({
    showRankerButton,
    transition,
    isShowCampaignPage,
    account,
    dispatch
}) => {
    const { routes } = plansConfig;
    const [buttonAnimation, toggle] = useToggle(false);

    const newPlan = () => {
        if (buttonAnimation) return false;
        toggle();
        if (!isShowCampaignPage)
            requestHandlers.plans
                .newMediaPlan({
                    mediaPlanType: POLITICAL,
                    email: account.email
                })
                .then(res => {
                    dispatch(setA4PlanId(res.data));
                    dispatch(updateAttributes({ planId: res.data }));
                    transition.router.stateService.go(
                        getRouteForUserType(isShowCampaignPage, routes),
                        { planId: 'new' }
                    );
                    toggle();
                })
                .catch(err => {
                    toggle();
                    console.error('error creating new plan', err);
                });
        else {
            transition.router.stateService.go(
                getRouteForUserType(isShowCampaignPage, routes),
                { planId: 'new' }
            );
        }
    };

    return (
        <Layout
            flexDirection="row"
            hAlign="space-between"
            className={styles.headerContainer}>
            <Text className={styles.headerText}>My Plans</Text>

            <Layout flexDirection="row">
                {showRankerButton && (
                    <UISref to={routes.ranker} options={{ source: 'plans' }}>
                        <Button
                            id={PLANS_NETWORK_RANKER}
                            theme="outlineNeutral"
                            className={styles.networkRanker}>
                            <Icon src={rankerIcon} size={2}></Icon>
                            <Text>Network Ranker</Text>
                        </Button>
                    </UISref>
                )}

                <Button
                    onClick={newPlan}
                    id={PLANS_NEW_PLAN}
                    animate={buttonAnimation}>
                    New Plan
                </Button>
            </Layout>
        </Layout>
    );
};

export const Header = React.memo(_Header);
