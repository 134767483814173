import React from 'react';
import { Watch } from 'scrollmonitor-react';
import PropTypes from 'prop-types';

import { Layout, Text } from 'maslow';
import { slugBuilder } from '@services';

import featuresManager from '@featuresManager';
import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';
import { POLITICAL } from '@config';
import { AUDIENCE_BUILDER_POLITICAL_CAMPAIGNS_LINK } from '@config/idConstants';

import styles from './index.scss';

const AudienceSection = ({ children, sectionData }) => {
    const sectionSlug = slugBuilder(sectionData.name);
    const [role] = featuresManager.getCustomFilterState('role');

    return (
        <React.Fragment>
            {role === POLITICAL && sectionSlug === 'political' && (
                <Text type="input" color="vividNavy">
                    The following Political Attributes may be used only for{' '}
                    <a
                        id={AUDIENCE_BUILDER_POLITICAL_CAMPAIGNS_LINK}
                        target="_blank"
                        href={`${termsAndPolicyConfig.termsOfUse}#PoliticalCampaigns`}
                        className={styles.linkText}>
                        <Text type="input" color="vividNavy">
                            legally permissible political campaigns
                        </Text>
                    </a>
                </Text>
            )}
            <Layout
                flexDirection="column"
                className={styles.categoryWrapper}
                id={sectionSlug}>
                {children}
            </Layout>
        </React.Fragment>
    );
};

AudienceSection.propTypes = {
    sectionData: PropTypes.object.isRequired
};

export default Watch(AudienceSection);
