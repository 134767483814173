import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Text, Layout, Icon, Spinner } from 'maslow';

import {
    createPercentSelector,
    lockedSubStatusSelector,
    createStatusSelector,
    budgetSelector,
    mediaPlanSelector
} from '@selectors';

import BudgetSlider from '../BudgetSlider';
import BudgetInput from '../BudgetInput';
import BudgetPercentInput from '../BudgetPercentInput';

import lockSvg from '@public/img/ico-lock.svg';

import LockedInfoToolTipSection from '../LockedInfoToolTipSection';

import styles from '../Distribution/styles.scss';

import { idBuilder } from '@services';
import { BUDGET_SUB_PERCENT_BUBBLE } from '@config/idConstants';
import { LINEAR_TV } from '@config/';
import { getFormattedCpm } from '@services/budget';

const SubCampaign = ({
    config,
    style,
    disable,
    isEditing,
    toggleEditing,
    channelKey
}) => {
    const { name, key } = config;
    const {
        sliderColorName,
        percentTextBackgroundColor,
        primaryColor,
        errorBackgroundColor,
        errorColor,
        errorBorderColor
    } = style;

    const subPercent = useSelector(createPercentSelector.subCampaign(key));
    const isLockedStatus = useSelector(lockedSubStatusSelector(key));
    const disabledClass =
        disable || isLockedStatus ? styles.disabledWrapper : '';
    const status = useSelector(createStatusSelector.subCampaign(key));
    const disabledNameClass = isLockedStatus ? styles.disabledWrapper : '';
    const {
        tvDetails: { primaryTarget }
    } = useSelector(mediaPlanSelector);

    const tvCpmFormatted = primaryTarget
        ? getFormattedCpm(primaryTarget.cpm)
        : null;
    const { digitalCpmConfig } = useSelector(budgetSelector);
    const cpmFormatted =
        name !== LINEAR_TV
            ? getFormattedCpm(digitalCpmConfig[key])
            : tvCpmFormatted;
    const subPercentValidStyle = {
        backgroundColor: percentTextBackgroundColor,
        color: primaryColor,
        borderColor: percentTextBackgroundColor
    };

    const subPercentErrorStyle = {
        backgroundColor: errorBackgroundColor,
        color: errorColor,
        borderColor: errorBorderColor
    };

    const subPercentStyle =
        status === 'error' ? subPercentErrorStyle : subPercentValidStyle;

    const buildSubCampaignInputs = () => {
        if (isLockedStatus) {
            return <LockedInfoToolTipSection sliderKey={key} />;
        }
        return (
            <Layout
                flexDirection="row"
                vAlign="center"
                className={`${styles.budgetInputsLayout} ${disabledClass}`}>
                <div
                    className={[
                        styles.budgetInputWrapper,
                        styles.budgetPercentInputWrapper
                    ].join(' ')}>
                    <BudgetPercentInput config={config} theme="subCampaign" />
                </div>
                <div
                    className={`${styles.budgetInputWrapper} ${styles.subBudgetInputWrapper}`}>
                    <BudgetInput config={config} theme="subCampaign" />
                </div>
            </Layout>
        );
    };

    if (!isEditing) {
        return config ? (
            <Layout
                flexDirection="row"
                vAlign="center"
                className={styles.subBudgetSummary}>
                {!isLockedStatus ? (
                    <Text
                        id={idBuilder(BUDGET_SUB_PERCENT_BUBBLE, key)}
                        type="body"
                        style={subPercentStyle}
                        className={styles.subBudgetPercent}
                        onClick={() => toggleEditing(channelKey)}>
                        {subPercent}%
                    </Text>
                ) : (
                    <Layout
                        id={idBuilder(BUDGET_SUB_PERCENT_BUBBLE, key)}
                        vAlign="center"
                        className={styles.lockedSubBudgetBubble}
                        onClick={() => toggleEditing(channelKey)}>
                        <Icon src={lockSvg} size="2" />
                    </Layout>
                )}
                <Text
                    type="body"
                    className={`${styles.subBudgetName} ${disabledNameClass}`}>
                    {name}
                </Text>
            </Layout>
        ) : null;
    }

    return config ? (
        <Layout
            flexDirection="column"
            vAlign="center"
            className={styles.subCampaignWrapper}>
            <Layout flexDirection="row" vAlign="center" hAlign="space-between">
                <Layout flexDirection="row" vAlign="center">
                    <Layout className={styles.channelText}>
                        {isLockedStatus && (
                            <Icon
                                src={lockSvg}
                                size="2"
                                className={styles.lockIcon}
                            />
                        )}
                        <Text type="body" className={disabledNameClass}>
                            {name}
                        </Text>
                    </Layout>
                    {!cpmFormatted && <Spinner />}
                    {cpmFormatted && (
                        <Layout
                            className={styles.cpmWrapper}
                            vAlign="center"
                            hAlign="center">
                            <Text type="input">{cpmFormatted}</Text>
                            <Text type="input" color="atelier">
                                &nbsp;CPM
                            </Text>
                        </Layout>
                    )}
                </Layout>
                {buildSubCampaignInputs()}
            </Layout>

            <Layout
                flexDirection="column"
                vAlign="center"
                className={disabledClass}>
                <BudgetSlider
                    size="tiny"
                    color={sliderColorName}
                    config={config}
                    theme="subCampaign"
                />
            </Layout>
        </Layout>
    ) : null;
};

SubCampaign.propTypes = {
    config: PropTypes.object.isRequired,
    style: PropTypes.object.isRequired,
    disable: PropTypes.bool,
    isEditing: PropTypes.bool,
    toggleEditing: PropTypes.func.isRequired,
    channelKey: PropTypes.string.isRequired
};

export default SubCampaign;
