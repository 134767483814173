import request from '../request';

export default function (data) {
    return request({
        method: 'POST',
        url: `plans/payment/`,
        data
    }).then(response => {
        return response;
    }).catch(error => {
        throw Error(error);
    });
};
