import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Layout, Text, Divider, Bar, Icon, Button } from 'maslow';
import styles from './networkdist.scss';
import { round, isLessThanOne, strf, sliceArray } from '@lib';
import NETWORK_MAP from './networks.json';
import iconPlaceholder from '@public/img/network-placeholder.svg';
import networkIcons from '@config/config.networkIcons';

import { MEDIA_PLAN_TOGGLE_VIEW_NETWORKS } from '@config/idConstants';

const NETWORK_LIST_SIZE = 5;

const NetworkDistribution = ({ tvImpressions, networks }) => {
    const [isDisplayAll, setIsDisplayAll] = useState(false);

    const buildNetworks = (item, key) => {
        const itemImpressions = round(
            (tvImpressions * item.DeliveryEstimate) / 100
        );
        const percentage = round(item.DeliveryEstimate);
        const name = NETWORK_MAP[item.Network]
            ? NETWORK_MAP[item.Network].name
            : item.Network;
        const networkIcon = networkIcons[item.Network];

        return (
            <Layout key={key} flexDirection="column">
                <Layout flexDirection="row" vAlign="center">
                    <Layout size="10" flexDirection="column" hAlign="center">
                        <Icon
                            src={networkIcon}
                            size="7"
                            placeholder={iconPlaceholder}
                        />
                        <Text type="input">{item.Network}</Text>
                    </Layout>

                    <Layout
                        flex
                        flexDirection="column"
                        className={styles.barWrapper}>
                        <Bar percentage={percentage} />

                        <Layout
                            flexDirection="row"
                            hAlign="space-between"
                            className={styles.leftPad}>
                            <Layout flexDirection="column">
                                <Text type="input">{name}</Text>
                            </Layout>

                            <Layout flexDirection="row">
                                <Text type="input">
                                    {strf(itemImpressions).commas().value()} |
                                </Text>

                                <Text type="input">
                                    &nbsp;
                                    {isLessThanOne(round(percentage, true))}%
                                </Text>
                            </Layout>
                        </Layout>
                    </Layout>
                </Layout>
                <Divider
                    borderWidth="2px"
                    color="paleCyan"
                    margin="16px 0"></Divider>
            </Layout>
        );
    };

    const onToggleViewNetworks = () => {
        setIsDisplayAll(!isDisplayAll);
    };

    const networkList = isDisplayAll
        ? networks
        : sliceArray(networks, 0, NETWORK_LIST_SIZE);
    const _networks = networkList.map(buildNetworks);
    const buttonText = isDisplayAll
        ? 'Hide Networks'
        : `View All ${networks.length} Networks`;

    return (
        <Layout flexDirection="column" className={styles.wrapper}>
            <Layout
                flexDirection="row"
                vAlign="baseline"
                className={styles.tableTitle}>
                <Text className={styles.title} type="h4">
                    Network Distribution
                </Text>
                <Text type="h5">By impressions</Text>
            </Layout>

            <Layout flexDirection="column">
                <Divider
                    borderWidth="2px"
                    color="paleCyan"
                    margin="16px 0"></Divider>
                <Layout flexDirection="row" alignSelf="flex-end">
                    <Text type="input">
                        <b>Total: {strf(tvImpressions).commas().value()} | </b>
                    </Text>
                    <Text type="input">
                        <b>&nbsp;100%</b>
                    </Text>
                </Layout>
                <Divider
                    borderWidth="2px"
                    color="paleCyan"
                    margin="16px 0"></Divider>
                {_networks}
            </Layout>
            {networks.length > NETWORK_LIST_SIZE && (
                <Layout>
                    <Button
                        id={MEDIA_PLAN_TOGGLE_VIEW_NETWORKS}
                        onClick={onToggleViewNetworks}>
                        {buttonText}
                    </Button>
                </Layout>
            )}
        </Layout>
    );
};

NetworkDistribution.propTypes = {
    tvImpressions: PropTypes.number.isRequired,
    networks: PropTypes.array.isRequired
};

export default NetworkDistribution;
