import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import styles from './styles.scss';

class RightSidebar extends React.Component {
    constructor(props) {
        super(props);

        this.onMenuClick = this.onMenuClick.bind(this);
    }

    onMenuClick() {
        this.props.handleFlyoutNavClick();
    }

    render() {
        const { components } = getFeatures('rightSidebar', this);

        return <div className={styles.sidebar}>{components}</div>;
    }
}

export default withFeatures(RightSidebar);
