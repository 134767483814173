export const pipe = fns => {
    return new Promise((resolve, reject) => {
        if (!fns.length)
            return cancel('error: no functions were provided for pipe.');

        function callback(...args) {
            if (fns.length) {
                const fn = fns[0];

                fns.shift();

                return fn(...args, callback, cancel);
            } else {
                resolve(args);
            }
        }

        function cancel(reason) {
            return reject(reason || 'pipe was cancelled.');
        }

        callback();
    });
};

export const addArgs = (fn, ..._args) => (...args) => fn(..._args, ...args);
