import { roundDown, validateArg, getSumFromArray } from '@lib';
import { budgetConfig } from '@config/config.budget';
import { TESTER } from '@config';
import {
    setRegionPrimaryCpmNet,
    getMaxImpressions,
    getImpressionsWithCPM,
    getSubName,
    getChannelName,
    getSubKey
} from './helpers';

export const getMaxBudget = ({
    hhCountValue,
    duration,
    impressionModifier,
    primary_cpm_net,
    region,
    role,
    userType
}) => {
    const {
        limits: { maxSubBudget }
    } = budgetConfig;
    if (!impressionModifier) return maxSubBudget;
    if (impressionModifier && userType === TESTER) return maxSubBudget;

    const primaryCpmNet = setRegionPrimaryCpmNet(primary_cpm_net, region, role);
    const impressions = getMaxImpressions(
        hhCountValue,
        duration,
        impressionModifier
    );

    return roundDown(getImpressionsWithCPM(primaryCpmNet, impressions));
};

export const getTotalMaxBudget = (subCampaigns, config) => {
    validateArg(subCampaigns, 'object', 1);
    validateArg(config, 'object', 2);

    return getSumFromArray(
        Object.keys(subCampaigns).map(key => {
            //Only add maxBudget to total is max is above minimum
            const { maxBudget, min } = config.subCampaigns[getSubName(key)];
            return min > maxBudget ? 0 : maxBudget;
        })
    );
};

export const getChannelMaxBudget = (key, subCampaigns, config) => {
    validateArg(key, 'string', 1);
    validateArg(subCampaigns, 'object', 2);
    validateArg(config, 'object', 3);

    return getSumFromArray(
        Object.values(config.channels[getChannelName(key)].subCampaigns)
            .filter(subName =>
                Object.keys(subCampaigns).includes(getSubKey(subName))
            )
            .map(subName => {
                //Only add maxBudget to channel total is max is above minimum
                const { maxBudget, min } = config.subCampaigns[subName];
                return min > maxBudget ? 0 : maxBudget;
            })
    );
};

export const getMaxBudgets = (budget, config) => {
    validateArg(budget, 'object', 1);
    validateArg(config, 'object', 2);

    const { subCampaigns, channels } = budget;
    const maxBudgets = {};

    Object.keys(subCampaigns).forEach(key => {
        //Record maxBudget as 0 if max is lower than the minimum
        const { maxBudget, min } = config.subCampaigns[getSubName(key)];
        maxBudgets[key] = min > maxBudget ? 0 : maxBudget;
    });
    Object.keys(channels).forEach(key => {
        maxBudgets[key] = getChannelMaxBudget(key, subCampaigns, config);
    });
    maxBudgets.total = getTotalMaxBudget(subCampaigns, config);

    return maxBudgets;
};
