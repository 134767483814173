import { flatten } from 'lodash';

import { categories } from '@config/config.locations';
import {
    SAVED_AUDIENCES,
    DEMOGRAPHIC,
    AUTOMOTIVE,
    LIFESTYLE,
    POLITICAL
} from '@config/constants';
import { getZones } from '@services';
import { isArray, validateArg } from '@lib';

const {
    ZIP,
    STATE,
    DMA,
    COUNTY,
    US_CONGRESS,
    STATE_HOUSE,
    STATE_LEGISLATIVE,
    STATE_SENATE,
    DISTRICT,
    CITY
} = categories;

export const getLocationsByCategory = (locations, category) => {
    if (!isArray(locations)) throw new Error('First argument must be an array');
    if (typeof category !== 'string')
        throw new Error('Second argument must be a string');

    if (category === DISTRICT) {
        return locations.filter(({ audienceCategory }) => {
            return [
                US_CONGRESS,
                STATE_HOUSE,
                STATE_LEGISLATIVE,
                STATE_SENATE
            ].includes(audienceCategory);
        });
    }
    return locations.filter(
        ({ audienceCategory }) => audienceCategory === category
    );
};

export const formatAudiences = (audiences, key) => {
    if (!isArray(audiences)) throw new Error('First argument must be an array');
    if (typeof key !== 'string')
        throw new Error('Second argument must be a string');

    let items = [];

    audiences.forEach(audience => {
        if (
            audience.category !== SAVED_AUDIENCES &&
            !items.includes(audience[key])
        )
            items.push(audience[key]);
    });

    return items.join(', ');
};

export const filterAudiencesBySection = (audiences, section) => {
    if (!isArray(audiences)) throw new Error('First argument must be an array');
    if (typeof section !== 'string')
        throw new Error('Second argument must be a string');

    return audiences.filter(
        audience => audience?.section?.toLowerCase() === section.toLowerCase()
    );
};

export const getPendoMetaData = (
    data,
    locations,
    status,
    totalImpression,
    totalCpm
) => {
    validateArg(data, 'object', 1);
    validateArg(locations, 'array', 2);
    validateArg(status, 'string', 3);
    validateArg(totalImpression, 'number', 4);
    validateArg(totalCpm, 'number', 5);

    const {
        plan: {
            attributes: { planId, region, campaignType }
        },
        schedule: { startDate, endDate, duration, hiatusWeeks },
        audience: {
            households: { count, total: audienceTotal },
            audiences
        },
        budget: { total: budgetTotal, subCampaigns }
    } = data;

    const products = Object.keys(subCampaigns)
        .map(key => ({
            product: key,
            budget: subCampaigns[key]
        }))
        .filter(({ budget }) => budget);

    const flatAudiences = flatten(audiences);

    return {
        planId,
        status,
        region,
        campaignType,
        duration,
        startDate,
        endDate,
        hiatusWeeks: hiatusWeeks.length ? true : false,
        hhCount: count ? count : audienceTotal,
        locationsAmount: locations.length,
        zipAmount: getLocationsByCategory(locations, ZIP).length,
        stateAmount: getLocationsByCategory(locations, STATE).length,
        dmaAmount: getLocationsByCategory(locations, DMA).length,
        countyAmount: getLocationsByCategory(locations, COUNTY).length,
        cityAmount: getLocationsByCategory(locations, CITY).length,
        districtAmount: getLocationsByCategory(locations, DISTRICT).length,
        locations: getZones(locations),
        impressions: totalImpression,
        cpm: totalCpm.toFixed(4),
        budgetTotal,
        productsAmount: products.length,
        tvBudget: subCampaigns.linearTv ? subCampaigns.linearTv : 0,
        ottBudget: subCampaigns.ott ? subCampaigns.ott : 0,
        preMidRollBudget: subCampaigns.preMidRoll ? subCampaigns.preMidRoll : 0,
        bannersBudget: subCampaigns.banners ? subCampaigns.banners : 0,
        adMessengerBudget: subCampaigns.adMessenger
            ? subCampaigns.adMessenger
            : 0,
        facebookBudget: subCampaigns.facebook ? subCampaigns.facebook : 0,
        instagramBudget: subCampaigns.instagram ? subCampaigns.instagram : 0,
        audienceAmount: audiences.length,
        attributeAmount: flatAudiences.filter(
            ({ category }) => category !== SAVED_AUDIENCES
        ).length,
        audienceCategories: formatAudiences(flatAudiences, 'category'),
        automotive: filterAudiencesBySection(flatAudiences, AUTOMOTIVE).length
            ? true
            : false,
        demographics: filterAudiencesBySection(flatAudiences, DEMOGRAPHIC)
            .length
            ? true
            : false,
        lifestyle: filterAudiencesBySection(flatAudiences, LIFESTYLE).length
            ? true
            : false,
        political: filterAudiencesBySection(flatAudiences, POLITICAL).length
            ? true
            : false,
        attributes: formatAudiences(flatAudiences, 'value'),
        savedAudience: flatAudiences.find(
            ({ category }) => category === SAVED_AUDIENCES
        )
            ? true
            : false
    };
};
