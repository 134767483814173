import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Text, Layout, Card } from 'maslow';

import { AD_MESSENGER, INSTAGRAM, FACEBOOK } from '@config';
import { budgetConfig } from '@config/config.budget';
import { PLAN_STATUSES } from '@config/config.planStatus';
import featuresManager from '@featuresManager';

import { updateBudget } from '@state/budget/actions';
import {
    setIsValid,
    setFacebook,
    setInstagram,
    setAdMessenger
} from '@state/creative/actions';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import { updateTvDetails } from '@state/mediaPlan/actions/tvDetails';
import { buildHHCountPayload } from '@services';
import requestHandlers from '@requestHandlers';

import {
    budgetSelector,
    totalSelector,
    regionSelector,
    subCampaignSelector,
    creativeSelector,
    isAutoDistributedSelector,
    getUserTypeSelector,
    getLockedSubCampaignsSelector,
    getLockedChannelsSelector,
    allMaxBudgetsSelector,
    locationSelector,
    audienceSelector
} from '@selectors/';

import {
    filterConfig,
    getZeroDistribution,
    budgetCalc,
    isProductExist
} from '@services/budget';

import Distribution from './Distribution';
import TotalInput from './TotalInput';

import styles from './styles.scss';

import { BUDGET_TOTAL_RESET_BUTTON } from '@config/idConstants';

export default function (version, feature) {
    const Budget = () => {
        const dispatch = useDispatch();
        const [disabledChannelsKeys, setDisabledChannelsKeys] = useState([]);
        const [role] = featuresManager.getCustomFilterState('role');

        const budget = useSelector(budgetSelector);
        const isAutoDistributed = useSelector(isAutoDistributedSelector);
        const region = useSelector(regionSelector);
        const total = useSelector(totalSelector);
        const subCampaigns = useSelector(subCampaignSelector);
        const creative = useSelector(creativeSelector);
        const userType = useSelector(getUserTypeSelector);
        const lockedSubCampaigns = useSelector(getLockedSubCampaignsSelector);
        const lockedChannels = useSelector(getLockedChannelsSelector);
        const maxBudgets = useSelector(allMaxBudgetsSelector);
        const { selectedLocations } = useSelector(locationSelector);
        const { audiences, combinations } = useSelector(audienceSelector);

        const filteredConfig = filterConfig(
            budgetConfig.distribution,
            budget.distributionConfig
        );

        const createBudgetState = budget => {
            const distribution = getZeroDistribution(budget);
            dispatch(updateBudget.total(distribution));
        };

        const emptyCreativeUrl = () => {
            if (
                subCampaigns[AD_MESSENGER] === 0 &&
                creative.adMessenger.url !== ''
            ) {
                dispatch(setAdMessenger());
            }
            if (
                subCampaigns[INSTAGRAM] === 0 &&
                creative.instagram.url !== ''
            ) {
                dispatch(setInstagram());
            }
            if (subCampaigns[FACEBOOK] === 0 && creative.facebook.url !== '') {
                dispatch(setFacebook());
            }
        };

        const disableUnusedChannels = (channels, lockedChannelKeys = []) => {
            const unusedChannels = Object.keys(channels).filter(
                key => channels[key] === 0 && !lockedChannelKeys.includes(key)
            );
            setDisabledChannelsKeys(unusedChannels);
        };

        const updateState = () => {
            const distribution = getZeroDistribution(budget);
            const newAmounts = budgetCalc.total(
                total,
                distribution,
                role,
                region,
                userType,
                lockedSubCampaigns,
                lockedChannels,
                disabledChannelsKeys,
                maxBudgets
            );
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
            dispatch(setIsValid(false));
            dispatch(updateBudget.total(newAmounts));
            dispatch(updateBudget.setIsAutoDistributed(true));
            disableUnusedChannels(newAmounts.channels, lockedChannels);
        };

        const showResetButton = () => {
            if (total > 0 && isAutoDistributed === false) {
                return (
                    <Layout
                        id={BUDGET_TOTAL_RESET_BUTTON}
                        className={styles.resetButton}
                        flexDirection="row"
                        onClick={() => updateState()}
                        vAlign="center">
                        <Text type="body">Reset</Text>
                    </Layout>
                );
            }
        };

        const getPrimaryTargetDemo = () => {
            const audiencePayload = buildHHCountPayload(
                audiences,
                combinations,
                selectedLocations
            );

            requestHandlers.audiences
                .primaryTargetDemo(audiencePayload)
                .then(({ data }) => {
                    const { response } = data;
                    dispatch(
                        updateTvDetails({
                            name: 'primaryTarget',
                            value: response
                        })
                    );
                })
                .catch(err => {
                    console.error('error loading primaryTargetDemo', err);
                });
        };

        useEffect(() => {
            total >= budgetConfig.limits.min[userType][role][region]
                ? disableUnusedChannels(budget.channels, lockedChannels)
                : createBudgetState(budget);
            getPrimaryTargetDemo();
        }, []);

        useEffect(() => {
            emptyCreativeUrl();
        }, [subCampaigns]);

        return (
            <Layout flexDirection="column" className={styles.budgetWrapper}>
                <Layout flexDirection="row" vAlign="center">
                    <Text type="h3" className={styles.totalTitle}>
                        Specify your budget:
                    </Text>
                    <Card
                        borderRadius="2px"
                        boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                        className={styles.totalCardWrapper}
                        backgroundColor="white">
                        <Layout
                            flexDirection="row"
                            vAlign="center"
                            className={styles.totalCard}>
                            <TotalInput
                                disableUnusedChannels={disableUnusedChannels}
                                disabledChannelsKeys={disabledChannelsKeys}
                            />
                        </Layout>
                        {showResetButton()}
                    </Card>
                </Layout>
                <Distribution
                    config={filteredConfig}
                    disabledChannelsKeys={disabledChannelsKeys}
                    setDisabledChannelsKeys={setDisabledChannelsKeys}
                />
            </Layout>
        );
    };

    return Budget;
}
