import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { Layout, Text, Button } from 'maslow';

import { isEmpty } from '@lib';
import { SAVED_AUDIENCES } from '@config';
import SavedAudience from './SavedAudience';
import SelectedAudience from '../SelectedAudience';
import SelectedAudienceCategory from '../SelectedAudienceCategory';

import styles from '../audiences.scss';

const SelectedAudiences = ({ audienceStyles }) => {
    const { audiences, combinations } = useSelector(state => state.audience);

    const mapAudiencesDataForUi = audiences => {
        return audiences.map(audience => {
            const mappedAudience = {};

            audience.forEach(option => {
                if (!mappedAudience[option.category])
                    mappedAudience[option.category] = [];

                mappedAudience[option.category].push(option);
            });

            return mappedAudience;
        });
    };

    const renderAudiences = () => {
        const mappedAudiences = mapAudiencesDataForUi(audiences);

        return mappedAudiences.map((audience, i) => {
            const categoryNames = Object.keys(audience);

            const enableMyAudience = audience[categoryNames];
            let savedAudience = false;

            if (
                enableMyAudience &&
                enableMyAudience[0].category === SAVED_AUDIENCES
            ) {
                savedAudience = true;
            }

            const audienceLabel = `Audience ${i + 1}`;
            const isCompleteVennSelected = !isEmpty(combinations)
                ? Object.keys(combinations).some(combination => {
                      return combinations[combination].label === audienceLabel;
                  })
                : true;
            const audienceTitleColor = isCompleteVennSelected
                ? 'black'
                : 'gray';

            return (
                <Layout
                    flexDirection="column"
                    key={i}
                    className={styles.marginBottom}>
                    <Layout
                        flexDirection="row"
                        vAlign="center"
                        className={styles.subTitleWrapper}>
                        <div
                            style={audienceStyles[i].circle}
                            className={styles.smallRound}></div>
                        <Text
                            type="input"
                            color={audienceTitleColor}
                            className={styles.boldText}>
                            {audienceLabel}
                        </Text>
                    </Layout>
                    {savedAudience && (
                        <SavedAudience
                            item={enableMyAudience[0]}
                            audienceStyles={audienceStyles[i]}
                        />
                    )}

                    {!savedAudience && (
                        <SelectedAudience
                            key={i}
                            audience={audience}
                            audienceStyles={audienceStyles[i]}
                        />
                    )}
                </Layout>
            );
        });
    };

    return <React.Fragment>{renderAudiences()}</React.Fragment>;
};

SelectedAudiences.propTypes = {
    audienceStyles: PropTypes.array.isRequired
};

export default SelectedAudiences;
