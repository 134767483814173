import { createReducer } from '@reduxjs/toolkit';
import { SET_A4_PLAN_ID } from '../constants';

const initialState = null;

const setA4PlanId = (state, action) => {
    const { value } = action.payload;
    return value === null || typeof value === 'number' ? value : state;
};

export const a4PlanId = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_A4_PLAN_ID, setA4PlanId)
        .addDefaultCase((state, _) => state);
});
