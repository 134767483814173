import { createReducer } from '@reduxjs/toolkit';
import { SET_CURRENT_AUDIENCE_INDEX } from '../constants';

const initialState = 0;

const setCurrentAudienceIndex = (state, action) => {
    const { audienceIndex } = action.payload;
    return typeof audienceIndex === 'number' ? audienceIndex : state;
};

export const currentAudienceIndex = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_CURRENT_AUDIENCE_INDEX, setCurrentAudienceIndex)
        .addDefaultCase((state, _) => state);
});
