import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone';
import { UISref } from '@uirouter/react';
import { isIpad } from '@services';
import { Text, Layout, Icon } from 'maslow';
import { ProgressCircle } from '../CLProgressCircle';
import FileExample from '../CLFileExample';
import { FileInfo } from '../CLFileInfo';
import style from './upload.scss';
import commonStyle from '../styles.scss';
import arrowSvg from '@public/img/UI-Kit-Arrow-gray.svg';
import { VALID_FILE_TYPES } from '@config/config.customList';
import {
    CUSTOM_LIST_DROPZONE,
    CUSTOM_LIST_BACK,
    CUSTOM_LIST_DROPZONE_BROWSE_LINK
} from '@config/idConstants';
import { pathSelector } from '@selectors/ui';
import audienceRoutes from '@config/config.audienceRoutes';

const Upload = props => {
    const { currentStep, match_percent, steps, onDrop } = props;
    const path = useSelector(pathSelector);

    const isDisabled = currentStep && currentStep.name !== 'initial';
    let title = <Text type="h4">{currentStep.title}</Text>;

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        noClick: isIpad(),
        accept: VALID_FILE_TYPES
    });

    if (currentStep.name === 'initial') {
        title = (
            <Layout flexDirection="row">
                <Text type="h4">{currentStep.title}</Text>

                <div
                    {...getRootProps()}
                    id={CUSTOM_LIST_DROPZONE}
                    className={style.smallDrop}>
                    <input {...getInputProps()} />
                    <div />
                    <Text
                        type="h4"
                        color="vividNavy"
                        id={CUSTOM_LIST_DROPZONE_BROWSE_LINK}>
                        Browse
                    </Text>
                </div>

                <Text type="h4"> to match file</Text>
            </Layout>
        );
    }

    return (
        <div>
            <UISref to={audienceRoutes.audience[path]}>
                <div className={style.backText} id={CUSTOM_LIST_BACK}>
                    <span className={style.backIcon}>
                        <Icon src={arrowSvg} size="1" />
                    </span>

                    <Text type="logo">Back</Text>
                </div>
            </UISref>

            <div className={commonStyle.headerWrapper}>
                <Text type="h6" color="deepBlue">
                    Custom Audience
                </Text>
            </div>

            <div className={commonStyle.captionWrapper}>{title}</div>

            <div
                {...getRootProps()}
                className={style.drop}
                disabled={isDisabled}>
                <input {...getInputProps()} />

                <Layout
                    className={style.progressWrapper}
                    vAlign="center"
                    hAlign="center"
                    flexDirection="column"
                    flex>
                    <ProgressCircle
                        currentStep={currentStep}
                        match_percent={match_percent}
                        onReset={props.onReset}
                    />
                </Layout>
            </div>

            <div className={commonStyle.currentStepSubTitle}>
                <Text type="h5">{currentStep.subTitle}</Text>
            </div>

            <div className={commonStyle.currentStepBody}>
                <Text>{currentStep.body}</Text>
            </div>

            {currentStep.blockToDisplay === 'example' && <FileExample />}

            {currentStep.blockToDisplay === 'info' && (
                <FileInfo currentStep={currentStep} steps={steps} />
            )}
        </div>
    );
};

Upload.propTypes = {
    currentStep: PropTypes.object.isRequired,
    steps: PropTypes.object.isRequired,
    onDrop: PropTypes.func.isRequired
};

export default Upload;
