import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setFlyoutNavStatus } from '@state/ui/actions';
import { Text, Icon, Layout } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';
import styles from './styles.scss';
import logoSvg from '@public/img/Athena-R.svg';
import menuSvg from '@public/img/UI-Kit-Menu.svg';

import { MAIN_SIDEBAR_LOGO } from '@config/idConstants';

const MainSidebar = props => {
    const dispatch = useDispatch();
    const flyoutNavStatus = useSelector(state => state.ui.flyoutNavStatus);
    const onClick = () => {
        dispatch(setFlyoutNavStatus(!flyoutNavStatus));
    };

    const { components } = getFeatures('mainSidebar', { props });

    return (
        <div className={styles.sidebar}>
            <div className={styles.logoContainer}>
                <Layout
                    id={MAIN_SIDEBAR_LOGO}
                    flexDirection="column"
                    vAlign="center"
                    hAlign="center"
                    fillParent
                    onClick={onClick}
                    className="clickable flyout-nav-toggle">
                    <Icon src={logoSvg} size="6" className={styles.logoIcon} />

                    <Layout flexDirection="row" vAlign="baseline">
                        <div className={styles.logoMenu}>
                            <Icon src={menuSvg} size="1" />
                        </div>
                        <Text type="logo">Menu</Text>
                    </Layout>
                </Layout>
            </div>

            {components}
        </div>
    );
};

export default withFeatures(MainSidebar);
