import { createReducer } from '@reduxjs/toolkit';
import { SET_IS_RANKER } from '../constants';

const initialState = false;

const setIsRanker = (state, action) => {
    const { isRanker } = action.payload;
    return typeof isRanker === 'boolean' ? isRanker : state;
};

export const isRanker = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_IS_RANKER, setIsRanker)
        .addDefaultCase((state, _) => state);
});
