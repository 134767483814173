import { validateArg } from '@lib';

export default (val, digits = 0) => {
    validateArg(val, 'number', 1);
    validateArg(digits, 'number', 2);

    const multiplicator = Math.pow(10, digits);
    val = parseFloat((val * multiplicator).toFixed(11));
    return Math.round(val) / multiplicator;
};
