import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Text, Bar } from 'maslow';
import { strf, getPercentFromValue, roundUp, isGreaterThanZero } from '@lib';

import styles from './digitalDetails.scss';

const SubCampaign = ({ subCampaign, channelImpression, style }) => {
    const impressionFormatted = strf(subCampaign.impression).commas().value();
    const percent =
        getPercentFromValue(subCampaign.impression, channelImpression) || 0;
    const percentFormatted = strf(percent).append('%').value();
    const isImpressionGreaterThanZero = isGreaterThanZero(
        subCampaign.impression
    );
    const barStyle = {
        borderColor: style.primaryColor
    };

    return (
        isImpressionGreaterThanZero && (
            <Layout
                flexDirection="row"
                vAlign="center"
                className={styles.subCampaingWrapper}>
                <Layout vAlign="center" className={styles.leftWrapper}>
                    <Layout hAlign="flex-end" className={styles.iconWrapper}>
                        <div
                            style={barStyle}
                            className={styles.subChannelBar}
                        />
                    </Layout>

                    <Layout className={styles.iconCaption}>
                        <Text type="body">{subCampaign.name}</Text>
                    </Layout>
                </Layout>

                <Layout flexDirection="column" vAlign="center" fillParent>
                    <Bar percentage={percent} theme={style.theme} />

                    <Layout flexDirection="row" alignSelf="flex-end">
                        <Text type="h5">{impressionFormatted} |</Text>
                        <Text type="h5" className={styles.leftPad}>
                            {percentFormatted}
                        </Text>
                    </Layout>
                </Layout>
            </Layout>
        )
    );
};

SubCampaign.propTypes = {
    subCampaign: PropTypes.object.isRequired,
    channelImpression: PropTypes.number.isRequired,
    style: PropTypes.object.isRequired
};

export default SubCampaign;
