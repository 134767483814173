// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".wrapper___1-hAi {\n  margin-bottom: 24px;\n  margin-top: 40px;\n  width: 100%; }\n\n.tableTitle___15OvB {\n  margin-bottom: 8px; }\n\n.title___9aYSm {\n  margin-right: 8px; }\n\n.barWrapper___2zCSM {\n  padding: 0 0 0 24px; }\n\n.leftPad___3fsnJ {\n  padding-left: 8px; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/features/MediaPlan/1.0.0/NetworkDistribution/networkdist.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAEA;EACI,mBCDyB;EDEzB,gBCAyB;EDCzB,WAAW,EAAA;;AAGf;EACI,kBCTe,EAAA;;ADYnB;EACI,iBCbe,EAAA;;ADgBnB;EACI,mBCfyB,EAAA;;ADkB7B;EACI,iBAAiB,EAAA","file":"networkdist.scss","sourcesContent":["@import '~@scss/settings/baseline';\n\n.wrapper {\n    margin-bottom: $baseline-x3;\n    margin-top: $baseline-x5;\n    width: 100%;\n}\n\n.tableTitle {\n    margin-bottom: $baseline;\n}\n\n.title {\n    margin-right: $baseline;\n}\n\n.barWrapper {\n    padding: 0 0 0 $baseline-x3;\n}\n\n.leftPad {\n    padding-left: 8px;\n}\n","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"wrapper": "wrapper___1-hAi",
	"tableTitle": "tableTitle___15OvB",
	"title": "title___9aYSm",
	"barWrapper": "barWrapper___2zCSM",
	"leftPad": "leftPad___3fsnJ"
};
module.exports = exports;
