import { validateArg } from '@lib';

export const getLeftShift = networkScrollRef => {
    if (networkScrollRef === null) return 0;
    validateArg(networkScrollRef, 'element');

    const scrollBoundary = networkScrollRef.getBoundingClientRect().left + 10;
    const scrollWidth = networkScrollRef.getBoundingClientRect().width;
    const childNodes = networkScrollRef.childNodes;

    let counter = 0;
    const fourthFromTheLeft =
        Array.from(childNodes)
            .reverse()
            .find((node, i) => {
                const nodeBoundary = node.getBoundingClientRect().left;
                if (nodeBoundary <= scrollBoundary) counter++;

                if (counter === 4) return true;
            })
            ?.getBoundingClientRect()?.left || scrollBoundary;

    return fourthFromTheLeft - scrollBoundary || -scrollWidth;
};

export const getRightShift = networkScrollRef => {
    if (networkScrollRef === null) return 0;
    validateArg(networkScrollRef, 'element');

    const scrollBoundary = networkScrollRef.getBoundingClientRect().left + 10;
    const scrollWidth = networkScrollRef.getBoundingClientRect().width;
    const childNodes = networkScrollRef.childNodes;

    let counter = 0;
    const fourthFromTheLeft =
        Array.from(childNodes)
            .find((node, i) => {
                const nodeBoundary = node.getBoundingClientRect().left;
                if (nodeBoundary > scrollBoundary) counter++;

                if (counter === 4) return true;
            })
            ?.getBoundingClientRect()?.left || scrollBoundary;
    return fourthFromTheLeft - scrollBoundary || scrollWidth;
};
