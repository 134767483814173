import clone from 'clone';
import * as store from '../store';

class StateManager {
    constructor() {
        this._store = null;
    }

    init(state) {
        this._store = clone(state);
    }

    destroy() {
        this._store = null;
    }

    getDefaultStore(name) {
        return store[name];
    }

    update(name, state, uniqueName) {
        _throwNameError(name);

        if (!state) throw Error('no state value provided');
        if (!this._store) throw Error('no stores have been set');

        const store = this._recurse(_splitName(name), this._store);
        Object.assign(store, clone(state));

        window.emitter.emit(`state.${uniqueName || name}`, store);
    }

    subscribe(name, callback) {
        _throwNameError(name);

        if (!callback) throw Error('no callback provided');
        if (typeof callback !== 'function')
            throw Error('typeof callback is not a function');

        return window.emitter.addListener(`state.${name}`, callback);
    }

    getState(name) {
        _throwNameError(name);
        if (!this._store) throw Error('no stores have been set');
        return clone(this._recurse(_splitName(name), this._store));
    }

    deleteProperty(name, property) {
        if (!name || !property)
            throw Error('please provide store name and property to delete');
        if (!this._store) throw Error('no stores have been set');

        const store = this._recurse(_splitName(name), this._store);
        if (!(property in store))
            throw Error('property not found in the store');
        delete store[property];
    }

    _recurse(arr, context) {
        if (!arr.length) {
            if (context === undefined)
                throw Error('property not found in the store');
            return context;
        }

        const newContext = context[arr[0]];
        arr.shift();
        return this._recurse(arr, newContext);
    }
}

function _splitName(name) {
    return name.split('.');
}

function _throwNameError(name) {
    if (!name) throw Error('no store name provided');
    if (typeof name !== 'string') throw Error('typeof name is not string');
}

export default new StateManager();
