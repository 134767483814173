import {
    COMMERCIAL,
    POLITICAL,
    MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN
} from '@config';
import App from '../components/main/App';
import { MainSidebar } from '../components/layouts';
import featuresManager from '@featuresManager';
import { loginService } from '@services';

const ls = loginService();

export default [
    {
        name: 'app',
        url: '/app',
        abstract: true,
        views: {
            '': {
                component: App
            },
            'mainSidebar@app': {
                component: MainSidebar
            }
        },
        onEnter: props => {
            const data = ls.getAndDecodeJwt();

            if (!data) ls.logout(props.router);
            const { type, permissions } = data;

            featuresManager.setCustomFilterState('type', type);
            const role = !permissions.includes(
                MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN
            )
                ? [POLITICAL]
                : [COMMERCIAL];
            featuresManager.setCustomFilterState('role', role);
        }
    }
];
