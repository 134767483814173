export const SET_CAMPAIGN_ROUTER_ID = 'SET_CAMPAIGN_ROUTER_ID';
export const SET_PLAN_STATUS = 'SET_PLAN_STATUS';

export const SET_HAS_TV_DETAILS = 'SET_HAS_TV_DETAILS';

export const UPDATE_TV_DETAILS = 'UPDATE_TV_DETAILS';
export const REMOVE_ALL_TV_DETAILS = 'REMOVE_ALL_TV_DETAILS';

export const REMOVE_ALL_DIGITAL_DETAILS = 'REMOVE_ALL_DIGITAL_DETAILS';

export const SET_A4_PLAN_ID = 'SET_A4_PLAN_ID';
