import React from 'react';

import { Layout, Text } from 'maslow';

import style from './styles.scss';

export const LeftIllustrationSection = props => {
    return (
        <Layout flex flexDirection="column" className={style.spaceAround}>
            <Layout
                flex
                flexDirection="row"
                hAlign="center"
                className={style.illustrationWrapper}></Layout>

            <Layout flexDirection="row" className={style.textWrapper}>
                <Text type="h5" className={style.topText}>
                    One household. Multiple screens. Target them all with
                    precision.
                </Text>
            </Layout>
        </Layout>
    );
};
