import React from 'react';
import { Layout } from 'maslow';

export default function (version, feature) {
    return function () {
        return (
            <Layout fillParent>
                <div></div>
            </Layout>
        );
    };
}
