import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { UISref } from '@uirouter/react';

import styles from '../styles.scss';
import { getFeatures, withFeatures } from '@delivery/react';
import {
    hasMultipleNotEmptyAudiences,
    isTargetingEveryone
} from '@services/audiencesService';
import {
    removeCategoryFromAudience,
    removeOptionFromAudience
} from '@state/audience/actions/audiences';
import { toggleIsSummaryScrollbar } from '@state/audience/actions/toggleIsSummaryScrollbar';
import { Layout, Button, Icon, Text, Divider } from 'maslow';
import EmptySelection from '../EmptySelection';
import saveIcon from '@public/img/Save-Arrow.svg';
import saveIconDisabled from '@public/img/Save-Arrow-Disabled.svg';
import audienceRoutes from '@config/config.audienceRoutes';

import {
    AUDIENCE_SUMMARY_SELECT_COMBINATIONS,
    AUDIENCE_SUMMARY_OPEN_SAVE_POPUP
} from '@config/idConstants';

const isScrollbarVisible = element => {
    return element.scrollHeight > element.clientHeight;
};

class Summary extends React.Component {
    constructor(props) {
        super(props);
        this.wrapperRef = React.createRef();
    }

    componentDidUpdate() {
        this.props.toggleIsSummaryScrollbar(
            isScrollbarVisible(this.wrapperRef.current)
        );
    }

    renderEmpty() {
        return (
            <div className={styles.emptyWrapper}>
                <EmptySelection
                    title={this.props.title}
                    description={this.props.description}
                    currentAudienceIndex={this.props.currentAudienceIndex}
                />
            </div>
        );
    }

    renderSelectedAudiences() {
        const { components } = getFeatures('interactiveAudienceSummary', this, {
            interactive: true
        });

        return components;
    }

    render() {
        const { getDisableState, openSaveAudiencePopup } = this.props;
        const selectIsDisabled = !hasMultipleNotEmptyAudiences(
            this.props.audiences
        );
        const saveIsDisabled = getDisableState();
        const saveTextStyle = saveIsDisabled
            ? 'styles.saveTextDisabled'
            : 'styles.saveTextEnabled';
        const saveIconSrc = saveIsDisabled ? saveIconDisabled : saveIcon;
        const content = !isTargetingEveryone(this.props.audiences)
            ? this.renderSelectedAudiences()
            : this.renderEmpty();
        const route = this.props.isRanker
            ? audienceRoutes.venn.ranker
            : audienceRoutes.venn.plan;

        return (
            <Layout flexDirection="column" className={styles.summaryWrapper}>
                <Layout
                    flexDirection="column"
                    hAlign="center"
                    vAlign="center"
                    className={styles.buttonsWrapper}>
                    <Layout
                        hAlign="center"
                        className={styles.combinationsButtonWrapper}>
                        <UISref to={route}>
                            <Button
                                id={AUDIENCE_SUMMARY_SELECT_COMBINATIONS}
                                disabled={selectIsDisabled}
                                theme="outline">
                                Select Combinations
                            </Button>
                        </UISref>
                    </Layout>
                    <Layout flexDirection="row" hAlign="center" vAlign="center">
                        <Layout
                            id={AUDIENCE_SUMMARY_OPEN_SAVE_POPUP}
                            flexDirection="row"
                            hAlign="center"
                            vAlign="center"
                            className={
                                saveIsDisabled
                                    ? styles.saveIconDisabled
                                    : styles.saveIcon
                            }
                            onClick={openSaveAudiencePopup}>
                            <Text
                                className={`${saveTextStyle} ${styles.saveTextFont}`}
                                type="input">
                                Save Audience
                            </Text>
                            <Icon
                                src={saveIconSrc}
                                className={styles.icon}
                                size="2"
                                key={saveIconSrc}
                            />
                        </Layout>
                    </Layout>
                </Layout>

                <Divider
                    color="paleCyan"
                    borderWidth="2px"
                    margin="8px 0 4px 0"
                />

                <div className={styles.listWrapper} ref={this.wrapperRef}>
                    {content}
                </div>
            </Layout>
        );
    }
}

Summary.propTypes = {
    audiences: PropTypes.array.isRequired,
    currentAudienceIndex: PropTypes.number.isRequired,
    isRanker: PropTypes.bool.isRequired,
    removeCategoryFromAudience: PropTypes.func.isRequired,
    removeOptionFromAudience: PropTypes.func.isRequired,
    title: PropTypes.string,
    description: PropTypes.string
};

const mapStateToProps = state => ({
    audiences: state.audience.audiences,
    currentAudienceIndex: state.audience.currentAudienceIndex,
    isRanker: state.ui.isRanker
});

const mapDispatchToProps = dispatch => ({
    removeCategoryFromAudience: (audienceIndex, category) =>
        dispatch(removeCategoryFromAudience(audienceIndex, category)),

    removeOptionFromAudience: (audienceIndex, id) =>
        dispatch(removeOptionFromAudience(audienceIndex, id)),

    toggleIsSummaryScrollbar: isVisible =>
        dispatch(toggleIsSummaryScrollbar(isVisible))
});

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(withFeatures(Summary));
