import React, { useReducer, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import requestHandlers from '@requestHandlers';
import { Text, Textarea, Layout, Input, Button } from 'maslow';
import { UISref } from '@uirouter/react';
import { strf } from '@lib';
import { audienceNameExists } from '@services';
import { setUploadStep } from '@state/customList/actions';
import {
    replaceAudience,
    addOptionToAudience,
    toggleIsAudienceSaved
} from '@state/audience/actions';
import {
    fetchHouseholdCount,
    toggleIsAudienceModified
} from '@state/audience/actions/';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import {
    customListSelector,
    userSelector,
    audienceSelector,
    campaignTypeSelector
} from '@selectors';
import { UPLOAD_STEPS, DISPLAY_TEXT_STATES } from '@config/config.customList';

import {
    CUSTOM_LIST_AUDIENCE_NAME_INPUT,
    CUSTOM_LIST_AUDIENCE_DESCRIPTION_INPUT,
    CUSTOM_LIST_AUDIENCE_SAVE,
    CUSTOM_LIST_AUDIENCE_SAVE_USE
} from '@config/idConstants';
import { SAVED_AUDIENCES, ERROR, IDLE } from '@config';
import {
    MAX_AUDIENCE_NAME_LIMIT,
    MAX_AUDIENCE_DESC_LIMIT
} from '@config/config.customList';

import { PLAN_STATUSES } from '@config/config.planStatus';
import audienceRoutes from '@config/config.audienceRoutes';

import styles from './styles.scss';
import { pathSelector } from '@selectors/';

const initialState = {
    saveOnlyButtonAnimate: false,
    saveAndUseButtonAnimate: false,
    audienceName: '',
    audienceDescription: '',
    errorText: '',
    isDisabled: false,
    audienceNameStatus: IDLE
};

const reducer = (state, action) => {
    const { type, payload } = action;
    switch (type) {
        case 'updateValue':
            return {
                ...state,
                ...payload
            };
        default:
            throw new Error();
    }
};

export const FileInfo = props => {
    const [
        {
            audienceName,
            audienceDescription,
            errorText,
            isDisabled,
            audienceNameStatus,
            saveAndUseButtonAnimate,
            saveOnlyButtonAnimate
        },
        stateDispatch
    ] = useReducer(reducer, initialState);

    const { currentStep, steps } = props;

    const dispatch = useDispatch();
    const customList = useSelector(customListSelector);
    const savedAudiences = useSelector(audienceSelector);
    const {
        account: { id: externalId }
    } = useSelector(userSelector);
    const campaignType = useSelector(campaignTypeSelector);
    const path = useSelector(pathSelector);

    const [audienceNameCount, audienceDescriptionCount] = [
        audienceName.length,
        audienceDescription.length
    ];

    const { num_matches, num_rows } = customList;
    const rows = num_rows || 0;
    const matches =
        currentStep.name === UPLOAD_STEPS.PROMPT_TO_SAVE ||
        currentStep.name === UPLOAD_STEPS.NEXT_STEPS
            ? num_matches || 0
            : currentStep.matchesText;

    const matchesFormatted = strf(matches).commas().value();
    const rowsFormatted = strf(rows).commas().value();

    const updateComponentState = value => {
        stateDispatch({
            type: 'updateValue',
            payload: { ...value }
        });
    };

    useEffect(() => {
        if (audienceName && audienceDescription) {
            updateComponentState({ isDisabled: false });
        } else {
            updateComponentState({ isDisabled: true });
        }
    }, [audienceName, audienceDescription]);

    const buildPayload = customList => {
        return customList
            ? {
                  ...customList,
                  name: audienceName,
                  description: audienceDescription,
                  externalId,
                  campaignType: campaignType
              }
            : {
                  name: audienceName,
                  description: audienceDescription,
                  externalId,
                  campaignType: campaignType
              };
    };

    const onAudienceChange = () => {
        dispatch(fetchHouseholdCount());
        dispatch(toggleIsAudienceModified(true));
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    const stopButtonAnimation = useAud => {
        if (useAud) {
            updateComponentState({ saveAndUseButtonAnimate: false });
        } else {
            updateComponentState({ saveOnlyButtonAnimate: false });
        }
    };

    const useAudience = ({ name, id }) => {
        const optionToSave = {
            name: name,
            id: id.toString(),
            category: SAVED_AUDIENCES
        };
        const { currentAudienceIndex, audiences } = savedAudiences;
        const currentAudience = audiences[currentAudienceIndex];

        if (currentAudience.length) {
            dispatch(replaceAudience(currentAudienceIndex, [optionToSave]));
            return onAudienceChange();
        }

        dispatch(addOptionToAudience(currentAudienceIndex, optionToSave));

        onAudienceChange();
    };

    const saveAudience = async useAud => {
        const isAudienceNameExist = await audienceNameExists({
            savedAudiences,
            audienceName,
            externalId,
            campaignType
        });
        if (isAudienceNameExist) {
            updateComponentState({
                errorText: DISPLAY_TEXT_STATES.EXISTING_AUDIENCE_NAME,
                isDisabled: true,
                audienceNameStatus: ERROR
            });
            stopButtonAnimation(useAud);
        } else {
            requestHandlers.customList
                .save(buildPayload(customList))
                .then(res => {
                    if (res.status === 200) {
                        updateComponentState({
                            audienceDescription: '',
                            audienceName: ''
                        });
                        dispatch(toggleIsAudienceSaved(true));

                        dispatch(
                            setUploadStep(steps[UPLOAD_STEPS.NEXT_STEPS].name)
                        );
                        if (useAud) {
                            useAudience(res.data);
                        }
                    } else {
                        updateComponentState({
                            audienceDescription: '',
                            audienceName: ''
                        });

                        stopButtonAnimation(useAud);
                        console.error(
                            DISPLAY_TEXT_STATES.ERROR_SAVING_AUDIENCE
                        );
                    }
                })
                .catch(error => {
                    stopButtonAnimation(useAud);
                    console.error(
                        DISPLAY_TEXT_STATES.ERROR_SAVING_AUDIENCE,
                        error
                    );
                });
        }
    };

    const renderNextStepsLink = () => {
        return (
            <UISref to={audienceRoutes.audiences[path]}>
                <a className={styles.nextStepsLink}>Saved</a>
            </UISref>
        );
    };

    const renderNextSteps = () => {
        return (
            <Layout
                flexDirection="column"
                hAlign="space-between"
                vAlign="top"
                className={styles.cardWrapper}>
                <Layout
                    flexDirection="column"
                    hAlign="space-between"
                    vAlign="top"
                    className={styles.cardWrapper}>
                    {renderSaveDialogTitle()}
                    <Text className={styles.nextStepsText}>
                        {currentStep.subText} {renderNextStepsLink()}
                    </Text>
                </Layout>
            </Layout>
        );
    };

    const renderSaveDialogTitle = () => {
        return (
            <Layout flexDirection="row">
                <Text type="h5" color="deepBlue">
                    SAVE YOUR CUSTOM AUDIENCE
                </Text>
            </Layout>
        );
    };

    const renderSaveDialog = () => {
        return (
            <Layout
                flexDirection="column"
                hAlign="space-between"
                vAlign="top"
                className={styles.cardWrapper}>
                {renderSaveDialogTitle()}
                <Layout flexDirection="column">
                    <Input
                        id={CUSTOM_LIST_AUDIENCE_NAME_INPUT}
                        label="Audience Name"
                        name="audienceName"
                        isLabelFloated={false}
                        onChange={value =>
                            updateComponentState({ audienceName: value })
                        }
                        value={audienceName}
                        maxLength={MAX_AUDIENCE_NAME_LIMIT}
                        errorText={errorText}
                        status={audienceNameStatus}
                        className={styles.audienceNameTitle}
                    />
                    <Text type="input" className={styles.audienceNameCount}>
                        {audienceNameCount}/{MAX_AUDIENCE_NAME_LIMIT}
                    </Text>

                    <label className={styles.audienceDescriptionTitle}>
                        Description
                    </label>
                    <Textarea
                        id={CUSTOM_LIST_AUDIENCE_DESCRIPTION_INPUT}
                        name="audienceDescription"
                        onChange={value =>
                            updateComponentState({ audienceDescription: value })
                        }
                        value={audienceDescription}
                        maxLength={MAX_AUDIENCE_DESC_LIMIT}
                        className={styles.audienceDescriptionText}
                    />
                    <Text
                        type="input"
                        className={styles.audienceDescriptionCount}>
                        {audienceDescriptionCount}/{MAX_AUDIENCE_DESC_LIMIT}
                    </Text>
                </Layout>
                <Layout
                    className={styles.saveButton}
                    flexDirection="row"
                    vAlign="left">
                    <Layout
                        flexDirection="column"
                        className={styles.btnWrapper}>
                        <Button
                            className={styles.saveBtn}
                            id={CUSTOM_LIST_AUDIENCE_SAVE_USE}
                            theme="primary"
                            disabled={isDisabled}
                            onClick={() => {
                                saveAudience(true);
                                updateComponentState({
                                    saveAndUseButtonAnimate: true
                                });
                            }}
                            animate={saveAndUseButtonAnimate}>
                            Save and use
                        </Button>
                    </Layout>

                    <Layout
                        flexDirection="column"
                        className={styles.btnWrapper}>
                        <Button
                            className={styles.saveBtn}
                            id={CUSTOM_LIST_AUDIENCE_SAVE}
                            disabled={isDisabled}
                            theme="outline"
                            onClick={() => {
                                saveAudience(false);
                                updateComponentState({
                                    saveOnlyButtonAnimate: true
                                });
                            }}
                            animate={saveOnlyButtonAnimate}>
                            Save only
                        </Button>
                    </Layout>
                </Layout>
            </Layout>
        );
    };

    return (
        <Layout flexDirection="column">
            <Layout flexDirection="row" margin="0 0 16px 0">
                <Layout flexDirection="column">
                    <span className={styles.header}>Matched</span>

                    <span className={styles.row}>
                        <Text
                            type="h6"
                            className={
                                currentStep.matchesText === 'pending' &&
                                'pulsate'
                            }
                            color="deepBlue">
                            {matchesFormatted}
                        </Text>
                    </span>
                </Layout>

                <Layout flexDirection="column">
                    <span className={styles.header}>Potential</span>

                    <span className={styles.row}>
                        <Text type="h6" color="deepBlue">
                            {rowsFormatted}
                        </Text>
                    </span>
                </Layout>
            </Layout>
            {currentStep.name === UPLOAD_STEPS.PROMPT_TO_SAVE &&
                renderSaveDialog()}
            {currentStep.name === UPLOAD_STEPS.NEXT_STEPS && renderNextSteps()}
        </Layout>
    );
};
