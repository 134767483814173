import React from 'react';

import { getFeature, withFeatures } from '@delivery/react';
import styles from './styles.scss';
import { AUDIENCE_PAGE } from '@config';

const Component = props => {
    const HouseholdCount = getFeature(
        'HouseholdCount',
        'leftSidebarCombinations'
    );

    const AudienceSummarySelections = getFeature(
        'AudienceSummarySelections',
        'leftSidebarCombinations'
    );

    return (
        <div className={styles.leftSidebar}>
            <HouseholdCount {...props} page={AUDIENCE_PAGE} />

            <div className={styles.wrapper}>
                <AudienceSummarySelections interactive={false} />
            </div>
        </div>
    );
};

export const LeftSidebarCombinations = withFeatures(Component);
