import { SAVE_IMPRESSION_MODIFIER_CONFIG } from '../constants';

export const impressionModifierConfig = (state = {}, action) => {
    switch (action.type) {
        case SAVE_IMPRESSION_MODIFIER_CONFIG: {
            const { impressionModifierConfig } = action.payload;

            return typeof impressionModifierConfig === 'object'
                ? impressionModifierConfig
                : state;
        }

        default: {
            return state;
        }
    }
};
