import React, { memo } from 'react';
import { UISrefActive, UISref } from '@uirouter/react';
import { useSelector } from 'react-redux';

import { Icon, Text, Layout } from 'maslow';
import { validateElementInteraction } from '@components/common';
import { planNav } from '@config/config.planNav';
import featuresManager from '@featuresManager';

import { getFilteredPlanNavItems } from '@services/';
import { budgetConfigSelector, uiSelector, userSelector } from '@selectors/';

import style from './planNav.scss';

const navIconStyle = { display: 'block' };

const NavItem = memo(props => {
    const className = props.newlyActive ? style.newlyActive : '';
    const testId = props.navItem.testId;
    return (
        <UISrefActive class={style.active}>
            <div
                className={[style.item, props.navItem.className].join(' ')}
                style={{ ...props.style, ...navIconStyle }}>
                <UISref to={props.navTo}>
                    <a id={testId} data-testid={testId}>
                        <Layout
                            flexDirection="column"
                            hAlign="center"
                            className={className}>
                            <Icon src={props.navItem.icon} size="3" />
                            <Text type="caption" className={style.caption}>
                                {props.navItem.text}
                            </Text>
                            <div className={style.circle} />
                        </Layout>
                    </a>
                </UISref>
            </div>
        </UISrefActive>
    );
});

const buildValidationComponents = isRanker => {
    const navItems = isRanker ? 'rankerNavItems' : 'planNavItems';
    return planNav[navItems].map(navItem => {
        const { validationRules } = navItem;

        return {
            navItem,
            ValidationComponent: validateElementInteraction(
                validationRules,
                navItem.text
            )
        };
    });
};

const buildNavItems = (validationComponents, isRanker) => {
    const budgetSelector = useSelector(budgetConfigSelector);
    const [role] = featuresManager.getCustomFilterState('role');
    const [region] = featuresManager.getCustomFilterState('region');
    const [type] = featuresManager.getCustomFilterState('type');
    const effectiveUserPermissions = [role, region, type];
    const {
        account: { permissions }
    } = useSelector(userSelector);

    return getFilteredPlanNavItems(
        validationComponents,
        budgetSelector,
        effectiveUserPermissions,
        permissions,
        style
    ).map(({ navItem, ValidationComponent }) => (
        <ValidationComponent key={navItem.text} className={navItem.className}>
            <NavItem navTo={navItem.to} navItem={navItem} />
        </ValidationComponent>
    ));
};

export default function (version, feature) {
    const Component = () => {
        const { isRanker } = useSelector(uiSelector);
        const validationComponents = buildValidationComponents(isRanker);
        const navItems = buildNavItems(validationComponents);
        return (
            <React.Fragment>
                <div className={style.planNav}>{navItems}</div>
            </React.Fragment>
        );
    };

    return Component;
}
