export default {
    name: 'MainTopbar', // Component name
    description: 'Topbar for main area of app',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: [
        'main'
    ],
    sections: [
        'topbar'
    ],
    permissions: [],
    data: {},
    attributes: {},
    modifications: {}
};
