import React from 'react';
import { SelectedAudiences } from './SelectedAudiences';

export default function (version, feature) {
    const { selectedAudienceStyles } = feature.data;

    const AudienceSummarySelections = props => {
        const { interactive } = props;

        return (
            <SelectedAudiences
                interactive={interactive}
                selectedAudienceStyles={selectedAudienceStyles}
            />
        );
    };

    return AudienceSummarySelections;
}
