import { createReducer } from '@reduxjs/toolkit';
import { SET_SKIP_UPLOAD } from '../constants';

const initialState = false;

const setSkipUpload = (state, action) => {
    const { value } = action.payload;
    return typeof value === 'boolean' ? value : state;
};

export const skipUpload = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_SKIP_UPLOAD, setSkipUpload)
        .addDefaultCase((state, _) => state);
});
