import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { addAudience, removeAudience } from '@state/audience/actions/audiences';
import { setCurrentAudienceIndex } from '@state/audience/actions/currentAudienceIndex';
import { sliceArray } from '@lib';

import styles from './styles.scss';
import { Card, Text, Layout } from 'maslow';
import AddNew from './AddNew';

const CURRENT = 'current';
const CURRENT_EMPTY = 'currentEmpty';
const INACTIVE = 'inactive';
const CLASS_NAME = 'circle';

const getCircleClassNames = (styles, type, key) => {
    return [styles.circle, styles[type], styles[CLASS_NAME + (key + 1)]].join(
        ' '
    );
};

const getAudienceToRemove = (audiences, index) => {
    const toRemove = [];

    audiences.forEach((audience, key) => {
        if (key > index && audience.length === 0) toRemove.push(key);
    });

    return toRemove;
};

export const MultiAudiences = (version, feature) => {
    class Component extends React.Component {
        constructor(props) {
            super(props);

            this.clickOnSet = this.clickOnSet.bind(this);
            this.addAudience = this.addAudience.bind(this);
        }

        componentWillUnmount() {
            this.removeEmpty();
        }

        removeEmpty() {
            const toRemove = getAudienceToRemove(this.props.audiences, 0);

            if (toRemove.indexOf(this.props.currentAudienceIndex) !== -1) {
                this.props.setCurrentAudienceIndex(0);
                this.removeEmptyAudiences(toRemove);
            }
        }

        removeEmptyAudiences(toRemove) {
            toRemove.forEach(index => this.props.removeAudience(index));
        }

        clickOnSet(index) {
            this.removeEmptyAudiences(
                getAudienceToRemove(this.props.audiences, index)
            );
            this.props.setCurrentAudienceIndex(index);
        }

        addAudience() {
            const currentLength = this.props.audiences.length;
            this.props.addAudience();
            this.props.setCurrentAudienceIndex(currentLength);
        }

        getCircleType(key, currentIndex, audience) {
            if (key !== currentIndex) return INACTIVE;
            return audience.length > 0 || key === 0 ? CURRENT : CURRENT_EMPTY;
        }

        buildVenn() {
            return this.props.audiences.map((audience, key) => {
                const type = this.getCircleType(
                    key,
                    this.props.currentAudienceIndex,
                    audience
                );
                const style = getCircleClassNames(styles, type, key);

                return (
                    <Layout
                        key={key}
                        className={style}
                        vAlign="center"
                        hAlign="center"
                        onClick={() => this.clickOnSet(key)}>
                        <Text type="h4">{key + 1}</Text>
                    </Layout>
                );
            });
        }

        render() {
            const venn = this.buildVenn();
            return (
                <div className={styles.wrapper}>
                    <Layout hAlign="center">
                        <Layout className={styles.venn} flexDirection="row">
                            {venn}

                            <AddNew
                                audiences={this.props.audiences}
                                addAudience={this.addAudience}
                            />
                        </Layout>
                    </Layout>
                </div>
            );
        }
    }

    Component.propTypes = {
        audiences: PropTypes.array.isRequired,
        currentAudienceIndex: PropTypes.number.isRequired,
        addAudience: PropTypes.func.isRequired,
        setCurrentAudienceIndex: PropTypes.func.isRequired,
        removeAudience: PropTypes.func.isRequired
    };

    return Component;
};

export default function (version, feature) {
    const Component = MultiAudiences(version, feature);

    const mapStateToProps = state => ({
        audiences: state.audience.audiences,
        currentAudienceIndex: state.audience.currentAudienceIndex
    });

    const mapDispatchToProps = dispatch => ({
        removeAudience: audienceIndex =>
            dispatch(removeAudience(audienceIndex)),
        addAudience: () => dispatch(addAudience()),
        setCurrentAudienceIndex: audienceIndex =>
            dispatch(setCurrentAudienceIndex(audienceIndex))
    });

    return connect(mapStateToProps, mapDispatchToProps)(Component);
}
