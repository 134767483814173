import { createReducer } from '@reduxjs/toolkit';
import { UPDATE_IS_AUTO_DISTRIBUTED } from '../constants';

const initialState = [];

const updateIsAutoDistributed = (state, action) => {
    const { isAutoDistributed } = action.payload;
    return typeof isAutoDistributed === 'boolean' ? isAutoDistributed : state;
};

export const isAutoDistributed = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_IS_AUTO_DISTRIBUTED, updateIsAutoDistributed)
        .addDefaultCase((state, _) => state);
});
