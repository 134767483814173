export default {
    name: 'Book', // Component name
    description: 'Book feature',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan'],
    sections: ['book'],
    permissions: [],
    data: {
        maxPayNowLimit: 25000,
        apiKey: {
            liveMode: 'pk_live_6uvnHnaVxKyi6ljjDL4PTAMG00GV8lxb7K',
            testMode: 'pk_test_x0IoSqAITfT1FvztwaJ9R2hk00baNsq2vx'
        },
        routes: {
            campaignPage: 'app.plan.campaign',
            locationPage: 'app.plan.location'
        }
    },
    attributes: {},
    modifications: {}
};
