import request from '../request';

export default function (email, password) {
    return request({
        method: 'POST',
        url: 'auth/login/',
        data: {
            email,
            password
        }
    }).then(response => {
        return response;
    }).catch(error => {
        return { message: error, status: 400 };
    });
};
