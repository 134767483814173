// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".wrapper___2FyH_ {\n  margin-top: 40px;\n  margin-bottom: 40px; }\n\n.title___3VwFL {\n  margin-bottom: 24px; }\n\n.textSegment1___1zP1Q {\n  margin-bottom: 32px; }\n\n.textSegment2___sCaAv {\n  margin-bottom: 16px; }\n\n.buttonContainer___1XNmz {\n  width: 125px; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/features/MediaPlan/1.0.0/WhatsNext/styles.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAEA;EACI,gBCCyB;EDAzB,mBCAyB,EAAA;;ADE7B;EACI,mBCLyB,EAAA;;ADQ7B;EACI,mBCRyB,EAAA;;ADW7B;EACI,mBCdyB,EAAA;;ADiB7B;EACI,YAAY,EAAA","file":"styles.scss","sourcesContent":["@import '~@scss/settings/baseline';\n\n.wrapper {\n    margin-top: $baseline-x5;\n    margin-bottom: $baseline-x5;\n}\n.title {\n    margin-bottom: $baseline-x3;\n}\n\n.textSegment1 {\n    margin-bottom: $baseline-x4;\n}\n\n.textSegment2 {\n    margin-bottom: $baseline-x2;\n}\n\n.buttonContainer {\n    width: 125px;\n}\n","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"wrapper": "wrapper___2FyH_",
	"title": "title___3VwFL",
	"textSegment1": "textSegment1___1zP1Q",
	"textSegment2": "textSegment2___sCaAv",
	"buttonContainer": "buttonContainer___1XNmz"
};
module.exports = exports;
