import React from 'react';
import { Content } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';
import shared from '@sharedStyle/page.scss';

const Plans = props => {
    const { components } = getFeatures('plans', { props });

    return (
        <Content className={shared.pageWrapper} style={props.fadeIn}>
            {components}
        </Content>
    );
};

export default withFeatures(Plans);
