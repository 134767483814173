import request from '../request';

export default function () {
    const req = {
        method: 'GET',
        url: 'audiences/savedAudiences/'
    };

    return request(req);
};
