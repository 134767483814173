import audienceSvg from '@public/img/UI-Kit-Final_Audience.svg';
import scheduleSvg from '@public/img/schedule-default.svg';
import creativeSvg from '@public/img/creative-default.svg';
import budgetSvg from '@public/img/budget-default.svg';
import networksSvg from '@public/img/media-default.svg';
import mediaPlanSvg from '@public/img/mediaplan-default.svg';
import bookSvg from '@public/img/book-default.svg';
import locationSvg from '@public/img/location-default.svg';
import campaignSvg from '@public/img/campaign.svg';

import {
    INTERNAL,
    EXTERNAL,
    NATIONAL,
    COMMERCIAL,
    MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN,
    CAN_NOT_BOOK
} from '@config';

export const planNav = {
    planNavItems: [
        {
            text: 'Campaign',
            to: 'app.plan.campaign',
            icon: campaignSvg,
            className: 'campaign-nav-icon',
            testId: 'campaign-nav-icon',
            validationRules: [
                'isNotOnBookPage',
                'planIsNotBooked',
                'isDraft',
                'isCampaignTypeNotSelected'
            ],
            toggleVisibility: false,
            permission: MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN
        },
        {
            text: 'Location',
            to: 'app.plan.location',
            icon: locationSvg,
            className: 'location-nav-icon',
            testId: 'location-nav-icon',
            validationRules: [
                'isNotOnBookPage',
                'planIsNotBooked',
                'isDraft',
                'isCampaignTypeSelected'
            ],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        },
        {
            text: 'Audience',
            to: 'app.plan.audience',
            icon: audienceSvg,
            className: 'audience-nav-icon',
            testId: 'audience-nav-icon',
            validationRules: [
                'isNotOnBookPage',
                'planIsNotBooked',
                'isDraft',
                'isCampaignTypeSelected',
                'householdCountAboveThreshold'
            ],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        },
        {
            text: 'Schedule',
            to: 'app.plan.schedule',
            icon: scheduleSvg,
            className: 'schedule-nav-icon',
            testId: 'schedule-nav-icon',
            validationRules: [
                'customListValid',
                'audienceTypeSelected',
                'householdCountAboveThreshold',
                'isCampaignTypeSelected',
                'isDraft'
            ],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        },
        {
            text: 'Budget',
            to: 'app.plan.budget',
            icon: budgetSvg,
            className: 'budget-nav-icon',
            testId: 'budget-nav-icon',
            validationRules: [
                'audienceTypeSelected',
                'householdCountAboveThreshold',
                'customListValid',
                'startDateSelected',
                'endDateSelected',
                'aboveMinimumDateRange',
                'belowMaxDateRange',
                'isCampaignTypeSelected',
                'isDraft',
                'receivedDistributionConfig'
            ],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        },
        {
            text: 'Creative',
            to: 'app.plan.creative',
            icon: creativeSvg,
            className: 'creative-nav-icon',
            validationRules: [
                'audienceTypeSelected',
                'householdCountAboveThreshold',
                'customListValid',
                'isCampaignTypeSelected',
                'startDateSelected',
                'endDateSelected',
                'aboveMinimumDateRange',
                'belowMaxDateRange',
                'aboveMinimumBudget',
                'aboveMinimumSubCampaignsBudget',
                'isBudgetDistributed',
                'belowMaxSubCampaignsBudget',
                'isOnEditOrDraft'
            ],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        },
        {
            text: 'Media',
            to: 'app.plan.networks',
            icon: networksSvg,
            className: 'networks-nav-icon',
            testId: 'networks-nav-icon',
            validationRules: [
                'audienceTypeSelected',
                'householdCountAboveThreshold',
                'customListValid',
                'isCampaignTypeSelected',
                'startDateSelected',
                'endDateSelected',
                'aboveMinimumDateRange',
                'belowMaxDateRange',
                'aboveMinimumBudget',
                'aboveMinimumSubCampaignsBudget',
                'isBudgetDistributed',
                'belowMaxSubCampaignsBudget',
                'creativeExists',
                'isDraft'
            ],
            toggleVisibility: true,
            userFilters: [NATIONAL, COMMERCIAL]
        },
        {
            text: 'Media Plan',
            to: 'app.plan.mediaPlan',
            icon: mediaPlanSvg,
            className: 'media-plan-nav-icon',
            testId: 'media-plan-nav-icon',
            validationRules: [
                'audienceTypeSelected',
                'householdCountAboveThreshold',
                'customListValid',
                'startDateSelected',
                'endDateSelected',
                'aboveMinimumDateRange',
                'belowMaxDateRange',
                'aboveMinimumBudget',
                'isCampaignTypeSelected',
                'aboveMinimumSubCampaignsBudget',
                'isBudgetDistributed',
                'belowMaxSubCampaignsBudget',
                'creativeExists',
                'isNotEditCreatives'
            ],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        },
        {
            text: 'Book',
            to: 'app.plan.book',
            icon: bookSvg,
            className: 'book-nav-icon',
            testId: 'book-nav-icon',
            validationRules: [
                'audienceTypeSelected',
                'householdCountAboveThreshold',
                'customListValid',
                'startDateSelected',
                'endDateSelected',
                'aboveMinimumDateRange',
                'belowMaxDateRange',
                'aboveMinimumBudget',
                'isCampaignTypeSelected',
                'aboveMinimumSubCampaignsBudget',
                'isBudgetDistributed',
                'planGenerated',
                'belowMaxSubCampaignsBudget',
                'creativeExists',
                'isDraft',
                'isAfterLeadDate'
            ],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL],
            restrictions: CAN_NOT_BOOK ///this has to be temporary, INTERNAL user filter should be removed in the future
        }
    ],
    rankerNavItems: [
        {
            text: 'Location',
            to: 'app.ranker.location',
            icon: locationSvg,
            className: 'location-nav-icon',
            testId: 'ranker-location-nav-icon',
            validationRules: ['isRanker'],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        },
        {
            text: 'Audience',
            to: 'app.ranker.audience',
            icon: audienceSvg,
            className: 'audience-nav-icon',
            testId: 'ranker-audience-nav-icon',
            validationRules: ['isRanker', 'isLocationSelected'],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        },
        {
            text: 'Ranker',
            to: 'app.ranker.rankertool',
            icon: networksSvg,
            className: 'ranker-nav-icon',
            testId: 'ranker-nav-icon',
            validationRules: [
                'isRanker',
                'isLocationSelected',
                'audienceTypeSelected'
            ],
            toggleVisibility: false,
            userFilters: [EXTERNAL, INTERNAL]
        }
    ]
};
