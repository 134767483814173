import { MediaPlan } from '../components/pages';
import { Footer } from '../components/layouts';

export default [
    {
        name: 'app.plan.mediaPlan',
        url: '/media-plan',
        views: {
            'content@app': {
                component: MediaPlan
            },
            'footer@app': {
                component: Footer
            }
        }
    }
];
