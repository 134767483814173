import React from 'react';
import PropTypes from 'prop-types';
import RcSlider from 'rc-slider';

// import 'rc-slider/assets/index.css';

import './rcSlider.scss';
import SliderGlobalStyle from './style';
import colors from '../../shared/colors';
const { paleCyan, white } = colors;

const IMAGE =
    'url(data:image/svg+xml;base64,PHN2ZyBpZD0iTGF5ZXJfMSIgZGF0YS1uYW1lPSJMYXllciAxIiB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHZpZXdCb3g9IjAgMCA0MiA0MiI+PHRpdGxlPkljb25zPC90aXRsZT48cGF0aCBkPSJNNDIsMjFBMjEsMjEsMCwxLDAsMjEsNDIsMjEsMjEsMCwwLDAsNDIsMjFaIiBzdHlsZT0iZmlsbDojZmZmIi8+PHBhdGggZD0iTTI3LDMwLjIzYTEsMSwwLDAsMCwuOTUtMVYxMmEuOTUuOTUsMCwwLDAtMS45LDBWMjkuMjdBMSwxLDAsMCwwLDI3LDMwLjIzWiIgc3R5bGU9ImZpbGw6IzJlNWVmZiIvPjxwYXRoIGQ9Ik0xMywxMS4wNWEuOTQuOTQsMCwwLDAtLjk1Ljk1VjI5LjI3YTEsMSwwLDEsMCwxLjksMFYxMkEuOTQuOTQsMCwwLDAsMTMsMTEuMDVaIiBzdHlsZT0iZmlsbDojMmU1ZWZmIi8+PHBhdGggZD0iTTIwLDExLjA1YS45NC45NCwwLDAsMC0uOTUuOTVWMjkuMjdhMSwxLDAsMSwwLDEuOSwwVjEyQS45NC45NCwwLDAsMCwyMCwxMS4wNVoiIHN0eWxlPSJmaWxsOiMyZTVlZmYiLz48L3N2Zz4=)';

const SIZE_MAP = {
    small: 26,
    large: 34,
    tiny: 8
};

const HANDLE_SIZE_MAP = {
    small: {
        height: 30,
        width: 30,
        marginTop: -2
        // marginLeft: -16
    },
    large: {
        height: 42,
        width: 42,
        marginTop: -4
        // marginLeft: -18
    },
    tiny: {
        height: 24,
        width: 24,
        marginTop: -8
    }
};

const getTrackStyle = (color, size = 'small ') => {
    const trackColor = color in colors ? colors[color] : 'vividNavy';
    const trackSize = size in SIZE_MAP ? SIZE_MAP[size] : SIZE_MAP.small;

    return {
        backgroundColor: trackColor,
        height: trackSize,
        borderRadius: '2px 0 0 2px'
    };
};

const getHandleStyle = size => {
    const handleSize =
        size in HANDLE_SIZE_MAP ? HANDLE_SIZE_MAP[size] : HANDLE_SIZE_MAP.small;
    const { height, width, marginTop, marginLeft } = handleSize;
    return {
        height,
        width,
        marginTop,
        marginLeft,
        borderColor: white,
        borderWidth: 1,
        backgroundColor: white,
        boxShadow: '0 3px 8px 0 rgba(0,51,128,0.3)',
        backgroundImage: IMAGE
    };
};

const getRailStyle = size => {
    return {
        backgroundColor: paleCyan,
        height: SIZE_MAP[size],
        borderRadius: '2px 0 0 2px'
    };
};

const Slider = props => {
    const {
        size,
        color,
        value,
        onChange,
        onAfterChange,
        disabled,
        id,
        ...result
    } = props;

    const trackStyle = getTrackStyle(color, size);
    const handleStyle = getHandleStyle(size);
    const railStyle = getRailStyle(size);
    const sliderValue = value || 0;

    return (
        <div {...result} id={id}>
            <SliderGlobalStyle />
            <RcSlider
                onAfterChange={onAfterChange}
                onChange={onChange}
                value={sliderValue}
                trackStyle={trackStyle}
                handleStyle={handleStyle}
                railStyle={railStyle}
                disabled={disabled}
            />
        </div>
    );
};

Slider.propTypes = {
    /** Value for the slider */
    value: PropTypes.number,
    /** Colour */
    color: PropTypes.string,
    /** Size (small or large) */
    size: PropTypes.oneOf(['small', 'large', 'tiny']),
    /** Namespace for e2e tests targeting */
    testNameSpace: PropTypes.string,
    /** onChange callback */
    onChange: PropTypes.func,
    /** onAfterChange callback */
    onAfterChange: PropTypes.func,
    /** Disable the slider */
    disabled: PropTypes.bool,
    /** Id for tracking */
    id: PropTypes.string.isRequired
};

Slider.defaultProps = {
    size: 'small',
    color: 'vividNavy',
    value: 0,
    disabled: false
};

export default Slider;
