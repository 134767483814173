import requestHandlers from '@requestHandlers';
import { pipe, isArray, addArgs } from '@lib';
import { addFiles, addDisclaimers, updateFiles } from '@state/creative/actions';
import { convertArrayToObject } from '@lib';
import { validateDisclaimer } from '@services/creative';
import { VIDEO_TYPE, INTERNAL, COMMERCIAL } from '@config';
import featuresManager from '@featuresManager';
import { STATUS, FILE_STATUS } from '@config/config.creative';

export const fileUpload = dispatch => {
    const _addToState = (files, callback, cancel) => {
        files.length ? dispatch(addFiles([...files])) : files;
        callback(files);
    };

    const _addPropsToPayload = (
        { startDate, endDate, a4PlanId = 0 },
        files = [],
        callback,
        cancel
    ) => {
        const filesWithAddedProps = files.length
            ? files.map(file => {
                  return {
                      ...file,
                      a4PlanId,
                      startDate,
                      endDate,
                      status: FILE_STATUS.NEW
                  };
              })
            : callback(files);
        callback(filesWithAddedProps);
    };

    const handleResponse = (response = {}, file = {}) => {
        if (typeof response !== 'object' && typeof file !== 'object')
            throw Error('no repsonse or file passed to file upload');

        const { status, data = {} } = response;

        if (status === 200)
            return {
                ...file,
                ...data,
                ...{ isUploading: false },
                ...{ src: data.fileLocation }
            };

        return {
            ...file,
            ...data,
            ...{ errors: ['errorDuringUpload'] },
            ...{ isUploading: false }
        };
    };

    const _uploadFilesToBackend = async (files = [], callback, cancel) => {
        if (Array.isArray(files) && files.length) {
            const creativePromises = files.map(async file => {
                return requestHandlers.creative
                    .upload(file)
                    .then(response => handleResponse(response, file))
                    .catch(e => handleResponse(e, file));
            });

            Promise.all(creativePromises).then(promises => callback(promises));
        } else {
            cancel(console.error(err));
        }
    };

    const _updateState = (files, callback, cancel) => {
        files.length ? dispatch(updateFiles([...files])) : files;
        callback(files);
    };

    return (files, { startDate, endDate, a4PlanId }) => {
        return pipe([
            addArgs(_addToState, files),
            addArgs(_addPropsToPayload, {
                startDate,
                endDate,
                a4PlanId
            }),
            _uploadFilesToBackend,
            _updateState
        ]);
    };
};
