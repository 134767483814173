import { store } from '@state';
import { setRanker } from '@state/rootActions';

import { Location } from '@components/pages';
import { LeftSidebarLocation } from '@components/layouts';

export default [
    {
        name: 'app.plan.location',
        url: '/location',
        views: {
            'leftSidebar@app': {
                component: LeftSidebarLocation
            },
            'content@app': {
                component: Location
            }
        }
    },
    {
        name: 'app.ranker.location',
        url: '/location',
        views: {
            'leftSidebar@app': {
                component: LeftSidebarLocation
            },
            'content@app': {
                component: Location
            }
        }
    }
];
