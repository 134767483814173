import React from 'react';
import PropTypes from 'prop-types';
import { Text, Layout } from 'maslow';
import SelectedLocation from '../SelectedLocation';
import styles from '../summary.scss';

const SelectedLocationCategory = props => {
    const { locationArray } = props;

    const buildLocations = (locationData, i) => {
        const { type, id } = locationData;

        return (
            <SelectedLocation
                key={`${type}.${id}`}
                locationData={locationData}
            />
        );
    };

    const buildLocationCategories = (locationCategory, i) => {
        const locationComponents = locationCategory.entries.map(buildLocations);

        return (
            locationComponents.length > 0 && (
                <div
                    className={`${styles.locationRow}`}
                    key={locationCategory.title}
                    className={`${styles.span} ${styles.locationRow}`}>
                    <Text type="body">{locationCategory.title}:</Text>
                    <div className={`${styles.span} ${styles.locationRow}`}>
                        {locationComponents}
                    </div>
                </div>
            )
        );
    };

    const locationCategories = locationArray.map(buildLocationCategories);
    return <Layout flexDirection="column">{locationCategories}</Layout>;
};

SelectedLocationCategory.propTypes = {
    locationArray: PropTypes.array.isRequired
};

export default SelectedLocationCategory;
