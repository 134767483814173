import { validateArg } from '@lib';

import { LOCATION_PAGE, VIEWERSHIP } from '@config/';
import { getAudiencePercent } from '@services';

export const formatHouseholdStatus = page => {
    validateArg(page, 'string');
    return households => {
        validateArg(households, 'object');
        const {
            total,
            count,
            isFetching,
            isFetchingTotal,
            locationCount,
            locationIsFetching
        } = households;

        const isFetchingLocal =
            isFetching || isFetchingTotal || locationIsFetching;
        const isLocationPage = page === LOCATION_PAGE;
        const pageTotal = isLocationPage ? total : locationCount;
        const hhPercent = getAudiencePercent(count, pageTotal);
        const targetingEveryone = count === total;

        return {
            count,
            total,
            pageTotal,
            isFetching: isFetchingLocal,
            isFetchingTotal,
            hhPercent,
            targetingEveryone
        };
    };
};

export const filterViewershipData = (data, audience) => {
    validateArg(data, 'array', 1);
    validateArg(audience, 'array', 2);

    return data.map(({ type, entries }) => ({
        type,
        entries: entries
            .map(({ name, fullName }) => ({
                //Arbitrary values until hhCount is set up
                name: `${fullName} (${name})`,
                id: name,
                category: type,
                categoryValue: type,
                section: VIEWERSHIP,
                value: name
            }))
            .filter(
                ({ id, category }) =>
                    !audience.find(attribute => {
                        return (
                            attribute?.id === id &&
                            attribute?.category === category
                        );
                    })
            )
    }));
};

//Not writing tests for this as the logic is likely to change once real data is applied
export const filterNetworks = (entries, audience) => {
    validateArg(entries, 'array', 1);
    validateArg(audience, 'array', 2);

    return entries.map(({ name, fullName }) => ({
        //Arbitrary values until hhCount is set up
        name: `${fullName} (${name})`,
        id: name,
        category: 'Networks',
        categoryValue: 'Networks',
        section: VIEWERSHIP,
        value: name,
        isSelected: !!audience.find(attribute => {
            return attribute?.id === name && attribute?.category === 'Networks';
        })
    }));
};

export const getVennSize = windowWidth => {
    validateArg(windowWidth, 'number');
    if (windowWidth <= 1150) return { width: 600, height: 650 };
    if (windowWidth <= 1350) return { width: 625, height: 675 };
    return { width: 700, height: 750 };
};
