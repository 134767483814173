import { createReducer } from '@reduxjs/toolkit';
import { TOGGLE_IS_AUDIENCE_SAVED } from '../constants';

const initialState = false;

const toggleIsAudienceSaved = (state, action) => {
    const { isSaved } = action.payload;
    return typeof isSaved === 'boolean' ? isSaved : state;
};

export const isAudienceSaved = createReducer(initialState, reducer => {
    reducer
        .addCase(TOGGLE_IS_AUDIENCE_SAVED, toggleIsAudienceSaved)
        .addDefaultCase((state, _) => state);
});
