import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import DayPicker from 'react-day-picker';
import { calendarHelper, disabledDatesService } from '@services';
import { WeekSelection } from './WeekSelection';
import featuresManager from '@featuresManager';
import { datesHandler } from '@lib/';

import { scheduleSelector } from '@selectors';
import {
    SCHEDULE_CALENDAR_NEXT,
    SCHEDULE_CALENDAR_PREV
} from '@config/idConstants';

import './react-datepicker.scss';

export const Calendar = ({
    from = Date.now(),
    to,
    maxDate,
    numberOfMonths,
    onDateChange,
    focusedType,
    isBroadcast
}) => {
    const [role] = featuresManager.getCustomFilterState('role');
    const { hiatusWeeks } = useSelector(scheduleSelector);

    const handleDayClick = (day, { disabled }) => {
        if (disabled) return;
        const newRange = {
            from: calendarHelper.getFromDate(day, from, focusedType),
            to: calendarHelper.getToDate(day, to, focusedType)
        };

        if (onDateChange) onDateChange(newRange);
    };

    const setIdToCalendarNav = () => {
        const nextBtnEl = document.querySelector('.DayPicker-NavButton--next');
        nextBtnEl.setAttribute('id', SCHEDULE_CALENDAR_NEXT);
        const prevBtnEl = document.querySelector('.DayPicker-NavButton--prev');
        prevBtnEl.setAttribute('id', SCHEDULE_CALENDAR_PREV);
    };

    useEffect(() => {
        hideWeeks();
        setIdToCalendarNav();
    }, [isBroadcast]);

    const hiatusWeekModifer = hiatusWeeks => {
        if (!hiatusWeeks.length) return;
        return hiatusWeeks.map(({ week }) => {
            return { from: week[0], to: week[6] };
        });
    };

    const hideWeeks = () => {
        isBroadcast && calendarHelper.hideWeeks();
    };

    const firstDayOfWeek = isBroadcast ? 1 : 0;

    const modifiers = {
        from,
        to,
        'from-input-focused': focusedType === 'from' ? from : '',
        'to-input-focused': focusedType === 'to' ? to : '',
        'curved-left': [{ daysOfWeek: [1] }, calendarHelper.firstOfMonth],
        'curved-right': [{ daysOfWeek: [0] }, calendarHelper.endOfMonth],
        'hiatus-week': hiatusWeekModifer(hiatusWeeks)
    };

    const disabledDates = disabledDatesService({ from, to, role, hiatusWeeks });
    const dayPickerClassName = isBroadcast ? 'broadCastDayPicker' : '';

    return (
        <div className="day-picker-container">
            <DayPicker
                className={`Selectable ${dayPickerClassName}`}
                selectedDays={[{ from, to }]}
                modifiers={modifiers}
                showWeekNumbers
                showOutsideDays={isBroadcast}
                firstDayOfWeek={firstDayOfWeek}
                onDayClick={handleDayClick}
                disabledDays={disabledDates}
                renderDay={calendarHelper.renderDay}
                fromMonth={datesHandler.newDateStatic(Date.now())}
                toMonth={maxDate}
                onMonthChange={hideWeeks}
                numberOfMonths={numberOfMonths}
                renderWeek={(weekNumber, week) => (
                    <WeekSelection weekNumber={weekNumber} week={week} />
                )}
            />
        </div>
    );
};

Calendar.propTypes = {
    from: PropTypes.instanceOf(Date),
    to: PropTypes.instanceOf(Date),
    onDateChange: PropTypes.func.isRequired,
    focusedType: PropTypes.string,
    maxDate: PropTypes.instanceOf(Date).isRequired,
    isBroadcast: PropTypes.bool
};
