import { createReducer } from '@reduxjs/toolkit';
import { SAVE_DIGITAL_CPM_CONFIG } from '../constants';

const initialState = {};

const saveDigitalCpmConfig = (state, action) => {
    const { digitalCpmConfig } = action.payload;

    return typeof digitalCpmConfig === 'object' ? digitalCpmConfig : state;
};

export const digitalCpmConfig = createReducer(initialState, reducer => {
    reducer
        .addCase(SAVE_DIGITAL_CPM_CONFIG, saveDigitalCpmConfig)
        .addDefaultCase((state, _) => state);
});
