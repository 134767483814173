export default {
    name: 'LocationSummarySelections',
    description: 'Location Summary Selections Cards.',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan', 'ranker'],
    sections: ['leftSidebarLocation'],
    permissions: [],
    data: {},
    attributes: {},
    modifications: {}
};
