import { createSelector } from 'reselect';
import { budgetConfig } from '@config/config.budget';
import { hhCountLimit } from '@config/config.households';
import { steps } from '@config/config.customList';
import featuresManager from '@featuresManager';
import { MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN } from '@config';
import { PLAN_STATUSES } from '@config/config.planStatus';
import {
    budgetConfigSelector,
    creativeValidationSelector,
    getUserTypeSelector,
    effectiveDurationSelector,
    pastLeadDateSelector,
    planGeneratedSelector,
    hasDistributionConfigSelector,
    hasPermissionSelector,
    selectedLocationsSelector
} from '@selectors';
import { isMinError, isMaxError } from '@services';
import { isEmpty } from '@lib';
import { scheduleConfig } from '@config/config.schedule';
import { getSubName } from '@services/budget';

const isOnBookPageSelector = state => state.ui.isOnBookPage;
const planStatusSelector = state => state.mediaPlan.planStatus;
const bookedSelector = state => state.plan.attributes.booked;
const audiencesSelector = state => state.audience.audiences;
const audienceTypeSelectedSelector = state => state.audience.audienceType;
const householdsCountSelector = state => state.audience.households.count;
const householdsTotalSelector = state => state.audience.households.total;
const budgetTotalSelector = state => state.budget.total;
const regionSelector = state => state.plan.attributes.region;
const startDateSelector = state => state.schedule.startDate;
const endDateSelector = state => state.schedule.endDate;
const creativeSkipUploadSelector = state => state.creative.skipUpload;
const campaignTypeSelector = state => state.plan.attributes.campaignType;
const budgetChannelsSelector = state => state.budget.channels;
const householdsFetchingSelector = state =>
    state.audience.households.isFetching;
const customListStepSelector = state => state.customList.uploadStep;
const isEditCreativesSelector = state => state.ui.isEditCreatives;
const isRankerSelector = state => state.ui.isRanker;

const audienceSelectedSelector = createSelector(
    audiencesSelector,
    audiences => audiences[0].length > 0
);
const budgetSubCampaignsSelector = state => state.budget.subCampaigns;

// if we require any of the selectors in other components,
// we can move these out to their own sharable directory
export const ruleMapToStateProps = {
    isNotOnBookPage: createSelector(
        isOnBookPageSelector,
        isOnBookPage => !isOnBookPage
    ),
    planIsNotBooked: createSelector(
        planStatusSelector,
        planStatus => planStatus !== PLAN_STATUSES.BOOKED
    ),
    isDraft: createSelector(bookedSelector, isDraft => isDraft === false),
    audienceTypeSelected: createSelector(
        audienceTypeSelectedSelector,
        audienceType => audienceType !== ''
    ),
    aboveMinimumBudget: createSelector(
        budgetTotalSelector,
        regionSelector,
        getUserTypeSelector,
        (budgetTotal, region, userType) => {
            const [role] = featuresManager.getCustomFilterState('role');
            const minimumBudget =
                budgetConfig.limits.min[userType][role][region];
            return budgetTotal >= minimumBudget;
        }
    ),
    householdCountAboveThreshold: createSelector(
        audienceSelectedSelector,
        regionSelector,
        householdsCountSelector,
        householdsTotalSelector,
        householdsFetchingSelector,
        (
            audienceSelected,
            region,
            householdCount,
            householdTotal,
            isFetching
        ) => {
            const { min } = hhCountLimit;
            const count =
                audienceSelected || region === 'local'
                    ? householdCount
                    : householdTotal;

            return count > min && !isFetching;
        }
    ),
    startDateSelected: createSelector(
        startDateSelector,
        startDate => startDate !== null
    ),
    endDateSelected: createSelector(
        endDateSelector,
        endDate => endDate !== null
    ),
    aboveMinimumDateRange: createSelector(
        effectiveDurationSelector,
        duration => {
            const [role] = featuresManager.getCustomFilterState('role');
            const { rules } = scheduleConfig;
            return !isMinError(duration, rules[role].minPlanDuration, role);
        }
    ),
    belowMaxDateRange: createSelector(effectiveDurationSelector, duration => {
        const { rules } = scheduleConfig;
        return !isMaxError(duration, rules.maxPlanDuration);
    }),
    aboveMinimumSubCampaignsBudget: createSelector(
        budgetConfigSelector,
        regionSelector,
        getUserTypeSelector,
        (budget, region, userType) => {
            const {
                distribution: { subCampaigns }
            } = budgetConfig;

            const subCampaignsKeys = Object.keys(budget.subCampaigns);

            let minData = {};

            subCampaignsKeys.forEach(key => {
                minData[key] = subCampaigns[key].min[userType][region];
            });

            for (const key of subCampaignsKeys) {
                if (
                    budget.subCampaigns[key].budget > 0 &&
                    budget.subCampaigns[key].budget < minData[key]
                ) {
                    return false;
                }
            }

            return true;
        }
    ),
    belowMaxSubCampaignsBudget: createSelector(
        budgetConfigSelector,
        budgetSubCampaignsSelector,
        ({ subCampaigns }, subs) =>
            !Object.keys(subs).some(key => {
                const subName = getSubName(key);
                const subCampaign = subCampaigns[subName];
                return subCampaign.budget > subCampaign.maxBudget;
            })
    ),
    creativeExists: createSelector(
        creativeSkipUploadSelector,
        creativeValidationSelector,
        (skipUpload, isValid) => isValid || skipUpload
    ),
    planGenerated: createSelector(planGeneratedSelector, planGenerated => {
        return planGenerated ? true : false;
    }),
    isCampaignTypeSelected: createSelector(
        campaignTypeSelector,
        hasPermissionSelector(MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN),
        (campaignType, isShowCampaignPage) => {
            return campaignType !== 'unset' || !isShowCampaignPage;
        }
    ),
    isCampaignTypeNotSelected: createSelector(
        campaignTypeSelector,
        campaignType => campaignType === 'unset'
    ),
    isBudgetDistributed: createSelector(budgetChannelsSelector, channels => {
        return Object.keys(channels).some(key => channels[key] > 0);
    }),
    customListValid: createSelector(customListStepSelector, step => {
        return (
            step === steps.initial.name ||
            step === steps.prompt_to_save.name ||
            step === steps.nextSteps.name ||
            step === steps.belowMatchingThreshold.name ||
            step === steps.belowThreshold.name
        );
    }),
    isAfterLeadDate: createSelector(
        pastLeadDateSelector,
        isPastLeadDate => !isPastLeadDate
    ),
    isOnEditOrDraft: createSelector(
        isEditCreativesSelector,
        bookedSelector,
        (isEditCreatives, isBooked) => isEditCreatives || !isBooked
    ),
    isNotEditCreatives: createSelector(
        isEditCreativesSelector,
        isEditCreatives => !isEditCreatives
    ),
    receivedDistributionConfig: createSelector(
        hasDistributionConfigSelector,
        hasDistributionConfig => hasDistributionConfig
    ),
    isRanker: isRankerSelector,
    isLocationSelected: createSelector(
        selectedLocationsSelector,
        locations => !isEmpty(locations)
    )
};
