import { isArray } from '@lib';

export default (arr, prop, isDesc, isStrings) => {
    if (!isArray(arr)) return;

    const sorted = arr.slice();

    if (isStrings) {
        isDesc
            ? sorted.sort((a, b) =>
                  a[prop].toUpperCase() < b[prop].toUpperCase()
                      ? 1
                      : b[prop].toUpperCase() < a[prop].toUpperCase()
                      ? -1
                      : 0
              )
            : sorted.sort((a, b) =>
                  a[prop].toUpperCase() > b[prop].toUpperCase()
                      ? 1
                      : b[prop].toUpperCase() > a[prop].toUpperCase()
                      ? -1
                      : 0
              );

        return sorted;
    }

    isDesc
        ? sorted.sort((a, b) =>
              a[prop] < b[prop] ? 1 : b[prop] < a[prop] ? -1 : 0
          )
        : sorted.sort((a, b) =>
              a[prop] > b[prop] ? 1 : b[prop] > a[prop] ? -1 : 0
          );

    return sorted;
};
