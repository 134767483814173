import { Creative } from '../components/pages';

export default [
    {
        name: 'app.plan.creative',
        url: '/creative',
        views: {
            'content@app': {
                component: Creative
            }
        }
    }
];
