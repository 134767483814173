import { isValidEmail, isValidPassword } from '@services';
import jsonwebtoken from 'jsonwebtoken';
import requestHandlers from '@requestHandlers';
import { store } from '@state';
import { reset } from '@state/rootActions';

export const loginService = feature => {
    const userDefault = {
        email: undefined,
        password: undefined
    };

    let _user = { ...userDefault };

    const setUserEmail = email => {
        _user.email = email;
    };

    const setUserPassword = password => {
        _user.password = password;
    };

    const hasValidEmail = email => {
        if (email !== undefined) setUserEmail(email);
        const _email = email || _user.email;
        return isValidEmail(_email);
    };

    const hasValidPassword = password => {
        if (password !== undefined) setUserPassword(password);
        const _password = password || _user.password;
        return isValidPassword(_password);
    };

    const processAccountData = (
        {
            firstName = '',
            lastName = '',
            email = '',
            userId = '',
            verified = false,
            permissions = []
        } = {
            firstName: '',
            lastName: '',
            email: '',
            userId: '',
            verified: false,
            permissions: []
        }
    ) => ({
        firstName,
        lastName,
        email,
        id: userId,
        verified,
        permissions
    });

    const processAgencyData = ({ name = '' } = { name: '' }) => ({ name });
    const getUserDetails = () => _user;

    const resetUserDetails = () => {
        _user = Object.assign({}, userDefault);
    };

    const getDisplayText = (titles, displayTextState) => {
        return {
            title: titles[displayTextState].title,
            subTitle: titles[displayTextState].subTitle,
            button: titles[displayTextState].button
        };
    };

    const setAndDecodeJwt = jwt => {
        localStorage.setItem('jwt', jwt);
        return jsonwebtoken.decode(jwt);
    };

    const getAndDecodeJwt = () => {
        const jwt = localStorage.getItem('jwt');
        return jsonwebtoken.decode(jwt);
    };

    const logout = async router => {
        await requestHandlers.auth
            .logout()
            .catch(() => console.error('log out failed'));
        await router.stateService.go('login');
        localStorage.removeItem('jwt');
        store.dispatch(reset());
    };

    return {
        setUserEmail,
        setUserPassword,
        getUserDetails,
        hasValidEmail,
        hasValidPassword,
        resetUserDetails,
        isValidEmail,
        processAccountData,
        processAgencyData,
        getDisplayText,
        setAndDecodeJwt,
        getAndDecodeJwt,
        logout
    };
};
