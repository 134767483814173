import { createSelector } from 'reselect';

export const planSelector = createSelector(
    state => state.plan,
    plan => plan
);

const accountSelector = createSelector(
    state => state.user.account,
    account => account
);

export const campaignTypeSelector = createSelector(
    [planSelector, accountSelector],
    ({ attributes: { campaignType } }, account) =>
        campaignType === 'unset' ? account.role[0] : campaignType
);

export const regionSelector = createSelector(
    planSelector,
    ({ attributes: { region } }) => region
);

export const createBookButtonDisabledSelector = createSelector(
    planSelector,
    ({ attributes: { name, advertiser, hasAcceptedTerms } }) =>
        !name || !advertiser || !hasAcceptedTerms
);

export const bookedSelector = createSelector(
    planSelector,
    ({ attributes: { booked } }) => booked
);
