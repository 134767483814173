import featuresManager from '@featuresManager';
import request from '../request';

export default function (string) {
    const [role] = featuresManager.getCustomFilterState('role');

    const req = {
        method: 'GET',
        url: `locations/strings?string=${string}&role=${role}`
    };

    return request(req);
}
