import { createAction } from '@reduxjs/toolkit';
import {
    ADD_LOCATION,
    ADD_LOCATIONS,
    REMOVE_LOCATION,
    REMOVE_LOCATIONS
} from '../constants';

export const addLocation = createAction(ADD_LOCATION, location => {
    return {
        payload: { location }
    };
});

export const addLocations = createAction(ADD_LOCATIONS, locations => {
    return {
        payload: { locations }
    };
});

export const removeLocation = createAction(REMOVE_LOCATION, locationIndex => {
    return {
        payload: { locationIndex }
    };
});

export const removeLocations = createAction(REMOVE_LOCATIONS);
