import { getPercentFromValue, strf } from '@lib';
import { MARGIN_10, CELL_WIDTH_01, COLOR_BLUE } from '../constants';

export const buildDigitalDetailsData = (
    doc,
    counter,
    mappedDigitalDetailsForUi,
    totalDigitalImpression
) => {
    mappedDigitalDetailsForUi.forEach(item => {
        const { name, impression, subCampaigns } = item;
        const table = {
            startY: counter.add(MARGIN_10),
            headStyles: { fillColor: COLOR_BLUE, cellWidth: CELL_WIDTH_01 },
            head: [],
            body: [],
            didDrawPage: data => {
                counter.set(counter.count + data.table.height);
            }
        };

        const impressionFormatted = strf(impression).commas().value();
        const percentFormatted = strf(
            getPercentFromValue(impression, totalDigitalImpression)
        )
            .append('%')
            .value();

        if (impression > 0)
            table.head.push([name, impressionFormatted, percentFormatted]);

        subCampaigns.forEach(subCampaign => {
            if (subCampaign.impression > 0) {
                const subCampaignPercentFormatted = strf(
                    getPercentFromValue(subCampaign.impression, impression)
                )
                    .append('%')
                    .value();

                table.body.push([
                    subCampaign.name,
                    strf(subCampaign.impression)
                        .commas()
                        .value(),
                    subCampaignPercentFormatted
                ]);
            }
        });

        doc.autoTable(table);
    });
};
