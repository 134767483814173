import { datesHandler } from '@lib';

export const getDuration = (currentDate, dateToCompare) => {

    if (datesHandler.differenceInYears(currentDate, dateToCompare) === 1)
        return ' last year';

    if (
        datesHandler.differenceInYears(currentDate, dateToCompare) > 1 &&
        datesHandler.differenceInYears(currentDate, dateToCompare) < 3
    )
        return (
            datesHandler.differenceInYears(currentDate, dateToCompare) +
            ' years ago'
        );

    if (datesHandler.differenceInYears(currentDate, dateToCompare) > 2)
        return ' few years ago';

    if (datesHandler.differenceInMonths(currentDate, dateToCompare) === 1)
        return ' last month';

    if (
        datesHandler.differenceInMonths(currentDate, dateToCompare) > 1 &&
        datesHandler.differenceInMonths(currentDate, dateToCompare) < 3
    )
        return (
            datesHandler.differenceInMonths(currentDate, dateToCompare) +
            ' months ago'
        );

    if (datesHandler.differenceInMonths(currentDate, dateToCompare) > 2)
        return ' few months ago';

    if (
        datesHandler.differenceInWeeks(currentDate, dateToCompare) > 1 &&
        datesHandler.differenceInWeeks(currentDate, dateToCompare) < 3
    )
        return (
            datesHandler.differenceInWeeks(currentDate, dateToCompare) +
            ' weeks ago'
        );
    
    if (datesHandler.differenceInWeeks(currentDate, dateToCompare) === 1)
        return ' last week';

    if (datesHandler.differenceInWeeks(currentDate, dateToCompare) > 2)
        return ' few weeks ago';

    if (datesHandler.differenceInDays(currentDate, dateToCompare) === 1)
        return ' yesterday';

    if (
        datesHandler.differenceInDays(currentDate, dateToCompare) > 1 &&
        datesHandler.differenceInDays(currentDate, dateToCompare) < 3
    )
        return (
            datesHandler.differenceInDays(currentDate, dateToCompare) +
            ' days ago'
        );

    if (datesHandler.differenceInDays(currentDate, dateToCompare) > 2)
        return ' few days ago';
    
    if (datesHandler.differenceInDays(currentDate, dateToCompare) < 1)
        return ' few hours ago';

    return '';
};
