export const formatLargeIntegers = integer => {
    const newNum = Number(integer);

    if (isNaN(newNum)) return integer;

    if (newNum >= 1000000000000) {
        return Math.round(newNum / 1000000000000) + 'T';
    }

    if (newNum >= 1000000000) {
        return Math.round(newNum / 1000000000) + 'B';
    }

    if (newNum >= 1000000) {
        return Math.round(newNum / 1000000) + 'M';
    }

    if (newNum >= 1000) {
        return Math.round(newNum / 1000) + 'K';
    }

    return Math.round(newNum).toString();
};
