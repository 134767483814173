import React from 'react';
import { useSelector } from 'react-redux';

import { Layout } from 'maslow';

import { hasMultipleAudiences } from '@services/audiencesService';

import styles from './audiences.scss';

const VennSection = () => {
    const { audiences, vennPngDataUrl } = useSelector(state => state.audience);

    return (
        <React.Fragment>
            {hasMultipleAudiences(audiences) && (
                <Layout flexDirection="row" vAlign="center" hAlign="center">
                    <img className={styles.venn} src={vennPngDataUrl} />
                </Layout>
            )}
        </React.Fragment>
    );
};

export default VennSection;
