// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".topSpace___3BnGK {\n  margin-top: 3px; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/features/SignUp/1.0.0/Passwords/styles.scss"],"names":[],"mappings":"AAGA;EACI,eAAe,EAAA","file":"styles.scss","sourcesContent":["@import '~@scss/settings/colors';\n@import '~@scss/settings/baseline';\n\n.topSpace {\n    margin-top: 3px;\n}"]}]);
// Exports
exports.locals = {
	"topSpace": "topSpace___3BnGK"
};
module.exports = exports;
