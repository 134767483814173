import { createAction } from '@reduxjs/toolkit';

import { SET_IS_MEDIA_PLAN_VISITED } from '../constants';

export const setIsMediaPlanVisited = createAction(
    SET_IS_MEDIA_PLAN_VISITED,
    isMediaPlanVisited => {
        return { payload: { isMediaPlanVisited } };
    }
);
