import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { strf, round, getPercentFromValue } from '@lib';
import { Layout, Text, Content, Bar } from 'maslow';
import styles from '../styles.scss';
import {
    budgetConfigSelector,
    digitalProductDetailsSelector,
    mediaPlanSelector
} from '@selectors/';
import { getFormattedCpm } from '@services/budget';
import { LINEAR_TV } from '@config/';

const _buildSection = (name, budget, percent, bar, style, cpm) => {
    const { primaryColor } = style;
    const barStyle = { borderColor: primaryColor };
    const percentFormatted = strf(percent).append('%').value();
    const cpmFormatted = getFormattedCpm(cpm);

    return (
        <Content fillParent key={name}>
            <Layout flexDirection="row" vAlign="center">
                <Layout hAlign="flex-end" className={styles.iconWrapper}>
                    <div style={barStyle} className={styles.subChannelBar} />
                </Layout>

                <Layout className={styles.channelText}>
                    <Text type="body">{name}</Text>
                </Layout>

                <Layout flexDirection="column" vAlign="center" fillParent>
                    <Bar
                        className={styles.channelBar}
                        percentage={percent}
                        theme={bar}
                    />

                    <Layout flexDirection="row" alignSelf="flex-end">
                        <Text type="body">{cpmFormatted} CPM |</Text>
                        <Text type="body" className={styles.leftPad}>
                            {budget} |
                        </Text>
                        <Text type="body" className={styles.leftPad}>
                            {percentFormatted}
                        </Text>
                    </Layout>
                </Layout>
            </Layout>
        </Content>
    );
};

const SubCampaign = ({ data }) => {
    const { channels, subCampaigns } = useSelector(budgetConfigSelector);
    const digitalProductDetails = useSelector(digitalProductDetailsSelector);
    const {
        tvDetails: { primaryTarget }
    } = useSelector(mediaPlanSelector);
    const tvCpm = primaryTarget ? primaryTarget.cpm || 0 : 0;

    const { style, name } = data;
    const { theme } = style;

    return data.subCampaigns.map((subCampaign, i) => {
        const totalBudget = name in channels ? channels[name].budget : 0;
        const spendAmount =
            subCampaign in subCampaigns ? subCampaigns[subCampaign].budget : 0;
        const budgetFormattedAmount = strf(round(spendAmount))
            .commas()
            .prepend('$')
            .value();
        const percent = getPercentFromValue(spendAmount, totalBudget) || 0;

        if (spendAmount > 0) {
            return _buildSection(
                subCampaign,
                budgetFormattedAmount,
                percent,
                theme,
                style,
                subCampaign === LINEAR_TV
                    ? tvCpm
                    : digitalProductDetails[subCampaign].cpm
            );
        }
    });
};

SubCampaign.propTypes = {
    data: PropTypes.object
};

export default SubCampaign;
