import { createAction } from '@reduxjs/toolkit';
import { UPDATE_ACCOUNT, RESET_ACCOUNT } from '../constants';

export const updateAccount = createAction(UPDATE_ACCOUNT, account => {
    return {
        payload: { account }
    };
});

export const resetAccount = createAction(RESET_ACCOUNT);
