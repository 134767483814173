import { connect } from 'react-redux';
import { datesHandler } from '@lib';

const getFormattedHiatusWeeks = hiatusWeeks =>
    hiatusWeeks.map(item => {
        return {
            week: item.week.map(week =>
                datesHandler.format(week, "yyyy-MM-dd'T'HH:mm:ss.SSS")
            ),
            weekNumber: item.weekNumber
        };
    });

export const bookingPayloadContainer = Component => {
    const mapStateToProps = state => {
        return {
            endDate: datesHandler.format(state.schedule.endDate),
            startDate: datesHandler.format(state.schedule.startDate),
            hiatusWeeks: getFormattedHiatusWeeks(state.schedule.hiatusWeeks),
            daypartExclusions: state.schedule.excludedDayparts,
            user: state.user,
            audiences: state.audience.audiences,
            combinations: state.audience.combinations
        };
    };

    return connect(mapStateToProps)(Component);
};
