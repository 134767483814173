import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import {
    CardNumberElement,
    CardExpiryElement,
    CardCVCElement
} from 'react-stripe-elements';
import { Input, Layout } from 'maslow';
import { removePaymentError } from '@state/plan/actions';
import { planSelector } from '@selectors/';
import styles from './styles.scss';
import { ERROR, IDLE } from '@config';
import {
    BOOK_CARDHOLDER_NAME,
    BOOK_CARD_NUM_ELEMENT,
    BOOK_CARD_EXPIRY_ELEMENT,
    BOOK_CARD_CVC_ELEMENT
} from '@config/idConstants';

const createOptions = () => {
    return {
        style: {
            base: {
                fontFamily: 'Avenir, Helvetica, Arial, sans-serif',
                fontWeight: '300',
                '::placeholder': {
                    color: '#b2b2b2'
                }
            },
            invalid: {
                color: '#000000'
            }
        }
    };
};

const PayForm = props => {
    let elemMemo;
    const { setPayError, cardHolderName, setCardHolderName } = props;

    const dispatch = useDispatch();
    const {
        attributes: { paymentError }
    } = useSelector(planSelector);

    const handleChange = ({ elementType }) => {
        if (elemMemo === elementType) return;
        setPayError(false);
        if (paymentError) dispatch(removePaymentError());
        elemMemo = elementType;
        return;
    };

    const onCardHolderNameChange = value => {
        setCardHolderName(value);
    };

    return (
        <form>
            <Layout flexDirection="column">
                <CardNumberElement
                    id={BOOK_CARD_NUM_ELEMENT}
                    className="fs-block"
                    placeholder="Card Number"
                    onChange={handleChange}
                    {...createOptions()}
                />

                <Input
                    id={BOOK_CARDHOLDER_NAME}
                    placeholder="Cardholder Name"
                    name="name"
                    status={
                        paymentError.length && !cardHolderName.length
                            ? ERROR
                            : IDLE
                    }
                    className="fs-block"
                    value={cardHolderName}
                    onChange={onCardHolderNameChange}
                />

                <Layout flexDirection="row" hAlign="space-between">
                    <Layout
                        flexDirection="column"
                        className={styles.inputWidth}>
                        <CardExpiryElement
                            id={BOOK_CARD_EXPIRY_ELEMENT}
                            className="fs-block"
                            placeholder="Expiration Date (MM/YY)"
                            onChange={handleChange}
                            {...createOptions()}
                        />
                    </Layout>

                    <Layout
                        flexDirection="column"
                        className={styles.inputWidth}>
                        <CardCVCElement
                            id={BOOK_CARD_CVC_ELEMENT}
                            className="fs-block"
                            placeholder="CVC Code"
                            onChange={handleChange}
                            {...createOptions()}
                        />
                    </Layout>
                </Layout>
            </Layout>
        </form>
    );
};

PayForm.propTypes = {
    setPayError: PropTypes.func.isRequired,
    cardHolderName: PropTypes.string.isRequired,
    setCardHolderName: PropTypes.func.isRequired
};

export default React.memo(PayForm);
