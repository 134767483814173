import React, {
    useEffect,
    useReducer,
    useRef,
    useCallback,
    useState
} from 'react';
import { useSelector } from 'react-redux';

import { createSelector } from 'reselect';
import { Card, Text, Layout, Icon, Button } from 'maslow';
import { reMount } from '@components/hoc';

import {
    filterNetworks,
    idBuilder,
    getRightShift,
    getLeftShift
} from '@services';
import requestHandlers from '@requestHandlers/';
import styles from './index.scss';
import { audienceSelector } from '@selectors';

import activeArrowSvg from '@public/img/UI-Kit-Arrow-gray.svg';
import disabledArrowSvg from '@public/img/UI-Kit-Arrow-lightgray.svg';
import networkIcons from '@config/config.networkIcons';
import plusIcon from '@public/img/plus-selector.svg';
import iconPlaceholder from '@public/img/network-placeholder.svg';
import minusIcon from '@public/img/minus-selector.svg';
import {
    NETWORK_SCROLL_OPTION,
    NETWORK_SCROLL_LEFT,
    NETWORK_SCROLL_RIGHT
} from '@config/idConstants';

const initialState = {
    networks: {
        all: [],
        filtered: []
    },
    listenerAdded: false
};

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'networks':
            return {
                ...state,
                networks: payload
            };
        case 'filteredNetworks':
            return {
                ...state,
                networks: {
                    ...state.networks,
                    filtered: payload
                }
            };
        case 'listenerAdded':
            return {
                ...state,
                listenerAdded: payload
            };
        default:
            throw new Error(`Action type ${type} is not not recognized.`);
    }
};

const currentAudienceSelector = createSelector(
    audienceSelector,
    ({ audiences, currentAudienceIndex }) => audiences[currentAudienceIndex]
);

const NetworkScroll = ({ onClickFunction }) => {
    const currentAudience = useSelector(currentAudienceSelector);

    const [
        {
            networks: { filtered, all },
            listenerAdded
        },
        stateDispatch
    ] = useReducer(reducer, initialState);

    const [networkScrollRef, setNetworkScrollRef] = useState(null);
    const [disabledLeft, setDisabledLeft] = useState(true);
    const [disabledRight, setDisabledRight] = useState(false);
    const firstItem = useRef(null);
    const lastItem = useRef(null);

    const networkScroll = useCallback(node => {
        if (node !== null) {
            if (!listenerAdded) {
                node.addEventListener('scroll', scrollHandler);
                stateDispatch({ type: 'listenerAdded', payload: true });
            }
            setNetworkScrollRef(node);
        }
        return () => {
            node.removeEventListener('scroll', scrollHandler);
            stateDispatch({ type: 'listenerAdded', payload: false });
        };
    });

    const scrollHandler = e => {
        if (firstItem.current) {
            const firstItemLeft = firstItem.current.getBoundingClientRect()
                .left;
            const scrollLeft = e.target.getBoundingClientRect().left;
            if (firstItemLeft >= scrollLeft + 10) {
                setDisabledLeft(true);
            } else setDisabledLeft(false);
        }
        if (lastItem.current) {
            const lastItemRight = lastItem.current.getBoundingClientRect()
                .right;
            const scrollRight = e.target.getBoundingClientRect().right;
            if (lastItemRight <= scrollRight - 10) {
                setDisabledRight(true);
            } else setDisabledRight(false);
        }
    };

    const buildNetwork = netLength => (item, i) => {
        const { id, isSelected } = item;

        const renderIcon = isSelected => {
            const Component = reMount(Icon);
            const iconSrc = isSelected ? minusIcon : plusIcon;

            return (
                <Component
                    src={iconSrc}
                    className={styles.scrollOptionAddRemove}
                />
            );
        };

        const ref = i === 0 ? firstItem : i === netLength - 1 ? lastItem : null;

        return (
            <Layout
                key={i}
                className={styles.scrollOption}
                ref={ref}
                onClick={() => onClickFunction(item)}
                id={idBuilder(NETWORK_SCROLL_OPTION, id)}>
                {renderIcon(isSelected)}
                <Icon
                    src={networkIcons[id]}
                    className={styles.scrollOptionNetworkIcon}
                    placeholder={iconPlaceholder}></Icon>
            </Layout>
        );
    };

    const buildNetworkScroller = networks => {
        if (!networks.length) return null;

        return (
            <Layout className={styles.networkContainer}>
                <Layout className={styles.networkScroll} ref={networkScroll}>
                    {networks.map(buildNetwork(networks.length))}
                </Layout>
            </Layout>
        );
    };

    useEffect(() => {
        requestHandlers.networks.getNetworks().then(({ data }) => {
            //Temporary logic and temporary API call until proper data is returned from endpoint, this is currently being formatted to match data from viewership search endpoint
            const formattedData = {
                type: 'Networks',
                entries: data.map(({ Code, Name }) => ({
                    name: Code,
                    fullName: Name
                }))
            };

            stateDispatch({
                type: 'networks',
                payload: {
                    all: formattedData.entries,
                    filtered: filterNetworks(
                        formattedData.entries,
                        currentAudience
                    )
                }
            });
        });
    }, []);

    useEffect(() => {
        stateDispatch({
            type: 'filteredNetworks',
            payload: filterNetworks(all, currentAudience)
        });
    }, [currentAudience]);

    const scrollRight = () => {
        networkScrollRef.scrollBy({
            top: 0,
            left: getRightShift(networkScrollRef),
            behavior: 'smooth'
        });
    };

    const scrollLeft = () => {
        networkScrollRef.scrollBy({
            top: 0,
            left: getLeftShift(networkScrollRef),
            behavior: 'smooth'
        });
    };

    const getArrowIcon = (disabled, disabledSvg, activeSvg, style) => {
        const IconComponent = reMount(Icon);
        const svg = disabled ? disabledSvg : activeSvg;

        return (
            <div className={styles.selectedAudienceArrowIconWrapper}>
                <IconComponent src={svg} className={style} size={2} />
            </div>
        );
    };

    return (
        <Card borderRadius="5px" backgroundColor="white" flexDirection="column">
            <Layout fillParent={true} hAlign="space-between" padding="10px">
                <Text type="h5" className={styles.availableNetworks}>
                    Available Networks
                </Text>
                <Layout>
                    <Button
                        id={NETWORK_SCROLL_LEFT}
                        onClick={scrollLeft}
                        theme="icon"
                        className={styles.networkScrollArrows}
                        doubleClick={true}>
                        {getArrowIcon(
                            disabledLeft,
                            disabledArrowSvg,
                            activeArrowSvg
                        )}
                    </Button>
                    <Button
                        id={NETWORK_SCROLL_RIGHT}
                        onClick={scrollRight}
                        theme="icon"
                        className={styles.networkScrollArrows}
                        doubleClick={true}>
                        {getArrowIcon(
                            disabledRight,
                            disabledArrowSvg,
                            activeArrowSvg,
                            styles.rightArrow
                        )}
                    </Button>
                </Layout>
            </Layout>
            {buildNetworkScroller(filtered)}
        </Card>
    );
};

export default NetworkScroll;
