export const getReportsStyles = (width, height, settings, tablet) => {
    const {
        widePcBreakpoint,
        widePcOffset,
        skinnyPcOffset,
        tabletOffset,
        importMinWidth
    } = settings;

    if (tablet) {
        return {
            marginTop: -tabletOffset,
            height: `calc(100% + ${tabletOffset}px)`,
            width: '100%'
        };
    } else {
        const offset = width < widePcBreakpoint ? skinnyPcOffset : widePcOffset,
            scale = width > importMinWidth ? 1 : width / importMinWidth,
            scaleCorrective = scale === 1 ? 1 : 1 / scale,
            marginSides = ((scaleCorrective - 1) * 100) / 2,
            marginTopBottom = (scaleCorrective * height - height) / 2;

        return {
            marginLeft: -marginSides + '%',
            marginRight: -marginSides + '%',
            marginTop: -marginTopBottom - offset * scale,
            marginBottom: 0,
            transform: `scale(${scale}, ${scale})`,
            height: `calc(${scaleCorrective * 100}% + ${marginTopBottom +
                offset * scale}px)`,
            width: scaleCorrective * 100 + '%'
        };
    }
};

export const getMaxGraphVal = array => {
    const numberArray = array.map(item => Math.ceil(Number(item)));
    const max = Math.max(...numberArray);
    const maxDigits = max.toString().length;
    const result = max / Math.pow(10, parseInt(maxDigits) - 1);
    return Math.ceil(result) / Math.pow(10, 1 - maxDigits);
};
