import { createReducer } from '@reduxjs/toolkit';
import { SET_HAS_TV_DETAILS } from '../constants';

const initialState = null;

const setHasTvDetails = (state, action) => {
    const { value } = action.payload;
    return typeof value === 'boolean' ? value : state;
};

export const hasTvDetails = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_HAS_TV_DETAILS, setHasTvDetails)
        .addDefaultCase((state, _) => state);
});
