import { validateArg } from '@lib';
import request from '../request';

export default function (string) {
    validateArg(string, 'string');
    const req = {
        method: 'GET',
        url: `audiences/viewership?string=${string}`
    };

    return request(req);
}
