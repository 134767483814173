import { LINEAR_TV } from '@config';
import { getSubName, getContextFromKey } from '@services/budget';
import { validateArg } from '@lib';

export const getProductDetails = (
    subCampaigns,
    digitalProductDetails,
    tvImpressions,
    tvCpm
) => {
    validateArg(subCampaigns, 'object', 1);
    validateArg(digitalProductDetails, 'object', 2);
    validateArg(tvImpressions, 'number', 3);
    validateArg(tvCpm, 'number', 4);

    return Object.entries(subCampaigns)
        .filter(([scName, value]) => {
            return value > 0;
        })
        .map(([scKey, value]) => {
            const scName = getSubName(scKey);
            return {
                Amount: value,
                Name: getContextFromKey(scKey),
                Impressions:
                    scName === LINEAR_TV
                        ? tvImpressions
                        : digitalProductDetails[scName].impression,
                Cpm:
                    scName === LINEAR_TV
                        ? tvCpm
                        : digitalProductDetails[scName].cpm
            };
        });
};
