import { validateArg } from '@lib';
import { getContextFromName, getContextFromKey } from '../budget';

import { budgetConfig } from '@config/config.budget';
const { distribution } = budgetConfig;

export const getReduxBudget = (
    budget,
    normalizedDigitalCpmConfig,
    normalizedImpressionModifierConfig
) => {
    validateArg(budget, 'array', 1);
    validateArg(normalizedDigitalCpmConfig, 'object', 2);

    return {
        total: budget.reduce((acc, prev) => {
            return acc + prev.Amount;
        }, 0),
        channels: distribution.channels.reduce(
            (channelsObj, { key, subCampaigns }) => {
                channelsObj[key] = subCampaigns.reduce((acc, sub) => {
                    return (
                        acc +
                        (budget.find(
                            ({ Name }) => Name === getContextFromName(sub)
                        )?.Amount || 0)
                    );
                }, 0);
                return channelsObj;
            },
            {}
        ),
        subCampaigns: Object.values(distribution.subCampaigns).reduce(
            (subsObj, { key }) => {
                subsObj[key] =
                    budget.find(({ Name }) => Name === getContextFromKey(key))
                        ?.Amount || 0;
                return subsObj;
            },
            {}
        ),
        digitalCpmConfig: normalizedDigitalCpmConfig,
        impressionModifierConfig: normalizedImpressionModifierConfig
    };
};
