// vendor related imports
import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { injectStripe } from 'react-stripe-elements';
// component related imports
import { Layout, Button, Icon } from 'maslow';
import paySvg from '@public/img/securePayment.svg';
// redux related imports
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { setPaymentError } from '@state/plan/actions';
import { resetPlan } from '@state/rootActions';
import { setIsOnBookPage } from '@state/ui/actions';
// service/lib related imports
import { getPaymentPayload } from '@services';
import { pipe } from '@lib';
//selectors
import {
    budgetConfigSelector,
    totalFormattedSelector,
    userSelector,
    planSelector,
    createBookButtonDisabledSelector,
    getPendoMetaDataSelector,
    a4PlanIdSelector
} from '@selectors/';

// project related imports
import requestHandlers from '@requestHandlers';
import featuresManager from '@featuresManager';
// style related imports
import styles from '../styles.scss';
//constants
import { PLAN_STATUSES } from '@config/config.planStatus';
import { BOOK_ERRORS_MAP } from '@config/config.appInsights';
import { BOOK_SUBMIT_PLAN_BUTTON } from '@config/idConstants';
import { DEFAULT_STRIPE_ERROR } from '@config/config.book';
import { pendoService } from '@services/tracking';
import { sendAppInsightEvent } from '@services/';
import { bookPlanObjectSelector } from '@selectors/';
import { BOOK } from '@config/';

const Component = ({
    setSubmitted,
    showPayNow,
    setPayError,
    cardHolderName,
    stripe
}) => {
    const {
        attributes: { name, advertiser }
    } = useSelector(planSelector);
    const budget = useSelector(budgetConfigSelector);
    const totalFormatted = useSelector(totalFormattedSelector);
    const {
        account: { email }
    } = useSelector(userSelector);
    const disabled = useSelector(createBookButtonDisabledSelector);
    const pendoMetaData = useSelector(getPendoMetaDataSelector('book'));
    const bookPlanPayload = useSelector(bookPlanObjectSelector);
    const a4PlanId = useSelector(a4PlanIdSelector);

    const [buttonAnimation, setButtonAnimation] = useState(false);
    const dispatch = useDispatch();

    const trackEvent = sendAppInsightEvent();

    const bookPlan = (callback, cancel) => {
        const handleError = err => {
            if (cancel) cancel(err);
            else console.error(err);
        };

        try {
            requestHandlers.plans.bookPlan(bookPlanPayload);
            featuresManager.setFeatureState('plan');
            dispatch(setPlanStatus(PLAN_STATUSES.BOOKED));
            setButtonAnimation(false);
            setSubmitted(true);
            dispatch(resetPlan());
            dispatch(setIsOnBookPage(true));

            if (callback) callback();
        } catch (err) {
            setButtonAnimation(false);
            handleError(err);
        }
    };

    const triggerErrorFuncs = message => {
        trackEvent('TokenValidationError', {
            error: message
        });

        setButtonAnimation(false);
        setPayError(true);
        dispatch(
            setPaymentError([
                {
                    message
                }
            ])
        );
    };

    const getStripeToken = async (callback, cancel) => {
        try {
            const response = await stripe.createToken({
                name: cardHolderName
            });

            if (!response || response?.error) {
                const message =
                    response?.error?.message || DEFAULT_STRIPE_ERROR;

                triggerErrorFuncs(message);
                return;
            }

            setPayError(false);
            callback(response.token.id);
        } catch (error) {
            const message = DEFAULT_STRIPE_ERROR;
            triggerErrorFuncs(message);
        }
    };

    const submitPayment = async (stripeToken, callback, cancel) => {
        const paymentPayload = getPaymentPayload({
            name,
            advertiser,
            email,
            stripeToken,
            budget,
            a4PlanId
        });

        try {
            const { data } = await requestHandlers.plans.payment(
                paymentPayload
            );
            const { status, errorData } = data;

            if (status) {
                switch (status) {
                    case 'succeeded':
                        setPayError(false);
                        callback();
                        break;
                    case 'PaymentRequired':
                        setButtonAnimation(false);
                        setPayError(true);
                        trackEvent('PayValidationError', {
                            error: BOOK_ERRORS_MAP[status]
                        });
                        dispatch(
                            setPaymentError([
                                {
                                    message: errorData.message
                                }
                            ])
                        );
                        break;
                    default:
                        setButtonAnimation(false);
                        setPayError(true);
                        cancel();
                        break;
                }
            }
        } catch (err) {
            setButtonAnimation(false);
            setPayError(true);
            cancel(err);
        }
    };

    const handleSubmit = ev => {
        ev.preventDefault();
        setButtonAnimation(true);
        pendoService.track(BOOK, pendoMetaData);

        if (showPayNow) {
            pipe([getStripeToken, submitPayment, bookPlan]).catch(e => {
                setPayError(true);
                setButtonAnimation(false);
                console.error(e);
            });
        } else {
            bookPlan();
        }
    };

    useEffect(() => {
        if (disabled) setButtonAnimation(false);
    }, [disabled]);

    const buttonText = !showPayNow ? 'Book Now' : ` Pay ${totalFormatted}`;

    return (
        <Layout flexDirection="column" className={styles.buttonWrapper}>
            <Button
                id={BOOK_SUBMIT_PLAN_BUTTON}
                onClick={handleSubmit}
                disabled={disabled}
                animate={buttonAnimation}>
                {showPayNow && (
                    <Icon src={paySvg} className={`${styles.payIcon}`} />
                )}
                {buttonText}
            </Button>
        </Layout>
    );
};

Component.propTypes = {
    setSubmitted: PropTypes.func,
    showPayNow: PropTypes.bool.isRequired,
    stripe: PropTypes.object.isRequired,
    setPayError: PropTypes.func.isRequired,
    cardHolderName: PropTypes.string.isRequired
};

export const SubmitButton = injectStripe(Component);
