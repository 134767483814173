import { createReducer } from '@reduxjs/toolkit';
import { SET_IS_MEDIA_PLAN_VISITED } from '../constants';

const initialState = false;

const setIsMediaPlanVisited = (state, action) => {
    const { isMediaPlanVisited } = action.payload;
    return typeof isMediaPlanVisited === 'boolean' ? isMediaPlanVisited : state;
};

export const isMediaPlanVisited = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_IS_MEDIA_PLAN_VISITED, setIsMediaPlanVisited)
        .addDefaultCase((state, _) => state);
});
