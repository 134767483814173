import featuresManager from '@featuresManager';

export default [
    {
        name: 'app.main',
        url: '/main',
        abstract: true,
        resolve: [
            {
                token: 'featureState',
                deps: ['$transition$'],
                resolveFn: trans => {
                    featuresManager.setFeatureState('main');
                    return Promise.resolve(featuresManager.featureState);
                }
            }
        ]
    }
];
