import { createReducer } from '@reduxjs/toolkit';
import { ADD_VENN_PNG_DATA_URL, REMOVE_VENN_PNG_DATA_URL } from '../constants';

const initialState = '';

const addVennPngDataUrl = (state, action) => {
    const { vennPngDataUrl } = action.payload;
    return vennPngDataUrl;
};

const removeVennPngDataUrl = () => {
    return initialState;
};

export const vennPngDataUrl = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_VENN_PNG_DATA_URL, addVennPngDataUrl)
        .addCase(REMOVE_VENN_PNG_DATA_URL, removeVennPngDataUrl)
        .addDefaultCase((state, _) => state);
});
