import React, { useRef } from 'react';
import PropTypes from 'prop-types';

import { Layout, Text, Icon, Card } from 'maslow';
import menuSvg from '@public/img/icon-menu.svg';
import { actionMenuHelper } from '@services/plans';
import { actionWrapper, menuListWrapper } from './styles.scss';
import { useToggle, useClickOutside } from '@hooks';

const ActionMenu = ({ menu, menuId }) => {
    // EXPLANATION OF PROPS
    // menuId = html id for dots, built with idBuilder
    // menu: array of objects with following format:
    // {
    //  action: text to display,
    //  func: function to follow through on click,
    //  itemId: html id for menu items, built with idBuilder
    // }
    const actionMenuRef = useRef(null);

    const [isOpen, toggle] = useToggle(false);

    useClickOutside(actionMenuRef, () => actionMenuHelper.hide(menuId));

    const handleClick = () => {
        toggle();
        isOpen ? actionMenuHelper.hide(menuId) : actionMenuHelper.show(menuId);
    };

    const buildMenuList = () => {
        return menu.map(({ text, func, itemId }, i) => {
            return (
                <div
                    key={i}
                    className={actionWrapper}
                    onClick={func}
                    id={itemId}>
                    <Text type="input">{text}</Text>
                </div>
            );
        });
    };

    return (
        <div ref={actionMenuRef}>
            <Layout flexDirection="column" hAlign="flex-end">
                <Layout
                    flexDirection="column"
                    vAlign="center"
                    hAlign="center"
                    onClick={handleClick}
                    className={`menuIconWrapper menu_${menuId}`}
                    id={menuId}>
                    <Icon src={menuSvg} size="2" />
                </Layout>

                <Card
                    className={`${menuListWrapper} dropDownCard card_${menuId}`}>
                    {buildMenuList()}
                </Card>
            </Layout>
        </div>
    );
};

ActionMenu.propTypes = {
    menu: PropTypes.array.isRequired,
    menuId: PropTypes.string.isRequired
};

export default ActionMenu;
