import React, { useState, useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { creativeSelector, creativeUrlsSelector } from '@selectors/';
import { Input } from 'maslow';
import {
    setFacebook,
    setInstagram,
    setAdMessenger
} from '@state/creative/actions';
import { isValidUrl } from '@services';
import styles from './styles.scss';
import { AD_MESSENGER, FACEBOOK, INSTAGRAM } from '@config';

import { idBuilder } from '@services';
import { CREATIVE_INPUT } from '@config/idConstants';

const INTERVAL_TIME = 500;

const STATUSES = {
    IDLE: 'idle',
    VALID: 'valid',
    ERROR: 'error',
    EMPTY: 'empty'
};

export const CreativeInput = memo(({ name, label }) => {
    const dispatch = useDispatch();

    const { adMessenger = '', facebook = '', instagram = '' } = useSelector(
        creativeUrlsSelector
    );
    const { files = [] } = useSelector(creativeSelector);

    const URL_ERROR_STATE = {
        empty: `Please enter a valid ${name} url.`,
        error: 'Not a valid url.',
        idle: 'idle',
        valid: 'valid'
    };

    useEffect(() => {
        setInputValue(getUrlFromState(name));
    }, []);

    useEffect(() => {}, [files]);

    const getUrlFromState = name => {
        switch (name) {
            case AD_MESSENGER:
                return adMessenger;
            case FACEBOOK:
                return facebook;
            case INSTAGRAM:
                return instagram;
            default:
                return '';
        }
    };

    const doneTyping = value => {
        validateInput(name, value);
    };

    const onInputChange = value => {
        setInputValue(value || '');
    };

    const buildURLPayload = value => {
        return {
            isValid: status === STATUSES.VALID || STATUSES.IDLE ? true : false,
            url: value
        };
    };

    const validateInput = (name, value) => {
        if (files.length > 0) {
            isValidUrl(value)
                ? setStatus(STATUSES.VALID)
                : setStatus(STATUSES.ERROR);
        }
        addUrlToState(name, value);
    };

    const addUrlToState = (name, payload) => {
        const urlPayload = buildURLPayload(payload);
        switch (name) {
            case AD_MESSENGER:
                return dispatch(setAdMessenger(urlPayload));
            case FACEBOOK:
                return dispatch(setFacebook(urlPayload));
            case INSTAGRAM:
                return dispatch(setInstagram(urlPayload));
            default:
                return '';
        }
    };

    const [status, setStatus] = useState(STATUSES.IDLE);
    const [inputValue, setInputValue] = useState('' || getUrlFromState(name));

    const renderInputs = () => {
        return (
            <div className={styles.inputWrapper}>
                <Input
                    id={idBuilder(CREATIVE_INPUT, name)}
                    name={name}
                    key={name}
                    placeholder={label}
                    onChange={onInputChange}
                    doneTyping={doneTyping}
                    doneTypingInterval={INTERVAL_TIME}
                    value={inputValue || ''}
                    status={status}
                    errorText={URL_ERROR_STATE[status]}
                />
            </div>
        );
    };
    return <React.Fragment>{renderInputs()}</React.Fragment>;
});

CreativeInput.propTypes = {
    name: PropTypes.string,
    label: PropTypes.string,
    placeholder: PropTypes.string
};
