import React, { useState, useEffect } from 'react';
import { UISref } from '@uirouter/react';

import { Text, Layout, Card } from 'maslow';
import caretLeftSvg from '@public/img/caret-left.svg';
import requestHandlers from '@requestHandlers';
import styles from './styles.scss';

import PlanDetails from './PlanDetails';
import ImpressionsAndClicks from './ImpressionsAndClicks';
import PerformanceByChannel from './PerformanceByChannel';
import ImpressionsByChannel from './ImpressionsByChannel';
import CreativePerformance from './CreativePerformance';
import DownloadReport from './DownloadReport';

const plansRoute = 'app.main.plans';

export default function (version, feature) {
    const Reports = ({ planId, redirectTo }) => {
        const config = feature.data;
        const [data, setData] = useState(null);

        useEffect(() => {
            requestHandlers.reports
                .getData(planId)
                .then(res => {
                    return setData(res.data);
                })
                .catch(e => redirectTo('app.main.notfound'));
        }, []);

        //Ensuring that broken test data in staging doesn't break app
        if (
            data &&
            data.impressionsAndClicksData &&
            data.channelPerformance &&
            (!data.impressionsAndClicksData.length ||
                !data.channelPerformance.length)
        ) {
            return <Text>No data</Text>;
        }

        return data ? (
            <Layout flexDirection="column" className={styles.wrapper}>
                <Layout flexDirection="column" className={styles.innerWrapper}>
                    <Layout
                        flexDirection="row"
                        className={styles.returnLinkWrapper}>
                        <UISref to={plansRoute}>
                            <Text
                                color="vividNavy"
                                className={styles.returnLink}>
                                <img src={caretLeftSvg} />
                                <span>Return to All Plans</span>
                            </Text>
                        </UISref>
                    </Layout>
                    <Text type="h3" className={styles.pageTitle}>
                        <span>{data.campaignName}</span>{' '}
                        <span className={styles.notBold}>for</span>{' '}
                        <span>{data.userName}</span>
                    </Text>
                    <DownloadReport
                        planId={planId}
                        planName={data.campaignName}
                    />
                    <PlanDetails data={data.planDetailsData} />
                    <Card backgroundColor="white" className={styles.card}>
                        <div className={styles.graphsWrapper}>
                            <ImpressionsAndClicks
                                data={data.impressionsAndClicksData}
                            />

                            <ImpressionsByChannel
                                data={data.channelPerformance}
                            />

                            <PerformanceByChannel
                                data={data.channelPerformance}
                                config={config}
                            />

                            <CreativePerformance
                                data={data.channelPerformance}
                                config={config}></CreativePerformance>
                        </div>
                    </Card>
                </Layout>
            </Layout>
        ) : null;
    };

    return Reports;
}
