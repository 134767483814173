import React, { useReducer, useEffect } from 'react';
import { Layout } from 'maslow';

import { emailAndPasswordErrors } from '@config/config.login';
import { loginService } from '@services';
import { getQueryParameter } from '@lib';

import SendLink from './SendLink';
import LoginSection from './LoginSection';
import PasswordChangedSection from './PasswordChangedSection';
import NewPassword from './NewPassword';
import {
    LeftIllustrationSection,
    TermsAndConditions,
    TopLogoSection
} from '@components/common';

import shared from '@sharedStyle/loginAndSignUp.scss';

const INTERVAL_TIME = 200;
const STATUSES = {
    IDLE: 'idle',
    VALID: 'valid',
    ERROR: 'error'
};

const DISPLAY_TEXT_STATES = {
    INITIAL: 'initial',
    FORGOT_PASSWORD: 'forgotPassword',
    NEW_PASSWORD: 'newPassword',
    PASSWORD_LINK_SENT: 'passwordLinkSent',
    PASSWORD_CHANGED: 'passwordChanged'
};

const getInitialState = ({ INITIAL }) => {
    return {
        displayTextState: INITIAL,
        showSendLink: false,
        showPasswordChangedPage: false,
        showPasswordLinkSent: false,
        newPassword: false,
        token: '',
        email: ''
    };
};

const reducer = (state, action) => {
    const { type, payload } = action;
    const { name, value } = payload;
    switch (type) {
        case 'updateValue':
            return {
                ...state,
                [name]: value
            };
        default:
            throw new Error();
    }
};

export default function (version, feature) {
    const ls = loginService(feature);
    const { titles } = feature.data;

    const Login = props => {
        const [state, stateDispatch] = useReducer(
            reducer,
            getInitialState(DISPLAY_TEXT_STATES)
        );

        const {
            displayTextState,
            showSendLink,
            showPasswordChangedPage,
            showPasswordLinkSent,
            newPassword,
            token,
            email
        } = state;

        const updateComponentState = (name, value) => {
            stateDispatch({
                type: 'updateValue',
                payload: { name, value }
            });
        };

        useEffect(() => {
            ls.resetUserDetails();
            const { tokenParam, emailParam } = getQueryParameter();
            if (tokenParam && emailParam) {
                updateComponentState('newPassword', true);
                updateComponentState(
                    'displayTextState',
                    DISPLAY_TEXT_STATES.NEW_PASSWORD
                );
                updateComponentState('token', tokenParam);
                updateComponentState('email', emailParam);
            }
        }, []);

        const render = () => {
            const displayText = ls.getDisplayText(titles, displayTextState);

            return (
                <Layout flexDirection="row" className={shared.wrapper}>
                    <LeftIllustrationSection />

                    <Layout
                        flexDirection="column"
                        className={shared.rightWrapper}>
                        <TopLogoSection
                            isLogin={true}
                            displayText={displayText}
                        />

                        {!newPassword &&
                            !showSendLink &&
                            !showPasswordChangedPage &&
                            !showPasswordLinkSent && (
                                <LoginSection
                                    ls={ls}
                                    errors={emailAndPasswordErrors}
                                    STATUSES={STATUSES}
                                    INTERVAL_TIME={INTERVAL_TIME}
                                    updateComponentState={updateComponentState}
                                    DISPLAY_TEXT_STATES={DISPLAY_TEXT_STATES}
                                    displayText={displayText}
                                    transition={props.transition}
                                />
                            )}

                        {showSendLink && (
                            <SendLink
                                ls={ls}
                                errors={emailAndPasswordErrors}
                                STATUSES={STATUSES}
                                INTERVAL_TIME={INTERVAL_TIME}
                                updateComponentState={updateComponentState}
                                DISPLAY_TEXT_STATES={DISPLAY_TEXT_STATES}
                                buttonText={displayText.button}
                            />
                        )}

                        {showPasswordChangedPage && (
                            <PasswordChangedSection
                                buttonText={displayText.button}
                                updateComponentState={updateComponentState}
                                DISPLAY_TEXT_STATES={DISPLAY_TEXT_STATES}
                            />
                        )}

                        {newPassword && (
                            <NewPassword
                                loginService={ls}
                                errors={emailAndPasswordErrors}
                                text={displayText.button}
                                intervalTime={INTERVAL_TIME}
                                STATUSES={STATUSES}
                                token={token}
                                email={email}
                                updateComponentState={updateComponentState}
                                DISPLAY_TEXT_STATES={DISPLAY_TEXT_STATES}
                            />
                        )}

                        <TermsAndConditions />
                    </Layout>
                </Layout>
            );
        };

        return render();
    };

    return Login;
}
