import React from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';

import { Text, Icon, Layout } from 'maslow';
import plusIcon from '@public/img/plus-selector.svg';
import minusIcon from '@public/img/minus-selector.svg';

import {
    addExcludedDaypart,
    removeExcludedDaypart
} from '@state/schedule/actions/excludedDayparts';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { reMount } from '@components/hoc';
import { PLAN_STATUSES } from '@config/config.planStatus';

import styles from '../styles.scss';
import { idBuilder } from '@services';
import { SCHEDULE_DAYPART } from '@config/idConstants';

const Daypart = ({ option, exclusionsLimit }) => {
    const dispatch = useDispatch();
    const excludedDayparts = useSelector(
        state => state.schedule.excludedDayparts
    );

    const hasExcludedDayPart = () => {
        const values = excludedDayparts.map(
            excludedDaypart => excludedDaypart.value
        );

        return values.includes(option.value);
    };

    const isSelected = hasExcludedDayPart();
    const optionStyle = isSelected
        ? [styles.option, styles.selected]
        : styles.option;

    const handleClick = () => {
        if (!isSelected && excludedDayparts.length < exclusionsLimit) {
            dispatch(addExcludedDaypart(option));
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        } else if (isSelected) {
            dispatch(removeExcludedDaypart(option.value));
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        }
    };

    const renderIcon = () => {
        const Component = reMount(Icon);
        const iconSrc = isSelected ? plusIcon : minusIcon;

        return <Component src={iconSrc} size="2.5" />;
    };

    return (
        <Layout
            id={idBuilder(SCHEDULE_DAYPART, option.value)}
            flexDirection="row"
            vAlign="center"
            className={optionStyle}
            onClick={() => handleClick()}>
            <Layout className={styles.icon}>{renderIcon()}</Layout>

            <Layout flexDirection="column">
                <Text className={styles.dayPartName}>{option.name}</Text>
                <Text className={styles.dayPartTime}>{option.timeOfDay}</Text>
            </Layout>
        </Layout>
    );
};

Daypart.propTypes = {
    exclusionsLimit: PropTypes.number,
    option: PropTypes.shape({
        name: PropTypes.string,
        value: PropTypes.string,
        timeOfDay: PropTypes.string
    })
};

export default Daypart;
