/**
 * names are from http://colorate.azurewebsites.net/
 */

export default {
    white: '#FFFFFF',
    paleCyan: '#D9E1E2',
    gray: '#B2B2B2',
    darkGray: '#414141',
    vividNavy: '#2E5EFF',
    lightNavy: '#6387FF',
    strongNavy: '#1D3B97',
    deepBlue: '#003380',
    softBlue: '#4167A0',
    darkBlue: '#001E4C',
    black: '#000000',
    aqua: '#3CDBC0',
    red: '#E31D1A',
    green: '#00AA5A',
    lightGreen: '#41bf84',
    darkGreen: '#006636',
    transparent: 'transparent',
    teal: '#0090AA',
    lavenderBlue: 'rgba(207,224,255,0.32)',
    chetwodeBlue: '#6A7AAB',
    backgroundGraySolid: '#f4f6f6',
    quartz: '#F5F6FC',
    echoBlue: '#A5B0D2',
    backgroundGray: 'rgba(217, 225, 226, 0.3)',
    brightBlue: '#2C5EFF',
    heatherGray: '#AAB5C3',
    linkWaterGray: '#C7CED8',
    slateGray: '#88898d',
    eggBlue: '#3464f6',
    wildBlue: '#8391BC',
    denim: '#0d82c0',
    audience3: '#07667d',
    atelier: '#a2abb8',
    corbeau: '#0e1821',
    brightBlue: '#2c5eff'
};
