import clone from 'clone';

export default (...args) => {
    if (!args.length) throw Error('no arguments were provided');

    args.forEach(arg => {
        if (
            Array.isArray(arg) ||
            typeof arg !== 'object' ||
            typeof arg === 'undefined' ||
            arg instanceof RegExp ||
            !arg
        ) {
            throw TypeError('type of one or more arguments are not an object.');
        }
    });

    const recurse = (objs, acc) => {
        const _obj = acc || { props: {}, proto: {} };
        const _objects = objs || clone(args);

        if (!_objects.length) return _obj;

        Object.assign(_obj.proto, _objects[0].proto);
        Object.assign(_obj.props, _objects[0].props);

        _objects.shift();

        return recurse(_objects, _obj);
    };

    const obj = recurse();

    return Object.assign(Object.create(obj.proto), obj.props);
};
