import { createReducer } from '@reduxjs/toolkit';
import { SAVE_DISTRIBUTION_CONFIG } from '../constants';
import { isArray } from '@lib';

const initialState = [];

const saveDistributionConfig = (state, action) => {
    const { distributionConfig } = action.payload;
    return isArray(distributionConfig) ? distributionConfig : state;
};

export const distributionConfig = createReducer(initialState, reducer => {
    reducer
        .addCase(SAVE_DISTRIBUTION_CONFIG, saveDistributionConfig)
        .addDefaultCase((state, _) => state);
});
