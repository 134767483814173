import { Profile } from '../components/pages';

export default [
    {
        name: 'app.main.profile',
        url: '/profile',
        views: {
            'content@app': {
                component: Profile
            }
        }
    }
];
