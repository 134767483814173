import { createReducer } from '@reduxjs/toolkit';
import { UPDATE_AGENCY, RESET_AGENCY } from '../constants';

const initialState = {};

const updateAgency = (state, action) => {
    const { agency } = action.payload;
    return {
        ...state,
        ...agency
    };
};

const resetAgency = (state, _) => {
    state.account = initialState;
};

export const agency = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_AGENCY, updateAgency)
        .addCase(RESET_AGENCY, resetAgency)
        .addDefaultCase((state, _) => state);
});
