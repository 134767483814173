import React, { useEffect, useState, forwardRef, useRef } from 'react';
import * as d3 from 'd3';
import * as venn from 'venn.js';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';

import { fetchHouseholdCount } from '@state/audience/actions/households';
import { addCombinations } from '@state/audience/actions/combinations';
import {
    buildSets,
    buildSelected,
    greyOut,
    removeGrayinOut,
    FILL_COLORS
} from '@services/vennService';
import { formatLocations } from '@services/audiencesService';
import { combineAudiences } from '@services';
import { isIpad, getVennSize } from '@services';
import { isEmpty } from '@lib';
import { AUDIENCE_BUILDER_VENN } from '@config/idConstants';
import {
    audienceSelector,
    audiencesSelector,
    selectedLocationsSelector
} from '@selectors/';
import vStyles from './styles.scss';

const { select } = d3;
const ORDER = { 0: 2, 1: 1, 2: 3 };

const originalSetsSelector = createSelector(audiencesSelector, audiences =>
    buildSets(audiences, 'large')
);

const CombinationsVennComponent = forwardRef((props, ref) => {
    const dispatch = useDispatch();
    const locations = useSelector(selectedLocationsSelector);
    const { audiences, combinations } = useSelector(audienceSelector);
    const originalSets = useSelector(originalSetsSelector);
    const [selectedState, setSelectedState] = useState(
        isEmpty(combinations) ? buildSelected(originalSets) : combinations
    );
    const selectedRef = useRef(selectedState);

    const onMouseOver = (d, el, container, selected) => {
        if (!(d.ref in selected)) return;

        venn.sortAreas(container, d);
        const selection = select(el);

        selection
            .select('path')
            .style('stroke', '#FFFFFF')
            .style('stroke-width', 1)
            .style('fill-opacity', 0.7);
    };

    const onMouseOut = (d, el, selected) => {
        if (!(d.ref in selected)) return;

        const selection = select(el);
        selection
            .select('path')
            .style('stroke', '#1A64CD')
            .style('stroke-width', () => {
                return d.sets.length > 1 ? 0 : 1;
            })
            .style('fill-opacity', 1);
    };

    const removeCombination = (ref, selectedState) => {
        const { [ref]: c, ...selected } = selectedState;
        return setSelectedState(selected);
    };

    const addCombination = (combination, selected) => {
        const newSelected = {
            ...selected,
            [combination.ref]: {
                ...combination
            }
        };

        return setSelectedState(newSelected);
    };

    const clickOnSet = (d, el, selected) => {
        if (d.ref in selected) {
            removeCombination(d.ref, selected);
            greyOut(el);
        } else {
            addCombination(d, selected);
            removeGrayinOut(el);
        }
    };

    const setColors = (d, i, n, selected) => {
        const element = n[i];
        const fill = FILL_COLORS[d.ref];

        select(element).style('fill', fill);

        if (!(d.ref in selected)) {
            greyOut(element);
        }
    };

    const buildVenn = originalSets => {
        const { width, height } = getVennSize(window.innerWidth);

        const chart = venn
            .VennDiagram()
            .width(width)
            .height(height)
            .styled(false)
            .orientation(-Math.PI / 2);

        chart.orientationOrder((a, b) => ORDER[a.setid] - ORDER[b.setid]);
        const container = select('#venn');
        container.datum(originalSets).call(chart);

        const gNodes = container.selectAll('g');

        gNodes.on('click', (d, i, n) =>
            clickOnSet(d, n[i], selectedRef.current)
        );
        if (!isIpad()) {
            gNodes
                .on('mouseover', (d, i, n) =>
                    onMouseOver(d, n[i], container, selectedRef.current)
                )
                .on('mouseout', (d, i, n) =>
                    onMouseOut(d, n[i], selectedRef.current)
                );
        }

        gNodes.each((d, i, n) => {
            setColors(d, i, n, selectedRef.current);
            d3.select(n[i]).attr('id', function (d) {
                return AUDIENCE_BUILDER_VENN + '-' + d.label.replace(/ /g, '');
            });
        });
    };

    const fetchCount = (selected, originalSets, audiences, locations) => {
        const formattedLocations = formatLocations(locations);
        const locationsArray =
            formattedLocations.map(location => ({
                ...location,
                section: 'Location'
            })) || [];

        const audienceArrays = !audiences.length
            ? [locationsArray]
            : locationsArray.length
            ? audiences.map(audience => [...audience, ...locationsArray])
            : audiences;

        const combinedAudiences = combineAudiences(audienceArrays);

        dispatch(
            fetchHouseholdCount({
                selected,
                originalSets,
                audiences: combinedAudiences
            })
        );
    };

    useEffect(() => {
        return () => dispatch(fetchHouseholdCount());
    }, []);

    useEffect(() => {
        buildVenn(originalSets);
    }, [originalSets]);

    useEffect(() => {
        selectedRef.current = selectedState;
        ref.current.saveCombinations = () =>
            dispatch(addCombinations(selectedState));
        fetchCount(selectedState, originalSets, audiences, locations);
    }, [selectedState]);

    return <div id="venn" ref={ref} />;
});

export default CombinationsVennComponent;
