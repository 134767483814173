import React from 'react';
import PropTypes from 'prop-types';
import { chunkLocationData } from '@services';
import { Text, Layout } from 'maslow';
import styles from '../styles.scss';
import SelectedLocation from '../SelectedLocation';

const SelectedLocationCategory = props => {
    const { selectedLocations } = props;

    if (selectedLocations.length === 0) {
        return null;
    }

    const buildLocations = location => {
        return (
            <SelectedLocation
                location={location}
                key={`location-selection-${location.name}`}
            />
        );
    };

    const buildLocationCategories = locations => {
        if (locations.length === 0) {
            return null;
        }
        const groupedLocations = chunkLocationData(locations);

        return groupedLocations.map(audienceCategory => {
            if (audienceCategory.entries.length === 0) {
                return null;
            }
            return (
                <Layout
                    flexDirection="column"
                    className={styles.marginTop}
                    key={`location-category-${audienceCategory.title}`}>
                    <Text type="body" className={styles.boldText}>
                        {audienceCategory.title}
                    </Text>
                    {audienceCategory.entries.map(location =>
                        buildLocations(location)
                    )}
                </Layout>
            );
        });
    };

    return (
        <Layout flexDirection="column">
            {buildLocationCategories(selectedLocations)}
        </Layout>
    );
};

SelectedLocationCategory.propTypes = {
    selectedLocations: PropTypes.array.isRequired
};

export default SelectedLocationCategory;
