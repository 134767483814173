import { connect } from 'react-redux';

import { normalizeAudienceOptionId } from '@services';
import { toggleIsIndicatorMounted } from '../actions/toggleIsIndicatorMounted';
import { fetchHouseholdCount } from '../actions/households';
import {
    addOptionToAudience,
    removeOptionFromAudience
} from '../actions/audiences';

export const audiencesContainer = Component => {
    const mapStateToProps = (state, props) => {
        const { audiences, currentAudienceIndex } = state.audience;
        const normalizedId = normalizeAudienceOptionId(
            props.option.name,
            props.category
        );

        if (!audiences[currentAudienceIndex]) return { isSelected: false };

        const isSelected =
            audiences[currentAudienceIndex].filter(s => s.id === normalizedId)
                .length > 0;

        return { isSelected: !!isSelected };
    };

    const mapDispatchToProps = dispatch => ({
        addOptionToAudience: (audienceIndex, option) =>
            dispatch(addOptionToAudience(audienceIndex, option)),
        removeOptionFromAudience: (audienceIndex, optionId) =>
            dispatch(removeOptionFromAudience({ audienceIndex, optionId })),
        fetchHouseholdCount: () => dispatch(fetchHouseholdCount())
    });

    return connect(mapStateToProps, mapDispatchToProps)(Component);
};

export const toggleIsIndicatorMountedContainer = Component => {
    const mapDispatchToProps = dispatch => ({
        toggleIsIndicatorMounted: isMounted =>
            dispatch(toggleIsIndicatorMounted(isMounted))
    });

    return connect(null, mapDispatchToProps)(Component);
};
