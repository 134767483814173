import { createAction } from '@reduxjs/toolkit';
import {
    ADD_HIATUS_WEEK,
    REMOVE_HIATUS_WEEK,
    REMOVE_ALL_HIATUS_WEEKS,
    SHIFT_HIATUS_WEEKS
} from '../constants';

export const addHiatusWeek = createAction(ADD_HIATUS_WEEK, hiatusWeek => {
    return {
        payload: { hiatusWeek }
    };
});

export const shiftHiatusWeeks = createAction(
    SHIFT_HIATUS_WEEKS,
    hiatusWeeks => {
        return {
            payload: { hiatusWeeks }
        };
    }
);

export const removeHiatusWeek = createAction(REMOVE_HIATUS_WEEK, weekNumber => {
    return {
        payload: { weekNumber }
    };
});

export const removeAllHiatusWeeks = createAction(REMOVE_ALL_HIATUS_WEEKS);
