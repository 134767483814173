import axios from 'axios';
import { hasOwnProp } from './utils';
const cache = {};

const getSvgElement = (data) => {
    const parser = new DOMParser();
    return parser.parseFromString(data, 'image/svg+xml');
};

const performRequest = (src) => {
    return axios({
        method: 'GET',
        url: src
    }).then(response => {
        const svgElement = getSvgElement(response.data);
        cache[src].loading = false;
        return svgElement.documentElement;
    }).catch(err => {
        cache[src].loading = false;
        return Promise.reject(err);
    });
};

export default function requestSvg(src) {
    if (!cache[src]) cache[src] = { loading: false };

    // if svg is cached, use cached version
    if (hasOwnProp(cache, src) && hasOwnProp(cache[src], 'data')) {
        return cache[src].data;
    }

    cache[src].loading = true;
    cache[src].data = performRequest(src);

    return cache[src].data;
};
