export default {
    name: 'feature-name',
    description: 'description for this feature',
    type: 'feature',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    permissions: [],
    sections: [],
    states: [],
    order: 0,
    data: {},
    attributes: {},
    customFilters: {}
};
