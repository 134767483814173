import { createReducer } from '@reduxjs/toolkit';
import {
    defaultFileShape,
    replaceUpdatedFile,
    updateCreativeStatus
} from '@services/creative';
import { groupBy } from '@lib';

import {
    ADD_FILE,
    ADD_FILES,
    UPDATE_FILE,
    UPDATE_FILES,
    REMOVE_FILE,
    REMOVE_ALL_FILES,
    SET_FILE_STATUS
} from '../constants';

const initialState = [];

const addFile = (state, action) => {
    const { file } = action.payload;

    return [...state, { ...defaultFileShape, ...file }];
};

const addFiles = (state, action) => {
    const { files } = action.payload;

    return [...state, ...files.map(file => ({ ...defaultFileShape, ...file }))];
};

const updateFile = (state, action) => {
    const { file, index } = action.payload;

    return replaceUpdatedFile(state, file, index);
};

const updateFiles = (state, action) => {
    const { files } = action.payload;

    let updatedFiles = groupBy(files, 'name');

    return state.map(item => {
        return updatedFiles[item.name]
            ? {
                  ...item,
                  ...updatedFiles[item.name][0],
                  excludedProducts: item.excludedProducts
              }
            : { ...item };
    });
};

const removeFile = (state, action) => {
    const { file: _file } = action.payload;

    if (typeof _file !== 'object') return state;
    // check if file has id (uploaded first)
    if (_file.hasOwnProperty('id') && _file.id !== undefined) {
        return state.filter(file => file.id !== _file.id);
    }
    // if file doesn't have id, remove file by name only
    if (_file.hasOwnProperty('name') && _file.name !== undefined) {
        const matchingItems = state.filter(file => file.name === _file.name);
        const nonMatchingItems = state.filter(file => file.name !== _file.name);
        const [_, ...rest] = matchingItems;
        return [...nonMatchingItems, ...(rest || {})];
    }
};

const removeAllFiles = () => initialState;

const setFileStatus = (state, action) => {
    const { id, status } = action.payload;
    return updateCreativeStatus(state, id, status);
};

export const files = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_FILE, addFile)
        .addCase(ADD_FILES, addFiles)
        .addCase(UPDATE_FILE, updateFile)
        .addCase(UPDATE_FILES, updateFiles)
        .addCase(REMOVE_FILE, removeFile)
        .addCase(REMOVE_ALL_FILES, removeAllFiles)
        .addCase(SET_FILE_STATUS, setFileStatus)
        .addDefaultCase((state, _) => state);
});
