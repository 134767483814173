import React, { useState, useEffect, useRef } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import featuresManager from '@featuresManager';
import AudienceScrollContainer from './AudienceScrollContainer';
import AudienceHeader from './AudienceHeader';
import { connect } from 'react-redux';
import { Content } from 'maslow';
import { strf, capitalizeFirstLetters } from '@lib';
import { slugBuilder } from '@services';
import { audienceSelector, regionSelector } from '@selectors/';
import { getSelectedAgeList } from '@services/audiencesService';
import { setBudgetDistribution } from '@services/budget';
import ViewershipSection from './ViewershipSection';
import { VIEWERSHIP } from '@config';

import styles from './index.scss';

const sectionUrlBuilder = section => {
    return strf(window.location.href)
        .replace(/([^/]+$)/g, section)
        .value();
};

const AudienceSelection = props => {
    const dispatch = useDispatch();
    const { audienceConfig } = props;
    const [activeSection, setActiveSection] = useState(audienceConfig[0].name);
    const [boxShadow, setBoxShadow] = useState({});
    const [boxShrink, setBoxShrink] = useState({});
    const [marginShrink, setMarginShrink] = useState({});

    const { audiences } = useSelector(audienceSelector);
    const selectedAgesRef = useRef([]);
    const [role] = featuresManager.getCustomFilterState('role');
    const region = useSelector(regionSelector);
    const _viewershipText = capitalizeFirstLetters(VIEWERSHIP);

    const scrollUnderHeader = () => {
        setBoxShadow({
            boxShadow: '0px 30px 26px -8px rgba(143, 143, 143, 1)'
        });
        setBoxShrink({
            transform: 'scale(0)',
            opacity: 0
        });
        setMarginShrink({
            margin: 0
        });
    };

    const scrollOutOfHeader = () => {
        setBoxShadow({});
        setBoxShrink({});
        setMarginShrink({});
    };

    const onSectionChange = sectionName => {
        const activeSectionName = slugBuilder(activeSection);
        const sectionSlugName = slugBuilder(sectionName);
        if (!activeSectionName || sectionSlugName === activeSectionName) return;
        const url = sectionUrlBuilder(sectionSlugName);
        window.history.pushState({}, sectionSlugName, url);
        if (
            sectionName === _viewershipText ||
            activeSection === _viewershipText
        ) {
            setActiveSection(sectionName);
            scrollOutOfHeader();
        }
    };

    const onSectionViewPortChange = ({ name, isInViewport, viewPortState }) => {
        if (
            isInViewport &&
            viewPortState !== 'partiallyExitViewport' &&
            viewPortState !== 'enterViewport'
        ) {
            onSectionChange(name);
            setActiveSection(name);
        }
    };

    useEffect(() => {
        selectedAgesRef.current = getSelectedAgeList(audiences);
    }, [audiences]);

    useEffect(() => {
        return () => {
            setBudgetDistribution(
                role,
                region,
                selectedAgesRef.current,
                dispatch
            );
        };
    }, []);

    const isShowViewership = activeSection === _viewershipText;

    return (
        <Content className={styles.audienceMainWrapper}>
            <AudienceHeader
                {...props}
                activeSection={activeSection}
                onSectionChange={onSectionChange}
                boxShadow={boxShadow}
                boxShrink={boxShrink}
                marginShrink={marginShrink}
            />
            {!isShowViewership && (
                <AudienceScrollContainer
                    {...props}
                    onSectionViewPortChange={onSectionViewPortChange}
                    scrollUnderHeader={scrollUnderHeader}
                    scrollOutOfHeader={scrollOutOfHeader}
                />
            )}
            {isShowViewership && <ViewershipSection />}
        </Content>
    );
};

const mapStateToProps = state => ({
    currentAudienceIndex: state.audience.currentAudienceIndex
});

export default connect(mapStateToProps)(AudienceSelection);
