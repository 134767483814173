import { header, listItem, ulEl } from './Segment/segment.scss';

const searchHelper = {
    onKeyDown: (e, handleOnEnter, stateDispatch, isSelectFirstItem) => {
        const listElm = document.querySelector('ul');
        if ((e.key === 'ArrowUp' || e.key === 'ArrowDown') && listElm) {
            e.preventDefault();
            document.body.style.pointerEvents = 'none';
            if (isSelectFirstItem) {
                const elIndex = listElm.firstElementChild.classList.contains(
                    header
                )
                    ? 1
                    : 0;
                listElm.children[elIndex].focus();
                stateDispatch({
                    type: 'updateIsSelectFirstItem',
                    payload: { isSelectFirstItem: false }
                });
            } else {
                let selectedElm = document.activeElement,
                    action = {
                        ArrowUp: 'previous',
                        ArrowDown: 'next'
                    };

                do {
                    selectedElm = selectedElm[action[e.key] + 'ElementSibling'];
                } while (
                    selectedElm ? selectedElm.classList.contains(header) : false
                );

                if (!selectedElm) {
                    selectedElm =
                        listElm.children[
                            e.key === 'ArrowUp'
                                ? listElm.children.length - 1
                                : 0
                        ];
                    while (selectedElm.classList.contains(header)) {
                        selectedElm =
                            selectedElm[action[e.key] + 'ElementSibling'];
                    }
                }

                selectedElm.focus();
            }
        }

        if (e.key === 'Enter' && listElm) {
            e.preventDefault();
            handleOnEnter(e);
        }
    },

    onMouseOver: (e, stateDispatch) => {
        e.preventDefault();
        if (e.target.classList.contains(listItem)) {
            e.target.focus();
            stateDispatch({
                type: 'updateIsSelectFirstItem',
                payload: { isSelectFirstItem: false }
            });
        }
    },

    onMouseLeave: e => {
        if (e.target.nodeName !== 'HTML') {
            const activeEl = document.activeElement;
            if (activeEl.classList.contains(listItem)) {
                activeEl.blur();
            }
        }
    },

    onKeyUp: e => {
        document.body.style.pointerEvents = 'auto';
    },

    onClick: (e, stateDispatch) => {
        if (
            !e.target.classList.contains(ulEl) &&
            !e.target.classList.contains(header) &&
            !e.target.classList.contains('searchInput') &&
            !(
                e.target.previousSibling &&
                e.target.previousSibling.classList.contains('searchInput')
            )
        ) {
            stateDispatch({ type: 'emptyInput' });
        }
    }
};

export default searchHelper;
