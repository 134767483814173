const counterProps = {
    _count: 0
};

const counterProto = {
    get count () {
        return this._count;
    },

    set (amount = 0) {
        this._count = amount;
        return this._count;
    },

    reset () {
        this._count = 0;
        return this._count;
    },

    add (amount = 0) {
        this._count = this._count + amount;
        return this._count;
    },

    minus (amount = 0) {
        this._count = this._count - amount;
        return this._count;
    }
};

/**
 * counterFactory creates an object that is intended to make a simple
 * interface for incrementing and decrementing integers.
 * see unit tests for implementation details
 *
 * @param {Number} start
 */
export const counterFactory = (start = 0) => {
    const props = { ...counterProps, ...{ _count: start } };
    return Object.assign(Object.create(counterProto), props);
}
