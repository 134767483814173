import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { Layout } from 'maslow';
import { setIsOnBookPage } from '@state/ui/actions';
import { a4PlanIdSelector } from '@selectors/';
import NextSteps from './NextSteps';
import SubmitSection from './SubmitSection';

let _a4PlanId;

export default function (version, feature) {
    const Book = ({ transition, customFiltersStates }) => {
        const dispatch = useDispatch();
        const { maxPayNowLimit, apiKey, routes } = feature.data;
        const [submitted, setSubmitted] = useState(false);
        const a4PlanId = useSelector(a4PlanIdSelector);

        useEffect(() => {
            _a4PlanId = a4PlanId;
            return () => {
                dispatch(setIsOnBookPage(false));
            };
        }, []);

        return (
            <Layout flexDirection="column" hAlign="center" fillParent={true}>
                {!submitted && (
                    <SubmitSection
                        setSubmitted={setSubmitted}
                        maxPayNowLimit={maxPayNowLimit}
                        apiKey={apiKey}
                    />
                )}
                {submitted && (
                    <NextSteps
                        a4PlanId={_a4PlanId}
                        routes={routes}
                        transition={transition}
                        customFiltersStates={customFiltersStates}
                    />
                )}
            </Layout>
        );
    };

    return Book;
}
