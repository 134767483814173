import request from '../request';

export default function() {
    const req = {
        method: 'GET',
        url: 'networks/network/'
    };

    return request(req);
}
