import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { Layout } from 'maslow';
import { Search } from '@components/common';
import NetworkScroll from './NetworkScroll';
import requestHandlers from '@requestHandlers/';

import { audienceSelector } from '@selectors';
import { updateAudiences } from '@state/audience/actions';
import { filterViewershipData } from '@services';

const currentAudienceSelector = createSelector(
    audienceSelector,
    ({ audiences, currentAudienceIndex }) => audiences[currentAudienceIndex]
);

const ViewershipSection = () => {
    const searchLabel = 'Search for networks';
    const dispatch = useDispatch();
    const currentAudience = useSelector(currentAudienceSelector);

    const onClickFunction = item => {
        dispatch(updateAudiences(item));
    };

    const searchRequest = audience => {
        return async value => {
            try {
                const { data } = await requestHandlers.audiences.viewership(
                    value
                );
                return filterViewershipData(data, audience);
            } catch (err) {
                console.error(err);
                return [];
            }
        };
    };
    return (
        <Layout flexDirection="column">
            <Search
                searchLabel={searchLabel}
                onClickFunction={onClickFunction}
                searchRequest={searchRequest(currentAudience)}></Search>
            <NetworkScroll onClickFunction={onClickFunction}></NetworkScroll>
        </Layout>
    );
};

export default ViewershipSection;
