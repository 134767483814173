import React from 'react';

import { Text, Layout } from 'maslow';
import { strf, addDecimals } from '@lib';

import styles from './styles.scss';

import statsSvg from '@public/img/stats.svg';

export default ({ data }) => {
    const { digitalCtr, ottVcr } = data;
    const formattedDigitalCtr = strf(addDecimals(digitalCtr * 100))
        .append('%')
        .value();
    const formattedOttVcr = strf(addDecimals(ottVcr * 100))
        .append('%')
        .value();
    return (
        <div className={styles.performanceOverview}>
            <Text type="input" className={styles.boxTitle}>
                <img src={statsSvg} className={styles.statsImg} />
                Performance Overview
            </Text>
            <Layout
                className={styles.performanceOverviewBoxContainer}
                hAlign="space-around">
                <div className={styles.performanceOverviewBox}>
                    <Text className={styles.performanceOverviewStat}>
                        {formattedDigitalCtr}
                    </Text>{' '}
                    <Text
                        type="body"
                        className={styles.performanceOverviewCaption}>
                        Digital CTR
                    </Text>
                </div>
                <div className={styles.performanceOverviewBox}>
                    {' '}
                    <Text className={styles.performanceOverviewStat}>
                        {formattedOttVcr}
                    </Text>{' '}
                    <Text
                        type="body"
                        className={styles.performanceOverviewCaption}>
                        OTT VCR
                    </Text>
                </div>
            </Layout>
        </div>
    );
};
