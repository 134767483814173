import request from '../request';

export default function(audience) {
    return request({
        method: 'POST',
        url: 'custom_list/save/',
        data: audience
    }).catch(error => {
        throw Error(error);
    });
}
