// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".textContainer___2ixCL {\n  width: 100%;\n  text-align: center; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/features/VerifyUser/1.0.0/styles.scss"],"names":[],"mappings":"AAAA;EACI,WAAW;EACX,kBAAkB,EAAA","file":"styles.scss","sourcesContent":[".textContainer {\n    width: 100%;\n    text-align: center;\n}\n"]}]);
// Exports
exports.locals = {
	"textContainer": "textContainer___2ixCL"
};
module.exports = exports;
