import {
    RESTART_CREATIVE,
    PAUSE_CREATIVE,
    INTERNAL,
    EXTERNAL
} from './constants';

export const STATUS = {
    IDLE: 'idle',
    IN_PROGRESS: 'in_progress',
    INVALID: 'invalid',
    VALID: 'valid',
    ERROR: 'error'
};

export const CONTEXT = {
    PUBLISH: 'publish',
    EDIT: 'edit'
};

export const FILE_STATUS = {
    PAUSED: 'paused',
    ACTIVE: 'active',
    NEW: 'new'
};

export const VALID_FILE_NAME_REGEX = /[^a-zA-Z0-9._\-()&]/;

export const VALID_DIMENSIONS = [
    '768x90',
    '728x90',
    '300x600',
    '300x250',
    '320x50',
    '320x250',
    '320x100',
    '160x600',
    '640x360',
    '970x250',
    '1080x1080',
    '1920x1080',
    '1280x720'
];

export const VALID_FILE_SIZES = {
    image: {
        maxFileSize: 200 * 1000,
        minFileSize: 0
    },
    video: {
        maxFileSize: 2000000 * 1000,
        minFileSize: 0
    }
};

export const VALID_FILE_TYPES = [
    'image/jpg',
    'image/jpeg',
    'image/gif',
    'image/png',
    'image/apng',
    'video/mp4',
    'video/quicktime',
    'video/mpeg2',
    'video/mpg',
    'video/mpeg',
    'video/webp'
];

export const CREATIVE_URLS_MAP = {
    adMessenger: 'adMessengerUrl',
    destination: 'ClickThroughUrl',
    facebook: 'FacebookUrl',
    instagram: 'InstagramUrl'
};

export const VALID_ASPECT_RATIO = [16 / 9, 9 / 16, 1 / 1];

export const VALID_POLITICAL_DISCLAIMER = ['paid', 'for', 'by'];

export const ERROR_MESSAGES = {
    fileSizeNotAcceptable: 'File size is not acceptable.',
    fileFormatNotAcceptable: 'File format is not acceptable.',
    fileDimensionsNotAcceptable: 'File dimensions are not acceptable',
    fileAspectRatioNotAcceptable: 'File aspect ratio is not acceptable',
    politicalDisclaimerNotFound: 'Political Disclaimer is missing',
    noValidProducts:
        'Does not match the requirements of your selected products',
    errorDuringUpload: 'There was an error uploading your file',
    default: 'This file does not meet the required specifications'
};

export const SUB_CAMPAIGN_SETTINGS = {
    preMidRoll: {
        default: {
            aspectRatio: [16 / 9],
            formats: ['video/quicktime', 'video/mp4', 'video/wmv'],
            maxSize: 100000000
        }
    },
    ott: {
        default: {
            dimensions: [
                { width: 1920, height: 1080 },
                { width: 1280, height: 720 },
                { width: 640, height: 360 }
            ],
            formats: [
                'video/quicktime',
                'video/webp',
                'video/mp4',
                'video/mpeg2'
            ]
        }
    },
    banners: {
        big: {
            dimensions: [
                { width: 300, height: 250 },
                { width: 728, height: 90 },
                { width: 160, height: 600 },
                { width: 300, height: 600 },
                { width: 970, height: 250 }
            ],
            maxSize: 200000,
            formats: ['image/gif', 'image/jpeg', 'image/jpg', 'image/png']
        },
        small: {
            dimensions: [
                { width: 320, height: 50 },
                { width: 320, height: 100 },
                { width: 320, height: 250 },
                { width: 768, height: 90 }
            ],
            maxSize: 50000,
            formats: ['image/gif', 'image/jpeg', 'image/jpg', 'image/png']
        }
    },
    instagram: {
        video: {
            aspectRatio: [16 / 9, 1 / 1],
            maxSize: 100000000,
            formats: ['video/mp4']
        },
        image: {
            dimensions: [{ width: 1080, height: 1080 }],
            maxSize: 150000,
            formats: ['image/gif', 'image/jpeg', , 'image/jpg', 'image/png']
        }
    },
    facebook: {
        video: {
            aspectRatio: [16 / 9, 1 / 1],
            maxSize: 100000000,
            formats: ['video/mp4']
        },
        image: {
            dimensions: [{ width: 1080, height: 1080 }],
            maxSize: 150000,
            formats: ['image/gif', 'image/jpeg', , 'image/jpg', 'image/png']
        }
    }
};

export const fileConfig = {
    [FILE_STATUS.PAUSED]: [RESTART_CREATIVE],
    [FILE_STATUS.ACTIVE]: [PAUSE_CREATIVE],
    [FILE_STATUS.NEW]: []
};

export const adSpecsPdf = {
    [INTERNAL]: '/public/pdf/int/Creatives_Specifications_from_Athena.pdf',
    [EXTERNAL]: '/public/pdf/ext/Creatives_Specifications_from_Athena.pdf'
};

export const POPUP_STEPS = {
    HIDDEN: 'hidden',
    PUBLISH_QUERY: 'publishQuery',
    PUBLISH_CONFIRMATION: 'publishConfirmation',
    CANCEL_QUERY: 'cancelQuery'
};
