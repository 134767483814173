import { isObject, isArray } from '.';

export const validateArg = (argument, type, order = 0) => {
    if (typeof order !== 'number')
        throw new Error(
            'if order argument is included in validation function, then it must be a number'
        );
    if (typeof type !== 'string')
        throw new Error(
            'type argument of validation function must be a string'
        );

    let error;
    switch (type) {
        case 'string':
        case 'number':
        case 'boolean':
        case 'function':
            error = typeof argument !== type;
            break;
        case 'object':
            error = !isObject(argument);
            break;
        case 'array':
            error = !isArray(argument);
            break;
        case 'element':
            error = argument?.nodeType !== Node.ELEMENT_NODE;
            break;
        default:
            throw new Error(
                'type argument must be one of the following types of variable: string, number, boolean, object, array, function'
            );
    }
    if (error)
        throw new Error(
            `Argument ${order ? order + ' ' : ''}must be type ${type}`
        );
};
