import featuresManager from '@featuresManager';
import requestHandlers from '@requestHandlers';

export default [
    {
        name: 'app.admin',
        url: '/admin',
        abstract: true,
        onEnter: (trans) => {
            requestHandlers.auth.isAdmin()
                .then(response => {
                    if (response.data) {
                        featuresManager.setFeatureState('admin');
                    } else {
                        trans.router.stateService.go('app.main.plans')
                    }
                });
        }
    }
];
