import { capitalizeFirstLetters } from '@lib';

export const getDisplayString = (name, states) => {
    return `${capitalizeFirstLetters(name)}${
        states
            ? ` (${states.map((state, i) => {
                  return `${i === 0 ? '' : ' '}${state.toUpperCase()}`;
              })})`
            : ''
    }`;
};
