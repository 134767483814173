import request from '../request';

export default function(audience, token) {
    const req = {
        method: 'POST',
        url: 'audiences/primaryTargetDemo/',
        data: audience
    };

    if (token) req.cancelToken = token;

    return request(req);
}
