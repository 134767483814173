import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { setAllNetworks } from '@state/networks/actions';
import requestHandlers from '@requestHandlers';
import { sortArrayOfObjectsByProp } from '@lib';
import { Layout } from 'maslow';
import { Network } from './Network';

export const NetworksExclusions = ({
    orderBy,
    exclusionsLimit,
    networksList
}) => {
    const dispatch = useDispatch();

    const getNetworksList = async () => {
        const networkResponse = await requestHandlers.networks.getNetworks();
        dispatch(setAllNetworks(networkResponse.data));
    };

    useEffect(() => {
        if (networksList === null) getNetworksList();
    }, []);

    const renderNetworksList = () => {
        if (networksList) {
            const descOrder = orderBy === 'DESC';
            const sortedNetworksList = sortArrayOfObjectsByProp(
                networksList,
                'Name',
                descOrder
            );
            const limit = networksList.length - exclusionsLimit;

            return (
                <div>
                    {sortedNetworksList.map(network => {
                        return (
                            <Network
                                network={network}
                                key={network.Code}
                                limit={limit}
                            />
                        );
                    })}
                </div>
            );
        } else {
            return null;
        }
    };

    return <Layout flexDirection="column">{renderNetworksList()}</Layout>;
};

NetworksExclusions.propTypes = {
    exclusionsLimit: PropTypes.number,
    orderBy: PropTypes.string
};
