import React, { useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';

import {
    VALID_FILE_TYPES,
    VALID_FILE_NAME_REGEX
} from '@config/config.creative';
import { runCreativeOperations, dedupeFileName } from '@services/creative';
import { Layout } from 'maslow';
import { fileUpload } from './FileUpload';
import { strf } from '@lib/';
import styles from './styles.scss';
import { budgetConfigSelector, formattedCreativeSelector } from '@selectors';

const Dropzone = props => {
    const dispatch = useDispatch();
    const [status, setStatus] = useState(false);
    const { startDate, endDate } = useSelector(state => state.schedule);
    const { a4PlanId } = useSelector(state => state.mediaPlan);
    const budgetConfig = useSelector(budgetConfigSelector);
    const creative = useSelector(formattedCreativeSelector);

    const _fileUpload = fileUpload(dispatch);

    const onDrop = useCallback(
        (acceptedFiles, rejectedFiles) => {
            runCreativeOperations(
                [...acceptedFiles, ...rejectedFiles],
                budgetConfig
            ).then(results => {
                const files = results.flat().map(file => {
                    const unsafeFileName = file.name;
                    const safeFileName = strf(unsafeFileName)
                        .replace(VALID_FILE_NAME_REGEX, '_')
                        .value();

                    const dedupedFileName = dedupeFileName(
                        safeFileName,
                        creative.files
                    );

                    const newFileBlob = new File(
                        [file.file],
                        `${dedupedFileName}`,
                        {
                            type: file.type
                        }
                    );

                    return {
                        ...file,
                        ...{ name: dedupedFileName },
                        ...{ file: newFileBlob }
                    };
                });

                _fileUpload(
                    files,
                    {
                        startDate,
                        endDate,
                        a4PlanId
                    },
                    setStatus
                );
            });
        },
        [creative]
    );

    const { getRootProps, getInputProps } = useDropzone({
        onDrop,
        accept: VALID_FILE_TYPES
    });

    return (
        <Layout
            flexDirection="column"
            hAlign="center"
            className={styles.wrapper}>
            <div {...getRootProps()} id="dropzone">
                <input {...getInputProps()} />

                <Layout className={styles.innerWrapper}>
                    {props.children}
                </Layout>
            </div>
        </Layout>
    );
};

export default React.memo(Dropzone);
