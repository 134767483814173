import request from '../request';

export default function(email, token, password1, password2) {
    const req = {
        method: 'POST',
        url: 'auth/password_reset/',
        data: { 
            email,
            token,
            password1,
            password2
        }
    };

    return request(req);
}
