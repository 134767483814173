import { validateArg } from '@lib';

export const formatDates = plan => {
    validateArg(plan, 'object');
    const { startDate, endDate } = plan;
    if (startDate === undefined || endDate === undefined) {
        return plan;
    }
    validateArg(startDate, 'string');
    validateArg(endDate, 'string');

    return {
        ...plan,
        startDate: startDate.replace(/T\d\d:00:00/, ''),
        endDate: endDate.replace(/T\d\d:00:00/, '')
    };
};
