import { createAction } from '@reduxjs/toolkit';
import { SET_CAMPAIGN_ROUTER_ID } from '../constants';

export const setCampaignRouterId = createAction(
    SET_CAMPAIGN_ROUTER_ID,
    value => {
        return {
            payload: { value }
        };
    }
);
