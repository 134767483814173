import React from "react";
import PropTypes from "prop-types";
import {
  Popup,
  PopupModal,
  PopupModalBody,
  PopupModalBackground
} from "./style";
import { testIdBuilder } from "../../core";
import { POPUP } from "../testIds";

/**
 * Popup component
 * */
const Component = props => {
  const {
    show,
    width,
    height,
    borderWidth,
    borderColor,
    boxShadow,
    borderRadius,
    backgroundColor,
    left,
    testNameSpace,
    id,
    ...result
  } = props;

  if (!show) return null;
  const testId = testIdBuilder(testNameSpace, POPUP);

  return (
    <Popup data-testid={testId} {...result} left={left} id={id}>
      <PopupModal left={left}>
        <PopupModalBody
          width={width}
          height={height}
          borderColor={borderColor}
          boxShadow={boxShadow}
          borderRadius={borderRadius}
          borderWidth={borderWidth}
        >
          {props.children}
        </PopupModalBody>
      </PopupModal>
      <PopupModalBackground backgroundColor={backgroundColor} left={left} />
    </Popup>
  );
};

Component.propTypes = {
  show: PropTypes.bool.isRequired,
  width: PropTypes.string,
  height: PropTypes.string,
  left: PropTypes.string,
  /** Border width of the popup */
  borderWidth: PropTypes.string,
  /** Border color of the popup */
  borderColor: PropTypes.string,
  /** Box shadow for the popup */
  boxShadow: PropTypes.string,
  /** Border radius for the popup */
  borderRadius: PropTypes.string,
  /** Background color color of the popup */
  backgroundColor: PropTypes.string,
  /** Namespace for e2e tests targeting */
  testNameSpace: PropTypes.string,
  /** Id for tracking */
  id: PropTypes.string
};

Component.defaultProps = {
  borderRadius: "7px",
  backgroundColor: "rgba(255, 255, 255, 0.6)",
  left: "0"
};

export default Component;
