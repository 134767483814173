import { LINEAR_TV } from '@config';
import { round, validateArg, decimalRound } from '@lib';
import { impressionService } from '@services';
import { mediaPlanConfig } from '@config/config.mediaPlan';

export const getTotalDigitalBudget = subCampaigns => {
    validateArg(subCampaigns, 'object');
    const subCampaignsKeys = Object.keys(subCampaigns);
    return subCampaignsKeys
        .map(key => (key !== LINEAR_TV ? round(subCampaigns[key].budget) : 0))
        .reduce((a, b) => a + b, 0);
};

export const getTruncatedCpm = (budget, impression) => {
    validateArg(budget, 'number', 1);
    validateArg(impression, 'number', 2);

    const cpm = impressionService(budget / impression);
    return parseFloat(cpm.toString().match(/^-?\d+(?:\.\d{0,4})?/)[0]);
};

export const getBudget = (impression, cpm) => {
    validateArg(impression, 'number', 1);
    validateArg(cpm, 'number', 2);
    return decimalRound((impression * cpm) / 1000, 2);
};

export const getIncrementedImpression = (
    impression,
    actualBudget,
    tempBudget,
    cpm
) => {
    validateArg(impression, 'number', 1);
    validateArg(actualBudget, 'number', 2);
    validateArg(tempBudget, 'number', 3);
    validateArg(cpm, 'number', 4);

    while (tempBudget < actualBudget) {
        impression++;
        tempBudget = getBudget(impression, cpm);
    }
    return { impression, tempBudget };
};

export const getDecrementedCpm = (
    impression,
    actualBudget,
    tempBudget,
    cpm
) => {
    validateArg(impression, 'number', 1);
    validateArg(actualBudget, 'number', 2);
    validateArg(tempBudget, 'number', 3);
    validateArg(cpm, 'number', 4);

    while (tempBudget > actualBudget && cpm > 0) {
        cpm = (cpm * 1000000 - 0.0001 * 1000000) / 1000000;
        tempBudget = getBudget(impression, cpm);
    }
    return { cpm, tempBudget };
};

export const getFinalImpAndCpm = (impression, cpm, budget) => {
    validateArg(impression, 'number', 1);
    validateArg(cpm, 'number', 2);
    validateArg(budget, 'number', 3);
    let tempBudget = getBudget(impression, cpm);
    let counter = 0;

    if (tempBudget === budget) {
        return { impression, cpm };
    }

    while (tempBudget !== budget && counter < mediaPlanConfig.counterLimit) {
        if (tempBudget < budget) {
            counter++;
            const incrementedObj = getIncrementedImpression(
                impression,
                budget,
                tempBudget,
                cpm
            );
            impression = incrementedObj.impression;
            tempBudget = incrementedObj.tempBudget;
        }

        if (tempBudget > budget) {
            counter++;
            const decrementedObj = getDecrementedCpm(
                impression,
                budget,
                tempBudget,
                cpm
            );
            cpm = decrementedObj.cpm;
            tempBudget = decrementedObj.tempBudget;
        }
    }
    return { impression, cpm };
};

export const mapDigitalDetailsForUi = (
    channels,
    subCampaigns,
    booked,
    submittedDigitalDetails
) => {
    validateArg(channels, 'object', 1);
    validateArg(subCampaigns, 'object', 2);
    validateArg(booked, 'boolean', 3);
    validateArg(submittedDigitalDetails, 'object', 4);

    const channelsKeys = Object.keys(channels);
    const digitalDetailsForUi = [];
    for (const key of channelsKeys) {
        const digitalDetailsObj = {
            name: key,
            subCampaigns: [],
            impression: 0
        };

        channels[key].subCampaigns.forEach(subChannel => {
            if (
                subChannel !== LINEAR_TV &&
                subCampaigns[subChannel].budget > 0
            ) {
                const roundedImpression = Math.round(
                    impressionService(
                        subCampaigns[subChannel].budget,
                        subCampaigns[subChannel].primary_cpm_net
                    )
                );
                const truncatedCpm = getTruncatedCpm(
                    subCampaigns[subChannel].budget,
                    roundedImpression
                );
                const { impression, cpm } = booked
                    ? submittedDigitalDetails[subChannel]
                    : getFinalImpAndCpm(
                          roundedImpression,
                          truncatedCpm,
                          subCampaigns[subChannel].budget
                      );

                const subCampaign = {
                    name: subChannel,
                    impression,
                    cpm
                };

                digitalDetailsObj.subCampaigns.push(subCampaign);
                digitalDetailsObj.impression += subCampaign.impression;
            }
        });

        digitalDetailsForUi.push(digitalDetailsObj);
    }

    return digitalDetailsForUi;
};

export const getTotalDigitalImpression = mappedDigitalDetailsForUi => {
    validateArg(mappedDigitalDetailsForUi, 'array');
    return mappedDigitalDetailsForUi
        .map(product => product.impression)
        .reduce((a, b) => a + b, 0);
};

export const getRoundedCpm = (budget, impression) => {
    validateArg(budget, 'number', 1);
    validateArg(impression, 'number', 2);
    return decimalRound(impressionService(budget / impression), 4) || 0;
};

export const getDigitalProductDetails = mappedDigitalProducts => {
    validateArg(mappedDigitalProducts, 'array');
    let digitalProductDetails = {};
    mappedDigitalProducts.forEach(channel => {
        channel.subCampaigns.forEach(({ name, impression, cpm }) => {
            digitalProductDetails[name] = {
                impression,
                cpm
            };
        });
    });

    return digitalProductDetails;
};
