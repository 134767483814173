import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import shared from '@sharedStyle/page.scss';

const Budget = props => {
    const { components } = getFeatures('budget', { props });

    return (
        <div className={shared.smallPageWrapper} style={props.fadeIn}>
            {components}
        </div>
    );
};

export default withFeatures(Budget);
