import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import featuresManager from '@featuresManager';
import styles from './styles.scss';
import { AUDIENCE_PAGE } from '@config';

class LeftSidebar extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { components } = getFeatures('leftSidebar', this, {
            page: AUDIENCE_PAGE
        });

        return <div className={styles.leftSidebar}>{components}</div>;
    }
}

export default withFeatures(LeftSidebar);
