// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".spaceAround___3LZD8 {\n  padding: 32px 32px 150px 32px; }\n\n.illustrationWrapper___33sjd {\n  min-height: 0;\n  background: url(\"/public/img/login-illustration.svg\") center center no-repeat transparent;\n  pointer-events: none; }\n\n.textWrapper___SMv7x {\n  padding-left: 24px; }\n\n.topText___NVNFc {\n  margin-top: 32px; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/common/LeftIllustrationSection/styles.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAGA;EACI,6BCDyB,EAAA;;ADI7B;EACI,aAAa;EACb,yFAAyF;EACzF,oBAAoB,EAAA;;AAGxB;EACI,kBCZyB,EAAA;;ADe7B;EACI,gBCfyB,EAAA","file":"styles.scss","sourcesContent":["@import '~@scss/settings/colors';\n@import '~@scss/settings/baseline';\n\n.spaceAround {\n    padding: $baseline-x4 $baseline-x4 150px $baseline-x4;\n}\n\n.illustrationWrapper {\n    min-height: 0;\n    background: url(\"/public/img/login-illustration.svg\") center center no-repeat transparent;\n    pointer-events: none;\n}\n\n.textWrapper {\n    padding-left: $baseline-x3;\n}\n\n.topText {\n    margin-top: $baseline-x4;\n}","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"spaceAround": "spaceAround___3LZD8",
	"illustrationWrapper": "illustrationWrapper___33sjd",
	"textWrapper": "textWrapper___SMv7x",
	"topText": "topText___NVNFc"
};
module.exports = exports;
