import { ruleMapToStateProps } from './createMapForMapStateToProps';

export const getMapStateToProps = (validationRules = []) => {
    if (!validationRules.length) return undefined;

    return state => {
        const isInvalid = validationRules.map(validationRule => {
            return ruleMapToStateProps.hasOwnProperty(validationRule)
                ? ruleMapToStateProps[validationRule](state)
                : false;
        }).includes(false);

        return { isInvalid };
    };
};
