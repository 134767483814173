export default {
    name: 'NetworksSummarySelections',
    description: 'Networks Summary Selections Cards.',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan'],
    sections: ['leftSidebarNetworks'],
    permissions: [],
    data: {},
    attributes: {},
    modifications: {}
};
