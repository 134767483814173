import {
    Audience,
    AudienceSelection,
    CustomList,
    Audiences,
    Combinations
} from '../components/pages';
import { LeftSidebar, LeftSidebarCombinations } from '../components/layouts';

import { store } from '@state';
import { setAudienceConfig } from '@state/audience/actions/audienceConfig';
import requestHandlers from '../requestHandlers';
import featuresManager from '@featuresManager';
import { slugBuilder } from '@services';
import { UPLOAD_STEPS } from '@config/config.customList';
import { resetCustomList } from '@state/customList/actions';

export default [
    {
        name: 'app.plan.audience',
        url: '/audience',
        views: {
            'content@app': {
                component: Audience
            }
        },
        redirectTo: () => {
            const state = store.getState();
            let sectionName = '';
            if (state.audience.audienceConfig.length) {
                sectionName = slugBuilder(
                    state.audience.audienceConfig[0].name
                );
            }
            return state.audience.audienceType === ''
                ? {}
                : {
                      state: 'app.plan.audience.builder.section',
                      params: { sectionName }
                  };
        },
        resolve: [
            {
                token: 'audienceConfig',
                resolveFn: () => {
                    const state = store.getState();
                    const [role] = featuresManager.getCustomFilterState('role');

                    return requestHandlers.households
                        .audience(role)
                        .then(response => {
                            const result =
                                response.data.response.audience_segments;
                            store.dispatch(setAudienceConfig(result));
                            return result;
                        });
                }
            }
        ]
    },
    {
        name: 'app.plan.audience.builder',
        url: '/builder',
        abstract: true
    },
    {
        name: 'app.plan.audience.builder.section',
        url: '/:sectionName',
        views: {
            'leftSidebar@app': {
                component: LeftSidebar
            },
            'content@app': {
                component: AudienceSelection
            }
        },
        redirectTo: () => {
            const state = store.getState();

            return state.audience.audienceType === ''
                ? { state: 'app.plan.audience' }
                : {};
        }
    },
    {
        name: 'app.plan.audience.builder.combinations',
        url: '/combinations',
        views: {
            'leftSidebar@app': {
                component: LeftSidebarCombinations
            },
            'content@app': {
                component: Combinations
            }
        }
    },
    {
        name: 'app.plan.audience.audiences',
        url: '/audiences',
        views: {
            'leftSidebar@app': {
                component: LeftSidebar
            },
            'content@app': {
                component: Audiences
            }
        },
        redirectTo: () => {
            const state = store.getState();

            return state.audience.audienceType === ''
                ? { state: 'app.plan.audience' }
                : {};
        }
    },
    {
        name: 'app.ranker.audience',
        url: '/audience',
        views: {
            'content@app': {
                component: Audience
            }
        },
        redirectTo: () => {
            const state = store.getState();
            let sectionName = '';
            if (state.audience.audienceConfig.length) {
                sectionName = slugBuilder(
                    state.audience.audienceConfig[0].name
                );
            }
            return state.audience.audienceType === ''
                ? {}
                : {
                      state: 'app.ranker.audience.builder.section',
                      params: { sectionName }
                  };
        },
        resolve: [
            {
                token: 'audienceConfig',
                resolveFn: () => {
                    const state = store.getState();
                    const [role] = featuresManager.getCustomFilterState('role');

                    return requestHandlers.households
                        .audience(role)
                        .then(response => {
                            const result =
                                response.data.response.audience_segments;
                            store.dispatch(setAudienceConfig(result));
                            return result;
                        });
                }
            }
        ]
    },
    {
        name: 'app.ranker.audience.builder',
        url: '/builder',
        abstract: true
    },
    {
        name: 'app.ranker.audience.builder.section',
        url: '/:sectionName',
        views: {
            'leftSidebar@app': {
                component: LeftSidebar
            },
            'content@app': {
                component: AudienceSelection
            }
        },
        redirectTo: () => {
            const state = store.getState();

            return state.audience.audienceType === ''
                ? { state: 'app.ranker.audience' }
                : {};
        }
    },
    {
        name: 'app.ranker.audience.builder.combinations',
        url: '/combinations',
        views: {
            'leftSidebar@app': {
                component: LeftSidebarCombinations
            },
            'content@app': {
                component: Combinations
            }
        }
    },
    {
        name: 'app.ranker.audience.custom',
        url: '/custom',
        views: {
            'leftSidebar@app': {
                component: LeftSidebar
            },
            'content@app': {
                component: CustomList
            }
        },
        onEnter: () => {
            const state = store.getState();
            const { customList } = state;
            if (customList.uploadStep === UPLOAD_STEPS.NEXT_STEPS)
                store.dispatch(resetCustomList());
        }
    },
    {
        name: 'app.ranker.audience.audiences',
        url: '/audiences',
        views: {
            'leftSidebar@app': {
                component: LeftSidebar
            },
            'content@app': {
                component: Audiences
            }
        },
        redirectTo: () => {
            const state = store.getState();

            return state.audience.audienceType === ''
                ? { state: 'app.ranker.audience' }
                : {};
        }
    }
];
