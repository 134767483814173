import React, { useEffect } from 'react';
import { Content, Text, Layout, Icon } from 'maslow';
import { useDispatch, useSelector } from 'react-redux';

import { LOCAL, NATIONAL } from '@config';
import featuresManager from '@featuresManager';
import EmptySelection from '../EmptySelection';
import SelectedLocationCategory from '../SelectedLocationCategory';
import { updateRegion } from '@state/plan/actions/attributes';
import { updateBudget } from '@state/budget/actions/';
import { removeLocations } from '@state/location/actions/';
import { fetchHouseholdCount } from '@state/audience/actions/households';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import {
    selectedLocationsSelector,
    regionSelector,
    hasDigitalCpmConfigSelector,
    hasImpressionModifierConfigSelector,
    scheduleSelector
} from '@selectors/';
import { getDigitalCpmConfig } from '@services/budget';
import { getImpressionModifierConfig } from '@services/budget';

import styles from '../styles.scss';
import closeIconSvg from '@public/img/close.svg';
import { LOCATIONS_REMOVE_LOCATIONS } from '@config/idConstants';
import { PLAN_STATUSES } from '@config/config.planStatus';

const Summary = () => {
    const dispatch = useDispatch();
    const [role] = featuresManager.getCustomFilterState('role');
    const [type] = featuresManager.getCustomFilterState('type');
    const hasImpressionModifierConfig = useSelector(
        hasImpressionModifierConfigSelector
    );
    const { startDate, endDate, duration } = useSelector(scheduleSelector);
    const hasDigitalCpmConfig = useSelector(hasDigitalCpmConfigSelector);
    const selectedLocations = useSelector(selectedLocationsSelector);
    const region = useSelector(regionSelector);

    const hasSelections = selectedLocations.length > 0;
    const newRegion = hasSelections ? LOCAL : NATIONAL;

    const onClear = () => {
        dispatch(removeLocations());
        dispatch(fetchHouseholdCount());
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    useEffect(() => {
        if (newRegion !== region) {
            featuresManager.setCustomFilterState('region', [newRegion]);
            dispatch(updateRegion(newRegion));
            dispatch(updateBudget.resetBudget());
        }
        return () => {
            if (newRegion !== region || !hasDigitalCpmConfig) {
                getDigitalCpmConfig(type, role, newRegion)
                    .then(data => {
                        dispatch(updateBudget.saveDigitalCpmConfig(data));
                    })
                    .catch(console.error);
            }
            if (
                startDate &&
                endDate &&
                newRegion !== region &&
                hasImpressionModifierConfig
            ) {
                getImpressionModifierConfig(role, newRegion, duration)
                    .then(data => {
                        dispatch(
                            updateBudget.saveImpressionModifierConfig(data)
                        );
                    })
                    .catch(console.error);
            }
        };
    }, [selectedLocations]);

    return (
        <Content>
            {!hasSelections ? <EmptySelection /> : null}
            {hasSelections ? (
                <Layout flexDirection="column">
                    <Layout flexDirection="row" hAlign="space-between">
                        <Text type="h5">LOCATIONS</Text>
                        <Layout
                            flexDirection="row"
                            hAlign="center"
                            onClick={onClear}
                            className={styles.clearTextWrapper}>
                            <Text
                                className={styles.clearText}
                                id={LOCATIONS_REMOVE_LOCATIONS}>
                                Clear
                            </Text>
                            <Icon
                                src={closeIconSvg}
                                className={styles.clearAllIcon}
                                size={1}
                            />
                        </Layout>
                    </Layout>

                    <SelectedLocationCategory
                        selectedLocations={selectedLocations}
                    />
                </Layout>
            ) : null}
        </Content>
    );
};

export default Summary;
