export default (val, decimals) => {
    if (val === undefined || val === null) {
        val = 0;
    }

    if (decimals === true) {
        return parseFloat(val.toFixed(2));
    }

    return Math.round(parseFloat(val.toFixed(2)));
};
