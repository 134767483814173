import { createReducer } from '@reduxjs/toolkit';
import {
    ADD_EXCLUDED_NETWORK,
    REMOVE_EXCLUDED_NETWORK,
    REMOVE_ALL_EXCLUDED_NETWORKS
} from '../constants';

const initialState = [];

const addExcludedNetwork = (state, action) => {
    const { excludedNetwork } = action.payload;
    return [...state, excludedNetwork];
};

const removeExcludedNetwork = (state, action) => {
    const { value } = action.payload;

    return state.filter(excludedNetwork => excludedNetwork.A4mediaId !== value);
};
const removeAllExcludedNetworks = () => {
    return initialState;
};

export const excludedNetworks = createReducer(initialState, reducer => {
    reducer
        .addCase(ADD_EXCLUDED_NETWORK, addExcludedNetwork)
        .addCase(REMOVE_EXCLUDED_NETWORK, removeExcludedNetwork)
        .addCase(REMOVE_ALL_EXCLUDED_NETWORKS, removeAllExcludedNetworks)
        .addDefaultCase((state, _) => state);
});
