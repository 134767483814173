import { createAction } from '@reduxjs/toolkit';

import { SET_IS_EDIT_CREATIVES } from '../constants';

export const setIsEditCreatives = createAction(
    SET_IS_EDIT_CREATIVES,
    isEditCreatives => {
        return { payload: { isEditCreatives } };
    }
);
