import request from '../request';

export default function (email, externalId) {
    return request({
        method: 'POST',
        url: 'auth/athena_user/',
        data: {
            email: email.toLowerCase(),
            externalId
        }
    }).then(response => {
        return response;
    }).catch(error => {
        throw new Error(error);
    });
};