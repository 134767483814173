import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { Text } from 'maslow';
import styles from './styles.scss';

import { planGeneratedSelector, hasPermissionSelector } from '@selectors';
import { CAN_NOT_BOOK } from '@config';

export default function (version, feature) {
    const { generating, generated } = feature.data.messages;

    const Component = () => {
        const [message, setMessage] = useState(generating);
        const isBooked = useSelector(state => state.plan.attributes.booked);
        const planGenerated = useSelector(planGeneratedSelector);
        const canBook = !useSelector(hasPermissionSelector(CAN_NOT_BOOK));
        const textStyle = planGenerated ? styles.savedText : styles.savingText;

        useEffect(() => {
            if (planGenerated) setMessage(generated);
            else setMessage(generating);
        }, [planGenerated]);

        return (
            <React.Fragment>
                {!isBooked && canBook && (
                    <div className={styles.footer}>
                        <Text type="h5" className={textStyle}>
                            {message}
                        </Text>
                    </div>
                )}
            </React.Fragment>
        );
    };

    return Component;
}
