import { createAction } from '@reduxjs/toolkit';
import { SET_DURATION } from '../constants';
import { datesHandler } from '@lib';

export const updateDuration = () => {
    return (dispatch, getState) => {
        const {
            schedule: { hiatusWeeks, startDate, endDate }
        } = getState();

        if (!startDate || !endDate) return;

        const campaignDuration =
            datesHandler.differenceInDays(endDate, startDate) + 1;

        const duration = hiatusWeeks.length
            ? parseInt(campaignDuration) - hiatusWeeks.length * 7
            : parseInt(campaignDuration);

        dispatch(setDuration(duration));
    };
};

export const setDuration = createAction(SET_DURATION, duration => {
    return {
        payload: { duration }
    };
});
