import React from 'react';
import { UISref } from '@uirouter/react';
import { Layout, Button } from 'maslow';
import { validateElementInteraction } from '@components/common';
import { planNav } from '@config/config.planNav';

import { MEDIA_PLAN_BOOK } from '@config/idConstants';

import styles from '../styles.scss';

export const BookButton = props => {
    const results = planNav.planNavItems.find(item => item.text === 'Book');

    const ValidateElementInteraction = validateElementInteraction(
        results.validationRules
    );

    return (
        <React.Fragment>
            <ValidateElementInteraction>
                <UISref to={`app.plan.book`}>
                    <Layout
                        flex
                        className={styles.buttonContainer}
                        flexDirection="column">
                        <Button id={MEDIA_PLAN_BOOK} theme="green">
                            {'Book Plan'}
                        </Button>
                    </Layout>
                </UISref>
            </ValidateElementInteraction>
        </React.Fragment>
    );
};
