import {
    LINEAR_TV,
    PRE_MID_ROLL,
    OTT,
    BANNERS,
    FACEBOOK,
    INSTAGRAM,
    AD_MESSENGER
} from '@config';

export const creativeStub = [
    {
        name: 'fun_image.jpg',
        type: 'image',
        mimeType: 'image/jpg',
        src: '',
        size: 20,
        width: 50,
        height: 100,
        errors: [],
        aspectRatio: 0,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        errors: [],
        height: 250,
        mimeType: 'image/png',
        name: 'incorrect_image_format.png',
        size: 5000000000,
        src: '',
        type: 'image',
        width: 300,
        aspectRatio: 0,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'incorrect_image_format_2.png',
        type: 'image',
        mimeType: 'image/png',
        src: '',
        size: 500,
        width: 110,
        height: 145,
        errors: [],
        aspectRatio: 0,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'successful_image_format_1.gif',
        type: 'image',
        mimeType: 'image/gif',
        src: '',
        size: 300,
        width: 250,
        height: 325,
        errors: [],
        aspectRatio: 0,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'fail_video_format_1.flv',
        type: 'video',
        mimeType: 'video/x-flv',
        src: '',
        size: 40000,
        width: 0,
        height: 0,
        errors: [],
        aspectRatio: 0,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'successful_image_format_2.gif',
        type: 'image',
        mimeType: 'image/gif',
        src: '',
        size: 40,
        width: 728,
        height: 90,
        errors: [],
        aspectRatio: 0,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'successful_video_format_1.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 40,
        width: 2000,
        height: 2000,
        errors: [],
        aspectRatio: 2,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'successful_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 150,
        width: 1920,
        height: 1080,
        errors: [],
        aspectRatio: 1.7777777777777777,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'fail_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 150,
        width: 1000,
        height: 2000,
        errors: [],
        aspectRatio: 2,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'invalid_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 150,
        width: 1000,
        height: 2000,
        errors: ['fileAspectRatioNotAcceptable', 'fileDimensionsNotAcceptable'],
        aspectRatio: undefined,
        subCampaigns: []
    },
    {
        name: 'successful_portrait_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 400000,
        width: 1920,
        height: 1080,
        errors: [],
        aspectRatio: 9 / 16,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'successful_1by1_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 600000,
        width: 1080,
        height: 1080,
        errors: [],
        aspectRatio: 1 / 1,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    }
];

export const creativeStubExpectedResults = [
    {
        aspectRatio: 0,
        errors: ['fileDimensionsNotAcceptable'],
        height: 100,
        mimeType: 'image/jpg',
        name: 'fun_image.jpg',
        size: 20,
        src: '',
        type: 'image',
        width: 50,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        aspectRatio: 0,
        errors: ['fileSizeNotAcceptable'],
        height: 250,
        mimeType: 'image/png',
        name: 'incorrect_image_format.png',
        size: 5000000000,
        src: '',
        type: 'image',
        width: 300,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        aspectRatio: 0,
        errors: ['fileDimensionsNotAcceptable'],
        height: 145,
        mimeType: 'image/png',
        name: 'incorrect_image_format_2.png',
        size: 500,
        src: '',
        type: 'image',
        width: 110,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        aspectRatio: 0,
        errors: ['fileDimensionsNotAcceptable'],
        height: 325,
        mimeType: 'image/gif',
        name: 'successful_image_format_1.gif',
        size: 300,
        src: '',
        type: 'image',
        width: 250,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        aspectRatio: 0,
        errors: [
            'fileAspectRatioNotAcceptable',
            'fileDimensionsNotAcceptable',
            'fileFormatNotAcceptable'
        ],
        height: 0,
        mimeType: 'video/x-flv',
        name: 'fail_video_format_1.flv',
        size: 40000,
        src: '',
        type: 'video',
        width: 0,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        aspectRatio: 0,
        errors: [],
        height: 90,
        mimeType: 'image/gif',
        name: 'successful_image_format_2.gif',
        size: 40,
        src: '',
        type: 'image',
        width: 728,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        errors: ['fileAspectRatioNotAcceptable', 'fileDimensionsNotAcceptable'],
        mimeType: 'video/mp4',
        name: 'successful_video_format_1.mp4',
        size: 40,
        src: '',
        type: 'video',
        height: 2000,
        width: 2000,
        aspectRatio: 2,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'successful_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 150,
        width: 1920,
        height: 1080,
        errors: [],
        aspectRatio: 16 / 9,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'fail_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 150,
        width: 1000,
        height: 2000,
        errors: ['fileAspectRatioNotAcceptable', 'fileDimensionsNotAcceptable'],
        aspectRatio: 2,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'invalid_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 150,
        width: 1000,
        height: 2000,
        errors: ['fileAspectRatioNotAcceptable', 'fileDimensionsNotAcceptable'],
        aspectRatio: undefined,
        subCampaigns: []
    },
    {
        name: 'successful_portrait_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 400000,
        width: 1920,
        height: 1080,
        errors: [],
        aspectRatio: 9 / 16,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    },
    {
        name: 'successful_1by1_aspect_ratio.mp4',
        type: 'video',
        mimeType: 'video/mp4',
        src: '',
        size: 600000,
        width: 1080,
        height: 1080,
        errors: [],
        aspectRatio: 1 / 1,
        subCampaigns: [
            LINEAR_TV,
            PRE_MID_ROLL,
            OTT,
            BANNERS,
            FACEBOOK,
            INSTAGRAM,
            AD_MESSENGER
        ]
    }
];

export const invalidFileTypes = [
    'image/bmp',
    'image/svg+xml',
    'video/ogg',
    'video/x-flv',
    'audio/aac',
    'text/css',
    'text/html',
    'text/csv',
    'text/javascript',
    'text/plain'
];

export const fileTypesExpectedResults = [
    'image',
    'image',
    'image',
    'video',
    'video',
    'video',
    'audio',
    'text',
    'text',
    'text',
    'text',
    'text'
];

export const disclaimerStub = {
    language: 'en',
    textAngle: 0.0,
    orientation: 'NotDetected',
    regions: [
        {
            boundingBox: '13,10,140,18',
            lines: [
                {
                    boundingBox: '13,10,140,18',
                    words: [
                        {
                            boundingBox: '13,11,27,17',
                            text: 'TV'
                        },
                        {
                            boundingBox: '46,10,31,18',
                            text: 'Ad:'
                        },
                        {
                            boundingBox: '85,10,68,18',
                            text: '"Fraud"'
                        }
                    ]
                }
            ]
        },
        {
            boundingBox: '38,1067,53,65',
            lines: [
                {
                    boundingBox: '61,1067,30,13',
                    words: [
                        {
                            boundingBox: '61,1067,30,13',
                            text: '0:00'
                        }
                    ]
                },
                {
                    boundingBox: '38,1112,16,20',
                    words: [
                        {
                            boundingBox: '38,1112,16,20',
                            text: 'Il'
                        }
                    ]
                }
            ]
        },
        {
            boundingBox: '127,159,1486,973',
            lines: [
                {
                    boundingBox: '448,159,511,132',
                    words: [
                        {
                            boundingBox: '448,159,511,132',
                            text: 'TRUMP'
                        }
                    ]
                },
                {
                    boundingBox: '457,310,500,56',
                    words: [
                        {
                            boundingBox: '457,310,219,56',
                            text: 'FOOLED'
                        },
                        {
                            boundingBox: '701,310,74,56',
                            text: 'US'
                        },
                        {
                            boundingBox: '800,310,157,56',
                            text: 'ONCE'
                        }
                    ]
                },
                {
                    boundingBox: '533,392,352,76',
                    words: [
                        {
                            boundingBox: '533,392,215,76',
                            text: 'WILL'
                        },
                        {
                            boundingBox: '780,392,105,76',
                            text: 'HE'
                        }
                    ]
                },
                {
                    boundingBox: '451,485,514,155',
                    words: [
                        {
                            boundingBox: '451,485,514,155',
                            text: 'SCAM'
                        }
                    ]
                },
                {
                    boundingBox: '455,653,513,55',
                    words: [
                        {
                            boundingBox: '455,653,259,55',
                            text: 'FLORIDA'
                        },
                        {
                            boundingBox: '734,653,234,55',
                            text: 'AGAIN?'
                        }
                    ]
                },
                {
                    boundingBox: '453,766,503,54',
                    words: [
                        {
                            boundingBox: '453,766,503,54',
                            text: 'Trumpauestions.com'
                        }
                    ]
                },
                {
                    boundingBox: '427,885,1008,74',
                    words: [
                        {
                            boundingBox: '427,899,50,60',
                            text: 'OUR'
                        },
                        {
                            boundingBox: '489,900,39,58',
                            text: 'PRI'
                        },
                        {
                            boundingBox: '533,900,14,58',
                            text: 'N'
                        },
                        {
                            boundingBox: '552,899,13,60',
                            text: 'c'
                        },
                        {
                            boundingBox: '568,900,6,58',
                            text: 'I'
                        },
                        {
                            boundingBox: '578,899,56,60',
                            text: 'PIES'
                        },
                        {
                            boundingBox: '645,899,46,60',
                            text: 'PAC'
                        },
                        {
                            boundingBox: '702,900,55,58',
                            text: 'PAID'
                        },
                        {
                            boundingBox: '770,899,45,60',
                            text: 'FOR'
                        },
                        {
                            boundingBox: '826,900,52,58',
                            text: 'AND'
                        },
                        {
                            boundingBox: '890,899,22,60',
                            text: 'IS'
                        },
                        {
                            boundingBox: '924,900,29,58',
                            text: 'RE'
                        },
                        {
                            boundingBox: '956,899,13,60',
                            text: 's'
                        },
                        {
                            boundingBox: '973,900,12,58',
                            text: 'P'
                        },
                        {
                            boundingBox: '988,899,14,60',
                            text: 'o'
                        },
                        {
                            boundingBox: '1105,899,45,60',
                            text: 'FOR'
                        },
                        {
                            boundingBox: '1161,900,45,58',
                            text: 'THE'
                        },
                        {
                            boundingBox: '1217,899,31,60',
                            text: 'CO'
                        },
                        {
                            boundingBox: '1340,899,14,60',
                            text: 'o'
                        },
                        {
                            boundingBox: '1359,900,11,58',
                            text: 'F'
                        },
                        {
                            boundingBox: '1378,885,57,74',
                            text: 'THIS'
                        }
                    ]
                },
                {
                    boundingBox: '434,965,1179,59',
                    words: [
                        {
                            boundingBox: '434,965,49,59',
                            text: 'NOT'
                        },
                        {
                            boundingBox: '491,965,157,59',
                            text: 'AUTHORIZED'
                        },
                        {
                            boundingBox: '661,965,30,59',
                            text: 'BY'
                        },
                        {
                            boundingBox: '699,965,52,59',
                            text: 'ANY'
                        },
                        {
                            boundingBox: '761,965,140,59',
                            text: 'CANDIDATE'
                        },
                        {
                            boundingBox: '912,965,32,59',
                            text: 'OR'
                        },
                        {
                            boundingBox: '1130,965,483,59',
                            text: 'COMMITTEE.OURPRINCIPIESPAC.COM'
                        }
                    ]
                },
                {
                    boundingBox: '127,1113,113,19',
                    words: [
                        {
                            boundingBox: '127,1113,19,19',
                            text: '0'
                        },
                        {
                            boundingBox: '164,1115,30,13',
                            text: '0:27'
                        },
                        {
                            boundingBox: '199,1115,6,14',
                            text: '/'
                        },
                        {
                            boundingBox: '210,1115,30,13',
                            text: '0:32'
                        }
                    ]
                }
            ]
        }
    ]
};
