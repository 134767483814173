import React from 'react';
import PropTypes from 'prop-types';
import { UISref } from '@uirouter/react';

import { Layout, Text, Icon } from 'maslow';
import viewPlanSvg from '@public/img/view-plan.svg';

import style from '../styles.scss';

import { idBuilder } from '@services';
import { PLANS_VIEW_PLAN } from '@config/idConstants';

const ViewPlan = ({ route, id }) => {
    return (
        <UISref to={route} params={{ planId: id }}>
            <Layout
                id={idBuilder(PLANS_VIEW_PLAN, id)}
                flexDirection="row"
                hAlign="center"
                vAlign="center"
                className={style.viewPlanWrapper}>
                <Icon src={viewPlanSvg} className={style.bottomSectionIcon} />
                <Text color="vividNavy" className={style.bottomSectionLink}>
                    View Plan
                </Text>
            </Layout>
        </UISref>
    );
};

ViewPlan.propTypes = {
    route: PropTypes.string.isRequired,
    id: PropTypes.number.isRequired
};

export default ViewPlan;
