import { createSelector } from 'reselect';

import featuresManager from '@featuresManager/';
import { isMinSatisfied, strf, existsInArray, isEmpty } from '@lib';
import { budgetConfig } from '@config/config.budget';
import {
    getChannelKey,
    getSubCampaign,
    getErrorText,
    getMaxBudget,
    getBudgetConfig,
    getStatus,
    getSubsWithBudget,
    getContextFromKey,
    getLockedSubCampaigns,
    isDisableSubCampaign,
    isAnyChildInErrorState,
    getLockedChannels,
    getMergedChannelKeys,
    getSubKey,
    getMaxBudgets
} from '@services/budget';

import { regionSelector } from '../plan';
import { durationSelector } from '../schedule';
import { hhCountSelector } from '../audience';
import { userSelector } from '../user';
import { ACTUAL_USER, TESTER } from '@config';

export const budgetSelector = createSelector(
    state => state.budget,
    budget => budget
);

export const isAutoDistributedSelector = createSelector(
    budgetSelector,
    ({ isAutoDistributed }) => isAutoDistributed
);

export const totalSelector = createSelector(
    budgetSelector,
    ({ total }) => total
);

export const totalFormattedSelector = createSelector(totalSelector, total =>
    strf(total).commas().prepend('$').value()
);

export const subCampaignSelector = createSelector(
    budgetSelector,
    ({ subCampaigns }) => subCampaigns
);

export const subsWithBudgetSelector = createSelector(
    subCampaignSelector,
    subCampaigns => getSubsWithBudget(subCampaigns)
);

export const getUserTypeSelector = createSelector(
    userSelector,
    ({ account }) => {
        const { email } = account;
        return budgetConfig.noThresholdEmail.includes(email)
            ? TESTER
            : ACTUAL_USER;
    }
);

export const channelDisabledSelector = createSelector(
    totalSelector,
    regionSelector,
    getUserTypeSelector,
    (total, region, userType) => {
        const [role] = featuresManager.getCustomFilterState('role');
        return !isMinSatisfied(
            total,
            budgetConfig.limits.min[userType][role][region]
        );
    }
);

export const createSectionSelector = {
    channel: key => {
        return createSelector(budgetSelector, ({ channels }) => channels[key]);
    },
    subCampaign: key => {
        return createSelector(
            budgetSelector,
            ({ subCampaigns }) => subCampaigns[key]
        );
    }
};

export const createPercentSelector = {
    channel: key => {
        return createSelector(
            budgetSelector,
            ({ channels, total }) =>
                Math.round((channels[key] / total) * 100) || 0
        );
    },
    subCampaign: key => {
        return createSelector(budgetSelector, ({ subCampaigns, channels }) => {
            const channelKey = getChannelKey(
                key,
                budgetConfig.distribution.subCampaigns
            );
            return (
                Math.round((subCampaigns[key] / channels[channelKey]) * 100) ||
                0
            );
        });
    }
};

export const createMaxBudgetSelector = key => {
    const [role] = featuresManager.getCustomFilterState('role');
    return createSelector(
        hhCountSelector,
        durationSelector,
        regionSelector,
        getUserTypeSelector,
        budgetSelector,
        (
            hhCountValue,
            duration,
            region,
            userType,
            { digitalCpmConfig, impressionModifierConfig }
        ) => {
            return getMaxBudget({
                hhCountValue,
                duration,
                impressionModifier: impressionModifierConfig[key],
                primary_cpm_net: digitalCpmConfig[key] || 0,
                region,
                role,
                userType
            });
        }
    );
};

export const createStatusSelector = {
    total: () =>
        createSelector(
            totalSelector,
            regionSelector,
            getUserTypeSelector,
            (total, region, userType) => {
                const [role] = featuresManager.getCustomFilterState('role');
                return getStatus(
                    total,
                    budgetConfig.limits.min[userType][role][region]
                );
            }
        ),
    subCampaign: key => {
        return createSelector(
            budgetSelector,
            regionSelector,
            createMaxBudgetSelector(key),
            getUserTypeSelector,
            ({ subCampaigns, channels }, region, maxBudget, userType) => {
                const channelKey = getChannelKey(
                    key,
                    budgetConfig.distribution.subCampaigns
                );
                const sub = getSubCampaign(key);

                return getStatus(
                    subCampaigns[key],
                    sub.min[userType][region],
                    true,
                    channels[channelKey],
                    maxBudget
                );
            }
        );
    }
};

export const createErrorTextSelector = (key, config) => {
    return createSelector(
        regionSelector,
        budgetSelector,
        createMaxBudgetSelector(key),
        getUserTypeSelector,
        (region, { subCampaigns }, maxBudget, userType) => {
            const { min, minErrorText } = config;

            return getErrorText({
                subCampaigns,
                key,
                min,
                region,
                minErrorText,
                maxBudget,
                userType
            });
        }
    );
};

export const budgetConfigSelector = createSelector(
    budgetSelector,
    regionSelector,
    hhCountSelector,
    durationSelector,
    getUserTypeSelector,
    state => state.mediaPlan,
    (budget, region, hhCountValue, duration, userType, mediaPlan) => {
        const [role] = featuresManager.getCustomFilterState('role');
        return getBudgetConfig({
            role,
            budget,
            region,
            hhCountValue,
            duration,
            userType,
            linearTvCpm: mediaPlan?.tvDetails?.primaryTarget?.cpm
        });
    }
);

export const getSCWithBudgetByNameSelector = key => {
    return createSelector(budgetSelector, ({ subCampaigns }) => {
        return Object.entries(subCampaigns)
            .filter(([scName, value]) => {
                return value > 0;
            })
            .every(product => product[0].toLowerCase() === key.toLowerCase());
    });
};

export const getBudgetByProduct = name => {
    return createSelector(
        budgetSelector,
        ({ subCampaigns }) => subCampaigns[getSubKey(name)]
    );
};

export const getAmountBasedOnTotalSelector = value => {
    return createSelector(
        totalSelector,
        regionSelector,
        getUserTypeSelector,
        (total, region, userType) => {
            const [role] = featuresManager.getCustomFilterState('role');
            return total < budgetConfig.limits.min[userType][role][region]
                ? 0
                : value;
        }
    );
};

export const singleChannelDisabledSelector = (disabledChannelsKeys, key) => {
    return createSelector(
        budgetSelector,
        getLockedChannelsSelector,
        lockedChannelStatusSelector(key),
        ({ channels }, lockedChannels, isLockedStatus) => {
            const newLockedChannels = getMergedChannelKeys(
                lockedChannels,
                disabledChannelsKeys
            );

            return (
                (newLockedChannels &&
                    newLockedChannels.length ===
                        Object.keys(channels).length - 1) ||
                isLockedStatus
            );
        }
    );
};

export const getLockedSubCampaignsSelector = createSelector(
    budgetConfigSelector,
    ({ subCampaigns }) => getLockedSubCampaigns(subCampaigns)
);

export const lockedSubStatusSelector = key => {
    return createSelector(getLockedSubCampaignsSelector, lockedSubCampaigns =>
        existsInArray(key, lockedSubCampaigns)
    );
};

export const subCampaignDisabledSelector = (subCampaigns, key) => {
    return createSelector(
        createSectionSelector.channel(key),
        getLockedSubCampaignsSelector,
        (channelBudget, lockedSubCampaigns) =>
            isDisableSubCampaign(
                subCampaigns,
                lockedSubCampaigns,
                channelBudget
            )
    );
};

export const childErrorSelector = name => {
    return createSelector(budgetConfigSelector, ({ channels, subCampaigns }) =>
        isAnyChildInErrorState(name, channels, subCampaigns)
    );
};

export const hasLockedSubsBudgetSelector = createSelector(
    budgetSelector,
    getLockedSubCampaignsSelector,
    ({ subCampaigns }, lockedSubCampaigns) =>
        lockedSubCampaigns.some(key => subCampaigns[key] > 0)
);

export const getLockedChannelsSelector = createSelector(
    budgetConfigSelector,
    budgetSelector,
    ({ channels, subCampaigns }, budget) => {
        const sections = budget.channels;
        const subs = budget.subCampaigns;
        return getLockedChannels(channels, subCampaigns, sections, subs);
    }
);

export const lockedChannelStatusSelector = key => {
    return createSelector(getLockedChannelsSelector, lockedChannels =>
        existsInArray(key, lockedChannels)
    );
};

export const hasLockedChannelsBudgetSelector = createSelector(
    budgetSelector,
    getLockedChannelsSelector,
    ({ channels }, lockedChannels) =>
        lockedChannels.some(key => channels[key] > 0)
);

export const hasDistributionConfigSelector = createSelector(
    budgetSelector,
    ({ distributionConfig }) => !isEmpty(distributionConfig)
);

export const hasDigitalCpmConfigSelector = createSelector(
    budgetSelector,
    ({ digitalCpmConfig }) => !isEmpty(digitalCpmConfig)
);

export const hasImpressionModifierConfigSelector = createSelector(
    budgetSelector,
    ({ impressionModifierConfig }) => !isEmpty(impressionModifierConfig)
);

export const allMaxBudgetsSelector = createSelector(
    budgetSelector,
    budgetConfigSelector,
    (budget, config) => getMaxBudgets(budget, config)
);
