import audienceBuilderSelectorSvg from '@public/img/audience-builder-selector.svg';
import customListSelectorSvg from '@public/img/custom-list-selector.svg';
import myAudienceSelectorSvg from '@public/img/my-audience-selector.svg';
import audienceRoutes from '@config/config.audienceRoutes';

export default {
    name: 'AudienceTypeOptions', // Component name
    description: 'Audience type options, 3 icons to redefine your audience',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan', 'ranker'],
    sections: ['rightSidebar', 'leftSidebar'],
    permissions: [],
    data: {
        miniNavItems: [
            {
                text: 'Build',
                icon: audienceBuilderSelectorSvg,
                to: audienceRoutes.builder,
                type: 'builder'
            },
            {
                text: 'Saved Audiences',
                icon: myAudienceSelectorSvg,
                to: audienceRoutes.audiences,
                type: 'audiences'
            },
            {
                text: 'Upload',
                icon: customListSelectorSvg,
                to: audienceRoutes.custom,
                type: 'custom'
            }
        ]
    },
    attributes: {},
    modifications: {}
};
