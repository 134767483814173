import { createReducer } from '@reduxjs/toolkit';
import { UPDATE_CHANNEL, UPDATE_TOTAL, RESET_BUDGET } from '../constants';

const initialState = {};

const updateTotal = (state, action) => {
    const {
        distribution: { channels }
    } = action.payload;
    return typeof channels === 'object' ? { ...state, ...channels } : state;
};

const updateChannel = (state, action) => {
    const {
        distribution: { channels }
    } = action.payload;
    return typeof channels === 'object' ? { ...state, ...channels } : state;
};

const resetBudget = () => {
    return initialState;
};

export const channels = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_TOTAL, updateTotal)
        .addCase(UPDATE_CHANNEL, updateChannel)
        .addCase(RESET_BUDGET, resetBudget)
        .addDefaultCase((state, _) => state);
});
