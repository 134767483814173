import { createReducer } from '@reduxjs/toolkit';
import { SET_STATES } from '../constants';
import { isArray } from '@lib';

const initialState = [];

const setStates = (state, action) => {
    const { statesArray } = action.payload;
    return isArray(statesArray) ? statesArray : state;
};

export const states = createReducer(initialState, reducer => {
    reducer.addCase(SET_STATES, setStates).addDefaultCase((state, _) => state);
});
