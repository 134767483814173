import * as d3 from 'd3';
import { createCurve } from '@services/reports';
import { barColors } from '@config/barColors';

export const createHorizontalBarsByChannel = (data, styles, id) => {
    const margin = 100;
    const marginLeft = 162;
    const numberOfChannels = data.length;
    const padding = numberOfChannels > 3 ? 0.5 : 0.7;

    const wrapper = d3.select(`#${id}`);

    const graphWidth = wrapper.node().clientWidth * 0.9;
    const graphHeight = wrapper.node().clientWidth * 0.7;
    const width = graphWidth - 2 * margin;
    const height = graphHeight - 2 * margin;

    wrapper.selectAll('svg').remove();

    wrapper.style('height', graphHeight + 'px');

    const svg = wrapper.append('svg');
    const chart = svg
        .append('g')
        .attr('transform', `translate(${marginLeft}, ${margin})`);

    const maxXDomain = d3.max(data, d => d.percent);
    const xScale = d3
        .scaleLinear()
        .range([0, width])
        .domain([0, maxXDomain ? maxXDomain : 90]);
    xScale.domain([0, d3.max(xScale.ticks(5)) + xScale.ticks(5)[1]]);

    const xAxis = d3
        .axisBottom(xScale)
        .ticks(5)
        .tickFormat(x => `${d3.format('.0f')(x)}%`);

    const yScale = d3
        .scaleBand()
        .range([height, 0])
        .domain(data.map(d => d.channel))
        .padding(padding);

    const yAxis = d3.axisLeft(yScale).tickSize(0);

    chart
        .append('g')
        .attr('class', `yAxis ${styles.yAxis}`)
        .call(yAxis.scale(yScale));

    chart
        .append('g')
        .attr('id', `grid`)
        .attr('class', `grid ${styles.grid}`)
        .attr('transform', `translate(0, ${height})`)
        .call(xAxis);

    chart
        .select('.grid')
        .selectAll('.tick')
        .append('line')
        .attr('class', styles.gridLine)
        .attr('y1', 0)
        .attr('y2', -height);

    chart
        .select('.yAxis')
        .selectAll('.tick')
        .selectAll('text')
        .attr('x', -10)
        .each(function(d) {
            d.width = this.getBBox().width;
        });

    const yAxisHeight = chart
        .select('.yAxis')
        .node()
        .getBBox().height;

    const distanceBetweenTicks = yAxisHeight / (numberOfChannels + 1);

    chart
        .select('.yAxis')
        .selectAll('.tick')
        .insert('path', ':first-child')
        .attr('class', `${styles.yAxisCurve}`)
        .attr('d', d => {
            const start = [-123, -20];
            const end = [-d.width - 15, 0];
            const radius = 13;

            return createCurve(start, end, radius);
        });

    chart
        .select('.yAxis')
        .selectAll('.tick')
        .attr('class', (d, i) => {
            if (i === 0) {
                return 'firstTick';
            }
            return '';
        });

    chart
        .select('.firstTick')
        .append('path')
        .attr('class', `${styles.yAxisCurve}`)
        .attr('d', (d, i) => {
            const start = [-123, -yAxisHeight + distanceBetweenTicks];
            const end = [-160, -yAxisHeight + distanceBetweenTicks - 26];
            const radius = -13;

            return createCurve(start, end, radius);
        });

    chart
        .select('.firstTick')
        .append('line')
        .attr('class', `${styles.verticalLine}`)
        .attr('x1', d => -123)
        .attr('y1', -20)
        .attr('x2', d => -123)
        .attr('y2', -yAxisHeight + distanceBetweenTicks);

    const barGroups = chart
        .selectAll()
        .data(data)
        .enter();

    barGroups
        .append('path')
        .attr('class', 'bar')
        .attr('fill', d => barColors[d.channel])
        .attr('d', d => {
            const r = d.percent ? 4 : 0;
            return ` M0,${yScale(d.channel)}
             h${xScale(d.percent) - r}
             a${r},${r} 0 0 1 ${r},${r}
             v${yScale.bandwidth() - 2 * r}
             a${r},${r} 0 0 1 ${-r},${r}
             h${r - xScale(d.percent)}Z`;
        });

    barGroups
        .append('text')
        .attr('y', d => yScale(d.channel) + yScale.bandwidth() / 2 + 4)
        .attr('x', d => xScale(d.percent) + 5)
        .text(
            d => `${d3.format(',')(d.value)} | ${d3.format('.0f')(d.percent)}%`
        );
};
