const getRandomInt = (min, max) => {
    min = Math.ceil(min);
    max = Math.floor(max);
    return Math.floor(Math.random() * (max - min)) + min;
};

export const wait = async (min, max) => {
    const _ms = getRandomInt(min, max);
    return new Promise(resolve => {
        return setTimeout(resolve, _ms);
    });
};
