import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { StripeProvider, Elements } from 'react-stripe-elements';

import { Layout, Card, Input } from 'maslow';

import { updateAttributes } from '@state/plan/actions/attributes';
import { setShowPayNow } from '@state/ui/actions';
import Summary from '../Summary';
import PaymentMethod from '../PaymentMethod';
import { planSelector, uiSelector, hasPermissionSelector } from '@selectors/';
import { IDLE, ERROR, MEDIA_PLAN_CAN_PAY_LATER, PRODUCTION } from '@config';

import { errorText } from '@config/config.book';

import shared from '../styles.scss';

import {
    BOOK_PLAN_NAME_INPUT,
    BOOK_ADVERTISER_NAME_INPUT
} from '@config/idConstants';

const SubmitSection = props => {
    const { attributes } = useSelector(planSelector);
    const { showPayNow } = useSelector(uiSelector);

    const [payError, setPayError] = useState(false);
    const [cardHolderName, setCardHolderName] = useState('');

    const dispatch = useDispatch();
    const { setSubmitted, maxPayNowLimit, apiKey } = props;
    const { hasAcceptedTerms, advertiser, name } = attributes;

    const hasPayLaterOption = useSelector(
        hasPermissionSelector(MEDIA_PLAN_CAN_PAY_LATER)
    );

    const getInputStatus = inputValue => {
        return hasAcceptedTerms && !inputValue ? ERROR : IDLE;
    };

    const handleChange = (inputName, value) => {
        dispatch(updateAttributes({ [inputName]: value }));
    };

    const stripeKey =
        window.env === PRODUCTION ? apiKey.liveMode : apiKey.testMode;

    useEffect(() => {
        dispatch(setShowPayNow(!hasPayLaterOption));
    }, []);

    return (
        <StripeProvider apiKey={stripeKey}>
            <Elements>
                <Layout
                    flexDirection="row"
                    className={shared.submitSectionWrapper}>
                    <Layout
                        flex
                        flexDirection="column"
                        className={shared.marginRight}>
                        <Layout flexDirection="column">
                            <Card
                                backgroundColor="white"
                                borderRadius="2px"
                                boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                                className={shared.paddingSpace}>
                                <Input
                                    id={BOOK_PLAN_NAME_INPUT}
                                    status={getInputStatus(name)}
                                    label="Enter your Plan Name here"
                                    name="name"
                                    theme="line"
                                    value={name}
                                    onChange={e => handleChange('name', e)}
                                    errorText={errorText}
                                />
                            </Card>

                            <Card
                                backgroundColor="white"
                                borderRadius="2px"
                                boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                                className={shared.paddingSpace}>
                                <Input
                                    id={BOOK_ADVERTISER_NAME_INPUT}
                                    status={getInputStatus(advertiser)}
                                    label="Specify the Advertiser Name"
                                    name="advertiser"
                                    theme="line"
                                    value={advertiser}
                                    onChange={e =>
                                        handleChange('advertiser', e)
                                    }
                                    errorText={errorText}
                                />
                            </Card>

                            <PaymentMethod
                                showPayNow={showPayNow}
                                setPayError={setPayError}
                                maxPayNowLimit={maxPayNowLimit}
                                cardHolderName={cardHolderName}
                                setCardHolderName={setCardHolderName}
                                hasPayLaterOption={hasPayLaterOption}
                            />
                        </Layout>
                    </Layout>

                    <Summary
                        errorState={payError}
                        setSubmitted={setSubmitted}
                        showPayNow={showPayNow}
                        setPayError={setPayError}
                        cardHolderName={cardHolderName}
                    />
                </Layout>
            </Elements>
        </StripeProvider>
    );
};

SubmitSection.propTypes = {
    setSubmitted: PropTypes.func.isRequired,
    maxPayNowLimit: PropTypes.number.isRequired,
    apiKey: PropTypes.object.isRequired
};

export default SubmitSection;
