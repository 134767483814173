import * as d3 from 'd3';
import { barColors } from '@config/barColors';

export const createPerformanceByChannel = (data, styles) => {
    const margin = 100;
    const numberOfChannels = data.length;
    const padding = numberOfChannels > 3 ? 0.6 : 0.8;

    const wrapper = d3.select('#performanceByChannelGraph');

    const graphWidth = wrapper.node().clientWidth;
    const graphHeight = wrapper.node().clientWidth * 0.5;
    const width = graphWidth - 2 * margin;
    const height = graphHeight - 2 * margin;

    wrapper.selectAll('svg').remove();

    wrapper.style('height', graphHeight + 'px');

    const svg = wrapper.append('svg');
    const chart = svg
        .append('g')
        .attr('transform', `translate(${margin}, ${margin})`);

    const xScale = d3
        .scaleBand()
        .range([10, width])
        .domain(data.map(d => d.channel))
        .padding(padding);

    const xAxis = d3.axisBottom(xScale).tickSize(0);

    const maxYDomain = d3.max(data, d => d.percent);
    const yScale = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, maxYDomain ? maxYDomain : 90]);
    yScale.domain([0, d3.max(yScale.ticks(6)) + yScale.ticks(6)[1]]);

    if (d3.max(data, d => d.percent) > d3.max(yScale.ticks(6))) {
        yScale.domain([0, d3.max(yScale.ticks(6)) + yScale.ticks(6)[1]]);
    }

    const yAxis = d3
        .axisLeft(yScale)
        .ticks(6)
        .tickFormat(y => `${d3.format('.2f')(y)}%`)
        .tickSize(0);

    chart
        .append('g')
        .attr('class', `grid ${styles.grid}`)
        .call(yAxis.scale(yScale))
        .call(g => g.select('.domain').remove());

    chart
        .select('.grid')
        .selectAll('.tick')
        .append('line')
        .attr('class', styles.gridLine)
        .attr('x1', 10)
        .attr('x2', width);

    chart
        .append('g')
        .attr('class', `xAxis ${styles.xAxis}`)
        .attr('transform', `translate(0, ${height})`)
        .call(xAxis);

    chart
        .select('.xAxis')
        .selectAll('.tick')
        .selectAll('text')
        .attr('y', 10);

    const barGroups = chart
        .selectAll()
        .data(data)
        .enter();

    barGroups
        .append('path')
        .attr('class', 'bar')
        .attr('fill', d => barColors[d.channel])
        .attr('d', d => {
            const r = d.percent > 0 ? 4 : 0;
            return ` M${xScale(d.channel)},${yScale(d.percent) + r}
             a${r},${r} 0 0 1 ${r},${-r}
             h${xScale.bandwidth() - 2 * r}
             a${r},${r} 0 0 1 ${r},${r}
             v${height - yScale(d.percent) - r}
             h${-xScale.bandwidth()}Z`;
        });

    barGroups
        .append('text')
        .attr('x', d => xScale(d.channel) + xScale.bandwidth() / 2)
        .attr('y', d => yScale(d.percent) - 5)
        .attr('text-anchor', 'middle')
        .text(d => `${d3.format('.2f')(d.percent)}%`);
};
