import React from 'react';
import { useSelector } from 'react-redux';
import { getFormattedBudget } from '@services/budget';
import { Layout, Text, Card, Divider } from 'maslow';
import Channel from './Channel';
import SubCampaign from './SubCampaign';
import { budgetConfig } from '@config/config.budget';
import styles from './styles.scss';
import { budgetConfigSelector } from '@selectors/';

const Budget = () => {
    const { total, channels } = useSelector(budgetConfigSelector);

    const totalSpend = getFormattedBudget(total);
    const budgetChannels = budgetConfig.distribution.channels;

    const contents = () => {
        return budgetChannels.map(item => {
            const { name, style } = item;
            const { sliderColorName } = style;
            const channelBudget = name in channels ? channels[name].budget : 0;

            return (
                channelBudget > 0 && (
                    <Card
                        className={styles.channelWrapper}
                        borderColor={sliderColorName}
                        key={item.name}
                        borderWidth="3px">
                        <Layout flexDirection="column" fillParent>
                            <Channel data={item} />

                            <Divider
                                borderWidth="3px"
                                color={sliderColorName}
                                margin="0"
                            />

                            <SubCampaign data={item} />
                        </Layout>
                    </Card>
                )
            );
        });
    };

    return (
        <Layout flexDirection="column" className={styles.wrapper}>
            <Layout flexDirection="column" hAlign="center">
                <Text type="h3">Budget</Text>

                <Layout
                    className={styles.subTitle}
                    flexDirection="row"
                    hAlign="center">
                    <Text className={styles.totalSpend} type="h4">
                        {totalSpend}
                    </Text>
                </Layout>
            </Layout>

            <div>{contents()}</div>
        </Layout>
    );
};

export default Budget;
