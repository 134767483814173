import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { Layout, Text, Icon } from 'maslow';
import { scrollToElement, slugBuilder } from '@services';
import {
    removeCategoryFromAudience,
    resetAudiencesOrder
} from '@state/audience/actions/audiences';
import { fetchHouseholdCount } from '@state/audience/actions/households';
import { removeCombinationsIfAudienceEmpty } from '@state/audience/actions/combinations';
import { SelectedAudienceOption } from '../SelectedAudienceOption';
import { toggleIsAudienceModified } from '@state/audience/actions/toggleIsAudienceModified';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import styles from '../styles.scss';

import { PLAN_STATUSES } from '@config/config.planStatus';

import { idBuilder } from '@services';
import {
    AUDIENCE_SUMMARY_SCROLL,
    AUDIENCE_SUMMARY_CLEAR_CATEGORY,
    AUDIENCE_SUMMARY_CLEAR_OPTION
} from '@config/idConstants';

const Component = props => {
    const clearCategoryWrapper = (currentAudienceIndex, categoryName) => () => {
        props.removeCategoryFromAudience(currentAudienceIndex, categoryName);
        props.removeCombinationsIfAudienceEmpty(currentAudienceIndex);
        props.fetchHouseholdCount();
        props.toggleIsAudienceModified(true);
        props.setCampaignRouterId(null);
        props.setPlanStatus(PLAN_STATUSES.UNSAVED);
        props.resetAudiencesOrder(currentAudienceIndex);
    };

    const {
        interactive,
        currentAudienceIndex,
        selectedAudienceIndex,
        categoryName,
        minusIcon,
        closeIcon
    } = props;

    const onClickHandler = () => {
        const categorySlugName = slugBuilder(categoryName);

        return interactive
            ? scrollToElement('audience-scroll-container', categorySlugName)
            : null;
    };

    const renderCategoryHeader = () => {
        const clearCategory = clearCategoryWrapper(
            currentAudienceIndex,
            categoryName
        );
        const categoryNameText = interactive
            ? categoryName
            : categoryName + ':';

        return (
            <Layout
                flexDirection="column"
                className={styles.selectedAudienceCategory}>
                <Layout
                    flexDirection="row"
                    hAlign="space-between"
                    vAlign="center"
                    className={styles.selectedAudienceCategoryTitleWrapper}>
                    <Text
                        id={idBuilder(AUDIENCE_SUMMARY_SCROLL, categoryName)}
                        type="body"
                        className={styles.selectedAudienceCategoryTitle}
                        onClick={onClickHandler}>
                        {categoryNameText}
                    </Text>

                    {interactive && (
                        <Layout
                            id={idBuilder(
                                AUDIENCE_SUMMARY_CLEAR_CATEGORY,
                                categoryNameText
                            )}
                            flexDirection="row"
                            vAlign="center"
                            className={styles.selectedAudienceClearWrapper}
                            onClick={clearCategory}>
                            <Text
                                type="body"
                                className={styles.selectedAudienceClearText}>
                                Clear
                            </Text>

                            <Icon
                                src={closeIcon}
                                className={styles.selectedAudienceCloseIcon}
                                size="2"
                            />
                        </Layout>
                    )}
                </Layout>
            </Layout>
        );
    };

    const renderOption = () => {
        return props.category.map((option, i) => {
            return (
                <SelectedAudienceOption
                    id={idBuilder(AUDIENCE_SUMMARY_CLEAR_OPTION, option.id)} //TODO discuss name or id
                    key={i}
                    interactive={interactive}
                    option={option}
                    selectedAudienceIndex={selectedAudienceIndex}
                    minusIcon={minusIcon}
                    readonly={false}
                />
            );
        });
    };

    return (
        <div>
            {renderCategoryHeader()}
            {renderOption()}
        </div>
    );
};

Component.propTypes = {
    interactive: PropTypes.bool.isRequired,
    categoryName: PropTypes.string.isRequired,
    category: PropTypes.array.isRequired,
    currentAudienceIndex: PropTypes.number.isRequired,
    selectedAudienceIndex: PropTypes.number.isRequired,
    minusIcon: PropTypes.node.isRequired,
    closeIcon: PropTypes.node.isRequired,
    fetchHouseholdCount: PropTypes.func.isRequired,
    removeCategoryFromAudience: PropTypes.func.isRequired,
    removeCombinationsIfAudienceEmpty: PropTypes.func.isRequired,
    toggleIsAudienceModified: PropTypes.func.isRequired,
    setCampaignRouterId: PropTypes.func.isRequired,
    setPlanStatus: PropTypes.func.isRequired,
    resetAudiencesOrder: PropTypes.func.isRequired
};

const mapDispatchToProps = dispatch => ({
    fetchHouseholdCount: () => dispatch(fetchHouseholdCount()),
    removeCategoryFromAudience: (audienceIndex, category) =>
        dispatch(removeCategoryFromAudience(audienceIndex, category)),
    removeCombinationsIfAudienceEmpty: audienceIndex =>
        dispatch(removeCombinationsIfAudienceEmpty(audienceIndex)),
    toggleIsAudienceModified: isModified =>
        dispatch(toggleIsAudienceModified(isModified)),
    setCampaignRouterId: campaignRouterId =>
        dispatch(setCampaignRouterId(campaignRouterId)),
    setPlanStatus: planStatus => dispatch(setPlanStatus(planStatus)),
    resetAudiencesOrder: audienceIndex =>
        dispatch(resetAudiencesOrder(audienceIndex))
});

export const SelectedAudienceCategory = connect(
    null,
    mapDispatchToProps
)(Component);
