import { combineReducers } from 'redux';

import { campaignRouterId } from './campaignRouterId';
import { planStatus } from './planStatus';
import { tvDetails } from './tvDetails';
import { hasTvDetails } from './hasTvDetails';
import { a4PlanId } from './a4PlanId';
import { submittedDigitalDetails } from './submittedDigitalDetails';

export const mediaPlan = combineReducers({
    campaignRouterId,
    planStatus,
    tvDetails,
    hasTvDetails,
    a4PlanId,
    submittedDigitalDetails
});
