import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';

class Footer extends React.Component {
    constructor (props) {
        super(props);
    }

    render () {
        const { components } = getFeatures('footer', this);

        return <div>{components}</div>;
    }
}

export default withFeatures(Footer);
