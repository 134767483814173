import Login from './Login';
import MainNav from './MainNav';
import PlanNav from './PlanNav';
import Plans from './Plans';
import Location from './Location';
import MainTopbar from './MainTopbar';
import MediaPlanTopbar from './MediaPlanTopbar';
import Schedule from './Schedule';
import MediaPlan from './MediaPlan';
import Budget from './Budget';
import AudienceSelectors from './AudienceSelectors';
import AudienceTypeOptions from './AudienceTypeOptions';
import MyAudiencesLink from './MyAudiencesLink';
import HouseholdCount from './HouseholdCount';
import MultiAudiences from './MultiAudiences';
import AudienceSummary from './AudienceSummary';
// import { Dashboard } from './Admin';
import AudienceSummarySelections from './AudienceSummarySelections';
import Networks from './Networks';
import NetworksSummarySelections from './NetworksSummarySelections';
import Book from './Book';
// import PopulateUIButton from './PopulateUIButton';
import Creative from './Creative';
import LocationSummarySelections from './LocationSummarySelections';
import DaypartExclusions from './DaypartExclusions';
import PdfExport from './PdfExport';
import CampaignSelectors from './CampaignSelectors';
import VerificationError from './VerificationError';
import SignUp from './SignUp';
import MediaPlanSummaryFooter from './MediaPlanSummaryFooter';
import VerifyUser from './VerifyUser';
import Reports from './Reports';
import NotFound from './NotFound';
import Ranker from './Ranker';

export default [
    Login,
    MainNav,
    PlanNav,
    Plans,
    Location,
    HouseholdCount,
    MainTopbar,
    MediaPlanTopbar,
    Schedule,
    MediaPlan,
    Budget,
    AudienceSelectors,
    AudienceTypeOptions,
    MyAudiencesLink,
    MultiAudiences,
    AudienceSummary,
    AudienceSummarySelections,
    Networks,
    NetworksSummarySelections,
    Book,
    // PopulateUIButton,
    Creative,
    LocationSummarySelections,
    Reports,
    DaypartExclusions,
    PdfExport,
    CampaignSelectors,
    VerificationError,
    SignUp,
    MediaPlanSummaryFooter,
    VerifyUser,
    NotFound,
    Ranker
];
