import React from 'react';

import { Text } from 'maslow';

import shared from '@sharedStyle/loginAndSignUp.scss';

import { SIGNUP_LOGIN } from '@config/idConstants';

const AlreadyMemberSection = ({ transition }) => {
    const onLogIn = () => {
        transition.router.stateService.go('login');
    };

    return (
        <div className={shared.textBelowButton}>
            <Text type="caption" color="echoBlue">
                Already a member?{' '}
                <Text
                    id={SIGNUP_LOGIN}
                    type="caption"
                    color="vividNavy"
                    className={shared.textLink}
                    onClick={onLogIn}>
                    Log in to your account
                </Text>
            </Text>
        </div>
    );
};

export default AlreadyMemberSection;
