import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import shared from '@sharedStyle/page.scss';

const Schedule = props => {
    const { components } = getFeatures('schedule', { props });

    return <div className={shared.smallPageWrapper}>{components}</div>;
};

export default withFeatures(Schedule);
