import React from 'react';
import { Text, Layout } from 'maslow';
import styles from '../styles.scss';

const EmptySelection = () => {
    return (
        <Layout flexDirection="column">
            <Text type="h5" className={styles.emptyLocationTitle}>
                Targeting nationwide
            </Text>
            <Text
                type="body"
                className={`${styles.marginTop} ${styles.emptyLocationText}`}>
                Search to select a location.
            </Text>
        </Layout>
    );
};

export default EmptySelection;
