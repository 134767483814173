import { createReducer } from '@reduxjs/toolkit';
import { UPDATE_LOADING_SCREEN, RESET_LOADING_SCREEN } from '../constants';
import { isObject } from '@lib';

const initialState = {
    loadingText: '',
    showLoadingScreen: false
};

const updateLoadingScreen = (state, action) => {
    const { loadingScreen } = action.payload;
    return isObject(loadingScreen) ? loadingScreen : state;
};

const resetLoadingScreen = () => {
    return initialState;
};

export const loadingScreen = createReducer(initialState, reducer => {
    reducer
        .addCase(UPDATE_LOADING_SCREEN, updateLoadingScreen)
        .addCase(RESET_LOADING_SCREEN, resetLoadingScreen)
        .addDefaultCase((state, _) => state);
});
