import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Text, Icon } from 'maslow';

import { datesHandler } from '@lib';

import style from '../styles.scss';

const DateSection = ({ startDate, endDate, startDateIcon, endDateIcon }) => {
    const _startDate = datesHandler.parseISO(startDate);
    const _endDate = datesHandler.parseISO(endDate);
    const formattedStartDate = datesHandler.format(_startDate, 'LLL dd, yyyy');
    const formattedEndDate = datesHandler.format(_endDate, 'LLL dd, yyyy');
    const startDateSectionStyle = startDateIcon ? style.dateSection : '';
    const endDateSectionStyle = endDateIcon ? style.dateSection : '';

    return (
        <Layout flexDirection="row" hAlign="space-between">
            <Layout flexDirection="column" className={startDateSectionStyle}>
                <Text color="slateGray" className={style.xsmallFont}>
                    Start Date
                </Text>

                <Layout flexDirection="row" vAlign="center">
                    <Text color="darkGray" className={style.dateValue}>
                        {formattedStartDate}
                    </Text>
                    {startDateIcon && (
                        <Icon src={startDateIcon} className={style.leftIcon} />
                    )}
                </Layout>
            </Layout>

            <Layout
                flexDirection="column"
                hAlign="flex-end"
                className={endDateSectionStyle}>
                <Text color="slateGray" className={style.xsmallFont}>
                    End Date
                </Text>

                <Layout flexDirection="row">
                    {endDateIcon && (
                        <Icon src={endDateIcon} className={style.rightIcon} />
                    )}
                    <Text color="darkGray" className={style.dateValue}>
                        {formattedEndDate}
                    </Text>
                </Layout>
            </Layout>
        </Layout>
    );
};

DateSection.propTypes = {
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired
};

export default DateSection;
