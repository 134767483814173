import { Schedule } from '../components/pages';
import { LeftSidebarSchedule } from '../components/layouts';

export default [
    {
        name: 'app.plan.schedule',
        url: '/schedule',
        views: {
            'leftSidebar@app': {
                component: LeftSidebarSchedule
            },
            'content@app': {
                component: Schedule
            }
        }
    }
];
