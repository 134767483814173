import { validateArg } from '@lib';

export const getFilteredNavItems = (navItems, permissions, clickFunctions) => {
    validateArg(navItems, 'array', 1);
    if (permissions === undefined) return [];
    validateArg(permissions, 'array', 2);
    if (clickFunctions !== undefined) validateArg(clickFunctions, 'object', 3);
    return navItems
        .map(item => {
            if (
                item.onClick &&
                clickFunctions &&
                clickFunctions[item.onClick]
            ) {
                return { ...item, onClick: clickFunctions[item.onClick] };
            }
            return item;
        })
        .filter(
            item => !item.permission || permissions.includes(item.permission)
        );
};
