import { budgetConfig } from '@config/config.budget';
import {
    getChannelKey,
    getChannelDefaults,
    getMergedChannelKeys
} from './helpers';
import {
    getDistributions,
    distribute,
    distributeSubCampaigns,
    changeAmounts,
    getRoundPriorities,
    getSubCampaignRoundPriorities,
    getDistributionBasedOnLockedSections
} from './distributionHelpers';

export const channelPriorities = getRoundPriorities(
    budgetConfig.distribution.channels
);

export const subCampaignPriorities = getSubCampaignRoundPriorities(
    budgetConfig.distribution.channels,
    budgetConfig.distribution.subCampaigns
);

export const budgetCalc = {
    total: (
        newTotalInput,
        { total, channels, subCampaigns, distributionConfig },
        role,
        region,
        userType,
        lockedSubCampaigns,
        lockedChannels,
        disabledChannelsKeys,
        maxBudgets
    ) => {
        const lockedAndExcludedChannels = getMergedChannelKeys(
            lockedChannels,
            disabledChannelsKeys
        );
        const newTotal = Math.round(newTotalInput);
        const min = budgetConfig.limits.min[userType][role][region];
        const oldDistributedTotal = Object.values(channels).reduce(
            (a, b) => a + b,
            0
        );
        const oldTotal =
            oldDistributedTotal ||
            (disabledChannelsKeys && disabledChannelsKeys.length)
                ? total
                : 0;
        const shouldDistributeTotal = newTotal >= min || oldTotal;
        const isGreaterThanLimit = newTotal >= min && oldTotal >= min;
        const distributions = getDistributions(newTotal, distributionConfig);
        const defaultPercentages = getChannelDefaults(distributions);

        const newChannels = shouldDistributeTotal
            ? distribute(
                  oldTotal,
                  newTotal,
                  channels,
                  channelPriorities,
                  getDistributionBasedOnLockedSections(
                      defaultPercentages,
                      lockedChannels,
                      channelPriorities
                  ),
                  isGreaterThanLimit,
                  lockedAndExcludedChannels,
                  maxBudgets
              )
            : {};

        const newSubCampaigns = shouldDistributeTotal
            ? distributeSubCampaigns(
                  channels,
                  newChannels,
                  subCampaigns,
                  subCampaignPriorities,
                  isGreaterThanLimit,
                  lockedSubCampaigns,
                  distributions,
                  maxBudgets
              )
            : {};

        return {
            total: newTotal,
            channels: newChannels,
            subCampaigns: newSubCampaigns
        };
    },
    channel: (
        channelName,
        newChannelInput,
        { total, channels, subCampaigns, distributionConfig },
        isPercent,
        lockedSubCampaigns,
        lockedChannels,
        role,
        region,
        disabledChannelsKeys
    ) => {
        const lockedAndExcludedChannels = getMergedChannelKeys(
            lockedChannels,
            disabledChannelsKeys
        );
        const newChannelBudget = isPercent
            ? Math.round((newChannelInput / 100) * total)
            : Math.round(newChannelInput);
        const distributions = getDistributions(total, distributionConfig);
        const newChannels = changeAmounts(
            channelName,
            newChannelBudget,
            channels,
            channelPriorities,
            lockedAndExcludedChannels
        );

        const newSubCampaigns = distributeSubCampaigns(
            channels,
            newChannels,
            subCampaigns,
            subCampaignPriorities,
            true,
            lockedSubCampaigns,
            distributions
        );

        return {
            channels: newChannels,
            subCampaigns: newSubCampaigns
        };
    },
    subCampaign: (
        subName,
        newSubInput,
        { channels, subCampaigns },
        isPercent,
        lockedSubCampaigns
    ) => {
        const channelKey = getChannelKey(
            subName,
            budgetConfig.distribution.subCampaigns
        );
        const newSubBudget = isPercent
            ? Math.round((newSubInput / 100) * channels[channelKey])
            : Math.round(newSubInput);
        return {
            ...subCampaigns,
            ...changeAmounts(
                subName,
                newSubBudget,
                subCampaigns,
                subCampaignPriorities[channelKey],
                lockedSubCampaigns
            )
        };
    }
};

export const getNewSubAmountsBasedOnLockedStatus = (
    { subCampaigns },
    lockedSubCampaigns
) => {
    let newSubCampaigns = {};
    Object.keys(subCampaignPriorities).forEach(channel => {
        const sections = {};
        subCampaignPriorities[channel].forEach(({ key }) => {
            if (!isNaN(subCampaigns[key])) sections[key] = subCampaigns[key];
        });
        newSubCampaigns = {
            ...getDistributionBasedOnLockedSections(
                sections,
                lockedSubCampaigns,
                subCampaignPriorities[channel]
            ),
            ...newSubCampaigns
        };
    });

    return newSubCampaigns;
};

export const getNewChannelAmountsBasedOnLockedStatus = (
    { total, channels, subCampaigns, distributionConfig },
    lockedChannels,
    role,
    region,
    lockedSubCampaigns
) => {
    const newChannels = getDistributionBasedOnLockedSections(
        channels,
        lockedChannels,
        channelPriorities
    );

    const distributions = getDistributions(total, distributionConfig);

    const newSubCampaigns = distributeSubCampaigns(
        channels,
        newChannels,
        subCampaigns,
        subCampaignPriorities,
        true,
        lockedSubCampaigns,
        distributions
    );

    return {
        channels: newChannels,
        subCampaigns: newSubCampaigns
    };
};
