import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Text, Icon } from 'maslow';
import triangleSvg from '@public/img/triangle-small.svg';
import upArrowSvg from '@public/img/blue-upload-arrow.svg';

import styles from '../audiences.scss';

const SavedAudience = ({ item, audienceStyles }) => {
    return (
        <Layout
            style={audienceStyles.card}
            flexDirection="row"
            className={styles.card}>
            <Layout
                flexDirection="row"
                flex
                vAlign="center"
                className={styles.savedAudInnerWrapper}>
                <Icon src={upArrowSvg} size="3" />
                <Text
                    type="input"
                    color="wildBlue"
                    className={styles.savedAudText}>
                    {item.category}
                </Text>
                <Icon src={triangleSvg} className={styles.triangle} />
                <Text type="input" className={styles.savedAudName}>
                    {item.name}
                </Text>
            </Layout>
        </Layout>
    );
};

SavedAudience.propTypes = {
    item: PropTypes.object.isRequired,
    audienceStyles: PropTypes.object.isRequired
};

export default SavedAudience;
