import React from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import { CardSelector } from '@components/common';

import { setAudienceType } from '@state/audience/actions/audienceType';
import { slugBuilder } from '@services';
import { audienceSelectorsConfig as config } from '@config/config.audienceSelectors';
import { pathSelector } from '@selectors/';

export default function (version, feature) {
    const AudienceSelectors = ({ audienceConfig }) => {
        const dispatch = useDispatch();
        const getSlugName = (type, audienceConfig) =>
            type === 'builder' ? slugBuilder(audienceConfig[0].name) : '';

        const updateAudienceType = type => dispatch(setAudienceType(type));
        const path = useSelector(pathSelector);

        return (
            <React.Fragment>
                {config.map(
                    (
                        {
                            route,
                            name,
                            button,
                            tooltip,
                            type,
                            id,
                            tooltipId,
                            svg
                        },
                        i
                    ) => {
                        const handleClick =
                            type === 'custom'
                                ? () => {}
                                : () => updateAudienceType(type);
                        return (
                            <CardSelector
                                route={route[path]}
                                onClick={handleClick}
                                key={type + i}
                                params={{
                                    sectionName: getSlugName(
                                        type,
                                        audienceConfig
                                    )
                                }}
                                cardId={id}
                                svg={svg}
                                text={name}
                                buttonText={button}
                                tooltipId={tooltipId}
                                tooltipText={tooltip}
                                type={type}></CardSelector>
                        );
                    }
                )}
            </React.Fragment>
        );
    };

    AudienceSelectors.propTypes = {
        audienceConfig: PropTypes.array.isRequired
    };

    return AudienceSelectors;
}
