import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import minusIcon from '@public/img/minus-selector.svg';
import closeIcon from '@public/img/close.svg';
import audience1DownArrow from '@public/img/Arrows-01.svg';
import audience1UpArrow from '@public/img/Arrows-02.svg';
import audience2DownArrow from '@public/img/Arrows-03.svg';
import audience2UpArrow from '@public/img/Arrows-04.svg';
import audience3DownArrow from '@public/img/Arrows-05.svg';
import audience3UpArrow from '@public/img/Arrows-06.svg';

import { SelectedAudience } from '../SelectedAudience';

const arrowIcons = [
    {
        down: audience1DownArrow,
        up: audience1UpArrow
    },
    {
        down: audience2DownArrow,
        up: audience2UpArrow
    },
    {
        down: audience3DownArrow,
        up: audience3UpArrow
    }
];

const mapAudiencesDataForUi = audiences => {
    return audiences.map(audience => {
        const mappedAudience = {};

        audience.forEach(option => {
            if (!mappedAudience[option.category]) mappedAudience[option.category] = [];

            mappedAudience[option.category].push(option);
        });

        return mappedAudience;
    });
};

const Component = props => {
    const {
        interactive,
        audiences,
        currentAudienceIndex,
        selectedAudienceStyles
    } = props;

    const renderAudiences = () => {
        const mappedAudiences = mapAudiencesDataForUi(audiences);

        return mappedAudiences.map((audience, i) => {
            return <SelectedAudience key={i}
                interactive={interactive}
                audience={audience}
                currentAudienceIndex={currentAudienceIndex}
                selectedAudienceIndex={i}
                selectedAudienceStyles={selectedAudienceStyles[i]}
                closeIcon={closeIcon}
                minusIcon={minusIcon}
                arrowIcons={arrowIcons[i]}
            />;
        });
    };

    return <div>
        {renderAudiences()}
    </div>;
};

Component.propTypes = {
    interactive: PropTypes.bool.isRequired,
    selectedAudienceStyles: PropTypes.array.isRequired,
    currentAudienceIndex: PropTypes.number.isRequired,
    audiences: PropTypes.array.isRequired
};

const mapStateToProps = state => ({
    audiences: state.audience.audiences,
    currentAudienceIndex: state.audience.currentAudienceIndex
});

export const SelectedAudiences = connect(mapStateToProps)(Component);
