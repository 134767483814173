import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Watch } from 'scrollmonitor-react';

import { Layout, Text, Card, Content, Icon } from 'maslow';
import styles from './index.scss';
import { sliceArray, chunkArray } from '@lib';
import AudienceSelectionOption from './AudienceSelectionOption';
import { slugBuilder } from '@services';
import { idBuilder } from '../../../services';

import { AUDIENCE_BUILDER_CATEGORY } from '@config/idConstants';

const OPTIONS_ROW_SIZE = 1;

const AudienceCategory = ({
    stopWatcher,
    sizeRatio,
    optionsColumns,
    sectionName,
    scrollContainer,
    categoryData
}) => {
    const [isMoreClicked, setIsMoreClicked] = useState(false);

    const buildEachOption = (item, key, categoryName, categoryValue) => {
        return (
            <Layout key={key} size={sizeRatio} flexDirection="column">
                <AudienceSelectionOption
                    option={item}
                    category={categoryName}
                    categoryValue={categoryValue}
                    section={sectionName}
                />
            </Layout>
        );
    };

    const buildOptions = (
        option,
        key,
        categoryName,
        categoryValue,
        sizeRatio,
        sectionName
    ) => {
        const eachOptionsCol = option.map((val, i) =>
            buildEachOption(
                val,
                i,
                categoryName,
                categoryValue,
                sizeRatio,
                sectionName
            )
        );

        return (
            <Layout key={key} flexDirection="row" className={styles.rowWrapper}>
                {eachOptionsCol}
            </Layout>
        );
    };

    const buildCategory = (
        item,
        optionsColumns,
        isMoreClicked,
        sizeRatio,
        sectionName
    ) => {
        const chunkOptions = chunkArray(item.options, optionsColumns);
        const optionList = isMoreClicked
            ? chunkOptions
            : sliceArray(chunkOptions, 0, OPTIONS_ROW_SIZE);
        const options = optionList.map((option, i) =>
            buildOptions(
                option,
                i,
                item.name,
                item.value,
                sizeRatio,
                sectionName
            )
        );
        const optionText = isMoreClicked ? '- HIDE' : '+ SHOW MORE';

        useEffect(() => stopWatcher(), [sizeRatio]);

        useEffect(() => {
            const updateTimeout = setTimeout(() => {
                scrollContainer.update();
            }, 300);

            return () => clearTimeout(updateTimeout);
        }, [isMoreClicked, scrollContainer]);

        return (
            <Card
                backgroundColor="white"
                borderRadius="2px"
                boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                className={styles.cardWrapper}>
                <Content fillParent>
                    <Layout className={styles.rowWrapper}>
                        <Text type="h5">{item.name}</Text>
                    </Layout>

                    {options}

                    {chunkOptions.length > OPTIONS_ROW_SIZE && (
                        <Text
                            id={idBuilder(
                                AUDIENCE_BUILDER_CATEGORY,
                                item.name,
                                optionText
                            )}
                            type="input"
                            color="vividNavy"
                            onClick={() => setIsMoreClicked(!isMoreClicked)}
                            className={styles.itemContainer}>
                            {optionText}
                        </Text>
                    )}
                </Content>
            </Card>
        );
    };

    const category = buildCategory(
        categoryData,
        optionsColumns,
        isMoreClicked,
        sizeRatio,
        sectionName
    );
    const slugName = slugBuilder(categoryData.name);

    return <div id={slugName}>{category}</div>;
};

AudienceCategory.propTypes = {
    categoryData: PropTypes.object.isRequired,
    sectionName: PropTypes.string.isRequired,
    sizeRatio: PropTypes.number.isRequired,
    optionsColumns: PropTypes.number.isRequired,
    stopWatcher: PropTypes.func.isRequired,
    scrollContainer: PropTypes.object
};

export default Watch(AudienceCategory);
