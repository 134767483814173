import React from 'react';
import PropTypes from 'prop-types';
import Icon from '../Icon';
import Text from '../Text';
import {
    Label,
    CheckboxWrapper,
    CheckboxIcon,
    CheckboxBack,
    CheckboxText,
    ThemeProvider
} from './style';
import THEMES from './themes';
import primaryIcon from '../../../public/img/UI-Kit-Check.svg';
import invertedIcon from '../../../public/img/UI-Kit-Check-blue.svg';
import { CHECKBOX, CHECKBOX_LABEL } from '../testIds';
import { testIdBuilder } from '../../core/testIdBuilder';

/** Checkbox component */

const Checkbox = props => {
    const {
        theme,
        onChange,
        label,
        testNameSpace,
        labelColor,
        id,
        value,
        size = 1
    } = props;

    const themeObj = THEMES[theme];
    const icon =
        theme === 'primary' || theme === 'blue' ? primaryIcon : invertedIcon;
    const testId = testIdBuilder(testNameSpace, CHECKBOX);
    const labelTestId = testIdBuilder(testNameSpace, CHECKBOX_LABEL);

    return (
        <ThemeProvider theme={themeObj}>
            <Label>
                <input
                    type="checkbox"
                    onChange={onChange}
                    data-testid={testId}
                    id={id}
                    checked={value}
                />
                <CheckboxWrapper size={size}>
                    <CheckboxBack />
                    <CheckboxIcon>
                        <Icon src={icon} size="checkbox" />
                    </CheckboxIcon>
                </CheckboxWrapper>
                {label && (
                    <CheckboxText>
                        <Text
                            color={labelColor}
                            data-testid={labelTestId}
                            type="caption">
                            {label}
                        </Text>
                    </CheckboxText>
                )}
            </Label>
        </ThemeProvider>
    );
};

Checkbox.propTypes = {
    //** Value for the checkbox **//
    value: PropTypes.bool,
    /** Label for the checkbox */
    label: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
    /** Theme of the checkbox */
    theme: PropTypes.oneOf(['primary', 'inverted', 'blue']),
    /** onChange callback*/
    onChange: PropTypes.func,
    /** Namespace for e2e tests targeting */
    testNameSpace: PropTypes.string,
    /** Label color for the checkbox */
    labelColor: PropTypes.string,
    /** Id for tracking */
    id: PropTypes.string.isRequired,
    /** Element size */
    size: PropTypes.number
};

Checkbox.defaultProps = {
    theme: 'primary'
};

export default Checkbox;
