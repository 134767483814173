export default {
    name: 'PopulateUIButton', // Component name
    description:
        'A button to populate the UI with data so we can develop FAST!',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan'],
    sections: ['devStuff'],
    permissions: [],
    data: {
        audienceType: 'builder',
        totalBudget: 140000,
        totalHouseholdCount: 1000000,
        householdCount: 123000,
        startDateDaysToAdd: 10,
        endDateDaysToAdd: 60,
        campaignDuration: 50
    },
    attributes: {},
    modifications: {}
};
