import React from 'react';
import PropTypes from 'prop-types';

import { Layout, Button } from 'maslow';

import { LOGIN_PASSWORD_CHANGED } from '@config/idConstants';

const PasswordChangedSection = ({
    buttonText,
    updateComponentState,
    DISPLAY_TEXT_STATES
}) => {
    const onSignIn = () => {
        updateComponentState('displayTextState', DISPLAY_TEXT_STATES.INITIAL);
        updateComponentState('showPasswordChangedPage', false);
    };

    return (
        <Layout flexDirection="column">
            <Button id={LOGIN_PASSWORD_CHANGED} onClick={onSignIn}>
                {buttonText}
            </Button>
        </Layout>
    );
};

PasswordChangedSection.propTypes = {
    buttonText: PropTypes.string.isRequired,
    updateComponentState: PropTypes.func.isRequired,
    DISPLAY_TEXT_STATES: PropTypes.object.isRequired
};

export default PasswordChangedSection;
