import { Help } from '../components/pages';

export default [
    {
        name: 'app.main.help',
        url: '/help',
        views: {
            'content@app': {
                component: Help
            }
        }
    }
];
