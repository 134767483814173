import React from 'react';
import { UISref } from '@uirouter/react';
import PropTypes from 'prop-types';

import style from '@sharedStyle/audienceSelector.scss';
import { Text, Card, Layout, Icon, Button, Tooltip } from 'maslow';
import informationSvg from '@public/img/information-icon.svg';

const CardSelector = props => {
    const { onClick, params, route, type } = props;

    const renderCardContents = ({
        cardId,
        svg,
        text,
        buttonText,
        tooltipId,
        tooltipText,
        animateButton
    }) => (
        <Card
            id={cardId}
            hoverBorderColor={type === 'custom' ? '' : 'vividNavy'}
            backgroundColor={
                type === 'custom' ? 'rgba(217, 225, 226, 0.3)' : 'white'
            }
            borderRadius="2px">
            <Layout
                flexDirection="column"
                vAlign="center"
                flex
                className={style.cardContentWrapper}>
                <Layout
                    flexDirection="column"
                    hAlign="center"
                    vAlign="center"
                    flex
                    className={style.cardContents}>
                    <div className={style.selectorIcon}>
                        <Icon src={svg} />
                    </div>

                    <div className={style.selectorText}>
                        <Text type="h5">{text}</Text>
                    </div>

                    <div>
                        <Button
                            disabled={type === 'custom'}
                            notClickable={type === 'custom'}
                            className={
                                type === 'custom' ? style.customSelector : ''
                            }
                            animate={animateButton}>
                            {buttonText}
                        </Button>
                    </div>
                    <div className={style.descriptionTooltip}>
                        <Tooltip
                            icon={informationSvg}
                            size={3}
                            id={tooltipId}
                            width={type === 'custom' ? 500 : 224}>
                            {tooltipText}
                        </Tooltip>
                    </div>
                </Layout>
            </Layout>
        </Card>
    );

    return (
        <Layout
            className={
                style.selectorBox +
                (type === 'custom' ? ' ' + style.disabledSelectorBox : '')
            }
            onClick={onClick}>
            {route ? (
                <UISref to={route} params={params}>
                    {renderCardContents(props)}
                </UISref>
            ) : (
                renderCardContents(props)
            )}
        </Layout>
    );
};

CardSelector.propTypes = {
    onClick: PropTypes.func.isRequired,
    params: PropTypes.object,
    route: PropTypes.string,
    cardId: PropTypes.string.isRequired,
    svg: PropTypes.string.isRequired,
    text: PropTypes.string.isRequired,
    buttonText: PropTypes.string.isRequired,
    tooltipId: PropTypes.string.isRequired,
    tooltipText: PropTypes.string.isRequired,
    animateButton: PropTypes.bool
};

export default CardSelector;
