import { createAction } from '@reduxjs/toolkit';
import {
    ADD_FILE,
    ADD_FILES,
    UPDATE_FILE,
    UPDATE_FILES,
    REMOVE_FILE,
    REMOVE_ALL_FILES,
    SET_FILE_STATUS
} from '../constants';

export const addFile = createAction(ADD_FILE, file => {
    return {
        payload: { file }
    };
});

export const addFiles = createAction(ADD_FILES, files => {
    return {
        payload: { files }
    };
});

export const updateFile = createAction(UPDATE_FILE, ({ file, index }) => {
    return {
        payload: { file, index }
    };
});

export const updateFiles = createAction(UPDATE_FILES, files => {
    return {
        payload: { files }
    };
});

export const removeFile = createAction(REMOVE_FILE, file => {
    return {
        payload: { file }
    };
});

export const removeAllFiles = createAction(REMOVE_ALL_FILES);

export const setFileStatus = createAction(SET_FILE_STATUS, (id, status) => {
    return {
        payload: { id, status }
    };
});
