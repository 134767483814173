import React from 'react';

import { Card, Text, Layout } from 'maslow';
import styles from './styles.scss';

import DeliveryProgress from '../DeliveryProgress';
import PerformanceOverview from '../PerformanceOverview';
import ScheduleProgress from '../ScheduleProgress';

const PlanDetails = ({ data }) => {
    const {
        deliveryProgressData,
        performanceOverviewData,
        scheduleProgressData
    } = data;

    return (
        <Card backgroundColor="white" className={styles.card}>
            <Layout flexDirection="row" className={styles.table}>
                <div className={styles.title}>
                    <Text type="h4" className={styles.titleText}>
                        Plan Details
                    </Text>
                </div>
                <DeliveryProgress
                    data={deliveryProgressData}></DeliveryProgress>
                <PerformanceOverview
                    data={performanceOverviewData}></PerformanceOverview>
                <ScheduleProgress
                    data={scheduleProgressData}></ScheduleProgress>
            </Layout>
        </Card>
    );
};

export default PlanDetails;
