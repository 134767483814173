export const PROD = 'prod';

export const EXTERNAL = 'external';
export const INTERNAL = 'internal';
export const LOCAL = 'local';
export const NATIONAL = 'national';
export const POLITICAL = 'political';
export const COMMERCIAL = 'commercial';
export const UNSET = 'unset';

export const VIDEO = 'Video';
export const DISPLAY = 'Display';
export const SOCIAL_MEDIA = 'Social Media';
export const LINEAR_TV = 'Linear TV';
export const PRE_MID_ROLL = 'Pre/Mid Roll';
export const OTT = 'OTT';
export const BANNERS = 'Banners';
export const AD_MESSENGER = 'AdMessenger';
export const FACEBOOK = 'Facebook';
export const INSTAGRAM = 'Instagram';

export const IMAGE_TYPE = 'image';
export const VIDEO_TYPE = 'video';

export const STATE_CATEGORY = 'State';
export const POLITICAL_STATE = 'PoliticalState';
export const POLITICAL_DISTRICTS = 'PoliticalDistricts';

export const CTR = 'CTR';
export const VCR = 'VCR';

export const ALL = 'all';
export const DRAFT = 'Draft';
export const SUBMITTED = 'Submitted';
export const COMPLETED = 'Completed';
export const LIVE = 'Live';
export const PENDING = 'Pending';
export const NEW = 'new';
export const BOOK = 'book';

export const IDLE = 'idle';
export const IN_PROGRESS = 'in_progress';
export const INVALID = 'invalid';
export const VALID = 'valid';
export const EMPTY = 'empty';

export const MODIFIED_DATE = 'modifiedDate';
export const START_DATE = 'startDate';
export const BUDGET = 'budget';
export const NAME = 'name';
export const ADVERTISER_NAME = 'advertiserName';
export const END_DATE = 'endDate';
export const ID = 'id';
export const STATE = 'state';

export const SAVED_AUDIENCES = 'Saved Audiences';
export const PRODUCTION = 'production';
export const STAGING = 'staging';
export const DEVELOPMENT = 'development';

export const ACTUAL_USER = 'actualUser';
export const TESTER = 'tester';

export const REMOVE_PLAN = 'Remove';
export const EDIT_CREATIVES = 'Edit Creatives';
export const RESTART_CREATIVE = 'Restart this creative';
export const PAUSE_CREATIVE = 'Pause this creative';

export const WARNING = 'warning';
export const ERROR = 'error';
export const INFO = 'info';

export const BUILDER = 'builder';

export const AUTOMOTIVE = 'automotive';
export const DEMOGRAPHIC = 'demographic';
export const LIFESTYLE = 'lifestyle';
export const VIEWERSHIP = 'viewership';

export const NETWORKS = 'networks';

export const VALIDATION_ERROR_PAY_STRIPE_CC_CARD_INVALID =
    'validation_error_pay_stripe_cc_card_invalid';
export const VALIDATION_ERROR_PAY_STRIPE_DATE_INCOMPLETE =
    'validation_error_pay_stripe_date_incomplete';
export const VALIDATION_ERROR_PAY_STRIPE_CVC_INCOMPLETE =
    'validation_error_pay_stripe_cvc_incomplete';
export const VALIDATION_ERROR_PAY_STRIPE_CC_CARD_DECLINED =
    'validation_error_pay_stripe_cc_card_declined';

export const CUSTOM_LIST_ERROR_DELETING_UPLOAD =
    'CUSTOM_LIST_ERROR_DELETING_UPLOAD';

export const LOCATION_PAGE = 'location-page';
export const AUDIENCE_PAGE = 'audience-page';
export const NETWORKS_PAGE = 'networks-page';
export const BUDGET_PAGE = 'budget-page';
export const SCHEDULE_PAGE = 'schedule-page';

export const PLANS_REMOVE_PLAN = 'plans-remove-plan';
export const PLANS_CANCEL_REMOVE = 'plans-cancel-remove';
export const PLANS_RETURN_TO_PLAN_LIST = 'plans-return-to-plan-list';
export const ASCENDING_ORDER = 'asc';
export const DESCENDING_ORDER = 'desc';

export const MEDIA_PLAN_CREATE_COMMERCIAL_CAMPAIGN =
    'mediaplan.createcommercialcampaign';
export const MEDIA_PLAN_CREATIVE_ADSPECS_INTERNAL_PDF =
    'mediaplan.creativeadspecsinternalpdf';
export const MEDIA_PLAN_CREATIVE_ADSPECS_EXTERNAL_PDF =
    'mediaplan.creativeadspecsexternalpdf';
export const MEDIA_PLAN_CAN_PAY_LATER = 'mediaplan.canpaylater';
export const CAN_NOT_BOOK = 'mediaplan.cannotbook';
