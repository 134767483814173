import { replaceHyphenWithSlash, validateArg } from '@lib';
import { formatDates } from '@services/plans';

export default (arr, prop, isDesc) => {
    validateArg(arr, 'array', 1);
    validateArg(prop, 'string', 2);
    const sorted = arr.slice();

    isDesc
        ? sorted.sort(
              (a, b) =>
                  new Date(replaceHyphenWithSlash(formatDates(b)[prop])) -
                  new Date(replaceHyphenWithSlash(formatDates(a)[prop]))
          )
        : sorted.sort(
              (a, b) =>
                  new Date(replaceHyphenWithSlash(formatDates(a)[prop])) -
                  new Date(replaceHyphenWithSlash(formatDates(b)[prop]))
          );

    return sorted;
};
