import { combineReducers } from 'redux';
import { isPlanOpened } from './isPlanOpened';
import { loadingScreen } from './loadingScreen';
import { flyoutNavStatus } from './flyoutNavStatus';
import { isOnBookPage } from './isOnBookPage';
import { isEditCreatives } from './isEditCreatives';
import { toasts } from './toasts';
import { showPayNow } from './showPayNow';
import { isRanker } from './isRanker';
import { isMediaPlanVisited } from './isMediaPlanVisited';

export const ui = combineReducers({
    flyoutNavStatus,
    isOnBookPage,
    isPlanOpened,
    loadingScreen,
    isEditCreatives,
    toasts,
    showPayNow,
    isRanker,
    isMediaPlanVisited
});
