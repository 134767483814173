import { AD_MESSENGER, INSTAGRAM, FACEBOOK } from '@config';
import { INTERNAL, EXTERNAL, NATIONAL, COMMERCIAL } from '@config';

export default {
    name: 'Creative',
    description: 'Creative Feature Component.',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan'],
    sections: ['creative'],
    permissions: [],
    data: {
        creativeText: {
            ['EDIT']: {
                pageTitle: 'Edit Your Creatives',
                dropzoneTitle: 'Drag to add more files'
            },
            ['NEW']: {
                pageTitle: 'Upload Your Creatives',
                dropzoneTitle: 'Drag media here'
            }
        },
        creativeUrls: [
            {
                name: 'Destination',
                label: 'Click-through URL',
                placeholder: 'Click-through URL',
                errorText: 'Please enter a valid click-through url.',
                icon: ''
            },
            {
                name: 'AdMessenger',
                label: 'AdMessenger URL',
                placeholder: 'AdMessenger URL',
                errorText: 'Not a valid url.',
                icon: ''
            },
            {
                name: 'Facebook',
                label: 'Facebook URL',
                placeholder: 'Facebook URL',
                errorText: 'Not a valid url.',
                icon: ''
            },
            {
                name: 'Instagram',
                label: 'Instagram URL',
                placeholder: 'Instagram URL',
                errorText: 'Not a valid url.',
                icon: ''
            }
        ],
        routesTo: {
            plans: {
                to: 'app.plans',
                userFilters: [EXTERNAL, INTERNAL]
            },
            media: {
                to: 'app.plan.networks',
                userFilters: [NATIONAL, COMMERCIAL]
            },
            mediaPlan: {
                to: 'app.plan.mediaPlan',
                userFilters: [EXTERNAL, INTERNAL]
            }
        }
    },
    attributes: {},
    modifications: {}
};
