import React from 'react';

import { Layout, Text } from 'maslow';
import { BookButton } from './BookButton';
import styles from './styles.scss';

const WhatsNext = props => {
    return (
        <Layout fillParent>
            <Layout
                flexDirection="column"
                className={styles.wrapper}
                flex
                hAlign="center">
                <Text type="h2" className={styles.title}>
                    What's next?
                </Text>
                <BookButton />
            </Layout>
        </Layout>
    );
};

export default WhatsNext;
