import React, { useReducer, useEffect, memo } from 'react';
import { Layout, Input, Text } from 'maslow';
import { TransitionGroup, CSSTransition } from 'react-transition-group';

import searchIcon from '@public/img/Search.svg';
import Skeleton from 'react-loading-skeleton';
import Segment from './Segment';
import { listItem } from './Segment/segment.scss';
import styles from './search.scss';

import { checkForData, testZipString } from '@services';
import searchHelper from './searchHelper';
import { SEARCH_INPUT } from '@config/idConstants';

const EMPTY_VALUE_INTERVAL_TIME = 350;
const HAS_VALUE_INTERVAL_TIME = 200;
let searchInputEl;

const buildSegments = (segmentArray, onClick) => {
    return segmentArray.length > 0 ? (
        <div className={styles.dropdown}>
            <ul className={styles.ulElem}>
                {segmentArray.map((segmentData, i) => {
                    const { type } = segmentData;
                    return (
                        <Segment
                            data={segmentData}
                            onClick={onClick}
                            key={`${type}.${i}`}
                            segmentId={i}
                        />
                    );
                })}
            </ul>
        </div>
    ) : null;
};

const initialState = {
    styles: styles.initialInputStyles,
    segments: null,
    resultsCount: 0,
    value: '',
    zipString: false,
    dropDownData: [],
    isShowNoMatch: false,
    isSelectFirstItem: true,
    showLoader: false,
    showResults: false,
    showFocusState: false
};

const init = initialState => {
    return initialState;
};

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'updateInput':
            return {
                ...state,
                value: payload.value,
                showFocusState: !!payload.value,
                styles: [styles.activeInputStyles, styles.dropdownPresent].join(
                    ' '
                )
            };
        case 'updateData':
            return {
                ...state,
                styles: [styles.activeInputStyles, styles.dropdownPresent].join(
                    ' '
                ),
                segments: buildSegments(payload.data, payload.onClick),
                resultsCount: checkForData(payload.data),
                zipString: testZipString(payload.value, payload.handleZips),
                dropDownData: payload.data,
                isShowNoMatch: !checkForData(payload.data),
                showFocusState: false
            };
        case 'resetData':
            return {
                ...state,
                styles: styles.activeInputStyles,
                segments: null,
                resultsCount: 0,
                zipString: false,
                dropDownData: [],
                isShowNoMatch: false,
                showLoader: false,
                showFocusState: false
            };
        case 'updateIsSelectFirstItem':
            return {
                ...state,
                isSelectFirstItem: payload.isSelectFirstItem
            };
        case 'showLoader':
            return {
                ...state,
                showResults: false,
                showLoader: true,
                showFocusState: false
            };
        case 'hideLoader': {
            return {
                ...state,
                showLoader: false
            };
        }
        case 'showResults': {
            return {
                ...state,
                showLoader: false,
                showResults: true,
                showFocusState: false
            };
        }
        case 'emptyActiveInput':
            return {
                ...state,
                showFocusState: true,
                styles: payload.styles,
                showLoader: false,
                isShowNoMatch: false
            };
        case 'emptyInput':
            return init(initialState);
        default:
            throw new Error();
    }
};

export default memo(
    ({
        searchLabel,
        onClickFunction,
        submitZips,
        searchRequest,
        focusDropdown
    }) => {
        const [state, stateDispatch] = useReducer(reducer, initialState, init);

        const handleOnClick = e => {
            searchHelper.onClick(e, stateDispatch);
        };

        const localOnClickFunction = newLocation => {
            onClickFunction(newLocation);
            stateDispatch({ type: 'emptyInput' });
            searchInputEl.focus();
        };

        const handleOnEnter = e => {
            let dataIndex = 0;
            let entryIndex = 0;
            if (e.target.tagName !== 'INPUT' && e.target.id) {
                const splittedId = e.target.id.split('.');
                dataIndex = splittedId[0];
                entryIndex = splittedId[1];
            }

            const hasEntries =
                state.dropDownData.length &&
                state.dropDownData[dataIndex].entries.length;
            if (showDropDown(state.value)) {
                if (state.zipString && !state.resultsCount) {
                    submitZips(e, state.value, searchInputEl);
                } else if (hasEntries) {
                    onClickFunction(
                        state.dropDownData[dataIndex].entries[entryIndex]
                    );
                }
            }
        };

        useEffect(() => {
            searchInputEl = document.querySelector('.searchInput');
            window.addEventListener('click', handleOnClick);
            return () => {
                window.removeEventListener('click', handleOnClick);
            };
        }, []);

        const handleFocus = () => {
            if (state.value) {
                stateDispatch({
                    type: 'updateIsSelectFirstItem',
                    payload: {
                        isSelectFirstItem: true
                    }
                });
            } else {
                const dropdownStyle = focusDropdown
                    ? styles.dropdownPresent
                    : '';
                stateDispatch({
                    type: 'emptyActiveInput',
                    payload: {
                        styles: [styles.activeInputStyles, dropdownStyle].join(
                            ' '
                        )
                    }
                });
            }
        };

        const onKeyDown = e => {
            searchHelper.onKeyDown(
                e,
                handleOnEnter,
                stateDispatch,
                state.isSelectFirstItem
            );
        };

        const onMouseOver = e => {
            searchHelper.onMouseOver(e, stateDispatch);
        };

        useEffect(() => {
            window.addEventListener('keydown', onKeyDown);
            const listElm = document.querySelector('ul');
            if (listElm) {
                listElm.addEventListener('keyup', searchHelper.onKeyUp);
                listElm.addEventListener('mouseover', onMouseOver);
                listElm.addEventListener(
                    'mouseleave',
                    searchHelper.onMouseLeave
                );
            }
            return () => {
                window.removeEventListener('keydown', onKeyDown);
                if (listElm) {
                    listElm.removeEventListener('keyup', searchHelper.onKeyUp);
                    listElm.removeEventListener('mouseover', onMouseOver);
                    listElm.removeEventListener(
                        'mouseleave',
                        searchHelper.onMouseLeave
                    );
                }
            };
        });

        const updateInputValue = value => {
            value
                ? stateDispatch({ type: 'showLoader' })
                : stateDispatch({ type: 'hideLoader' });
            stateDispatch({ type: 'updateInput', payload: { value } });
        };

        const showDropDown = value => {
            return (value.length > 1 && isNaN(value)) || value.length > 3;
        };

        const searchOnChange = value => {
            if (showDropDown(value)) {
                searchRequest(value)
                    .then(data => {
                        stateDispatch({
                            type: 'updateData',
                            payload: {
                                data,
                                value,
                                onClick: localOnClickFunction,
                                handleZips: !!submitZips
                            }
                        });
                        stateDispatch({
                            type: 'showResults'
                        });
                    })
                    .catch(console.error);
            } else {
                const dropdownStyle = focusDropdown
                    ? styles.dropdownPresent
                    : '';
                stateDispatch({
                    type: value ? 'resetData' : 'emptyActiveInput',
                    payload: {
                        styles: [styles.activeInputStyles, dropdownStyle].join(
                            ' '
                        )
                    }
                });
            }
        };

        const renderLoader = () => {
            return (
                <div className={styles.dropdown}>
                    <ul className={styles.loader}>
                        <Skeleton duration={1.4} count={8} height={20} />
                    </ul>
                </div>
            );
        };

        const renderResults = () => {
            if (state.segments && state.resultsCount) {
                return state.segments;
            }

            if (state.isShowNoMatch && !state.zipString) {
                return (
                    <p className={`${styles.dropdown} ${styles.noMatches}`}>
                        No matches
                    </p>
                );
            }

            if (state.zipString && !state.resultsCount) {
                return (
                    <div
                        className={styles.dropdown}
                        onClick={e =>
                            submitZips(e, state.value, searchInputEl)
                        }>
                        <ul className={styles.ulElem}>
                            <li tabIndex="-1" className={listItem}>
                                Submit ZIPs
                            </li>
                        </ul>
                    </div>
                );
            }
        };

        const renderFocusState = () => {
            return (
                <CSSTransition
                    classNames="focusDropdownSlide"
                    timeout={300}
                    key="focusDropdownSlide">
                    <div className={styles.focusDropdown}>
                        <Text type="body">
                            You can copy & paste your zip-codes here, allowing
                            Athena to deliver the matched counts faster. (Max
                            200 at a time, 500 total)
                        </Text>
                    </div>
                </CSSTransition>
            );
        };

        return (
            <Layout className={styles.layoutInitialStyles}>
                <form
                    onSubmit={e => submitZips(e, state.value, searchInputEl)}
                    autoComplete="off">
                    <Input
                        id={SEARCH_INPUT}
                        name="search"
                        value={state.value}
                        label={searchLabel}
                        theme="base"
                        icon={searchIcon}
                        doneTypingInterval={
                            state.value && state.value <= 4
                                ? HAS_VALUE_INTERVAL_TIME
                                : EMPTY_VALUE_INTERVAL_TIME
                        }
                        doneTyping={searchOnChange}
                        onChange={updateInputValue}
                        className={[state.styles, 'searchInput'].join(' ')}
                        onFocus={handleFocus}
                    />
                </form>
                <TransitionGroup>
                    {state.showFocusState &&
                        focusDropdown &&
                        renderFocusState()}
                </TransitionGroup>
                {state.showLoader && renderLoader()}
                {state.showResults && renderResults()}
            </Layout>
        );
    }
);
