import React from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import requestHandlers from '@requestHandlers';
import { updateAttributes } from '@state/plan/actions/attributes';
import { Layout, Text, Popup, Content, Icon, Input, Button } from 'maslow';
import closeSvg from '@public/img/close.svg';

import {
    planSelector,
    getPendoMetaDataSelector,
    bookPlanObjectSelector
} from '@selectors/';

import {
    MEDIA_PLAN_CLOSE_SAVE_POPUP,
    MEDIA_PLAN_POPUP_NAME,
    MEDIA_PLAN_POPUP_ADVERTISER_NAME,
    MEDIA_PLAN_POPUP_SAVE
} from '@config/idConstants';
import shared from '@sharedStyle/mediaPlanTopbar.scss';
import styles from '../styles.scss';
import { pendoService } from '@services/tracking';

const SavePlanPopup = props => {
    const { isPopupOpen, togglePopup, dispatch, stateDispatch } = props;
    const {
        attributes: { name, advertiser }
    } = useSelector(planSelector);
    const pendoMetaData = useSelector(getPendoMetaDataSelector('save'));
    const { saveMediaPlanObject } = useSelector(bookPlanObjectSelector);

    const onChange = (value = '', type = '') => {
        if (type === 'name') dispatch(updateAttributes({ name: value }));
        if (type === 'advertiser')
            dispatch(updateAttributes({ advertiser: value }));
    };

    const savePlan = () => {
        togglePopup();
        stateDispatch({
            type: 'updateValue',
            payload: {
                saveClicked: true
            }
        });

        pendoService.track('save', pendoMetaData);

        requestHandlers.plans
            .saveMediaPlan(saveMediaPlanObject)
            .then(() =>
                stateDispatch({
                    type: 'updateValue',
                    payload: {
                        planSaved: true
                    }
                })
            )
            .catch(e => {
                console.error(e);
            });
    };

    return (
        <Popup
            show={isPopupOpen}
            borderRadius="2px"
            boxShadow="1px 1px 3px 0 rgba(178,178,178,0.4)"
            borderColor="vividNavy"
            borderWidth="2px"
            left="75px"
            className={shared.popupWrapper}>
            <Content fillParent>
                <Layout
                    flexDirection="row"
                    hAlign="space-between"
                    className={shared.header}>
                    <Text type="h5" color="deepBlue">
                        Name Your Plan
                    </Text>

                    <div
                        id={MEDIA_PLAN_CLOSE_SAVE_POPUP}
                        className={styles.closeIcon}
                        onClick={togglePopup}>
                        <Icon src={closeSvg} size="2" onClick={togglePopup} />
                    </div>
                </Layout>

                <Layout flexDirection="column" className={shared.marginTop}>
                    <Text type="body" color="vividNavy">
                        Plan Name
                    </Text>

                    <Input
                        id={MEDIA_PLAN_POPUP_NAME}
                        name="plan"
                        value={name}
                        onChange={value => onChange(value, 'name')}
                    />

                    <Text
                        type="body"
                        color="vividNavy"
                        className={shared.marginTop}>
                        Advertiser Name
                    </Text>

                    <Input
                        id={MEDIA_PLAN_POPUP_ADVERTISER_NAME}
                        name="advertiser"
                        value={advertiser}
                        onChange={value => onChange(value, 'advertiser')}
                    />
                </Layout>

                <Layout
                    flexDirection="column"
                    hAlign="center"
                    className={shared.buttonLayout}>
                    <Layout
                        flexDirection="column"
                        className={shared.buttonWrapper}>
                        <Button
                            id={MEDIA_PLAN_POPUP_SAVE}
                            onClick={savePlan}
                            disabled={!name || !advertiser}>
                            Go!
                        </Button>
                    </Layout>
                </Layout>
            </Content>
        </Popup>
    );
};

SavePlanPopup.propTypes = {
    isPopupOpen: PropTypes.bool.isRequired,
    togglePopup: PropTypes.func.isRequired,
    stateDispatch: PropTypes.func.isRequired
};

export default SavePlanPopup;
