import { combineReducers } from 'redux';

import { selectedLocations } from './selectedLocations';
import { mapImageDataUrls } from './mapImageDataUrls';
import { geoJson } from './geoJson';
import { states } from './states';

export const location = combineReducers({
    selectedLocations,
    mapImageDataUrls,
    geoJson,
    states
});
