import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Layout, Input, Button, Text } from 'maslow';

import requestHandlers from '@requestHandlers';
import { loginService } from '@services';
import { isEmptyString } from '@lib';

import style from '../login.scss';
import shared from '@sharedStyle/loginAndSignUp.scss';

import {
    LOGIN_SEND_LINK,
    LOGIN_SEND_LINK_BACK,
    LOGIN_SEND_LINK_EMAIL_INPUT
} from '@config/idConstants';

const SendLink = ({
    ls,
    errors,
    STATUSES,
    INTERVAL_TIME,
    DISPLAY_TEXT_STATES,
    buttonText,
    updateComponentState
}) => {
    const [email, setEmail] = useState('');
    const [emailStatus, setEmailStatus] = useState(STATUSES.IDLE);
    const [emailError, setEmailError] = useState('');
    const [enableSendLink, setEnableSendLink] = useState(false);
    const [buttonAnimation, setButtonAnimation] = useState(false);

    const emailDoneTyping = value => {
        const hasValidEmail = ls.hasValidEmail(value);
        setEmail(value);
        setEnableSendLink(hasValidEmail);
        if (hasValidEmail) {
            setEmailStatus(STATUSES.VALID);
        } else {
            setEmailError(errors.emailError);
            setEmailStatus(
                isEmptyString(value) ? STATUSES.IDLE : STATUSES.ERROR
            );
        }
    };

    const onSendLink = () => {
        if (buttonAnimation) return false;
        setButtonAnimation(true);

        requestHandlers.auth
            .passwordResetToken(email)
            .then(({ status }) => {
                if (status === 200) {
                    updateComponentState(
                        'displayTextState',
                        DISPLAY_TEXT_STATES.PASSWORD_LINK_SENT
                    );
                    updateComponentState('showPasswordLinkSent', true);
                    updateComponentState('showSendLink', false);
                }
                setButtonAnimation(false);
            })
            .catch(err => {
                setButtonAnimation(false);
                console.error('error creating token', err);
            });
    };

    const onBack = () => {
        updateComponentState('showSendLink', false);
        updateComponentState('displayTextState', DISPLAY_TEXT_STATES.INITIAL);
        ls.resetUserDetails();
    };

    return (
        <React.Fragment>
            <div>
                <Input
                    id={LOGIN_SEND_LINK_EMAIL_INPUT}
                    name="email"
                    theme="smallLine"
                    placeholder="Email Address"
                    status={emailStatus}
                    errorText={emailError}
                    doneTypingInterval={INTERVAL_TIME}
                    doneTyping={emailDoneTyping}
                />
            </div>

            <Layout
                flexDirection="column"
                hAlign="center"
                className={style.sendLinkBtnWrapper}>
                <Button
                    id={LOGIN_SEND_LINK}
                    className={shared.btnSize}
                    onClick={onSendLink}
                    disabled={!enableSendLink}
                    animate={buttonAnimation}>
                    {buttonText}
                </Button>
            </Layout>

            <Layout hAlign="center" className={style.extraSpacing}>
                <Text
                    id={LOGIN_SEND_LINK_BACK}
                    type="body"
                    color="vividNavy"
                    onClick={onBack}
                    className={style.pointerCursor}>
                    Back
                </Text>
            </Layout>
        </React.Fragment>
    );
};

SendLink.propTypes = {
    ls: PropTypes.object.isRequired,
    errors: PropTypes.object.isRequired,
    STATUSES: PropTypes.object.isRequired,
    INTERVAL_TIME: PropTypes.number.isRequired,
    DISPLAY_TEXT_STATES: PropTypes.object.isRequired,
    buttonText: PropTypes.string.isRequired,
    updateComponentState: PropTypes.func.isRequired
};

export default SendLink;
