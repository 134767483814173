import React, { useState } from 'react';

import { Layout, Text, Icon } from 'maslow';
import exportSvg from '@public/img/export-blue.svg';

import PdfExportPopup from './PdfExportPopup';

import styles from '@sharedStyle/mediaPlanTopbar.scss';

import { MEDIA_PLAN_PDF_EXPORT_OPEN_POPUP } from '@config/idConstants';

export default function (version, feature) {
    const PdfExport = ({ disabledStyle }) => {
        const [exportPdf, setExportPdf] = useState(false);

        const toggleExportPopup = () => {
            setExportPdf(!exportPdf);
        };

        return (
            <React.Fragment>
                <Layout
                    id={MEDIA_PLAN_PDF_EXPORT_OPEN_POPUP}
                    flexDirection="row"
                    vAlign="center"
                    onClick={toggleExportPopup}
                    style={disabledStyle}
                    className={styles.topTextWrapper}>
                    <Icon
                        src={exportSvg}
                        size="2"
                        className={styles.marginRight}
                    />
                    <Text type="h5" color="vividNavy">
                        Export PDF
                    </Text>
                </Layout>

                <PdfExportPopup
                    isExportPdf={exportPdf}
                    toggleExportPopup={toggleExportPopup}
                />
            </React.Fragment>
        );
    };
    return PdfExport;
}
