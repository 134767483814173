import React from 'react';
import { useSelector } from 'react-redux';
import { getFeature } from '@delivery/react';
import { createSelector } from 'reselect';

import { Layout, Text } from 'maslow';

import Save from './Save';

import styles from './styles.scss';
import { planGeneratedSelector, hasPermissionSelector } from '@selectors/';

const disabledStyle = {
    pointerEvents: 'none',
    opacity: 0.25
};

const disabledStyleSelector = createSelector(
    planGeneratedSelector,
    planGenerated => (planGenerated ? {} : disabledStyle)
);

export default function (version, feature) {
    return function () {
        const disabledStyle = useSelector(disabledStyleSelector);
        const PdfExport = getFeature('PdfExport', 'pdfExport');
        const isShowPdfExport = useSelector(
            hasPermissionSelector('mediaplan.exportsummarypdf')
        );

        return (
            <Layout className={styles.wrapper}>
                <Layout
                    flexDirection="row"
                    vAlign="center"
                    hAlign="space-between"
                    className={styles.innerWrapper}
                    flex>
                    <Text type="h3" className={styles.title}>
                        Review Your Media Plan
                    </Text>
                    <Layout flexDirection="row" vAlign="center">
                        <Save disabledStyle={disabledStyle} />

                        {isShowPdfExport && (
                            <PdfExport disabledStyle={disabledStyle} />
                        )}
                    </Layout>
                </Layout>
            </Layout>
        );
    };
}
