import { INTERNAL, NATIONAL, COMMERCIAL } from '@config';

export default {
    name: 'DaypartExclusions',
    description: 'DaypartExclusions feature',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan'],
    sections: ['daypartExclusions'],
    customFilters: {
        type: [INTERNAL],
        role: [COMMERCIAL]
    },
    permissions: [],
    data: {
        exclusionsLimit: 2
    },
    attributes: {},
    modifications: {}
};
