import { createAction } from '@reduxjs/toolkit';

import { ADD_TOAST, REMOVE_TOAST, REMOVE_ALL_TOASTS } from '../constants';

export const addToast = createAction(ADD_TOAST, message => {
    return {
        payload: {
            message
        }
    };
});

export const removeToast = createAction(REMOVE_TOAST, id => {
    return {
        payload: {
            id
        }
    };
});

export const removeAllToasts = createAction(REMOVE_ALL_TOASTS);
