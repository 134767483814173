import React from 'react';

export default function (Component, props) {
    return class extends React.Component {
        constructor (props) {
            super(props);
        }

        render () {
            return (
                <Component {...this.props} {...props} />
            );
        }
    }
}
