import React, { useState, useEffect } from 'react';

import { Layout } from 'maslow';

import { emailAndPasswordErrors } from '@config/config.login';
import { loginService } from '@services';

import {
    LeftIllustrationSection,
    TermsAndConditions,
    TopLogoSection
} from '@components/common';
import CreateAccount from './CreateAccount';

import shared from '@sharedStyle/loginAndSignUp.scss';

const DISPLAY_TEXT_STATES = {
    NEW_USER: 'newUser',
    VERIFY_ACCOUNT: 'verifyAccount'
};

export default function (version, feature) {
    const ls = loginService(feature);
    const { titles } = feature.data;

    const SignUp = props => {
        const [displayTextState, setDisplayTextState] = useState(
            DISPLAY_TEXT_STATES.NEW_USER
        );
        const [showSignUpPage, setShowSignUpPage] = useState(false);

        useEffect(() => {
            ls.resetUserDetails();
        }, []);

        const displayText = ls.getDisplayText(titles, displayTextState);

        return (
            <Layout flexDirection="row" className={shared.wrapper}>
                <LeftIllustrationSection />

                <Layout flexDirection="column" className={shared.rightWrapper}>
                    <TopLogoSection displayText={displayText} />

                    {showSignUpPage && (
                        <CreateAccount
                            ls={ls}
                            errors={emailAndPasswordErrors}
                            DISPLAY_TEXT_STATES={DISPLAY_TEXT_STATES}
                            displayText={displayText}
                            setDisplayTextState={setDisplayTextState}
                            setShowSignUpPage={setShowSignUpPage}
                            transition={props.transition}
                        />
                    )}

                    <TermsAndConditions />
                </Layout>
            </Layout>
        );
    };

    return SignUp;
}
