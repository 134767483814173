import request from '../request';
import axios from 'axios';

export default function (customListId) {
    return request({
        method: 'DELETE',
        url: `custom_list/${customListId}/`,
        headers: {}
    }).then(response => {
        return response;
    }).catch(error => {
        throw Error(error);
    });
};
