import React from 'react';

import { Text, Layout, Bar } from 'maslow';
import { datesHandler, getPercentFromValue } from '@lib';

import calendarSvg from '@public/img/calendar.svg';

import styles from './styles.scss';

export default ({ data }) => {
    const { startDate, endDate } = data;
    const currentDate = datesHandler.newDate();
    const totalDays = datesHandler.differenceInDays(endDate, startDate);
    const tempDaysPassed = datesHandler.differenceInDays(
        currentDate,
        startDate
    );
    const daysPassed =
        tempDaysPassed > totalDays
            ? totalDays
            : tempDaysPassed < 0
            ? 0
            : tempDaysPassed;
    const daysLeft = totalDays - daysPassed;
    const percentage = getPercentFromValue(daysPassed, totalDays);

    return (
        <div className={styles.scheduleProgress}>
            <Text type="input" className={styles.boxTitle}>
                <div className={styles.calendarImg}>
                    <img src={calendarSvg} />
                </div>
                Schedule Progress
            </Text>
            <Bar percentage={percentage}></Bar>
            <Layout hAlign="space-between">
                <div>
                    <Text type="body" className={styles.startDateCaption}>
                        Start Date
                    </Text>
                    <Text className={styles.startDateStat}>
                        {datesHandler.format(startDate, 'LLL do, yyyy')}
                    </Text>
                </div>
                <div>
                    <Text className={styles.daysLeft}>
                        <span
                            className={
                                styles.bold
                            }>{`${daysLeft} days left`}</span>
                        <span>{` of ${totalDays}`}</span>
                    </Text>
                </div>
                <div>
                    <Text type="body" className={styles.endDateCaption}>
                        End Date
                    </Text>
                    <Text className={styles.endDateStat}>
                        {datesHandler.format(endDate, 'LLL do, yyyy')}
                    </Text>
                </div>
            </Layout>
        </div>
    );
};
