import { createReducer } from '@reduxjs/toolkit';
import { SET_ALL_NETWORKS } from '../constants';

const initialState = null;

const setAllNetworks = (state, action) => {
    const { networks } = action.payload;
    return networks;
};

export const allNetworks = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_ALL_NETWORKS, setAllNetworks)
        .addDefaultCase((state, _) => state);
});
