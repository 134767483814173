import request from '../request';

export default function ({
    saveMediaPlanObject,
    bookPlanObject,
    mediaPlanBookObject,
    planStatus
}) {
    return request({
        method: 'POST',
        url: `plans/book/`,
        data: {
            saveMediaPlanObject,
            bookPlanObject,
            mediaPlanBookObject,
            planStatus
        }
    })
        .then(response => {
            return response;
        })
        .catch(error => {
            throw Error(error);
        });
}
