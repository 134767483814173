import React, { useEffect, useReducer } from 'react';
import { UISref } from '@uirouter/react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { Layout, Text, Icon, Spinner } from 'maslow';
import saveSvg from '@public/img/Save-Arrow.svg';
import successSvg from '@public/img/save-success.svg';
import SavePlanPopup from '../SavePlanPopup';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';

import styles from './styles.scss';
import shared from '@sharedStyle/mediaPlanTopbar.scss';
import { PLAN_STATUSES } from '@config/config.planStatus';
import {
    planSelector,
    planSaveStatusSelector,
    mediaPlanSelector
} from '@selectors/';

import {
    MEDIA_PLAN_OPEN_SAVE_POPUP,
    MEDIA_PLAN_VIEW_SAVED_PLANS
} from '@config/idConstants';

const initialState = {
    isPopupOpen: false,
    savePlanText: PLAN_STATUSES.INITIAL,
    saveClicked: false,
    planSaved: false
};

const reducer = (state, action) => {
    const { type, payload } = action;

    switch (type) {
        case 'reset':
            return { ...state, ...initialState };
        case 'updateValue':
            return {
                ...state,
                ...payload
            };
        default:
            return {
                ...state
            };
    }
};

const Save = ({ disabledStyle }) => {
    const dispatch = useDispatch();
    const { planStatus } = useSelector(mediaPlanSelector);
    const planSaveStatus = useSelector(planSaveStatusSelector);

    const [state, stateDispatch] = useReducer(reducer, initialState);
    const { isPopupOpen, saveClicked, planSaved, savePlanText } = state;

    const { attributes } = useSelector(planSelector);

    useEffect(() => {
        if (
            !saveClicked &&
            planSaveStatus === PLAN_STATUSES.VIEW_PLAN_LIST &&
            savePlanText !== planSaveStatus
        ) {
            stateDispatch({
                type: 'updateValue',
                payload: {
                    savePlanText: PLAN_STATUSES.VIEW_PLAN_LIST
                }
            });
        }
    }, [planSaveStatus]);

    useEffect(() => {
        if (planSaved) {
            dispatch(setPlanStatus(PLAN_STATUSES.SAVED_DRAFT));
            stateDispatch({
                type: 'updateValue',
                payload: {
                    savePlanText: PLAN_STATUSES.SAVED
                }
            });
        }
    }, [planSaved]);

    useEffect(() => {
        if (saveClicked) {
            dispatch(setPlanStatus(PLAN_STATUSES.SAVING));
            stateDispatch({
                type: 'updateValue',
                payload: {
                    savePlanText: PLAN_STATUSES.SAVING
                }
            });
        }
    }, [saveClicked]);

    useEffect(() => {
        if (savePlanText === PLAN_STATUSES.SAVED) {
            setTimeout(() => {
                stateDispatch({
                    type: 'updateValue',
                    payload: {
                        savePlanText: PLAN_STATUSES.VIEW_PLAN_LIST
                    }
                });
            }, 1000);
        }
    }, [savePlanText]);

    useEffect(() => {
        if (
            savePlanText === PLAN_STATUSES.VIEW_PLAN_LIST &&
            planStatus !== PLAN_STATUSES.BOOKED
        ) {
            stateDispatch({
                type: 'reset'
            });
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        }
    }, [attributes.name, attributes.advertiser]);

    const togglePopup = () => {
        stateDispatch({
            type: 'updateValue',
            payload: {
                isPopupOpen: !isPopupOpen
            }
        });
    };

    return (
        <Layout>
            {savePlanText === PLAN_STATUSES.INITIAL && (
                <Layout
                    id={MEDIA_PLAN_OPEN_SAVE_POPUP}
                    flexDirection="row"
                    vAlign="center"
                    onClick={togglePopup}
                    style={disabledStyle}
                    className={shared.topTextWrapper}>
                    <Icon
                        src={saveSvg}
                        size="2"
                        className={shared.marginRight}
                    />
                    <Text type="h5" color="vividNavy">
                        Save
                    </Text>
                </Layout>
            )}
            {savePlanText === PLAN_STATUSES.SAVING && (
                <Layout flexDirection="row" vAlign="center">
                    <Layout
                        flexDirection="row"
                        vAlign="center"
                        className={styles.savingIcon}>
                        <Spinner
                            size="2"
                            margin="0"
                            className={styles.savingIcon}
                        />
                    </Layout>
                    <Text type="h5" color="slateGray">
                        Saving
                    </Text>
                </Layout>
            )}

            {savePlanText === PLAN_STATUSES.SAVED && (
                <Layout flexDirection="row" vAlign="center">
                    <Icon
                        src={successSvg}
                        size="2"
                        className={styles.successIcon}
                    />
                    <Text
                        type="h5"
                        color="green"
                        className={styles.successText}>
                        Saved
                    </Text>
                </Layout>
            )}

            {savePlanText === PLAN_STATUSES.VIEW_PLAN_LIST && (
                <UISref to={'app.main.plans'}>
                    <Text
                        id={MEDIA_PLAN_VIEW_SAVED_PLANS}
                        type="h5"
                        color="vividNavy"
                        className={shared.topTextWrapper}>
                        View plan list
                    </Text>
                </UISref>
            )}

            <SavePlanPopup
                isPopupOpen={isPopupOpen}
                togglePopup={togglePopup}
                stateDispatch={stateDispatch}
                dispatch={dispatch}
            />
        </Layout>
    );
};

Save.propTypes = {
    disabledStyle: PropTypes.object.isRequired
};

export default Save;
