import { createReducer } from '@reduxjs/toolkit';
import { SET_SAVED_AUDIENCES } from '../constants';
import { isArray } from '@lib';

const initialState = [];

const setSavedAudiences = (state, action) => {
    const { savedAudiences } = action.payload;
    return isArray(savedAudiences) ? savedAudiences : state;
};

export const savedAudiences = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_SAVED_AUDIENCES, setSavedAudiences)
        .addDefaultCase((state, _) => state);
});
