import * as d3 from 'd3';

const { select } = d3;

const buildSet = (i, isFull) => {
    return {
        sets: [i],
        size: 16,
        label: isFull ? `Audience ${i + 1}` : (i + 1).toString(),
        ref: i.toString()
    };
};

const buildLabels = labels => (labels.map(label => label + 1)).join(' + ');
const buildRef = labels => (labels.map(label => label)).join(' + ');
const buildIntersection = (sets, isFull) => {
    return {
        sets,
        size: isFull ? 4 : 2,
        label: isFull ? buildLabels(sets) : '',
        ref: buildRef(sets)
    };
};

export const GREY = 'rgb(217, 225, 226)';
export const FILL_COLORS = {
    '0': '#2E5EFF',
    '1': '#2396CF',
    '2': '#07667D',
    '0 + 1': '#0637CF',
    '0 + 2': '#003C65',
    '1 + 2': '#01257D',
    '0 + 1 + 2': '#000000'
};

export const buildSelected = sets => {
    return sets.reduce((acc, element) => {
        acc[element.ref] = element;
        return acc;
    }, {});
};


export const buildSets = (audiences, size) => {
    const isFull = (size === 'large');
    const sets = audiences.map((a, i) => buildSet(i, isFull));
    const result = [...sets];
    let isCentral = false;

    for (let i = 0; i < sets.length; i++) {
        const second = sets[i + 1];
        const secondNext = sets[i + 2];

        if (second) result.push(buildIntersection([i, i + 1], isFull));
        if (secondNext) result.push(buildIntersection([i, i + 2], isFull));
        if (second && secondNext) isCentral = true;
    }

    if (isCentral) result.push(buildIntersection([0, 1, 2], isFull));

    return result;
};

export const greyOut = el => {
    const selection = select(el);

    selection.select('path')
        .style('fill', '#D9E1E2')
        .style('stroke', '#1A64CD')
        .style('fill-opacity', 1);

    selection.select('text')
        .style('fill', '#000000');
};

export const removeGrayinOut = el => {
    const selection = select(el);

    selection.select('path')
        .style('fill', null)
        .style('stroke', null)
        .style('fill-opacity', null);

    selection.select('text')
        .style('fill', null);
};
