import request from '../request';

export default function (id) {
    const req = {
        method: 'GET',
        url: `locations/singleGeoJson?id=${id}`
    };

    return request(req);
}
