import featuresManager from '@featuresManager';

const EmptyComponent = () => null;

export const getFeature = (name, section, features) => {
    const feature = featuresManager.getFeature(name, section, features)[0];

    return feature ?
        require(`../../../app/components/features/${feature.name}/component`).component :
        EmptyComponent;
};
