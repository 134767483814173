import React, { useEffect, useRef, useState } from 'react';

import { Text, Layout } from 'maslow';

import buildBarChart from './buildBarChart';
import styles from './styles.scss';
import reportGraphConfig from '@config/reportGraphConfig';

const { blue, green } = reportGraphConfig.impressionsAndClicksColors;

const ImpressionsAndClicks = ({ data }) => {
    const container = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);

    const buildCircle = color => {
        const style = {
            backgroundColor: color
        };

        return <div style={style} className={styles.circle}></div>;
    };

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        buildBarChart(data, container);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [container.current, width]);

    return (
        <Layout flexDirection="row" className={styles.table}>
            <div className={styles.title}>
                <Text type="h5">
                    {buildCircle(blue)}
                    <span>Impressions</span>
                    {/* {buildCircle(green)}
                    <span>Clicks</span> */}
                </Text>
            </div>
            <div
                id="impressionsClicksGraph"
                className={styles.graphContainer}
                ref={container}>
                <div id="tooltip" className={styles.tooltip}>
                    <Layout flexDirection="column">
                        <div>
                            <Text id="tooltipDate"></Text>
                        </div>
                        <div className={styles.legend}>
                            <div>{buildCircle(blue)}</div>
                            <div>
                                <Text
                                    className={styles.legendText}
                                    id="tooltipImpressions">
                                    76,450
                                </Text>
                                <Text className={styles.caption}>
                                    Impressions
                                </Text>
                            </div>
                            <div>{buildCircle(green)}</div>
                            <div>
                                <Text
                                    className={styles.legendText}
                                    id="tooltipClicks">
                                    25,100
                                </Text>
                                <Text className={styles.caption}>Clicks</Text>
                            </div>
                        </div>
                    </Layout>
                </div>
            </div>
            <div className={styles.disclaimerTextWrapper}>
                <Text className={styles.disclaimerText}>
                    Please note that all time measures are presented in UTC
                    (Coordinated Universal Time).
                </Text>
            </div>
        </Layout>
    );
};

export default ImpressionsAndClicks;
