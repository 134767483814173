import { budgetConfig } from '@config/config.budget';
import requestHandlers from '@requestHandlers';
import { updateBudget } from '@state/budget/actions';
import { isEmpty, validateArg } from '@lib';
import { LINEAR_TV } from '@config/constants';

export const filterConfig = (
    { channels, subCampaigns },
    distributionConfig
) => {
    if (isEmpty(distributionConfig)) {
        throw new Error('distributionConfig is empty');
    }
    return {
        channels: channels.filter(channel =>
            getAvailableChannelKeys(distributionConfig).some(
                key => channel.key === key
            )
        ),
        subCampaigns: Object.values(subCampaigns).filter(subCampaign =>
            getAvailableSubcampaignKeys(distributionConfig).some(
                key => subCampaign.key === key
            )
        )
    };
};

export const filterSubs = (subCampaigns, subs) => {
    return subCampaigns.reduce((filtered, name) => {
        subs.forEach(sub => {
            if (name === sub.name) filtered.push(sub);
        });
        return filtered;
    }, []);
};

const getAvailableChannelKeys = distributionConfig =>
    distributionConfig[0].distributions.map(c => c.channel);

const getAvailableSubcampaignKeys = distributionConfig => {
    return distributionConfig[0].distributions.reduce((acc, d) => {
        const subcampaignsList = d.subcampaigns.reduce((acc, s) => {
            acc.push(s.channel);
            return acc;
        }, []);
        return [...acc, ...subcampaignsList];
    }, []);
};

export const getEmptySubcampaigns = distributionConfig => {
    return distributionConfig[0].distributions.reduce((acc, d) => {
        const subcampaignsList = d.subcampaigns.reduce((acc, s) => {
            acc[s.channel] = 0;
            return acc;
        }, {});
        return { ...acc, ...subcampaignsList };
    }, {});
};

export const getEmptyChannels = distributionConfig => {
    return distributionConfig[0].distributions.reduce((acc, d) => {
        acc[d.channel] = 0;
        return acc;
    }, {});
};

export const getZeroDistribution = ({
    channels,
    subCampaigns,
    distributionConfig
}) => {
    if (isEmpty(distributionConfig)) {
        throw new Error('distributionConfig is empty');
    }
    const distribution = {
        total: 0,
        channels: getEmptyChannels(distributionConfig),
        subCampaigns: getEmptySubcampaigns(distributionConfig),
        distributionConfig
    };
    Object.keys(channels).forEach(key => (distribution.channels[key] = 0));
    return distribution;
};

export const getSubcampaignKey = contextName => {
    return Object.values(budgetConfig.distribution.subCampaigns).filter(
        sc => sc.context === contextName
    )[0].key;
};

export const normalizeDistributionConfig = distributionConfig => {
    return distributionConfig.map(
        ({ maximumBudget, minimumBudget, distributions }) => {
            return {
                maximumBudget,
                minimumBudget,
                distributions: distributions.map(
                    ({ percentage, channel, subcampaigns }) => {
                        return {
                            percentage,
                            channel,
                            subcampaigns: subcampaigns
                                .filter(({ channel }) => channel !== 'linear')
                                .map(({ percentage, channel }) => {
                                    return {
                                        percentage,
                                        channel: getSubcampaignKey(channel)
                                    };
                                })
                        };
                    }
                )
            };
        }
    );
};

export const setBudgetDistribution = (role, region, selectedAges, dispatch) => {
    requestHandlers.budget
        .getBudgetDistribution({
            campaignType: role,
            region,
            ages: selectedAges
        })
        .then(({ data }) => {
            const normalizedDistribution = normalizeDistributionConfig(data);
            dispatch(
                updateBudget.saveDistributionConfig(normalizedDistribution)
            );
        })
        .catch(console.error);
};

export const normalizeDigitalCpmConfig = digitalCpmConfig => {
    validateArg(digitalCpmConfig, 'array');
    let normalizedDigitalCpmConfig = {};
    digitalCpmConfig.map(({ name, cpm }) => {
        const key = getSubcampaignKey(name);
        normalizedDigitalCpmConfig[key] = cpm;
    });
    return normalizedDigitalCpmConfig;
};

export const getDigitalCpmConfig = (userType, campaignType, region) => {
    validateArg(userType, 'string', 1);
    validateArg(campaignType, 'string', 2);
    validateArg(region, 'string', 3);
    return requestHandlers.budget
        .getDigitalCpm({
            userType,
            campaignType,
            region
        })
        .then(({ data }) => {
            return normalizeDigitalCpmConfig(data);
        })
        .catch(console.error);
};

export const normalizeImpressionModifierConfig = impressionModifierConfig => {
    validateArg(impressionModifierConfig, 'array');
    let normalizedImpressionModifierConfig = {};
    impressionModifierConfig.map(({ name, impressionsModifier }) => {
        const key = getSubcampaignKey(name);
        normalizedImpressionModifierConfig[key] = impressionsModifier;
    });
    return normalizedImpressionModifierConfig;
};

export const getImpressionModifierConfig = (type, region, duration) => {
    validateArg(type, 'string', 1);
    validateArg(region, 'string', 2);
    validateArg(duration, 'number', 3);
    return requestHandlers.budget
        .getImpressionModifier(type, region, duration)
        .then(({ data }) => {
            return normalizeImpressionModifierConfig(data);
        })
        .catch(console.error);
};
