import axios from 'axios';

export default function (email, password) {
    return axios({
        method: 'POST',
        url: 'user/create',
        data: {
            email,
            password
        }
    }).then(response => {
        return response;
    }).catch(error => {
        return error;
    });
};
