import { createSelector } from 'reselect';
import { hasExcludedNetwork } from '@services';

export const networkSelector = createSelector(
    state => state.networks,
    networks => networks
);

export const excludedNetworksSelector = createSelector(
    networkSelector,
    ({ excludedNetworks }) => excludedNetworks
);

export const hasExcludedNetworkSelector = A4mediaId => {
    return createSelector(
        excludedNetworksSelector,
        excludedNetworks =>
            excludedNetworks.filter(
                excludedNetwork => excludedNetwork.A4mediaId === A4mediaId
            ).length > 0
    );
};
