import * as d3 from 'd3';

import { datesHandler, formatLargeIntegers, strf } from '@lib';
import { getMaxGraphVal } from '@services';
import styles from './styles.scss';

export default data => {
    const margin = 100;
    const padding = 0.4;
    const maxImpressions = getMaxGraphVal(data.map(d => d.impressions));
    // CLICKS HAVE BEEN REMOVED, BUT THEY MAY REAPPEAR; PLEASE DON'T DELETE CODE
    // const maxClicks = getMaxGraphVal(data.map(d => d.clicks));

    const wrapper = d3.select('#impressionsClicksGraph');

    const graphWidth = wrapper.node().clientWidth;
    const graphHeight = wrapper.node().clientWidth * 0.6;
    const width = graphWidth - 2 * margin;
    const height = graphHeight - 2 * margin;
    // const lineShift = ((width / data.length) * (1 - padding)) / 2;

    wrapper.selectAll('svg').remove();

    wrapper.style('height', graphHeight + 'px');

    const svg = wrapper.append('svg');
    const chart = svg
        .append('g')
        .attr('transform', `translate(${margin}, ${margin})`);
    const tooltip = d3.select('#tooltip');

    const showTooltip = (d, bar, barWidth) => {
        tooltip
            .select('#tooltipDate')
            .text(datesHandler.format(d.date, 'eee, LLL dd yyyy'));
        tooltip
            .select('#tooltipImpressions')
            .text(strf(d.impressions).commas().value());
        tooltip.select('#tooltipClicks').text(strf(d.clicks).commas().value());

        const width = tooltip.node().getBoundingClientRect().width;

        const x = bar.node().getBBox().x + margin - (width / 2 - barWidth / 2);
        const y = bar.node().getBBox().height + margin + 30;

        tooltip
            .style('visibility', 'visible')
            .style('bottom', y + 'px')
            .style('left', x + 'px');
    };

    const hideTooltip = () => {
        tooltip.style('visibility', 'hidden');
    };

    const xScale = d3
        .scaleBand()
        .range([10, width])
        .domain(data.map(d => datesHandler.format(d.date, 'LLL do')))
        .padding(padding);

    const xAxis = d3
        .axisBottom(xScale)
        .tickValues(
            xScale.domain().filter((d, i) => !(i % Math.ceil(data.length / 8)))
        )
        .tickSizeOuter(0);

    const yScale = d3
        .scaleLinear()
        .range([height, 0])
        .domain([0, maxImpressions]);

    const yAxis = d3
        .axisLeft(yScale)
        .ticks(5)
        .tickFormat(x => strf(x).commas().value());

    // const y2Scale = d3
    //     .scaleLinear()
    //     .range([height, 0])
    //     .domain([0, maxClicks]);

    // const y2Axis = d3
    //     .axisRight(y2Scale)
    //     .ticks(5)
    //     .tickFormat(x => formatLargeIntegers(x));

    chart
        .append('g')
        .attr('class', `yAxis ${styles.yAxis}`)
        .call(yAxis)
        .call(g => g.select('.domain').remove());

    const axisWidth = chart.select('.yAxis').node().getBBox().width + 5;

    const tickHeight =
        chart.select('.yAxis').select('.tick').node().getBBox().height + 10;

    const tickWidth = axisWidth > 30 ? axisWidth + 10 : 40;

    chart
        .select('.yAxis')
        .selectAll('.tick')
        .insert('rect', ':first-child')
        .attr('class', 'yAxisRect')
        .attr('width', tickWidth)
        .attr('height', tickHeight)
        .attr('x', -axisWidth)
        .attr('y', -(tickHeight / 2));

    chart
        .select('.yAxis')
        .selectAll('line')
        .attr('x1', -axisWidth)
        .attr('x2', -axisWidth)
        .attr('y1', -(tickHeight / 2))
        .attr('y2', tickHeight / 2);

    chart
        .select('.yAxis')
        .selectAll('text')
        .attr('x', -(axisWidth - 3));

    chart
        .select('.yAxis')
        .selectAll('.tick')
        .append('line')
        .attr('class', styles.gridLine)
        .attr('x1', 10)
        .attr('x2', width);

    chart
        .append('g')
        .attr('class', styles.gridColor)
        .attr('transform', `translate(0, ${height})`)
        .call(xAxis);

    // chart
    //     .append('g')
    //     .attr('class', `y2Axis ${styles.y2Axis}`)
    //     .attr('transform', `translate(${width}, 0)`)
    //     .call(y2Axis)
    //     .call(g => g.select('.domain').remove());

    // chart
    //     .select('.y2Axis')
    //     .selectAll('.tick')
    //     .insert('rect', ':first-child')
    //     .attr('class', 'yAxisRect')
    //     .attr('width', axisWidth - 5)
    //     .attr('height', tickHeight)
    //     .attr('x', 5)
    //     .attr('y', -(tickHeight / 2));

    // chart
    //     .select('.y2Axis')
    //     .selectAll('line')
    //     .attr('x1', axisWidth + 10)
    //     .attr('x2', axisWidth + 10)
    //     .attr('y1', -(tickHeight / 2))
    //     .attr('y2', tickHeight / 2);

    // chart
    //     .select('.y2Axis')
    //     .selectAll('text')
    //     .attr('x', axisWidth + 5);

    chart
        .selectAll()
        .data(data)
        .enter()
        .append('path')
        .attr('class', styles.bars)
        .attr('d', d => {
            return height - yScale(d.impressions) > 4
                ? `M 
                ${xScale(datesHandler.format(d.date, 'LLL do')) + 4}
                ${yScale(d.impressions)} 
                h ${xScale.bandwidth() - 8}
                a 4,4 0 0 1 4,4
                v ${height - yScale(d.impressions) - 4}
                h ${-xScale.bandwidth()}
                v ${-(height - yScale(d.impressions) - 4)}
                a 4,4 0 0 1 4,-4`
                : `M 
                ${xScale(datesHandler.format(d.date, 'LLL do'))}
                ${yScale(d.impressions)} 
                h ${xScale.bandwidth()} 
                v ${height - yScale(d.impressions)} 
                h ${-xScale.bandwidth()} Z`;
        })
        .on('mouseover', function (d) {
            return showTooltip(d, d3.select(this), xScale.bandwidth());
        })
        .on('mouseout', () => hideTooltip());

    // chart
    //     .append('path')
    //     .datum(data)
    //     .attr('class', styles.clickLine)
    //     .attr('transform', `translate( ${lineShift}, 0)`)
    //     .attr(
    //         'd',
    //         d3
    //             .line()
    //             .x(d => xScale(datesHandler.format(d.date, 'LLL do')))
    //             .y(d => y2Scale(d.clicks))
    //     );
};
