import React from 'react';
import { getFeatures, withFeatures } from '@delivery/react';
import styles from './styles.scss';

export const Topbar = props => {
    const { name } = props.transition.router.stateService.current;
    const { components } = getFeatures(`topbar-${name}`, { props });

    return <div className={styles.topbar}>{ components }</div>
};

export default withFeatures(Topbar);
