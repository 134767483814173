export default {
    audience: {
        plan: 'app.plan.audience',
        ranker: 'app.ranker.audience'
    },
    venn: {
        plan: 'app.plan.audience.builder.combinations',
        ranker: 'app.ranker.audience.builder.combinations'
    },
    builder: {
        plan: 'app.plan.audience.builder.section',
        ranker: 'app.ranker.audience.builder.section'
    },
    builderMain: {
        plan: 'app.plan.audience.builder',
        ranker: 'app.ranker.audience.builder'
    },
    audiences: {
        plan: 'app.plan.audience.audiences',
        ranker: 'app.ranker.audience.audiences'
    },
    custom: {
        plan: '',
        ranker: 'app.ranker.audience.custom'
    }
};
