import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Text, Layout } from 'maslow';
import eyePng from '@public/img/eye.png';

import {
    createHorizontalBarsByChannel,
    getSubCampaignNameFromContext
} from '@services';
import { strf, round, sortArrayOfObjectsByProp } from '@lib';

import styles from '../horizontalBarGraph.scss';
import shared from '../styles.scss';

const ImpressionsByChannel = ({ data }) => {
    const container = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    const graphId = 'impressionsByChannelGraph';

    const totalImpressions = data.reduce(
        (x, d) => x + Number(d.impressions),
        0
    );

    const newData = data.reduce((filtered, { channel, impressions }) => {
        if (impressions > 0) {
            const name = getSubCampaignNameFromContext(channel);
            const newValue = {
                channel: [name],
                value: impressions,
                percent: totalImpressions
                    ? round((impressions / totalImpressions) * 100)
                    : 0
            };
            filtered.push(newValue);
        }
        return filtered;
    }, []);

    const sortedData = sortArrayOfObjectsByProp(newData, 'percent');

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        if (sortedData.length > 0)
            createHorizontalBarsByChannel(sortedData, styles, graphId);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [container.current, width]);

    if (sortedData.length > 0) {
        return (
            <Layout flexDirection="row" className={shared.table}>
                <div className={shared.titleWithTop}>
                    <Text type="h5">Impressions by Channel</Text>
                </div>

                <Layout flexDirection="row" className={styles.bodySection}>
                    <Layout
                        flexDirection="column"
                        className={styles.totalSection}>
                        <Text type="h4" className={styles.boxTitle}>
                            <img className={styles.eyeImg} src={eyePng} />
                            {strf(totalImpressions).commas().value()}
                        </Text>
                        <Text type="caption" className={styles.totalCaption}>
                            Impressions Total
                        </Text>
                    </Layout>

                    <div
                        id={graphId}
                        className={styles.graphContainer}
                        ref={container}></div>
                </Layout>
            </Layout>
        );
    } else {
        return null;
    }
};

ImpressionsByChannel.propTypes = {
    data: PropTypes.array.isRequired
};

export default ImpressionsByChannel;
