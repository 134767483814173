import React from 'react';
import PropTypes from 'prop-types';
import { Text } from 'maslow';
import styles from '../summary.scss';

const SelectedLocation = ({ locationData }) => {
    const { name } = locationData;

    return (
        <div className={styles.bubble}>
            <Text type="body" className={styles.bubbleText} color="vividNavy">
                {name}
            </Text>
        </div>
    );
};

SelectedLocation.propTypes = {
    locationData: PropTypes.object.isRequired
};

export default SelectedLocation;
