export default {
    name: 'CampaignSelectors', // Component name
    description: 'Description for Feature Component',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: ['plan'],
    sections: ['campaign_selector'],
    data: {
        routeTo: 'app.plan.location'
    },
    order: 1,
    attributes: {},
    modifications: {}
};
