import { getDisplayString } from '@services';
import {
    POLITICAL,
    STATE_CATEGORY,
    POLITICAL_STATE,
    POLITICAL_DISTRICTS
} from '@config';

export const getFilteredEntries = (
    category,
    entries,
    config,
    selectedLocations,
    role
) => {
    return entries.filter(entry => {
        const { attributeName, displayName, states } = entry;

        let keep = false;
        config.forEach(section => {
            section.options.forEach(sectionOption => {
                if (
                    sectionOption.name === category ||
                    (sectionOption.name === 'PoliticalState' &&
                        category === 'State')
                ) {
                    sectionOption.options.forEach(unit => {
                        if (unit.name === attributeName) {
                            keep = true;
                        }
                    });
                }
            });
        });

        const clicked = selectedLocations.find(({ code, type }) => {
            return code === entry.code && type === entry.type;
        });

        entry.displayString = getDisplayString(displayName, states);

        if (entry.category === STATE_CATEGORY && role === POLITICAL) {
            entry.category = POLITICAL_STATE;
            entry.section = POLITICAL_DISTRICTS;
        }

        return keep && !clicked;
    });
};
