import { createAction } from '@reduxjs/toolkit';
import { ADD_VENN_PNG_DATA_URL, REMOVE_VENN_PNG_DATA_URL } from '../constants';

export const addVennPngDataUrl = createAction(
    ADD_VENN_PNG_DATA_URL,
    vennPngDataUrl => {
        return {
            payload: { vennPngDataUrl }
        };
    }
);

export const removeVennPngDataUrl = createAction(REMOVE_VENN_PNG_DATA_URL);
