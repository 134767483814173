import React from 'react';
import { useSelector } from 'react-redux';
import { Text, Icon, Layout, Card } from 'maslow';
import PropTypes from 'prop-types';

import {
    createSectionSelector,
    createPercentSelector,
    singleChannelDisabledSelector,
    subCampaignDisabledSelector,
    lockedChannelStatusSelector,
    channelDisabledSelector
} from '@selectors';

import SubCampaign from '../SubCampaign';
import BudgetSlider from '../BudgetSlider';
import BudgetInput from '../BudgetInput';
import BudgetPercentInput from '../BudgetPercentInput';
import ChannelControls from '../ChannelControls';
import LockedInfoToolTipSection from '../LockedInfoToolTipSection';

import styles from '../Distribution/styles.scss';

const Channel = ({
    config,
    subCampaigns,
    toggleEditing,
    editedChannel,
    disableChannel,
    disabledChannelsKeys
}) => {
    const { style, key } = config;

    const channelBudget = useSelector(createSectionSelector.channel(key));
    const channelPercent = useSelector(createPercentSelector.channel(key));
    const isLockedStatus = useSelector(lockedChannelStatusSelector(key));
    const singleChannelDisabledClass = useSelector(
        singleChannelDisabledSelector(disabledChannelsKeys, key)
    )
        ? styles.disabledWrapper
        : '';
    const disableSubs = useSelector(
        subCampaignDisabledSelector(subCampaigns, key)
    );
    const disabledClass = useSelector(channelDisabledSelector)
        ? styles.disabledWrapper
        : '';

    const isEditing = editedChannel === key;

    const buildChannelInputs = () => {
        if (isLockedStatus) {
            return (
                <div className={styles.toolTipSectionWrapper}>
                    <LockedInfoToolTipSection sliderKey={key} />
                </div>
            );
        }
        return (
            <Layout
                flexDirection="row"
                vAlign="center"
                className={`${styles.budgetInputsLayout} ${singleChannelDisabledClass} ${disabledClass}`}>
                <div
                    className={[
                        styles.budgetInputWrapper,
                        styles.budgetPercentInputWrapper
                    ].join(' ')}>
                    <BudgetPercentInput
                        config={config}
                        theme="channel"
                        disabledChannelsKeys={
                            disabledChannelsKeys
                        }></BudgetPercentInput>
                </div>
                <div className={styles.budgetInputWrapper}>
                    <BudgetInput
                        config={config}
                        theme="channel"
                        disabledChannelsKeys={
                            disabledChannelsKeys
                        }></BudgetInput>
                </div>
            </Layout>
        );
    };

    const buildSubCampaign = config => {
        return (
            <SubCampaign
                isEditing={isEditing}
                key={config.key}
                config={config}
                style={style}
                disable={disableSubs}
                channelKey={key}
                toggleEditing={toggleEditing}
            />
        );
    };

    const buildSubCampaigns = subCampaigns => {
        if (!isEditing) {
            return (
                <Layout
                    flexDirection="row"
                    className={styles.subBudgetSummaryWrapper}>
                    {subCampaigns.map(buildSubCampaign)}
                </Layout>
            );
        }
        return (
            <Layout flexDirection="column" className={styles.subBudgetWrapper}>
                {subCampaigns.map(buildSubCampaign)}
            </Layout>
        );
    };

    const iconStyle = {
        backgroundColor: style.primaryColor,
        boxShadow: style.shadowColor
    };

    return isNaN(channelBudget) ? null : (
        <Layout flexDirection="row" className={styles.cardWrapper} fillParent>
            <Card
                borderRadius="2px"
                boxShadow="0 1px 3px 0 rgba(0,51,128,0.3)"
                backgroundColor="white"
                className={styles.wrapper}>
                <Layout
                    flexDirection="column"
                    className={styles.distributionCard}
                    fillParent>
                    <Layout
                        flexDirection="row"
                        vAlign="center"
                        hAlign="space-between">
                        <Layout
                            vAlign="center"
                            className={`${styles.channelWrapper} ${disabledClass}`}>
                            <Layout
                                hAlign="center"
                                vAlign="center"
                                style={iconStyle}
                                className={styles.channelIconWrapper}>
                                <Icon src={config.icon} size="2" />
                            </Layout>
                            <Layout className={styles.channelText}>
                                <Text type="h5">{config.name}</Text>
                            </Layout>
                        </Layout>
                        {buildChannelInputs()}
                    </Layout>
                    <div className={disabledClass}>
                        <Layout
                            flexDirection="column"
                            className={singleChannelDisabledClass}>
                            <BudgetSlider
                                size="small"
                                color={style.sliderColorName}
                                config={config}
                                theme="channel"
                                disabledChannelsKeys={disabledChannelsKeys}
                            />
                        </Layout>
                        {buildSubCampaigns(subCampaigns)}
                    </div>
                </Layout>
            </Card>
            <ChannelControls
                config={config}
                toggleEditing={toggleEditing}
                isEditing={isEditing}
                disableChannel={disableChannel}
                disabledChannelsKeys={disabledChannelsKeys}
            />
        </Layout>
    );
};

Channel.propTypes = {
    config: PropTypes.object.isRequired,
    subCampaigns: PropTypes.array,
    toggleEditing: PropTypes.func.isRequired,
    disableChannel: PropTypes.func.isRequired,
    disabledChannelsKeys: PropTypes.array
};

export default Channel;
