export default {
    name: 'MultiAudiences', // Component name
    description: 'Venn diagram for selecting multiple audiences',
    library: 'react',
    versions: {
        prod: '1.1.0',
        sim: '1.1.0',
        ltd: '1.1.0',
        beta: '1.1.0',
        alpha: '1.1.0'
    },
    states: ['plan', 'ranker'],
    sections: ['audienceSummary'],
    permissions: [],
    data: {},
    attributes: {},
    modifications: {}
};
