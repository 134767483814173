export const checkForData = data => {
    let counter = 0;
    data.forEach(section => {
        if (section.hasOwnProperty('entries')) {
            const { length } = section.entries;
            counter += length;
        }
    });
    return counter;
};
