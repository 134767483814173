import { combineReducers } from 'redux';

import { audienceType } from './audienceType';
import { households } from './households';
import { audiences } from './audiences';
import { isIndicatorMounted } from './isIndicatorMounted';
import { currentAudienceIndex } from './currentAudienceIndex';
import { isSummaryScrollbar } from './isSummaryScrollbar';
import { audienceConfig } from './audienceConfig';
import { savedAudiences } from './savedAudiences';
import { isAudienceSaved } from './isAudienceSaved';
import { combinations } from './combinations';
import { isAudienceModified } from './isAudienceModified';
import { vennPngDataUrl } from './vennPngDataUrl';

export const audience = combineReducers({
    audienceType,
    households,
    audiences,
    isIndicatorMounted,
    currentAudienceIndex,
    isSummaryScrollbar,
    audienceConfig,
    savedAudiences,
    isAudienceSaved,
    combinations,
    isAudienceModified,
    vennPngDataUrl
});
