import request from '../request';

export default function (data) {
    return request({
        method: 'POST',
        url: 'auth/email/',
        data: {
            email: data.toLowerCase()
        }
    }).then(() => {
        return true;
    }).catch(() => {
        return false;
    });
};
