import AENSvg from '@public/img/AEN.svg';
import AHCSvg from '@public/img/AHC.svg';
import AMCSvg from '@public/img/AMC.svg';
import APLSvg from '@public/img/APL.svg';
import BBCASvg from '@public/img/BBCA.svg';
import BHERSvg from '@public/img/BHER.svg';
import BETSvg from '@public/img/BET.svg';
import BRVOSvg from '@public/img/BRVO.svg';
import CCSvg from '@public/img/CC.svg';
import CMDSvg from '@public/img/CMD.svg';
import CMTSvg from '@public/img/CMT.svg';
import CNBSvg from '@public/img/CNB.svg';
import CNNSvg from '@public/img/CNN.svg';
import CTRCSvg from '@public/img/CTRC.svg';
import DAMSvg from '@public/img/DAM.svg';
import DISCSvg from '@public/img/DISC.svg';
import DIYSvg from '@public/img/DIY.svg';
import DXDSvg from '@public/img/DXD.svg';
import ENTSvg from '@public/img/ENT.svg';
import ESNUSvg from '@public/img/ESNU.svg';
import ESP2Svg from '@public/img/ESP2.svg';
import ESPNSvg from '@public/img/ESPN.svg';
import ESQSvg from '@public/img/ESQ.svg';
import FRFMSvg from '@public/img/FRFM.svg';
import FBNSvg from '@public/img/FBN.svg';
import FOODSvg from '@public/img/FOOD.svg';
import FS1Svg from '@public/img/FS1.svg';
import FUSESvg from '@public/img/FUSE.svg';
import FXSvg from '@public/img/FX.svg';
import FXNCSvg from '@public/img/FXNC.svg';
import FYISvg from '@public/img/FYI.svg';
import GACSvg from '@public/img/GAC.svg';
import GALASvg from '@public/img/GALA.svg';
import GOLFSvg from '@public/img/GOLF.svg';
import HALLSvg from '@public/img/HALL.svg';
import HGTVSvg from '@public/img/HGTV.svg';
import HISTSvg from '@public/img/HIST.svg';
import HLNSvg from '@public/img/HLN.svg';
import HMMSvg from '@public/img/HMM.svg';
import IDSvg from '@public/img/ID.svg';
import LIFSvg from '@public/img/LIF.svg';
import LMNSvg from '@public/img/LMN.svg';
import LOGOSvg from '@public/img/LOGO1.svg';
import MLBNSvg from '@public/img/MLBN.svg';
import MNBCSvg from '@public/img/MNBC.svg';
import MTVSvg from '@public/img/MTV.svg';
import MTV2Svg from '@public/img/MTV2.svg';
import NBCSSvg from '@public/img/NBCS.svg';
import NFLNSvg from '@public/img/NFLN.svg';
import NGCSvg from '@public/img/NGC.svg';
import NGWDSvg from '@public/img/NGWD.svg';
import NICKSvg from '@public/img/NICK.svg';
import OVASvg from '@public/img/OVA.svg';
import OWNSvg from '@public/img/OWN.svg';
import OXYGSvg from '@public/img/OXYG.svg';
import POPSvg from '@public/img/POP.svg';
import SCISvg from '@public/img/SCI.svg';
import PARSvg from '@public/img/PAR.svg';
import SYFYSvg from '@public/img/SYFY.svg';
import TBSCSvg from '@public/img/TBSC.svg';
import TLCSvg from '@public/img/TLC.svg';
import TNTSvg from '@public/img/TNT.svg';
import TOONSvg from '@public/img/TOON.svg';
import TRAVSvg from '@public/img/TRAV.svg';
import TRUSvg from '@public/img/TRU.svg';
import TVLSvg from '@public/img/TVL.svg';
import TWCSvg from '@public/img/TWC.svg';
import UPSvg from '@public/img/UP.svg';
import USASvg from '@public/img/USA.svg';
import VH1Svg from '@public/img/VH1.svg';
import WESvg from '@public/img/WE.svg';
import GSNSvg from '@public/img/GSN.svg';
import IFCSvg from '@public/img/IFC.svg';
import DFCSvg from '@public/img/DFC.svg';
import FXXSvg from '@public/img/FXX.svg';
import FS2Svg from '@public/img/FS2.svg';
import RLZCSvg from '@public/img/RLZC.svg';
import RFDSvg from '@public/img/RFD.svg';
import WGNASvg from '@public/img/WGNA.svg';
import VELSvg from '@public/img/VEL.svg';

export default {
    AEN: AENSvg,
    AHC: AHCSvg,
    AMC: AMCSvg,
    APL: APLSvg,
    BBCA: BBCASvg,
    BET: BETSvg,
    BHER: BHERSvg,
    BRVO: BRVOSvg,
    CC: CCSvg,
    CMD: CMDSvg,
    CMT: CMTSvg,
    CNB: CNBSvg,
    CNN: CNNSvg,
    CTRC: CTRCSvg,
    DAM: DAMSvg,
    DISC: DISCSvg,
    DIY: DIYSvg,
    DXD: DXDSvg,
    ENT: ENTSvg,
    ESNU: ESNUSvg,
    ESP2: ESP2Svg,
    ESPN: ESPNSvg,
    ESQ: ESQSvg,
    FRFM: FRFMSvg,
    FBN: FBNSvg,
    FOOD: FOODSvg,
    FS1: FS1Svg,
    FUSE: FUSESvg,
    FX: FXSvg,
    FXNC: FXNCSvg,
    FYI: FYISvg,
    GAC: GACSvg,
    GALA: GALASvg,
    GOLF: GOLFSvg,
    HALL: HALLSvg,
    HGTV: HGTVSvg,
    HIST: HISTSvg,
    HLN: HLNSvg,
    HMM: HMMSvg,
    ID: IDSvg,
    LIF: LIFSvg,
    LMN: LMNSvg,
    LOGO: LOGOSvg,
    MLBN: MLBNSvg,
    MNBC: MNBCSvg,
    MTV: MTVSvg,
    MTV2: MTV2Svg,
    NBCS: NBCSSvg,
    NFLN: NFLNSvg,
    NGC: NGCSvg,
    NGWD: NGWDSvg,
    NICK: NICKSvg,
    OVA: OVASvg,
    OWN: OWNSvg,
    OXYG: OXYGSvg,
    POP: POPSvg,
    SCI: SCISvg,
    PAR: PARSvg,
    SYFY: SYFYSvg,
    TBSC: TBSCSvg,
    TLC: TLCSvg,
    TNT: TNTSvg,
    TOON: TOONSvg,
    TRAV: TRAVSvg,
    TRU: TRUSvg,
    TVL: TVLSvg,
    TWC: TWCSvg,
    UP: UPSvg,
    USA: USASvg,
    VH1: VH1Svg,
    WE: WESvg,
    GSN: GSNSvg,
    IFC: IFCSvg,
    DFC: DFCSvg,
    FXX: FXXSvg,
    FS2: FS2Svg,
    RLZC: RLZCSvg,
    RFD: RFDSvg,
    WGNA: WGNASvg,
    VEL: VELSvg
};
