import featuresManager from '@featuresManager';
import { Ranker } from '../components/pages';
import { store } from '@state';
import { setRanker } from '@state/rootActions';

export default [
    {
        name: 'app.ranker',
        url: '/ranker',
        abstract: true,
        resolve: [
            {
                token: 'featureState',
                deps: ['$transition$'],
                resolveFn: trans => {
                    if (trans._options.source !== 'url') {
                        featuresManager.setFeatureState('ranker');
                        store.dispatch(setRanker());
                        return Promise.resolve(featuresManager.featureState);
                    } else {
                        trans.router.stateService.reload('app.main.plans');
                        trans.router.stateService.go('app.main.plans');
                    }
                }
            }
        ]
    },
    {
        name: 'app.ranker.rankertool',
        url: '/rankertool',
        views: {
            'content@app': {
                component: Ranker
            }
        }
    }
];
