import { validateArg } from '@lib';
import {
    COLORED,
    COLORED_EMPTY,
    ADD,
    INACTIVE,
    DRAFT
} from '@config/config.multiAudiences';

export const getAudienceToRemove = (audiences, index) => {
    validateArg(audiences, 'array', 1);
    validateArg(index, 'number', 2);
    const toRemove = [];

    audiences.forEach((audience, key) => {
        if (key > index && audience.length === 0) toRemove.push(key);
    });

    return toRemove;
};

const buildIntersection = sets => {
    validateArg(sets, 'array');
    return { sets, size: 2 };
};

export const getTypeForFirstSet = (key, audiences) => {
    validateArg(key, 'number', 1);
    validateArg(audiences, 'array', 2);
    const audience = audiences[key];
    const next = audiences[key + 1];

    if (!audience.length && next) {
        return COLORED_EMPTY;
    }

    return COLORED;
};

export const getType = (key, audiences) => {
    validateArg(key, 'number', 1);
    validateArg(audiences, 'array', 2);
    if (key === 0) return getTypeForFirstSet(key, audiences);

    const audience = audiences[key];
    const previous = audiences[key - 1];

    if (previous && previous.length && !audience) return ADD;
    if (!audience) return INACTIVE;
    if (!audience.length) return COLORED_EMPTY;

    return COLORED;
};

export const buildSet = (i, audiences) => {
    validateArg(i, 'number', 1);
    validateArg(audiences, 'array', 2);
    const type = getType(i, audiences);
    const label = type === 'add' ? 'Add' : ' ';

    return {
        sets: [i],
        size: 16,
        label,
        type
    };
};

export const buildVennSets = audiences => {
    validateArg(audiences, 'array');
    const sets = DRAFT.map(i => buildSet(i, audiences));
    const result = [...sets];
    let isCentral = false;

    for (let i = 0; i < sets.length; i++) {
        const second = sets[i + 1];
        const secondNext = sets[i + 2];

        if (second) result.push(buildIntersection([i, i + 1]));
        if (secondNext) result.push(buildIntersection([i, i + 2]));
        if (second && secondNext) isCentral = true;
    }

    if (isCentral) result.push(buildIntersection([0, 1, 2]));

    return result;
};
