import React from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';

import { Text, Layout } from 'maslow';
import closeIcon from '@public/img/close-white.svg';
import { hasAudience } from '@services';
import {
    fetchHouseholdCount,
    setLocationCount
} from '@state/audience/actions/households';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import { removeLocation } from '@state/location/actions/';
import { selectedLocationsSelector } from '@selectors/location';
import { audiencesSelector } from '@selectors/audience';

import styles from '../styles.scss';

import { PLAN_STATUSES } from '@config/config.planStatus';
import { idBuilder } from '@services';
import { LOCATIONS_SELECTED_LOCATION } from '@config/idConstants';

const SelectedLocation = props => {
    const { location } = props;
    const selectedLocations = useSelector(selectedLocationsSelector);
    const audiences = useSelector(audiencesSelector);

    const locationIndex = selectedLocations.indexOf(location);

    const dispatch = useDispatch();

    const { name } = location;

    const onClickHandler = () => {
        dispatch(removeLocation(locationIndex));
        if (hasAudience(audiences)) {
            dispatch(setLocationCount(0));
        }
        dispatch(fetchHouseholdCount());
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    return (
        <Layout
            flexDirection="row"
            className={styles.marginTop}
            key={`location-list-${name}`}>
            <Layout vAlign="center" className={styles.bubble}>
                <div
                    id={idBuilder(LOCATIONS_SELECTED_LOCATION, name)}
                    onClick={onClickHandler}
                    className={styles.iconWrapper}>
                    <img src={closeIcon} className={styles.icon} />
                </div>
                <Text type="body" color="white">
                    {name}
                </Text>
            </Layout>
        </Layout>
    );
};

SelectedLocation.propTypes = {
    location: PropTypes.object.isRequired
};

export default SelectedLocation;
