import { POLITICAL, COMMERCIAL } from '@config';
import {
    CAMPAIGN_COMMERCIAL,
    CAMPAIGN_POLITICAL,
    CAMPAIGN_COMMERCIAL_TOOLTIP_INFO,
    CAMPAIGN_POLITICAL_TOOLTIP_INFO
} from '@config/idConstants';
import smbSvg from '@public/img/smb.svg';
import politicalSvg from '@public/img/political.svg';

export default [
    {
        role: COMMERCIAL,
        id: CAMPAIGN_COMMERCIAL,
        icon: smbSvg,
        tooltipId: CAMPAIGN_COMMERCIAL_TOOLTIP_INFO,
        copy:
            'Perfect for small to medium sized businesses and ad agencies. Campaigns are designed using demographic and lifestyle information to target the ideal audience for your advertising message.'
    },
    {
        role: POLITICAL,
        id: CAMPAIGN_POLITICAL,
        tooltipId: CAMPAIGN_POLITICAL_TOOLTIP_INFO,
        icon: politicalSvg,
        copy:
            'Create media plans for an upcoming election. Campaigns are designed using political districts and voter registration files to target the voters you’re looking for.'
    }
];
