import React, { useEffect } from 'react';
import { Layout, Icon, Text, Button } from 'maslow';
import { useDispatch, useSelector } from 'react-redux';
import { uiSelector } from '@selectors';
import { resetLoadingScreen } from '@state/ui/actions';
import notFoundSvg from '@public/img/empty-state.svg';

import styles from './styles.scss';

import { NOT_FOUND_BACK } from '@config/idConstants';

export default function (version, feature) {
    const Component = ({ transition }) => {
        const {
            headings: { title, text, buttonText },
            routeTo
        } = feature.data;
        const dispatch = useDispatch();
        const {
            loadingScreen: { showLoadingScreen }
        } = useSelector(uiSelector);

        useEffect(() => {
            if (showLoadingScreen) {
                dispatch(resetLoadingScreen());
            }
        }, []);

        return (
            <Layout className={styles.notFoundWrapper} fillParent flex>
                <Layout
                    hAlign="center"
                    vAlign="center"
                    flexDirection="column"
                    fillParent
                    flex>
                    <Icon src={notFoundSvg} size="40" />
                    <Text type="h3" color="eggBlue" className={styles.title}>
                        {title}
                    </Text>
                    <Text type="h4" color="gray" className={styles.text}>
                        {text}
                    </Text>
                    <Button
                        id={NOT_FOUND_BACK}
                        className={styles.actionBtn}
                        onClick={() => {
                            transition.router.stateService.go(routeTo);
                        }}>
                        {buttonText}
                    </Button>
                </Layout>
            </Layout>
        );
    };

    return Component;
}
