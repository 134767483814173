import { strf, round, isArray, validateArg } from '@lib';
import { SAVED_AUDIENCES } from '@config';
import { categories } from '@config/config.locations';
const {
    STATE,
    CITY,
    COUNTY,
    ZIP,
    DMA,
    US_CONGRESS,
    STATE_HOUSE,
    STATE_LEGISLATIVE,
    STATE_SENATE
} = categories;

export const hasMultipleNotEmptyAudiences = audiences => {
    return isArray(audiences)
        ? audiences.length > 1
            ? audiences[0].length && audiences[1].length
            : false
        : null;
};

export const hasMultipleAudiences = audiences => {
    return isArray(audiences)
        ? audiences.length > 1
            ? audiences[1].length
            : false
        : null;
};

export const isTargetingEveryone = (audiences, locations) => {
    const noAudiences = isArray(audiences)
        ? audiences.length === 1 && !audiences[0].length
        : false;
    const noLocations = !isArray(locations)
        ? true
        : locations.length === 0
        ? true
        : false;

    return noAudiences && noLocations;
};

export const filterAudienceName = audiences => {
    const lowercaseAudienceName = audiences.map(audience => {
        return {
            filteredName: audience.name.toLowerCase(),
            ...audience
        };
    });
    return lowercaseAudienceName;
};
export const hasAudience = audiences => {
    return isArray(audiences)
        ? audiences.map(audience => audience.length > 0).includes(true)
        : false;
};

export const hasLocations = locations => {
    return isArray(locations) && locations.length > 0;
};

export const hasCustomList = customListId => {
    return customListId && typeof customListId === 'string' ? true : false;
};

export const trimAudiences = audiences => {
    return isArray(audiences)
        ? audiences.filter(audience => audience.length)
        : [];
};

export const isSavedAudience = audience => {
    return isArray(audience)
        ? audience.findIndex(item => item.category === SAVED_AUDIENCES) !== -1
        : 0;
};

export const formatLocations = locations => {
    validateArg(locations, 'array');
    return locations.map(location => {
        const {
            audienceCategory,
            audienceAttributeId,
            category,
            name,
            audienceName
        } = location;

        if (
            [
                STATE,
                DMA,
                US_CONGRESS,
                STATE_HOUSE,
                STATE_LEGISLATIVE,
                STATE_SENATE
            ].includes(audienceCategory)
        ) {
            return {
                categoryValue: audienceCategory,
                value: audienceName
            };
        }

        if (audienceCategory === CITY || audienceCategory === COUNTY || audienceCategory == ZIP) {
            return {
                categoryValue: audienceCategory || category,
                value: audienceName || audienceAttributeId
            };
        }

        return {
            categoryValue: audienceCategory || category,
            value: audienceAttributeId
        };
    });
};

export const getAudiencePercent = (count, total) => {
    const percent = round((count / total) * 100);
    if (isNaN(percent)) return percent;
    return percent <= 0
        ? strf(percent).prepend('< ').replace(0, 1).value()
        : percent.toString();
};

export const getHouseholdAmounts = (audience, locations, customList) => {
    if (!audience || typeof audience !== 'object')
        throw new Error('first arg must be an object with truthy value');

    if (!isArray(locations)) throw new Error('second arg must be an array');
    if (!customList || typeof customList !== 'object')
        throw new Error('third arg must be an object with truthy value');

    const {
        households: { count, total, locationCount },
        audiences
    } = audience;

    const { id } = customList;
    const noAudiences =
        (!hasAudience(audiences) && !hasCustomList(id)) || !count;
    const pageTotal = !hasLocations(locations) ? total : locationCount;

    return {
        percentage: noAudiences ? '100' : getAudiencePercent(count, pageTotal),
        hhCount: noAudiences ? pageTotal : count
    };
};

export const getSelectedAgeList = audiences => {
    const selectedAgeList = [];
    audiences.map(audience => {
        audience.map(item => {
            if (
                item.category === 'Age' &&
                !selectedAgeList.includes(item.value)
            ) {
                selectedAgeList.push(item.value);
            }
        });
    });

    return selectedAgeList;
};

export const isAudienceSelected = (
    audiences,
    currentAudienceIndex,
    savedAudience
) => {
    return audiences[currentAudienceIndex]
        ? audiences[currentAudienceIndex].filter(
              s => s.id === savedAudience.id.toString()
          ).length > 0
        : false;
};
