import React, { useEffect } from 'react';
import { Page } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';

const SignUp = props => {
    const _props = { props };
    const { components } = getFeatures('signUp', _props);

    return (
        <Page center backgroundColor="quartz">
            {components}
        </Page>
    );
};

export default withFeatures(SignUp);
