export const SET_AUDIENCE_TYPE = 'SET_AUDIENCE_TYPE';
export const SET_HOUSEHOLD_COUNT = 'SET_HOUSEHOLD_COUNT';
export const SET_LOCATION_COUNT = 'SET_LOCATION_COUNT';
export const SET_TOTAL_HOUSEHOLD_COUNT = 'SET_TOTAL_HOUSEHOLD_COUNT';
export const FETCHING_HOUSEHOLD_COUNT = 'FETCHING_HOUSEHOLD_COUNT';
export const FETCHING_LOCATION_COUNT = 'FETCHING_LOCATION_COUNT';
export const FETCHING_TOTAL_HOUSEHOLD_COUNT = 'FETCHING_TOTAL_HOUSEHOLD_COUNT';
export const ABORT_FETCHING_HOUSEHOLD_COUNT = 'ABORT_FETCHING_HOUSEHOLD_COUNT';
export const ABORT_FETCHING_LOCATION_COUNT = 'ABORT_FETCHING_LOCATION_COUNT';

export const ADD_AUDIENCE = 'ADD_AUDIENCE';
export const REPLACE_AUDIENCE = 'REPLACE_AUDIENCE';
export const REPLACE_AUDIENCES = 'REPLACE_AUDIENCES';
export const REMOVE_AUDIENCE = 'REMOVE_AUDIENCE';
export const REMOVE_ALL_AUDIENCES = 'REMOVE_ALL_AUDIENCES';
export const ADD_OPTION_TO_AUDIENCE = 'ADD_OPTION_TO_AUDIENCE';
export const REMOVE_OPTION_FROM_AUDIENCE = 'REMOVE_OPTION_FROM_AUDIENCE';
export const REMOVE_ALL_OPTIONS_FROM_AUDIENCE =
    'REMOVE_ALL_OPTIONS_FROM_AUDIENCE';
export const REMOVE_CATEGORY_FROM_AUDIENCE = 'REMOVE_CATEGORY_FROM_AUDIENCE';
export const SET_CURRENT_AUDIENCE_INDEX = 'SET_CURRENT_AUDIENCE_INDEX';
export const REMOVE_ALL_OPTIONS = 'REMOVE_ALL_OPTIONS';

export const TOGGLE_IS_INDICATOR_MOUNTED = 'TOGGLE_IS_INDICATOR_MOUNTED';
export const TOGGLE_IS_SUMMARY_SCROLLBAR = 'TOGGLE_IS_SUMMARY_SCROLLBAR';
export const TOGGLE_IS_AUDIENCE_SAVED = 'TOGGLE_IS_AUDIENCE_SAVED';
export const TOGGLE_IS_AUDIENCE_MODIFIED = 'TOGGLE_IS_AUDIENCE_MODIFIED';

export const SET_AUDIENCE_CONFIG = 'SET_AUDIENCE_CONFIG';
export const SET_SAVED_AUDIENCES = 'SET_SAVED_AUDIENCES';

export const ADD_COMBINATIONS = 'ADD_COMBINATIONS';
export const REMOVE_COMBINATIONS = 'REMOVE_COMBINATIONS';

export const ADD_VENN_PNG_DATA_URL = 'ADD_VENN_PNG_DATA_URL';
export const REMOVE_VENN_PNG_DATA_URL = 'REMOVE_VENN_PNG_DATA_URL';
