import React from 'react';
import PropTypes from 'prop-types';

import { Text, Layout, Icon } from 'maslow';
import csvSvg from '@public/img/ico-csv.svg';
import { REPORTS_DOWNLOAD_CSV } from '@config/idConstants';

import requestHandlers from '@requestHandlers';
import { downloadFile } from '@services';
import { datesHandler } from '@lib';

import styles from './styles.scss';

const DownloadReport = ({ planId, planName }) => {
    const getCsv = () => {
        const currentDate = datesHandler.format(
            datesHandler.newDate(),
            'LL.dd.yyyy'
        );
        const _planName = planName.replace(/[\/\\":*?<>|]/g, '_');
        const filename = `${_planName}_Report_${currentDate}.csv`;
        requestHandlers.reports
            .getFile(planId)
            .then(({ data }) => downloadFile(filename, data))
            .catch(console.error);
    };

    return (
        <Layout
            flexDirection="row"
            hAlign="flex-end"
            className={styles.downloadWrapper}>
            <Text type="input">Download as:</Text>
            <Layout
                flexDirection="row"
                vAlign="center"
                id={REPORTS_DOWNLOAD_CSV}
                className={styles.fileTypeWrapper}
                onClick={getCsv}>
                <Icon src={csvSvg} className={styles.icon} />
                <Text
                    type="input"
                    color="vividNavy"
                    className={styles.fileTypeText}>
                    CSV
                </Text>
            </Layout>
        </Layout>
    );
};

DownloadReport.propTypes = {
    planId: PropTypes.string.isRequired,
    planName: PropTypes.string.isRequired
};

export default DownloadReport;
