import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { UISrefActive, UISref } from '@uirouter/react';

import { Layout, Icon, Text } from 'maslow';
import { withFeatures } from '@delivery/react';
import { toggleIsAudienceSaved } from '@state/audience/actions/toggleIsAudienceSaved';
import { setAudienceType } from '@state/audience/actions/audienceType';
import { slugBuilder } from '@services';
import styles from './styles.scss';

import { idBuilder } from '@services';
import { AUDIENCE_SUMMARY_TYPE_OPTION } from '@config/idConstants';
import { pathSelector } from '@selectors/';
import audienceRoutes from '@config/config.audienceRoutes';
import { audienceSelector } from '@selectors/';
import { isEmpty } from '@lib/';

export default function (version, feature) {
    const AudienceTypeOptions = () => {
        const dispatch = useDispatch();
        const path = useSelector(pathSelector);
        const { isAudienceSaved, audienceConfig } = useSelector(
            audienceSelector
        );

        const buildMiniNavItems = ({ type, icon, to, text }, i) => {
            const updateAudienceType = () => setAudienceType(type);
            const sectionSlugName =
                to[path] === audienceRoutes.builder[path]
                    ? slugBuilder(audienceConfig[0].name)
                    : '';

            const iconStyle =
                to[path] === audienceRoutes.audiences[path] && isAudienceSaved
                    ? styles.itemSave
                    : styles.item;

            const miniNavItemText =
                to[path] === audienceRoutes.audiences[path] && isAudienceSaved
                    ? 'Saved!'
                    : text;

            const handleClick =
                type === 'custom' ? () => {} : updateAudienceType;

            return (
                <Layout
                    id={idBuilder(
                        AUDIENCE_SUMMARY_TYPE_OPTION,
                        miniNavItemText
                    )}
                    key={i}
                    flexDirection="row"
                    hAlign="space-around"
                    onClick={handleClick}
                    style={{ width: '33.33%' }}>
                    <UISrefActive class={styles.active}>
                        <div
                            className={
                                type === 'custom'
                                    ? styles.item + ' ' + styles.disabledItem
                                    : iconStyle
                            }>
                            {to[path] ? (
                                <UISref
                                    to={to[path]}
                                    params={{ sectionName: sectionSlugName }}>
                                    <Layout
                                        vAlign="center"
                                        flexDirection="column"
                                        hAlign="center">
                                        <Icon
                                            src={icon}
                                            size="4"
                                            className={styles.icon}
                                        />
                                        <Text type="button">
                                            {miniNavItemText}
                                        </Text>
                                    </Layout>
                                </UISref>
                            ) : (
                                <Layout
                                    vAlign="center"
                                    flexDirection="column"
                                    hAlign="center">
                                    <Icon
                                        src={icon}
                                        size="4"
                                        className={styles.icon}
                                    />
                                    <Text type="button">{miniNavItemText}</Text>
                                </Layout>
                            )}
                        </div>
                    </UISrefActive>
                </Layout>
            );
        };

        useEffect(() => {
            setTimeout(() => {
                dispatch(toggleIsAudienceSaved(false));
            }, 3000);
        }, []);

        return (
            !isEmpty(audienceConfig) && (
                <div className={styles.wrapper}>
                    <Layout flexDirection="row" hAlign="space-between">
                        {feature.data.miniNavItems.map(buildMiniNavItems)}
                    </Layout>
                </div>
            )
        );
    };

    return withFeatures(AudienceTypeOptions);
}
