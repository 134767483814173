import React from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import plusIcon from '@public/img/plus-selector.svg';
import minusIcon from '@public/img/minus-selector.svg';

import { normalizeAudienceOptionId } from '@services';
import { updateAudiences } from '@state/audience/actions';
import { audiencesContainer } from '@state/audience/containers';

import { Layout, Icon, Text } from 'maslow';
import { reMount } from '@components/hoc';

import styles from './index.scss';
import { idBuilder } from '@services';
import { AUDIENCE_BUILDER_OPTION } from '@config/idConstants';

const AudienceSelectionOption = props => {
    const { category, option, isSelected, categoryValue, section } = props;
    const dispatch = useDispatch();

    const { name } = option;
    const id = normalizeAudienceOptionId(name, category);
    const optionToSave = {
        ...option,
        id,
        category,
        isSelected,
        categoryValue,
        section
    };

    const handleClick = optionToSave => {
        dispatch(updateAudiences(optionToSave));
    };

    const renderIcon = () => {
        const Component = reMount(Icon);
        const iconSrc = isSelected ? minusIcon : plusIcon;

        return (
            <Component src={iconSrc} size="3" className={styles.optionIcon} />
        );
    };

    return (
        <Layout flexDirection="row">
            <Layout
                id={idBuilder(AUDIENCE_BUILDER_OPTION, id)}
                vAlign="center"
                onClick={() => handleClick(optionToSave)}
                className={styles.itemContainer}>
                <Layout flexDirection="column" alignSelf="flex-start">
                    {renderIcon()}
                </Layout>

                <Layout flexDirection="column">
                    <Text
                        type="body"
                        color={isSelected ? 'vividNavy' : 'black'}
                        className={styles.optionText}>
                        <b>{name}</b>
                    </Text>
                </Layout>
            </Layout>
        </Layout>
    );
};

AudienceSelectionOption.propTypes = {
    category: PropTypes.string.isRequired,
    option: PropTypes.object.isRequired,
    isSelected: PropTypes.bool.isRequired
};

export default audiencesContainer(AudienceSelectionOption);
