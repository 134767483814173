import React from 'react';
import { Content } from 'maslow';
import { getFeatures, withFeatures } from '@delivery/react';
import shared from '@sharedStyle/page.scss';

class Book extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        const { components } = getFeatures('book', this);

        return <Content className={shared.pageWrapper}>{components}</Content>;
    }
}

export default withFeatures(Book);
