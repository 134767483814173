import { validateArg } from '@lib';
import { LINEAR_TV } from '@config';

export const determineIconVisability = (selector, property, style) => {
    validateArg(selector, 'object', 1);
    validateArg(property, 'string', 2);
    validateArg(style, 'object', 3);
    const budget = selector.subCampaigns.hasOwnProperty(property)
        ? selector.subCampaigns[property].budget
        : 0;

    budget >= 0 ? (style.display = 'none') : (style.display = 'block');

    return budget;
};

export const getFilteredPlanNavItems = (
    items,
    budgetSelector,
    effectiveUserPermissions,
    permissions,
    style
) => {
    validateArg(items, 'array', 1);
    validateArg(budgetSelector, 'object', 2);
    validateArg(effectiveUserPermissions, 'array', 3);
    validateArg(permissions, 'array', 4);
    validateArg(style, 'object', 5);
    return items.filter(({ navItem }) => {
        if (navItem.toggleVisibility) {
            return (
                determineIconVisability(budgetSelector, LINEAR_TV, style) &&
                navItem.userFilters.some(filter =>
                    effectiveUserPermissions.includes(filter)
                )
            );
        } else {
            return (
                (navItem.userFilters
                    ? navItem.userFilters.some(filter =>
                          effectiveUserPermissions.includes(filter)
                      )
                    : permissions.includes(navItem.permission)) &&
                !permissions.includes(navItem.restrictions)
            );
        }
    });
};
