export const PLAN_STATUSES = {
    INITIAL: 'initial',
    UNSAVED: 'unsaved',
    AUTO_SAVE: 'auto_save',
    SAVED_DRAFT: 'saved_draft',
    SAVED: 'saved',
    BOOKED: 'booked',
    SAVING: 'saving',
    VIEW_PLAN_LIST: 'view-plan-list'
};
