import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import requestHandlers from '@requestHandlers';
import { hourMapper } from '@lib';
import { Text, Layout } from 'maslow';
import Daypart from './Daypart';
import { cancelable } from 'cancelable-promise';

import styles from './styles.scss';

const mapDaypartResponseToUI = data => {
    const BEGIN = 'begin_hour';
    const END = 'end_hour';
    const NAME = 'name';
    return data.map(item => {
        const name = `${hourMapper(item[BEGIN])}-${hourMapper(item[END])}`;

        return {
            name,
            value: name,
            timeOfDay: item[NAME]
        };
    });
};

const renderDaypartExclusionOptions = (
    daypartExclusionOptions,
    exclusionsLimit
) => {
    return (
        daypartExclusionOptions && (
            <Layout flexDirection="column" className={styles.mainWrapper}>
                <Layout
                    flexDirection="row"
                    vAlign="center"
                    className={styles.titleWrapper}>
                    <Text type="h5">Daypart Exclusions</Text>
                    <Text
                        color="slateGray"
                        type="body"
                        className={styles.subTitle}>
                        Select up to 2
                    </Text>
                </Layout>

                <Layout flexDirection="row" hAlign="space-between">
                    {daypartExclusionOptions.map(option => {
                        return (
                            <Daypart
                                option={option}
                                key={option.value}
                                exclusionsLimit={exclusionsLimit}
                            />
                        );
                    })}
                </Layout>
            </Layout>
        )
    );
};

export default function (version, feature) {
    const exclusionsLimit = feature.data.exclusionsLimit;

    const DaypartExclusions = () => {
        const [daypartExclusionOptions, setDaypartExclusionOptions] = useState(
            null
        );
        const excludedDayparts = useSelector(
            state => state.schedule.excludedDayparts
        );

        useEffect(() => {
            const daypartsCall = cancelable(
                requestHandlers.schedule.getDayparts()
            );
            daypartsCall
                .then(response => {
                    setDaypartExclusionOptions(
                        mapDaypartResponseToUI(response.data)
                    );
                })
                .catch(err => console.error(err));

            return () => {
                daypartsCall.cancel();
            };
        }, []);

        return (
            <React.Fragment>
                {daypartExclusionOptions &&
                    renderDaypartExclusionOptions(
                        daypartExclusionOptions,
                        exclusionsLimit
                    )}
            </React.Fragment>
        );
    };
    return DaypartExclusions;
}
