import { animator, cycles } from 'maslow';
import * as animations from './animations';

class Runner {
    constructor() {
        this.a = animator();
        this.a.registerCycles(cycles.customList);
    }

    async run(actionName, args) {
        return await animations[actionName](this.a, args);
    }
}

export default new Runner();
