import { datesHandler } from '@lib';
import { getZones } from './getZones';

export const getSalesforceObject = (
    audiences,
    endDate,
    startDate,
    hiatusWeeks,
    locations,
    campaignRouterId,
    subCampaigns
) => {
    const formattedZones = getZones(locations || []);
    const payload = {
        campaign: {
            audience: audiences,
            end_date: datesHandler.format(endDate),
            start_date: datesHandler.format(startDate),
            geo_data: formattedZones,
            id: campaignRouterId
        },
        sub_campaigns: Object.values(subCampaigns)
            .filter(subCampaign => {
                const { budget } = subCampaign;
                return budget > 0;
            })
            .map(subCampaign => {
                const {
                    context,
                    budget,
                    medium,
                    primary_cpm_net
                } = subCampaign;
                return {
                    context,
                    dollar_budget: budget,
                    medium,
                    primary_cpm_net
                };
            })
    };

    if (hiatusWeeks.length) {
        payload.campaign.hiatus_weeks = hiatusWeeks.map(({ week }) => {
            return {
                end_date: datesHandler.format(week[week.length - 1]),
                start_date: datesHandler.format(week[0])
            };
        });
    }

    return payload;
};
