import featuresManager from '@featuresManager';
import { default as features } from '@components/features';
import {
    PROD,
    UNSET,
    EXTERNAL,
    INTERNAL,
    NATIONAL,
    LOCAL,
    POLITICAL,
    COMMERCIAL
} from '@config';

export const initializeFeatureSystem = async () => {
    featuresManager.initialize({
        config: {
            status: PROD
        },
        customFilters: {
            type: [INTERNAL, EXTERNAL],
            region: [NATIONAL, LOCAL],
            role: [COMMERCIAL, POLITICAL]
        },
        customFiltersStates: {
            type: [UNSET],
            region: [NATIONAL],
            role: [COMMERCIAL]
        },
        features
    });
};
