import React, { memo, useEffect, useState } from 'react';
import { Layout, Text, Popup, Button } from 'maslow';

import successIconSvg from '@public/img/successIcon.svg';
import styles from './styles.scss';

export default memo(({ settings }) => {
    const [animate, setAnimate] = useState(false);

    const handleClick = () => {
        settings.button1.onClick();
        if (settings.button1.animate) setAnimate(true);
    };

    useEffect(() => {
        setAnimate(false);
    }, [settings]);

    return (
        <Popup
            show={settings.showPopup}
            width="42vmin"
            height="auto"
            backgroundColor="rgba(0, 0, 0, 0.75)">
            <Layout
                flexDirection="column"
                hAlign="center"
                vAlign="center"
                className={styles.popupContentsWrapper}>
                <Layout flexDirection="column" hAlign="center">
                    {settings.showSuccessIcon && <img src={successIconSvg} />}
                    <Text type="h3" className={styles.popupText}>
                        {settings.text}
                    </Text>
                    {settings.subText && (
                        <Text className={styles.popupSubText} type="body">
                            {settings.subText}
                        </Text>
                    )}
                </Layout>

                <Layout
                    flexDirection="row"
                    vAlign="end"
                    hAlign="center"
                    className={styles.popupButtonWrapper}>
                    {settings?.button1?.text && (
                        <Button
                            padding="7px 16px"
                            id={settings.button1.id}
                            theme={settings.button1.theme}
                            onClick={handleClick}
                            animate={animate}>
                            {settings.button1.text}
                        </Button>
                    )}
                    {settings?.button2?.text && (
                        <Button
                            padding="7px 16px"
                            id={settings.button2.id}
                            theme={settings.button2.theme}
                            onClick={settings.button2.onClick}
                            animate={settings.button2.animate}>
                            {settings.button2.text}
                        </Button>
                    )}
                </Layout>
            </Layout>
        </Popup>
    );
});
