import { createReducer } from '@reduxjs/toolkit';
import { SET_IS_VALID } from '../constants';

const initialState = false;

const setIsValid = (state, action) => {
    const { isValid } = action.payload;
    return typeof isValid === 'boolean' ? isValid : state;
};

export const isValid = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_IS_VALID, setIsValid)
        .addDefaultCase((state, _) => state);
});
