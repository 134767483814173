import request from '../request';

export default function (email) {
    return request({
        method: 'POST',
        url: 'auth/resend_verification/',
        data: {
            email
        }
    })
        .then(response => {
            return response;
        })
        .catch(error => {
            return { message: error, status: 400 };
        });
}
