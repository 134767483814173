import plansSvg from '@public/img/plans.svg';
import profileSvg from '@public/img/profile.svg';
import reportsSvg from '@public/img/reports.svg';
import rankerSvg from '@public/img/network-ranker-black.svg';
import logoutSvg from '@public/img/logout.svg';

import { termsAndPolicyConfig } from '@config/config.termsAndPolicy';
import { adSpecsPdf } from '@config/config.creative';
import {
    INTERNAL,
    EXTERNAL,
    MEDIA_PLAN_CREATIVE_ADSPECS_INTERNAL_PDF,
    MEDIA_PLAN_CREATIVE_ADSPECS_EXTERNAL_PDF
} from '@config';

export default {
    topNavItems: [
        {
            text: 'Plans',
            to: 'app.main.plans',
            icon: plansSvg
        },
        {
            text: 'Reports',
            linkTo: 'https://a4media.domo.com/page/635609314',
            icon: reportsSvg,
            permission: 'reports.domoreport'
        }
    ],
    bottomNavItems: [
        {
            text: 'Open Network Ranker',
            to: 'app.ranker.location',
            icon: rankerSvg,
            permission: 'mediaplan.useranker',
            transOptions: { source: 'flyout' }
        },
        {
            text: 'Logout',
            onClick: 'openPopup',
            icon: logoutSvg
        }
    ],
    secondaryNavItems: [
        {
            text: 'View Ad Specs',
            to: {
                [MEDIA_PLAN_CREATIVE_ADSPECS_INTERNAL_PDF]:
                    adSpecsPdf[INTERNAL],
                [MEDIA_PLAN_CREATIVE_ADSPECS_EXTERNAL_PDF]: adSpecsPdf[EXTERNAL]
            },
            permissionList: [
                MEDIA_PLAN_CREATIVE_ADSPECS_INTERNAL_PDF,
                MEDIA_PLAN_CREATIVE_ADSPECS_EXTERNAL_PDF
            ]
        },
        {
            text: 'Terms of Use',
            to: {
                [INTERNAL]: termsAndPolicyConfig.termsOfUse,
                [EXTERNAL]: termsAndPolicyConfig.termsOfUse
            }
        },
        {
            text: 'Privacy Policy',
            to: {
                [INTERNAL]: termsAndPolicyConfig.privacyPolicy,
                [EXTERNAL]: termsAndPolicyConfig.privacyPolicy
            }
        }
    ],
    adminNavItems: [
        {
            text: 'Dashboard',
            to: 'app.admin.dashboard',
            icon: profileSvg
        }
    ]
};
