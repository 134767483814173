import { createReducer } from '@reduxjs/toolkit';
import { SET_IS_EDIT_CREATIVES } from '../constants';

const initialState = false;

const setIsEditCreatives = (state, action) => {
    const { isEditCreatives } = action.payload;
    return typeof isEditCreatives === 'boolean' ? isEditCreatives : state;
};

export const isEditCreatives = createReducer(initialState, reducer => {
    reducer
        .addCase(SET_IS_EDIT_CREATIVES, setIsEditCreatives)
        .addDefaultCase((state, _) => state);
});
