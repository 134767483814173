import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useSelector, useDispatch } from 'react-redux';
import requestHandlers from '@requestHandlers';
import { filterAudienceName, audienceNameExists } from '@services';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import {
    setSavedAudiences,
    toggleIsAudienceSaved,
    toggleIsAudienceModified,
    fetchHouseholdCount
} from '@state/audience/actions';
import { replaceAudiences } from '@state/audience/actions/audiences';
import {
    savedAudiencesSelector,
    campaignTypeSelector,
    hhCountPayloadSelector,
    userSelector
} from '@selectors';

import { Text, Button, Input, Icon, Layout, Textarea } from 'maslow';

import {
    AUDIENCE_SUMMARY_CANCEL_SAVE_AUDIENCE,
    AUDIENCE_SUMMARY_INPUT_SAVE_AUDIENCE,
    AUDIENCE_SUMMARY_SAVE_AUDIENCE,
    AUDIENCE_SUMMARY_SAVE_AND_USE_AUDIENCE,
    AUDIENCE_SUMMARY_DESCRIPTION_SAVE_AUDIENCE
} from '@config/idConstants';
import { SAVED_AUDIENCES } from '@config';
import { PLAN_STATUSES } from '@config/config.planStatus';

import styles from './styles.scss';
import saveAudienceSvg from '@public/img/selector-mya.svg';

const STATUSES = {
    IDLE: 'idle',
    VALID: 'valid',
    ERROR: 'error'
};

const DISPLAY_TEXT_STATES = {
    INITIAL: '',
    EXISTING_AUDIENCE_NAME: 'Audience name already exists.'
};

const INTERVAL_TIME = 500;

const AudienceSavePrompt = ({
    isPopupActive,
    updateActiveState,
    maxAudienceNameLimit,
    maxDescriptionLimit,
    characterErrorLimit
}) => {
    const dispatch = useDispatch();
    const hhCountPayload = useSelector(hhCountPayloadSelector);
    const savedAudiences = useSelector(savedAudiencesSelector);
    const { account } = useSelector(userSelector);
    const campaignType = useSelector(campaignTypeSelector);

    const [audienceName, setAudienceName] = useState('');
    const [audienceDescription, setAudienceDescription] = useState('');
    const [errorText, setError] = useState('');
    const [isDisabled, setDisabled] = useState(true);
    const [audienceNameStatus, setAudienceNameStatus] = useState(STATUSES.IDLE);
    const [audienceDescriptionStatus, setAudienceDescriptionStatus] = useState(
        STATUSES.IDLE
    );
    const [saveAndUseButtonAnimate, setSaveAndUseButtonAnimate] = useState(
        false
    );
    const [saveOnlyButtonAnimate, setSaveOnlyButtonAnimate] = useState(false);

    const externalId = account.id;

    const onNameChange = value => {
        if (value) setDisabled(false);
        setError(DISPLAY_TEXT_STATES.INITIAL);
        setAudienceNameStatus(STATUSES.IDLE);
    };

    const closePopup = () => {
        updateActiveState(false);
        setAudienceName('');
        setAudienceDescription('');
        setError(DISPLAY_TEXT_STATES.INITIAL);
        setAudienceNameStatus(STATUSES.IDLE);
        setDisabled(false);
    };

    const buildSaveAudiencePayload = () => ({
        name: audienceName,
        description: audienceDescription,
        externalId,
        audience: hhCountPayload,
        campaignType
    });

    const getSavedAudiences = () => {
        requestHandlers.audiences
            .getUserAssociatedSavedAudiences(externalId, campaignType)
            .then(({ data }) => {
                dispatch(setSavedAudiences(filterAudienceName(data)));
            })
            .catch(err => {
                console.error('error loading audiences', err);
            });
    };

    const onSaveAudience = (audience, isUseAudience) => {
        getSavedAudiences();
        isUseAudience && useSavedAudience(audience);
        dispatch(toggleIsAudienceSaved(true));
        dispatch(toggleIsAudienceModified(false));
        animateButton(isUseAudience, false);
        closePopup();
    };

    const submitNewAudience = isUseAudience => {
        requestHandlers.audiences
            .audience(buildSaveAudiencePayload())
            .then(({ data }) => onSaveAudience(data, isUseAudience))
            .catch(console.error);
    };

    const getAudienceToUse = ({ audience }) => ({
        name: audience.name,
        id: audience.id.toString(),
        category: SAVED_AUDIENCES
    });

    const useSavedAudience = audience => {
        dispatch(replaceAudiences(getAudienceToUse(audience)));
        dispatch(fetchHouseholdCount());
        dispatch(setCampaignRouterId(null));
        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
    };

    const animateButton = (isUseAudience, run) =>
        isUseAudience
            ? setSaveAndUseButtonAnimate(run)
            : setSaveOnlyButtonAnimate(run);

    const saveAudience = async isUseAudience => {
        animateButton(isUseAudience, true);
        const isAudienceNameExist = await audienceNameExists({
            savedAudiences,
            audienceName,
            externalId,
            campaignType
        });

        if (isAudienceNameExist) {
            setError(DISPLAY_TEXT_STATES.EXISTING_AUDIENCE_NAME);
            setAudienceNameStatus(STATUSES.ERROR);
            setDisabled(true);
            animateButton(isUseAudience, false);
        } else {
            submitNewAudience(isUseAudience);
        }
    };

    const makeSaveAudience = isUseAudience => () => saveAudience(isUseAudience);

    if (!isPopupActive) return null;
    return (
        <div className={styles.savePrompt}>
            <div className={styles.savePromptBackground}>
                <div className={styles.savePromptModal}>
                    <Layout
                        flexDirection="column"
                        hAlign="center"
                        vAlign="top"
                        className={styles.cardWrapper}>
                        <Icon src={saveAudienceSvg} size={16}></Icon>
                        <Text type="h5" color="black" className={styles.header}>
                            Save your audience
                        </Text>
                    </Layout>

                    <Layout flexDirection="column" vAlign="left">
                        <Layout flexDirection="row">
                            <Layout
                                flexDirection="column"
                                className={styles.inputWrapper}>
                                <Input
                                    id={AUDIENCE_SUMMARY_INPUT_SAVE_AUDIENCE}
                                    name="audience"
                                    value={audienceName}
                                    errorText={errorText}
                                    isLabelFloated={false}
                                    status={audienceNameStatus}
                                    label={'Audience Name'}
                                    onChange={setAudienceName}
                                    doneTyping={onNameChange}
                                    doneTypingInterval={INTERVAL_TIME}
                                    maxLength={maxAudienceNameLimit}
                                />
                                <Text
                                    type="input"
                                    className={styles.audienceNameCount}>
                                    {audienceName.length}/{maxAudienceNameLimit}
                                </Text>
                            </Layout>
                        </Layout>
                        <Layout flexDirection="row">
                            <Layout
                                flexDirection="column"
                                className={styles.inputWrapper}>
                                <label
                                    className={styles.audienceDescriptionTitle}>
                                    Description (optional)
                                </label>
                                <Textarea
                                    id={
                                        AUDIENCE_SUMMARY_DESCRIPTION_SAVE_AUDIENCE
                                    }
                                    name="audienceDescription"
                                    onChange={setAudienceDescription}
                                    value={audienceDescription}
                                    maxLength={maxDescriptionLimit}
                                    className={styles.audienceDescriptionText}
                                />
                                <Text
                                    type="input"
                                    className={styles.audienceDescriptionCount}>
                                    {audienceDescription.length}/
                                    {maxDescriptionLimit}
                                </Text>
                            </Layout>
                        </Layout>
                        <Layout
                            className={styles.saveButtons}
                            flexDirection="row"
                            vAlign="center">
                            <Button
                                id={AUDIENCE_SUMMARY_SAVE_AND_USE_AUDIENCE}
                                theme="primary"
                                animate={saveAndUseButtonAnimate}
                                disabled={isDisabled || !audienceName}
                                onClick={makeSaveAudience(true)}>
                                Save and use
                            </Button>
                            <Button
                                className={styles.saveOnlyButton}
                                id={AUDIENCE_SUMMARY_SAVE_AUDIENCE}
                                theme="outline"
                                animate={saveOnlyButtonAnimate}
                                disabled={isDisabled || !audienceName}
                                onClick={makeSaveAudience(false)}>
                                Save only
                            </Button>
                        </Layout>
                        <Layout
                            className={styles.cancelButton}
                            flexDirection="column"
                            vAlign="left">
                            <Button
                                id={AUDIENCE_SUMMARY_CANCEL_SAVE_AUDIENCE}
                                theme="outlineGray"
                                onClick={closePopup}>
                                Cancel
                            </Button>
                        </Layout>
                    </Layout>
                </div>
            </div>
        </div>
    );
};

AudienceSavePrompt.propTypes = {
    isPopupActive: PropTypes.bool,
    updateActiveState: PropTypes.func,
    maxAudienceNameLimit: PropTypes.number.isRequired,
    maxDescriptionLimit: PropTypes.number.isRequired,
    characterErrorLimit: PropTypes.number.isRequired
};

export default AudienceSavePrompt;
