export default {
    name: 'MediaPlanTopbar',
    description: 'Topbar with save and share functionality for Media Plan',
    library: 'react',
    versions: {
        prod: '1.0.0',
        sim: '1.0.0',
        ltd: '1.0.0',
        beta: '1.0.0',
        alpha: '1.0.0'
    },
    states: [
        'plan'
    ],
    sections: [
        'mediaPlan'
    ],
    permissions: [],
    data: {},
    attributes: {},
    modifications: {}
};
