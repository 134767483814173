import { WARNING, ERROR, INFO } from '@config';

import errorSvg from '@public/img/error-state.svg';
import warningSvg from '@public/img/ico-warning.svg';

export const ERROR_STATES = {
    WARNING: {
        title: WARNING,
        icon: warningSvg
    },
    ERROR: {
        title: ERROR,
        icon: errorSvg
    },
    INFO: {
        title: INFO,
        icon: warningSvg
    }
};
