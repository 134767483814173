import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { Layout, Text, Bar, Tooltip } from 'maslow';
import showFullSvg from '@public/img/ico-show-full.svg';

import {
    strf,
    datesHandler,
    getPercentFromValue,
    replaceHyphenWithSlash,
    isArray
} from '@lib';
import { DRAFT, COMPLETED, LIVE, EDIT_CREATIVES } from '@config';
import { plansConfig } from '@config/config.plans';
import { PLANS_ACTION_MENU, PLANS_ACTION_MENU_ITEM } from '@config/idConstants';

import {
    PLANS_NAME_TOOLTIP,
    PLANS_ADVERTISER_NAME_TOOLTIP
} from '@config/idConstants';
import { idBuilder } from '@services/idBuilder';

import DateSection from './DateSection';
import ViewPlan from './ViewPlan';
import ContinueEditing from './ContinueEditing';
import ViewReport from './ViewReport';
import { ActionMenu } from '@components/common';
import { setIsEditCreatives } from '@state/ui/actions';

import style from '../styles.scss';
import { REMOVE_PLAN } from '@config/';

const Plan = ({ plan, transition, showPopup, cardIndex }) => {
    const {
        name,
        advertiserName,
        id,
        budget,
        startDate,
        endDate,
        modifiedDate,
        state: status
    } = plan;
    const dispatch = useDispatch();
    const [showAdvertiserPlusSvg, setShowAdvertiserPlusSvg] = useState(false);

    const advertiserId = `advertiser-${id}`;
    const { routes, planStatus } = plansConfig;
    const { mediaPlanSummary, report } = routes;
    const currentDate = datesHandler.format(datesHandler.newDate());

    const isDraft = status === DRAFT;
    const totalDays = datesHandler.differenceInDays(endDate, startDate);
    const tempDaysPassed = datesHandler.differenceInDays(
        currentDate,
        startDate
    );
    const daysPassed =
        tempDaysPassed > totalDays
            ? totalDays
            : tempDaysPassed < 0
            ? 0
            : tempDaysPassed;
    const percentage = isDraft ? 0 : getPercentFromValue(daysPassed, totalDays);

    const formattedBudget = strf(budget).commas().prepend('$').value();
    const formattedModifiedDate = replaceHyphenWithSlash(modifiedDate);
    const { menu, styles, startDateIcon, endDateIcon } = planStatus[status];

    useEffect(() => {
        setShowPlusSvg();
        window.addEventListener('resize', setShowPlusSvg);
        return () => {
            window.removeEventListener('resize', setShowPlusSvg);
        };
    }, []);

    const isShowActionMenu = menu.length > 0;
    const isShowViewReport = status === LIVE || status === COMPLETED;

    const onEditCreatives = () => {
        dispatch(setIsEditCreatives(true));
        transition.router.stateService.go(routes.creative, { planId: id });
    };

    const onActionClick = action => {
        switch (action) {
            case EDIT_CREATIVES:
                onEditCreatives();
                break;
            case REMOVE_PLAN:
                showPopup(id);
                break;
            default:
                break;
        }
    };

    const buildActionMenuOptions = array => {
        if (!isArray(array)) [];

        return array.map(action => {
            return {
                text: action,
                func: () => onActionClick(action),
                itemId: idBuilder(PLANS_ACTION_MENU_ITEM, action)
            };
        });
    };

    const setShowPlusSvg = () => {
        const elem = document.getElementById(advertiserId);
        if (elem) {
            if (
                elem.scrollHeight > elem.offsetHeight ||
                elem.scrollWidth > elem.offsetWidth
            ) {
                setShowAdvertiserPlusSvg(true);
            } else {
                setShowAdvertiserPlusSvg(false);
            }
        }
    };

    return (
        <React.Fragment>
            <div className={style.cardContainer}>
                <Layout flexDirection="column" className={style.paddingAround}>
                    <Layout
                        flexDirection="row"
                        hAlign="space-between"
                        vAlign="center">
                        <Text color="slateGray" className={style.xsmallFont}>
                            ID {id}
                        </Text>
                        {isShowActionMenu && (
                            <ActionMenu
                                menu={buildActionMenuOptions(menu)}
                                menuId={idBuilder(PLANS_ACTION_MENU, id)}
                            />
                        )}
                    </Layout>

                    <Layout flexDirection="row" className={style.nameContainer}>
                        <Text
                            className={`${style.nameContent} ${style.planName}`}>
                            {name}
                        </Text>

                        {name.length > 78 && (
                            <Layout flexDirection="column" vAlign="flex-end">
                                <Tooltip
                                    id={idBuilder(PLANS_NAME_TOOLTIP, id)}
                                    isOnEdgeOfWindow={cardIndex % 3 === 0}
                                    icon={showFullSvg}
                                    size={2}>
                                    {name}
                                </Tooltip>
                            </Layout>
                        )}
                    </Layout>
                </Layout>

                <Layout
                    flexDirection="row"
                    hAlign="space-between"
                    vAlign="center"
                    className={style.advertiserContainer}>
                    <Layout
                        flexDirection="row"
                        vAlign="center"
                        className={style.advertiserWrapper}>
                        <Text
                            color="slateGray"
                            className={`${style.advertiserContent} ${style.advertiserName}`}
                            id={advertiserId}>
                            for {advertiserName}
                        </Text>

                        {showAdvertiserPlusSvg && (
                            <Layout
                                flexDirection="column"
                                vAlign="flex-end"
                                alignSelf="flex-end">
                                <Tooltip
                                    icon={showFullSvg}
                                    size={2}
                                    id={idBuilder(
                                        PLANS_ADVERTISER_NAME_TOOLTIP,
                                        id
                                    )}>
                                    {advertiserName}
                                </Tooltip>
                            </Layout>
                        )}
                    </Layout>

                    <Layout
                        flexDirection="column"
                        vAlign="center"
                        style={styles}
                        className={style.planStatus}>
                        <Text className={style.planStatusText}>{status}</Text>
                    </Layout>
                </Layout>

                <Layout flexDirection="column" className={style.middleSection}>
                    <Layout
                        flexDirection="row"
                        hAlign="space-between"
                        className={style.budgetSection}>
                        <Text color="slateGray" className={style.smallFont}>
                            Total Budget
                        </Text>

                        <Text color="darkGray" className={style.budgetValue}>
                            {formattedBudget}
                        </Text>
                    </Layout>

                    <div>
                        <Bar
                            height="5px"
                            percentage={percentage}
                            className={style.bar}></Bar>
                    </div>

                    <DateSection
                        startDate={startDate}
                        endDate={endDate}
                        startDateIcon={startDateIcon}
                        endDateIcon={endDateIcon}
                    />

                    {isDraft && (
                        <Layout
                            flexDirection="column"
                            hAlign="center"
                            className={style.editPlanContainer}>
                            <ContinueEditing route={mediaPlanSummary} id={id} />
                            <Text
                                color="slateGray"
                                className={
                                    style.smallFont
                                }>{`Last saved ${datesHandler.format(
                                formattedModifiedDate,
                                'LLL dd, yyyy'
                            )}
                        `}</Text>
                        </Layout>
                    )}
                </Layout>

                {!isDraft && (
                    <Layout
                        flexDirection="column"
                        className={style.bottomSection}>
                        <ViewPlan route={mediaPlanSummary} id={id} />

                        {isShowViewReport && (
                            <ViewReport route={report} id={id} />
                        )}
                    </Layout>
                )}
            </div>
        </React.Fragment>
    );
};

Plan.propTypes = {
    plan: PropTypes.object.isRequired,
    transition: PropTypes.object.isRequired,
    showPopup: PropTypes.func.isRequired,
    cardIndex: PropTypes.number.isRequired
};

export default Plan;
