import React, { useState } from 'react';
import { Layout, Input, Icon } from 'maslow';
import searchSvg from '@public/img/Search.svg';
import closeSvg from '@public/img/close.svg';
import styles from './searchAudience.scss';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import {
    MY_AUDIENCES_SEARCH_INPUT,
    MY_AUDIENCES_SEARCH_CLEAR
} from '@config/idConstants';
import { savedAudiencesSelector } from '@selectors/';

const SearchAudience = ({ setSavedAudiencesList }) => {
    const [searchedAudience, setSearchedAudience] = useState('');
    const savedAudiences = useSelector(savedAudiencesSelector);

    const onSearch = value => {
        setSearchedAudience(value);
        const filteredSavedAudiencesList = savedAudiences.filter(audience =>
            audience.name.toLowerCase().includes(value.toLowerCase())
        );
        setSavedAudiencesList(filteredSavedAudiencesList);
    };

    return (
        <Layout vAlign="center" className={styles.searchInputContainer}>
            <Input
                id={MY_AUDIENCES_SEARCH_INPUT}
                name="amount"
                theme="line"
                icon={searchSvg}
                value={searchedAudience}
                className={styles.searchInput}
                onChange={onSearch}
            />
            {searchedAudience && (
                <Layout
                    id={MY_AUDIENCES_SEARCH_CLEAR}
                    className={styles.clearIcon}
                    onClick={() => onSearch('')}>
                    <Icon src={closeSvg} size="2" />
                </Layout>
            )}
        </Layout>
    );
};

SearchAudience.propTypes = {
    setSavedAudiencesList: PropTypes.func.isRequired
};

export default SearchAudience;
