// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".bottomSection___Kt9T0 {\n  text-align: center; }\n\n.emptyWrapper___JkndZ {\n  flex-grow: 1; }\n\n.termsWrapper___3RhMN {\n  text-decoration: none; }\n\n.textLink___ZOAhn {\n  cursor: pointer;\n  text-decoration: underline; }\n\n.policyWrapper___27f7L {\n  margin-left: 20px;\n  text-decoration: none; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/common/TermsAndConditions/styles.scss"],"names":[],"mappings":"AAGA;EACI,kBAAkB,EAAA;;AAGtB;EACI,YAAY,EAAA;;AAGhB;EACI,qBAAqB,EAAA;;AAGzB;EACI,eAAe;EACf,0BAA0B,EAAA;;AAG9B;EACI,iBAAiB;EACjB,qBAAqB,EAAA","file":"styles.scss","sourcesContent":["@import '~@scss/settings/colors';\n@import '~@scss/settings/baseline';\n\n.bottomSection {\n    text-align: center;\n}\n\n.emptyWrapper {\n    flex-grow: 1;\n}\n\n.termsWrapper {\n    text-decoration: none;\n}\n\n.textLink {\n    cursor: pointer;\n    text-decoration: underline;\n}\n\n.policyWrapper {\n    margin-left: 20px;\n    text-decoration: none;\n}\n"]}]);
// Exports
exports.locals = {
	"bottomSection": "bottomSection___Kt9T0",
	"emptyWrapper": "emptyWrapper___JkndZ",
	"termsWrapper": "termsWrapper___3RhMN",
	"textLink": "textLink___ZOAhn",
	"policyWrapper": "policyWrapper___27f7L"
};
module.exports = exports;
