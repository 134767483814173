import React, { useCallback, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import featuresManager from '@featuresManager';

import { ActionMenu } from '@components/common';
import { reMount } from '@components/hoc';
import { Text, Layout, Icon, Spinner, Button, Tooltip } from 'maslow';
import { DisclaimerText } from './DisclaimerText';
import {
    ERROR_MESSAGES,
    FILE_STATUS,
    fileConfig
} from '@config/config.creative';
import {
    POLITICAL,
    IMAGE_TYPE,
    VIDEO_TYPE,
    EXTERNAL,
    PAUSE_CREATIVE,
    RESTART_CREATIVE
} from '@config';
import {
    CREATIVE_ACTION_MENU,
    CREATIVE_ACTION_MENU_ITEM
} from '@config/idConstants';
import { excludeFromArray, datesHandler, isEmpty } from '@lib';
import { formatBytes, idBuilder } from '@services';
import { budgetConfigSelector, uiSelector } from '@selectors/';
import {
    removeFile,
    removeDisclaimer,
    updateFile,
    setFileStatus
} from '@state/creative/actions';

import styles from './styles.scss';
import errorIcon from '@public/img/error.svg';
import imagePlaceholder from '@public/img/image.svg';
import videoPlaceholder from '@public/img/video-placeholder.svg';
import closeSvg from '@public/img/close.svg';
import plusSvg from '@public/img/plus-selector.svg';

import {
    CREATIVE_SHOW_AVAILABLE_SUBCAMPAIGNS,
    CREATIVE_REMOVE_SUBCAMPAIGN,
    CREATIVE_ADD_SUBCAMPAIGN,
    CREATIVE_REMOVE_FILE
} from '@config/idConstants';

const FILE_NAME_LENGTH_VISIBILITY_LIMIT = 45;

const Component = ({ file, index }) => {
    const [userType] = featuresManager.getCustomFilterState('type');
    const [userRole] = featuresManager.getCustomFilterState('role');
    const { PAUSED, ACTIVE, NEW } = FILE_STATUS;

    const allSubCampaigns = useSelector(budgetConfigSelector).subCampaigns;
    const { isEditCreatives = false } = useSelector(uiSelector);
    const {
        subCampaigns,
        applicableSubCampaigns,
        id,
        width,
        height,
        name,
        type,
        src,
        size,
        errors,
        isUploading,
        excludedProducts,
        status,
        createdDate
    } = file;

    const dispatch = useDispatch();
    const [addSubCampaignsOpened, setAddSubCampaignsOpened] = useState(false);

    const availableSubCampaigns = excludeFromArray(
        applicableSubCampaigns,
        subCampaigns
    );

    const validSubs = Object.keys(allSubCampaigns).filter(sc => {
        return allSubCampaigns[sc].budget > 0;
    });

    const _removeFile = useCallback(
        file => {
            dispatch(removeFile(file));
            dispatch(removeDisclaimer(file.id));
        },
        [id]
    );

    const addSubCampaign = subCampaign => {
        setAddSubCampaignsOpened(!addSubCampaignsOpened);
        file.excludedProducts = excludedProducts.filter(
            product => subCampaign !== product
        );
        dispatch(updateFile({ file, index }));
    };

    const removeSubCampaign = subCampaign => {
        file.excludedProducts.push(subCampaign);
        dispatch(updateFile({ file, index }));
    };

    const isValid = () => {
        const filteredSubs = subCampaigns.filter(sc => {
            return validSubs.includes(sc) && !errors.length;
        });
        return filteredSubs.length ? true : false;
    };

    const renderDisclaimerMessages = () => {
        return (
            <Layout
                flexDirection="column"
                vAlign="center"
                className={styles.messageText}>
                <DisclaimerText file={file} />
            </Layout>
        );
    };

    const renderStatusIcon = () => {
        const IconComponent = reMount(Icon);
        const iconSrc = errorIcon;

        return (
            <IconComponent
                className={styles.statusIcon}
                src={iconSrc}
                size={2}
            />
        );
    };

    const renderUploadMessages = ({ createdDate, size, name }) => {
        return isValid() ? (
            <Layout
                className={[styles.statusWrapper, styles.successWrapper].join(
                    ' '
                )}
                hAlign="space-between"
                flexDirection="row">
                <Layout flexDirection="row" vAlign="center">
                    <Text type="input" className={styles.success}>
                        {`Uploaded ${datesHandler.format(
                            createdDate || new Date(),
                            'LLL dd, yyyy'
                        )}`}
                    </Text>
                </Layout>
                <Layout flexDirection="row" vAlign="center">
                    <Text
                        key={`${name}-${size}`}
                        type="input"
                        className={styles.fileSize}>
                        {formatBytes(size)}
                    </Text>
                    {!isValid() ? renderStatusIcon() : null}
                </Layout>
            </Layout>
        ) : (
            errors.map(error => (
                <Layout
                    className={[styles.statusWrapper, styles.errorWrapper].join(
                        ' '
                    )}
                    hAlign="space-between"
                    key={`${name}-${error}`}
                    flexDirection="row">
                    <div>
                        <Text type="body" className={styles.error}>
                            {ERROR_MESSAGES[error]}
                        </Text>
                    </div>
                    <Layout flexDirection="row" vAlign="center">
                        {renderStatusIcon()}
                    </Layout>
                </Layout>
            ))
        );
    };

    const renderPlaceholderIcon = () => {
        const IconComponent = reMount(Icon);
        const iconSrc =
            type === IMAGE_TYPE ? imagePlaceholder : videoPlaceholder;

        return (
            <Layout
                className={styles.placeholderThumbnailWrapper}
                vAlign="center"
                hAlign="center">
                <IconComponent src={iconSrc} size="6" />
            </Layout>
        );
    };

    const renderThumbnail = () => {
        return type !== VIDEO_TYPE && type === IMAGE_TYPE ? (
            <Layout
                className={styles.placeholderThumbnailWrapper}
                vAlign="center"
                hAlign="center">
                <img src={src} className={styles.placeholderThumbnail} />
            </Layout>
        ) : (
            renderPlaceholderIcon()
        );
    };

    const renderFileName = () => {
        const fileNameText = (
            <Text type="body" className={styles.filenameText}>
                {name}
            </Text>
        );
        return name.length >= FILE_NAME_LENGTH_VISIBILITY_LIMIT ? (
            <Tooltip targetComponent={() => fileNameText}>
                <Text type="body">{name}</Text>
            </Tooltip>
        ) : (
            fileNameText
        );
    };

    const changeStatus = (action, id) => {
        switch (action) {
            case PAUSE_CREATIVE:
                dispatch(setFileStatus(id, PAUSED));
                break;
            case RESTART_CREATIVE:
                dispatch(setFileStatus(id, ACTIVE));
                break;
            default:
                break;
        }
    };

    const creativeMenu = fileConfig[status].map(action => ({
        text: action,
        id: idBuilder(CREATIVE_ACTION_MENU_ITEM, action, id),
        func: () => changeStatus(action, id)
    }));

    const renderNotFetching = file => {
        return (
            <Layout fillParent flexDirection="row">
                <Layout
                    flexDirection="column"
                    className={styles.filenameTextContainer}>
                    <Layout
                        flexDirection="column"
                        vAlgin="center"
                        className={styles.messageText}>
                        <Layout hAlign="space-between" flexDirection="row">
                            {renderFileName()}
                            {isEditCreatives && file.status !== NEW ? (
                                <ActionMenu
                                    menuId={idBuilder(CREATIVE_ACTION_MENU, id)}
                                    menu={creativeMenu}></ActionMenu>
                            ) : (
                                <Button
                                    id={CREATIVE_REMOVE_FILE}
                                    className={styles.closeIcon}
                                    onClick={() => _removeFile(file)}>
                                    {id || errors ? (
                                        <Icon src={closeSvg} />
                                    ) : null}
                                </Button>
                            )}
                        </Layout>
                        {renderUploadMessages(file)}
                    </Layout>
                    {(userRole === POLITICAL || userType === EXTERNAL) &&
                    type === IMAGE_TYPE &&
                    !errors.length
                        ? renderDisclaimerMessages()
                        : null}
                </Layout>
            </Layout>
        );
    };

    const renderFetching = () => {
        return (
            <Layout
                flexDirection="column"
                vAlgin="center"
                className={styles.messageText}>
                <Layout
                    className={[
                        styles.statusWrapper,
                        styles.inProgressWrapper
                    ].join(' ')}
                    hAlign="space-between"
                    flexDirection="row">
                    <Layout flexDirection="row" vAlign="center">
                        <Text type="input">Upload in progress</Text>
                    </Layout>
                    <Layout flexDirection="row" vAlign="center">
                        <Text
                            key={`${name}-${size}`}
                            type="input"
                            className={styles.fileSize}>
                            {formatBytes(size)}
                        </Text>
                        <Spinner size="2" margin="0" />
                    </Layout>
                </Layout>
            </Layout>
        );
    };

    const renderRightSide = (subCampaigns, validSubs, isUploading) => {
        if (!isUploading && !isValid(subCampaigns, validSubs)) {
            return renderNotFetching(file);
        } else {
            return isUploading ? renderFetching(file) : renderNotFetching(file);
        }
    };

    const buildSubCampaignsTags = () => {
        return subCampaigns.map(subCampaign => (
            <Layout className={styles.subCampaignTag} key={subCampaign}>
                <Layout flexDirection="row" vAlign="center">
                    <Button
                        id={idBuilder(CREATIVE_REMOVE_SUBCAMPAIGN, subCampaign)}
                        className={styles.closeIcon}
                        onClick={() => removeSubCampaign(subCampaign)}>
                        <Icon src={closeSvg} />
                    </Button>
                    <Text className={styles.tagText} type="body">
                        {subCampaign}
                    </Text>
                </Layout>
            </Layout>
        ));
    };

    const addSubCampaignTags = () => {
        if (!addSubCampaignsOpened) return;
        return availableSubCampaigns.map(subCampaign => (
            <Layout className={styles.subCampaignTag} key={subCampaign}>
                <Layout
                    id={idBuilder(CREATIVE_ADD_SUBCAMPAIGN, subCampaign)}
                    onClick={() => addSubCampaign(subCampaign)}
                    flexDirection="row">
                    <Text className={styles.tagText} type="body">
                        {subCampaign}
                    </Text>
                </Layout>
            </Layout>
        ));
    };

    return (
        <Layout
            flexDirection="column"
            className={styles.wrapper}
            hAlign="space-between">
            <Layout flexDirection="row">
                <Layout
                    flexDirection="column"
                    hAlign="center"
                    className={styles.outerThumbnailWrapper}>
                    {renderThumbnail()}
                    <Text className={styles.resolutionText}>
                        {width}x{height}
                    </Text>
                </Layout>
                {renderRightSide(subCampaigns, validSubs, isUploading)}
            </Layout>
            <Layout flexDirection="column" className={styles.bottomWrapper}>
                <Text className={styles.targetText} type="input">
                    This media targets:
                </Text>
                <Layout
                    flexDirection="row"
                    className={styles.subCampaignTagWrapper}>
                    {buildSubCampaignsTags()}
                    {!isEmpty(availableSubCampaigns) && (
                        <Button
                            id={CREATIVE_SHOW_AVAILABLE_SUBCAMPAIGNS}
                            className={styles.addIcon}
                            onClick={() =>
                                setAddSubCampaignsOpened(!addSubCampaignsOpened)
                            }>
                            <Icon src={plusSvg} />
                        </Button>
                    )}
                </Layout>
                <Layout
                    flexDirection="row"
                    className={styles.subCampaignTagWrapper}>
                    {addSubCampaignTags()}
                </Layout>
            </Layout>
        </Layout>
    );
};
Component.propTypes = {
    file: PropTypes.object.isRequired
};

export const CreativeFile = Component;
