import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import { Text, Layout } from 'maslow';

import {
    createPerformanceByChannel,
    getSubCampaignNameFromContext
} from '@services';
import { round, sortArrayOfObjectsByProp } from '@lib';
import { barColors } from '@config/barColors';

import styles from './styles.scss';
import shared from '../styles.scss';

const PerformanceByChannel = ({ data, config }) => {
    const { ctr, vcr } = config;
    const container = useRef(null);
    const [width, setWidth] = useState(window.innerWidth);
    let isCtr = false;
    let isVcr = false;

    const newData = data.reduce((filtered, d) => {
        if (d.impressions > 0) {
            const name = getSubCampaignNameFromContext(d.channel);
            let numeratorValue;
            let denominatorValue;
            if (vcr.includes(name)) {
                numeratorValue = d.videoCompletions;
                denominatorValue = d.videoStarts;
            } else {
                numeratorValue = d.clicks;
                denominatorValue = d.impressions;
            }

            const newValue = {
                channel: [name],
                impressions: d.impressions,
                percent: round((numeratorValue / denominatorValue) * 100, true)
            };
            filtered.push(newValue);
        }
        return filtered;
    }, []);

    const sortedData = sortArrayOfObjectsByProp(newData, 'percent', true);

    useEffect(() => {
        const handleResize = () => setWidth(window.innerWidth);
        window.addEventListener('resize', handleResize);
        if (sortedData.length > 0)
            createPerformanceByChannel(sortedData, styles);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, [container.current, width]);

    const buildLegends = type => {
        return sortedData.map(({ channel }) => {
            if (type.includes(channel[0])) {
                isCtr = type === ctr;
                isVcr = type === vcr;
                const legendStyle = {
                    backgroundColor: barColors[channel[0]],
                    borderColor: barColors[channel[0]]
                };
                return (
                    <div
                        style={legendStyle}
                        className={styles.graphLegend}
                        key={channel[0]}></div>
                );
            }
        });
    };

    if (sortedData.length > 0) {
        return (
            <Layout flexDirection="row" className={shared.table}>
                <Layout className={shared.titleWithTop} hAlign="space-between">
                    <Text type="h5">Performance by Channel</Text>

                    <Layout>
                        <Layout flexDirection="column">
                            <Layout>{buildLegends(ctr)}</Layout>
                            {isCtr && (
                                <Text
                                    type="input"
                                    className={styles.legendText}>
                                    CTR
                                </Text>
                            )}
                        </Layout>

                        <Layout
                            flexDirection="column"
                            className={styles.vcrLegend}>
                            <Layout>{buildLegends(vcr)}</Layout>
                            {isVcr && (
                                <Text
                                    type="input"
                                    className={styles.legendText}>
                                    VCR
                                </Text>
                            )}
                        </Layout>
                    </Layout>
                </Layout>

                <div
                    id="performanceByChannelGraph"
                    className={styles.graphContainer}
                    ref={container}></div>
            </Layout>
        );
    } else {
        return null;
    }
};

PerformanceByChannel.propTypes = {
    data: PropTypes.array.isRequired,
    config: PropTypes.object.isRequired
};

export default PerformanceByChannel;
