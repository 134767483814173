import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { Layout } from 'maslow';
import featuresManager from '@featuresManager';
import requestHandlers from '@requestHandlers';
import { Search } from '@components/common';
import Maps from './Maps';

import { selectedLocationsSelector, audiencesSelector } from '@selectors';
import { addLocation, addLocations } from '@state/location/actions/';
import {
    fetchHouseholdCount,
    fetchLocationCount,
    setLocationCount
} from '@state/audience/actions/households';
import { setCampaignRouterId } from '@state/mediaPlan/actions/campaignRouterId';
import { setPlanStatus } from '@state/mediaPlan/actions/planStatus';
import {
    getInputArray,
    filterZips,
    filterLocationSearchResponse,
    hasAudience
} from '@services';
import { isArray } from '@lib';
import { PLAN_STATUSES } from '@config/config.planStatus';

export default function (version, feature) {
    const Location = props => {
        const { searchLabel } = feature.data;
        const [role] = featuresManager.getCustomFilterState('role');
        const dispatch = useDispatch();
        const selectedLocations = useSelector(selectedLocationsSelector);
        const audiences = useSelector(audiencesSelector);

        const onLocationClick = newLocation => {
            const { name } = newLocation;
            dispatch(addLocation({ ...newLocation, ...{ name } }));
            dispatch(fetchHouseholdCount());
            if (hasAudience(audiences)) {
                dispatch(setLocationCount(0));
            }
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        };

        const searchRequest = locations => {
            return async searchStr => {
                const value = searchStr.replace(/[&%#]/g, '');
                try {
                    //skip api call for long zip inputs
                    if (value.length > 100) {
                        return filterLocationSearchResponse([], locations);
                    }
                    const { data } = await requestHandlers.locations.getStrings(
                        value
                    );
                    return filterLocationSearchResponse(data, locations);
                } catch (err) {
                    console.error(err);
                }
            };
        };

        const submitZips = (e, value, el) => {
            e.preventDefault();

            const inputArray = getInputArray(value);

            requestHandlers.locations
                .getZips(inputArray)
                .then(res => {
                    const zips = filterZips(
                        res.data.entries,
                        selectedLocations
                    );
                    if (isArray(zips) && zips.length) {
                        dispatch(addLocations(zips));
                        dispatch(fetchHouseholdCount());
                        if (hasAudience(audiences)) {
                            dispatch(setLocationCount(0));
                        }
                        dispatch(setCampaignRouterId(null));
                        dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
                    }
                    el.focus();
                })
                .catch(console.error);
        };

        useEffect(() => {
            return () => {
                dispatch(fetchLocationCount());
            };
        }, []);

        return (
            <Layout flexDirection="column" padding="16px" fillParent={true}>
                <Search
                    locations={selectedLocations}
                    searchLabel={searchLabel[role]}
                    onClickFunction={onLocationClick}
                    handleZips={true}
                    submitZips={submitZips}
                    searchRequest={searchRequest(selectedLocations)}
                    focusDropdown={true}
                />

                <Maps isState={props.transition.router.stateService.is} />
            </Layout>
        );
    };

    return Location;
}
