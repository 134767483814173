import { createSelector } from 'reselect';
import { effectiveDurationService, disabledDatesService } from '@services/';
import featuresManager from '@featuresManager/';
import { datesHandler } from '@lib';

export const durationSelector = createSelector(
    state => state.schedule.duration,
    duration => duration
);

export const scheduleSelector = createSelector(
    state => state.schedule,
    schedule => schedule
);

export const effectiveDurationSelector = createSelector(
    scheduleSelector,
    ({ hiatusWeeks, startDate, endDate }) => {
        return effectiveDurationService({
            startDate,
            endDate,
            hiatusWeeks
        });
    }
);

export const pastLeadDateSelector = createSelector(
    scheduleSelector,
    ({ hiatusWeeks, startDate, endDate }) => {
        const [role] = featuresManager.getCustomFilterState('role');
        if (startDate) {
            const disabledDates = disabledDatesService({
                startDate,
                endDate,
                role,
                hiatusWeeks
            });
            const leadDate = datesHandler.format(
                disabledDates[disabledDates.length - 1].before
            );
            const formattedStartDate = datesHandler.format(startDate);

            return (
                datesHandler.differenceInDays(leadDate, formattedStartDate) > 0
            );
        }
        return false;
    }
);
