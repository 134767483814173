import { validateArg, isEmpty } from '@lib';
import { buildHHCountPayload } from '../buildHHCountPayload';
import {
    trimAudiences,
    formatLocations,
    hasAudience
} from '../audiencesService';

import { SAVED_AUDIENCES, BUILDER } from '@config';

export const formatAudienceArrayForRedux = arr => {
    validateArg(arr, 'array');

    return arr.map(({ name, values, myAudienceId }) => {
        if (!myAudienceId && !values) return [];
        return myAudienceId
            ? [
                  {
                      id: myAudienceId,
                      name,
                      category: SAVED_AUDIENCES
                  }
              ]
            : values.map(
                  ({
                      name,
                      value,
                      category,
                      section,
                      categoryValue,
                      externalId
                  }) => ({
                      name,
                      value,
                      category,
                      section,
                      categoryValue,
                      id: externalId
                  })
              );
    });
};

export const formatAudienceCombosForRedux = combos => {
    validateArg(combos, 'array');

    return combos.reduce((acc, curr) => {
        const single = curr.length === 1;
        const refVal = single ? curr[0] : curr.join(' + ');
        const labelVal = curr.map(val => val + 1);

        acc[refVal] = {
            sets: curr,
            size: single ? 16 : 4,
            ref: refVal.toString(),
            label: single ? `Audience ${labelVal[0]}` : labelVal.join(' + ')
        };

        return acc;
    }, {});
};

export const returnAllHouseholdCounts = async (
    audience,
    locations,
    getCounts
) => {
    try {
        validateArg(audience, 'object', 1);
        validateArg(locations, 'array', 2);
        validateArg(getCounts, 'function', 3);

        const { audiences, combinations } = audience;
        const households = {};

        const res = await getCounts({});
        households.total = res.data.count;

        if (!isEmpty(locations)) {
            const locationsPayload = buildHHCountPayload(
                [],
                {},
                formatLocations(locations)
            );
            const res = await getCounts(locationsPayload);
            households.locationCount = res.data.count;
        } else {
            households.locationCount = households.total;
        }

        if (hasAudience(audiences)) {
            const audiencesPayload = buildHHCountPayload(
                trimAudiences(audiences),
                combinations,
                formatLocations(locations)
            );
            const res = await getCounts(audiencesPayload);
            households.count = res.data.count;
        } else {
            households.count = households.locationCount;
        }

        return households;
    } catch (error) {
        throw error;
    }
};

export const formatAudienceDataForRedux = async (
    audience,
    locations,
    households,
    getCounts,
    booked
) => {
    validateArg(audience, 'object');
    const { audiences, audienceCombinations, vennPngDataUrl } = audience;

    const newAudience = {
        audiences: formatAudienceArrayForRedux(audiences || [{}]),
        combinations: formatAudienceCombosForRedux(audienceCombinations || []),
        vennPngDataUrl: vennPngDataUrl || '',
        audienceType: BUILDER
    };

    const newHouseholds = booked
        ? households
        : await returnAllHouseholdCounts(newAudience, locations, getCounts);

    return {
        ...newAudience,
        households: newHouseholds
    };
};
