import { createReducer } from '@reduxjs/toolkit';
import { TOGGLE_IS_INDICATOR_MOUNTED } from '../constants';

const initialState = false;

const toggleIsIndicatorMounted = (state, action) => {
    const { isMounted } = action.payload;
    return typeof isMounted === 'boolean' ? isMounted : state;
};

export const isIndicatorMounted = createReducer(initialState, reducer => {
    reducer
        .addCase(TOGGLE_IS_INDICATOR_MOUNTED, toggleIsIndicatorMounted)
        .addDefaultCase((state, _) => state);
});
