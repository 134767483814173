import expandArrowIcon from '@public/img/expandArrowIcon.svg';
import { ERROR } from '@config';

export const mediaPlanConfig = {
    audienceStyles: [
        {
            circle: { backgroundColor: '#2E5EFF' },
            card: {
                backgroundColor: 'rgba(207, 224, 255, 0.5)'
            },
            icon: expandArrowIcon,
            color: 'vividNavy'
        },
        {
            circle: { backgroundColor: '#0D82C0' },
            card: {
                backgroundColor: 'rgba(26,131,190,0.11)'
            },
            icon: expandArrowIcon,
            color: 'denim'
        },
        {
            circle: { backgroundColor: '#07667D' },
            card: {
                backgroundColor: 'rgba(7,120,125,0.11)'
            },
            icon: expandArrowIcon,
            color: 'audience3'
        }
    ],
    mediaToastConfig: {
        type: ERROR,
        text: "Please update your plan's schedule.",
        details: {
            errorMessage:
                "Some dates have expired or do not satisfy Athena's lead time requirements. Please update your dates before booking.",
            routeTo: 'app.plan.schedule',
            routeText: 'Schedule'
        }
    },
    counterLimit: 20
};
