// Imports
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
exports = ___CSS_LOADER_API_IMPORT___(true);
// Module
exports.push([module.id, ".downloadWrapper___3aP5o {\n  margin: 0 auto 24px auto;\n  max-width: 1240px;\n  width: 100%; }\n\n.icon___DmuY5 {\n  margin-right: 8px;\n  height: 16px;\n  width: 16px; }\n\n.fileTypeWrapper___2Dnlf {\n  cursor: pointer;\n  margin-left: 16px; }\n\n.fileTypeText___3QTcF {\n  font-weight: 500; }\n", "",{"version":3,"sources":["/home/vsts/work/1/s/UI/Athena/client/app/components/features/Reports/1.0.0/DownloadReport/styles.scss","/home/vsts/work/1/s/UI/Athena/client/app/scss/settings/_baseline.scss"],"names":[],"mappings":"AAGA;EACI,wBAAgC;EAChC,iBAAiB;EACjB,WAAW,EAAA;;AAGf;EACI,iBCVe;EDWf,YCVyB;EDWzB,WCXyB,EAAA;;ADc7B;EACI,eAAe;EACf,iBChByB,EAAA;;ADmB7B;EACI,gBAAgB,EAAA","file":"styles.scss","sourcesContent":["@import '~@scss/settings/baseline';\n@import '~@scss/settings/colors';\n\n.downloadWrapper {\n    margin: 0 auto $baseline-x3 auto;\n    max-width: 1240px;\n    width: 100%;\n}\n\n.icon {\n    margin-right: $baseline;\n    height: $baseline-x2;\n    width: $baseline-x2;\n}\n\n.fileTypeWrapper {\n    cursor: pointer;\n    margin-left: $baseline-x2;\n}\n\n.fileTypeText {\n    font-weight: 500;\n}\n","$baseline     : 8px;\n$baseline-x2  : $baseline * 2;\n$baseline-x3  : $baseline * 3;\n$baseline-x4  : $baseline * 4;\n$baseline-x5  : $baseline * 5;\n$baseline-x6  : $baseline * 6;\n$baseline-x7  : $baseline * 7;\n$baseline-x8  : $baseline * 8;\n$baseline-x9  : $baseline * 9;\n$baseline-x10  : $baseline * 10;\n$baseline-x11  : $baseline * 11;\n$baseline-x12  : $baseline * 12;\n$baseline-x13  : $baseline * 13;\n$baseline-x14  : $baseline * 14;\n$baseline-x15  : $baseline * 15;\n$baseline-x25  : $baseline * 25;\n"]}]);
// Exports
exports.locals = {
	"downloadWrapper": "downloadWrapper___3aP5o",
	"icon": "icon___DmuY5",
	"fileTypeWrapper": "fileTypeWrapper___2Dnlf",
	"fileTypeText": "fileTypeText___3QTcF"
};
module.exports = exports;
