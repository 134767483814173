import { VerificationError } from '../components/pages';
import featuresManager from '@featuresManager';

export default [
    {
        name: 'verificationError',
        url: '/error',
        views: {
            '@': {
                component: VerificationError
            }
        },
        onEnter: () => {
            featuresManager.setFeatureState('verificationError');
        }
    }
];