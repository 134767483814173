import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import featuresManager from '@featuresManager/';
import { Input } from 'maslow';
import { PLAN_STATUSES } from '@config/config.planStatus';
import {
    budgetSelector,
    createPercentSelector,
    createSectionSelector,
    createStatusSelector,
    regionSelector,
    getLockedSubCampaignsSelector,
    getLockedChannelsSelector
} from '@selectors';
import { budgetCalc } from '@services/budget';

import { strf, getUnformattedBudget } from '@lib';
import { updateBudget } from '@state/budget/actions';
import { setCampaignRouterId, setPlanStatus } from '@state/mediaPlan/actions';
import { setIsValid } from '@state/creative/actions';

import { idBuilder } from '@services';
import { BUDGET_PERCENT_INPUT } from '@config/idConstants';

const INTERVAL_TIME = 500;

const BudgetPercentInput = ({ config, theme, disabledChannelsKeys }) => {
    const { key } = config;
    const dispatch = useDispatch();

    const [role] = featuresManager.getCustomFilterState('role');
    const [value, setValue] = useState(0);

    const budget = useSelector(budgetSelector);
    const percent = useSelector(createPercentSelector[theme](key));
    const sectionBudget = useSelector(createSectionSelector[theme](key));
    const region = useSelector(regionSelector);
    const status =
        theme === 'subCampaign'
            ? useSelector(createStatusSelector.subCampaign(key))
            : 'idle';
    const lockedSubCampaigns = useSelector(getLockedSubCampaignsSelector);
    const lockedChannels = useSelector(getLockedChannelsSelector);

    const updateInput = value => {
        const amount = getUnformattedBudget(value);
        const correctedAmount = amount > 100 ? 100 : amount;
        const formattedAmount = strf(correctedAmount)
            .commas()
            .append('%')
            .value();

        setValue(formattedAmount);
    };

    const updateState = value => {
        const amount = getUnformattedBudget(value);
        const newAmounts = budgetCalc[theme](
            key,
            amount,
            budget,
            true,
            lockedSubCampaigns,
            lockedChannels,
            role,
            region,
            disabledChannelsKeys
        );

        const newBudget =
            theme === 'channel' ? newAmounts.channels[key] : newAmounts[key];
        if (newBudget !== sectionBudget) {
            dispatch(setCampaignRouterId(null));
            dispatch(setPlanStatus(PLAN_STATUSES.UNSAVED));
        }

        dispatch(setIsValid(false));
        dispatch(updateBudget.setIsAutoDistributed(false));
        dispatch(updateBudget[theme](newAmounts));
    };

    useEffect(() => {
        if (!isNaN(percent)) updateInput(percent);
    }, [percent]);

    return (
        <Input
            id={idBuilder(BUDGET_PERCENT_INPUT, key)}
            name="amount"
            value={value}
            doneTypingInterval={INTERVAL_TIME}
            doneTyping={updateState}
            onChange={updateInput}
            status={status}
            textAlign="center"></Input>
    );
};

BudgetPercentInput.propTypes = {
    config: PropTypes.object.isRequired,
    theme: PropTypes.string,
    disabledChannelsKeys: PropTypes.array
};

export default BudgetPercentInput;
